import React, { useEffect, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

type Props = {
    files: File[],
    handleFiles: (files: File[]) => void,
    isMultiple: boolean,
}

const DropZone: React.FC<Props> = ({ files, isMultiple, handleFiles }) => {
    const onDrop = useCallback((acceptedFiles: any) => {
        handleFiles(acceptedFiles)
    }, [])
    const { getRootProps, getInputProps } = useDropzone({ onDrop, noClick: true, multiple: isMultiple })
    const removeImg = (file: File) => {
        const newFiles = files.filter(f => f !== file)
        handleFiles(newFiles)
    }

    useEffect(() => {}, [files])

    return (
      <div className="fv-row">
        <div className='dropzone dz-clickable dz-started' {...getRootProps()}>
          <input {...getInputProps()} />
          <div className="dz-message needsclick">
            <i className="bi bi-file-earmark-arrow-up text-primary fs-3x"></i>
            <div className="ms-4">
              <h3 className="fs-5 fw-bolder text-gray-900 mb-1">Drop files here or click to upload.</h3>
              <span className="fs-7 fw-bold text-gray-400">Upload or drag files</span>
            </div>
          </div>
          {files && files.map((file: any, index: number) => (
            <div key={index} className="dz-preview dz-processing dz-image-preview">
              <div className="dz-image">
                <img src={URL.createObjectURL(file)} className='med-object-fit' alt=""/>
              </div>
              <div className="dz-remove" onClick={() => removeImg(file)} data-dz-remove>Remove file</div>
            </div>
          ))}
        </div>
      </div>
    )
}

export default DropZone