import React, { useState, useEffect } from 'react'
import { KTSVG } from '../../../../../../../_metronic/helpers'
import Functions from '../../../../../../../setup/utilities/Functions'
import LeaveService from '../../../../../../services/Hr/LeaveService'
import EmployeeAddPhone from './EmployeeAddPhone'
import ReactTooltip from 'react-tooltip'
import LeaveHistory from './history/LeaveHistory'
import { useIntl } from 'react-intl'

type Props = {
  employee: IEmployee|undefined,
  handleShow: () => void,
  isLeaveLoading: boolean,
  handleIsLeaveLoading: (isLoading: boolean) => void,
  handleLeaveType: (leaveType: string) => void,
  handleIsLoading: (isLoading: boolean) => void,
  isLoading: boolean,
}

const EmployeeDetail: React.FC<Props> = ({
  employee,
  handleShow,
  isLeaveLoading,
  handleIsLeaveLoading,
  handleLeaveType,
  handleIsLoading,
  isLoading,
}) => {
  const [employeeAbsencesApproved, setEmployeeAbsencesApproved] = useState<IEmployeeAbsence[]>([])
  const [employeeAbsencesPending, setEmployeeAbsencesPending] = useState<IEmployeeAbsence[]>([])
  const [authorizationApproved, setAuthorizationApproved] = useState<IEmployeeAbsence[]>([])
  const [authorizationPending, setAuthorizationPending] = useState<IEmployeeAbsence[]>([])
  const [showPhone, setShowPhone] = useState<boolean>(false)
  const [leaveType, setLeaveType] = useState<string>('leave')
  const [showLeaveHistory, setShowLeaveHistory] = useState<boolean>(false)
  const intl = useIntl()
  const handleShowPhone = () => setShowPhone(true)
  const handleClosePhone = () => setShowPhone(false)
  const handleCloseLeaveHistory = () => setShowLeaveHistory(false)
  const handleShowLeaveHistory = (leaveType: string) => {
    setLeaveType(leaveType)
    setShowLeaveHistory(true)
  }

  const updateLeaveStatus = (uuid: string, status: string) => {
    const data = {
      status,
    }
    LeaveService.updateStatus(uuid, data).then(() => {
      handleIsLeaveLoading(true)
    })
  }

  useEffect(() => {
    if (employee !== undefined) {
      LeaveService.getEmployeeFutureLeaves(employee.uuid).then((response) => {
        setEmployeeAbsencesApproved(response.data.data.employeeAbsenceApproved)
        setEmployeeAbsencesPending(response.data.data.employeeAbsencePending)
        setAuthorizationApproved(response.data.data.authorizationApproved)
        setAuthorizationPending(response.data.data.authorizationPending)
        handleIsLeaveLoading(false)
      })
    }
  }, [employee, isLeaveLoading])


  return (
    <>
      <div className={`card ${isLoading ? 'overlay-wrapper' : ''}`}>
        <ReactTooltip />
        <div className='card-header'>
          <div className="d-flex flex-wrap flex-sm-nowrap mb-3 mt-5">
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-100px symbol-fixed position-relative'>
                <img src={`data:image/*;base64,${employee?.profilePicture}`} alt='Metronic'/>
              </div>
            </div>
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center'>
                    <a href='src/app/modules/hr/employee/components/display/components/EmployeeDetail#' className='text-gray-800 text-hover-primary fs-4 fw-bold me-1'>
                      {employee?.firstName && employee?.lastName ? `${Functions.fullName(employee.firstName, employee.lastName)}` : 'Employee'}
                    </a>
                  </div>
                  <div className='d-flex flex-wrap fw-400 fs-7 mb-2 pe-2'>
                    <a
                      href='src/app/modules/hr/employee/components/display/components/EmployeeDetail#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5'
                    >
                      {employee?.profession}
                    </a>
                  </div>
                  <div className='d-flex flex-wrap fw-400 fs-7 pe-2'>
                    <a
                      href='src/app/modules/hr/employee/components/display/components/EmployeeDetail#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-1'
                    >
                      <div className="d-flex">
                        <KTSVG
                          path='/media/icons/duotune/abstract/at.svg'
                          className='svg-icon svg-icon-4 pt-half me-1'
                        />
                        <span className="text-primary d-block">{employee?.email}</span>
                      </div>
                    </a>
                  </div>
                  <div className='d-flex flex-wrap fw-400 fs-7 pe-2'>
                    {employee && employee.phone ? (
                      <div className="d-flex">
                        <KTSVG
                          path='/media/icons/duotune/abstract/phone.svg'
                          className='svg-icon svg-icon-4 pt-half me-1'
                        />
                        <span className="text-primary d-block">{employee?.phone}</span>
                      </div>
                    ) : (
                      <button
                        type='button'
                        className='btn btn-light btn-sm px-3 py-2 text-primary'
                        onClick={handleShowPhone}
                      >
                        {intl.formatMessage({ id: 'employee.add.phone' })}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='card-toolbar mb-auto mt-5'>
            <button type='button'
              className='btn btn-icon btn-light w-32px h-32px me-3'>
              <i className='bi bi-pencil-square fs-3 text-primary'></i>
            </button>
            <button type='button'
              className='btn btn-icon btn-light w-32px h-32px'>
              <i className='bi bi-three-dots-vertical fs-3 text-primary'></i>
            </button>
          </div>
        </div>
        <div className='card-body mt-3'>
          <div className='row'>
            <div className='col-md-6 mt-3'>
              {/* Start Card current future leaves */}
              {((employeeAbsencesApproved && employeeAbsencesApproved.length > 0) || (employeeAbsencesPending && employeeAbsencesPending.length > 0)) && (
                <div className='card bg-med-light-primary'>
                  <div className='card-body p-3'>
                    <span className='fs-7 fw-500 med-dark'>
                      {intl.formatMessage({ id: 'employee.leave.ongoing' })}
                    </span>
                    {employeeAbsencesApproved && employeeAbsencesApproved.length > 0 && (
                      <div className='me-2 mt-2 d-flex flex-equal flex-wrap'>
                        <div>
                          <span className={'badge me-3 px-4 py-3 badge-success'}>
                            {intl.formatMessage({ id: 'employee.leaves.approved' })}
                          </span>
                        </div>
                        <div>
                          {employeeAbsencesApproved.map((absence, index) => (
                            <p className='text-dark fs-7 fw-bolder mt-2' key={`approved_leave_${index}`}>
                            . {absence.leaveType === 'half_day_leave' ?
                                Functions.formatDateDayMonthYear(absence.date, 'numeric', 'long', 'numeric', 'fr-FR') + ' ' + Functions.firstLetterUpperCase(absence.timeOfDay) :
                                Functions.formatDateDayMonth(absence.startDate, 'numeric', 'long', 'fr-FR') + ' - ' + Functions.formatDateDayMonthYear(absence.endDate, 'numeric', 'long', 'numeric', 'fr-FR')}
                            </p>
                          ))}
                        </div>
                      </div>
                    )}
                    {employeeAbsencesPending && employeeAbsencesPending.length > 0 && (
                      <div className="separator separator-dashed med-border-secondary"></div>
                    )}
                    {employeeAbsencesPending && employeeAbsencesPending.length > 0 && (
                      <div className='me-2 mt-2 d-flex'>
                        <div className='mt-1 float-start'>
                          <span className={'badge me-3 px-4 py-3 badge-warning'}>
                            {intl.formatMessage({ id: 'employee.leaves.pending' })}
                          </span>
                        </div>
                        <div className='w-100'>
                          {employeeAbsencesPending.map((absencePending, indexPending) => (
                            <div key={`approved_leave_pending_${indexPending}`} className='d-flex mb-1'>
                              <div className='text-dark fs-7 fw-bolder mt-3' >
                                . {absencePending.leaveType === 'half_day_leave' ?
                                  Functions.formatDateDayMonthYear(absencePending.date, 'numeric', 'long', 'numeric', 'fr-FR') + ' ' + Functions.firstLetterUpperCase(absencePending.timeOfDay) :
                                  Functions.formatDateDayMonth(absencePending.startDate, 'numeric', 'long', 'fr-FR') + ' - ' + Functions.formatDateDayMonthYear(absencePending.startDate, 'numeric', 'long', 'numeric', 'fr-FR')}
                              </div>
                              <div className='ms-auto'>
                                <button type='button' className='btn btn-icon btn-sm btn-danger'
                                  onClick={() => updateLeaveStatus(absencePending.uuid, 'canceled')}
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/abstract/close.svg'
                                    className='svg-icon svg-icon-1'
                                  />
                                </button>
                                <button type='button' className='btn btn-icon btn-sm btn-success ms-2'
                                  onClick={() => updateLeaveStatus(absencePending.uuid, 'approved')}
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/abstract/check.svg'
                                    className='svg-icon svg-icon-1'
                                  />
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {/* End Card current future leaves */}
              {/* Start Card leave */}
              <div className={`card bg-primary ${(employeeAbsencesApproved && employeeAbsencesApproved.length > 0) || (employeeAbsencesPending && employeeAbsencesPending.length > 0) ? 'mt-3' : ''}`}>
                <div className='card-header border-0'>
                  <span className='fs-max text-inverse-primary fw-600 pt-8'>
                    07
                  </span>
                  <div className='card-toolbar pt-4'>
                    <button type='button' className='btn btn-icon med-bold-primary pt-1' data-tip='Voir tout' data-place='right'
                      onClick={() => handleShowLeaveHistory('leave')}
                    >
                      <i className="bi bi-list-ul text-white fw-bolder fs-1"></i>
                    </button>
                  </div>
                </div>
                <div className='card-body'>
                  <div className='fs-3 text-inverse-primary fw-600'>
                    {intl.formatMessage({ id: 'employee.leave.balance' })}
                  </div>
                  <div className='fs-7 text-inverse-primary fw-500'>
                    {intl.formatMessage({ id: 'employee.leave.balance.description' })}
                  </div>
                  <button type='button' className='btn btn-bg-white ps-2 pt-2 pb-1 mt-5' onClick={() => {
                    handleLeaveType('leave')
                    handleShow()
                  }}>
                    <i className='bi bi-plus fs-max text-primary fw-600'></i>
                    <span className='fw-500 text-primary'>{intl.formatMessage({ id: 'employee.leave.request' })}</span>
                  </button>
                </div>
              </div>
              {/* End Card leave */}
              {/* Start Card no leaves in the future */}
              {((!employeeAbsencesApproved || employeeAbsencesApproved.length === 0) && (!employeeAbsencesPending || employeeAbsencesPending.length === 0)) && (
                <div className='card bg-med-light-primary mt-3'>
                  <div className='card-body p-3'>
                    <span className='fs-7 fw-500 med-dark'>
                      {intl.formatMessage({ id: 'employee.leave.underway' })}
                    </span>
                  </div>
                </div>
              )}
              {/* End Card no leaves in the future */}
              {/* Start Card documents */}
              <div className='card bg-med-light-yellow mt-7'>
                <div className='card-body'>
                  <div className='fs-3 text-dark fw-600'>
                    {intl.formatMessage({ id: 'employee.documents' })}
                  </div>
                  <div className='fs-7 text-dark fw-500'>
                    Flats. Shared Rooms, Duplex
                  </div>
                  <button type='button' className='btn btn-icon btn-bg-white h-60px w-60px mt-5'>
                    <i className='bi bi-plus fs-max text-primary fw-600'></i>
                  </button>
                </div>
              </div>
              {/* End Card documents */}
            </div>
            <div className='col-md-6 mt-3'>
              {/* Start Card future authorizations */}
              {((authorizationApproved && authorizationApproved.length > 0) || (authorizationPending && authorizationPending.length > 0)) && (
                <div className='card bg-med-lighter-primary'>
                  <div className='card-body p-3'>
                    <span className='fs-7 fw-500 med-dark'>
                      {intl.formatMessage({ id: 'employee.authorization.ongoing' })}
                    </span>
                    {authorizationApproved && authorizationApproved.length > 0 && (
                      <div className='me-2 mt-2 d-flex flex-equal flex-wrap'>
                        <div>
                          <span className={'badge me-3 px-4 py-3 badge-success'}>
                            {intl.formatMessage({ id: 'employee.leaves.approved' })}
                          </span>
                        </div>
                        <div>
                          {authorizationApproved.map((authorizationApproved, indexAuthorization) => (
                            <p className='text-dark fs-7 fw-bolder mt-2' key={`approved_authorization_${indexAuthorization}`}>
                                . {Functions.formatDateDayMonthYear(authorizationApproved.date, 'numeric', 'long', 'numeric', 'fr-FR') + ' ' + Functions.firstLetterUpperCase(authorizationApproved.timeOfDay)}
                            </p>
                          ))}
                        </div>
                      </div>
                    )}
                    {authorizationPending && authorizationPending.length > 0 && (
                      <div className="separator separator-dashed med-border-secondary"></div>
                    )}
                    {authorizationPending && authorizationPending.length > 0 && (
                      <div className='me-2 mt-2 d-flex'>
                        <div className='float-start'>
                          <span className={'badge me-3 px-4 py-3 badge-warning'}>
                            {intl.formatMessage({ id: 'employee.leaves.pending' })}
                          </span>
                        </div>
                        <div className='w-100'>
                          {authorizationPending.map((authorizationPending, indexPending) => (
                            <div key={`authorization_pending_${indexPending}`} className='d-flex mb-1'>
                              <div className='text-dark fs-7 fw-bolder mt-3'>
                                . {Functions.formatDateDayMonthYear(authorizationPending.date, 'numeric', 'long', 'numeric', 'fr-FR') + ' ' + Functions.firstLetterUpperCase(authorizationPending.timeOfDay)}
                              </div>
                              <div className='ms-auto'>
                                <button type='button' className='btn btn-icon btn-sm btn-danger'
                                  onClick={() => updateLeaveStatus(authorizationPending.uuid, 'canceled')}
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/abstract/close.svg'
                                    className='svg-icon svg-icon-1'
                                  />
                                </button>
                                <button type='button' className='btn btn-icon btn-sm btn-success ms-2'
                                  onClick={() => updateLeaveStatus(authorizationPending.uuid, 'approved')}
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/abstract/check.svg'
                                    className='svg-icon svg-icon-1'
                                  />
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {/* End Card future authorizations */}
              {/* Start Card authorization */}
              <div className={`card bg-med-light-warning ${(authorizationApproved && authorizationApproved.length > 0) || (authorizationPending && authorizationPending.length > 0) ? 'mt-3' : ''}`}>
                <div className='card-header border-0'>
                  <span className='fs-max med-dark fw-600 pt-8'>
                0
                    <span className='fs-2'>
                /2
                    </span>
                  </span>
                  <div className='card-toolbar pt-4'>
                    <button type='button' className='btn btn-icon btn-sm pt-1 bg-white' data-tip='Voir tout' data-place='left'
                      onClick={() => handleShowLeaveHistory('authorization')}
                    >
                      <i className="bi bi-list-ul med-dark fw-bolder fs-1"></i>
                    </button>
                  </div>
                </div>
                <div className='card-body'>
                  <div className='fs-3 med-dark fw-600'>
                    {intl.formatMessage({ id: 'employee.authorization.balance' })}
                  </div>
                  <div className='fs-7 med-dark fw-500'>
                    {intl.formatMessage({ id: 'employee.authorization.description' })}
                  </div>
                  <button type='button' className='btn btn-bg-white ps-2 pt-2 pb-1 mt-5' onClick={() => {
                    handleLeaveType('authorization')
                    handleShow()
                  }}>
                    <i className='bi bi-plus fs-max med-dark fw-600'></i>
                    <span className='fw-500 med-dark'>{intl.formatMessage({ id: 'employee.authorization.request' })}</span>
                  </button>
                </div>
              </div>
              {/* End Card authorization */}
              {/* Start Card no authorizations in the future */}
              {((!authorizationApproved || authorizationApproved.length === 0) && (!authorizationPending || authorizationPending.length === 0)) && (
                <div className='card bg-med-lighter-primary mt-3'>
                  <div className='card-body p-3'>
                    <span className='fs-7 fw-500 med-dark'>
                      {intl.formatMessage({ id: 'employee.authorization.underway' })}
                    </span>
                  </div>
                </div>
              )}
              {/* End Card no authorizations in the future */}
              {/* Start Card equipments */}
              <div className='card bg-med-light-green mt-7'>
                <div className='card-body'>
                  <div className='fs-3 text-dark fw-600'>
                    {intl.formatMessage({ id: 'employee.equipments' })}
                  </div>
                  <div className='fs-7 text-dark fw-500'>
                    Flats. Shared Rooms, Duplex
                  </div>
                  <button type='button' className='btn btn-icon btn-bg-white h-60px w-60px mt-5'>
                    <i className='bi bi-plus fs-max text-dark fw-600'></i>
                  </button>
                </div>
              </div>
              {/* End Card equipments */}
            </div>
          </div>
        </div>
        <EmployeeAddPhone show={showPhone} handleIsLoading={handleIsLoading} handleClose={handleClosePhone} employee={employee}/>
        <LeaveHistory employee={employee} leaveType={leaveType} show={showLeaveHistory} handleClose={handleCloseLeaveHistory} />
      </div>
      {isLoading &&
          <div className="overlay-layer rounded bg-dark bg-opacity-5">
            <div
              className="spinner-border text-primary"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
      }
    </>
  )
}

export default EmployeeDetail
