import React, { useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Modal } from 'react-bootstrap'
import ProfilePermission from './ProfilePermission'
import { Accordion } from 'react-bootstrap'
import ProfileService from '../../../../../services/Hr/ProfileService'
import { useIntl } from 'react-intl'

const profileSchema = Yup.object().shape({
  name: Yup.string()
    .required('Ce champs est obligatoire'),
  description: Yup.string()
    .required('Ce champs est obligatoire'),
})

type ICheckedFeature = {
  uuid: string,
  name: string,
  slug: string,
  parentFeatureUuid: string,
  isParent: boolean,
}

type Inputs = {
  name: string,
  description: string,
  features: ICheckedFeature[],
}

type Props = {
  profile: IProfile|null|undefined,
  show: boolean,
  handleClose: () => void,
  handleIsLoading: (isLoading: boolean) => void,
  checkedFeatures: ICheckedFeature[]
  handleCheckedFeatures: (checkedFeatures: ICheckedFeature[]) => void
  action: string
}

const ProfileCreate: React.FC<Props> = ({
  profile,
  show,
  handleClose,
  handleIsLoading,
  checkedFeatures,
  action,
  handleCheckedFeatures,
}) => {
  const [showPermission, setShowPermission] = useState(false)
  const [selectedParentFeature, setSelectedParentFeature] = useState<string>('')
  const handleClosePermission = () => setShowPermission(false)
  const handleShowPermission = () => setShowPermission(true)
  const intl = useIntl()

  const config = {
    resolver: yupResolver(profileSchema),
    defaultValues: {
      name: profile?.name ?? '',
      description: profile?.description ?? '',
      features: checkedFeatures,
    },
  }

  const handleSelectedParentFeature = (uuid: string) => {
    setSelectedParentFeature(uuid)
  }

  const { register, handleSubmit, formState: { errors }, reset, setValue, watch, getValues } = useForm<Inputs>(config)

  const watchFeatures = watch('features')

  const handleFeatureChange = (features: ICheckedFeature[]) => {
    setValue('features', features)
  }

  const handlePermissions = (uuid: string|null) => {
    if (uuid !== null) {
      setSelectedParentFeature(uuid)
    } else {
      setSelectedParentFeature('')
    }
    handleShowPermission()
  }

  const deleteOneFeature = (uuid: string, parentUuid: string|null) => {
    const features = getValues('features')
    let newFeatures = features.filter((feature) => feature.uuid !== uuid)
    let exist = false
    newFeatures.forEach((feature) => {
      if (feature.parentFeatureUuid === parentUuid) {
        exist = true
      }
    })
    if (!exist) {
      newFeatures = newFeatures.filter((feature) => feature.uuid !== parentUuid)
    }
    setValue('features', newFeatures)
  }

  const resetForm = () => {
    reset()
  }

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (action === 'create') {
      ProfileService.createProfile(data).then(() => {
        handleIsLoading(true)
        handleClose()
        resetForm()
      })
    } else {
      ProfileService.updateProfile(profile?.uuid, data).then(() => {
        handleCheckedFeatures([])
        handleIsLoading(true)
        handleClose()
        resetForm()
      })
    }
  }

  return (
    <Modal show={show} onHide={handleClose} dialogClassName={'mw-1000px'}>
      <Modal.Header closeButton>
        <Modal.Title>{intl.formatMessage({ id: 'profile.create' })}</Modal.Title>
      </Modal.Header>
      <form className='form w-100' onSubmit={handleSubmit(onSubmit)} noValidate id='create_profile_form'>
        <Modal.Body>
          <div className='card card-p-0'>
            <div className='card-body card-scroll'>
              <div className='row mb-5'>
                <div className='col-md-2'>
                  <label className='form-label mt-3 fs-6 text-dark required'>{intl.formatMessage({ id: 'profile.entity.title.label' })}</label>
                </div>
                <div className='col-md-10'>
                  <input
                    {...register('name')}
                    placeholder={intl.formatMessage({ id: 'profile.entity.title.placeholder' })}
                    className={`form-control form-control-solid form-control-lg col-md-10 ${errors.name ? 'is-invalid' : ''}`}
                    type='text'
                    name='name'
                    autoComplete='off'
                  />
                  {errors.name && errors.name.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.name.message}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-10'>
                <div className='col-md-2'>
                  <label className='form-label mt-3 fs-6 text-dark required'>{intl.formatMessage({ id: 'profile.entity.description.label' })}</label>
                </div>
                <div className='col-md-10'>
                  <textarea
                    {...register('description')}
                    placeholder={intl.formatMessage({ id: 'profile.entity.description.placeholder' })}
                    className={`form-control form-control-solid form-control-lg col-md-10 ${errors.description ? 'is-invalid' : ''}`}
                    name='description'
                    autoComplete='off'
                  />
                  {errors.description && errors.description.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.description.message}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="separator separator-dashed border-secondary border-2 my-10"></div>
              <div>
                <div className='row'>
                  <div className='col-md-8'></div>
                  <div className='col-md-4'>
                    <button
                      type='button'
                      className='btn btn-sm btn-success float-end fs-4 fw-500'
                      onClick={handleShowPermission}
                    >
                      {intl.formatMessage({ id: 'profile.entity.features.create' })}
                    </button>
                  </div>
                </div>
                <div className="separator separator-dashed border-secondary border-2 mt-10 mb-5"></div>
                <div className='h-200px mt-5'>
                  {watchFeatures && watchFeatures.map((feature, index) => {
                    if (feature.isParent === true) {
                      return (
                        <div key={`feature_${index}`}>
                          <Accordion defaultActiveKey="2">
                            <Accordion.Item eventKey="0" className='border-0'>
                              <div className='d-flex'>
                                <Accordion.Header className='mb-2 accordion-profile pt-3'>
                                  <span className="form-check-label med-dark fw-500 fs-5">{feature.name}</span>
                                </Accordion.Header>
                                <div className='ms-auto'>
                                  <button type='button' className='btn btn-icon btn-light-primary' onClick={() => handlePermissions(feature.parentFeatureUuid)}>
                                    <i className='bi bi-pencil-square fs-4'></i>
                                  </button>
                                </div>
                              </div>
                              <Accordion.Body className='p-0'>
                                <div>
                                  {watchFeatures && watchFeatures.map((subFeature, subIndex) => {
                                    if (subFeature.parentFeatureUuid === feature.uuid) {
                                      return (
                                        <span key={`sub_feature_${subIndex}`} className="badge badge-light fw-400 fs-7 px-3 py-2 me-4 mb-4">
                                          {subFeature.name}
                                          <span className="badge badge-secondary fw-400 fs-7 ms-1 px-1 py-1" onClick={() => deleteOneFeature(subFeature.uuid, subFeature.parentFeatureUuid)}>
                                            <i className='bi bi-x fs-4 text-gray-700'></i>
                                          </span>
                                        </span>
                                      )
                                    }
                                  })}
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                          <div className="separator separator-dashed border-secondary border-2 my-5"></div>
                        </div>
                      )
                    }
                  })}
                </div>
              </div>
              <ProfilePermission
                profile={profile}
                show={showPermission}
                handleFeatureChange={handleFeatureChange}
                handleClose={handleClosePermission}
                handleSelectedParentFeature={handleSelectedParentFeature}
                selectedParentFeature={selectedParentFeature}
                watchedFeatures={watchFeatures}
                action='create'
                handleIsLoading={handleIsLoading}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn btn-sm btn-secondary'
            onClick={() => {
              handleClose()
              resetForm()
            }}
          >
            {intl.formatMessage({ id: 'action.close' })}
          </button>
          <button
            type='submit'
            className='btn btn-sm btn-secondary'
          >
            {intl.formatMessage({ id: 'action.save' })}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default ProfileCreate
