import React, { useEffect, useState } from 'react'
import TableHeaderSolid from '../../../../../_metronic/partials/med-table/TableHeaderSolid'
import PaginationBar from '../../../../../_metronic/partials/pagination/PaginationBar'
import { KTSVG } from '../../../../../_metronic/helpers'
import { useIntl } from 'react-intl'
import LocationService from '../../../../services/Crm/LocationService'

type Props = {
    show: boolean,
    locationSelected: ILocation|null|undefined
    handleShow: (location:ILocation|undefined|null, action: string) => void
    handleClose: (isSubmit: boolean) => void
    action: string
}

const LocationListing: React.FC<Props> = ({ show, handleClose, handleShow, locationSelected, action }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [locations, setLocations] = useState<ILocation[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = React.useState(1)
  const intl = useIntl()

  const handleCurrentPage = (page:number) => {
    setIsLoading(true)
    setCurrentPage(page)
  }

  const headers = [
    {
      minWidth: 'w-400px',
      text: intl.formatMessage({ id: 'name.label' }),
    },
    {
      minWidth: 'w-400px',
      text: intl.formatMessage({ id: 'location.countryCode.label' }),
    },
    {
      minWidth: 'w-400px',
      text: intl.formatMessage({ id: 'location.parent.label' }),
    },
    {
      minWidth: 'w-400px',
      text: intl.formatMessage({ id: 'location.radius.label' }),
    },
    {
      minWidth: 'w-200px',
      text: intl.formatMessage({ id: 'location.isPublic.label' }),
    },
    {
      minWidth: 'w-100px',
      text: intl.formatMessage({ id: 'actions' }),
    },
  ]

  useEffect(() => {
    LocationService.getLocations(currentPage).then((response) => {
      setLocations(response.data.data.list)
      setTotalPages(response.data.data.totalPages)
      setIsLoading(false)
    })
  }, [isLoading, currentPage])

  const changeIsPublicStatus = (uuid: string|undefined, event: any) => {
    LocationService.updateIsPublicStatus(uuid, {
      'public': event.target.checked,
    }).then(() => {
      setIsLoading(true)
    })
  }

  return (
    <>
      <div className={`card mb-5 ${isLoading ? 'overlay overlay-block' : ''}`}>
        <div className={`card-body py-3 ${isLoading ? 'overlay-wrapper' : ''}`}>
          <div>
            <div className={'row pt-5'}>
              <div className="table-responsive">
                <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                  <TableHeaderSolid headers={headers} />
                  <tbody>
                    {locations && locations.map((locationItem, index) => (
                      <tr key={`location_${index}`}>
                        <td>
                          <span className="text-muted text-muted">{locationItem.name}</span>
                        </td>
                        <td>
                          <span className="text-muted text-muted">{locationItem.countryCode}</span>
                        </td>
                        <td>
                          <span className="text-muted text-muted">{locationItem.radius || '-'}</span>
                        </td>
                        <td>
                          <span className="text-muted text-muted">{locationItem.radius || '-'}</span>
                        </td>
                        <td>
                          <div className="form-check form-switch form-check-custom form-check-solid">
                            <input className="form-check-input w-32px h-20px" checked={locationItem.isPublic} onChange={() => changeIsPublicStatus(locationItem.uuid, event)} type="checkbox" id="flexSwitchChecked"/>
                          </div>
                        </td>
                        <td className="text-end">
                          <a href="src/app/modules/crm/location/components/LocationListing#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 w-32px h-32px">
                            <KTSVG
                              path='/media/icons/duotune/abstract/write.svg'
                              className='svg-icon svg-icon-2'
                            />
                          </a>
                          <a href="src/app/modules/crm/location/components/LocationListing#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 w-32px h-32px">
                            <KTSVG
                              path='/media/icons/duotune/abstract/trash.svg'
                              className='svg-icon svg-icon-2'
                            />
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {isLoading &&
                        <div className="overlay-layer rounded bg-dark bg-opacity-5">
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
          }
        </div>
        {totalPages > 1 ? (
          <PaginationBar
            totalPages={totalPages}
            currentPage={currentPage}
            handleCurrentPage={handleCurrentPage}
          />
        ) : (<></>)}
      </div>
    </>
  )
}

export default LocationListing
