import apiService from '../../../setup/axios/ApiService'

const prefixURL = 'cash-flow'

const getAllCashFlows = () => apiService.get(`${prefixURL}/`)

const selectCashFlow = () => apiService.get(`${prefixURL}/select/select-cash-flow/`)

const createCashFlow = (data: any) => apiService.post(`${prefixURL}/`, data)

const updateCashFlow = (uuid: string|undefined, data: any) => apiService.put(`${prefixURL}/${uuid}`, data)

const deleteCashFlow = (uuid: string|undefined) => apiService.deletes(`${prefixURL}/${uuid}`)

const getCashFlow = (uuid:string|undefined) => apiService.get(`${prefixURL}/${uuid}`)

const CashFlowService = {
  getAllCashFlows,
  selectCashFlow,
  createCashFlow,
  updateCashFlow,
  deleteCashFlow,
  getCashFlow,
}

export default CashFlowService
