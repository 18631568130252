import React from 'react'
import TimeSlotService from '../../../../../../services/Crm/TimeSlotService'
import { KTSVG } from '../../../../../../../_metronic/helpers'

type Props = {
  journey: IJourney|null,
  timeSlot: ITimeSlot|null,
  setIsLoading: (isLoading: boolean) => void,
}

const BreakNotStarted : React.FC<Props> = ({ journey, setIsLoading, timeSlot }) => {
  const onStart = () => {
    const data = {
      title: 'Lunch Break',
      type: 'lunch_break',
      isStarted: true,
      isFinished: false,
      status: 'undone',
      journey: journey?.uuid,
    }

    TimeSlotService.createTimeSlot(data).then(() => {
      setIsLoading(true)
    })
  }

  return (
    <div className='card card-rounded mt-5 bg-light-warning'>
      <div className='card-body px-2 py-0'>
        <div className="d-flex">
          <div className="mt-2">
            <div className="d-flex flex-column h-100 pb-2">
              <KTSVG
                path='/media/icons/duotune/abstract/abs009.svg'
                className='svg-icon svg-icon-1 svg-icon-gray-300'
              />
              <div className='vr bg-gray-300 opacity-100 w-3px h-100 ms-3'></div>
            </div>
          </div>
          <div className="flex-grow-1">
            <div className="row mt-4 ms-3">
              <div className="col-sm-6">
                <span className="text-dark fs-5 fw-500">Pause déjeuner :
                </span>
              </div>

            </div>
          </div>
          <div className="d-flex align-content-end justify-content-end mt-1">
            <a onClick={() => {
              onStart()
            }}
            className='btn btn-icon btn-warning btn-color-dark ms-1 btn-sm w-40px h-40px  rounded-3'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr027.svg'
                className='svg-icon svg-icon-1 svg-icon-white'
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BreakNotStarted
