// import UserService from '../../app/services/UserService'
// import * as auth from '../../app/modules/auth/redux/AuthRedux'

export default function setupAxios(axios: any, store: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.defaults.headers.ContentType = 'multipart/form-data'
  axios.interceptors.request.use(
    (config: any) => {
      const {
        auth: { accessToken },
      } = store.getState()

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
      }
      return config
    },
    (err: any) => Promise.reject(err),
  )
  axios.interceptors.response.use(
    (response:any) => response,
    async (err: any) => {
      console.log(err)
      /* const originalRequest = err.config
      if (err.response.status === 400 && !originalRequest.sent) {
        const {
          auth: { refreshToken },
        } = store.getState()
        originalRequest.sent = true
        await UserService.refreshToken(refreshToken).then((response) => {
          const data = response.data.data
          store.dispatch(auth.actions.refreshToken(data.user, data.accessToken, data.refreshToken))
          if (data.accessToken) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${data.accessToken}`
            return axios(originalRequest)
          }
        })
      } */
      return Promise.reject(err)
    },
  )
}
