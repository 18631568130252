import React, { useEffect, useState } from 'react'
import { Navigate, Outlet, Route, Routes, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PageLink, PageTitle } from '../../../../../../_metronic/layout/core'
import BankAccountHeader from './BankAccountHeader'
import BankAccountTransactions from './components/BankAccountTransactions'
import OperationCreate from './components/OperationCreate'
import BankAccountDetailsToolbar from './BankAccountDetailsToolbar'
import BankAccountService from '../../../../../services/Accounting/BankAccountService'
import BankAccountNavBar from './BankAccountNavBar'


const BankAccountDetailsWrapper : React.FC = () => {
  const { id } = useParams()
  const [bankAccount, setBankAccount] = useState<IBankAccount>()
  const intl = useIntl()
  const bankAccountDisplayBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({ id: 'home' }),
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: 'accounting.menu' }),
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: 'accounting.bank.account.menu' }),
      path: '/customer',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  useEffect(() => {
    BankAccountService.getBankAccount(id).then((response) => {
      setBankAccount(response.data.data)
    })
  }, [])

  return (
    <>
      <div className="d-flex flex-column flex-xl-row">
        <Routes>
          <Route
            element={
              <>
                <BankAccountHeader bankAccount={bankAccount} />
                <Outlet/>
              </>
            }
          >
            <Route
              path='transactions'
              element={
                <>
                  <PageTitle breadcrumbs={bankAccountDisplayBreadCrumbs} pageTitle={`${bankAccount?.name}`}>
                    <BankAccountDetailsToolbar/>
                  </PageTitle>
                  <div className="flex-lg-row-fluid ms-lg-8">
                    <BankAccountNavBar bankAccount={bankAccount} />
                    <BankAccountTransactions />
                  </div>
                </>
              }
            />
            <Route
              path='operation'
              element={
                <>
                  <PageTitle breadcrumbs={bankAccountDisplayBreadCrumbs} pageTitle={`${bankAccount?.name}`}>
                    <BankAccountDetailsToolbar/>
                  </PageTitle>
                  <div className="flex-lg-row-fluid ms-lg-8">
                    <BankAccountNavBar bankAccount={bankAccount} />
                    <OperationCreate />
                  </div>
                </>
              }
            />
            <Route index element={<Navigate to='transactions' />} />
          </Route>
        </Routes>
      </div>
    </>
  )
}

export default BankAccountDetailsWrapper
