import { FC } from 'react'

const Error403: FC = () => (
  <>
    <h1 className='fw-bolder fs-4x text-gray-700 mb-10'>Not authorized</h1>

    <div className='fw-bold fs-3 text-gray-400 mb-15'>
            You are not authorized for this!
    </div>
  </>
)


export { Error403 }
