import React from 'react'
import { useIntl } from 'react-intl'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import SupplierOrderToolbar from './components/SupplierOrderToolbar'
import SupplierOrderFilter from './components/SupplierOrderFilter'


const SupplierOrderWrapper : React.FC = () => {
  const intl = useIntl()
  const teamBreadCrumbs: Array<PageLink> = [
    {
      title: 'Home',
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: 'accounting.supplier.order.menu' }),
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={teamBreadCrumbs} pageTitle={intl.formatMessage({ id: 'accounting.supplier.order.list' })}>
        <SupplierOrderToolbar />
      </PageTitle>
      <SupplierOrderFilter/>
    </>
  )
}
export default SupplierOrderWrapper
