import React from 'react'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { Link } from 'react-router-dom'

type Props = {
    bankAccount: IBankAccount,
    isLoading: boolean,
    handleIsLoading: (isLoading: boolean) => void,
}

const BankAccountItem : React.FC<Props> = ({ bankAccount, isLoading, handleIsLoading }) => (
  <div
    className={'card bgi-no-repeat card-xl-stretch mb-xl-8'}
    style={{
      backgroundPosition: 'right top',
      backgroundSize: '30% auto',
      backgroundImage: `url(${toAbsoluteUrl('/media/svg/shapes/abstract-4.svg')})`,
    }}
  >
    <div className='card-body'>

      <Link to={`${bankAccount.uuid}`}>
        <h3 className='card-title fw-bold text-muted text-hover-primary fs-4'>{bankAccount.name}</h3>
      </Link>

      <div className='fw-bold text-primary my-6'>{bankAccount.balance}</div>

      <p
        className='text-dark-75 fw-semibold fs-5 m-0'
      >
        {bankAccount.bank.name}
      </p>
    </div>
  </div>
)

export default BankAccountItem
