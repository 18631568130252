import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import InvoiceService from '../../../../../../services/Accounting/InvoiceService'
import TableHeaderSolid from '../../../../../../../_metronic/partials/med-table/TableHeaderSolid'
import { KTSVG } from '../../../../../../../_metronic/helpers'
import PaginationBar from '../../../../../../../_metronic/partials/pagination/PaginationBar'
import Functions from '../../../../../../../setup/utilities/Functions'
import PaymentCreate from '../../../../invoice/components/payment/PaymentCreate'
import PaymentDetails from '../../../../invoice/components/payment/PaymentDetails'


const Invoices : React.FC = () => {
  const { id } = useParams()
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [invoices, setInvoices] = useState<IInvoice[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = React.useState(1)
  const [showPayment, setShowPayment] = useState<boolean>(false)
  const [selectedInvoice, setSelectedInvoice] = useState<IInvoice>()
  const [remainingPayment, setRemainingPayment] = useState<any>()
  const [showDetails, setShowDetails] = useState<boolean>(false)

  const handleShowPayment = (invoice: IInvoice, remainingPayment: number) => {
    setShowPayment(true)
    setSelectedInvoice(invoice)
    setRemainingPayment(remainingPayment)
  }

  const handleClosePayment = async (isSubmit: boolean) => {
    if (!isSubmit) {
      const isConfirmed = await Functions.sweetAlert(
        '<span class="mt-5">' + intl.formatMessage({ id: 'action.message' }) + '</span>',
        'warning',
        true,
        intl.formatMessage({ id: 'action.cancel' }),
        intl.formatMessage({ id: 'action.return' }),
        'btn btn-primary mt-10 mb-10',
        'btn btn-active-light mt-10 mb-10',
      )
      if (isConfirmed) {
        setShowPayment(false)
      }
    } else {
      setShowPayment(false)
    }
  }

  const handleShowDetails = (invoice: IInvoice) => {
    setShowDetails(true)
    setSelectedInvoice(invoice)
  }

  const handleCloseDetails = () => {
    setShowDetails(false)
  }

  const handleIsLoading = (loading: boolean) => {
    setIsLoading(loading)
  }

  const handleCurrentPage = (page:number) => {
    setIsLoading(true)
    setCurrentPage(page)
  }

  const headers = [
    {
      minWidth: 'w-50px',
      text: intl.formatMessage({ id: 'accounting.invoice.entity.reference' }),
    },
    {
      minWidth: 'w-50px',
      text: intl.formatMessage({ id: 'accounting.invoice.entity.paymentDate' }),
    },
    {
      minWidth: 'w-50px',
      text: intl.formatMessage({ id: 'accounting.invoice.entity.netAmount' }),
    },
    {
      minWidth: 'w-50px',
      text: intl.formatMessage({ id: 'accounting.invoice.entity.grossAmount' }),
    },
    {
      minWidth: 'w-50px',
      text: intl.formatMessage({ id: 'accounting.invoice.entity.status' }),
    },
    {
      minWidth: 'w-50px',
      text: intl.formatMessage({ id: 'actions' }),
    },
  ]

  useEffect(() => {
    InvoiceService.getInvoicesByCustomer(currentPage, 1, id).then((response) => {
      setInvoices(response.data.data.list)
      setTotalPages(response.data.data.totalPages)
      setIsLoading(false)
    })
  }, [isLoading, currentPage, status])

  return (
    <>
      <div className={`card mb-xl-10 ${isLoading ? 'overlay overlay-block' : ''}`}>
        <div className={`card-body pt-0 ${isLoading ? 'overlay-wrapper' : ''}`}>
          <div className="table-responsive">
            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 mt-10">
              <TableHeaderSolid headers={headers} />
              <tbody>
                {invoices && invoices.length > 0 ? (
                  invoices.map((invoice, index) => (
                    <tr key={`invoice_${index}`}>
                      <td>
                        <span className="text-muted">{invoice.reference}</span>
                      </td>
                      <td>
                        <span className="text-muted">{invoice.paymentDate}</span>
                      </td>
                      <td>
                        <span className="text-muted">{invoice.netAmount}</span>
                      </td>
                      <td>
                        <span className="text-muted">{invoice.grossAmount}</span>
                      </td>
                      <td>
                        {invoice.workflowInstances && invoice.workflowInstances.map((workflowInstance, index) => (
                          <div key={`workflow_${index}`}>
                            {workflowInstance.workflowType === 'invoice_workflow' ?
                              <>
                                <button
                                  type='button'
                                  className={`btn btn-light btn-sm ${workflowInstance.status === 'draft' ? 'btn-light-warning' : workflowInstance.status === 'unpaid' ? 'btn-light-info' : workflowInstance.status === 'paid' ? 'btn-light-success' : 'btn-light-danger'}`}
                                  data-kt-menu-trigger='click'
                                  data-kt-menu-placement='bottom-end'
                                  data-kt-menu-flip="top-end"
                                >
                                  {workflowInstance.status}
                                  <KTSVG
                                    path='/media/icons/duotune/arrows/arr072.svg'
                                    className='svg-icon-4 svg-icon-gray-500 me-1'
                                  />
                                </button>
                                <div
                                  className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                                  data-kt-menu='true'
                                >
                                  {workflowInstance.status === 'unpaid' ?
                                    <>
                                      <div className='menu-item'>
                                        <a className='menu-link'>
                                                    Cancel
                                        </a>
                                      </div>
                                    </> :
                                    <>
                                      {workflowInstance.status === 'valid' ?
                                        <>
                                          <div className='menu-item'>
                                            <a className='menu-link'>
                                                          Cancel
                                            </a>
                                          </div>
                                        </> :
                                        <>
                                          {workflowInstance.status === 'canceled' ?
                                            <>
                                              <div className='menu-item'>
                                                <a className='menu-link'>
                                                                Reactivate
                                                </a>
                                              </div>
                                            </> :
                                            <>
                                              {workflowInstance.status === 'draft' ?
                                                <>
                                                  <div className='menu-item'>
                                                    <a className='menu-link'>
                                                                      Create
                                                    </a>
                                                  </div>
                                                </> :
                                                <>
                                                </>
                                              }
                                            </>
                                          }
                                        </>
                                      }
                                    </>
                                  }

                                </div>
                              </> :
                              <></>
                            }
                          </div>
                        ))}
                      </td>
                      <td className="text-end">
                        {invoice.remainingAmount > 0 ?
                          <a onClick={() => handleShowPayment(invoice, invoice.remainingAmount)} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 w-32px h-32px">
                            <KTSVG path="/media/icons/duotune/finance/fin009.svg" className="svg-icon svg-icon-2" />
                          </a> :
                          <></>
                        }

                        <a onClick={() => handleShowDetails(invoice)} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 w-32px h-32px">
                          <KTSVG path="/media/icons/duotune/general/gen052.svg" className="svg-icon svg-icon-2" />
                        </a>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 fs-4 align-content-center justify-content-center mt-5'>
                        {intl.formatMessage({ id: 'action.not.found' })}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {totalPages > 1 ? (
          <PaginationBar
            totalPages={totalPages}
            currentPage={currentPage}
            handleCurrentPage={handleCurrentPage}
          />
        ) : (<></>)}
        {isLoading &&
            <div className="overlay-layer rounded bg-dark bg-opacity-5">
              <div
                className="spinner-border text-primary"
                role="status"
              >
                <span className="visually-hidden">{intl.formatMessage({ id: 'action.loading' })}</span>
              </div>
            </div>
        }
      </div>
      <PaymentCreate
        invoice={selectedInvoice ? selectedInvoice : null}
        show={showPayment}
        handleClose={handleClosePayment}
        handleIsLoading={handleIsLoading}
        remainingPayment={remainingPayment}
        setRemainingPayment={setRemainingPayment}
      />
      <PaymentDetails invoice={selectedInvoice ? selectedInvoice : null} show={showDetails} handleClose={handleCloseDetails} />
    </>
  )
}

export default Invoices
