import React, { useEffect, useState } from 'react'
import { Navigate, Outlet, Route, Routes, useParams } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../../../_metronic/layout/core'
import Overview from './components/Overview'
import Settings from './components/Settings'
import { useIntl } from 'react-intl'
import CustomerService from '../../../../../services/Accounting/CustomerService'
import CustomerDetailsToolbar from './CustomerDetailsToolbar'
import CustomerCreate from '../add/CustomerCreate'
import Activities from './components/Activities'
import Invoices from './components/Invoices'
import Estimates from './components/Estimates'
import PurchaseOrders from './components/PurchaseOrders'
import CustomerDetailsHeader from './CustomerDetailsHeader'
import CustomerNavBar from './CustomerNavBar'

const CustomerDetailsWrapper : React.FC = () => {
  const { id } = useParams()
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState(true)
  const [customer, setCustomer] = useState<ICustomer>()
  const [show, setShow] = useState(false)
  const customerDisplayBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({ id: 'home' }),
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: 'accounting.menu' }),
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: 'accounting.customer.menu' }),
      path: '/customer',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const handleIsLoading = (isLoading: boolean) => {
    setIsLoading(isLoading)
  }

  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)

  useEffect(() => {
    CustomerService.getCustomer(id).then((response) => {
      setCustomer(response.data.data)
    })
  }, [isLoading])
  return (
    <>
      <div className="d-flex flex-column flex-xl-row">
        <Routes>
          <Route
            element={
              <>
                <CustomerDetailsHeader customer={customer} />
                <Outlet/>
              </>
            }
          >
            <Route
              path='overview'
              element={
                <>
                  <PageTitle breadcrumbs={customerDisplayBreadCrumbs} pageTitle={`Overview - ${customer?.firstName} ${customer?.lastName}`}>
                    <CustomerDetailsToolbar handleShow={handleShow}/>
                  </PageTitle>
                  <div className="flex-lg-row-fluid ms-lg-5">
                    <CustomerNavBar customer={customer} />
                    <Overview/>
                  </div>
                </>
              }
            />
            <Route
              path='settings'
              element={
                <>
                  <PageTitle breadcrumbs={customerDisplayBreadCrumbs} pageTitle={`${customer?.firstName} ${customer?.lastName}`}>
                    <CustomerDetailsToolbar handleShow={handleShow}/>
                  </PageTitle>
                  <div className="flex-lg-row-fluid ms-lg-5">
                    <CustomerNavBar customer={customer} />
                    <Settings />
                  </div>
                </>
              }
            />
            <Route
              path='activities'
              element={
                <>
                  <PageTitle breadcrumbs={customerDisplayBreadCrumbs} pageTitle={`${customer?.firstName} ${customer?.lastName}`}>
                    <CustomerDetailsToolbar handleShow={handleShow}/>
                  </PageTitle>
                  <div className="flex-lg-row-fluid ms-lg-5">
                    <CustomerNavBar customer={customer} />
                    <Activities />
                  </div>
                </>
              }
            />
            <Route
              path='estimates'
              element={
                <>
                  <PageTitle breadcrumbs={customerDisplayBreadCrumbs} pageTitle={`${customer?.firstName} ${customer?.lastName}`}>
                    <CustomerDetailsToolbar handleShow={handleShow}/>
                  </PageTitle>
                  <div className="flex-lg-row-fluid ms-lg-5">
                    <CustomerNavBar customer={customer} />
                    <Estimates />
                  </div>
                </>
              }
            />
            <Route
              path='purchase-orders'
              element={
                <>
                  <PageTitle breadcrumbs={customerDisplayBreadCrumbs} pageTitle={`${customer?.firstName} ${customer?.lastName}`}>
                    <CustomerDetailsToolbar handleShow={handleShow}/>
                  </PageTitle>
                  <div className="flex-lg-row-fluid ms-lg-5">
                    <CustomerNavBar customer={customer} />
                    <PurchaseOrders />
                  </div>
                </>
              }
            />
            <Route
              path='invoices'
              element={
                <>
                  <PageTitle breadcrumbs={customerDisplayBreadCrumbs} pageTitle={`${customer?.firstName} ${customer?.lastName}`}>
                    <CustomerDetailsToolbar handleShow={handleShow}/>
                  </PageTitle>
                  <div className="flex-lg-row-fluid ms-lg-5">
                    <CustomerNavBar customer={customer} />
                    <Invoices />
                  </div>
                </>
              }
            />
            <Route index element={<Navigate to='overview' />} />
          </Route>
        </Routes>
        <CustomerCreate
          customer={null}
          show={show}
          handleClose={handleClose}
          handleIsLoading={handleIsLoading}
          action={'create'}
        />
      </div>
    </>
  )
}

export default CustomerDetailsWrapper
