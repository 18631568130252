import apiService from '../../../setup/axios/ApiService'

const prefixURL = 'employee'

const getAllEmployees = (currentPage : number = 1, limit : number = 200, withPagination: boolean = false) => apiService.get(`${prefixURL}/`, {
  page: currentPage,
  limit,
  withPagination,
})

const createEmployee = (data: any) => apiService.post(`${prefixURL}/`, data)

const updateEmployee = (uuid: string|undefined, data: any) => apiService.put(`${prefixURL}/${uuid}/edit`, data)

const updateStatus = (uuid:string|undefined, data: any) => apiService.patch(`${prefixURL}/${uuid}/active`, data)

const getEmployee = (uuid:string|undefined) => apiService.get(`${prefixURL}/${uuid}`)

const updatePhone = (uuid:string|undefined, data: any) => apiService.patch(`${prefixURL}/${uuid}/edit-phone`, data)

const selectEmployees = () => apiService.get(`${prefixURL}/select/select-employee`)

const getManagersByCurrentUser = () => apiService.get(`${prefixURL}/select/manager`)

const EmployeeService = {
  getAllEmployees,
  createEmployee,
  updateEmployee,
  updateStatus,
  getEmployee,
  updatePhone,
  selectEmployees,
  getManagersByCurrentUser,
}

export default EmployeeService
