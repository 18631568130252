import React, { useEffect, useState } from 'react'
import useWindowDimensions from '../../../../../../../../../_metronic/partials/mobile-check-view/useCheckMobileScreen'
import { KTSVG } from '../../../../../../../../../_metronic/helpers'
import SymbolMedicalProfessional from '../../../../../../../../../_metronic/partials/common/SymbolMedicalProfessional'
import Functions from '../../../../../../../../../setup/utilities/Functions'
import DropDownList from '../../../common/components/DropDownList'
import format from 'date-fns/format'
import { Link } from 'react-router-dom'
import Symbol from '../../../../../../../../../_metronic/partials/common/Symbol'

type Props = {
  timeSlot : ITimeSlot,
  handleShowCall: (calls: ICall[], timeSlot: ITimeSlot|null) => void,
  handleShowSugBug: () => void,
  handleShowCreateCall: (checkout: boolean, timeSlot: ITimeSlot|null) => void,
}
const ToDoneSupport : React.FC<Props> = ({ timeSlot, handleShowSugBug, handleShowCall }) => {
  const isMobile = useWindowDimensions()
  const [reminder, setReminder] = useState<IReminder|null>(null)

  useEffect(() => {
    setReminder(timeSlot?.todo?.reminder || null)
  }, [reminder])


  return (
    <>
      <div className='card card-rounded mt-5'>
        <div className='card-body px-2 py-0'>
          <div className="d-flex">
            <div className="mt-3">
              <div className="d-flex flex-column h-100 pb-2">
                <KTSVG
                  path='/media/icons/duotune/abstract/abs009.svg'
                  className='svg-icon svg-icon-1 svg-icon-success'
                />
                <div className='vr bg-success opacity-100 w-3px h-100 ms-3'></div>
              </div>
            </div>
            <div className="flex-grow-1 ms-2">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <div className="d-flex flex-grow-1 mb-3 mt-2">
                    <Link to={`/doctor/${timeSlot?.todo?.medicalProfessional?.medKey}`}>
                      <SymbolMedicalProfessional
                        picture={timeSlot?.todo?.medicalProfessional?.profilePicture}
                        picturePath='/admin/uploads/users/'
                        withText={true}
                        widthClassName='symbol-40px me-5'
                        text={Functions.fullName(timeSlot?.todo?.medicalProfessional?.firstName, timeSlot?.todo?.medicalProfessional?.lastName)}
                        shortText={Functions.firstLetterUpperCase(timeSlot?.todo?.medicalProfessional?.firstName || '').charAt(0) + Functions.firstLetterUpperCase(timeSlot?.todo?.medicalProfessional?.lastName || '').charAt(0)}
                        description={timeSlot?.todo?.medicalProfessional?.defaultSpeciality || null}
                        backgroundClass=''
                        textClassName='fs-3 fw-400'
                        isCircular={false}
                        descriptionColor={'text-gray-500 fs-7 fw-500'}
                        textColor={'text-dark fs-6 fw-bolder'}
                        withLocation={true}
                        location={timeSlot?.todo?.medicalProfessional?.customer?.location?.name || null}
                        isValid={timeSlot?.todo?.medicalProfessional.isValid || null}
                      />
                    </Link>
                    {isMobile ?
                      <>
                        <div className="ms-auto mt-2">
                          <div className="d-flex align-items-start">
                            <DropDownList timeSlot={timeSlot} handleShowCall={handleShowCall} handleShowSugBug={handleShowSugBug} active={false}/>
                          </div>
                        </div>
                      </> :
                      <>
                      </>
                    }
                  </div>
                  {timeSlot?.todo?.medicalProfessional?.salesPerson ?
                    <div className='d-flex align-items-start mt-2 mb-2'>
                      <span className="text-dark fs-6 fw-semibold mt-1"> By : </span>
                      <div className="ms-3">
                        <Symbol
                          picture={timeSlot?.todo?.medicalProfessional?.salesPerson?.profilePicture || null}
                          picturePath='/admin/uploads/users/'
                          withText={true}
                          widthClassName='symbol-30px ms-2'
                          text={Functions.fullName(timeSlot?.todo?.medicalProfessional?.salesPerson?.firstName || '', timeSlot?.todo?.medicalProfessional?.salesPerson?.lastName || '')}
                          shortText={Functions.firstLetterUpperCase((timeSlot?.todo?.medicalProfessional?.salesPerson?.firstName || '').charAt(0)) + Functions.firstLetterUpperCase((timeSlot?.todo?.medicalProfessional?.salesPerson?.lastName || '').charAt(0))}
                          description={null}
                          backgroundClass=''
                          textClassName='fs-3 fw-400'
                          isCircular={false}
                          descriptionColor={''}
                          textColor={'text-dark fs-6 ms-2'}
                        />
                      </div>
                      {timeSlot?.todo?.medicalProfessional?.isPremium ? <span className="badge badge-warning ms-3 mt-1 text-dark">VIP</span> : <></>}
                      {timeSlot?.todo?.medicalProfessional?.productSubscriptions.map((subscription, index) => (
                        <div key={`product_subscription_${index}`}>
                          <span className="badge badge-success ms-3 mt-1 text-dark">{subscription.name}</span>
                        </div>
                      ))}
                      {timeSlot?.todo?.medicalProfessional?.productSubscriptions.map((subscription, index) => (
                        <div key={`product_subscription_${index}`}>
                          <span className="badge badge-success ms-3 mt-1 text-dark">{subscription.status}</span>
                        </div>
                      ))}
                    </div> :
                    <></>
                  }
                </div>
                {!isMobile ?
                  <>
                    <div className="ms-auto mt-2">
                      <div className="d-flex align-content-end justify-content-end">
                        <span className='badge bg-med-light-dark fs-8 fw-bold h-30px mt-2 p-1'>
                          <div className="d-flex align-items-center justify-content-center">
                            <span className='badge badge-white w-70px h-25px'>
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr027.svg'
                                className='svg-icon svg-icon-1 svg-icon-primary'
                              />
                              <span className="text-primary fs-6 fw-500">{timeSlot.startDate ? format(new Date(timeSlot.startDate), 'HH:mm') : '--:--'}</span>
                            </span>
                            <span className='badge badge-white w-70px h-25px ms-2'>
                              <KTSVG
                                path='/media/icons/duotune/abstract/reset.svg'
                                className='svg-icon svg-icon-1 svg-icon-danger'
                              />
                              <span className="text-danger fs-6 fw-500">{timeSlot.endDate ? format(new Date(timeSlot.endDate), 'HH:mm') : '--:--'}</span>
                            </span>
                            <span className="text-dark ms-2">-</span>
                            <span className='badge badge-white h-25px ms-2'>
                              <KTSVG
                                path='/media/icons/duotune/general/gen013.svg'
                                className='svg-icon-2 svg-icon-dark'
                              />
                              <span className="text-dark fs-6 fw-500">{timeSlot.duration ? `${timeSlot.duration} min` : ''}</span>
                            </span>
                          </div>
                        </span>
                        <a className={'btn btn-icon btn-success btn-sm w-40px h-40px rounded-3 ms-2'}>
                          <KTSVG
                            path='/media/icons/duotune/abstract/phone.svg'
                            className='svg-icon svg-icon-2 svg-icon-white'
                          />
                        </a>
                        <a className={'btn btn-icon bg-secondary ms-1 btn-sm w-40px h-40px rounded-3'} onClick={() => handleShowCall(timeSlot.todo.medicalProfessional.calls, timeSlot)}>
                          <KTSVG
                            path='/media/icons/duotune/communication/com007.svg'
                            className='svg-icon svg-icon-1 svg-icon-dark'
                          />
                        </a>
                        <DropDownList handleShowCall={handleShowCall} handleShowSugBug={handleShowSugBug} timeSlot={timeSlot} active={false}/>

                      </div>
                    </div>
                  </> :
                  <>
                  </>
                }
              </div>
              <div className="row mb-2 px-3">
                <div className="card card-rounded bg-gray-300">
                  <div className="card-body py-3 px-0">
                    <div className="d-flex align-items-start">
                      <div className="badge badge-dark">
                        <KTSVG
                          path='/media/icons/duotune/communication/com007.svg'
                          className='svg-icon svg-icon-2 svg-icon-white'
                        />
                        <span className="text-white fs-7 fw-400"> 3 calls since 3 hours</span>
                      </div>
                      <div className="ms-auto">
                        <span className="badge badge-warning text-dark h-25px">{reminder?.call?.type}</span>
                        <span className="fs6 fw-500 ms-3">{reminder?.call.date}</span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-start mt-2">
                      <Symbol
                        picture={reminder?.call?.user ? reminder?.call?.user?.profilePicture : null}
                        picturePath='/admin/uploads/users/'
                        withText={true}
                        widthClassName='symbol-35px'
                        text={Functions.fullName(reminder?.call?.user ? reminder?.call?.user?.firstName : '', reminder?.call?.user ? reminder?.call?.user?.lastName : '')}
                        shortText={Functions.firstLetterUpperCase(reminder?.call?.user ? reminder?.call?.user?.firstName.charAt(0) : '') + Functions.firstLetterUpperCase(reminder?.call?.user ? reminder?.call?.user?.lastName.charAt(0) : '')}
                        description={null}
                        backgroundClass='bg-white'
                        textClassName='fs-3 fw-400'
                        isCircular={false}
                        descriptionColor={''}
                        textColor={'text-dark ms-2 fs-6 fw-bold'}
                      />
                    </div>
                    <div className="d-flex justify-content-start mt-3 ms-2">
                      <span className="text-dark fs-6 fw-400">
                        {reminder?.call?.message}
                      </span>
                    </div>
                    <div className="d-flex justify-content-start mt-3">
                      {reminder?.call?.tags.length ? <span className="text-dark fs-6 fw-bold mt-1"> Tag : </span> : <></>}
                      <div className="d-flex align-items-start ms-3">
                        {reminder?.call?.tags && reminder?.call?.tags.map((item, index) => (
                          <Symbol key={`tag_${index}`}
                            picture={item.profilePicture}
                            picturePath='/admin/uploads/users/'
                            withText={true}
                            widthClassName='symbol-30px ms-2'
                            text={Functions.fullName(item.firstName, item.lastName)}
                            shortText={Functions.firstLetterUpperCase(item.firstName.charAt(0)) + Functions.firstLetterUpperCase(item.lastName.charAt(0))}
                            description={null}
                            backgroundClass=''
                            textClassName='fs-3 fw-400'
                            isCircular={false}
                            descriptionColor={''}
                            textColor={'text-dark fs-6 ms-2'}
                          />
                        ))}
                      </div>
                    </div>
                    {reminder ?
                      <>
                        <div className="d-flex flex-column">
                          <span className='badge bg-med-light-orange mt-2'>
                            <span className="text-dark mt-1">Reminder :
                            </span>
                            <div className="ms-2">
                              <KTSVG
                                path='/media/icons/duotune/general/gen014.svg'
                                className='svg-icon svg-icon-1 svg-icon-dark'
                              />
                              <span className="text-dark ms-2">{format(new Date(reminder?.date), 'dd-MM-yyyy') || '--/--/--'}</span>
                            </div>
                            <div className="ms-2">
                              <KTSVG
                                path='/media/icons/duotune/general/gen013.svg'
                                className='svg-icon svg-icon-1 svg-icon-dark'
                              />
                              <span className="text-dark ms-2">{format(new Date(reminder?.date), 'HH:mm') || '--:--'}</span>
                            </div>
                            <div className="ms-2">
                              <KTSVG
                                path='/media/icons/duotune/communication/com013.svg'
                                className='svg-icon svg-icon-2 svg-icon-dark'
                              />
                              <span className="text-dark ms-2">{reminder?.user?.firstName} {reminder?.user?.lastName}</span>
                            </div>
                          </span>
                        </div>
                      </> :
                      <>
                      </>
                    }
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ToDoneSupport
