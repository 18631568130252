import React, { FC, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import ProfileDisplayWrapper from '../modules/hr/profile/components/display/ProfileDisplayWrapper'
import EmployeeWrapper from '../modules/hr/employee/EmployeeWrapper'
import DepartmentWrapper from '../modules/hr/department/DepartmentWrapper'
import TeamWrapper from '../modules/hr/team/TeamWrapper'
import ProfileWrapper from '../modules/hr/profile/ProfileWrapper'
import EmployeeDetailWrapper from '../modules/hr/employee/components/display/EmployeeDetailWrapper'
import ProductFeatureWrapper from '../modules/crm/product-feature/ProductFeatureWrapper'
import ProductWrapper from '../modules/crm/product/ProductWrapper'
import PackageWrapper from '../modules/crm/package/PackageWrapper'
import EquipmentWrapper from '../modules/accounting/equipment/EquipmentWrapper'
import SupplierWrapper from '../modules/accounting/supplier/SupplierWrapper'
import IncentiveWrapper from '../modules/crm/incentive/IncentiveWrapper'
import LocationWrapper from '../modules/crm/location/LocationWrapper'
import LocationCreateWrapper from '../modules/crm/location/components/LocationCreateWrapper'
import EmployeeEquipmentWrapper from '../modules/hr/employee-equipment/EmployeeEquipmentWrapper'
import CustomerDetailsWrapper from '../modules/accounting/customer/components/display/CustomerDetailsWrapper'
import CustomerWrapper from '../modules/accounting/customer/CustomerWrapper'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import TopBarProgress from 'react-topbar-progress-indicator'
import InvoiceWrapper from '../modules/accounting/invoice/InvoiceWrapper'
import EstimateWrapper from '../modules/accounting/estimate/EstimateWrapper'
import PurchaseOrderWrapper from '../modules/accounting/purchase-order/PurchaseOrderWrapper'
import InvoiceCreateWrapper from '../modules/accounting/invoice/components/add/InvoiceCreateWrapper'
import EstimateCreateWrapper from '../modules/accounting/estimate/components/add/EstimateCreateWrapper'
import PurchaseOrderCreateWrapper from '../modules/accounting/purchase-order/components/add/PurchaseOrderCreateWrapper'
import SupplierOrderWrapper from '../modules/accounting/supplier-order/SupplierOrderWrapper'
import CheckAndDraftWrapper from '../modules/accounting/check-and-draft/CheckAndDraftWrapper'
import BankAccountWrapper from '../modules/accounting/bank-account/BankAccountWrapper'
import CashFlowWrapper from '../modules/accounting/cash-flow/CashFlowWrapper'
import SupplierOrderCreateWrapper from '../modules/accounting/supplier-order/components/add/SupplierOrderCreateWrapper'
import SupplierDetailsWrapper from '../modules/accounting/supplier/components/display/SupplierDetailsWrapper'
import CashFlowDetailsWrapper from '../modules/accounting/cash-flow/components/display/CashFlowDetailsWrapper'
import BankAccountDetailsWrapper from '../modules/accounting/bank-account/components/display/BankAccountDetailsWrapper'
import JourneyWrapper from '../modules/crm/journey/JourneyWrapper'
import JourneySalesDetailsWrapper from '../modules/crm/journey/components/display/sales/JourneySalesDetailsWrapper'
import MedicalProfessionalWrapper from '../modules/crm/medical-professional/MedicalProfessionalWrapper'
import MedicalProfessionalDetailsWrapper from '../modules/crm/medical-professional/components/display/MedicalProfessionalDetailsWrapper'
import JourneySupportDetailsWrapper from '../modules/crm/journey/components/display/support/JourneySupportDetailsWrapper'
import SalesTeamDisplayWrapper from '../modules/hr/sales-team/components/display/SalesTeamDisplayWrapper'
import SalesTeamWrapper from '../modules/hr/sales-team/SalesTeamWrapper'
import CallHistoryWrapper from '../modules/crm/journey/components/support/support/call-history/CallHistoryWrapper'
import Can from '../services/casl/Can'

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export function PrivateRoutes() {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='employee' element={
          <Can do="management" on="employee">
            <EmployeeWrapper />
          </Can>
        } />
        <Route path='employee/:id' element={
          <Can do="management" on="employee">
            <EmployeeDetailWrapper />
          </Can>
        } />
        <Route path='department' element={
          <Can do="management" on="userGroup">
            <DepartmentWrapper />
          </Can>
        } />
        <Route path='team' element={
          <Can do="management" on="team">
            <TeamWrapper />
          </Can>
        } />
        <Route path='profile' element={
          <Can do="management" on="userGroup">
            <ProfileWrapper />
          </Can>
        } />
        <Route path='profile/:id' element={
          <Can do="management" on="profile">
            <ProfileDisplayWrapper/>
          </Can>
        }/>
        <Route path='product-feature' element={
          <Can do="management" on="productFeature">
            <ProductFeatureWrapper/>
          </Can>
        }/>
        <Route path='product' element={
          <Can do="management" on="product">
            <ProductWrapper/>
          </Can>
        }/>
        <Route path='package' element={
          <Can do="management" on="package">
            <PackageWrapper/>
          </Can>
        }/>
        <Route path='incentive' element={
          <Can do="management" on="incentive">
            <IncentiveWrapper/>
          </Can>
        }/>
        <Route path='location' element={
          <Can do="management" on="location">
            <LocationWrapper/>
          </Can>
        }/>
        <Route path='location/create' element={
          <Can do="create" on="location">
            <LocationCreateWrapper/>
          </Can>
        }/>
        <Route path='employee/equipment' element={<EmployeeEquipmentWrapper/>}/>
        <Route path='customer' element={
          <Can do="management" on="customer">
            <CustomerWrapper/>
          </Can>
        }/>
        <Route
          path='customer/:id/*'
          element={
            <SuspensedView>
              <Can do="management" on="customer">
                <CustomerDetailsWrapper />
              </Can>
            </SuspensedView>
          }
        />
        <Route path='supplier' element={
          <Can do="management" on="supplier">
            <SupplierWrapper />
          </Can>
        } />
        <Route
          path='supplier/:id/*'
          element={
            <SuspensedView>
              <Can do="management" on="supplier">
                <SupplierDetailsWrapper />
              </Can>
            </SuspensedView>
          }
        />
        <Route path='equipment' element={
          <Can do="management" on="equipment">
            <EquipmentWrapper />
          </Can>
        } />
        <Route path='invoice' element={
          <Can do="management" on="invoice">
            <InvoiceWrapper/>
          </Can>
        }/>
        <Route path='invoice/create' element={
          <Can do="create" on="invoice">
            <InvoiceCreateWrapper/>
          </Can>
        }/>
        <Route path='estimate' element={
          <Can do="management" on="estimate">
            <EstimateWrapper/>
          </Can>
        }/>
        <Route path='estimate/create' element={
          <Can do="create" on="estimate">
            <EstimateCreateWrapper/>
          </Can>
        }/>
        <Route path='purchase-order' element={
          <Can do="management" on="purchaseOrder">
            <PurchaseOrderWrapper/>
          </Can>
        }/>
        <Route path='purchase-order/create' element={
          <Can do="create" on="purchaseOrder">
            <PurchaseOrderCreateWrapper/>
          </Can>
        }/>
        <Route path='order-supplier' element={
          <Can do="management" on="purchaseOrder">
            <SupplierOrderWrapper/>
          </Can>
        }/>
        <Route path='order-supplier/create' element={
          <Can do="create" on="purchaseOrder">
            <SupplierOrderCreateWrapper/>
          </Can>
        }/>
        <Route path='cash-flow' element={
          <Can do="management" on="cashFlow">
            <CashFlowWrapper/>
          </Can>
        }/>
        <Route
          path='cash-flow/:id/*'
          element={
            <SuspensedView>
              <Can do="index" on="cashFlow">
                <CashFlowDetailsWrapper />
              </Can>
            </SuspensedView>
          }
        />
        <Route path='bank-account' element={<BankAccountWrapper/>}/>
        <Route
          path='bank-account/:id/*'
          element={
            <SuspensedView>
              <BankAccountDetailsWrapper />
            </SuspensedView>
          }
        />
        <Route path='check-draft' element={<CheckAndDraftWrapper/>}/>

        <Route path='journey' element={
          <Can do="management" on="journey">
            <JourneyWrapper/>
          </Can>
        }/>
        <Route
          path='journey/sales/:id/*'
          element={
            <SuspensedView>
              <Can do="index" on="journeySales">
                <JourneySalesDetailsWrapper />
              </Can>
            </SuspensedView>
          }
        />
        <Route
          path='journey/support/:id/*'
          element={
            <SuspensedView>
              <Can do="index" on="journeySupport">
                <JourneySupportDetailsWrapper />
              </Can>
            </SuspensedView>
          }
        />
        <Route path='doctor' element={
          <Can do="management" on="medicalProfessional">
            <MedicalProfessionalWrapper/>
          </Can>
        }/>
        <Route
          path='doctor/:id/*'
          element={
            <SuspensedView>
              <Can do="management" on="medicalProfessional">
                <MedicalProfessionalDetailsWrapper />
              </Can>
            </SuspensedView>
          }
        />
        <Route path='sales-team' element={<SalesTeamWrapper />} />
        <Route path='sales-team/:id' element={
          <SalesTeamDisplayWrapper/>
        }/>
        <Route path='call-history' element={
          <Can do="management" on="journey">
            <CallHistoryWrapper/>
          </Can>
        }/>
      </Route>
    </Routes>
  )
}


