import { Action } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { put, takeLatest, select } from 'redux-saga/effects'
import UserService from '../../../services/UserService'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
  Refresh: '[Refresh] Action',
  SetFeatures: '[Set Features] Action',
}

export interface IAuthState {
    user?: IUser,
    features?: IFeature[]|string,
    accessToken?: string,
    refreshToken?: string
}

const initialAuthState: IAuthState = {
  user: undefined,
  features: undefined,
  accessToken: undefined,
  refreshToken: undefined,
}

export const reducer = persistReducer(
  { storage, key: 'v100-demo1-auth', whitelist: ['user', 'features', 'accessToken', 'refreshToken'] },
  (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
    switch (action.type) {
    case actionTypes.Login: {
      const accessToken = action.payload?.accessToken
      const refreshToken = action.payload?.refreshToken
      return { accessToken, refreshToken, user: undefined }
    }

    case actionTypes.Register: {
      const accessToken = action.payload?.accessToken
      return { accessToken, user: undefined }
    }

    case actionTypes.Logout: {
      return initialAuthState
    }

    case actionTypes.UserLoaded: {
      const user = action.payload?.user
      return { ...state, user }
    }

    case actionTypes.SetUser: {
      const user = action.payload?.user
      return { ...state, user }
    }

    case actionTypes.Refresh: {
      const user = action.payload?.user
      const accessToken = action.payload?.accessToken
      const refreshToken = action.payload?.refreshToken
      return { accessToken, refreshToken, user }
    }

    case actionTypes.SetFeatures: {
      const features = action.payload?.features
      return { ...state, features }
    }

    default:
      return state
    }
  },
)

export const actions = {
  login: (accessToken: string, refreshToken: string) => ({ type: actionTypes.Login, payload: { accessToken, refreshToken } }),
  register: (accessToken: string) => ({
    type: actionTypes.Register,
    payload: { accessToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: () => ({
    type: actionTypes.UserRequested,
  }),
  fulfillUser: (user: IUser) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  fulfillFeatures: (features: IFeature[]) => ({ type: actionTypes.SetFeatures, payload: { features } }),
  refreshToken: (accessToken: string, refreshToken: string) => ({ type: actionTypes.Refresh, payload: { accessToken, refreshToken } }),
  setUser: (user: IUser) => ({ type: actionTypes.SetUser, payload: { user } }),
  store: () => ({ type: 'def' }),
}

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.Register, function* logoutSaga() {
    yield put(actions.logout())
  })

  yield takeLatest(actionTypes.Refresh, function* refreshSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const getToken = (state: any) => state.auth.accessToken
    // @ts-ignore
    const token = yield select(getToken)
    const { data: response } = yield UserService.getUserByToken(token)
    yield put(actions.fulfillUser(response.data.user))
    yield put(actions.fulfillFeatures(response.data.features))
  })
}
