import React from 'react'
import { useLayout, usePageData } from '../../../core'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

const BreadcrumbsDisplay: React.FC = () => {
  const { config } = useLayout()
  const { pageTitle, pageBreadcrumbs } = usePageData()

  return (
    <>
      {pageBreadcrumbs &&
                pageBreadcrumbs.length > 0 &&
                config.pageTitle &&
                config.pageTitle.breadCrumbs && (
        <>
          <ul className='breadcrumb breadcrumb-separatorless fs-7 my-1'>
            {Array.from(pageBreadcrumbs).map((item, index) => (
              <li
                className={clsx('breadcrumb-item', {
                  'text-dark': !item.isSeparator && item.isActive,
                  'text-muted': !item.isSeparator && !item.isActive,
                })}
                key={`${item.path}${index}`}
              >
                {!item.isSeparator ? (
                  <Link className='text-muted text-hover-primary' to={item.path}>
                    {item.title}
                  </Link>
                ) : (
                  <span className='bullet bg-gray-400 w-8px h-3px'></span>
                )}
              </li>
            ))}
            <li className='breadcrumb-item text-dark'>{pageTitle}</li>
          </ul>
        </>
      )}</>
  )
}

export default BreadcrumbsDisplay
