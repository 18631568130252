import React from 'react'
import { KTSVG } from '../../../../../../../../_metronic/helpers'
import Functions from '../../../../../../../../setup/utilities/Functions'
import Symbol from '../../../../../../../../_metronic/partials/common/Symbol'
import { Link } from 'react-router-dom'

type Props = {
    journey?: IJourney
    isLoading: boolean,
    handleIsLoading: (isLoading: boolean) => void,
    handleSelectedId: (uuid: string) => void,
    selectedId: string|undefined,
}

const JourneyManagerItem : React.FC<Props> = ({
  journey,
  isLoading,
  handleIsLoading,
  handleSelectedId,
  selectedId,
}) => (
  <>
    {journey?.startDate ?
      <>
        <div className={`card card-rounded bg-med-success mb-3 ${journey.uuid === selectedId ? 'border border-3 border-warning triangle' : ''}`} onClick={() => handleSelectedId(journey!.uuid)}>
          <div className='card-body px-0 py-2'>
            <div className="d-flex">
              <div className="flex-grow-1 ms-4">
                <div className="d-flex justify-content-start align-items-start">
                  <Symbol
                    picture={journey?.employee?.profilePicture || null}
                    picturePath='/admin/uploads/users/'
                    withText={true}
                    widthClassName='symbol-40px'
                    text={Functions.fullName(journey?.employee?.firstName || '', journey?.employee?.lastName || '')}
                    shortText={Functions.firstLetterUpperCase((journey?.employee?.firstName || '').charAt(0)) + Functions.firstLetterUpperCase((journey?.employee?.lastName || '').charAt(0))}
                    description={null}
                    backgroundClass=''
                    textClassName='fs-3 fw-400'
                    isCircular={false}
                    descriptionColor={''}
                    textColor={'text-white ms-3 fs-5 fw-bold'}
                  />
                  <div className="ms-auto">
                    <button
                      type='button'
                      className='btn btn-icon btn-bg-light btn-sm me-1 rounded-circle text-primary h-35px w-35px me-2 border border-success border-1'
                    >
                      <KTSVG
                        path='/media/icons/duotune/abstract/phone.svg'
                        className='svg-icon svg-icon-1 svg-icon-success'
                      />
                    </button>
                    <span className='badge badge-pill badge-danger fs-5 fw-bold border border-1 border-white border-right-0 rounded-pill rounded-end-0 h-40px w-50px'>
                      <span className="text-dark ms-3">{journey?.score ? journey?.score : 0}</span>
                    </span>
                  </div>
                </div>
                <div className="d-flex justify-content-start align-items-start mt-3">
                  <div className='badge bg-med-light-dark badge-sm'>
                    <Link to={`${journey?.uuid}`}>
                      <span className="text-white fw-400 fs-6">{journey?.location ? journey.location.name : ''}</span>
                    </Link>
                  </div>
                </div>
                <div className="d-flex justify-content-start align-items-start mt-3">
                  <span className='badge bg-med-light-dark fs-8 fw-bold h-30px p-1'>
                    <div className="d-flex align-items-center">
                      <span className='badge badge-white w-70px h-25px'>
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr027.svg'
                          className='svg-icon svg-icon-1 svg-icon-primary'
                        />
                        <span className="text-primary fs-6 fw-500">{journey?.startDate ? journey.startDate : '--:--'}</span>
                      </span>
                      <span className='badge badge-white w-70px h-25px ms-2'>
                        <KTSVG
                          path='/media/icons/duotune/abstract/reset.svg'
                          className='svg-icon svg-icon-1 svg-icon-danger'
                        />
                        <span className="text-danger fs-6 fw-500">{journey?.endDate ? journey.endDate : '--:--'}</span>
                      </span>
                    </div>
                  </span>
                  <div className="ms-auto me-3">
                    <div className="d-flex flex-column">
                      <div className="d-flex align-items-start justify-content-start">
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr016.svg'
                          className='svg-icon svg-icon-1 svg-icon-white'
                        />
                        <KTSVG
                          path='/media/icons/duotune/abstract/pharmacist.svg'
                          className='svg-icon svg-icon-1'
                        />
                        <span className="text-white fs-3 ms-2 fw-bold"> {journey?.progression ? journey.progression : 0} </span>
                        <span className="text-gray-700 fs-6 mt-1 ms-2 fw-500"> /{journey?.total ? journey.total : 0} </span>
                      </div>
                      <input type="range" className="input-range-dark" max={journey?.total ? journey.total : 0} min={0} defaultValue={journey?.progression ? journey.progression : 0} disabled={true}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </> :
      <>
        <div className='card card-rounded mb-3'>
          <div className='card-body px-0 py-2'>
            <div className="d-flex">
              <div className="flex-grow-1 ms-4">
                <div className="d-flex justify-content-start align-items-start">
                  <Symbol
                    picture={journey?.employee?.profilePicture || null}
                    picturePath='/admin/uploads/users/'
                    withText={true}
                    widthClassName='symbol-40px'
                    text={Functions.fullName(journey?.employee?.firstName || '', journey?.employee?.lastName || '')}
                    shortText={Functions.firstLetterUpperCase((journey?.employee?.firstName || '').charAt(0)) + Functions.firstLetterUpperCase((journey?.employee?.lastName || '').charAt(0))}
                    description={null}
                    backgroundClass=''
                    textClassName='fs-3 fw-400'
                    isCircular={false}
                    descriptionColor={''}
                    textColor={'text-dark ms-3 fs-5 fw-bold'}
                  />
                  <div className="ms-auto">
                    <button
                      type='button'
                      className='btn btn-icon btn-bg-light btn-sm me-1 rounded-circle text-primary h-35px w-35px me-2 border border-success border-1'
                    >
                      <KTSVG
                        path='/media/icons/duotune/abstract/phone.svg'
                        className='svg-icon svg-icon-1 svg-icon-success'
                      />
                    </button>
                    <span className='badge badge-pill bg-gray-200 fs-5 fw-bold border border-0 rounded-pill rounded-end-0 h-40px w-50px'>
                      <span className="text-dark ms-3">---</span>
                    </span>
                  </div>
                </div>
                <div className="d-flex justify-content-start align-items-start mt-6">
                  <div className='badge bg-med-light-dark badge-sm mt-2'>
                    <Link to={`${journey?.uuid}`}>
                      <span className="text-dark fw-400 fs-6">{journey?.location ? journey.location.name : ''}</span>
                    </Link>
                  </div>
                  <div className="ms-auto me-3">
                    <div className="d-flex flex-column">
                      <div className="d-flex align-items-start justify-content-start">
                        <KTSVG
                          path='/media/icons/duotune/abstract/pharmacist.svg'
                          className='svg-icon svg-icon-1'
                        />
                        <span className="text-gray-500 fs-3 ms-2 fw-bold"> {journey?.progression ? journey.progression : 0} </span>
                        <span className="text-gray-400 fs-6 mt-1 ms-1 fw-500"> /{journey?.total ? journey.total : 0} </span>
                      </div>
                      <input type="range" className="input-range-secondary" max={journey?.total ? journey.total : 0} min={0} defaultValue={journey?.progression ? journey.progression : 0}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    }

  </>
)

export default JourneyManagerItem
