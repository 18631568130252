import React from 'react'
import { KTSVG } from '../../../../../../../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import SymbolMedicalProfessional from '../../../../../../../../../_metronic/partials/common/SymbolMedicalProfessional'
import Symbol from '../../../../../../../../../_metronic/partials/common/Symbol'
import Functions from '../../../../../../../../../setup/utilities/Functions'
import format from 'date-fns/format'
import useWindowDimensions from '../../../../../../../../../_metronic/partials/mobile-check-view/useCheckMobileScreen'

type Props = {
  timeSlot: ITimeSlot,
}

const CallHistoryItem : React.FC<Props> = ({ timeSlot }) => {
  const isMobile = useWindowDimensions()
  return (
    <div className='card card-rounded mt-5'>
      <div className='card-body px-2 py-0'>
        <div className="d-flex">
          <div className="mt-3">
            <div className="d-flex flex-column h-100 pb-2">
              <KTSVG
                path='/media/icons/duotune/abstract/abs009.svg'
                className='svg-icon svg-icon-1 svg-icon-success'
              />
              <div className='vr bg-success opacity-100 w-3px h-100 ms-3'></div>
            </div>
          </div>
          <div className="flex-grow-1 ms-2">
            <div className="d-flex">
              <div className="flex-grow-1">
                <div className="d-flex flex-grow-1 mb-3 mt-2">
                  <Link to={`/doctor/${timeSlot?.todo?.medicalProfessional?.medKey}`}>
                    <SymbolMedicalProfessional
                      picture={timeSlot?.todo?.medicalProfessional?.profilePicture}
                      picturePath='/admin/uploads/users/'
                      withText={true}
                      widthClassName='symbol-40px me-5'
                      text={Functions.fullName(timeSlot?.todo?.medicalProfessional?.firstName || '', timeSlot?.todo?.medicalProfessional?.lastName || '')}
                      shortText={Functions.firstLetterUpperCase(timeSlot?.todo?.medicalProfessional?.firstName || '').charAt(0) + Functions.firstLetterUpperCase(timeSlot?.todo?.medicalProfessional?.lastName || '').charAt(0)}
                      description={timeSlot?.todo?.medicalProfessional?.defaultSpeciality || ''}
                      backgroundClass=''
                      textClassName='fs-3 fw-400'
                      isCircular={false}
                      descriptionColor={'text-gray-500 fs-7 fw-500'}
                      textColor={'text-dark fs-6 fw-bolder'}
                      location={null}
                      withLocation={false}
                      isValid={timeSlot?.todo?.medicalProfessional.isValid || null}
                    />
                  </Link>
                </div>
              </div>
              {!isMobile ?
                <>
                  <div className="ms-auto">
                    <div className="d-flex align-content-end justify-content-end">
                      <span className='badge bg-med-light-dark fs-8 fw-bold h-30px mt-2 p-1'>
                        <div className="d-flex align-items-center justify-content-center">
                          <span className='badge badge-white w-70px h-25px'>
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr027.svg'
                              className='svg-icon svg-icon-1 svg-icon-primary'
                            />
                            <span className="text-primary fs-6 fw-500">{timeSlot.startDate ? format(new Date(timeSlot.startDate), 'HH:mm') : '--:--'}</span>
                          </span>
                          <span className='badge badge-white w-70px h-25px ms-2'>
                            <KTSVG
                              path='/media/icons/duotune/abstract/reset.svg'
                              className='svg-icon svg-icon-1 svg-icon-danger'
                            />
                            <span className="text-danger fs-6 fw-500">{timeSlot.endDate ? format(new Date(timeSlot.endDate), 'HH:mm') : '--:--'}</span>
                          </span>
                          <span className="text-dark ms-2">-</span>
                          <span className='badge badge-white h-25px ms-2'>
                            <KTSVG
                              path='/media/icons/duotune/general/gen013.svg'
                              className='svg-icon-2 svg-icon-dark'
                            />
                            <span className="text-dark fs-6 fw-500">{timeSlot.duration ? `${timeSlot.duration} min` : ''}</span>
                          </span>
                        </div>
                      </span>
                    </div>
                  </div>
                </> : <></> }

            </div>
            {timeSlot.todo?.reminder?.call ?
              <>
                <div className="row mb-2 px-3">
                  <div className="card card-rounded bg-gray-300">
                    <div className="card-body py-3 px-0">
                      <div className="d-flex align-items-start">
                        <div className="badge badge-dark">
                          <KTSVG
                            path='/media/icons/duotune/communication/com007.svg'
                            className='svg-icon svg-icon-2 svg-icon-white'
                          />
                          <span className="text-white fs-7 fw-400"> 3 calls since 3 hours</span>
                        </div>
                        <div className="ms-auto">
                          <span className="badge badge-warning text-dark h-25px ms-3"> {timeSlot?.todo?.reminder?.call?.type}</span>
                          <span className="fs-6 fw-500 ms-3">{timeSlot?.todo?.reminder?.call?.date}</span>
                        </div>
                      </div>
                      <div className="d-flex justify-content-start mt-2">
                        <Symbol
                          picture={timeSlot?.todo?.reminder?.call?.user ? timeSlot?.todo?.reminder?.call?.user?.profilePicture : null}
                          picturePath='/admin/uploads/users/'
                          withText={true}
                          widthClassName='symbol-35px'
                          text={Functions.fullName(timeSlot?.todo?.reminder?.call?.user ? timeSlot?.todo?.reminder?.call?.user?.firstName : '', timeSlot?.todo?.reminder?.call?.user ? timeSlot?.todo?.reminder?.call?.user?.lastName : '')}
                          shortText={Functions.firstLetterUpperCase(timeSlot?.todo?.reminder?.call?.user ? timeSlot?.todo?.reminder?.call?.user?.firstName.charAt(0) : '') + Functions.firstLetterUpperCase(timeSlot?.todo?.reminder?.call?.user ? timeSlot?.todo?.reminder?.call?.user?.lastName.charAt(0) : '')}
                          description={null}
                          backgroundClass='bg-white'
                          textClassName='fs-3 fw-400'
                          isCircular={false}
                          descriptionColor={''}
                          textColor={'text-dark ms-2 fs-6 fw-bold'}
                        />
                      </div>
                      <div className="d-flex justify-content-start mt-3 ms-2">
                        <span className="text-dark fs-5 fw-400">
                          {timeSlot?.todo?.reminder?.call?.message}
                        </span>
                      </div>
                      <div className="d-flex justify-content-start mt-3">
                        {timeSlot?.todo?.reminder?.call?.tags.length ? <span className="text-dark fs-6 fw-bold mt-1"> Tag : </span> : <></>}
                        <div className="d-flex align-items-start ms-3">
                          {timeSlot?.todo?.reminder?.call?.tags && timeSlot?.todo?.reminder?.call?.tags.map((item, index) => (
                            <Symbol key={`tag_${index}`}
                              picture={item.profilePicture}
                              picturePath='/admin/uploads/users/'
                              withText={true}
                              widthClassName='symbol-30px ms-2'
                              text={Functions.fullName(item.firstName, item.lastName)}
                              shortText={Functions.firstLetterUpperCase(item.firstName.charAt(0)) + Functions.firstLetterUpperCase(item.lastName.charAt(0))}
                              description={null}
                              backgroundClass=''
                              textClassName='fs-3 fw-400'
                              isCircular={false}
                              descriptionColor={''}
                              textColor={'text-dark fs-6 ms-2'}
                            />
                          ))}
                        </div>
                      </div>
                      {timeSlot?.todo?.reminder ?
                        <>
                          <div className="d-flex flex-column">
                            <span className='badge bg-med-light-orange mt-2'>
                              <span className="text-dark mt-1">Reminder :
                              </span>
                              <div className="ms-2">
                                <KTSVG
                                  path='/media/icons/duotune/general/gen014.svg'
                                  className='svg-icon svg-icon-1 svg-icon-dark'
                                />
                                <span className="text-dark ms-2">{format(new Date(timeSlot?.todo?.reminder?.date), 'dd-MM-yyyy') || '--/--/--'}</span>
                              </div>
                              <div className="ms-2">
                                <KTSVG
                                  path='/media/icons/duotune/general/gen013.svg'
                                  className='svg-icon svg-icon-1 svg-icon-dark'
                                />
                                <span className="text-dark ms-2">{format(new Date(timeSlot?.todo?.reminder?.date), 'HH:mm') || '--:--'}</span>
                              </div>
                              <div className="ms-2">
                                <KTSVG
                                  path='/media/icons/duotune/communication/com013.svg'
                                  className='svg-icon svg-icon-2 svg-icon-dark'
                                />
                                <span className="text-dark ms-2">{timeSlot?.todo?.reminder?.user?.firstName} {timeSlot?.todo?.reminder?.user?.lastName}</span>
                              </div>
                            </span>
                          </div>
                        </> :
                        <>
                        </>
                      }
                    </div>

                  </div>
                </div>
              </> :
              <>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default CallHistoryItem
