import React from 'react'
import { useLayout, usePageData } from '../../../../../_metronic/layout/core'
import clsx from 'clsx'
import { MobileView, BrowserView } from 'react-device-detect'
import BreadcrumbsDisplay from '../../../../../_metronic/layout/components/header/page-title/BreadcrumbsDisplay'

type Props = {
    handleShow: (location: ILocation | undefined | null, action: string) => void
}

const LocationCreateToolbar : React.FC<Props> = ({ handleShow }) => {
  const { classes } = useLayout()
  const { pageTitle } = usePageData()

  return (
    <>
      <div
        id='kt_page_title'
        data-kt-swapper='true'
        data-kt-swapper-mode='prepend'
        data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
        className={clsx('page-title d-flex', classes.pageTitle.join(' '))}
      >
        <BrowserView>
          {/* begin::Title */}
          {pageTitle && (
            <h1 className='d-flex align-items-center text-dark fw-bolder my-1 fs-3'>
              {pageTitle}
            </h1>
          )}
          {/* end::Title */}
        </BrowserView>
        <MobileView>
          <div className='d-flex flex-wrap'>
            {pageTitle && (
              <h1 className='d-flex align-items-center text-dark fw-bolder my-1 fs-3 me-5'>
                {pageTitle}
              </h1>
            )}
            <BreadcrumbsDisplay />
          </div>
        </MobileView>
      </div>
      {/* <div className='d-flex align-common-center py-1'>*/}
      {/*  <div className='me-2'>*/}
      {/*    <Link className='btn btn-sm btn-success'*/}
      {/*      to={{ pathname: '/location/' }}>*/}
      {/*      Retour*/}
      {/*    </Link>*/}
      {/*  </div>*/}
      {/* </div>*/}
    </>
  )
}

export default LocationCreateToolbar
