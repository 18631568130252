import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import EmployeeService from '../../../../services/Hr/EmployeeService'
import Select, { MultiValue } from 'react-select'
import TeamService from '../../../../services/Hr/TeamService'

const teamSchema = Yup.object().shape({
  name: Yup.string()
    .required('Ce champs est obligatoire'),
  employees: Yup.array()
    .required('Ce champs est obligatoire')
    .min(1, 'Ce champs est obligatoire'),
})

type Inputs = {
  name: string,
  employees: string[],
}

type Props = {
  show: boolean,
  handleClose: () => void,
  handleIsLoading: (isLoading: boolean) => void,
  teamSelected: ITeam|null|undefined,
  action: string,
}

const CreateTeam: React.FC<Props> = ({
  show,
  handleClose,
  handleIsLoading,
  teamSelected,
  action,
}) => {
  const intl = useIntl()
  const config = {
    resolver: yupResolver(teamSchema),
    defaultValues: {
      name: '',
    },
  }
  const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm<Inputs>(config)

  // Set values in edit action
  if (action === 'edit') {
    setValue('name', teamSelected?.name || '')
    if (teamSelected?.employees) {
      setValue('employees', teamSelected.employees.map((employee) => employee.uuid))
    }
  } else {
    setValue('name', '')
    setValue('employees', [])
  }

  const [optionsEmployee, setOptionsEmployee] = useState<{value: string, label: string}[]>([])

  const onChangeSelect = (selectedOption: MultiValue<{value: string, label: string}>) => {
    const values: string[] = []
    selectedOption.forEach((option: {value: string, label: string}) => {
      values.push(option.value)
    })
    setValue('employees', values)
  }

  useEffect(() => {
    EmployeeService.selectEmployees().then((response) => {
      setOptionsEmployee(response.data.data)
    })
  }, [])

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (action === 'create') {
      TeamService.createTeam(data).then(() => {
        handleIsLoading(true)
        handleClose()
        reset()
      })
    } else {
      TeamService.updateTeam(teamSelected?.uuid, data).then(() => {
        handleIsLoading(true)
        handleClose()
        reset()
      })
    }
  }

  return (
    <Modal show={show} onHide={handleClose} dialogClassName={'mw-1000px'}>
      <Modal.Header closeButton>
        <Modal.Title>{intl.formatMessage({ id: 'team.create' })}</Modal.Title>
      </Modal.Header>
      <form className='form w-100' onSubmit={handleSubmit(onSubmit)} noValidate id='create-team-form'>
        <Modal.Body>
          <div className='card card-p-0 shadow-none'>
            <div className='card-body'>
              <div className='row mb-5'>
                <div className='col-md-4'>
                  <div className='form-label mt-3 fs-6 text-dark required'>Nom de l'équipe</div>
                </div>
                <div className='col-md-8'>
                  <input
                    {...register('name')}
                    placeholder='Nom du département'
                    className='form-control form-control-solid'
                    type='text'
                    name='name'
                    autoComplete='off'
                  />
                  {errors.name && errors.name.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.name.message}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col-md-4'>
                  <div className='form-label mt-3 fs-6 text-dark required'>Choisir des employés</div>
                </div>
                <div className='col-md-8'>
                  <Select
                    placeholder={'Choisir des employés'}
                    defaultValue={teamSelected?.employees?.map((employee) => ({ value: employee.uuid, label: employee.firstName + ' ' + employee.lastName }))}
                    options={optionsEmployee}
                    isMulti={true}
                    onChange={onChangeSelect}
                  />
                  {errors.employees && errors.employees.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.employees.message}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn btn-sm btn-secondary'
            onClick={handleClose}
          >
            {intl.formatMessage({ id: 'action.close' })}
          </button>
          <button
            type='submit'
            className='btn btn-sm btn-secondary'
          >
            {intl.formatMessage({ id: 'action.save' })}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default CreateTeam
