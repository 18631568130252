import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import WorkflowService from '../../../../services/WorkflowService'
import TableHeaderSolid from '../../../../../_metronic/partials/med-table/TableHeaderSolid'
import { KTSVG } from '../../../../../_metronic/helpers'
import EstimateDetails from '../../estimate/components/EstimateDetails'
import PaginationBar from '../../../../../_metronic/partials/pagination/PaginationBar'


type Props = {
  supplierOrders: ISupplierOrder[],
  totalPages: number,
  currentPage: number,
  isLoading: boolean,
  handleCurrentPage: (page: number) => void,
  setIsLoading: (isLoading: boolean) => void
}

const SupplierOrderListing : React.FC<Props> = ({
  supplierOrders,
  totalPages,
  currentPage,
  isLoading,
  handleCurrentPage,
  setIsLoading,
}) => {
  const intl = useIntl()
  const headers = [
    {
      minWidth: 'w-50px',
      text: intl.formatMessage({ id: 'accounting.supplier.order.entity.reference' }),
    },
    {
      minWidth: 'w-100px',
      text: intl.formatMessage({ id: 'accounting.supplier.order.entity.customer' }),
    },
    {
      minWidth: 'w-50px',
      text: intl.formatMessage({ id: 'accounting.supplier.order.entity.issueDate' }),
    },
    {
      minWidth: 'w-50px',
      text: intl.formatMessage({ id: 'accounting.supplier.order.entity.netAmount' }),
    },
    {
      minWidth: 'w-50px',
      text: intl.formatMessage({ id: 'accounting.supplier.order.entity.grossAmount' }),
    },
    {
      minWidth: 'w-50px',
      text: intl.formatMessage({ id: 'accounting.supplier.order.entity.status' }),
    },
    {
      minWidth: 'w-50px',
      text: intl.formatMessage({ id: 'accounting.supplier.order.entity.document' }),
    },
    {
      minWidth: 'w-50px',
      text: intl.formatMessage({ id: 'actions' }),
    },
  ]

  const [showDetails, setShowDetails] = useState<boolean>()
  const [workflowInstanceTasks, setWorkflowInstanceTasks] = useState<IWorkflowInstanceTask[]>()
  const handleShowDetails = (workflowInstanceTask: IWorkflowInstanceTask[]) => {
    setShowDetails(true)
    setWorkflowInstanceTasks(workflowInstanceTask)
  }
  const handleCloseDetails = () => setShowDetails(false)

  const changeStatus = (value: string, workflowInstance: IWorkflowInstance, transition: string) => {
    const data = [
      {
        field: 'status',
        type: 'string',
        old_value: workflowInstance.status,
        new_value: value,
        isArrayField: false,
        isNewEntityInstance: workflowInstance.workflowType === 'convert_process',
      },
    ]

    WorkflowService.changeWorkflowInstance(workflowInstance.uuid, transition, data).then(() => {
      setIsLoading(true)
    })
  }

  useEffect(() => {}, [workflowInstanceTasks])

  return (
    <>
      <div className={`card mb-5 ${isLoading ? 'overlay overlay-block' : ''}`}>
        <div className={`card-body py-3 ${isLoading ? 'overlay-wrapper' : ''}`}>
          <div className="row pt-5">
            <div className="table-responsive">
              <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                <TableHeaderSolid headers={headers} />
                <tbody>
                  {supplierOrders && supplierOrders.length > 0 ? (
                    supplierOrders.map((supplierOrder, index) => (
                      <tr key={`supplier_order__${index}`}>
                        <td>
                          <span className="text-muted ms-2">{supplierOrder.reference}</span>
                        </td>
                        <td>
                          <span className="text-muted">{supplierOrder.supplier ? supplierOrder.supplier.name : 'Pas de fournisseur'}</span>
                        </td>
                        <td>
                          <span className="text-muted">{supplierOrder.paymentDate}</span>
                        </td>
                        <td>
                          <span className="text-muted">{supplierOrder.netAmount}</span>
                        </td>
                        <td>
                          <span className="text-muted">{supplierOrder.grossAmount}</span>
                        </td>
                        <td>
                          {supplierOrder.workflowInstances && supplierOrder.workflowInstances.map((workflowInstance, index) => (
                            <div key={`workflow_${index}`}>
                              {workflowInstance.workflowType === 'supplier_order_workflow' ?
                                <>
                                  <button
                                    type='button'
                                    className={`btn btn-light btn-sm w-125px ${workflowInstance.status === 'draft' ? 'btn-light-warning' : workflowInstance.status === 'created' ? 'btn-light-info' : workflowInstance.status === 'valid' ? 'btn-light-success' : 'btn-light-danger'}`}
                                    data-kt-menu-trigger='click'
                                    data-kt-menu-placement='bottom-end'
                                    data-kt-menu-flip="top-end"
                                  >
                                    {workflowInstance.status}
                                    <KTSVG
                                      path='/media/icons/duotune/arrows/arr072.svg'
                                      className='svg-icon-4 svg-icon-gray-500 me-1'
                                    />
                                  </button>
                                  <div
                                    className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                                    data-kt-menu='true'
                                  >
                                    {workflowInstance.status === 'created' ?
                                      <>
                                        <div className='menu-item'>
                                          <a className='menu-link' defaultValue="canceled" onClick={() => changeStatus('cancelled', workflowInstance, 'to_canceled')}>
                                                  Cancel
                                          </a>
                                        </div>
                                        <div className='menu-item'>
                                          <a className='menu-link' defaultValue="accepted" onClick={() => changeStatus('valid', workflowInstance, 'to_valid')}>
                                                  Validate
                                          </a>
                                        </div>
                                      </> :
                                      <>
                                        {workflowInstance.status === 'valid' ?
                                          <>
                                            <div className='menu-item'>
                                              <a className='menu-link' onClick={() => changeStatus('cancelled', workflowInstance, 'to_canceled')}>
                                                        Cancel
                                              </a>
                                            </div>
                                          </> :
                                          <>
                                            {workflowInstance.status === 'canceled' ?
                                              <>
                                                <div className='menu-item'>
                                                  <a className='menu-link' onClick={() => changeStatus('draft', workflowInstance, 'to_draft')}>
                                                              Reactivate
                                                  </a>
                                                </div>
                                              </> :
                                              <>
                                                {workflowInstance.status === 'draft' ?
                                                  <>
                                                    <div className='menu-item'>
                                                      <a className='menu-link' onClick={() => changeStatus('created', workflowInstance, 'to_created')}>
                                                                    Create
                                                      </a>
                                                    </div>
                                                  </> :
                                                  <>
                                                  </>
                                                }
                                              </>
                                            }
                                          </>
                                        }
                                      </>
                                    }

                                  </div>
                                  <a onClick={() => handleShowDetails(workflowInstance.workflowInstanceTasks)} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm ms-5">
                                    <KTSVG
                                      path='/media/icons/duotune/general/gen038.svg'
                                      className='svg-icon svg-icon-2'
                                    />
                                  </a>
                                </> :
                                <></>
                              }
                            </div>
                          ))}
                        </td>
                        <td>
                          <span className="text-muted">-</span>
                        </td>
                        <td className="text-end">
                          <div key={`workflow_convert_${index}`}>
                            <>
                              <button
                                type='button'
                                className='btn btn-light btn-active-light-primary btn-sm w-100px'
                                data-kt-menu-trigger='click'
                                data-kt-menu-placement='bottom-end'
                                data-kt-menu-flip="top-end"
                              >
                                            Options
                                <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
                              </button>
                              <div
                                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                                data-kt-menu='true'
                              >
                                <div className='menu-item'>
                                  <a className='menu-link'>
                                                Upload Invoice
                                  </a>
                                </div>
                              </div>
                            </>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={7}>
                        <div className='d-flex text-center w-100 fs-4 align-content-center justify-content-center mt-5'>
                          {intl.formatMessage({ id: 'action.not.found' })}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <EstimateDetails showDetails={showDetails ? showDetails : false} handleCloseDetails={handleCloseDetails} workflowInstanceTasks={workflowInstanceTasks ? workflowInstanceTasks : []}/>
        {totalPages > 1 ? (
          <PaginationBar
            totalPages={totalPages}
            currentPage={currentPage}
            handleCurrentPage={handleCurrentPage}
          />
        ) : (<></>)}
        {isLoading &&
              <div className="overlay-layer rounded bg-dark bg-opacity-5">
                <div
                  className="spinner-border text-primary"
                  role="status"
                >
                  <span className="visually-hidden">{intl.formatMessage({ id: 'action.loading' })}</span>
                </div>
              </div>
        }
      </div>
    </>
  )
}

export default SupplierOrderListing
