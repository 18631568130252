import apiService from '../../../setup/axios/ApiService'

const prefixURL = 'contact'

const createContact = (data: any, uuid: string, type: string) => apiService.post(`${prefixURL}/${uuid}/${type}`, data)

const updateContact = (data: any, uuid: string) => apiService.put(`${prefixURL}/${uuid}/edit`, data)

const deleteContact = (uuid: string|undefined) => apiService.deletes(`${prefixURL}/${uuid}/delete`)

const updateStatus = (uuid:string|undefined, data: any) => apiService.put(`${prefixURL}/${uuid}/isDefault`, data)

const ContactService = {
  createContact,
  updateContact,
  deleteContact,
  updateStatus,
}

export default ContactService
