import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import Functions from '../../../../../../../setup/utilities/Functions'
import ContactItem from '../../../../contact/ContactItem'
import SupplierService from '../../../../../../services/Accounting/SupplierService'
import ContactCreate from '../../../../contact/add/ContactCreate'


const Overview : React.FC = () => {
  const { id } = useParams()
  const [supplier, setSupplier] = useState<ISupplier>()
  const [selectedContact, setSelectedContact] = useState<IContact|null>()
  const [actionContact, setActionContact] = useState<string>()
  const [actionAddress, setActionAddress] = useState<string>()
  const [isLoadingContact, setIsLoadingContact] = useState(true)
  const [showContact, setShowContact] = useState(false)
  const intl = useIntl()
  const [showAddress, setShowAddress] = useState(false)
  const [selectedAddress, setSelectedAddress] = useState<IAddress>()
  const handleShowAddress = (selectedAddress: IAddress|null, action : string) => {
    if (selectedAddress) {
      setSelectedAddress(selectedAddress)
    }
    setActionAddress(action)
    setShowAddress(true)
  }
  const handleCloseAddress = () => setShowAddress(false)
  const handleIsLoadingContact = (isLoading: boolean) => {
    setIsLoadingContact(isLoading)
  }

  const handleShowContact = (contact: IContact|null, action: string) => {
    setSelectedContact(contact)
    setActionContact(action)
    setShowContact(true)
  }

  useEffect(() => {}, [selectedAddress])

  const handleCloseContact = async (isSubmit: boolean) => {
    if (!isSubmit) {
      const isConfirmed = await Functions.sweetAlert(
        '<span class="mt-5">' + intl.formatMessage({ id: 'action.message' }) + '</span>',
        'warning',
        true,
        intl.formatMessage({ id: 'action.cancel' }),
        intl.formatMessage({ id: 'action.return' }),
        'btn btn-primary mt-10 mb-10',
        'btn btn-active-light mt-10 mb-10',
      )
      if (isConfirmed) {
        setShowContact(false)
      }
    } else {
      setShowContact(false)
    }
  }

  useEffect(() => {
    SupplierService.getSupplier(id).then((response) => {
      setSupplier(response.data.data)
      setIsLoadingContact(false)
    })
  }, [isLoadingContact, selectedContact])

  return (

    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>{intl.formatMessage({ id: 'accounting.supplier.overview' })}</h3>
          </div>
          <Link to={`/supplier/${supplier?.uuid}/settings`} className='btn btn-primary align-self-center'>
            {intl.formatMessage({ id: 'action.edit' })}
          </Link>
        </div>
        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: 'accounting.supplier.entity.name' })}</label>
            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{supplier?.name}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: 'accounting.supplier.entity.taxRegistrationNumber' })}</label>
            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>{supplier?.taxRegistrationNumber}</span>
              <span className='badge badge-success'>Verified</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: 'accounting.supplier.entity.commercialRegisterNumber' })}</label>
            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>{supplier?.commercialRegisterNumber}</span>
              <span className='badge badge-success'>Verified</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: 'accounting.supplier.entity.country' })}</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{supplier?.country ? supplier.country.name : '-'}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: 'accounting.supplier.entity.location' })}</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{supplier?.location ? supplier.location.name : '-'}</span>
            </div>
          </div>
        </div>
      </div>
      <div className='row gy-10 gx-xl-10'>
        {supplier?.contacts && supplier?.contacts.map((contact, index) => (
          <div className='col-xxl-4' key={`contact_${index}`}>
            <ContactItem isCreate={false} contact={contact} page='list' handleIsLoading={handleIsLoadingContact} handleShow={handleShowContact} isLoading={isLoadingContact}/>
          </div>
        ))}
        <div className='col-xxl-4'>
          <ContactItem isCreate={true} handleShow={handleShowContact} isLoading={isLoadingContact} handleIsLoading={handleIsLoadingContact} page='list' contact={null}/>
        </div>
      </div>
      <ContactCreate
        supplier={supplier}
        customer={null}
        handleIsLoading={handleIsLoadingContact}
        contact={selectedContact}
        actionAddress={actionAddress}
        actionContact={actionContact}
        handleCloseAddress={handleCloseAddress}
        handleCloseContact={handleCloseContact}
        handleShowAddress={handleShowAddress}
        showAddress={showAddress}
        showContact={showContact}
        selectedAddress={selectedAddress}
      />
    </>
  )
}

export default Overview
