import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../../../../../_metronic/helpers'
import Symbol from '../../../../../../../_metronic/partials/common/Symbol'
import Functions from '../../../../../../../setup/utilities/Functions'
import { useParams } from 'react-router-dom'
import MedicalProfessionalService from '../../../../../../services/Crm/MedicalProfessionalService'

const SmartList : React.FC = () => {
  const { id } = useParams()
  const [medicalProfessional, setMedicalProfessional] = useState<IMedicalProfessional>()


  useEffect(() => {
    MedicalProfessionalService.getMedicalProfessional(id).then((response) => {
      setMedicalProfessional(response.data.data)
    })
  }, [])
  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <KTSVG
              path='/media/icons/duotune/communication/com007.svg'
              className='svg-icon-1 svg-icon-dark'
            />
            <h2 className='fw-bolder ms-2'>Smart List</h2>
          </div>
        </div>
        <div className='card-body'>
          <div className="d-flex justify-content-start">
            <input className="form-check-input form-control-solid mt-8" type="checkbox" value="call" name="type"/>
            <div className="d-flex flex-grow-1 ms-2">
              <div className="d-flex flex-column">
                <div className="d-flex align-items-start">
                  <Symbol
                    picture={medicalProfessional?.profilePicture || null}
                    picturePath='/admin/uploads/users/'
                    withText={true}
                    widthClassName='symbol-40px me-5'
                    text={Functions.fullName(medicalProfessional?.firstName || '', medicalProfessional?.lastName || '')}
                    shortText={Functions.firstLetterUpperCase(medicalProfessional?.firstName.charAt(0) || '') + Functions.firstLetterUpperCase(medicalProfessional?.lastName.charAt(0) || '')}
                    description={medicalProfessional?.defaultSpeciality || ''}
                    backgroundClass=''
                    textClassName='fs-3 fw-400'
                    isCircular={false}
                    descriptionColor={'text-muted fs-6 fw-500'}
                    textColor={'text-dark fs-5 fw-500'}
                  />
                  <button
                    type='button'
                    className='btn btn-icon btn-sm'
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen045.svg'
                      className='svg-icon svg-icon-1 svg-icon-dark'
                    />
                  </button>
                </div>
                <div className="d-flex align-items-start mt-2 ms-16">
                  <span className='badge badge-warning text-dark h-20px fw-500 ms-2'> Med Pro</span>
                  <span className='badge badge-danger h-20px fw-500 ms-2'> Expired</span>
                  <span className='badge badge-success border border-1 border-white h-20px fw-500 ms-2'> Paid</span>
                </div>
              </div>
            </div>
            <div className="ms-auto mt-5">
              <span className="badge badge-dark h-25px">
                <KTSVG
                  path='/media/icons/duotune/maps/map003.svg'
                  className='svg-icon svg-icon-2 svg-icon-white'
                />
                <span className="text-white fs-6 ms-1">100 m</span>

              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SmartList
