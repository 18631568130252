import apiService from '../../../setup/axios/ApiService'

const prefixURL = 'customer'

const getAllCustomers = (currentPage:number, limit:number) => apiService.get(`${prefixURL}/`, {
  page: currentPage,
  limit,
})

const selectCustomer = (name: string) => apiService.get(`${prefixURL}/select/select-customer/`, {
  name,
})

const createCustomer = (data: any) => apiService.post(`${prefixURL}/`, data)

const updateCustomer = (uuid: string|undefined, data: any) => apiService.put(`${prefixURL}/${uuid}/edit`, data)

const deleteCustomer = (uuid: string|undefined) => apiService.deletes(`${prefixURL}/${uuid}/delete`)

const getCustomer = (uuid:string|undefined) => apiService.get(`${prefixURL}/${uuid}`)

const CustomerService = {
  getAllCustomers,
  createCustomer,
  selectCustomer,
  updateCustomer,
  deleteCustomer,
  getCustomer,
}

export default CustomerService
