import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../../../../../../_metronic/helpers'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import DropDownEmployees from './DropDownEmployees'
import EmployeeService from '../../../../../../../services/Hr/EmployeeService'
import SugBugService from '../../../../../../../services/Crm/SugBugService'

type Props = {
  show: boolean,
  handleClose: () => void,
  timeSlot: ITimeSlot|null,
  medKey: string|null
}

const sugBugSchema = Yup.object().shape({
  type: Yup.string()
    .required('Ce champs est obligatoire'),
  product: Yup.string()
    .required('Ce champs est obligatoire'),
  message: Yup.string()
    .required('Ce champs est obligatoire'),
  tags: Yup.array()
    .notRequired(),
})

type Inputs = {
  type: string,
  product: string,
  message: string,
  tags: any,
}

const SugBugCreate : React.FC<Props> = ({ show, handleClose, timeSlot, medKey }) => {
  const [employees, setEmployees] = useState<{ uuid: string, picture: string, firstName: string, lastName:string, isChecked: boolean }[]>([])
  const [checkedEmployees, setCheckedEmployees] = useState<{ uuid: string, picture: string, firstName: string, lastName:string, isChecked: boolean }[]>([])

  const config = {
    resolver: yupResolver(sugBugSchema),
    defaultValues: {
      type: '',
      product: '',
      message: '',
      tags: '',
    },
  }

  const { register, handleSubmit, formState: { errors }, setValue, watch, reset } = useForm<Inputs>(config)


  const addEmployee = (employee: { uuid: string, picture: string, firstName: string, lastName:string, isChecked: boolean }) => {
    const newEmployees = [...checkedEmployees]
    newEmployees.push(employee)
    setValue('tags', newEmployees)
    setCheckedEmployees(newEmployees)
  }

  const removeEmployee = (index: number, uuid : string) => {
    const newEmployees = [...checkedEmployees]
    newEmployees.splice(index, 1)
    setValue('tags', newEmployees)
    setCheckedEmployees(newEmployees)

    const removedEmployee = checkedEmployees[index]
    const employee = employees.find((element) => element.uuid === removedEmployee.uuid)
    // @ts-ignore
    const i = employees.indexOf(employee)
    const oldEmployees = [...employees]
    oldEmployees[i]['isChecked'] = false
    setEmployees(oldEmployees)
  }

  const onChangeEmployees = (employee: { uuid: string, picture: string, firstName: string, lastName:string, isChecked: boolean }, index: number) => {
    const oldEmployees = [...employees]
    oldEmployees[index]['isChecked'] = true
    setEmployees(oldEmployees)
  }

  const checkedType = watch('type')
  const checkedProduct = watch('product')

  const onChangeType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('type', event.target.value)
  }

  const onChangeProduct = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('product', event.target.value)
  }

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const sugBug = {
      type: data.type,
      title: data.product,
      message: data.message,
      tags: data.tags,
      medicalProfessional: medKey,
    }
    SugBugService.createSugBug(sugBug).then(() => {
      handleClose()
      reset()
    })
  }

  useEffect(() => {
    EmployeeService.getAllEmployees().then((response) => {
      response.data.data.map((item : any) =>
        employees.push({ uuid: item.uuid, picture: item.profilePicture, firstName: item.firstName, lastName: item.lastName, isChecked: false }),
      )
    })
  }, [])

  return (
    <Modal show={show} onHide={handleClose} dialogClassName={'mw-700px modal-dialog modal-dialog-centered'}>
      <Modal.Header className="py-4" closeButton>
        <Modal.Title>
          <KTSVG
            path='/media/icons/duotune/general/gen045.svg'
            className='svg-icon-1 svg-icon-warning'
          />
          <span className="text-dark fs-2 fw-bold ms-5">Ajouter Sugbug</span>
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)} noValidate id='create-call-form'>
        <Modal.Body>
          <div className="row">
            <div className="col-3">
              <label className="fw-semibold fs-6 mb-2">Type</label>
            </div>
            <div className="col-9">
              <div className="d-flex align-items-start">
                <div className='d-flex align-items-start'>
                  <div className="form-check form-check-custom form-check-solid mt-2">
                    <input checked={checkedType === 'suggestion'} className="form-check-input rounded-circle" type="checkbox" value="suggestion" name="type" onChange={onChangeType}/>
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                        Suggestion
                    </label>
                  </div>
                  <div className="form-check form-check-custom form-check-solid ms-5 mt-2">
                    <input checked={checkedType === 'bug'} className="form-check-input rounded-circle" type="checkbox" value="bug" name="type" onChange={onChangeType}/>
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                        Bug
                    </label>
                  </div>
                </div>
              </div>
            </div>

            {errors.type && errors.type.message && (
              <div className='fv-plugins-message-container text-danger'>
                <span role='alert'>{errors.type.message}</span>
              </div>
            )}
          </div>

          <div className="row mt-5">
            <div className="col-3">
              <label className="fw-semibold fs-6 mb-2">Produit</label>

            </div>
            <div className="col-9">
              <div className="d-flex align-items-start">
                <div className="form-check form-check-custom form-check-solid">
                  <input checked={checkedProduct === 'Site Web'} className="form-check-input rounded-circle" type="checkbox" value="Site Web" name="product" onChange={onChangeProduct}/>
                  <label className="form-check-label" htmlFor="flexCheckDefault">
                      Site Web
                  </label>
                </div>
                <div className="form-check form-check-custom form-check-solid ms-3">
                  <input checked={checkedProduct === 'Application'} className="form-check-input rounded-circle" type="checkbox" value="Application" name="product" onChange={onChangeProduct}/>
                  <label className="form-check-label" htmlFor="flexCheckDefault">
                      Application
                  </label>
                </div>
              </div>
            </div>

            {errors.product && errors.product.message && (
              <div className='fv-plugins-message-container text-danger'>
                <span role='alert'>{errors.product.message}</span>
              </div>
            )}
          </div>

          <div className='separator bg-secondary mt-5 mb-5'></div>
          <div className="d-flex flex-row">
            <div className="col-3">
              <label className="fw-semibold fs-6 mt-5">Taguer un collègue</label></div>
            <div className="col-9">
              <DropDownEmployees
                drop={'down'}
                toggleClassName={'form-control border border-1 border-gray-200 min-height-50px'}
                employees={employees}
                onChangeEmployees={onChangeEmployees}
                addEmployee={addEmployee}
                checkedEmployees={checkedEmployees}
                removeEmployee={removeEmployee}
              />
            </div>
          </div>
          <div className='separator bg-secondary mt-5'></div>
          <div className="fv-row mb-5 fv-plugins-icon-container mt-3">
            <textarea
              {...register('message')}
              placeholder={'Message'}
              className={'form-control form-control-lg'}
              autoComplete='off'
            />
            {errors.message && errors.message.message && (
              <div className='fv-plugins-message-container text-danger'>
                <span role='alert'>{errors.message.message}</span>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="py-2">
          <button
            type='button'
            className='btn btn-light'
            onClick={handleClose}
          >
              Annuler
          </button>
          <button
            type='submit'
            className='btn btn-primary'
          >
              Ajouter
          </button>

        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default SugBugCreate
