import React, { useEffect, useState } from 'react'
import { Outlet, Route, Routes, useParams } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../../../_metronic/layout/core'
import { useIntl } from 'react-intl'
import MedicalProfessionalDetailsHeader from './MedicalProfessionalDetailsHeader'
import MedicalProfessionalDetailsToolbar from './MedicalProfessionalDetailsToolbar'
import MedicalProfessionalService from '../../../../../services/Crm/MedicalProfessionalService'
import Info from './components/Info'
import PCL from './components/PCL'
import Stats from './components/Stats'
import Pay from './components/Pay'
import Pack from './components/Pack'
import Call from './components/Call'
import Activities from './components/Activities'
import SmartList from './components/SmartList'


const MedicalProfessionalDetailsWrapper : React.FC = () => {
  const { id } = useParams()
  const intl = useIntl()

  const [isLoading, setIsLoading] = useState(true)
  const [medicalProfessional, setMedicalProfessional] = useState<IMedicalProfessionalHeader>()
  const medicalProfessionalDisplayBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({ id: 'home' }),
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: 'medical.professional.menu' }),
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: 'medical.professional.details' }),
      path: '/doctor',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  useEffect(() => {
    MedicalProfessionalService.getMedicalProfessionalHeader(id).then((response) => {
      setMedicalProfessional(response.data.data)
      setIsLoading(false)
    })
  }, [isLoading])


  return (
    <>
      <Routes>
        <Route
          element={
            <>
              <MedicalProfessionalDetailsHeader medicalProfessional={medicalProfessional}/>
              <Outlet/>
            </>
          }
        >
          <Route
            index
            element={
              <>
                <PageTitle breadcrumbs={medicalProfessionalDisplayBreadCrumbs} pageTitle={`${medicalProfessional?.firstName} ${medicalProfessional?.lastName}`}>
                  <MedicalProfessionalDetailsToolbar />
                </PageTitle>
                <Info/>
              </>
            }
          />
          <Route
            path='pcl'
            element={
              <>
                <PageTitle breadcrumbs={medicalProfessionalDisplayBreadCrumbs} pageTitle={`${medicalProfessional?.firstName} ${medicalProfessional?.lastName}`}>
                  <MedicalProfessionalDetailsToolbar/>
                </PageTitle>
                <PCL />
              </>
            }
          />
          <Route
            path='stat'
            element={
              <>
                <PageTitle breadcrumbs={medicalProfessionalDisplayBreadCrumbs} pageTitle={`${medicalProfessional?.firstName} ${medicalProfessional?.lastName}`}>
                  <MedicalProfessionalDetailsToolbar/>
                </PageTitle>
                <Stats />
              </>
            }
          />
          <Route
            path='pay'
            element={
              <>
                <PageTitle breadcrumbs={medicalProfessionalDisplayBreadCrumbs} pageTitle={`${medicalProfessional?.firstName} ${medicalProfessional?.lastName}`}>
                  <MedicalProfessionalDetailsToolbar/>
                </PageTitle>
                <Pay />
              </>
            }
          />
          <Route
            path='pack'
            element={
              <>
                <PageTitle breadcrumbs={medicalProfessionalDisplayBreadCrumbs} pageTitle={`${medicalProfessional?.firstName} ${medicalProfessional?.lastName}`}>
                  <MedicalProfessionalDetailsToolbar/>
                </PageTitle>
                <Pack />
              </>
            }
          />
          <Route
            path='call'
            element={
              <>
                <PageTitle breadcrumbs={medicalProfessionalDisplayBreadCrumbs} pageTitle={`${medicalProfessional?.firstName} ${medicalProfessional?.lastName}`}>
                  <MedicalProfessionalDetailsToolbar/>
                </PageTitle>
                <Call />
              </>
            }
          />
          <Route
            path='activities'
            element={
              <>
                <PageTitle breadcrumbs={medicalProfessionalDisplayBreadCrumbs} pageTitle={`${medicalProfessional?.firstName} ${medicalProfessional?.lastName}`}>
                  <MedicalProfessionalDetailsToolbar/>
                </PageTitle>
                <Activities />
              </>
            }
          />
          <Route
            path='smart-list'
            element={
              <>
                <PageTitle breadcrumbs={medicalProfessionalDisplayBreadCrumbs} pageTitle={`${medicalProfessional?.firstName} ${medicalProfessional?.lastName}`}>
                  <MedicalProfessionalDetailsToolbar/>
                </PageTitle>
                <SmartList />
              </>
            }
          />
        </Route>
      </Routes>
    </>
  )
}

export default MedicalProfessionalDetailsWrapper
