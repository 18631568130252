import React from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'

type Props = {
  bankAccount : IBankAccount|null|undefined
}

const BankAccountNavBar : React.FC<Props> = ({ bankAccount }) => {
  const intl = useIntl()
  return (
    <>
      <div className="mb-6 ms-1">
        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
          <li className='nav-item'>
            <Link
              className = {`nav-link text-active-primary me-6 ${location.pathname === `/bank-account/${bankAccount?.uuid}/transactions` ? 'active' : ''}`}
              to={`/bank-account/${bankAccount?.uuid}/transactions`}
            >
              {intl.formatMessage({ id: 'accounting.bank.account.transactions' })}
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              className = {`nav-link text-active-primary me-6 ${location.pathname === `/bank-account/${bankAccount?.uuid}/operation` ? 'active' : ''}`}
              to={`/bank-account/${bankAccount?.uuid}/operation`}
            >
              {intl.formatMessage({ id: 'accounting.bank.account.operation' })}
            </Link>
          </li>
        </ul>
      </div>
    </>
  )
}

export default BankAccountNavBar
