import React, { useState, useEffect } from 'react'
import ProfileService from '../../../../services/Hr/ProfileService'
import ProfileItem from './ProfileItem'
import ProfileCreate from './add/ProfileCreate'

type Props = {
    handleShow: () => void,
    handleClose: () => void,
    show: boolean,
}

const ProfileListing: React.FC<Props> = ({ handleShow, handleClose, show }) => {
  const [profiles, setProfiles] = useState<IProfile[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const handleIsLoading = (isLoading: boolean) => {
    setIsLoading(isLoading)
  }

  useEffect(() => {
    ProfileService.getAllProfiles().then((response) => {
      setProfiles(response.data.data)
      setIsLoading(false)
    })
  }, [isLoading])


  return (
    <div className={`${isLoading ? 'overlay overlay-block' : ''}`}>
      <div className={`row gy-5 g-xl-8 px-4 ${isLoading ? 'overlay-wrapper' : ''}`}>
        {profiles && profiles.map((profile, index) => (
          <div className='col-xxl-4' key={`profile_${index}`}>
            <ProfileItem isCreate={false} handleShow={handleShow} profile={profile} isLoading={isLoading} handleIsLoading={handleIsLoading} page='list' profileLength={0}/>
          </div>
        ))}
        <div className='col-xxl-4'>
          <ProfileItem isCreate={true} handleShow={handleShow} isLoading={isLoading} handleIsLoading={handleIsLoading} page='list' profileLength={profiles.length}/>
        </div>
      </div>
      {isLoading &&
            <div className="overlay-layer rounded bg-dark bg-opacity-5">
              <div
                className="spinner-border text-primary"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
      }
      <ProfileCreate
        profile={null}
        show={show}
        handleClose={handleClose}
        handleIsLoading={handleIsLoading}
        handleCheckedFeatures={() => {}}
        checkedFeatures={[]}
        action='create'
      />
    </div>
  )
}

export default ProfileListing
