import apiService from '../../../setup/axios/ApiService'

const prefixURL = 'call'

const createCall = (data: any) => apiService.post(`${prefixURL}/`, data)

const getCallsByMedicalProfessional = (uuid:string|undefined, currentPage: number, limit: number) => apiService.get(`${prefixURL}/medical-professional/${uuid}`, {
  currentPage,
  limit,
})

const CallService = {
  createCall,
  getCallsByMedicalProfessional,
}

export default CallService
