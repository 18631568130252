import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import LocationService from '../../../../../services/Crm/LocationService'
import CountryService from '../../../../../services/CountryService'
import CustomerService from '../../../../../services/Accounting/CustomerService'
import Select from 'react-select'
import CustomerCategoryService from '../../../../../services/Accounting/CustomerCategoryService'

type Props = {
  show: boolean,
  customer: ICustomer|null|undefined,
  handleClose: (isSubmit: boolean) => void,
  handleIsLoading: (isLoading: boolean) => void,
  action: string,
}

const customerSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Ce champs est obligatoire'),
  lastName: Yup.string()
    .required('Ce champs est obligatoire'),
  displayName: Yup.string()
    .required('Ce champs est obligatoire'),
  taxRegistrationNumber: Yup.string()
    .required('Ce champs est obligatoire'),
  financialCode: Yup.string()
    .required('Ce champs est obligatoire'),
  location: Yup.string()
    .required('Ce champs est obligatoire'),
  country: Yup.string()
    .required('Ce champs est obligatoire'),
  customerCategory: Yup.string()
    .required('Ce champs est obligatoire'),
})


type Inputs = {
    firstName: string,
    lastName: string,
    displayName: string,
    taxRegistrationNumber: string,
    financialCode: string,
    location: string,
    country: string,
    customerCategory: string,
}

const CustomerCreate : React.FC<Props> = (
  {
    show,
    customer,
    handleClose,
    handleIsLoading,
    action,
  },
) => {
  const colourStyles = {
    control: (styles: any) => ({ ...styles, backgroundColor: '#F5F8FA', border: 0, height: '45px' }),
  }
  const intl = useIntl()
  const config = {
    resolver: yupResolver(customerSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      displayName: '',
      taxRegistrationNumber: '',
      financialCode: '',
      location: '',
      country: '',
      customerCategory: '',
    },
  }
  const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm<Inputs>(config)

  const [countries, setCountries] = useState<{value: string, label: string}[]>([])
  const [locations, setLocations] = useState<{value: string, label: string}[]>([])
  const [customerCategories, setCustomerCategories] = useState<{value: string, label: string}[]>([])

  const handleCountry = (event: any) => {
    setValue('country', event.value)
    LocationService.selectLocationByCountry(event.value).then((response) => {
      setLocations(response.data.data)
    })
  }

  const handleLocation = (event: any) => {
    setValue('location', event.value)
  }
  const handleCustomerCategory = (event: any) => {
    setValue('customerCategory', event.value)
  }

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    CustomerService.createCustomer(data).then(() => {
      handleIsLoading(true)
      handleClose(true)
      reset()
    })
  }

  useEffect(() => {
    CountryService.selectCountry().then((response) => {
      setCountries(response.data.data)
    })
    CustomerCategoryService.selectCustomerCategory().then((response) => {
      setCustomerCategories(response.data.data)
    })
  }, [])

  return (
    <Modal show={show} onHide={() => handleClose(false)} dialogClassName={'mw-1000px'}>
      <Modal.Header closeButton>
        <Modal.Title>
          {intl.formatMessage({ id: 'accounting.customer.create.modal' })}
        </Modal.Title>
      </Modal.Header>
      <form className='form w-100' onSubmit={handleSubmit(onSubmit)} noValidate id='create-customer-form'>
        <Modal.Body>
          <div className='card card-p-0'>
            <div className='card-body'>
              <div className='row mb-5'>
                <div className='col-md-2'>
                  <div className='form-label mt-3 fs-6 text-dark required'>{intl.formatMessage({ id: 'accounting.customer.entity.firstName' })}</div>
                </div>
                <div className='col-md-4'>
                  <input
                    {...register('firstName')}
                    placeholder={intl.formatMessage({ id: 'accounting.customer.entity.firstName' })}
                    className='form-control form-control-solid'
                    type='text'
                    autoComplete='off'
                  />
                  {errors.firstName && errors.firstName.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.firstName.message}</span>
                    </div>
                  )}
                </div>
                <div className='col-md-2'>
                  <div className='form-label mt-3 fs-6 text-dark required'>{intl.formatMessage({ id: 'accounting.customer.entity.lastName' })}</div>
                </div>
                <div className='col-md-4'>
                  <input
                    {...register('lastName')}
                    placeholder={intl.formatMessage({ id: 'accounting.customer.entity.lastName' })}
                    className='form-control form-control-solid'
                    autoComplete='off'
                  />
                  {errors.lastName && errors.lastName.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.lastName.message}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col-md-2'>
                  <div className='form-label mt-3 fs-6 text-dark required'>{intl.formatMessage({ id: 'accounting.customer.entity.displayName' })}</div>
                </div>
                <div className='col-md-4'>
                  <input
                    {...register('displayName')}
                    placeholder={intl.formatMessage({ id: 'accounting.customer.entity.displayName' })}
                    className='form-control form-control-solid'
                    autoComplete='off'
                  />
                  {errors.displayName && errors.displayName.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.displayName.message}</span>
                    </div>
                  )}
                </div>
                <div className='col-md-2'>
                  <div className='form-label mt-3 fs-6 text-dark required'>{intl.formatMessage({ id: 'accounting.customer.entity.customerCategory' })}</div>
                </div>
                <div className='col-md-4'>
                  <Select
                    options={customerCategories}
                    styles={colourStyles}
                    defaultValue={{ value: customer?.customerCategory.uuid, label: customer?.customerCategory.name }}
                    onChange={handleCustomerCategory}
                  />
                  {errors.customerCategory && errors.customerCategory.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.customerCategory.message}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col-md-2'>
                  <div className='form-label mt-3 fs-6 text-dark required'>{intl.formatMessage({ id: 'accounting.customer.entity.taxRegistrationNumber' })}</div>
                </div>
                <div className='col-md-4'>
                  <input
                    {...register('taxRegistrationNumber')}
                    placeholder={intl.formatMessage({ id: 'accounting.customer.entity.taxRegistrationNumber' })}
                    className='form-control form-control-solid'
                    autoComplete='off'
                  />
                  {errors.taxRegistrationNumber && errors.taxRegistrationNumber.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.taxRegistrationNumber.message}</span>
                    </div>
                  )}
                </div>
                <div className='col-md-2'>
                  <div className='form-label mt-3 fs-6 text-dark required'>{intl.formatMessage({ id: 'accounting.customer.entity.financialCode' })}</div>
                </div>
                <div className='col-md-4'>
                  <input
                    {...register('financialCode')}
                    placeholder={intl.formatMessage({ id: 'accounting.customer.entity.financialCode' })}
                    className='form-control form-control-solid'
                    autoComplete='off'
                  />
                  {errors.financialCode && errors.financialCode.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.financialCode.message}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col-md-2'>
                  <div className='form-label mt-3 fs-6 text-dark required'>{intl.formatMessage({ id: 'accounting.customer.entity.country' })}</div>
                </div>
                <div className='col-md-4'>
                  <Select
                    options={countries}
                    styles={colourStyles}
                    defaultValue={{ value: customer?.country.uuid, label: customer?.country.name }}
                    onChange={handleCountry}
                  />
                  {errors.country && errors.country.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <>{errors.country.message}</>
                    </div>
                  )}
                </div>
                <div className='col-md-2'>
                  <div className='form-label mt-3 fs-6 text-dark required'>{intl.formatMessage({ id: 'accounting.customer.entity.location' })}</div>
                </div>
                <div className='col-md-4'>
                  <Select
                    options={locations}
                    styles={colourStyles}
                    defaultValue={{ value: customer?.location.uuid, label: customer?.location.name }}
                    onChange={handleLocation}
                  />
                  {errors.location && errors.location.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <>{errors.location.message}</>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn btn-sm btn-secondary'
            onClick={async () => handleClose(false)}
          >
            {intl.formatMessage({ id: 'action.close' })}
          </button>
          <button
            type='submit'
            className='btn btn-sm btn-secondary'
          >
            {intl.formatMessage({ id: 'action.save' })}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default CustomerCreate
