import apiService from '../../../setup/axios/ApiService'

const prefixURL = 'medical-professional'

const getAllMedicalProfessional = (currentPage:number, limit:number) => apiService.get(`${prefixURL}/fr`, {
  page: currentPage,
  limit,
})

const getMedicalProfessional = (uuid:string|undefined) => apiService.get(`${prefixURL}/${uuid}/fr`)

const createMedicalProfessional = (data: any) => apiService.post(`${prefixURL}/`, data)

const updateMedicalProfessional = (uuid: string|undefined, data: any) => apiService.put(`${prefixURL}/${uuid}`, data)

const getNearMe = (position: { lat: number, lng:number }) => apiService.get('medical-professional/near-me/fr', {
  'lat': position.lat,
  'lng': position.lng,
})

const sellForMedicalProfessional = (medKey: string|undefined, data: any) => apiService.post(`${prefixURL}/sell/product/${medKey}`, data)

const getMedicalProfessionalHeader = (medKey: string|undefined) => apiService.get(`${prefixURL}/${medKey}/header/show/fr`)

const MedicalProfessionalService = {
  getAllMedicalProfessional,
  getMedicalProfessional,
  createMedicalProfessional,
  updateMedicalProfessional,
  getNearMe,
  sellForMedicalProfessional,
  getMedicalProfessionalHeader,
}

export default MedicalProfessionalService
