import React, { useEffect, useState } from 'react'
import useWindowDimensions from '../../../../../../../../_metronic/partials/mobile-check-view/useCheckMobileScreen'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import JourneyService from '../../../../../../../services/Crm/JourneyService'
import Functions from '../../../../../../../../setup/utilities/Functions'
import { renderToString } from 'react-dom/server'
import CheckIn from '../../../display/common/components/CheckIn'
import TimeSlotService from '../../../../../../../services/Crm/TimeSlotService'
import Symbol from '../../../../../../../../_metronic/partials/common/Symbol'
import { KTSVG } from '../../../../../../../../_metronic/helpers'
import TodoItemActive from '../../../display/sales/components/cards/TodoItemActive'
import TodoItem from '../../../display/sales/components/cards/TodoItem'
import BreakNotStarted from '../../../display/common/BreakNotStarted'
import BreakStarted from '../../../display/common/BreakStarted'
import ToDone from '../../../display/sales/components/cards/ToDone'
import BreakFinished from '../../../display/common/BreakFinished'
import Calls from '../../../display/common/components/Calls'
import SugBugCreate from '../../../display/common/components/SugBugCreate'
import EndJourney from '../../../display/common/components/EndJourney'
import Delay from '../../../display/common/Delay'
import DelayFinished from '../../../display/common/DelayFinished'
import FirstCheckIn from '../../../display/common/FirstCheckIn'

type Props = {
  journey: IJourney|undefined,
  isLoading: boolean,
  setIsLoading: (isLoading: boolean) => void,
}
const JourneyManagerDetails : React.FC<Props> = ({ journey, isLoading, setIsLoading }) => {
  const isMobile = useWindowDimensions()
  const [showCall, setShowCall] = useState<boolean>(false)
  const [showSugBug, setShowSugBug] = useState<boolean>(false)
  const [showCreateCall, setShowCreateCall] = useState<boolean>(false)
  const [showEndJourney, setShowEndJourney] = useState<boolean>(false)
  const [checkOut, setCheckout] = useState<boolean>(false)
  const [calls, setCalls] = useState<ICall[]>([])
  const [timeSlot, setTimeSlot] = useState<ITimeSlot|null>()
  const [undoneTimeSlots, setUndoneTimeSlots] = useState<ITimeSlot[]>([])
  const [activeTimeSlot, setActiveTimeSlot] = useState<ITimeSlot|null>(null)
  const intl = useIntl()
  const navigate = useNavigate()
  const [timeSlots, setTimeSlots] = useState<ITimeSlot[]>([])
  // Show Call History
  const handleShowCall = (calls : ICall[], timeSlot: ITimeSlot|null) => {
    setCalls(calls)
    setTimeSlot(timeSlot)
    setShowCall(true)
  }
  const handleCloseCall = () => setShowCall(false)

  // Show SugBug Create
  const handleShowSugBug = () => setShowSugBug(true)
  const handleCloseSugBug = () => setShowSugBug(false)

  // Show Call Create
  const handleShowCreateCall = (checkout: boolean) => {
    setShowCreateCall(true)
    setCheckout(checkout)
    handleCloseCall()
  }
  const handleCloseCreateCall = () => setShowCreateCall(false)
  // Show End Journey
  const handleShowEndJourney = () => {
    const item = timeSlots.find((element) => element.isStarted && !element.isFinished)
    const remainingTimeSlots = timeSlots && timeSlots.filter((timeSlot) => !timeSlot.isStarted && !timeSlot.isFinished).map((item) => item)
    setUndoneTimeSlots(remainingTimeSlots)
    setActiveTimeSlot(item || null)
    if (remainingTimeSlots.length !== 0) {
      setShowEndJourney(true)
    } else {
      const data = {
        endDate: new Date(),
      }
      JourneyService.endJourney(journey!.uuid, data).then(() => {
        navigate('/journey')
      })
    }
  }
  const handleCloseEndJourney = () => setShowEndJourney(false)

  // Alert on CheckIn
  const checkIn = async (timeSlot: ITimeSlot) => {
    const item = timeSlots.find((element) => element.isStarted && !element.isFinished)
    if (item) {
      const isConfirmed = await Functions.sweetAlert(renderToString(<CheckIn medicalProfessional={item ? item.todo.medicalProfessional : null}/>),
        'error',
        false,
        'Check out',
        intl.formatMessage({ id: 'action.return' }),
        'btn btn-danger',
        '',
      )
      if (isConfirmed) {
        TimeSlotService.checkOut(item.uuid, null).then(() => {
          setIsLoading(true)
        })
      }
    } else {
      TimeSlotService.checkIn(timeSlot.uuid, null).then(() => {
        setIsLoading(true)
      })
    }
  }
  const checkOutTimeSlot = (uuid: string) => {
    TimeSlotService.checkOut(uuid, null).then(() => {
      setIsLoading(true)
      handleCloseEndJourney()
    })
  }

  useEffect(() => {
    if (journey?.uuid) {
      TimeSlotService.getTimeSlotsByJourney(journey!.uuid, 1).then((response) => {
        setTimeSlots(response.data.data)
        setIsLoading(false)
      })
    }
  }, [isLoading, journey])

  return (
    <>
      <div className={`card card-rounded bg-med-purple mb-5 ${isLoading ? 'overlay-wrapper' : ''}`}>
        <div className={`card-body px-0 py-5 ${isLoading ? 'overlay-wrapper' : ''}`}>
          {/* Card Header */}
          <div className="d-flex ms-5">
            <div className="flex-grow-1">
              <div className="row">
                <div className="col-sm-6">
                  <div className="d-flex align-items-start">
                    <Symbol
                      picture={journey?.employee ? journey.employee.profilePicture : null}
                      picturePath='/admin/uploads/users/'
                      withText={true}
                      widthClassName='symbol-40px me-2'
                      text={Functions.fullName(journey?.employee ? journey?.employee.firstName : '', journey?.employee ? journey.employee.lastName : '')}
                      shortText={Functions.firstLetterUpperCase(journey?.employee ? journey.employee.firstName.charAt(0) : '') + Functions.firstLetterUpperCase(journey?.employee ? journey.employee.lastName.charAt(0) : '')}
                      description={journey?.location ? journey?.location?.name : null}
                      backgroundClass=''
                      textClassName='fs-3 fw-400'
                      isCircular={false}
                      descriptionColor={'text-white fs-7 fw-500'}
                      textColor={'text-white fs-3 fw-bold'}
                    />
                    {isMobile ?
                      <>
                        <div className="ms-auto">
                          <span className='badge badge-pill badge-success fs-5 fw-bold border border-1 border-white rounded-pill rounded-end-0 h-40px'>
                            <span className="text-dark ms-2 w-30px">{journey?.score ? journey?.score : 0}</span>
                          </span>
                        </div>
                      </> :
                      <>
                      </>
                    }
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className={`d-flex justify-content-center align-items-center ${isMobile ? 'mt-6' : 'mt-2 me-5'}`}>
                    <div className="d-flex align-items-start justify-content-start">
                      <span className='badge badge-white'>
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr027.svg'
                          className='svg-icon svg-icon-1 svg-icon-primary'
                        />
                        <span className="text-primary fs-5 fw-500">{journey?.startDate ? journey?.startDate : '--:--'}</span>
                      </span>
                      <span className='badge badge-white ms-2'>
                        <KTSVG
                          path='/media/icons/duotune/abstract/reset.svg'
                          className='svg-icon svg-icon-1 svg-icon-danger'
                        />
                        <span className="text-danger fs-5 fw-500">{journey?.endDate ? journey?.endDate : '--:--'}</span>
                      </span>
                    </div>
                    <div className="ms-auto me-3">
                      <div className="d-flex align-items-start justify-content-start">
                        <div className="d-flex flex-column ms-6">
                          <div className="d-flex align-items-start justify-content-start mb-1">
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr016.svg'
                              className='svg-icon svg-icon-1 svg-icon-white'
                            />
                            <KTSVG
                              path='/media/icons/duotune/abstract/pharmacist.svg'
                              className='svg-icon svg-icon-1 ms-2'
                            />
                            <span className="text-white fs-4 fw-500 ms-1"> {journey?.progression}</span>
                            <span className="text-muted fs-4 fw-500 ms-1"> /{journey?.total} </span>
                          </div>
                          <input type="range" className="input-range-white" disabled max={journey?.total ? journey.total : 0} min={0} defaultValue={journey?.progression ? journey.progression : 0}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {!isMobile ?
              <>
                <div className="ms-auto">
                  <span className='badge badge-pill badge-success fs-5 fw-bold border border-1 border-white rounded-pill rounded-end-0 h-40px'>
                    <span className="text-dark ms-2 w-30px">{journey?.score ? journey?.score : 0}</span>
                  </span>
                </div>
              </> :
              <></>
            }
          </div>
          <div className="separator mt-3"></div>
          {/* /Card Header */}

          {/* Card Body */}
          <div className="mt-5 mb-5 ms-5 me-5">
            <span className="text-white fs-1 fw-bold">Tâches à faire</span>
            {timeSlots && timeSlots.map((item, index) => (
              <div key={`undone_${index}`}>
                {!item.isFinished ?
                  <>
                    {item.type === 'to_do' ?
                      <>
                        {item.isStarted ?
                          <>
                            <TodoItemActive isLoading={isLoading} timeSlot={item} handleShowCall={handleShowCall} handleShowCreateCall={handleShowCreateCall} handleShowSugBug={handleShowSugBug}/>
                          </> :
                          <>
                            <TodoItem journey={journey ? journey : null} isLoading={isLoading} checkIn={checkIn} timeSlot={item} handleShowCall={handleShowCall} handleShowSugBug={handleShowSugBug}/>
                          </>
                        }
                      </> :
                      <>

                      </>
                    }
                  </> :
                  <>
                  </>
                }
              </div>
            ))}
            {timeSlots.filter((item) => item.type === 'lunch_break').length === 0 ?
              <>
                <BreakNotStarted setIsLoading={setIsLoading} journey={journey ? journey : null} timeSlot={null}/>
              </> :
              <>
                {timeSlots && timeSlots.filter((item) => item.type === 'lunch_break').map((item, index) => (
                  <div key={`break_${index}`}>
                    {!item.isFinished ?
                      <>
                        <BreakStarted checkIn={checkIn} setIsLoading={setIsLoading} journey={journey ? journey : null} timeSlot={item}/>
                      </> :
                      <>
                      </>
                    }

                  </div>
                ))}
              </>
            }
            <div className='card card-rounded mt-5 bg-danger'>
              <div className='card-body px-2 py-0'>
                <div className="d-flex align-items-center justify-content-center">
                  <button className="btn text-white" onClick={handleShowEndJourney}>Fin de journée</button>
                </div>
              </div>
            </div>
          </div>
          {/* /Card Body */}
        </div>
      </div>
      {isLoading &&
          <div className="overlay-layer rounded bg-dark bg-opacity-5">
            <div
              className="spinner-border text-primary"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
      }
      <div className="mb-3">
        <span className="text-dark fs-3 fw-500">Visites effectuées</span>
      </div>

      {timeSlots && timeSlots.map((timeSlot, index) => (
        <div key={`to_done_${index}`}>
          {timeSlot.isFinished ?
            <>
              {timeSlot.type === 'to_do' ?
                <>
                  <ToDone timeSlot={timeSlot} handleShowCall={handleShowCall} handleShowCreateCall={handleShowCreateCall} handleShowSugBug={handleShowSugBug}/>
                </> :
                <>
                  {timeSlot.type === 'lunch_break' ?
                    <>
                      <BreakFinished journey={journey ? journey : null} timeSlot={timeSlot}/>
                    </> :
                    <>
                      {timeSlot.type === 'delay' ?
                        <>
                          {timeSlot.status === 'active' ?
                            <>
                              <Delay journey={journey ? journey : null} timeSlot={timeSlot} setIsLoading={setIsLoading} />
                            </> :
                            <>
                              <DelayFinished journey={journey ? journey : null} timeSlot={timeSlot} setIsLoading={setIsLoading} />
                            </>
                          }
                        </> :
                        <>
                        </>
                      }
                    </>
                  }
                </>
              }
            </> :
            <></>
          }
        </div>
      ))}
      {journey?.startDate !== null ?
        <>
          <FirstCheckIn journey={journey ? journey : null} />
        </> :
        <>
        </>
      }

      <Calls
        checkOut={checkOut}
        timeSlot={timeSlot ? timeSlot : null}
        calls={calls}
        showCall={showCall}
        handleCloseCall={handleCloseCall}
        handleShowCreateCall={handleShowCreateCall}
        handleCloseCreateCall={handleCloseCreateCall}
        showCreateCall={showCreateCall}
        setIsLoading={setIsLoading}
      />
      <SugBugCreate show={showSugBug} handleClose={handleCloseSugBug} timeSlot={timeSlot ? timeSlot : null} medKey={timeSlot?.todo.medicalProfessional ? timeSlot?.todo.medicalProfessional.medKey : null}/>
      <EndJourney
        checkout={checkOutTimeSlot}
        show={showEndJourney}
        handleClose={handleCloseEndJourney}
        activeTimeSlot={activeTimeSlot}
        undoneTimeSlots={undoneTimeSlots}
        journey={journey ? journey : null}
        setIsLoading={setIsLoading}
      />
    </>
  )
}

export default JourneyManagerDetails
