import React from 'react'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'

type Props = {
    forms : { form: JSX.Element}[],
    handleClose: (() => void)|null,
    nextStep : () => void,
    prevStep : () => void,
    reset: () => void,
    isMobile?: boolean,
}

const FormStepperHeader : React.FC<Props> = ({forms,handleClose, nextStep, prevStep, reset, isMobile }) => {
    const intl = useIntl()
    return(
        <>
            <Modal.Body className={`bg-white ${isMobile ? 'min-height-form-mobile' : 'min-height-form-browser'}`}>
                {forms.map((item, index) => (
                    <div key={index} className={`${index === 0 ? 'current' : ''}`} data-kt-stepper-element='content'>
                        {item.form}
                    </div>
                ))}
            </Modal.Body>
            <Modal.Footer className='bg-white d-flex justify-content-between'>
                <div className='d-flex justify-content-start'>
                    <div className='mr-2'>
                        <button
                            type='button'
                            className='btn btn-lg btn-light py-4 pe-8 me-3'
                            onClick={() => {
                                handleClose && handleClose()
                                reset()
                            }}
                        >
                            {intl.formatMessage({ id: 'action.close' })}
                        </button>
                    </div>
                </div>
                <div className='d-flex justify-content-end'>
                    <div className='mr-2'>
                        <button
                            type='button'
                            className='btn btn-lg btn-light py-4 pe-8 me-3'
                            data-kt-stepper-action='previous'
                            onClick={prevStep}
                        >
                            {intl.formatMessage({ id: 'action.previous' })}
                        </button>
                    </div>
                    <div>
                        <button
                            type='submit'
                            className='btn btn-lg btn-primary py-4 ps-8 me-3'
                            data-kt-stepper-action='submit'
                        >
                            Submit{' '}
                        </button>

                        <button
                            type='button'
                            className='btn btn-lg btn-primary py-4 ps-8 me-3'
                            data-kt-stepper-action='next'
                            onClick={nextStep}
                        >
                            {intl.formatMessage({ id: 'action.next' })}
                        </button>
                    </div>
                </div>
            </Modal.Footer>
        </>
    )

}

export default FormStepperHeader