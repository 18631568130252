/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, createContext, useContext, useEffect, useState, ReactNode} from 'react'
import { WithChildren } from '../../helpers'

export interface PageLink {
  title: string
  path: string
  isActive: boolean
  isSeparator?: boolean
}

export interface PageDataContextModel {
  pageTitle?: string
  setPageTitle: (_title: string) => void
  pageDescription?: string
  setPageDescription: (_description: string) => void
  pageBreadcrumbs?: Array<PageLink>
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => void
  toolbarContent?: React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | Iterable<React.ReactNode> | React.ReactPortal | boolean | ReactNode
  setToolbarContent: (_content: React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number  | Iterable<React.ReactNode> | React.ReactPortal | boolean | ReactNode) => void
}

const PageDataContext = createContext<PageDataContextModel>({
  setPageTitle: (_title: string) => {},
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => {},
  setPageDescription: (_description: string) => {},
  setToolbarContent: (_content: React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number  | Iterable<React.ReactNode> | React.ReactPortal | boolean | ReactNode) => {},
})

const PageDataProvider: FC<WithChildren> = ({ children }) => {
  const [pageTitle, setPageTitle] = useState<string>('')
  const [pageDescription, setPageDescription] = useState<string>('')
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState<Array<PageLink>>([])
  const [toolbarContent, setToolbarContent] = useState<React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | Iterable<React.ReactNode> | React.ReactPortal | boolean | ReactNode>()
  const value: PageDataContextModel = {
    pageTitle,
    setPageTitle,
    pageDescription,
    setPageDescription,
    pageBreadcrumbs,
    setPageBreadcrumbs,
    toolbarContent,
    setToolbarContent,
  }
  return <PageDataContext.Provider value={value}>{children}</PageDataContext.Provider>
}

function usePageData() {
  return useContext(PageDataContext)
}

type Props = {
  description?: string
  breadcrumbs?: Array<PageLink>
  pageTitle?: string
}

const PageTitle: FC<Props & WithChildren> = ({ children, description, breadcrumbs, pageTitle }) => {
  const { setPageTitle, setPageDescription, setPageBreadcrumbs, setToolbarContent } = usePageData()
  useEffect(() => {
    if (pageTitle) {
      setPageTitle(pageTitle)
    }
    return () => {
      setPageTitle('')
    }
  }, [pageTitle])

  useEffect(() => {
    if (description) {
      setPageDescription(description)
    }
    return () => {
      setPageDescription('')
    }
  }, [description])

  useEffect(() => {
    if (breadcrumbs) {
      setPageBreadcrumbs(breadcrumbs)
    }
    return () => {
      setPageBreadcrumbs([])
    }
  }, [breadcrumbs])

  useEffect(() => {
    if (children) {
      setToolbarContent(children)
    }
    return () => {
      setToolbarContent([])
    }
  }, [children])

  return <></>
}

const PageDescription: FC<WithChildren> = ({ children }) => {
  const { setPageDescription } = usePageData()
  useEffect(() => {
    if (children) {
      setPageDescription(children.toString())
    }
    return () => {
      setPageDescription('')
    }
  }, [children])
  return <></>
}

export { PageDescription, PageTitle, PageDataProvider, usePageData }
