import React, { useState, useEffect } from 'react'
import SalesTeamService from '../../../../services/Crm/SalesTeamService'
import SalesTeamItem from './SalesTeamItem'
import SalesTeamCreate from './add/SalesTeamCreate'

type Props = {
    handleShow: (salesTeam: ISalesTeam|undefined|null, action:string) => void
    handleClose: (isSubmit: boolean) => void,
    salesTeamSelected: ISalesTeam|null|undefined
    show: boolean,
    action: string
}

const SalesTeamListing: React.FC<Props> = ({ handleShow, handleClose, show, salesTeamSelected, action }) => {
  const [salesTeams, setSalesTeams] = useState<ISalesTeam[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const handleIsLoading = (isLoading: boolean) => {
    setIsLoading(isLoading)
  }

  useEffect(() => {
    SalesTeamService.getAllSalesTeams(1, 10).then((response) => {
      setSalesTeams(response.data.data.list)
      setIsLoading(false)
    })
  }, [isLoading])


  return (
    <div className={`${isLoading ? 'overlay overlay-block' : ''}`}>
      <div className={`row gy-5 g-xl-8 px-4 ${isLoading ? 'overlay-wrapper' : ''}`}>
        {salesTeams && salesTeams.map((salesTeam, index) => (
          <div className='col-xxl-4' key={`sales_team_${index}`}>
            <SalesTeamItem isCreate={false} handleShow={handleShow} salesTeam={salesTeam} isLoading={isLoading} handleIsLoading={handleIsLoading} page='list'/>
          </div>
        ))}
        <div className='col-xxl-4'>
          <SalesTeamItem isCreate={true} handleShow={handleShow} isLoading={isLoading} handleIsLoading={handleIsLoading} page='list'/>
        </div>
      </div>
      {isLoading &&
            <div className="overlay-layer rounded bg-dark bg-opacity-5">
              <div
                className="spinner-border text-primary"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
      }
      <SalesTeamCreate salesTeam={salesTeamSelected} show={show} handleClose={handleClose} handleIsLoading={handleIsLoading} action={action}/>
    </div>
  )
}

export default SalesTeamListing
