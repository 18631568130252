import React, { useEffect, useState } from 'react'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { useIntl } from 'react-intl'
import ProductFeatureToolbar from './ProductToolbar'
import Functions from '../../../../setup/utilities/Functions'
import ProductListing from './components/ProductListing'


const ProductWrapper : React.FC = () => {
  const [product, setProduct] = useState<IProduct|undefined|null>(null)
  const [action, setAction] = useState<string>('')
  const [show, setShow] = React.useState(false)
  const intl = useIntl()

  const productBreadCrumbs : Array<PageLink> = [
    {
      title: intl.formatMessage({ id: 'home.label' }),
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: 'products.management.label' }),
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: false,
      isActive: false,
    },
  ]

  useEffect(() => {}, [product])

  const handleClose = async (isSubmit: boolean) => {
    if (!isSubmit) {
      const isConfirmed = await Functions.sweetAlert(
        '<span class="mt-5">Are you sure you would like to cancel</span>',
        'warning',
        true,
        'Yes, cancel it!',
        'No, return',
        'btn btn-primary mt-10 mb-10',
        'btn btn-active-light mt-10 mb-10',
      )
      if (isConfirmed) {
        setShow(false)
      }
    } else {
      setShow(false)
    }
  }
  const handleShow = (product: IProduct|undefined|null, action: string) => {
    setProduct(product)
    setAction(action)
    setShow(true)
  }

  return (
    <>
      <PageTitle breadcrumbs={productBreadCrumbs} pageTitle={intl.formatMessage({ id: 'product.list' })}>
        <ProductFeatureToolbar handleShow={handleShow}/>
      </PageTitle>
      <ProductListing show={show} handleShow={handleShow} handleClose={handleClose} productSelected={product} action={action}/>
    </>
  )
}

export default ProductWrapper
