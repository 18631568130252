import React from 'react'
import { useLayout, usePageData } from '../../../../../../_metronic/layout/core'
import clsx from 'clsx'
import { BrowserView, MobileView } from 'react-device-detect'
import BreadcrumbsDisplay from '../../../../../../_metronic/layout/components/header/page-title/BreadcrumbsDisplay'

const SalesTeamDisplayToolbar: React.FC = () => {
  const { classes } = useLayout()
  const { pageTitle } = usePageData()

  return (
    <>
      <div
        id='kt_page_title'
        data-kt-swapper='true'
        data-kt-swapper-mode='prepend'
        data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
        className={clsx('page-title d-flex', classes.pageTitle.join(' '))}
      >
        <BrowserView>
          {/* begin::Title */}
          {pageTitle && (
            <h1 className='d-flex align-items-center text-dark my-1 fs-3'>
              {pageTitle}
            </h1>
          )}
          {/* end::Title */}
        </BrowserView>
        <MobileView>
          <div className='d-flex flex-wrap'>
            {pageTitle && (
              <h1 className='d-flex align-items-center text-dark fw-bolder my-1 fs-3 me-5'>
                {pageTitle}
              </h1>
            )}
            <BreadcrumbsDisplay />
          </div>
        </MobileView>
      </div>
    </>
  )
}

export default SalesTeamDisplayToolbar
