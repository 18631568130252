import React, { useEffect, useState } from 'react'
import Functions from '../../../../../../../../setup/utilities/Functions'
import BreakNotStarted from '../../common/BreakNotStarted'
import BreakStarted from '../../common/BreakStarted'
import BreakFinished from '../../common/BreakFinished'
import Calls from '../../common/components/Calls'
import SugBugCreate from '../../common/components/SugBugCreate'
import { useIntl } from 'react-intl'
import { renderToString } from 'react-dom/server'
import CheckIn from '../../common/components/CheckIn'
import TimeSlotService from '../../../../../../../services/Crm/TimeSlotService'
import TodoItemSupport from './cards/TodoItemSupport'
import ToDoneSupport from './cards/ToDoneSupport'
import CallCreate from '../../../../../call/add/CallCreate'

type Props = {
  journey: IJourney|null,
  timeSlots: ITimeSlot[],
  isLoading : boolean,
  setIsLoading: (isLoading: boolean) => void,
}
const TodoSupport : React.FC<Props> = ({ journey, timeSlots, isLoading, setIsLoading }) => {
  const [showCall, setShowCall] = useState<boolean>(false)
  const [showSugBug, setShowSugBug] = useState<boolean>(false)
  const [showCreateCall, setShowCreateCall] = useState<boolean>(false)
  const [checkOut, setCheckout] = useState<boolean>(false)
  const [calls, setCalls] = useState<ICall[]>([])
  const [timeSlot, setTimeSlot] = useState<ITimeSlot|null>()
  const intl = useIntl()

  // Show Call History
  const handleShowCall = (calls : ICall[], timeSlot: ITimeSlot|null) => {
    setCalls(calls)
    setTimeSlot(timeSlot)
    setShowCall(true)
  }
  const handleCloseCall = () => setShowCall(false)

  // Show SugBug Create
  const handleShowSugBug = () => setShowSugBug(true)
  const handleCloseSugBug = () => setShowSugBug(false)

  // Show Call Create
  const handleShowCreateCall = (checkout: boolean) => {
    setShowCreateCall(true)
    setCheckout(checkout)
    handleCloseCall()
  }
  const handleCloseCreateCall = () => setShowCreateCall(false)
  // Show End Journey
  function success(pos: any) {
    const crd = pos.coords
    console.log(crd)
    // setLocation({ latitude: crd.latitude, longitude: crd.longitude })
  }

  function error(err: any) {
    console.warn(`ERROR(${err.code}): ${err.message}`)
  }


  navigator.geolocation.getCurrentPosition(success, error)
  // Alert on CheckIn
  const checkIn = async (timeSlot: ITimeSlot) => {
    const item = timeSlots.find((element) => element.isStarted && !element.isFinished)
    if (item) {
      const isConfirmed = await Functions.sweetAlert(renderToString(<CheckIn medicalProfessional={item ? item.todo.medicalProfessional : null}/>),
        'error',
        false,
        'Check out',
        intl.formatMessage({ id: 'action.return' }),
        'btn btn-danger',
        '',
      )
      if (isConfirmed) {
        TimeSlotService.checkOut(item.uuid, null).then(() => {
          setIsLoading(true)
        })
      }
    } else {
      TimeSlotService.checkIn(timeSlot.uuid, null).then(() => {
        setIsLoading(true)
      })
    }
  }

  useEffect(() => {}, [journey, checkOut])
  return (
    <>

      <div className={`card card-rounded bg-med-purple mb-5 ${isLoading ? 'overlay overlay-block' : ''}`}>
        <div className={`card-body px-0 py-5 ${isLoading ? 'overlay overlay-block' : ''}`}>
          {/* Card Body */}
          <div className=" ms-5 me-5">
            <span className="text-white fs-2 fw-bold">Rappel</span>
            {timeSlots && timeSlots.map((item, index) => (
              <div key={`undone_${index}`}>
                {!item.isFinished ?
                  <>
                    {item.type === 'to_do' ?
                      <>
                        <TodoItemSupport isLoading={isLoading} checkIn={checkIn} timeSlot={item} handleShowCall={handleShowCall} handleShowSugBug={handleShowSugBug} journey={journey ? journey : null}/>
                      </> :
                      <>

                      </>
                    }
                  </> :
                  <>
                  </>
                }
              </div>
            ))}
            {timeSlots.filter((item) => item.type === 'lunch_break').length === 0 ?
              <>
                <BreakNotStarted setIsLoading={setIsLoading} journey={journey} timeSlot={null}/>
              </> :
              <>
                {timeSlots && timeSlots.filter((item) => item.type === 'lunch_break').map((item, index) => (
                  <div key={`break_${index}`}>
                    {!item.isFinished ?
                      <>
                        <BreakStarted checkIn={checkIn} setIsLoading={setIsLoading} journey={journey} timeSlot={item}/>
                      </> :
                      <>
                      </>
                    }

                  </div>
                ))}
              </>
            }
          </div>
          {/* /Card Body */}
        </div>
      </div>
      <div className="mb-3">
        <span className="text-dark fs-3 fw-500">Rappels effectués</span>
      </div>

      {timeSlots && timeSlots.map((timeSlot, index) => (
        <div key={`to_done_${index}`}>
          {timeSlot.isFinished ?
            <>
              {timeSlot.type === 'to_do' ?
                <>
                  <ToDoneSupport timeSlot={timeSlot} handleShowCall={handleShowCall} handleShowCreateCall={handleShowCreateCall} handleShowSugBug={handleShowSugBug}/>
                </> :
                <>
                  {timeSlot.type === 'lunch_break' ?
                    <>
                      <BreakFinished journey={journey} timeSlot={timeSlot}/>
                    </> :
                    <>
                    </>
                  }
                </>
              }
            </> :
            <></>
          }
        </div>
      ))}

      <Calls
        checkOut={checkOut}
        timeSlot={timeSlot ? timeSlot : null}
        calls={calls}
        showCall={showCall}
        handleCloseCall={handleCloseCall}
        handleShowCreateCall={handleShowCreateCall}
        handleCloseCreateCall={handleCloseCreateCall}
        showCreateCall={showCreateCall}
        setIsLoading={setIsLoading}
      />
      <CallCreate
        show={showCreateCall}
        handleClose={handleCloseCreateCall}
        timeSlot={timeSlot ? timeSlot : null}
        checkOut={true}
        setIsLoading={setIsLoading}
        medKey={timeSlot?.todo.medicalProfessional ? timeSlot?.todo.medicalProfessional.medKey : null}
      />
      <SugBugCreate show={showSugBug} handleClose={handleCloseSugBug} timeSlot={timeSlot ? timeSlot : null} medKey={timeSlot?.todo.medicalProfessional ? timeSlot?.todo.medicalProfessional.medKey : null}/>
    </>
  )
}

export default TodoSupport
