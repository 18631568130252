import { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import AuthInit from './modules/auth/redux/AuthInit'
import { AllRoutes } from './routing/AllRoutes'
import { buildAbilityFor } from './services/casl/Ability'
import { AbilityContext } from './services/casl/Can'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../setup'


const { PUBLIC_URL } = process.env

const App = () => {
  const user: IUser = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as IUser
  const ability = buildAbilityFor(user)
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Suspense fallback={<LayoutSplashScreen/>}>
        <I18nProvider>
          <LayoutProvider>
            <AuthInit>
              <AbilityContext.Provider value={ability}>
                <AllRoutes/>
              </AbilityContext.Provider>
            </AuthInit>
          </LayoutProvider>
        </I18nProvider>
      </Suspense>
    </BrowserRouter>
  )
}

export { App }
