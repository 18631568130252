import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import TableHeaderSolid from '../../../../../../../_metronic/partials/med-table/TableHeaderSolid'
import { KTSVG } from '../../../../../../../_metronic/helpers'
import PaginationBar from '../../../../../../../_metronic/partials/pagination/PaginationBar'
import SupplierOrderService from '../../../../../../services/Accounting/SupplierOrderService'
import WorkflowService from '../../../../../../services/WorkflowService'


const SuppliersOrders : React.FC = () => {
  const { id } = useParams()
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [supplierOrders, setSupplierOrders] = useState<ISupplierOrder[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = React.useState(1)

  const handleCurrentPage = (page:number) => {
    setIsLoading(true)
    setCurrentPage(page)
  }

  const headers = [
    {
      minWidth: 'w-50px',
      text: intl.formatMessage({ id: 'accounting.supplier.order.entity.reference' }),
    },
    {
      minWidth: 'w-50px',
      text: intl.formatMessage({ id: 'accounting.supplier.order.entity.paymentDate' }),
    },
    {
      minWidth: 'w-50px',
      text: intl.formatMessage({ id: 'accounting.supplier.order.entity.netAmount' }),
    },
    {
      minWidth: 'w-50px',
      text: intl.formatMessage({ id: 'accounting.supplier.order.entity.grossAmount' }),
    },
    {
      minWidth: 'w-50px',
      text: intl.formatMessage({ id: 'accounting.supplier.order.entity.status' }),
    },
    {
      minWidth: 'w-50px',
      text: intl.formatMessage({ id: 'actions' }),
    },
  ]

  useEffect(() => {
    SupplierOrderService.getSupplierOrdersBySupplier(currentPage, 10, id).then((response) => {
      setSupplierOrders(response.data.data.list)
      setTotalPages(response.data.data.totalPages)
      setIsLoading(false)
    })
  }, [isLoading, currentPage])

  const changeStatus = (value: string, workflowInstance: IWorkflowInstance, transition: string) => {
    const data = [
      {
        field: 'status',
        type: 'string',
        old_value: workflowInstance.status,
        new_value: value,
        isArrayField: false,
        isNewEntityInstance: workflowInstance.workflowType === 'convert_process',
      },
    ]

    WorkflowService.changeWorkflowInstance(workflowInstance.uuid, transition, data).then(() => {
      setIsLoading(true)
    })
  }

  return (
    <>
      <div className={`card mb-xl-10 ${isLoading ? 'overlay overlay-block' : ''}`}>
        <div
          className={`card-header border-0 cursor-pointer${isLoading ? 'overlay-wrapper' : ''}`}
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>{intl.formatMessage({ id: 'accounting.supplier.supplier.orders' })}</h3>
          </div>
        </div>
        <div className={`card-body pt-0 ${isLoading ? 'overlay-wrapper' : ''}`}>
          <div className="table-responsive">
            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
              <TableHeaderSolid headers={headers} />
              <tbody>
                {supplierOrders && supplierOrders.length > 0 ? (
                  supplierOrders.map((supplierOrder, index) => (
                    <tr key={`supplier_order_${index}`}>
                      <td>
                        <span className="text-muted">{supplierOrder.reference}</span>
                      </td>
                      <td>
                        <span className="text-muted">{supplierOrder.paymentDate}</span>
                      </td>
                      <td>
                        <span className="text-muted">{supplierOrder.netAmount}</span>
                      </td>
                      <td>
                        <span className="text-muted">{supplierOrder.grossAmount}</span>
                      </td>
                      <td>
                        {supplierOrder.workflowInstances && supplierOrder.workflowInstances.map((workflowInstance, index) => (
                          <div key={`workflow_${index}`}>
                            {workflowInstance.workflowType === 'supplier_order_workflow' ?
                              <>
                                <button
                                  type='button'
                                  className={`btn btn-light btn-sm w-125px ${workflowInstance.status === 'draft' ? 'btn-light-warning' : workflowInstance.status === 'created' ? 'btn-light-info' : workflowInstance.status === 'valid' ? 'btn-light-success' : 'btn-light-danger'}`}
                                  data-kt-menu-trigger='click'
                                  data-kt-menu-placement='bottom-end'
                                  data-kt-menu-flip="top-end"
                                >
                                  {workflowInstance.status}
                                  <KTSVG
                                    path='/media/icons/duotune/arrows/arr072.svg'
                                    className='svg-icon-4 svg-icon-gray-500 me-1'
                                  />
                                </button>
                                <div
                                  className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                                  data-kt-menu='true'
                                >
                                  {workflowInstance.status === 'created' ?
                                    <>
                                      <div className='menu-item'>
                                        <a className='menu-link' defaultValue="canceled" onClick={() => changeStatus('cancelled', workflowInstance, 'to_canceled')}>
                                                Cancel
                                        </a>
                                      </div>
                                      <div className='menu-item'>
                                        <a className='menu-link' defaultValue="accepted" onClick={() => changeStatus('valid', workflowInstance, 'to_valid')}>
                                                Validate
                                        </a>
                                      </div>
                                    </> :
                                    <>
                                      {workflowInstance.status === 'valid' ?
                                        <>
                                          <div className='menu-item'>
                                            <a className='menu-link' onClick={() => changeStatus('cancelled', workflowInstance, 'to_canceled')}>
                                                      Cancel
                                            </a>
                                          </div>
                                        </> :
                                        <>
                                          {workflowInstance.status === 'canceled' ?
                                            <>
                                              <div className='menu-item'>
                                                <a className='menu-link' onClick={() => changeStatus('draft', workflowInstance, 'to_draft')}>
                                                            Reactivate
                                                </a>
                                              </div>
                                            </> :
                                            <>
                                              {workflowInstance.status === 'draft' ?
                                                <>
                                                  <div className='menu-item'>
                                                    <a className='menu-link' onClick={() => changeStatus('created', workflowInstance, 'to_created')}>
                                                                  Create
                                                    </a>
                                                  </div>
                                                </> :
                                                <>
                                                </>
                                              }
                                            </>
                                          }
                                        </>
                                      }
                                    </>
                                  }

                                </div>
                              </> :
                              <></>
                            }
                          </div>
                        ))}
                      </td>
                      <td className="text-end">
                        <button
                          type='button'
                          className='btn btn-light btn-active-light-primary btn-sm'
                          data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-end'
                          data-kt-menu-flip="top-end"
                        >
                              Options
                          <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
                        </button>
                        <div
                          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                          data-kt-menu='true'
                        >
                          <div className='menu-item'>
                            <a className='menu-link'>
                                  Pay
                            </a>
                          </div>
                          <div className='menu-item'>
                            <a className='menu-link'>
                                  Credit Note
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 fs-4 align-content-center justify-content-center mt-5'>
                        {intl.formatMessage({ id: 'action.not.found' })}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {totalPages > 1 ? (
          <PaginationBar
            totalPages={totalPages}
            currentPage={currentPage}
            handleCurrentPage={handleCurrentPage}
          />
        ) : (<></>)}
        {isLoading &&
              <div className="overlay-layer rounded bg-dark bg-opacity-5">
                <div
                  className="spinner-border text-primary"
                  role="status"
                >
                  <span className="visually-hidden">{intl.formatMessage({ id: 'action.loading' })}</span>
                </div>
              </div>
        }
      </div>
    </>
  )
}

export default SuppliersOrders
