import React, { useEffect, useState } from 'react'
import Functions from '../../../../../../../../setup/utilities/Functions'
import { KTSVG } from '../../../../../../../../_metronic/helpers'
import Symbol from '../../../../../../../../_metronic/partials/common/Symbol'
import useWindowDimensions from '../../../../../../../../_metronic/partials/mobile-check-view/useCheckMobileScreen'
import TodoItemActive from './cards/TodoItemActive'
import TodoItem from './cards/TodoItem'
import ToDone from './cards/ToDone'
import Calls from '../../common/components/Calls'
import SugBugCreate from '../../common/components/SugBugCreate'
import EndJourney from '../../common/components/EndJourney'
import { useIntl } from 'react-intl'
import CheckIn from '../../common/components/CheckIn'
import { renderToString } from 'react-dom/server'
import TimeSlotService from '../../../../../../../services/Crm/TimeSlotService'
import BreakStarted from '../../common/BreakStarted'
import BreakNotStarted from '../../common/BreakNotStarted'
import BreakFinished from '../../common/BreakFinished'
import JourneyService from '../../../../../../../services/Crm/JourneyService'
import { useNavigate } from 'react-router-dom'
import DropDownManagers from '../../common/components/DropDownManagers'
import CallCreate from '../../../../../call/add/CallCreate'


type Props = {
  journey: IJourney|null,
  timeSlots: ITimeSlot[],
  isLoading : boolean,
  setIsLoading: (isLoading: boolean) => void,
}

const TodoSales : React.FC<Props> = ({ journey, timeSlots, isLoading, setIsLoading }) => {
  const isMobile = useWindowDimensions()
  const [showCall, setShowCall] = useState<boolean>(false)
  const [showSugBug, setShowSugBug] = useState<boolean>(false)
  const [showCreateCall, setShowCreateCall] = useState<boolean>(false)
  const [showEndJourney, setShowEndJourney] = useState<boolean>(false)
  const [checkOut, setCheckout] = useState<boolean>(false)
  const [calls, setCalls] = useState<ICall[]>([])
  const [timeSlot, setTimeSlot] = useState<ITimeSlot|null>(null)
  const [undoneTimeSlots, setUndoneTimeSlots] = useState<ITimeSlot[]>([])
  const [activeTimeSlot, setActiveTimeSlot] = useState<ITimeSlot|null>(null)
  const intl = useIntl()
  const navigate = useNavigate()

  // Show Call History
  const handleShowCall = (calls : ICall[], timeSlot: ITimeSlot|null) => {
    setCalls(calls)
    setTimeSlot(timeSlot)
    setShowCall(true)
  }
  const handleCloseCall = () => setShowCall(false)

  // Show SugBug Create
  const handleShowSugBug = () => setShowSugBug(true)
  const handleCloseSugBug = () => setShowSugBug(false)

  // Show Call Create
  const handleShowCreateCall = (checkout: boolean, timeSlot: ITimeSlot|null) => {
    setShowCreateCall(true)
    setTimeSlot(timeSlot)
    setCheckout(checkout)
    handleCloseCall()
  }
  const handleCloseCreateCall = () => setShowCreateCall(false)
  // Show End Journey
  const handleShowEndJourney = () => {
    const item = timeSlots.find((element) => element.isStarted && !element.isFinished)
    const remainingTimeSlots = timeSlots && timeSlots.filter((timeSlot) => !timeSlot.isStarted && !timeSlot.isFinished).map((item) => item)
    setUndoneTimeSlots(remainingTimeSlots)
    setActiveTimeSlot(item || null)
    if (remainingTimeSlots.length !== 0) {
      setShowEndJourney(true)
    } else {
      const data = {
        endDate: new Date(),
      }
      JourneyService.endJourney(journey!.uuid, data).then(() => {
        navigate('/journey')
      })
    }
  }
  const handleCloseEndJourney = () => setShowEndJourney(false)

  // Alert on CheckIn
  const checkIn = async (timeSlot: ITimeSlot) => {
    const item = timeSlots.find((element) => element.isStarted && !element.isFinished)
    if (item) {
      const isConfirmed = await Functions.sweetAlert(renderToString(<CheckIn medicalProfessional={item ? item.todo.medicalProfessional : null}/>),
        'error',
        true,
        'Check out',
        intl.formatMessage({ id: 'action.return' }),
        'btn btn-danger',
        'btn btn-secondary',
      )
      if (isConfirmed) {
        TimeSlotService.checkOut(item.uuid, null).then(() => {
          setIsLoading(true)
        })
      }
    } else {
      TimeSlotService.checkIn(timeSlot.uuid, null).then(() => {
        setIsLoading(true)
      })
    }
  }
  const checkOutTimeSlot = (uuid: string) => {
    TimeSlotService.checkOut(uuid, null).then(() => {
      setIsLoading(true)
      handleCloseEndJourney()
    })
  }

  useEffect(() => {}, [journey, checkOut])

  useEffect(() => {}, [timeSlot])

  return (
    <>
      <div className={`card card-rounded bg-med-purple mb-5 ${isLoading ? 'overlay overlay-block' : ''}`}>
        <div className={`card-body px-0 py-5 ${isLoading ? 'overlay overlay-block' : ''}`}>
          {/* Card Header */}
          <div className="d-flex ms-5">
            <div className="flex-grow-1">
              <div className="row">
                <div className="col-sm-5">
                  <div className="d-flex align-items-start">
                    <Symbol
                      picture={journey?.employee ? journey.employee?.profilePicture : null}
                      picturePath='/admin/uploads/users/'
                      withText={true}
                      widthClassName='symbol-40px me-5'
                      text={Functions.fullName(journey?.employee ? journey?.employee.firstName : '', journey?.employee ? journey.employee.lastName : '')}
                      shortText={Functions.firstLetterUpperCase(journey?.employee ? journey.employee.firstName.charAt(0) : '') + Functions.firstLetterUpperCase(journey?.employee ? journey.employee.lastName.charAt(0) : '')}
                      description={journey?.location ? journey?.location.name : null}
                      backgroundClass=''
                      textClassName='fs-3 fw-400'
                      isCircular={false}
                      descriptionColor={'text-white fs-7 fw-500'}
                      textColor={'text-white fs-3 fw-bold'}
                    />
                    {isMobile ?
                      <>
                        <div className="ms-auto">
                          <DropDownManagers journey={journey} setIsLoading={setIsLoading}/>
                        </div>
                      </> :
                      <>
                      </>
                    }
                  </div>
                </div>
                <div className="col-sm-5">
                  <div className={`d-flex justify-content-center align-items-center ${isMobile ? 'mt-6' : ''}`}>
                    <div className="d-flex align-items-start justify-content-start">

                      <span className='badge badge-white'>
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr027.svg'
                          className='svg-icon svg-icon-1 svg-icon-primary'
                        />
                        <span className="text-primary fs-5 fw-500">{journey?.startDate ? journey?.startDate : '--:--'}</span>
                      </span>
                      <span className='badge badge-white ms-2'>
                        <KTSVG
                          path='/media/icons/duotune/abstract/reset.svg'
                          className='svg-icon svg-icon-1 svg-icon-danger'
                        />
                        <span className="text-danger fs-5 fw-500">{journey?.endDate ? journey?.endDate : '--:--'}</span>
                      </span>
                    </div>


                    <div className="ms-auto me-3">
                      <div className="d-flex align-items-start justify-content-start">
                        <div className="d-flex flex-column ms-6">
                          <div className="d-flex align-items-start justify-content-start">
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr016.svg'
                              className='svg-icon svg-icon-1 svg-icon-white'
                            />
                            <KTSVG
                              path='/media/icons/duotune/abstract/pharmacist.svg'
                              className='svg-icon svg-icon-1 ms-2'
                            />
                            <span className="text-white fs-4 fw-500 ms-1"> {journey?.progression || 0}</span>
                            <span className="text-muted fs-4 fw-500 ms-1"> /{journey?.total || 0} </span>
                          </div>
                          <input type="range" className="input-range-white" disabled max={journey?.total ? journey.total : 0} min={0} defaultValue={journey?.progression ? journey.progression : 0}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {!isMobile ?
              <>
                <DropDownManagers journey={journey} setIsLoading={setIsLoading}/>
              </> :
              <></>
            }
          </div>
          <div className="separator mt-3"></div>
          {/* /Card Header */}

          {/* Card Body */}
          <div className="mt-5 mb-5 ms-5 me-5">
            <span className="text-white fs-1 fw-bold">Tâches à faire</span>
            {timeSlots && timeSlots.filter((item) => item.type === 'lunch_break').map((item, index) => (
              <div key={`break_${index}`}>
                {!item.isFinished ?
                  <>
                    <BreakStarted checkIn={checkIn} setIsLoading={setIsLoading} journey={journey} timeSlot={item}/>
                  </> :
                  <>
                  </>
                }

              </div>
            ))}
            {timeSlots && timeSlots.map((item, index) => (
              <div key={`undone_${index}`}>
                {!item.isFinished ?
                  <>
                    {item.type === 'to_do' ?
                      <>
                        {item.isStarted ?
                          <>
                            <TodoItemActive isLoading={isLoading} timeSlot={item} handleShowCall={handleShowCall} handleShowCreateCall={handleShowCreateCall} handleShowSugBug={handleShowSugBug}/>
                          </> :
                          <>
                            <TodoItem isLoading={isLoading} checkIn={checkIn} timeSlot={item} handleShowCall={handleShowCall} handleShowSugBug={handleShowSugBug} journey={journey ? journey : null}/>
                          </>
                        }
                      </> :
                      <>

                      </>
                    }
                  </> :
                  <>
                  </>
                }
              </div>
            ))}
            {timeSlots.filter((item) => item.type === 'lunch_break').length === 0 ?
              <>
                <BreakNotStarted setIsLoading={setIsLoading} journey={journey} timeSlot={null}/>
              </> :
              <>
              </>
            }
            <div className='card card-rounded mt-5 bg-danger'>
              <div className='card-body px-2 py-0'>
                <div className="d-flex align-items-center justify-content-center">
                  <button className="btn text-white" onClick={handleShowEndJourney}>Fin de journée</button>
                </div>
              </div>
            </div>
          </div>
          {/* /Card Body */}
        </div>
      </div>
      <div className="mb-3">
        <span className="text-dark fs-3 fw-500">Visites effectuées</span>
      </div>

      {timeSlots && timeSlots.map((timeSlot, index) => (
        <div key={`to_done_${index}`}>
          {timeSlot.isFinished ?
            <>
              {timeSlot.type === 'to_do' ?
                <>
                  <ToDone timeSlot={timeSlot} handleShowCall={handleShowCall} handleShowCreateCall={handleShowCreateCall} handleShowSugBug={handleShowSugBug}/>
                </> :
                <>
                  {timeSlot.type === 'lunch_break' ?
                    <>
                      <BreakFinished journey={journey} timeSlot={timeSlot}/>
                    </> :
                    <>
                    </>
                  }
                </>
              }
            </> :
            <></>
          }
        </div>
      ))}

      <Calls
        checkOut={checkOut}
        timeSlot={timeSlot ? timeSlot : null}
        calls={calls}
        showCall={showCall}
        handleCloseCall={handleCloseCall}
        handleShowCreateCall={handleShowCreateCall}
        handleCloseCreateCall={handleCloseCreateCall}
        showCreateCall={showCreateCall}
        setIsLoading={setIsLoading}
      />
      <CallCreate
        show={showCreateCall}
        handleClose={handleCloseCreateCall}
        timeSlot={timeSlot}
        checkOut={checkOut}
        setIsLoading={setIsLoading}
        medKey={timeSlot?.todo.medicalProfessional ? timeSlot?.todo.medicalProfessional.medKey : null}
      />
      <SugBugCreate
        show={showSugBug}
        handleClose={handleCloseSugBug}
        timeSlot={timeSlot ? timeSlot : null}
        medKey={timeSlot ? timeSlot?.todo?.medicalProfessional?.medKey : null}
      />
      <EndJourney
        checkout={checkOutTimeSlot}
        show={showEndJourney}
        handleClose={handleCloseEndJourney}
        activeTimeSlot={activeTimeSlot}
        undoneTimeSlots={undoneTimeSlots}
        journey={journey ? journey : null}
        setIsLoading={setIsLoading}
      />
    </>
  )
}

export default TodoSales
