import apiService from '../../../setup/axios/ApiService'

const prefixURL = 'sales-team'

const getAllSalesTeams = (currentPage:number, limit:number) => apiService.get(`${prefixURL}/`, {
  page: currentPage,
  limit,
})

const getSalesTeam = (uuid:string|undefined) => apiService.get(`${prefixURL}/${uuid}`)
const createSalesTeam = (data: any) => apiService.post(`${prefixURL}/`, data)
const updateSalesTeam = (uuid: string|undefined, data: any) => apiService.put(`${prefixURL}/${uuid}`, data)
const updateStatus = (uuid:string|undefined, data: any) => apiService.patch(`${prefixURL}/${uuid}/active`, data)
const getSalesTeamManagers = (uuid:string|undefined) => apiService.get(`employee/select-manager/sales-team/${uuid}`)
const getSalesTeamEmployees = (uuid:string|undefined, currentPage:number, limit:number) => apiService.get(`employee/select/sales-team/${uuid}`, {
  page: currentPage,
  limit,
})
const deleteSalesTeam = (uuid: string|undefined) => apiService.deletes(`${prefixURL}/${uuid}`)


const ProfileService = {
  getAllSalesTeams,
  updateStatus,
  getSalesTeam,
  getSalesTeamManagers,
  getSalesTeamEmployees,
  createSalesTeam,
  updateSalesTeam,
  deleteSalesTeam,
}

export default ProfileService
