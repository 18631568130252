import apiService from '../../../setup/axios/ApiService'

const prefixURL = 'estimate'

const getAllEstimates = (currentPage:number, limit:number, customerName: string, customerType: boolean, status: string, startDate: string, endDate: string) => apiService.get(`${prefixURL}/`, {
  page: currentPage,
  limit,
  startDate,
  endDate,
  customerName,
  status,
  customerType,
})

const createEstimate = (data: any) => apiService.post(`${prefixURL}/`, data)

const updateEstimate = (uuid: string|undefined, data: any) => apiService.put(`${prefixURL}/${uuid}`, data)

const getEstimate = (uuid:string|undefined) => apiService.get(`${prefixURL}/${uuid}`)

const patchEstimateConfig = (uuid: string|undefined, data: any) => apiService.patch(`${prefixURL}/${uuid}/config`, data)

const setReferenceForEstimate = (uuid: string|undefined, workflowId:string, data: any) => apiService.patch(`${prefixURL}/${uuid}/reference/workflow/${workflowId}`, data)

const getEstimatesByCustomer = (currentPage:number, limit:number, uuid: string|undefined) => apiService.get(`${prefixURL}/customer/${uuid}`, {
  page: currentPage,
  limit,
})

const duplicateEstimate = (uuid: string|undefined) => apiService.post(`${prefixURL}/${uuid}/duplicate`, {})

const EstimateService = {
  getAllEstimates,
  createEstimate,
  updateEstimate,
  getEstimate,
  patchEstimateConfig,
  setReferenceForEstimate,
  getEstimatesByCustomer,
  duplicateEstimate,
}

export default EstimateService
