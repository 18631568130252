import React from 'react'
import { KTSVG } from '../../../../../../../../../_metronic/helpers'

type Props = {
  journey: IJourney|null,
}
const ToDonePatientCall : React.FC<Props> = ({ journey }) => (
  <>
    <div className='card card-rounded mt-5'>
      <div className='card-body px-2 py-0'>
        <div className="d-flex">
          <div className="mt-2">
            <div className="d-flex flex-column h-100 pb-2">
              <KTSVG
                path='/media/icons/duotune/abstract/abs009.svg'
                className='svg-icon svg-icon-1 med-color-orange'
              />
              <div className='vr bg-med-orange opacity-100 w-3px h-100 ms-3'></div>
            </div>
          </div>
          <div className="flex-grow-1">
            <div className="row mt-4 ms-3">
              <div className="col-sm-6">
                <span className="mt-2">
                  <KTSVG
                    path='/media/icons/duotune/abstract/phone.svg'
                    className='svg-icon svg-icon-1 svg-icon-dark'
                  />
                  <KTSVG
                    path='/media/icons/duotune/communication/com014.svg'
                    className='svg-icon svg-icon-1 svg-icon-dark'
                  />
                  <span className="text-dark fs-3 fw-bold ms-2">
                    {journey?.patientCalls || 0}
                  </span>
                </span>
              </div>

            </div>
          </div>
          <div className="d-flex align-content-end justify-content-end mt-1">
            <span className='badge bg-med-light-dark fs-8 fw-bold h-30px mt-2 p-1'>
              <div className="d-flex align-items-center justify-content-center">
                <span className='badge badge-white w-70px h-25px'>
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr027.svg'
                    className='svg-icon svg-icon-1 svg-icon-primary'
                  />
                  <span className="text-primary fs-6 fw-500">{journey?.startDate || '--:--'}</span>
                </span>
                <span className='badge badge-white w-70px h-25px ms-2'>
                  <KTSVG
                    path='/media/icons/duotune/abstract/reset.svg'
                    className='svg-icon svg-icon-1 svg-icon-danger'
                  />
                  <span className="text-danger fs-6 fw-500">{journey?.endDate || '--:--'}</span>
                </span>
                <span className="text-dark ms-2">-</span>
                <span className='badge badge-white h-25px ms-2'>
                  <KTSVG
                    path='/media/icons/duotune/general/gen013.svg'
                    className='svg-icon-2 svg-icon-dark'
                  />
                </span>
              </div>
            </span>
            <a className='btn btn-icon btn-success ms-2 btn-sm w-40px h-40px rounded-3'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr027.svg'
                className='svg-icon svg-icon-1 svg-icon-white'
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default ToDonePatientCall
