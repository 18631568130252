import apiService from '../../../setup/axios/ApiService'

const prefixURL = 'package'

const getAllPackages = (currentPage:number) => apiService.get(`${prefixURL}/`, { page: currentPage })

const createPackage = (data: any) => apiService.post(`${prefixURL}/`, data)

const updateIsActiveStatus = (uuid:string|undefined, data: any) => apiService.patch(`${prefixURL}/${uuid}/active`, data)

const updateIsAutoReactivation = (uuid:string|undefined, data: any) => apiService.patch(`${prefixURL}/${uuid}/reactivation`, data)

const deletePackage = (uuid: string|undefined) => apiService.deletes(`${prefixURL}/${uuid}/delete`)

const updatePackage = (uuid: string|undefined, data: any) => apiService.put(`${prefixURL}/${uuid}/edit`, data)

const PackageService = {
  getAllPackages,
  createPackage,
  updateIsActiveStatus,
  updateIsAutoReactivation,
  deletePackage,
  updatePackage,
}

export default PackageService
