import apiService from '../../../setup/axios/ApiService'

const prefixURL = 'journey'

const getAllJourneys = () => apiService.get(`${prefixURL}/`)

const getJourney = (uuid:string|undefined) => apiService.get(`${prefixURL}/${uuid}`)

const createJourney = (data: any) => apiService.post(`${prefixURL}/`, data)

const updateJourney = (uuid: string|undefined, data: any) => apiService.put(`${prefixURL}/${uuid}`, data)

const endJourney = (uuid: string, data: any) => apiService.put(`${prefixURL}/${uuid}/end-journey`, data)

const getJourneysByManager = (uuid: string) => apiService.get(`${prefixURL}/manager/${uuid}`)

const chooseManager = (uuid: string, data: any) => apiService.patch(`${prefixURL}/${uuid}/manager`, data)

const getJourneyOfToday = (date: string|null) => apiService.get(`${prefixURL}/my-journey/today`, {
  date,
})

const JourneyService = {
  getAllJourneys,
  getJourney,
  createJourney,
  updateJourney,
  endJourney,
  getJourneysByManager,
  chooseManager,
  getJourneyOfToday,
}

export default JourneyService
