import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import Functions from '../../../../setup/utilities/Functions'
import MedicalProfessionalToolbar from './components/MedicalProfessionalToolbar'
import MedicalProfessionalFilter from './components/MedicalProfessionalFilter'


const MedicalProfessionalWrapper : React.FC = () => {
  const [selectedMedicalProfessional, setSelectedMedicalProfessional] = useState<IMedicalProfessional|undefined|null>(null)
  const [show, setShow] = React.useState(false)
  const intl = useIntl()

  const medicalProfessionalBreadCrumbs : Array<PageLink> = [
    {
      title: intl.formatMessage({ id: 'home.label' }),
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: false,
      isActive: false,
    },
  ]

  const handleClose = async (isSubmit: boolean) => {
    if (!isSubmit) {
      const isConfirmed = await Functions.sweetAlert(
        '<span class="mt-5">Are you sure you would like to cancel</span>',
        'warning',
        true,
        'Yes, cancel it!',
        'No, return',
        'btn btn-primary mt-10 mb-10',
        'btn btn-active-light mt-10 mb-10',
      )
      if (isConfirmed) {
        setShow(false)
      }
    } else {
      setShow(false)
    }
  }
  const handleShow = (medicalProfessional: IMedicalProfessional|undefined|null) => {
    setSelectedMedicalProfessional(medicalProfessional)
    setShow(true)
  }

  return (
    <>
      <PageTitle breadcrumbs={medicalProfessionalBreadCrumbs} pageTitle={intl.formatMessage({ id: 'medical.professional.list' })}>
        <MedicalProfessionalToolbar handleShow={handleShow}/>
      </PageTitle>
      <MedicalProfessionalFilter show={show} handleShow={handleShow} handleClose={handleClose} selectedMedicalProfessional={selectedMedicalProfessional}/>
    </>
  )
}

export default MedicalProfessionalWrapper
