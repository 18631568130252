import React from 'react'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import EquipmentService from '../../../../../services/Accounting/EquipmentService'

type Props = {
    equipment: IEquipment|null|undefined,
    show: boolean,
    handleClose: (isSubmit: boolean) => void,
    handleIsLoading: (isLoading: boolean) => void,
    action: string,
}

const equipmentSchema = Yup.object().shape({
  title: Yup.string()
    .required('Ce champs est obligatoire'),
  link: Yup.string()
    .required('Ce champs est obligatoire'),
  description: Yup.string()
    .required('Ce champs est obligatoire'),
  reference: Yup.string()
    .required('Ce champs est obligatoire'),
  quantity: Yup.string()
    .required('Ce champs est obligatoire'),
  unitValue: Yup.string()
    .required('Ce champs est obligatoire'),
  globalValue: Yup.string()
    .required('Ce champs est obligatoire'),
})


type Inputs = {
    title: string,
    link: string,
    description: string,
    reference: string,
    quantity: string,
    unitValue: string,
    globalValue: string,
}

const EquipmentCreate: React.FC<Props> = ({ equipment, show, handleClose, handleIsLoading, action }) => {
  const intl = useIntl()
  const config = {
    resolver: yupResolver(equipmentSchema),
    defaultValues: {
      title: '',
      link: '',
      description: '',
      reference: '',
      quantity: '',
      unitValue: '',
      globalValue: '',
    },
  }
  const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm<Inputs>(config)
  setValue('title', equipment?.title || '')
  setValue('link', equipment?.link || '')
  setValue('description', equipment?.description || '')
  setValue('reference', equipment?.reference || '')
  setValue('quantity', equipment?.quantity || '')
  setValue('unitValue', equipment?.unitValue || '')
  setValue('globalValue', equipment?.globalValue || '')
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (action === 'create') {
      EquipmentService.createEquipment(data).then(() => {
        handleIsLoading(true)
        handleClose(true)
        reset()
      })
    } else {
      EquipmentService.updateEquipment(equipment?.uuid, data).then(() => {
        handleIsLoading(true)
        handleClose(true)
        reset()
      })
    }
  }

  return (
    <Modal show={show} onHide={() => handleClose(false)} dialogClassName={'mw-1000px'}>
      <Modal.Header closeButton>
        <Modal.Title>{action === 'create' ?
          <>{intl.formatMessage({ id: 'accounting.equipment.create.modal' })}</> :
          <>{intl.formatMessage({ id: 'accounting.equipment.update.modal' })}: <span className="text-muted fs-5">{equipment?.title}</span></>
        } </Modal.Title>
      </Modal.Header>
      <form className='form w-100' onSubmit={handleSubmit(onSubmit)} noValidate id='create-department-form'>
        <Modal.Body>
          <div className='card card-p-0'>
            <div className='card-body'>
              <div className='row mb-5'>
                <div className='col-md-2'>
                  <div className='form-label mt-3 fs-6 text-dark required'>{intl.formatMessage({ id: 'accounting.equipment.entity.title' })}</div>
                </div>
                <div className='col-md-10'>
                  <input
                    {...register('title')}
                    placeholder={intl.formatMessage({ id: 'accounting.equipment.entity.title' })}
                    className='form-control form-control-solid'
                    type='text'
                    autoComplete='off'
                  />
                  {errors.title && errors.title.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.title.message}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col-md-2'>
                  <div className='form-label mt-3 fs-6 text-dark required'>{intl.formatMessage({ id: 'accounting.equipment.entity.link' })}</div>
                </div>
                <div className='col-md-10'>
                  <input
                    {...register('link')}
                    placeholder={intl.formatMessage({ id: 'accounting.equipment.entity.link' })}
                    className='form-control form-control-solid'
                    autoComplete='off'
                  />
                  {errors.link && errors.link.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.link.message}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col-md-2'>
                  <div className='form-label mt-3 fs-6 text-dark required'>{intl.formatMessage({ id: 'accounting.equipment.entity.reference' })}</div>
                </div>
                <div className='col-md-4'>
                  <input
                    {...register('reference')}
                    placeholder={intl.formatMessage({ id: 'accounting.equipment.entity.reference' })}
                    className='form-control form-control-solid'
                    autoComplete='off'
                  />
                  {errors.reference && errors.reference.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.reference.message}</span>
                    </div>
                  )}
                </div>
                <div className='col-md-2'>
                  <div className='form-label mt-3 fs-6 text-dark required'>{intl.formatMessage({ id: 'accounting.equipment.entity.quantity' })}</div>
                </div>
                <div className='col-md-4'>
                  <input
                    {...register('quantity')}
                    type="number"
                    placeholder={intl.formatMessage({ id: 'accounting.equipment.entity.quantity' })}
                    className='form-control form-control-solid'
                  />
                  {errors.quantity && errors.quantity.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.quantity.message}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col-md-2'>
                  <div className='form-label mt-3 fs-6 text-dark required'>{intl.formatMessage({ id: 'accounting.equipment.entity.unitValue' })}</div>
                </div>
                <div className='col-md-4'>
                  <input
                    {...register('unitValue')}
                    placeholder={intl.formatMessage({ id: 'accounting.equipment.entity.unitValue' })}
                    className='form-control form-control-solid'
                    autoComplete='off'
                    type="number"
                  />
                  {errors.unitValue && errors.unitValue.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.unitValue.message}</span>
                    </div>
                  )}
                </div>
                <div className='col-md-2'>
                  <div className='form-label mt-3 fs-6 text-dark required'>{intl.formatMessage({ id: 'accounting.equipment.entity.globalValue' })}</div>
                </div>
                <div className='col-md-4'>
                  <input
                    {...register('globalValue')}
                    placeholder={intl.formatMessage({ id: 'accounting.equipment.entity.globalValue' })}
                    className='form-control form-control-solid'
                    autoComplete='off'
                    type="number"
                  />
                  {errors.globalValue && errors.globalValue.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.globalValue.message}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col-md-2'>
                  <div className='form-label mt-3 fs-6 text-dark required'>{intl.formatMessage({ id: 'accounting.equipment.entity.description' })}</div>
                </div>
                <div className='col-md-10'>
                  <input
                    {...register('description')}
                    placeholder={intl.formatMessage({ id: 'accounting.equipment.entity.description' })}
                    className='form-control form-control-solid'
                    autoComplete='off'
                    type="text"
                  />
                  {errors.description && errors.description.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.description.message}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn btn-sm btn-secondary'
            onClick={async () => handleClose(false)}
          >
            {intl.formatMessage({ id: 'action.close' })}
          </button>
          <button
            type='submit'
            className='btn btn-sm btn-secondary'
          >
            {intl.formatMessage({ id: 'action.save' })}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default EquipmentCreate
