import React from 'react'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import ProfileToolbar from './components/ProfileToolbar'
import ProfileListing from './components/ProfileListing'
import { useIntl } from 'react-intl'
import Functions from '../../../../setup/utilities/Functions'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Gestion des employés',
    path: '',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ProfileWrapper: React.FC = () => {
  const [show, setShow] = React.useState(false)
  const handleClose = async () => {
    const isConfirmed = await Functions.sweetAlert(
      '<span class="mt-5">Are you sure you would like to cancel</span>',
      'warning',
      true,
      'Yes, cancel it!',
      'No, return',
      'btn btn-primary mt-10 mb-10',
      'btn btn-active-light mt-10 mb-10',
    )
    if (isConfirmed) {
      setShow(false)
    }
  }
  const handleShow = () => setShow(true)
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs} pageTitle={intl.formatMessage({ id: 'profile.list' })}>
        <ProfileToolbar handleShow={handleShow}/>
      </PageTitle>
      <ProfileListing handleShow={handleShow} handleClose={handleClose} show={show} />
    </>
  )
}

export default ProfileWrapper
