import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import CustomerActivityService from '../../../../../../services/Accounting/CustomerActivityService'
import { useParams } from 'react-router-dom'
import { KTSVG } from '../../../../../../../_metronic/helpers'
import CustomerActivityCreate from './add-activity/CustomerActivityCreate'
import Functions from '../../../../../../../setup/utilities/Functions'
import { Accordion } from 'react-bootstrap'
import CommentCreate from './add-activity/CommentCreate'
import CustomerActivityCommentService from '../../../../../../services/Accounting/CustomerActivityCommentService'

const Activities : React.FC = () => {
  const { id } = useParams()
  const intl = useIntl()
  const [activities, setActivities] = useState<ICustomerActivity[]>([])
  const [activity, setActivity] = useState<ICustomerActivity|undefined|null>()
  const [comment, setComment] = useState<IActivityComment|undefined|null>()
  const [actionComment, setActionComment] = useState<string>()
  const [showComment, setShowComment] = React.useState(true)
  const [action, setAction] = useState<string>('')
  const [show, setShow] = React.useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const handleClose = async (isSubmit: boolean) => {
    if (!isSubmit) {
      const isConfirmed = await Functions.sweetAlert(
        '<span class="mt-5">' + intl.formatMessage({ id: 'action.message' }) + '</span>',
        'warning',
        true,
        intl.formatMessage({ id: 'action.cancel' }),
        intl.formatMessage({ id: 'action.return' }),
        'btn btn-primary mt-10 mb-10',
        'btn btn-active-light mt-10 mb-10',
      )
      if (isConfirmed) {
        setShow(false)
      }
    } else {
      setShow(false)
    }
  }

  const handleShow = (activity: ICustomerActivity|undefined|null, action: string) => {
    setActivity(activity)
    setAction(action)
    setShow(true)
  }

  const handleIsLoading = (loading: boolean) => {
    setIsLoading(loading)
  }

  useEffect(() => {
    CustomerActivityService.getAllCustomerActivities(id).then((response) => {
      setActivities(response.data.data.list)
      setIsLoading(false)
    })
  }, [isLoading])

  useEffect(() => {}, [activity])

  const deleteComment = async (uuid:string|undefined) => {
    const isConfirmed = await Functions.sweetAlert(
      '<span class="mt-5">Are you sure you want to delete this customer</span>',
      'warning',
      true,
      'Yes, delete!',
      'No, cancel',
      'btn btn-danger mt-10 mb-10',
      'btn btn-active-light mt-10 mb-10',
    )
    if (isConfirmed) {
      CustomerActivityCommentService.deleteComment(uuid).then(() => {
        handleIsLoading(true)
      })
    }
  }

  const onComment = (action: string, comment: IActivityComment|undefined|null) => {
    setComment(comment)
    setActionComment(action)
    setShowComment(false)
  }

  useEffect(() => {}, [])

  return (
    <>
      <div className={`card mb-5  mb-xl-10' ${isLoading ? 'overlay overlay-block' : ''}`}>
        <div className='card-header'>
          <div className='card-title m-0'>
          </div>
          <div className="card-toolbar">
            <button
              type='button'
              className='btn btn-sm btn-primary'
              onClick={() => handleShow(null, 'create')}
            >
              <KTSVG
                path='/media/icons/duotune/abstract/abs011.svg'
                className='svg-icon-4 svg-icon-gray-500 me-1'
              />
              {intl.formatMessage({ id: 'accounting.customerActivity.create.button' })}
            </button>
            <CustomerActivityCreate customerId={id} handleClose={handleClose} show={show} action={action} activity={activity} handleIsLoading={handleIsLoading}/>
          </div>
        </div>
        <div className='card-body p-9'>
          <div className="timeline-label">
            {activities && activities.map((item, index) => (
              <div key={`activity_${index}`} className="timeline-item">
                <div className="timeline-label fw-bold text-gray-800 fs-6">{item.startDate}</div>
                <div className="timeline-badge">
                  <i className="fa fa-genderless text-primary fs-1"></i>
                </div>
                <Accordion className="timeline-content ms-5" defaultActiveKey="2">
                  <Accordion.Item eventKey="0" className='border-0'>
                    <div className='d-flex'>
                      <Accordion.Header className='accordion-profile'>
                        <span className="form-check-label med-dark fw-500 fs-3">{item.title}</span>
                      </Accordion.Header>
                    </div>
                    <Accordion.Body className='pt-10'>
                      {item.comments && item.comments.map((comment, index) => (
                        <div className='fv-row' key={`comment_${index}`}>
                          <div className='d-flex align-items-center mb-4'>
                            <span className='bullet bullet-vertical h-40px bg-warning'></span>
                            <div className='flex-grow-1 ms-5'>
                              <span className='text-gray-800 fw-bold fs-6'>
                                {comment.note}
                              </span>
                              <span className='text-muted fw-semibold d-block'>{comment.createdAt}</span>
                            </div>
                            <div className='ms-auto'>
                              <button type='button' className='btn btn-icon btn-light-primary' onClick={() => onComment('edit', comment)}>
                                <i className='bi bi-pencil-square fs-4'></i>
                              </button>
                              <button type='button' className='btn btn-icon btn-light-primary' onClick={() => deleteComment(comment.uuid)}>
                                <i className='bi bi-trash fs-4'></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                      <button
                        hidden={!showComment}
                        type='button'
                        className='btn btn-sm btn-warning'
                        onClick={() => onComment('create', null)}
                      >
                        <KTSVG
                          path='/media/icons/duotune/abstract/abs011.svg'
                          className='svg-icon-4 svg-icon-gray-500 me-1'
                        />
                        {intl.formatMessage({ id: 'accounting.customerActivity.comment.button' })}
                      </button>
                      <div hidden={showComment}>
                        <CommentCreate action={actionComment} comment={comment} activityId={item.uuid} handleIsLoading={handleIsLoading} setShowComment={setShowComment}/>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            ))}
          </div>
          {isLoading &&
            <div className="overlay-layer rounded bg-dark bg-opacity-5">
              <div
                className="spinner-border text-primary"
                role="status"
              >
                <span className="visually-hidden">{intl.formatMessage({ id: 'action.loading' })}</span>
              </div>
            </div>
          }
        </div>
      </div>
    </>
  )
}

export default Activities
