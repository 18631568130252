import React, { useState, useEffect } from 'react'
import EmployeeService from '../../../../../../services/Hr/EmployeeService'
import Symbol from '../../../../../../../_metronic/partials/common/Symbol'
import Functions from '../../../../../../../setup/utilities/Functions'
import { KTSVG } from '../../../../../../../_metronic/helpers'
import { useIntl } from 'react-intl'

type Props = {
  handleSelectedId: (id: string) => void
}

const EmployeesList: React.FC<Props> = ({ handleSelectedId }) => {
  const [employees, setEmployees] = useState<IEmployee[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const intl = useIntl()

  useEffect(() => {
    EmployeeService.getAllEmployees(0, 0, false).then((response) => {
      setEmployees(response.data.data)
      setIsLoading(false)
    })
  }, [isLoading])

  return (
    <div className={`${isLoading ? 'overlay overlay-block' : ''}`}>
      <div className={`d-flex flex-column ${isLoading ? 'overlay-wrapper' : ''}`}>
        <div className='flex-column flex-lg-row-auto mb-10 mb-lg-0 '>
          <div className='card card-flush'>
            <div className='card-header pt-7'>
              <form className='w-100 position-relative' autoComplete='off'>
                <KTSVG
                  path='/media/icons/duotune/general/gen021.svg'
                  className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y'
                />

                <input
                  type='text'
                  className='form-control form-control-solid px-15'
                  name='search'
                  placeholder={intl.formatMessage({ id: 'employee.search.display' })}
                />
              </form>
            </div>
            <div className='card-body pt-5 h-600px overflow-auto' id='kt_chat_contacts_body'>
              {employees && employees.map((employee, index) => (
                <div key={`employee_detail_${index}`}>
                  <div className='d-flex py-4 cursor-pointer' onClick={() => handleSelectedId(employee.uuid)}>
                    <Symbol
                      picture={employee.profilePicture}
                      picturePath='/admin/uploads/users/'
                      withText={true}
                      widthClassName='symbol-40px me-3'
                      text={(employee.firstName && employee.lastName) ? Functions.fullName(employee.firstName, employee.lastName) : ''}
                      shortText={employee.firstName ? Functions.firstLetterUpperCase(employee.firstName.charAt(0)) : ''}
                      description={employee.email}
                      backgroundClass='bg-light-danger'
                      textClassName='text-danger'
                      isCircular={true}
                      descriptionColor={'text-muted'}
                      textColor={'text-dark'}
                    />
                  </div>
                  <div className='separator separator-dashed d-none'></div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {isLoading &&
            <div className="overlay-layer rounded bg-dark bg-opacity-5">
              <div
                className="spinner-border text-primary"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
      }
    </div>
  )
}

export default EmployeesList
