import cx from 'classnames'
import * as React from 'react'
import {memo, useCallback, useState} from 'react'
import { Dropdown, Form } from 'react-bootstrap'
import DropdownMenu from 'react-bootstrap/DropdownMenu'
import { Calender } from './calendar'

type PropsInput = {
    onClick: (e: any) => void,
    name: string,
    id: string,
    displayDate: string,
    label: string,
    placeholder: string,
}

const DateInput = React.forwardRef<HTMLDivElement,PropsInput>((props, ref) => {
    const [position, setPosition] = useState({
        current: undefined,
        focused: false,
    });

    const handleClick = useCallback(
        (e: any) => {
            e.preventDefault() && e.stopPropagation();
            props.onClick(e);
        },
        [props]
    );
    const handleFocus = useCallback(
        (e: any) => {
            e.preventDefault() && e.stopPropagation();
            let inputFocused = e.target.name || e.target.id;
            e.target.style.cursor = 'pointer';

            // if (position.current === inputFocused && position.focused === true) {
            //   return;
            // }
            setPosition({current: undefined,focused: false});
            setPosition({ current: inputFocused, focused: true });

        },
        [ref, position, props]
    );

    const handleBlur = useCallback(
        (e: any) => {
            e.stopPropagation() && e.preventDefault();

            let inputFocused = e.target.name || e.target.id;

            setPosition({ current: inputFocused, focused: false });
        },
        [setPosition, props]
    );

    return (
        <div ref={ref}>
            <Form.Control
                aria-label={props.label}
                name={props.name}
                id={props.id}
                onClick={handleClick}
                onFocus={handleFocus}
                onBlur={handleBlur}
                defaultValue={props.displayDate}
                style={{ cursor: 'pointer' }}
                placeholder={props.placeholder}
                className={cx(
                    'form-control',
                    'form-control-solid',
                    'position-relative',
                    {
                        focus: position.focused,
                        'select-focus': position.focused,
                    }
                )}
            />
        </div>
    );
});

type Props = {
    placeholder: string,
    label: string,
    id: string,
    name: string,
    ariaLabelledby: string,
    format: string,
    locale: string,
    specialDays: any,
    date: string,
    onHandleChangeDate: any
}

const Component = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
    const {
        placeholder,
        label,
        name,
        ariaLabelledby,
    } = props;

    return (
        <div className="position-relative" id={name}>
            <div className={cx('position-relative')} ref={ref}>
                <div className="position-relative">
                    <Dropdown>
                        <Dropdown.Toggle
                            as={DateInput}
                            label={label}
                            aria-label={ariaLabelledby}
                            placeholder={placeholder}
                            displayDate={props.date}
                        >
                            Seç
                        </Dropdown.Toggle>
                        <DropdownMenu>
                            <Calender
                                format={props.format}
                                locale={props.locale}
                                specialDays={props.specialDays}
                                onChange={props.onHandleChangeDate}
                            />
                        </DropdownMenu>
                    </Dropdown>
                </div>
            </div>
        </div>
    );
});

export const DateTimePicker = memo(Component);
