import React from 'react'

type Props = {
  isDisplayContact: boolean,
  label: string,
  isQuantity: boolean,
  isDiscount: boolean,
  isDisplayTotalAmount: boolean,
  isDisplayGrossAmount: boolean,
  color: string,
  supplier: ISupplier|null,
  date: any,
  formValues: { equipment: string, name: any, quantity: any, price: any, discount: any, discountType: any, vat: any, gross: any, total: any }[],
  pdfRef: any
}


const SupplierOrderPreview: React.FC<Props> = ({
  label,
  color,
  date,
  supplier,
  isDiscount,
  isDisplayTotalAmount,
  isDisplayGrossAmount,
  isDisplayContact,
  isQuantity,
  formValues,
  pdfRef,
}) => (
  <div>
    <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10" ref={pdfRef}>
      <div className={'card'}>
        <div className={'card-body p-12'}>
          <form noValidate>
            <div className="d-flex flex-column align-items-start flex-xxl-row">
              <div className="d-flex align-items-start fw-row me-4 order-2">
                <span className="fs-2x fw-bold text-gray-800">{label}</span>
              </div>
              <div className="d-flex align-items-center justify-content-end flex-equal order-3">
                <div className="image-input image-input-outline" data-kt-image-input="true">
                  <span className="form-control form-control-flush fw-bold fs-3">
                    {date}
                  </span>
                </div>
              </div>
            </div>
            <div className="row gx-10 mb-10">
              <div className="col-lg-6">
                <label className="form-label fs-6 fw-bold text-gray-700 mb-3">Client</label>
                <table className="table table-flush fw-semibold gy-1" hidden={isDisplayContact}>
                  <tbody>
                    {supplier?.contacts && supplier?.contacts.map((item) => (
                      <>
                        {item.isDefault ?
                          <>
                            <tr>
                              <td className="text-muted min-w-125px w-125px">Name</td>
                              <td className="text-gray-800">{supplier.name}</td>
                            </tr>
                            <tr>
                              <td className="text-muted min-w-125px w-125px">To</td>
                              <td className="text-gray-800">{item.name}</td>
                            </tr>
                            <tr>
                              <td className="text-muted min-w-125px w-125px">Phone</td>
                              <td className="text-gray-800">{item.phone}</td>
                            </tr>
                            <tr>
                              <td className="text-muted min-w-125px w-125px">Email</td>
                              <td className="text-gray-800">{item.email}</td>
                            </tr>
                          </> :
                          <></>
                        }
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table g-5 gs-0 mb-0 fw-bolds">
                <thead>
                  <tr className="border-bottom fs-7 fw-bold text-uppercase">
                    <th className="min-w-200px w-100px">Item</th>
                    <th className="min-w-75px w-100px" hidden={isQuantity}>QTY</th>
                    <th className="min-w-75px w-125px" hidden={isDisplayTotalAmount}>Price</th>
                    <th className="min-w-75px w-100px">Vat</th>
                    <th className="min-w-75px w-125px" hidden={isDiscount}>Discount</th>
                    <th className="min-w-125px w-125px" hidden={isDisplayGrossAmount}>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {formValues.map((element, index) => (
                    <tr className="border-bottom border-bottom-dashed" key={index}>
                      <td>
                        <span style={{ color }}>{element.name && element.name}</span>
                      </td>
                      <td className="ps-0" hidden={isQuantity}>
                        <span className="text-dark">{element.quantity && element.quantity}</span>
                      </td>
                      <td hidden={isDisplayTotalAmount}>
                        <span className="text-dark">{element.price && element.price.value}</span>
                      </td>
                      <td>
                        <span className="text-dark">{element.vat && element.vat}</span>
                      </td>
                      <td hidden={isDiscount}>
                        <div className="image-input image-input-outline" data-kt-image-input="true">
                          <span className="text-dark">{element.discount && element.discount}</span>
                        </div>

                      </td>
                      <td hidden={isDisplayGrossAmount}>
                        <span className="text-dark">{element.total && element.total.value}</span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <table className="table g-5 gs-0 mb-0 fw-bolds">
                <tfoot>
                  <tr className="fs-6 fw-bold text-gray-700">
                    <th>
                    </th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th className="ps-0">
                    </th>
                    <th className="text-end">
                    </th>
                  </tr>
                </tfoot>
              </table>
              <table className="table g-5 gs-0 mb-0 fw-bolds">
                <tfoot className="border-top border-dashed">
                  <tr className="fs-6 fw-bold text-gray-700" style={{ borderColor: color }}>
                    <th>
                    </th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th className="ps-0">
                      <div className="d-flex flex-column align-items-start">
                        <div className="fs-6">Total HT</div>
                        <div className="fs-6">Total VAT</div>
                        <div className="fs-6">Total Discount</div>
                        <div className="fs-6">Total TTC</div>
                        <div className="fs-6">Timbre Fiscale</div>
                        <div className="fs-6">Discount</div>
                      </div>
                    </th>
                    <th className="text-end">
                      <div className="d-flex flex-column align-items-start">
                        <span data-kt-element="sub-total">0.00</span>
                        <span data-kt-element="sub-total">0.00</span>
                        <span data-kt-element="sub-total">0.00</span>
                        <span data-kt-element="sub-total">0.00</span>
                        <span data-kt-element="sub-total">0.00</span>
                        <span data-kt-element="sub-total">0.00</span>
                      </div>
                    </th>
                  </tr>
                  <tr className="fw-bold text-gray-700">
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th className="fs-4 ps-0">Total</th>
                    <th className="text-end fs-4 text-nowrap">
                      <div className="d-flex flex-column align-items-start">
                        <span data-kt-element="sub-total">0.00</span>
                      </div>
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
)

export default SupplierOrderPreview
