import React, { useEffect, useState } from 'react'
import { PageLink, PageTitle } from '../../../../../../_metronic/layout/core'
import ProfileDisplayToolbar from './components/ProfileDisplayToolbar'
import ProfileDisplay from './components/ProfileDisplay'
import { useParams } from 'react-router-dom'
import ProfileService from '../../../../../services/Hr/ProfileService'
import ProfileCreate from '../add/ProfileCreate'
import { useIntl } from 'react-intl'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Gestion des employés',
    path: '',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Type de profiles',
    path: '/profile',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

type ICheckedFeature = {
  uuid: string,
  name: string,
  slug: string,
  parentFeatureUuid: string,
  isParent: boolean,
}

const ProfileDisplayWrapper: React.FC = () => {
  const { id } = useParams()
  const [profile, setProfile] = useState<IProfile>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [features, setFeatures] = useState<IFeature[]>([])
  const [checkedFeatures, setCheckedFeatures] = useState<ICheckedFeature[]>([])
  const [show, setShow] = React.useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const intl = useIntl()

  const handleIsLoading = (value: boolean) => {
    setIsLoading(value)
  }

  const handleCheckedFeatures = (checkedFeatures: ICheckedFeature[]) => {
    setCheckedFeatures(checkedFeatures)
  }

  useEffect(() => {
    ProfileService.getProfile(id).then((response) => {
      setProfile(response.data.data)
      setIsLoading(false)
      ProfileService.getProfileFeatures(response.data.data.uuid).then((res) => {
        setFeatures(res.data.data)
        if (checkedFeatures.length === 0) {
          res.data.data.forEach((feature: IFeature) => {
            checkedFeatures.push({
              uuid: feature.uuid,
              name: feature.name,
              slug: feature.slug,
              parentFeatureUuid: feature.parent,
              isParent: feature.isParent,
            })
            feature.children.forEach((child: IFeature) => {
              checkedFeatures.push({
                uuid: child.uuid,
                name: child.name,
                slug: child.slug,
                parentFeatureUuid: child.parent,
                isParent: child.isParent,
              })
            })
          })
        }
      })
    })
  }, [isLoading])

  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs} pageTitle={intl.formatMessage({ id: 'profile.show' }) + ': ' + profile?.name}>
        <ProfileDisplayToolbar
          handleShow={handleShow}
        />
      </PageTitle>
      {profile && <ProfileDisplay
        profile={profile}
        isLoading={isLoading}
        handleIsLoading={handleIsLoading}
        features={features}
        handleShow={handleShow}
        checkedFeatures={checkedFeatures}
      />}
      {checkedFeatures && checkedFeatures.length > 0 && (
        <ProfileCreate
          profile={profile}
          show={show}
          handleClose={handleClose}
          handleIsLoading={handleIsLoading}
          checkedFeatures={checkedFeatures}
          handleCheckedFeatures={handleCheckedFeatures}
          action='edit'
        />
      )}
    </>
  )
}

export default ProfileDisplayWrapper
