import React from 'react'
import SalesTeamEmployeesTable from './SalesTeamEmployeesTable'
import { useIntl } from 'react-intl'
import SalesTeamItem from '../../SalesTeamItem'
import Symbol from '../../../../../../../_metronic/partials/common/Symbol'
import Functions from '../../../../../../../setup/utilities/Functions'
import SalesTeamCreate from '../../add/SalesTeamCreate'

type Props = {
    handleShow: (salesTeam: ISalesTeam|undefined|null, action:string) => void
    handleClose: (isSubmit: boolean) => void,
    show: boolean,
    action: string
    salesTeam: ISalesTeam|undefined
    isLoading: boolean
    handleIsLoading: (value: boolean) => void
    managers: IEmployee[]
}

const SalesTeamDisplay: React.FC<Props> = ({
  handleShow,
  handleClose,
  show,
  salesTeam,
  isLoading,
  handleIsLoading,
  managers,
  action,
}) => {
  const intl = useIntl()

  return (
    <>
      {salesTeam ? (
        <>
          <div className='row'>
            <div className='col-xl-4'>
              <SalesTeamItem
                isCreate={false}
                handleShow={handleShow}
                salesTeam={salesTeam}
                isLoading={isLoading}
                handleIsLoading={handleIsLoading}
                page='display'
              />
              <div className='card card-stretch mt-5'>
                <div className='card-header'>
                  <h3 className='card-title fs-2 fw-500'>
                    {intl.formatMessage({ id: 'manager.plural.label' })}
                  </h3>
                </div>
                <div className='card-body'>
                  {managers && managers.map((manager, index) => (
                    <div key={`manager_detail_${index}`}>
                      <div className='d-flex flex-stack py-4'>
                        <Symbol
                          picture={manager.profilePicture}
                          picturePath='/admin/uploads/users/'
                          withText={true}
                          widthClassName='symbol-40px me-3'
                          text={(manager.firstName && manager.lastName) ? Functions.fullName(manager.firstName, manager.lastName) : ''}
                          shortText={manager.firstName ? Functions.firstLetterUpperCase(manager.firstName.charAt(0)) : ''}
                          description={manager.email}
                          backgroundClass='bg-light-danger'
                          textClassName='text-danger'
                          isCircular={true}
                          descriptionColor={'text-muted'}
                          textColor={'text-dark'}
                        />
                      </div>
                      <div className='separator separator-dashed d-none'></div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className='col-xl-8'>
              <SalesTeamEmployeesTable salesTeamUuid={salesTeam.uuid}/>
            </div>
          </div>
          <SalesTeamCreate salesTeam={salesTeam} show={show} handleClose={handleClose} handleIsLoading={handleIsLoading} action={action}/>
        </>
      ) : (<></>)}
    </>
  )
}

export default SalesTeamDisplay
