import apiService from '../../../setup/axios/ApiService'

const prefixURL = 'invoice'

const getAllInvoices = (currentPage:number, limit:number, customerName: string, customerType: boolean, status: string, startDate: string, endDate: string) => apiService.get(`${prefixURL}/`, {
  page: currentPage,
  limit,
  startDate,
  endDate,
  customerName,
  status,
  customerType,
})

const createInvoice = (data: any) => apiService.post(`${prefixURL}/`, data)

const updateInvoice = (uuid: string|undefined, data: any) => apiService.put(`${prefixURL}/${uuid}`, data)

const getInvoice = (uuid:string|undefined) => apiService.get(`${prefixURL}/${uuid}`)

const patchInvoiceConfig = (uuid: string|undefined, data: any) => apiService.patch(`${prefixURL}/${uuid}/config`, data)

const setReferenceForInvoice = (uuid: string|undefined, workflowId:string, data: any) => apiService.patch(`${prefixURL}/${uuid}/reference/workflow/${workflowId}`, data)

const getInvoicesByCustomer = (currentPage:number, limit:number, uuid: string|undefined) => apiService.get(`${prefixURL}/customer/${uuid}`, {
  page: currentPage,
  limit,
})

const InvoiceService = {
  getAllInvoices,
  createInvoice,
  updateInvoice,
  getInvoice,
  patchInvoiceConfig,
  setReferenceForInvoice,
  getInvoicesByCustomer,
}

export default InvoiceService
