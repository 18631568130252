import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import PaymentHeader from './PaymentHeader'
import { useIntl } from 'react-intl'
import TableHeaderSolid from '../../../../../../_metronic/partials/med-table/TableHeaderSolid'
import Symbol from '../../../../../../_metronic/partials/common/Symbol'
import Functions from '../../../../../../setup/utilities/Functions'
import { KTSVG } from '../../../../../../_metronic/helpers'
import PaginationBar from '../../../../../../_metronic/partials/pagination/PaginationBar'

type Props = {
  invoice: IInvoice|null,
  show: boolean,
  handleClose: (show: boolean) => void,
}

const PaymentDetails : React.FC<Props> = (
  {
    show,
    handleClose,
    invoice,
  }) => {
  const intl = useIntl()
  const headers = [
    {
      minWidth: 'w-100px',
      text: intl.formatMessage({ id: 'accounting.payment.entity.amount' }),
    },
    {
      minWidth: 'w-100px',
      text: intl.formatMessage({ id: 'accounting.payment.entity.date' }),
    },
    {
      minWidth: 'w-100px',
      text: intl.formatMessage({ id: 'accounting.payment.entity.paymentType' }),
    },
    {
      minWidth: 'w-50px',
      text: intl.formatMessage({ id: 'accounting.payment.entity.user' }),
    },
    {
      minWidth: 'w-50px',
      text: intl.formatMessage({ id: 'actions' }),
    },
  ]

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = React.useState<number>(1)

  const handleCurrentPage = (page:number) => {
    setCurrentPage(page)
    setTotalPages(parseInt(String(invoice?.payments.length || 0 / 10)))
  }

  return (
    <>
      <Modal show={show} onHide={() => handleClose(false)} dialogClassName={'mw-800px'}>
        <Modal.Body className="overflow-auto">
          <PaymentHeader invoice={invoice} remainingPayment={invoice?.remainingAmount || 0} />
          <div className="table-responsive">
            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 mt-10">
              <TableHeaderSolid headers={headers} />
              <tbody>
                {invoice?.payments && invoice?.payments.length > 0 ? (
                  invoice?.payments.map((payment, index) => (
                    <tr key={`payment_${index}`}>
                      <td>
                        <span className="text-dark ms-2">{payment.amount}</span>
                      </td>
                      <td>
                        <span className="text-dark ms-2">{payment.date}</span>
                      </td>
                      <td>
                        <span className="text-dark ms-2">{payment.method}</span>
                      </td>
                      <td>
                        <div className='me-2 d-flex flex-stack flex-wrap'>
                          <div className="symbol-group symbol-hover">
                            <Symbol
                              key={`user_${payment.uuid}`}
                              picture={payment.user.profilePicture}
                              picturePath='/admin/uploads/users/'
                              withText={false}
                              widthClassName='symbol-35px'
                              text={Functions.fullName(payment.user.firstName, payment.user.lastName)}
                              shortText={payment.user.firstName.charAt(0)}
                              description={null}
                              backgroundClass='bg-primary'
                              textClassName='text-inverse-primary'
                              isCircular={true}
                              descriptionColor={'text-muted'}
                              textColor={'text-dark'}
                            />
                          </div>
                        </div>
                      </td>
                      <td className="text-end">
                        <a href="src/app/modules/accounting/invoice/components/payment/PaymentDetails#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 w-32px h-32px">
                          <KTSVG
                            path='/media/icons/duotune/abstract/trash.svg'
                            className='svg-icon svg-icon-2'
                          />
                        </a>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 fs-4 align-content-center justify-content-center mt-5'>
                        {intl.formatMessage({ id: 'action.not.found' })}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {totalPages && totalPages > 1 ? (
            <PaginationBar
              totalPages={totalPages}
              currentPage={currentPage ? currentPage : 1}
              handleCurrentPage={handleCurrentPage}
            />
          ) : (<></>)}
        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn btn-sm btn-secondary'
            onClick={async () => handleClose(false)}
          >
            {intl.formatMessage({ id: 'action.close' })}
          </button>
        </Modal.Footer>
      </Modal>

    </>
  )
}

export default PaymentDetails
