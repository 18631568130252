import { createRoot } from 'react-dom/client'
import axios from 'axios'
import { Chart, registerables } from 'chart.js'
import { QueryClient, QueryClientProvider } from 'react-query'
import store, { persistor } from './setup/redux/Store'
import * as _redux from './setup'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n'
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import { App } from './app/App'
import KeycloakService from './app/security/KeycloakService'

const queryClient = new QueryClient()

Chart.register(...registerables)

const container = document.getElementById('root')
const root = createRoot(container as HTMLElement)


const renderApp = () => {
  root.render(
    <QueryClientProvider client={queryClient}>
      <MetronicI18nProvider>
        <Provider store={store}>
          <PersistGate persistor={persistor} loading={<div>Loading...</div>}>
            <App />
          </PersistGate>
        </Provider>
      </MetronicI18nProvider>
    </QueryClientProvider>,
  )
}

KeycloakService.initKeycloak(renderApp)
_redux.setupAxios(axios, store)
