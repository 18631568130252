import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import AddressCreate from './AddressCreate'
import { useParams } from 'react-router-dom'
import ContactService from '../../../../services/Accounting/ContactService'


const contactSchema = Yup.object().shape({
  name: Yup.string()
    .required('Ce champs est obligatoire'),
  description: Yup.string()
    .required('Ce champs est obligatoire'),
  email: Yup.string()
    .required('Ce champs est obligatoire'),
  phone: Yup.string()
    .required('Ce champs est obligatoire'),
  jobTitle: Yup.string()
    .required('Ce champs est obligatoire'),
  isDefault: Yup.boolean()
    .required('Ce champs est obligatoire'),
})

type Inputs = {
  name: string,
  description: string,
  email: string,
  phone: string,
  jobTitle: string,
  isDefault: boolean,
  addresses : IAddress []
}

type Props = {
  supplier: ISupplier|null|undefined,
  customer: ICustomer|null|undefined,
  contact: IContact|null|undefined,
  handleIsLoading: (isLoading: boolean) => void,
  showContact: boolean,
  handleCloseContact: (isSubmit: boolean) => void,
  actionContact: string|undefined,
  selectedAddress: IAddress|undefined,
  showAddress: boolean,
  actionAddress: string|undefined,
  handleShowAddress: (address : IAddress|null, action : string) => void,
  handleCloseAddress: () => void,
}

const ContactCreate : React.FC<Props> = ({
  handleCloseContact,
  handleCloseAddress,
  contact,
  showContact,
  supplier,
  customer,
  handleIsLoading,
  actionContact,
  selectedAddress,
  handleShowAddress,
  actionAddress,
  showAddress,
}) => {
  const { id } = useParams()
  const [addresses, setAddresses] = useState<Array<IAddress>>([])
  const intl = useIntl()

  const config = {
    resolver: yupResolver(contactSchema),
    defaultValues: {
      name: contact?.name ?? '',
      description: contact?.description ?? '',
      email: contact?.email ?? '',
      phone: contact?.phone ?? '',
      jobTitle: contact?.jobTitle ?? '',
      isDefault: contact?.isDefault ?? false,
      addresses: contact?.addresses ?? [],
    },
  }

  const { register, formState: { errors }, reset, handleSubmit, setValue } = useForm<Inputs>(config)

  const deleteOneAddress = (address: IAddress) => {
    const addressesArray = [...addresses]
    const index = addressesArray.indexOf(address)
    if (index !== -1) {
      addressesArray.splice(index, 1)
      setAddresses(addressesArray)
    }
  }

  const handleCreateAddress = (address : IAddress) => {
    addresses.push(address)
  }

  useEffect(() => {
    if (actionContact === 'create') {
      setAddresses([])
    } else {
      setAddresses(contact?.addresses || [])
    }
    setValue('name', contact?.name || '')
    setValue('description', contact?.description || '')
    setValue('jobTitle', contact?.jobTitle || '')
    setValue('email', contact?.email || '')
    setValue('phone', contact?.phone || '')
    setValue('isDefault', contact?.isDefault || false)
  }, [contact, selectedAddress])

  useEffect(() => {
    setValue('addresses', addresses)
  }, [addresses])

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const contactToAdd = {
      name: data.name,
      description: data.description,
      jobTitle: data.jobTitle,
      email: data.email,
      phone: data.phone,
      addresses: data.addresses,
    }
    if (actionContact === 'create') {
      ContactService.createContact(contactToAdd, id!.toString(), customer ? 'customer' : 'supplier').then(() => {
        handleCloseContact(true)
        reset()
        handleIsLoading(true)
      })
    } else {
      ContactService.updateContact(contactToAdd, contact!.uuid).then(() => {
        handleCloseContact(true)
        reset()
        handleIsLoading(true)
      })
    }
  }


  return (
    <Modal show={showContact} onHide={() => handleCloseContact(false)} dialogClassName={'mw-1000px'}>
      <Modal.Header closeButton>
        <Modal.Title>{intl.formatMessage({ id: 'accounting.contact.create.modal' })} {customer ? customer.displayName : supplier?.name}</Modal.Title>
      </Modal.Header>
      <form className='form w-100' onSubmit={handleSubmit(onSubmit)} noValidate id='create_contact_form'>
        <Modal.Body>
          <div className='row mb-5'>
            <div className='col-md-2'>
              <label className='form-label mt-3 fs-6 text-dark required'>{intl.formatMessage({ id: 'accounting.contact.entity.name' })}</label>
            </div>
            <div className='col-md-10'>
              <input
                {...register('name')}
                placeholder={intl.formatMessage({ id: 'accounting.contact.entity.name' })}
                className={`form-control form-control-solid form-control-lg col-md-10 ${errors.name ? 'is-invalid' : ''}`}
                type='text'
                name='name'
                autoComplete='off'
              />
              {errors.name && errors.name.message && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{errors.name.message}</span>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-5'>
            <div className='col-md-2'>
              <label className='form-label mt-3 fs-6 text-dark required'>{intl.formatMessage({ id: 'accounting.contact.entity.description' })}</label>
            </div>
            <div className='col-md-10'>
              <textarea
                {...register('description')}
                placeholder={intl.formatMessage({ id: 'accounting.contact.entity.description' })}
                className={`form-control form-control-solid form-control-lg col-md-10 ${errors.description ? 'is-invalid' : ''}`}
                name='description'
                autoComplete='off'
              />
              {errors.description && errors.description.message && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{errors.description.message}</span>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-5'>
            <div className='col-md-2'>
              <label className='form-label mt-3 fs-6 text-dark required'>{intl.formatMessage({ id: 'accounting.contact.entity.jobTitle' })}</label>
            </div>
            <div className='col-md-10'>
              <input
                {...register('jobTitle')}
                placeholder={intl.formatMessage({ id: 'accounting.contact.entity.jobTitle' })}
                className={`form-control form-control-solid form-control-lg col-md-10 ${errors.jobTitle ? 'is-invalid' : ''}`}
                type='text'
                name='jobTitle'
                autoComplete='off'
              />
              {errors.jobTitle && errors.jobTitle.message && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{errors.jobTitle.message}</span>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-5'>
            <div className='col-md-2'>
              <label className='form-label mt-3 fs-6 text-dark required'>{intl.formatMessage({ id: 'accounting.contact.entity.email' })}</label>
            </div>
            <div className='col-md-4'>
              <input
                {...register('email')}
                placeholder={intl.formatMessage({ id: 'accounting.contact.entity.email' })}
                className={`form-control form-control-solid form-control-lg col-md-10 ${errors.email ? 'is-invalid' : ''}`}
                type='text'
                name='email'
                autoComplete='off'
              />
              {errors.email && errors.email.message && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{errors.email.message}</span>
                </div>
              )}
            </div>
            <div className='col-md-2'>
              <label className='form-label mt-3 fs-6 text-dark required'>{intl.formatMessage({ id: 'accounting.contact.entity.phone' })}</label>
            </div>
            <div className='col-md-4'>
              <input
                {...register('phone')}
                placeholder={intl.formatMessage({ id: 'accounting.contact.entity.phone' })}
                className={`form-control form-control-solid form-control-lg col-md-10 ${errors.phone ? 'is-invalid' : ''}`}
                type='text'
                name='phone'
                autoComplete='off'
              />
              {errors.phone && errors.phone.message && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{errors.phone.message}</span>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-5'>
            <div className='col-md-2'>
              <div className='form-label mt-3 fs-6 text-dark '>{intl.formatMessage({ id: 'accounting.contact.entity.isDefault' })}</div>
            </div>
            <div className='col-md-10'>
              <div className="form-check form-switch form-check-custom form-check-solid">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexSwitchChecked"
                />
              </div>
            </div>
          </div>
          <div className="separator separator-dashed border-secondary border-2 my-10"></div>
          <div>
            <div className='row'>
              <div className='col-md-8'></div>
              <div className='col-md-4'>
                <button
                  type='button'
                  className='btn btn-sm btn-success float-end fs-4 fw-500'
                  onClick={() => handleShowAddress(null, 'create')}
                >
                  {intl.formatMessage({ id: 'accounting.contact.address.create.button' })}
                </button>
              </div>
            </div>
            <div className="separator separator-dashed border-secondary border-2 mt-10 mb-5"></div>
            <div className='mt-5'>
              {addresses && addresses.map((address, index) => (
                <div key={`address_${index}`}>
                  <div className='d-flex'>
                    <span className="form-check-label med-dark fw-500 fs-5">{address?.name}</span>
                    <div className='ms-auto'>
                      <button type='button' className='btn btn-icon btn-light-primary' onClick={() => handleShowAddress(address, 'edit')}>
                        <i className='bi bi-pencil-square fs-4'></i>
                      </button>
                      <button type='button' className='btn btn-icon btn-light-primary' onClick={() => deleteOneAddress(address)}>
                        <i className='bi bi-trash fs-4'></i>
                      </button>
                    </div>
                  </div>
                  <div className="separator separator-dashed border-secondary border-2 my-5"></div>
                </div>
              ))}
            </div>
          </div>
          <AddressCreate
            action={actionAddress}
            address={selectedAddress}
            show={showAddress}
            handleCreateAddress={handleCreateAddress}
            handleClose={handleCloseAddress}
            deleteOneAddress={deleteOneAddress}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn btn-sm btn-secondary'
            onClick={() => {
              handleCloseContact(false)
              reset()
            }}
          >
            {intl.formatMessage({ id: 'action.close' })}
          </button>
          <button
            type='submit'
            className='btn btn-sm btn-secondary'
          >
            {intl.formatMessage({ id: 'action.save' })}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
export default ContactCreate
