import React, { useState, useEffect } from 'react'
import { Accordion } from 'react-bootstrap'
import FeatureService from '../../../../../services/Hr/FeatureService'
import Functions from '../../../../../../setup/utilities/Functions'
import { useIntl } from 'react-intl'

type Props = {
  parentFeatureUuid: string,
  updateCheckedFeature: (features: IFeature[]) => void,
  checkedFeatures: IFeature[],
}

const PermissionList: React.FC<Props> = ({ parentFeatureUuid, updateCheckedFeature, checkedFeatures }) => {
  const [features, setFeatures] = useState<Array<IFeature>>([])
  const [featuresSelected] = useState<Array<IFeature>>(checkedFeatures)
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const [featuresLoading, setFeaturesLoading] = useState<boolean>(true)
  const intl = useIntl()

  const onSelectAllChange = (event: any) => {
    if (event.target.checked) {
      features.forEach((feature) => {
        const boolParent = Functions.checkExist(checkedFeatures, 'uuid', feature.uuid)
        if (!boolParent) {
          featuresSelected.push(feature)
        }
        feature.children.forEach((child) => {
          const boolChild = Functions.checkExist(checkedFeatures, 'uuid', child.uuid)
          if (!boolChild) {
            featuresSelected.push(child)
          }
        })
      })
      updateCheckedFeature(featuresSelected)
    } else {
      features.forEach((feature) => {
        Functions.popItem(featuresSelected, 'uuid', feature.uuid)
        feature.children.forEach((child) => {
          Functions.popItem(featuresSelected, 'uuid', child.uuid)
        })
      })
      updateCheckedFeature(featuresSelected)
    }
    setSelectAll(event.target.checked)
  }

  const checkIfExist = (item: any) => {
    let bool = false
    checkedFeatures.forEach((checkedFeature) => {
      if (checkedFeature.uuid === item.uuid) {
        bool = true
      }
    })
    return bool
  }

  const onChange = (event: any, feature: IFeature) => {
    if (!event.target.checked) {
      let newCheckedFeatures = checkedFeatures.filter((checkedFeature) => checkedFeature.uuid !== feature.uuid)
      if (!feature.isParent) {
        const bool = Functions.checkExist(newCheckedFeatures, 'parent', feature.parent)
        if (!bool) {
          const parentFeature = features.find((parent) => parent.uuid === feature.parent)
          if (parentFeature !== undefined) {
            newCheckedFeatures = newCheckedFeatures.filter((checkedFeature) => checkedFeature.uuid !== parentFeature.uuid)
          }
        }
      } else {
        const parentFeature = features.find((parent) => parent.uuid === feature.uuid)
        if (parentFeature !== undefined) {
          parentFeature.children.forEach((child) => {
            const bool = Functions.checkExist(newCheckedFeatures, 'uuid', child.uuid)
            if (bool) {
              newCheckedFeatures = newCheckedFeatures.filter((checkedFeature) => checkedFeature.uuid !== child.uuid)
            }
          })
        }
      }
      updateCheckedFeature(newCheckedFeatures)
    } else {
      const newCheckedFeatures = [...checkedFeatures]
      newCheckedFeatures.push(feature)
      if (!feature.isParent) {
        const bool = Functions.checkExist(newCheckedFeatures, 'uuid', feature.parent)
        if (!bool) {
          const parentFeature = features.find((parent) => parent.uuid === feature.parent)
          if (parentFeature !== undefined) {
            newCheckedFeatures.push(parentFeature)
          }
        }
      } else {
        const parentFeature = features.find((parent) => parent.uuid === feature.uuid)
        if (parentFeature !== undefined) {
          parentFeature.children.forEach((child) => {
            const bool = Functions.checkExist(newCheckedFeatures, 'uuid', child.uuid)
            if (!bool) {
              newCheckedFeatures.push(child)
            }
          })
        }
      }
      updateCheckedFeature(newCheckedFeatures)
    }
  }

  useEffect(() => {
    FeatureService.getFeatureChildren(parentFeatureUuid).then((response) => {
      setFeatures(response.data.data)
      setFeaturesLoading(false)
      const array: IFeature[] = []
      if (response.data.data) {
        response.data.data.forEach((feature: IFeature) => {
          array.push(feature)
          if (feature.children) {
            feature.children.forEach((child: IFeature) => {
              array.push(child)
            })
          }
        })
      }
      let bool = true
      array.forEach((feature) => {
        if (!Functions.checkExist(checkedFeatures, 'uuid', feature.uuid)) {
          bool = false
        }
      })
      setSelectAll(bool)
    })
  }, [featuresLoading, parentFeatureUuid])

  useEffect(() => {}, [checkedFeatures])

  return (
    <>
      {features.length > 0 ? (
        <div className='mt-4'>
          <div>
            {intl.formatMessage({ id: 'feature.choose' })}
          </div>
          <div className='table-responsive'>
            <table className='table align-middle table-row-dashed fs-6'>
              <tbody className='text-gray-600 fw-bold'>
                <tr>
                  <td>
                    <label className="form-check form-check-sm form-check-custom me-9">
                      <input className="form-check-input" type="checkbox" id="select_all_permissions" checked={selectAll} onChange={onSelectAllChange}/>
                      <span className="form-check-label med-dark fw-500">{intl.formatMessage({ id: 'action.choose.all' })}</span>
                    </label>
                  </td>
                </tr>
                {features && features.map((item, index) => (
                  <tr key={`select_${index}`}>
                    <td>
                      <Accordion defaultActiveKey="1">
                        <Accordion.Item eventKey="0" className='border-0'>
                          <Accordion.Header className='mb-2'>
                            <label className="form-check form-check-sm form-check-custom me-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id={item.slug}
                                checked={
                                  checkIfExist(item)
                                }
                                onChange={() => onChange(event, item)}
                              />
                            </label>
                            <span className="med-dark fw-500">{item.name}</span>
                          </Accordion.Header>
                          <Accordion.Body className='accordion-permissions'>
                            <div className='fv-row'>
                              {item.children && item.children.map((child, indexChild) => (
                                <label className="form-check form-check-sm form-check-custom me-9 mb-4" key={indexChild}>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id={child.slug}
                                    checked={
                                      checkIfExist(child)
                                    }
                                    onChange={() => onChange(event, child)}
                                  />
                                  <span className="form-check-label med-dark fw-400">{child.name}</span>
                                </label>
                              ))}
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) :
        <></>}
    </>
  )
}

export default PermissionList
