import apiService from '../../../setup/axios/ApiService'

const prefixURL = 'payment-method'

const selectPaymentMethods = (isIn: number) => apiService.get(`${prefixURL}/select`, {
  isIn,
})

const getPaymentMethod = (uuid:string|undefined) => apiService.get(`${prefixURL}/${uuid}`)

const PaymentMethodService = {
  selectPaymentMethods,
  getPaymentMethod,
}

export default PaymentMethodService
