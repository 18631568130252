import React, { useEffect, useState } from 'react'
import BankAccountItem from './BankAccountItem'
import BankAccountService from '../../../../services/Accounting/BankAccountService'
import BankAccountCreate from './add/BankAccountCreate'

type Props = {
  show: boolean,
  handleClose: (isSubmit: boolean) => void
  bankAccountSelected: IBankAccount|null|undefined
  action: string
}

const BankAccountListing : React.FC<Props> =
    ({
      bankAccountSelected,
      show,
      handleClose,
      action,
    }) => {
      const [isLoading, setIsLoading] = useState<boolean>(true)
      const [bankAccounts, setBankAccounts] = useState<IBankAccount[]>([])

      const handleIsLoading = (isLoading: boolean) => {
        setIsLoading(isLoading)
      }

      useEffect(() => {
        BankAccountService.getAllBankAccounts().then((response) => {
          setBankAccounts(response.data.data)
          setIsLoading(false)
        })
      }, [isLoading])

      return (
        <>
          <div className={'row gy-2 g-xl-8'}>
            {bankAccounts && bankAccounts.map((bankAccount, index) => (
              <div className='col-xxl-4' key={`bank_account_${index}`}>
                <BankAccountItem
                  bankAccount={bankAccount}
                  isLoading={isLoading}
                  handleIsLoading={handleIsLoading}
                />
              </div>
            ))}
          </div>
          <BankAccountCreate show={show} bankAccount={bankAccountSelected} handleClose={handleClose} handleIsLoading={handleIsLoading} action={'create'} />

        </>
      )
    }

export default BankAccountListing
