import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import Functions from '../../../../setup/utilities/Functions'
import BankAccountToolbar from './components/BankAccountToolbar'
import BankAccountListing from './components/BankAccountListing'


const BankAccountWrapper : React.FC = () => {
  const intl = useIntl()
  const teamBreadCrumbs: Array<PageLink> = [
    {
      title: 'Home',
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: 'accounting.bank.account.menu' }),
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  const [bankAccount, setBankAccount] = useState<IBankAccount|undefined|null>(null)
  const [action, setAction] = useState<string>('')
  const [show, setShow] = React.useState(false)
  const handleClose = async (isSubmit: boolean) => {
    if (!isSubmit) {
      const isConfirmed = await Functions.sweetAlert(
        '<span class="mt-5">' + intl.formatMessage({ id: 'action.message' }) + '</span>',
        'warning',
        true,
        intl.formatMessage({ id: 'action.cancel' }),
        intl.formatMessage({ id: 'action.return' }),
        'btn btn-primary mt-10 mb-10',
        'btn btn-active-light mt-10 mb-10',
      )
      if (isConfirmed) {
        setShow(false)
      }
    } else {
      setShow(false)
    }
  }
  const handleShow = (bankAccount: IBankAccount|undefined|null, action: string) => {
    setBankAccount(bankAccount)
    setAction(action)
    setShow(true)
  }

  useEffect(() => {}, [bankAccount])
  return (
    <>
      <PageTitle breadcrumbs={teamBreadCrumbs} pageTitle={intl.formatMessage({ id: 'accounting.bank.account.list' })}>
        <BankAccountToolbar handleShow={handleShow}/>
      </PageTitle>
      <BankAccountListing show={show} handleClose={handleClose} bankAccountSelected={bankAccount} action={action}/>
    </>
  )
}

export default BankAccountWrapper
