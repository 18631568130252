import React from 'react'
import { useIntl } from 'react-intl'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import CheckAndDraftToolbar from './components/CheckAndDraftToolbar'
import CheckAndDraftFilter from './components/CheckAndDraftFilter'


const CheckAndDraftWrapper : React.FC = () => {
  const intl = useIntl()

  const checkAndDraftBreadCrumbs: Array<PageLink> = [
    {
      title: 'Home',
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: 'accounting.check.and.draft.menu' }),
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={checkAndDraftBreadCrumbs} pageTitle={intl.formatMessage({ id: 'accounting.check.and.draft.list' })}>
        <CheckAndDraftToolbar/>
      </PageTitle>
      <CheckAndDraftFilter/>
    </>
  )
}

export default CheckAndDraftWrapper
