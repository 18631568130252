import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../../../../../_metronic/helpers'
import Symbol from '../../../../../../../_metronic/partials/common/Symbol'
import Functions from '../../../../../../../setup/utilities/Functions'
import { useParams } from 'react-router-dom'
import ProductService from '../../../../../../services/Crm/ProductService'

const Pay : React.FC = () => {
  const { id } = useParams()
  const [products, setProducts] = useState<IProductSubscription[]>([])

  useEffect(() => {
    ProductService.getProductsByMedicalProfessional(id).then((response) => {
      setProducts(response.data.data)
    })
  }, [])
  return (
    <>
      <div className='card'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h2 className='fw-bolder'>Liste des produits</h2>
          </div>
        </div>
        <div className='card-body px-0 py-0'>

          {products && products.map((product, index) => (
            <div key={`product_${index}`}>
              <div className="d-flex mt-3 mb-5">
                <div className="flex-grow-1">
                  <div className="row px-10">
                    <div className="col-sm-5">
                      <span className="text-dark fs-4 fw-semibold">{product.name}</span>
                    </div>
                    <div className="col-sm-7">
                      <span className='badge badge-secondary fs-8 fw-bold'>
                        <div className="d-flex align-items-center justify-content-center">
                          <span>
                            <KTSVG
                              path='/media/icons/duotune/general/gen014.svg'
                              className='svg-icon-2 svg-icon-dark'
                            />
                            <span className="text-dark fs-7 fw-500 ms-2">{product.subscriptionDate || '__:__:____'}</span>
                          </span>
                          <span className="text-dark ms-5"> - </span>
                          <span className="ms-3">
                            <KTSVG
                              path='/media/icons/duotune/general/gen014.svg'
                              className='svg-icon-2 svg-icon-dark me-1'
                            />
                            <span className="text-dark fs-7 fw-500 ms-2">{product.expirationDate || '__:__:____'}</span>
                          </span>
                        </div>
                      </span>
                    </div>
                  </div>
                  <div className="row mt-5 px-10">
                    <div className="col-sm-5">
                      <div className="d-flex justify-content-start">
                        <span className="text-dark fw-semibold fs-5 mt-3"> By : </span>
                        <div className="d-flex align-items-start ms-3">
                          <Symbol key={`tag_${index}`}
                            picture={product?.salesPerson?.profilePicture}
                            picturePath='/admin/uploads/users/'
                            withText={true}
                            widthClassName='symbol-40px ms-2'
                            text={Functions.fullName(product?.salesPerson?.firstName || '', product?.salesPerson?.lastName || '')}
                            shortText={Functions.firstLetterUpperCase((product?.salesPerson?.firstName || '').charAt(0)) + Functions.firstLetterUpperCase((product?.salesPerson?.lastName || '').charAt(0))}
                            description={null}
                            backgroundClass=''
                            textClassName='fs-3 fw-400'
                            isCircular={false}
                            descriptionColor={''}
                            textColor={'text-dark fs-5 ms-2'}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-7">
                      <span className="badge badge-danger h-25px mt-3">Not Paid</span>
                      <span className="badge badge-danger ms-2 h-25px">850 Not received</span>
                    </div>
                  </div>
                </div>
                <div className="ms-auto">
                  <button
                    type='button'
                    className='btn btn-icon btn-sm btn-secondary me-5'
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen053.svg'
                      className='svg-icon svg-icon-2'
                    />
                  </button>
                </div>
              </div>
              <div className='separator d-flex flex-center'></div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default Pay
