import React, { FC } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { PrivateRoutes } from './PrivateRoutes'
import { RootState } from '../../setup'
import { MasterInit } from '../../_metronic/layout/MasterInit'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout } from '../modules/auth'

const AllRoutes: FC = () => {
  const user = useSelector<RootState>(({ auth }) => auth.user, shallowEqual)
  return (
    <>
      <Routes>
        <Route path='error/*' element={<ErrorsPage />} />
        <Route path='logout' element={<Logout />} />
        <Route>
          {user ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/employee' />} />
            </>
          ) : (
            <>

            </>
          )}
        </Route>
      </Routes>

      <MasterInit />
    </>
  )
}

export { AllRoutes }
