import apiService from '../../../setup/axios/ApiService'

const prefixURL = 'team'

const getAllTeams = (currentPage:number, limit:number) => apiService.get(`${prefixURL}/`, {
  page: currentPage,
  limit,
})

const createTeam = (data: any) => apiService.post(`${prefixURL}/`, data)

const updateTeam = (uuid: string|undefined, data: any) => apiService.put(`${prefixURL}/${uuid}/edit`, data)

const deleteTeam = (uuid: string|undefined) => apiService.deletes(`${prefixURL}/${uuid}/delete`)

const updateStatus = (uuid: string|undefined, data: any) => apiService.patch(`${prefixURL}/${uuid}/active`, data)

const TeamService = {
  getAllTeams,
  createTeam,
  updateTeam,
  deleteTeam,
  updateStatus,
}

export default TeamService
