import React, { useEffect, useState } from 'react'
import DepartmentService from '../../../../../services/Hr/DepartmentService'
import InputMask from 'react-input-mask'
import { useFormContext } from 'react-hook-form'
import DropZone from '../../../../../../_metronic/partials/common/DropZone'
import useWindowDimensions from '../../../../../../_metronic/partials/mobile-check-view/useCheckMobileScreen'
import { useIntl } from 'react-intl'
import Select from 'react-select'

type Props = {
  employeeSelected: IEmployee|null|undefined
}

const Contract : React.FC<Props> = ({ employeeSelected }) => {
  const colourStyles = {
    control: (styles: any) => ({ ...styles, backgroundColor: '#F5F8FA', border: 0, minHeight: '45px' }),
  }
  const intl = useIntl()
  const { register, setValue, formState: { errors } } = useFormContext()
  const [files, setFiles] = React.useState<File[]>([])
  const [departments, setDepartments] = useState([])
  const isMobile = useWindowDimensions()
  const [selectedDepartment, setSelectedDepartment] = useState<{value: string|undefined, label: string|undefined}>({ value: employeeSelected?.group.uuid, label: employeeSelected?.group.name })

  const handleDepartment = (event: any) => {
    setValue('department', event.value)
  }

  const handleFiles = (files: File[]) => {
    setFiles(files)
    setValue('files', files)
  }

  const onChangeContractType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setValue('contractType', e.target.value)
  }

  useEffect(() => {
    DepartmentService.selectDepartment().then((response) => {
      setDepartments(response.data.data)
    })
  }, [])

  useEffect(() => {
    setSelectedDepartment({ value: employeeSelected?.country.uuid, label: employeeSelected?.country.name })
    setValue('department', employeeSelected?.group.uuid || '')
    setValue('profession', employeeSelected?.profession || '')
    // @ts-ignore
    setValue('hiringDate', employeeSelected?.hiringDate.date || '')
    setValue('contractType', employeeSelected?.contract.type || '')
    setValue('socialSecurityNumber', employeeSelected?.socialSecurityNumber || '')
    setValue('salary', employeeSelected?.contract.salary || '')
  }, [employeeSelected])

  return (
    <>
      { isMobile ?
        (
          <>
            <div className="row d-flex overflow-auto">
              <div className="text-primary fw-bold">{intl.formatMessage({ id: 'employee.step.contract.title' })}</div>
              <div className="fv-row mb-7 fv-plugins-icon-container pt-5">
                <label className="required fw-semibold fs-6 mb-2">{intl.formatMessage({ id: 'employee.entity.department' })}</label>
                <Select
                  options={departments}
                  styles={colourStyles}
                  defaultValue={selectedDepartment}
                  onChange={handleDepartment}
                />
                {errors.department && errors.department.message && (
                  <div className='fv-plugins-message-container text-danger'>
                    <>{errors.department.message}</>
                  </div>
                )}
              </div>
              <div className="fv-row mb-7 fv-plugins-icon-container">
                <label className="required fw-semibold fs-6 mb-2">{intl.formatMessage({ id: 'employee.entity.profession' })}</label>
                <input
                  type="text"
                  className="form-control form-control-solid"
                  placeholder={intl.formatMessage({ id: 'employee.entity.profession' })}
                  {...register('profession')}
                />
                {errors.profession && errors.profession.message && (
                  <div className='fv-plugins-message-container text-danger'>
                    <>{errors.profession.message}</>
                  </div>
                )}
              </div>
              <div className="fv-row mb-7 fv-plugins-icon-container">
                <label className="required fw-semibold fs-6 mb-2">{intl.formatMessage({ id: 'employee.entity.contractType' })}</label>
                <select
                  className="form-select form-select-solid"
                  onChange={onChangeContractType}
                >
                  <option value={undefined}>{intl.formatMessage({ id: 'employee.entity.contractType' })}</option>
                  <option value="CDD">CDD</option>
                  <option value="CDI">CDI</option>
                  <option value="SIVP">SIVP</option>
                  <option value="KARAMA">KARAMA</option>
                </select>
                {errors.contractType && errors.contractType.message && (
                  <div className='fv-plugins-message-container text-danger'>
                    <>{errors.contractType.message}</>
                  </div>
                )}
              </div>
              <div className="fv-row mb-7 fv-plugins-icon-container">
                <label className="required fw-semibold fs-6 mb-2">{intl.formatMessage({ id: 'employee.entity.hiringDate' })}</label>
                <InputMask
                  className="form-control form-control-solid"
                  mask='9999/99/99'
                  placeholder="----/--/--"
                  {...register('hiringDate')}
                />
                {errors.hiringDate && errors.hiringDate.message && (
                  <div className='fv-plugins-message-container text-danger'>
                    <>{errors.hiringDate.message}</>
                  </div>
                )}
              </div>
              <div className="fv-row mb-7 fv-plugins-icon-container">
                <label className="required fw-semibold fs-6 mb-2">{intl.formatMessage({ id: 'employee.entity.socialSecurityNumber' })}</label>
                <input
                  type="text"
                  className="form-control form-control-solid"
                  placeholder={intl.formatMessage({ id: 'employee.entity.socialSecurityNumber' })}
                  {...register('socialSecurityNumber')}
                />
                {errors.socialSecurityNumber && errors.socialSecurityNumber.message && (
                  <div className='fv-plugins-message-container text-danger'>
                    <>{errors.socialSecurityNumber.message}</>
                  </div>
                )}
              </div>
              <div className="fv-row mb-7 fv-plugins-icon-container">
                <label className="required fw-semibold fs-6 mb-2">{intl.formatMessage({ id: 'employee.entity.salary' })}</label>
                <input
                  type="text"
                  className="form-control form-control-solid"
                  placeholder={intl.formatMessage({ id: 'employee.entity.salary' })}
                  {...register('salary')}
                />
                {errors.salary && errors.salary.message && (
                  <div className='fv-plugins-message-container text-danger'>
                    <>{errors.salary.message}</>
                  </div>
                )}
              </div>
              <div className="fv-row mb-7 fv-plugins-icon-container">
                <DropZone files={files} handleFiles={handleFiles} isMultiple={true} />
              </div>
            </div>
          </>
        ) :
        (
          <>
            <div className="row d-flex overflow-auto">
              <div className="text-primary fw-bold">{intl.formatMessage({ id: 'employee.step.contract.title' })}</div>
              <div className="w-1000px ms-20 pt-5">
                <div className="row pt-10">
                  <div className="col-2">
                    <label className="required fw-500 pt-3">{intl.formatMessage({ id: 'employee.entity.department' })}</label>
                  </div>
                  <div className="col-4">
                    <Select
                      options={departments}
                      styles={colourStyles}
                      defaultValue={selectedDepartment}
                      onChange={handleDepartment}
                    />
                    {errors.department && errors.department.message && (
                      <div className='fv-plugins-message-container text-danger'>
                        <>{errors.department.message}</>
                      </div>
                    )}
                  </div>
                  <div className="col-2">
                    <label className="required fw-500 pt-3">{intl.formatMessage({ id: 'employee.entity.profession' })}</label>
                  </div>
                  <div className="col-4">
                    <input
                      type="text"
                      className="form-control form-control-solid"
                      placeholder={intl.formatMessage({ id: 'employee.entity.profession' })}
                      {...register('profession')}
                    />
                    {errors.profession && errors.profession.message && (
                      <div className='fv-plugins-message-container text-danger'>
                        <>{errors.profession.message}</>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row pt-10">
                  <div className="col-2">
                    <label className="required fw-500 pt-3">{intl.formatMessage({ id: 'employee.entity.contractType' })}</label>
                  </div>
                  <div className="col-4">
                    <select
                      className="form-select form-select-solid"
                      onChange={onChangeContractType}
                      defaultValue={employeeSelected?.contract.type}
                    >
                      <option value={undefined}>{intl.formatMessage({ id: 'employee.entity.contractType' })}</option>
                      <option value="CDD">CDD</option>
                      <option value="CDI">CDI</option>
                      <option value="SIVP">SIVP</option>
                      <option value="KARAMA">KARAMA</option>
                    </select>
                    {errors.contractType && errors.contractType.message && (
                      <div className='fv-plugins-message-container text-danger'>
                        <>{errors.contractType.message}</>
                      </div>
                    )}
                  </div>
                  <div className="col-2">
                    <label className="required fw-500 pt-3">{intl.formatMessage({ id: 'employee.entity.hiringDate' })}</label>
                  </div>
                  <div className="col-4">
                    <InputMask
                      className="form-control form-control-solid"
                      mask='9999/99/99'
                      placeholder="----/--/--"
                      {...register('hiringDate')}
                    />
                    {errors.hiringDate && errors.hiringDate.message && (
                      <div className='fv-plugins-message-container text-danger'>
                        <>{errors.hiringDate.message}</>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row pt-10">
                  <div className="col-2">
                    <label className="required fw-500 pt-3">{intl.formatMessage({ id: 'employee.entity.socialSecurityNumber' })}</label>
                  </div>
                  <div className="col-4">
                    <input
                      type="text"
                      className="form-control form-control-solid"
                      placeholder={intl.formatMessage({ id: 'employee.entity.socialSecurityNumber' })}
                      {...register('socialSecurityNumber')}
                    />
                    {errors.socialSecurityNumber && errors.socialSecurityNumber.message && (
                      <div className='fv-plugins-message-container text-danger'>
                        <>{errors.socialSecurityNumber.message}</>
                      </div>
                    )}
                  </div>
                  <div className="col-2">
                    <label className="required fw-500 pt-3">{intl.formatMessage({ id: 'employee.entity.salary' })}</label>
                  </div>
                  <div className="col-4">
                    <input
                      type="text"
                      className="form-control form-control-solid"
                      placeholder={intl.formatMessage({ id: 'employee.entity.salary' })}
                      {...register('salary')}
                    />
                    {errors.salary && errors.salary.message && (
                      <div className='fv-plugins-message-container text-danger'>
                        <>{errors.salary.message}</>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row pt-10 w-1000px">
                  <DropZone files={files} handleFiles={handleFiles} isMultiple={true} />
                </div>
              </div>
            </div>
          </>
        )
      }
    </>
  )
}
export default Contract
