import React from 'react'
import Symbol from '../../../../../../../../../_metronic/partials/common/Symbol'
import Functions from '../../../../../../../../../setup/utilities/Functions'
import { KTSVG } from '../../../../../../../../../_metronic/helpers'


type Props = {
    journey: IJourney | null,
    isLoading: boolean,
}
const CallHistoryHeader : React.FC<Props> = ({ journey, isLoading }) => (
  <>
    <div className={`card card-rounded bg-med-purple mt-5 ${isLoading ? 'overlay overlay-block' : ''}`}>
      <div className={`card-body px-0 py-3 ${isLoading ? 'overlay overlay-block' : ''}`}>
        {/* Card Header */}
        <div className="d-flex ms-5">
          <div className="flex-grow-1">
            <div className="row">
              <div className="col-sm-6">
                <div className="d-flex align-items-start">
                  <Symbol
                    picture={journey?.employee ? journey.employee?.profilePicture : null}
                    picturePath='/admin/uploads/users/'
                    withText={true}
                    widthClassName='symbol-40px me-5'
                    text={Functions.fullName(journey?.employee ? journey?.employee.firstName : '', journey?.employee ? journey.employee.lastName : '')}
                    shortText={Functions.firstLetterUpperCase(journey?.employee ? journey.employee.firstName.charAt(0) : '') + Functions.firstLetterUpperCase(journey?.employee ? journey.employee.lastName.charAt(0) : '')}
                    description={null}
                    backgroundClass=''
                    textClassName='fs-3 fw-400'
                    isCircular={false}
                    descriptionColor={'text-white fs-7 fw-500'}
                    textColor={'text-white fs-3 fw-bold'}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="d-flex justify-content-end">
                  <div className="ms-auto me-3">
                    <div className="d-flex align-items-end">
                      <span className="mt-2">
                        <KTSVG
                          path='/media/icons/duotune/abstract/phone.svg'
                          className='svg-icon svg-icon-1 svg-icon-white'
                        />
                        <KTSVG
                          path='/media/icons/duotune/abstract/pharmacist.svg'
                          className='svg-icon svg-icon-1 svg-icon-white'
                        />
                        <span className="text-white fs-3 fw-bold ms-2">
                          {journey?.medicalProfessionalCalls || 0}
                        </span>
                      </span>
                      <span className="mt-2 ms-10">
                        <KTSVG
                          path='/media/icons/duotune/abstract/phone.svg'
                          className='svg-icon svg-icon-1 svg-icon-white'
                        />
                        <KTSVG
                          path='/media/icons/duotune/communication/com014.svg'
                          className='svg-icon svg-icon-1 svg-icon-white'
                        />
                        <span className="text-white fs-3 fw-bold ms-2">
                          {journey?.patientCalls || 0}
                        </span>
                      </span>
                      <span className='badge badge-white ms-10'>
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr027.svg'
                          className='svg-icon svg-icon-1 svg-icon-primary'
                        />
                        <span className="text-primary fs-5 fw-500">{journey?.startDate ? journey?.startDate : '--:--'}</span>
                      </span>
                      <span className='badge badge-white ms-2'>
                        <KTSVG
                          path='/media/icons/duotune/abstract/reset.svg'
                          className='svg-icon svg-icon-1 svg-icon-danger'
                        />
                        <span className="text-danger fs-5 fw-500">{journey?.endDate ? journey?.endDate : '--:--'}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Card Header */}
      </div>
    </div>
  </>
)

export default CallHistoryHeader
