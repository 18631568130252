import React from 'react'
import Can from '../../../services/casl/Can'
import JourneyManagerWrapper from './components/sales/manager/JourneyManagerWrapper'
import JourneySalesWrapper from './components/sales/sales/JourneySalesWrapper'
import JourneySupportWrapper from './components/support/support/JourneySupportWrapper'
import JourneySupportManagerWrapper from './components/support/manager/JourneySupportManagerWrapper'


const JourneyWrapper : React.FC = () => (
  <>
    <Can do="management" on="journey">
      <Can do="index" on="journeySales">
        <JourneySalesWrapper />
      </Can>
      <Can do="index" on="journeySupport">
        <JourneySupportWrapper/>
      </Can>
      <Can do="index" on="journeyManagerSales">
        <JourneyManagerWrapper/>
      </Can>
      <Can do="index" on="journeyManagerSupport">
        <JourneySupportManagerWrapper/>
      </Can>
    </Can>
  </>
)

export default JourneyWrapper
