import React, { useEffect, useRef, useState } from 'react'
import { FormStepper } from '../../../../../../_metronic/partials/form-stepper/FormStepper'
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import * as Yup from 'yup'
import { StepperComponent } from '../../../../../../_metronic/assets/ts/components'
import { Modal } from 'react-bootstrap'
import EmployeeService from '../../../../../services/Hr/EmployeeService'
import useWindowDimensions from '../../../../../../_metronic/partials/mobile-check-view/useCheckMobileScreen'
import Information from './Information'
import Contact from './Contact'
import Bank from './Bank'
import Contract from './Contract'
import { useIntl } from 'react-intl'

type Props = {
  show: boolean,
  handleClose: () => void,
  handleSubmit: () => void,
  handleIsLoading: (isLoading: boolean) => void,
  currentStep: number,
  setCurrentStep: (step: number) => void,
  employeeSelected: IEmployee|null|undefined
  action: string
}

const employeeSchema = Yup.object().shape({
  profilePicture: Yup.string()
    .notRequired(),
  civility: Yup.string()
    .required('Ce champs est obligatoire'),
  lastName: Yup.string()
    .required('Ce champs est obligatoire'),
  firstName: Yup.string()
    .required('Ce champs est obligatoire'),
  birthDate: Yup.string()
    .required('Ce champs est obligatoire'),
  cin: Yup.string()
    .required('Ce champs est obligatoire')
    .min(8),
  familyStatus: Yup.string()
    .required('Ce champs est obligatoire'),
  maidenName: Yup.string()
    .notRequired(),
  childrenNumber: Yup.string()
    .notRequired(),
  country: Yup.string()
    .required('Ce champs est obligatoire'),
  location: Yup.string()
    .required('Ce champs est obligatoire'),
  address: Yup.string()
    .required('Ce champs est obligatoire'),
  email: Yup.string()
    .required('Ce champs est obligatoire'),
  username: Yup.string()
    .required('Ce champs est obligatoire'),
  phone: Yup.string()
    .required('Ce champs est obligatoire'),
  iBan: Yup.string()
    .required('Ce champs est obligatoire'),
  department: Yup.string()
    .required('Ce champs est obligatoire'),
  profession: Yup.string()
    .required('Ce champs est obligatoire'),
  contractType: Yup.string()
    .required('Ce champs est obligatoire'),
  hiringDate: Yup.string()
    .required('Ce champs est obligatoire'),
  socialSecurityNumber: Yup.string()
    .required('Ce champs est obligatoire'),
  salary: Yup.string()
    .required('Ce champs est obligatoire'),
})

type Inputs = {
  profilePicture: File[],
  civility: string,
  lastName: string,
  firstName: string,
  birthDate: string,
  cin: string,
  familyStatus: string,
  maidenName: string,
  childrenNumber: string,
  country: string,
  location: string,
  address: string,
  email: string,
  username: string,
  phone: string,
  iBan: string,
  department: string,
  profession: string,
  contractType: string,
  hiringDate: string,
  socialSecurityNumber: string,
  salary: string,
}

const EmployeeCreate : React.FC<Props> =
    ({
      show,
      handleClose,
      handleSubmit,
      handleIsLoading,
      currentStep,
      setCurrentStep,
      employeeSelected,
      action,
    }) => {
      const intl = useIntl()
      const isMobile = useWindowDimensions()
      const stepperRef = useRef<HTMLDivElement | null>(null)
      const stepper = useRef<StepperComponent | null>(null)
      const [image, setImage] = useState<any>(null)

      const steps = [
        {
          'order': '1',
          'class': 'd-flex flex-fill h-80px border border-gray-400 border-0 me-1 rounded-top',
          'title': intl.formatMessage({ id: 'employee.step.information.label' }),
          'description': intl.formatMessage({ id: 'employee.step.information.description' }),
        },
        {
          'order': '2',
          'class': 'd-flex flex-fill h-80px border border-gray-400 border-0 me-1 rounded-top',
          'title': intl.formatMessage({ id: 'employee.step.contact.label' }),
          'description': intl.formatMessage({ id: 'employee.step.contact.description' }),
        },
        {
          'order': '3',
          'class': 'd-flex flex-fill h-80px border border-gray-400 border-0 me-1 rounded-top',
          'title': intl.formatMessage({ id: 'employee.step.bank.label' }),
          'description': intl.formatMessage({ id: 'employee.step.bank.description' }),
        },
        {
          'order': '4',
          'class': 'd-flex flex-fill h-80px rounded-top',
          'title': intl.formatMessage({ id: 'employee.step.contract.label' }),
          'description': intl.formatMessage({ id: 'employee.step.contract.description' }),
        },
      ]

      const stepsMobile = [
        {
          'order': '1',
          'class': 'h-80px pt-6 col-3',
          'title': intl.formatMessage({ id: 'employee.step.information.label' }),
          'description': '',
        },
        {
          'order': '2',
          'class': 'h-80px pt-6 col-3',
          'title': intl.formatMessage({ id: 'employee.step.contact.label' }),
          'description': '',
        },
        {
          'order': '3',
          'class': 'h-80px pt-6 col-3',
          'title': intl.formatMessage({ id: 'employee.step.bank.label' }),
          'description': '',
        },
        {
          'order': '4',
          'class': 'h-80px pt-6 col-3',
          'title': intl.formatMessage({ id: 'employee.step.contract.label' }),
          'description': '',
        },
      ]

      const loadStepper = () => {
        stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
      }

      const config = {
        resolver: yupResolver(employeeSchema),
        defaultValues: {
          profilePicture: [],
          civility: '',
          lastName: '',
          firstName: '',
          birthDate: '',
          cin: '',
          familyStatus: '',
          maidenName: '',
          childrenNumber: '',
          country: '',
          location: '',
          address: '',
          email: '',
          username: '',
          phone: '',
          iBan: '',
          department: '',
          profession: '',
          contractType: '',
          hiringDate: '',
          socialSecurityNumber: '',
          salary: '',
        },
      }

      const methods = useForm<Inputs>(config)

      const prevStep = () => {
        if (!stepper.current) {
          return
        }
        stepper.current.goPrev()
        setCurrentStep(stepper.current?.currentStepIndex)
      }

      const nextStep = async () => {
        if (!stepper.current) {
          return
        }
        let isValid : boolean = false

        switch (stepper.current?.currentStepIndex) {
        case 1:
          isValid = await methods.trigger(['civility', 'lastName', 'firstName', 'birthDate', 'cin', 'familyStatus'])
          break
        case 2:
          isValid = await methods.trigger(['country', 'location', 'address', 'email', 'username', 'phone'])
          break
        case 3:
          isValid = await methods.trigger(['iBan'])
          break
        case 4:
          isValid = await methods.trigger(['department', 'profession', 'contractType', 'hiringDate', 'socialSecurityNumber', 'salary'])
          break
        }

        if (isValid) {
          stepper.current.goNext()
        }
        setCurrentStep(stepper.current?.currentStepIndex)
      }

      const onImageChange = (file: File) => {
        setImage(file)
      }


      const forms = [
        {
          'form': <Information onImageChange={onImageChange} employeeSelected={employeeSelected}/>,
        },
        {
          'form': <Contact employeeSelected={employeeSelected}/>,
        },
        {
          'form': <Bank employeeSelected={employeeSelected}/>,
        },
        {
          'form': <Contract employeeSelected={employeeSelected}/>,
        },
      ]

      const onSubmit: SubmitHandler<Inputs> = (data) => {
        const formData = new FormData()
        formData.append('files', image, image?.name)
        formData.append('civility', data.civility)
        formData.append('lastName', data.lastName)
        formData.append('firstName', data.firstName)
        formData.append('birthDate', data.birthDate)
        formData.append('cin', data.cin)
        formData.append('familyStatus', data.familyStatus)
        formData.append('maidenName', data.maidenName)
        formData.append('childrenNumber', data.childrenNumber)
        formData.append('country', data.country)
        formData.append('location', data.location)
        formData.append('address', data.address)
        formData.append('email', data.email)
        formData.append('username', data.username)
        formData.append('phone', data.phone)
        formData.append('iBan', data.iBan)
        formData.append('department', data.department)
        formData.append('profession', data.profession)
        formData.append('contractType', data.contractType)
        formData.append('hiringDate', data.hiringDate)
        formData.append('socialSecurityNumber', data.socialSecurityNumber)
        formData.append('salary', data.salary)
        if (action === 'create') {
          EmployeeService.createEmployee(formData).then(() => {
            handleIsLoading(true)
            handleSubmit()
            methods.reset()
          })
        } else {
          EmployeeService.updateEmployee(employeeSelected?.uuid, formData).then(() => {
            handleIsLoading(true)
            handleSubmit()
            methods.reset()
          })
        }
      }

      useEffect(() => {}, [currentStep, stepper])

      return (
        <>
          {isMobile ?
            (
              <>
                <Modal
                  id='kt_modal_create_mobile'
                  tabIndex={-1}
                  aria-hidden='true'
                  dialogClassName='modal-dialog modal-dialog-centered'
                  show={show}
                  onHide={handleClose}
                  onEntered={loadStepper}
                >
                  <FormProvider {...methods} >
                    <form noValidate id='kt_modal_create_app_form' onSubmit={methods.handleSubmit(onSubmit)}>
                      <FormStepper
                        steps={stepsMobile}
                        forms={forms}
                        nextStep={nextStep}
                        prevStep={prevStep}
                        currentStep={currentStep}
                        stepperRef={stepperRef}
                        handleClose={handleClose}
                        reset={methods.reset}
                      />
                    </form>
                  </FormProvider>
                </Modal>
              </>
            ) :
            (
              <>
                <Modal
                  id='kt_modal_create'
                  tabIndex={-1}
                  aria-hidden='true'
                  dialogClassName='modal-dialog mw-1200px modal-dialog-centered'
                  show={show}
                  onHide={handleClose}
                  onEntered={loadStepper}
                >
                  <FormProvider {...methods} >
                    <form noValidate id='kt_modal_create_app_form' onSubmit={methods.handleSubmit(onSubmit)}>
                      <FormStepper
                        steps={steps}
                        forms={forms}
                        nextStep={nextStep}
                        prevStep={prevStep}
                        currentStep={currentStep}
                        stepperRef={stepperRef}
                        handleClose={handleClose}
                        reset={methods.reset}
                      />
                    </form>
                  </FormProvider>
                </Modal>
              </>
            )
          }
        </>
      )
    }
export default EmployeeCreate
