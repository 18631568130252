import React, { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import PaymentMethodService from '../../../services/Accounting/PaymentMethodService'
import BankService from '../../../services/Accounting/BankService'
import currency from 'currency.js'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../../_metronic/helpers'
import Select from 'react-select'
import { DateTimePicker } from '../../../../_metronic/partials/date-time-picker'
import MedicalProfessionalService from '../../../services/Crm/MedicalProfessionalService'
import Functions from '../../../../setup/utilities/Functions'
import AccountingContract from './AccountingContract'

type Props = {
  show: boolean,
  handleClose: () => void,
  handleIsLoading: (isLoading: boolean) => void,
  totalAmount: number,
  setTotalAmount: (remainingPayment: number) => void,
  documentLines: { uuid: string, name:string, quantity:number, price: number, total: number, vat:number, categoryId: string }[],
  id: string|undefined
}

const colourStyles = {
  control: (styles: any) => ({ ...styles, backgroundColor: '#F5F8FA', border: 0, height: '45px' }),
}

const PaymentCreate : React.FC<Props> = ({
  show,
  handleClose,
  totalAmount,
  setTotalAmount,
  handleIsLoading,
  documentLines,
  id,
}) => {
  const intl = useIntl()
  const [formValues, setFormValues] = useState<any>([])
  const [banks, setBanks] = useState<{value: string, label: string}[]>([])
  const [paymentMethods, setPaymentMethods] = useState<{value: string, label: string}[]>([])
  const [paymentFormType, setPaymentFormType] = useState<string>('')
  const [cashForm, setCashForm] = useState<{ paymentMethod: string|null, paymentType: string|null, amount: number|null, date: string|null }>({ paymentMethod: null, paymentType: null, amount: null, date: null })
  const [checkForm, setCheckForm] = useState<{ paymentMethod: string|null, paymentType: string|null, amount: number|null, checkNumber: string|null, drawer: string|null, bank: string|null, bankName: string|null, settlementDate: string|null, dueDate: string|null, }>({ paymentMethod: null, paymentType: null, amount: null, checkNumber: null, drawer: null, bank: null, bankName: null, settlementDate: null, dueDate: null })
  const [draftForm, setDraftForm] = useState<{ paymentMethod: string|null, paymentType: string|null, amount: number|null, draftNumber: string|null, sourceAccount: string|null, settlementDate: string|null, dueDate: string|null, }>({ paymentMethod: null, paymentType: null, amount: null, draftNumber: null, sourceAccount: null, settlementDate: null, dueDate: null })
  const [transferForm, setTransferForm] = useState<{ paymentMethod: string|null, paymentType: string|null, amount: number|null, transferInformation: string|null, settlementDate: string|null, }>({ paymentMethod: null, paymentType: null, amount: null, transferInformation: null, settlementDate: null })
  const [showContract, setShowContract] = useState<boolean>(false)
  const [remainingPayment, setRemainingPayment] = useState<number>(totalAmount)
  const [payment, setPayment] = useState<number>(0)

  const handleShowContract = () => {
    setPayment(formValues.reduce((accumulator: number, object: any) => (object.amount ? parseFloat(currency(object.amount).add(currency(accumulator)).toString()) : 0), 0))
    handleClose()
    setShowContract(true)
  }
  const handleCloseContract = async (isSubmit: boolean) => {
    if (!isSubmit) {
      const isConfirmed = await Functions.sweetAlert(
        '<span class="mt-5">' + intl.formatMessage({ id: 'action.message' }) + '</span>',
        'warning',
        true,
        intl.formatMessage({ id: 'action.cancel' }),
        intl.formatMessage({ id: 'action.return' }),
        'btn btn-primary mt-10 mb-10',
        'btn btn-active-light mt-10 mb-10',
      )
      if (isConfirmed) {
        setShowContract(false)
      }
    } else {
      setShowContract(false)
    }
  }

  // Add A Form to payment table
  const addForm = () => {
    if (paymentFormType === 'cash') {
      setFormValues([...formValues, cashForm])
      setRemainingPayment(totalAmount - (cashForm.amount ? cashForm.amount : 0))
      setCashForm({ paymentMethod: null, paymentType: null, amount: null, date: null })
    } else if (paymentFormType === 'check') {
      setFormValues([...formValues, checkForm])
      setRemainingPayment(totalAmount - (checkForm.amount ? checkForm.amount : 0))
      setCheckForm({ paymentMethod: null, paymentType: null, amount: null, bank: null, bankName: null, checkNumber: null, drawer: null, settlementDate: null, dueDate: null })
    } else if (paymentFormType === 'draft') {
      setFormValues([...formValues, draftForm])
      setRemainingPayment(totalAmount - (draftForm.amount ? draftForm.amount : 0))

      setDraftForm({ paymentMethod: null, paymentType: null, amount: null, draftNumber: null, sourceAccount: null, settlementDate: null, dueDate: null })
    } else if (paymentFormType === 'transfer') {
      setFormValues([...formValues, transferForm])
      setRemainingPayment(totalAmount - (transferForm.amount ? transferForm.amount : 0))

      setTransferForm({ paymentMethod: null, paymentType: null, amount: null, transferInformation: null, settlementDate: null })
    }
    setPaymentFormType('')
  }

  // Edit a line from Table
  const editForm = (index: number, action: string) => {
    const newFormValues = [...formValues]
    if (action === 'cash') {
      setCashForm({ paymentMethod: newFormValues[index]['paymentMethod'], paymentType: newFormValues[index]['paymentType'], amount: newFormValues[index]['amount'], date: newFormValues[index]['date'] })
      setPaymentFormType(action)
    } else if (action === 'check') {
      setCheckForm({ paymentMethod: newFormValues[index]['paymentMethod'], paymentType: newFormValues[index]['paymentType'], amount: newFormValues[index]['amount'], drawer: newFormValues[index]['drawer'], checkNumber: newFormValues[index]['checkNumber'], bank: newFormValues[index]['bank'], bankName: newFormValues[index]['bankName'], settlementDate: newFormValues[index]['settlementDate'], dueDate: newFormValues[index]['dueDate'] })
      setPaymentFormType(action)
    } else if (action === 'draft') {
      setDraftForm({ paymentMethod: newFormValues[index]['paymentMethod'], paymentType: newFormValues[index]['paymentType'], amount: newFormValues[index]['amount'], draftNumber: newFormValues[index]['draftNumber'], sourceAccount: newFormValues[index]['sourceAccount'], settlementDate: newFormValues[index]['settlementDate'], dueDate: newFormValues[index]['dueDate'] })
      setPaymentFormType(action)
    } else if (action === 'transfer') {
      setTransferForm({ paymentMethod: newFormValues[index]['paymentMethod'], paymentType: newFormValues[index]['paymentType'], amount: newFormValues[index]['amount'], transferInformation: newFormValues[index]['transferInformation'], settlementDate: newFormValues[index]['settlementDate'] })
      setPaymentFormType(action)
    }
    newFormValues.splice(index, 1)
    setFormValues(newFormValues)
  }

  // Remove the actual Form
  const removeForm = () => {
    setPaymentFormType('')
    if (paymentFormType === 'cash') {
      setCashForm({ paymentMethod: null, paymentType: null, amount: null, date: null })
    } else if (paymentFormType === 'check') {
      setCheckForm({ paymentMethod: null, paymentType: null, amount: null, bank: null, bankName: null, checkNumber: null, drawer: null, settlementDate: null, dueDate: null })
    } else if (paymentFormType === 'draft') {
      setDraftForm({ paymentMethod: null, paymentType: null, amount: null, draftNumber: null, sourceAccount: null, settlementDate: null, dueDate: null })
    } else if (paymentFormType === 'transfer') {
      setTransferForm({ paymentMethod: null, paymentType: null, amount: null, transferInformation: null, settlementDate: null })
    }
  }

  // Remove payment from table
  const removeFormValues = (index: number) => {
    const newFormValues = [...formValues]
    newFormValues.splice(index, 1)
    setFormValues(newFormValues)
  }

  // Change the payment method via buttons
  const onChangeMethodType = (uuid: string) => {
    PaymentMethodService.getPaymentMethod(uuid).then((response) => {
      if (response.data.data.name === 'cash') {
        setPaymentFormType('cash')
        setCashForm({ paymentMethod: response.data.data.uuid, paymentType: response.data.data.name, amount: cashForm.amount, date: cashForm?.date })
      } else if (response.data.data.name === 'check') {
        setPaymentFormType('check')
        setCheckForm({
          paymentMethod: response.data.data.uuid,
          paymentType: response.data.data.name,
          checkNumber: checkForm?.checkNumber,
          drawer: checkForm?.drawer,
          bank: checkForm?.bank,
          bankName: checkForm?.bankName,
          amount: checkForm?.amount,
          settlementDate: checkForm?.settlementDate,
          dueDate: checkForm?.dueDate,
        })
      } else if (response.data.data.name === 'transfer') {
        setPaymentFormType('transfer')
        setTransferForm({
          paymentMethod: response.data.data.uuid,
          paymentType: response.data.data.name,
          amount: transferForm?.amount,
          transferInformation: transferForm?.transferInformation,
          settlementDate: transferForm?.settlementDate,
        })
      } else if (response.data.data.name === 'draft') {
        setPaymentFormType('draft')
        setDraftForm({
          paymentMethod: response.data.data.uuid,
          paymentType: response.data.data.name,
          amount: draftForm?.amount,
          draftNumber: draftForm?.draftNumber,
          sourceAccount: draftForm?.sourceAccount,
          settlementDate: draftForm?.settlementDate,
          dueDate: draftForm?.dueDate,
        })
      }
    })
  }

  // Change any field type in all forms
  const onChangeField = (event: any) => {
    if (paymentFormType === 'cash') {
      setCashForm({ paymentMethod: cashForm?.paymentMethod, paymentType: cashForm?.paymentType, amount: event.target.value, date: cashForm?.date })
    } else if (paymentFormType === 'check') {
      setCheckForm({
        paymentMethod: checkForm?.paymentMethod,
        paymentType: checkForm?.paymentType,
        checkNumber: event.target.name === 'checkNumber' ? event.target.value : checkForm.checkNumber,
        amount: event.target.name === 'amount' ? event.target.value : checkForm.amount,
        drawer: event.target.name === 'drawer' ? event.target.value : checkForm.drawer,
        bank: checkForm?.bank,
        bankName: checkForm?.bankName,
        settlementDate: checkForm?.settlementDate,
        dueDate: checkForm?.dueDate,
      })
    } else if (paymentFormType === 'draft') {
      setDraftForm({
        paymentMethod: draftForm?.paymentMethod,
        paymentType: draftForm?.paymentType,
        draftNumber: event.target.name === 'draftNumber' ? event.target.value : draftForm.draftNumber,
        amount: event.target.name === 'amount' ? event.target.value : draftForm.amount,
        sourceAccount: event.target.name === 'sourceAccount' ? event.target.value : draftForm.sourceAccount,
        settlementDate: draftForm?.settlementDate,
        dueDate: draftForm?.dueDate,
      })
    } else if (paymentFormType === 'transfer') {
      setTransferForm({
        paymentMethod: transferForm.paymentMethod,
        paymentType: transferForm.paymentType,
        amount: event.target.name === 'amount' ? event.target.value : transferForm.amount,
        transferInformation: event.target.name === 'transferInformation' ? event.target.value : transferForm.transferInformation,
        settlementDate: transferForm?.settlementDate,
      })
    }
  }

  // Change Date in Cash Form
  const onHandleChangeDate = useCallback(
    (date: any) => {
      setCashForm({ paymentMethod: cashForm?.paymentMethod, paymentType: cashForm?.paymentType, amount: cashForm?.amount, date })
    },
    [cashForm],
  )

  // Change Bank in Check Form
  const onChangeBank = (event: any) => {
    setCheckForm({
      paymentMethod: checkForm?.paymentMethod,
      paymentType: checkForm?.paymentType,
      checkNumber: checkForm?.checkNumber,
      amount: checkForm?.amount,
      drawer: checkForm?.drawer,
      bank: event.value,
      bankName: event.label,
      settlementDate: checkForm?.settlementDate,
      dueDate: checkForm?.dueDate,
    })
  }

  // Change Settlement Date in Check, Draft and Transfer Form
  const onHandleChangeSettlementDate = useCallback(
    (date: any) => {
      if (paymentFormType === 'check') {
        setCheckForm({
          paymentMethod: checkForm?.paymentMethod,
          paymentType: checkForm?.paymentType,
          checkNumber: checkForm?.checkNumber,
          amount: checkForm?.amount,
          drawer: checkForm?.checkNumber,
          bank: checkForm?.bank,
          bankName: checkForm?.bankName,
          settlementDate: date,
          dueDate: checkForm?.dueDate,
        })
      } else if (paymentFormType === 'draft') {
        setDraftForm({
          paymentMethod: draftForm?.paymentMethod,
          paymentType: draftForm?.paymentType,
          amount: draftForm?.amount,
          draftNumber: draftForm?.draftNumber,
          sourceAccount: draftForm?.sourceAccount,
          settlementDate: date,
          dueDate: draftForm?.dueDate,
        })
      } else if (paymentFormType === 'transfer') {
        setTransferForm({
          paymentMethod: transferForm.paymentMethod,
          paymentType: transferForm.paymentType,
          amount: transferForm.amount,
          transferInformation: transferForm.transferInformation,
          settlementDate: date,
        })
      }
    },
    [checkForm, transferForm, draftForm],
  )

  // Change Due Date in Check and Draft Form
  const onHandleChangeDueDate = useCallback(
    (date: any) => {
      if (paymentFormType === 'check') {
        setCheckForm({
          paymentMethod: checkForm?.paymentMethod,
          paymentType: checkForm?.paymentType,
          checkNumber: checkForm?.checkNumber,
          amount: checkForm?.amount,
          drawer: checkForm?.checkNumber,
          bank: checkForm?.bank,
          bankName: checkForm?.bankName,
          settlementDate: checkForm.settlementDate,
          dueDate: date,
        })
      } else if (paymentFormType === 'draft') {
        setDraftForm({
          paymentMethod: draftForm?.paymentMethod,
          paymentType: draftForm?.paymentType,
          amount: draftForm?.amount,
          draftNumber: draftForm?.draftNumber,
          sourceAccount: draftForm?.sourceAccount,
          settlementDate: draftForm.settlementDate,
          dueDate: date,
        })
      }
    },
    [checkForm, draftForm],
  )

  useEffect(() => {
    BankService.selectBank().then((response) => {
      setBanks(response.data.data)
    })
  }, [])

  const onSubmit = () => {
    const data = {
      documentLines,
      payments: formValues,
      grossAmount: documentLines.reduce((accumulator: number, object: any) => (object.price ? parseFloat(currency(object.price).add(currency(accumulator)).toString()) : 0), 0),
      netAmount: documentLines.reduce((accumulator: number, object: any) => (object.price ? parseFloat(currency(object.price).add(currency(accumulator)).toString()) : 0), 0),
      type: 'invoice_workflow',
      transition: 'to_unpaid',
    }
    MedicalProfessionalService.sellForMedicalProfessional(id, data).then(() => {
      setFormValues([])
      handleIsLoading(true)
      handleCloseContract(true)
    })
  }

  useEffect(() => {}, [remainingPayment])

  useEffect(() => {
    PaymentMethodService.selectPaymentMethods(1).then((response) => {
      setPaymentMethods(response.data.data)
    })
  }, [])

  return (
    <>
      <Modal show={show} onHide={handleClose} dialogClassName={'mw-600px'}>
        <Modal.Header className="py-4">
          <div className="d-flex flex-column">
            <h4>Plan de paiement</h4>
            <div className="d-flex align-items-start mt-2">
              <span className="badge badge-danger">Reste à payer {remainingPayment} DT</span>
              <span className="badge badge-warning text-dark ms-2">Total {totalAmount} DT</span>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            {paymentMethods.map((item, index) => (
              <button key={`button_${index}`} className="btn btn-sm btn-primary me-5" onClick={() => onChangeMethodType(item.value)}>
                <KTSVG
                  path={`/media/icons/duotune/${item.label === 'cash' ? 'abstract/money.svg' : item.label === 'check' ? 'abstract/card.svg' : ''}`}
                  className='svg-icon svg-icon-2'
                />
                {item.label}
              </button>
            ))}
          </div>

          {paymentFormType === 'cash' ?
            <>
              <div className="card mt-10 border border-1 border-secondary">
                <div className="card-body px-7 py-5">
                  <span className="badge bg-med-orange fs-5 fw-500">{paymentFormType}</span>
                  <div className="mb-5 mt-5">
                    <label
                      className="form-label fw-semibold">{intl.formatMessage({ id: 'accounting.payment.entity.amount' })}</label>
                    <input
                      placeholder={intl.formatMessage({ id: 'accounting.payment.entity.amount' })}
                      className={'form-control form-control-solid form-control-lg col-md-10'}
                      type='text'
                      autoComplete='off'
                      name="amount"
                      onChange={onChangeField}
                      value={cashForm.amount as number}
                    />
                  </div>
                  <div className="mb-5">
                    <label
                      className="form-label fw-semibold">{intl.formatMessage({ id: 'accounting.payment.entity.date' })}</label>
                    <DateTimePicker
                      format="YYYY/MM/DD"
                      name={'bir_sey_tarihi'}
                      id={'bir_sey_tarihi'}
                      placeholder="Select Date"
                      locale="tr"
                      specialDays={[
                        { day: 1, month: 1, memo: 'New Years' },
                        { day: 14, month: 2, memo: 'Valentine`s Day' },
                      ]}
                      ariaLabelledby={'aria - labelledby'}
                      label={'Hello'}
                      date={cashForm?.date as string}
                      onHandleChangeDate={onHandleChangeDate}
                    />
                  </div>
                  <div className="d-flex align-content-start">
                    <button onClick={addForm} className="btn btn-sm btn-success btn-sm me-1">
                      Ajouter
                    </button>
                    <div className="ms-auto">
                      <a onClick={removeForm} className="btn btn-icon btn-sm me-1 w-32px h-32px">
                        <KTSVG
                          path='/media/icons/duotune/abstract/trash.svg'
                          className='svg-icon svg-icon-2 svg-icon-danger'
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </> :
            <>
              {paymentFormType === 'check' ?
                <>
                  <div className="card mt-10 border border-1 border-secondary">
                    <div className="card-body px-7 py-5">
                      <span className="badge bg-med-orange fs-5 fw-500">{paymentFormType}</span>
                      <div className="mb-5 mt-5">
                        <label
                          className="form-label fw-semibold">{intl.formatMessage({ id: 'accounting.payment.entity.amount' })}</label>
                        <input
                          placeholder={intl.formatMessage({ id: 'accounting.payment.entity.amount' })}
                          className={'form-control form-control-solid form-control-lg col-md-10'}
                          type='text'
                          autoComplete='off'
                          name="amount"
                          value={checkForm.amount as number}
                          onChange={(e) => onChangeField(e)}
                        />
                      </div>
                      <div className="mb-5">
                        <label
                          className="form-label fw-semibold">{intl.formatMessage({ id: 'accounting.payment.entity.drawer' })}</label>
                        <input
                          placeholder={intl.formatMessage({ id: 'accounting.payment.entity.drawer' })}
                          className={'form-control form-control-solid form-control-lg col-md-10'}
                          type='text'
                          autoComplete='off'
                          name="drawer"
                          value={checkForm.drawer as string}
                          onChange={(e) => onChangeField(e)}
                        />
                      </div>
                      <div className="mb-5">
                        <label
                          className="form-label fw-semibold">{intl.formatMessage({ id: 'accounting.payment.entity.checkNumber' })}</label>
                        <input
                          placeholder={intl.formatMessage({ id: 'accounting.payment.entity.checkNumber' })}
                          className={'form-control form-control-solid form-control-lg col-md-10'}
                          type='text'
                          autoComplete='off'
                          name="checkNumber"
                          value={checkForm.checkNumber as string}
                          onChange={(e) => onChangeField(e)}
                        />
                      </div>
                      <div className="mb-5">
                        <label
                          className="form-label fw-semibold">{intl.formatMessage({ id: 'accounting.payment.entity.bank' })}</label>
                        <Select
                          options={banks}
                          styles={colourStyles}
                          name="bank"
                          onChange={(e) => onChangeBank(e)}
                          defaultValue={{ label: checkForm.bankName, value: checkForm.bank }}
                        />
                      </div>
                      <div className="mb-5">
                        <label
                          className="form-label fw-semibold">{intl.formatMessage({ id: 'accounting.payment.entity.settlementDate' })}</label>
                        <DateTimePicker
                          format="YYYY/MM/DD"
                          name={'bir_sey_tarihi'}
                          id={'bir_sey_tarihi'}
                          placeholder="Select Date"
                          locale="tr"
                          specialDays={[
                            { day: 1, month: 1, memo: 'New Years' },
                            { day: 14, month: 2, memo: 'Valentine`s Day' },
                          ]}
                          ariaLabelledby={'aria - labelledby'}
                          label={'Hello'}
                          date={checkForm?.settlementDate as string}
                          onHandleChangeDate={onHandleChangeSettlementDate}
                        />
                      </div>
                      <div className="mb-5">
                        <label
                          className="form-label fw-semibold">{intl.formatMessage({ id: 'accounting.payment.entity.dueDate' })}</label>
                        <DateTimePicker
                          format="YYYY/MM/DD"
                          name={'bir_sey_tarihi'}
                          id={'bir_sey_tarihi'}
                          placeholder="Select Date"
                          locale="tr"
                          specialDays={[
                            { day: 1, month: 1, memo: 'New Years' },
                            { day: 14, month: 2, memo: 'Valentine`s Day' },
                          ]}
                          ariaLabelledby={'aria - labelledby'}
                          label={'Hello'}
                          date={checkForm?.dueDate as string}
                          onHandleChangeDate={onHandleChangeDueDate}
                        />
                      </div>
                      <div className="d-flex align-content-start">
                        <button onClick={addForm}
                          className="btn btn-sm btn-success btn-sm me-1">
                            Ajouter
                        </button>
                        <div className="ms-auto">
                          <a onClick={removeForm} className="btn btn-icon btn-sm me-1 w-32px h-32px">
                            <KTSVG
                              path='/media/icons/duotune/abstract/trash.svg'
                              className='svg-icon svg-icon-2 svg-icon-danger'
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </> :
                <>
                  {paymentFormType === 'transfer' ?
                    <>
                      <div className="card mt-10 border border-1 border-secondary">
                        <div className="card-body px-7 py-5">
                          <span className="badge bg-med-orange fs-5 fw-500">{paymentFormType}</span>
                          <div className="mb-5 mt-5">
                            <label
                              className="form-label fw-semibold">{intl.formatMessage({ id: 'accounting.payment.entity.amount' })}</label>
                            <input
                              placeholder={intl.formatMessage({ id: 'accounting.payment.entity.amount' })}
                              className={'form-control form-control-solid form-control-lg col-md-10'}
                              type='text'
                              autoComplete='off'
                              name="amount"
                              value={transferForm.amount as number}
                              onChange={(e) => onChangeField(e)}
                            />
                          </div>
                          <div className="mb-5">
                            <label
                              className="form-label fw-semibold">{intl.formatMessage({ id: 'accounting.payment.entity.transferInformation' })}</label>
                            <input
                              placeholder={intl.formatMessage({ id: 'accounting.payment.entity.transferInformation' })}
                              className={'form-control form-control-solid form-control-lg col-md-10'}
                              type='text'
                              autoComplete='off'
                              name="transferInformation"
                              value={transferForm.transferInformation as string}
                              onChange={(e) => onChangeField(e)}
                            />
                          </div>
                          <div className="mb-5">
                            <label
                              className="form-label fw-semibold">{intl.formatMessage({ id: 'accounting.payment.entity.settlementDate' })}</label>
                            <DateTimePicker
                              format="YYYY/MM/DD"
                              name={'bir_sey_tarihi'}
                              id={'bir_sey_tarihi'}
                              placeholder="Select Date"
                              locale="tr"
                              specialDays={[
                                { day: 1, month: 1, memo: 'New Years' },
                                { day: 14, month: 2, memo: 'Valentine`s Day' },
                              ]}
                              ariaLabelledby={'aria - labelledby'}
                              label={'Hello'}
                              date={transferForm?.settlementDate as string}
                              onHandleChangeDate={onHandleChangeSettlementDate}
                            />
                          </div>
                          <div className="d-flex align-content-start">
                            <button onClick={addForm}
                              className="btn btn-sm btn-success btn-sm me-1">
                                  Ajouter
                            </button>
                            <div className="ms-auto">
                              <a onClick={removeForm}
                                className="btn btn-icon btn-sm me-1 w-32px h-32px">
                                <KTSVG
                                  path='/media/icons/duotune/abstract/trash.svg'
                                  className='svg-icon svg-icon-2 svg-icon-danger'
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </> :
                    <>
                      {paymentFormType === 'draft' ?
                        <div className="card mt-10 border border-1 border-secondary">
                          <div className="card-body px-7 py-5">
                            <span className="badge bg-med-orange fs-5 fw-500">{paymentFormType}</span>
                            <div className="mb-5 mt-5">
                              <label
                                className="form-label fw-semibold">{intl.formatMessage({ id: 'accounting.payment.entity.amount' })}</label>
                              <input
                                placeholder={intl.formatMessage({ id: 'accounting.payment.entity.amount' })}
                                className={'form-control form-control-solid form-control-lg col-md-10'}
                                type='text'
                                autoComplete='off'
                                name="amount"
                                value={draftForm.amount as number}
                                onChange={(e) => onChangeField(e)}
                              />
                            </div>
                            <div className="mb-5">
                              <label
                                className="form-label fw-semibold">{intl.formatMessage({ id: 'accounting.payment.entity.sourceAccount' })}</label>
                              <input
                                placeholder={intl.formatMessage({ id: 'accounting.payment.entity.sourceAccount' })}
                                className={'form-control form-control-solid form-control-lg col-md-10'}
                                type='text'
                                name="sourceAccount"
                                value={draftForm.sourceAccount as string}
                                autoComplete='off'
                                onChange={(e) => onChangeField(e)}
                              />
                            </div>
                            <div className="mb-5">
                              <label
                                className="form-label fw-semibold">{intl.formatMessage({ id: 'accounting.payment.entity.draftNumber' })}</label>
                              <input
                                placeholder={intl.formatMessage({ id: 'accounting.payment.entity.draftNumber' })}
                                className={'form-control form-control-solid form-control-lg col-md-10'}
                                type='text'
                                autoComplete='off'
                                name="draftNumber"
                                value={draftForm.draftNumber as string}
                                onChange={(e) => onChangeField(e)}
                              />
                            </div>
                            <div className="mb-5">
                              <label
                                className="form-label fw-semibold">{intl.formatMessage({ id: 'accounting.payment.entity.settlementDate' })}</label>
                              <DateTimePicker
                                format="YYYY/MM/DD"
                                name={'bir_sey_tarihi'}
                                id={'bir_sey_tarihi'}
                                placeholder="Select Date"
                                locale="tr"
                                specialDays={[
                                  { day: 1, month: 1, memo: 'New Years' },
                                  { day: 14, month: 2, memo: 'Valentine`s Day' },
                                ]}
                                ariaLabelledby={'aria - labelledby'}
                                label={'Hello'}
                                date={draftForm?.settlementDate as string}
                                onHandleChangeDate={onHandleChangeSettlementDate}
                              />
                            </div>
                            <div className="mb-5">
                              <label
                                className="form-label fw-semibold">{intl.formatMessage({ id: 'accounting.payment.entity.dueDate' })}</label>
                              <DateTimePicker
                                format="YYYY/MM/DD"
                                name={'bir_sey_tarihi'}
                                id={'bir_sey_tarihi'}
                                placeholder="Select Date"
                                locale="tr"
                                specialDays={[
                                  { day: 1, month: 1, memo: 'New Years' },
                                  { day: 14, month: 2, memo: 'Valentine`s Day' },
                                ]}
                                ariaLabelledby={'aria - labelledby'}
                                label={'Hello'}
                                date={draftForm?.dueDate as string}
                                onHandleChangeDate={onHandleChangeDueDate}
                              />
                            </div>
                            <div className="d-flex align-content-start">
                              <button onClick={addForm}
                                className="btn btn-sm btn-success btn-sm me-1">
                                Ajouter
                              </button>
                              <div className="ms-auto">
                                <a onClick={removeForm}
                                  className="btn btn-icon btn-sm me-1 w-32px h-32px">
                                  <KTSVG
                                    path='/media/icons/duotune/abstract/trash.svg'
                                    className='svg-icon svg-icon-2 svg-icon-danger'
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div> :
                        <>
                        </>
                      }
                    </>
                  }
                </>
              }
            </>
          }

          <div className="table-responsive mt-5">
            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
              <thead>
                <tr className="fw-bolder text-muted border-0 bg-light-primary fw-600">
                  <th className="ms-5">
                    <span className="ms-5 fs-5 fw-500 text-gray-700">Méthode</span>
                  </th>
                  <th className="text-end">
                    <span className="me-5 fs-5 fw-500 text-gray-700">Montant</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {formValues.map((item: any, index: number) => (
                  <tr key={`payment_${index}`}>
                    <td>
                      <KTSVG
                        path={`/media/icons/duotune/${item.paymentType === 'cash' ? 'abstract/money.svg' : item.paymentType === 'check'}`}
                        className='svg-icon svg-icon-2 svg-icon-dark ms-2'
                      />
                      <span className="text-dark fs-5 fw-500 ms-5">{item.paymentType}</span>
                    </td>
                    <td className="text-end">
                      <span className="text-primary fs-5 fw-500 me-5">{item.amount} TND</span>
                      <a onClick={() => removeFormValues(index)} className="btn btn-icon btn-sm me-1 w-32px h-32px">
                        <KTSVG
                          path='/media/icons/duotune/abstract/trash.svg'
                          className='svg-icon svg-icon-2 svg-icon-danger'
                        />
                      </a>
                      <a className="btn btn-icon btn-sm me-1 w-32px h-32px" onClick={() => editForm(index, item.paymentType)}>
                        <KTSVG path="/media/icons/duotune/abstract/write.svg" className="svg-icon svg-icon-2 svg-icon-dark" />
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer className="p-2">
          <button
            type='button'
            className='btn btn-sm btn-secondary'
            onClick={async () => {
              handleClose()
              setFormValues([])
            }}
          >
            {intl.formatMessage({ id: 'action.close' })}
          </button>
          <button
            type='submit'
            className='btn btn-sm btn-primary'
            onClick={handleShowContract}
          >
            {intl.formatMessage({ id: 'action.save' })}
          </button>
        </Modal.Footer>
      </Modal>
      <AccountingContract payment={payment} formValues={formValues} show={showContract} handleClose={handleCloseContract} remainingPayment={remainingPayment} onSubmit={onSubmit}/>
    </>
  )
}

export default PaymentCreate
