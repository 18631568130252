import clsx from 'clsx'
import React, { FC } from 'react'
import { KTSVG } from '../../../helpers'
import {
  HeaderUserMenu,
  ThemeModeSwitcher,
} from '../../../partials'
import { useLayout } from '../../core'
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../../setup";
import Functions from "../../../../setup/utilities/Functions";

const toolbarButtonMarginClass = 'ms-1 ms-lg-3'
const toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px'
const toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'
const toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
  const { config } = useLayout()
  const user: IUser = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as IUser

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
        {user && user.roles.includes('ROLE_ADMIN') ? (
            <>
                {/* Search */}
                <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
                    <div
                        className='d-flex align-items-center'
                        data-kt-search-element='toggle'
                        id='kt_header_search_toggle'
                    >
                        <div className='btn btn-icon w-30px h-30px w-md-40px h-md-40px'>
                            <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-1 icon-header' />
                        </div>
                    </div>
                </div>
                {/* Activities */}
                <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
                    {/* begin::Drawer toggle */}
                    <div
                        className={clsx(
                            'btn btn-icon btn-custom',
                            toolbarButtonHeightClass,
                        )}
                    >
                        <KTSVG
                            path='/media/icons/duotune/communication/com009.svg'
                            className='svg-icon-1 icon-header'
                        />
                    </div>
                    {/* end::Drawer toggle */}
                </div>

                {/* NOTIFICATIONS */}
                <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
                    {/* begin::Menu- wrapper */}
                    <div
                        className={clsx(
                            'btn btn-icon btn-custom',
                            toolbarButtonHeightClass,
                        )}
                    >
                        <KTSVG
                            path='/media/icons/duotune/ecommerce/ecm012.svg' className='svg-icon-1 icon-header'
                        />
                    </div>
                    {/* end::Menu wrapper */}
                </div>

                {/* CHAT */}
                <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
                    {/* begin::Menu wrapper */}
                    <div
                        className={clsx(
                            'btn btn-icon btn-custom position-relative',
                            toolbarButtonHeightClass,
                        )}
                    >
                        <KTSVG
                            path='/media/icons/duotune/general/gen007.svg' className='svg-icon-1 icon-header'
                        />
                    </div>
                    {/* end::Menu wrapper */}
                </div>

                {/* Quick links */}
                <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
                    {/* begin::Menu wrapper */}
                    <div
                        className={clsx(
                            'btn btn-icon btn-custom',
                            toolbarButtonHeightClass,
                        )}
                    >
                        <KTSVG
                            path='/media/icons/duotune/general/gen025.svg' className='svg-icon-1 icon-header'
                        />
                    </div>
                    {/* end::Menu wrapper */}
                </div>
            </>
        ) : (
            <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
                {/* begin::Menu wrapper */}
                <div
                    className={clsx(
                        'btn btn-icon btn-custom',
                        toolbarButtonHeightClass,
                    )}
                >
                    <KTSVG
                        path='/media/icons/duotune/abstract/group-chat.svg' className='svg-icon-1 icon-header'
                    />
                </div>
                {/* end::Menu wrapper */}
            </div>
        )}

      {/* begin::Theme mode */}
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <ThemeModeSwitcher
          toggleBtnClass={clsx('btn-custom', toolbarButtonHeightClass)}
        />
      </div>
      {/* end::Theme mode */}

      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <span className='symbol symbol-50px'>
            {user.profilePicture !== null && user.profilePicture !== undefined ? (
                <img
                    className='w-40px h-40px'
                    src={`data:image/*;base64,${user.profilePicture}`}
                    alt={`${user.firstName} ${user.lastName}`}
                />
            ) : (
                <div
                    className={`symbol-label fs-6 fw-bold`}>
                    {Functions.firstLetterUpperCase(user.firstName?.charAt(0))}{Functions.firstLetterUpperCase(user.lastName?.charAt(0))}
                </div>
            )}
          </span>
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      )}
    </div>
  )
}

export { Topbar }
