import React, { useEffect } from 'react'
import { Navigate, Routes, useNavigate } from 'react-router-dom'
import KeycloakService from '../../security/KeycloakService'

export function Logout() {
  const navigate = useNavigate()

  useEffect(() => {
    KeycloakService.doLogout()
    return navigate('/')
  }, [])

  return (
    <Routes>
      <Navigate to='/auth' />
    </Routes>

  )
}
