import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import Select from 'react-select'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import ExpenseCategoryService from '../../../../../../services/Accounting/ExpenseCategoryService'
import IncomeCategoryService from '../../../../../../services/Accounting/IncomeCategoryService'
import DatePicker from 'react-datepicker'
import format from 'date-fns/format'
import BankAccountService from '../../../../../../services/Accounting/BankAccountService'
import PaymentMethodService from '../../../../../../services/Accounting/PaymentMethodService'
import PaymentService from '../../../../../../services/Accounting/PaymentService'
import { useParams } from 'react-router-dom'

const colourStyles = {
  control: (styles: any) => ({ ...styles, backgroundColor: '#F5F8FA', border: 0, height: '45px' }),
}

const operationSchema = Yup.object().shape({
  paymentType: Yup.string()
    .required(),
  paymentMethod: Yup.string()
    .required(),
  type: Yup.string()
    .required(),
  incomeCategory: Yup.string()
    .notRequired(),
  expenseCategory: Yup.string()
    .notRequired(),
  amount: Yup.string()
    .required(),
  settlementDate: Yup.string()
    .required(),
  note: Yup.string()
    .notRequired(),
  checkNumber: Yup.string()
    .notRequired(),
  destinationAccount: Yup.string()
    .notRequired(),
  dueDate: Yup.string()
    .notRequired(),
})

type Inputs = {
  paymentType: string,
  paymentMethod: string,
  type: string,
  incomeCategory: string,
  expenseCategory: string,
  amount: string,
  settlementDate: string,
  note: string,
  checkNumber: string,
  destinationAccount: string,
  dueDate: string,
}

const OperationCreate : React.FC = () => {
  const { id } = useParams()
  const intl = useIntl()
  const [incomeCategories, setIncomeCategories] = useState<{value: string, label: string}[]>([])
  const [expenseCategories, setExpenseCategories] = useState<{value: string, label: string}[]>([])
  const [settlementDate, setSettlementDate] = useState<any>(format(new Date(), 'MM/dd/yyyy'))
  const [dueDate, setDueDate] = useState<any>(format(new Date(), 'MM/dd/yyyy'))
  const [bankAccounts, setBankAccounts] = useState<{value: string, label: string}[]>([])
  const [paymentMethods, setPaymentMethods] = useState<{value: string, label: string}[]>([])
  const [isIn, setIsIn] = useState<number>(0)

  const config = {
    resolver: yupResolver(operationSchema),
    defaultValues: {
      paymentType: '',
      paymentMethod: '',
      type: '',
      incomeCategory: '',
      expenseCategory: '',
      amount: '',
      settlementDate: '',
      note: '',
      checkNumber: '',
      destinationAccount: '',
      dueDate: '',
    },
  }

  const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm<Inputs>(config)

  const watchPaymentType = watch('paymentType')

  const watchPaymentMethod = watch('type')

  const onChangePaymentType = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue('paymentType', e.target.value)
    if (e.target.value === 'income') {
      setIsIn(1)
    } else {
      setIsIn(0)
    }
  }

  const onChangeIncomeCategory = (event: any) => {
    setValue('incomeCategory', event.value)
  }

  const onChangeExpenseCategory = (event: any) => {
    setValue('expenseCategory', event.value)
  }

  const onChangeSettlementDate = (event : any) => {
    setValue('settlementDate', format(event, 'MM/dd/yyyy'))
    setSettlementDate(format(event, 'MM/dd/yyyy'))
  }

  const onChangePaymentMethod = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setValue('type', e.target.value)
    setValue('paymentMethod', value)
  }

  const onChangeDueDate = (event : any) => {
    setValue('dueDate', format(event, 'MM/dd/yyyy'))
    setDueDate(format(event, 'MM/dd/yyyy'))
  }

  const onChangeDestinationAccount = (event: any) => {
    setValue('destinationAccount', event.value)
  }

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    PaymentService.createOperation(data, id!.toString()).then(() => {
      // window.location.reload()
    })
  }

  useEffect(() => {
    ExpenseCategoryService.selectExpenseCategories().then((response) => {
      setExpenseCategories(response.data.data)
    })
    IncomeCategoryService.selectIncomeCategories().then((response) => {
      setIncomeCategories(response.data.data)
    })
    BankAccountService.selectBankAccount().then((response) => {
      setBankAccounts(response.data.data)
    })
    PaymentMethodService.selectPaymentMethods(isIn).then((response) => {
      setPaymentMethods(response.data.data)
    })
  }, [isIn])

  return (
    <>
      <div className={'card mb-xl-10'}>
        <div className={'card-body px-20'}>
          <form className='form w-100 mt-10' onSubmit={handleSubmit(onSubmit)} noValidate id='create-customer-form'>
            <div className="row mb-10">
              <div className="col-2 pt-5">
                <label className="required fw-semibold fs-6">Type</label>
              </div>
              <div className="col-10">
                <div className="d-flex gap-4" data-kt-buttons="true">
                  <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary bg-gray-100 d-flex flex-stack text-start p-6 w-150px h-50px ${watchPaymentType === 'expense' ? 'active' : ''}`}>
                    <div className="d-flex align-items-center me-2">
                      <div className="form-check form-check-custom form-check-solid form-check-primary me-3">
                        <input checked={watchPaymentType === 'expense'} className="form-check-input" type="radio" value="expense" name="paymentType" onChange={onChangePaymentType}/>
                      </div>
                      <div className="flex-grow-1">
                        <div className="fw-600">
                          Débit
                        </div>
                      </div>
                    </div>
                  </label>
                  <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary bg-gray-100 d-flex flex-stack text-start p-6 w-150px h-50px ${watchPaymentType === 'income' ? 'active' : ''}`}>
                    <div className="d-flex align-items-center me-2">
                      <div className="form-check form-check-custom form-check-solid form-check-primary me-3">
                        <input checked={watchPaymentType === 'income'} className="form-check-input" type="radio" value="income" name="paymentType" onChange={onChangePaymentType}/>
                      </div>
                      <div className="flex-grow-1">
                        <div className="fw-600">
                          Crédit
                        </div>
                      </div>
                    </div>
                  </label>
                </div>

              </div>
              {errors.paymentType && errors.paymentType.message && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{errors.paymentType.message}</span>
                </div>
              )}
            </div>
            <div className="fv-row mb-7 fv-plugins-icon-container">
              <label className="required fw-semibold fs-6 mb-2">Catégorie</label>
              <Select
                options={watchPaymentType === 'income' ? incomeCategories : expenseCategories}
                styles={colourStyles}
                onChange={watchPaymentType === 'income' ? onChangeIncomeCategory : onChangeExpenseCategory}
              />
              {errors.incomeCategory && errors.incomeCategory.message && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{errors.incomeCategory.message}</span>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-6">
                <div className="fv-row mb-7 fv-plugins-icon-container">
                  <label className="required fw-semibold fs-6 mb-2">{intl.formatMessage({ id: 'accounting.payment.entity.amount' })}</label>
                  <input
                    {...register('amount')}
                    placeholder={intl.formatMessage({ id: 'accounting.payment.entity.amount' })}
                    className='form-control form-control-solid'
                    type='text'
                    autoComplete='off'
                  />
                  {errors.amount && errors.amount.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.amount.message}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="fv-row mb-7 fv-plugins-icon-container">
                  <label className="required fw-semibold fs-6 mb-2">{intl.formatMessage({ id: 'accounting.payment.entity.settlementDate' })}</label>
                  <DatePicker name="date" onChange={onChangeSettlementDate} value={settlementDate} className='form-control form-control-solid'/>

                  {errors.settlementDate && errors.settlementDate.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.settlementDate.message}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row pt-5">
              <div className="col-2">
                <label className="required fw-semibold fs-6 pt-3">Méthode</label>
              </div>
              <div className="col-10">
                <div className="d-flex gap-4" data-kt-buttons="true">
                  {paymentMethods.map((item, index) => (
                    <div key={`payment_method_${index}`}>
                      {item.label === 'cash' || item.label === 'check' ?
                        <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary bg-gray-100 d-flex flex-stack text-start p-6 w-125px h-50px ${watchPaymentMethod === item.label ? 'active' : ''}`}>
                          <div className="d-flex align-items-center">
                            <div className="form-check form-check-custom form-check-solid form-check-primary me-2">
                              <input checked={watchPaymentMethod === item.label} className="form-check-input" type="radio" value={item.label} name="depositType"
                                onChange={(e) => onChangePaymentMethod(e, item.value)}
                              />
                            </div>
                            <div className="fw-600">
                              {item.label}
                            </div>
                          </div>
                        </label> :
                        <>
                        </>
                      }
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {watchPaymentMethod === 'check' ?
              <>
                <div className="fv-row mb-7 fv-plugins-icon-container pt-10">
                  <label className="required fw-semibold fs-6 mb-2">{intl.formatMessage({ id: 'accounting.payment.entity.checkNumber' })}</label>
                  <input
                    {...register('checkNumber')}
                    placeholder={intl.formatMessage({ id: 'accounting.payment.entity.checkNumber' })}
                    className='form-control form-control-solid'
                    type='text'
                    autoComplete='off'
                  />
                  {errors.checkNumber && errors.checkNumber.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.checkNumber.message}</span>
                    </div>
                  )}
                </div>

                <div className="fv-row mb-7 fv-plugins-icon-container">
                  <label className="required fw-semibold fs-6 mb-2">{intl.formatMessage({ id: 'accounting.payment.entity.bank' })}</label>
                  <Select
                    options={bankAccounts}
                    styles={colourStyles}
                    name="destinationAccount"
                    onChange={onChangeDestinationAccount}
                  />
                  {errors.destinationAccount && errors.destinationAccount.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.destinationAccount.message}</span>
                    </div>
                  )}
                </div>

                <div className="fv-row mb-7 fv-plugins-icon-container">
                  <label className="required fw-semibold fs-6 mb-2">{intl.formatMessage({ id: 'accounting.payment.entity.dueDate' })}</label>
                  <DatePicker name="date" onChange={onChangeDueDate} value={dueDate} className='form-control form-control-solid form-control-lg col-md-10'/>

                  {errors.dueDate && errors.dueDate.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.dueDate.message}</span>
                    </div>
                  )}
                </div>
              </> :
              <>
              </>
            }

            <div className='fv-row mb-7 fv-plugins-icon-container'>
              <label className='fw-semibold fs-6 mb-2'>Note</label>
              <textarea
                {...register('note')}
                placeholder='Note'
                className={`form-control form-control-solid form-control-lg col-md-10 ${errors.note ? 'is-invalid' : ''}`}
                name='note'
                autoComplete='off'
              />
              {errors.note && errors.note.message && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{errors.note.message}</span>
                </div>
              )}
            </div>

            <div className="d-flex justify-content-end pt-10">
              <button
                type='submit'
                className='btn btn-sm btn-primary'
              >
                {intl.formatMessage({ id: 'action.save' })}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default OperationCreate
