import React, { useEffect, useState } from 'react'
import TableHeaderSolid from '../../../../../_metronic/partials/med-table/TableHeaderSolid'
import { KTSVG } from '../../../../../_metronic/helpers'
import PaginationBar from '../../../../../_metronic/partials/pagination/PaginationBar'
import { useIntl } from 'react-intl'
import Functions from '../../../../../setup/utilities/Functions'
import EmployeeEquipmentService from '../../../../services/Hr/EmployeeEquipmentService'
import Symbol from '../../../../../_metronic/partials/common/Symbol'

type Props = {
    show: boolean,
    employeeEquipmentSelected: IEmployeeEquipment|null|undefined
    handleShow: (employeeEquipment:IEmployeeEquipment|undefined|null, action: string) => void
    handleClose: (isSubmit: boolean) => void
    action: string
}

const EmployeeEquipmentListing : React.FC<Props> = ({ show, handleClose, handleShow, employeeEquipmentSelected, action }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [employeeEquipments, setEmployeeEquipments] = useState<IEmployeeEquipment[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = React.useState(1)
  const intl = useIntl()

  const handleCurrentPage = (page:number) => {
    setIsLoading(true)
    setCurrentPage(page)
  }

  const deleteProduct = async (uuid:string|undefined) => {
    const isConfirmed = await Functions.sweetAlert(
      '<span class="mt-5">Are you sure you want to delete this product feature?</span>',
      'warning',
      true,
      'Yes, delete!',
      'No, cancel',
      'btn btn-danger mt-10 mb-10',
      'btn btn-active-light mt-10 mb-10',
    )
    if (isConfirmed) {
      // TODO delete employeeEquipment
    }
  }

  const headers = [
    {
      minWidth: 'w-300px',
      text: intl.formatMessage({ id: 'name.label' }),
    },
    {
      minWidth: 'w-400px',
      text: intl.formatMessage({ id: 'employeeEquipment.reference.label' }),
    },
    {
      minWidth: 'w-400px',
      text: intl.formatMessage({ id: 'employeeEquipment.state.label' }),
    },
    {
      minWidth: 'w-100px',
      text: intl.formatMessage({ id: 'employeeEquipment.employee.label' }),
    },
    {
      minWidth: 'w-100px',
      text: intl.formatMessage({ id: 'actions' }),
    },
  ]

  useEffect(() => {
    EmployeeEquipmentService.getOwnedEmployeeEquipments(currentPage).then((response) => {
      setEmployeeEquipments(response.data.data.list)
      setTotalPages(response.data.data.totalPages)
      setIsLoading(false)
    })
  }, [isLoading, currentPage])

  return (
    <>
      <div className={`card mb-5 ${isLoading ? 'overlay overlay-block' : ''}`}>
        <div className={`card-body py-3 ${isLoading ? 'overlay-wrapper' : ''}`}>
          <div>
            <div className={'row pt-5'}>
              <div className="table-responsive">
                <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                  <TableHeaderSolid headers={headers} />
                  <tbody>
                    {employeeEquipments && employeeEquipments.map((employeeEquipmentItem, index) => (
                      <tr key={`employee_equipment_${index}`}>
                        <td>
                          <span className="text-muted text-muted">{employeeEquipmentItem.title}</span>
                        </td>
                        <td>
                          <span className="text-muted text-muted">{employeeEquipmentItem.reference}</span>
                        </td>
                        <td>
                          <span className="text-muted text-muted">{employeeEquipmentItem.state}</span>
                        </td>
                        <td className='pt-8'>
                          <div className='me-2 pt-2 d-flex flex-stack flex-wrap'>
                            <div className="symbol-group symbol-hover">
                              <Symbol
                                key={`employee_${employeeEquipmentItem.employee}`}
                                picture={employeeEquipmentItem.employee.profilePicture}
                                picturePath='/admin/uploads/users/'
                                withText={false}
                                widthClassName='symbol-35px mb-5'
                                text={Functions.fullName(employeeEquipmentItem.employee.firstName, employeeEquipmentItem.employee.lastName)}
                                shortText={employeeEquipmentItem.employee.firstName.charAt(0)}
                                description={null}
                                backgroundClass='bg-med-grey'
                                textClassName='text-inverse-primary'
                                isCircular={true}
                                descriptionColor={'text-muted'}
                                textColor={'text-dark'}
                              />
                            </div>
                          </div>
                        </td>
                        <td className="text-end">
                          <a href="src/app/modules/hr/employee-equipment/components/EmployeeEquipmentListing#" onClick={() => handleShow(employeeEquipmentItem, 'edit')} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 w-32px h-32px">
                            <KTSVG
                              path='/media/icons/duotune/abstract/write.svg'
                              className='svg-icon svg-icon-2'
                            />
                          </a>
                          <a href="src/app/modules/hr/employee-equipment/components/EmployeeEquipmentListing#" onClick={() => deleteProduct(employeeEquipmentItem.uuid)} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 w-32px h-32px">
                            <KTSVG
                              path='/media/icons/duotune/abstract/trash.svg'
                              className='svg-icon svg-icon-2'
                            />
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {isLoading &&
                        <div className="overlay-layer rounded bg-dark bg-opacity-5">
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
          }
        </div>
        {totalPages > 1 ? (
          <PaginationBar
            totalPages={totalPages}
            currentPage={currentPage}
            handleCurrentPage={handleCurrentPage}
          />
        ) : (<></>)}
      </div>
    </>
  )
}

export default EmployeeEquipmentListing
