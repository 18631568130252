import { FC, createContext, useContext, useState, useEffect } from 'react'
import { DefaultLayoutConfig } from './DefaultLayoutConfig'
import {
  getEmptyCssClasses,
  getEmptyCSSVariables,
  getEmptyHTMLAttributes,
  LayoutSetup,
} from './LayoutSetup'
import {
  ILayout,
  ILayoutCSSVariables,
  ILayoutCSSClasses,
  ILayoutHTMLAttributes,
} from './LayoutModels'
import { WithChildren } from '../../helpers'
import { shallowEqual, useSelector } from 'react-redux'
import {RootState} from "../../../setup";

export interface LayoutContextModel {
  config: ILayout
  classes: ILayoutCSSClasses
  attributes: ILayoutHTMLAttributes
  cssVariables: ILayoutCSSVariables
  setLayout: (config: LayoutSetup) => void
}

const LayoutContext = createContext<LayoutContextModel>({
  config: DefaultLayoutConfig,
  classes: getEmptyCssClasses(),
  attributes: getEmptyHTMLAttributes(),
  cssVariables: getEmptyCSSVariables(),
  setLayout: (config: LayoutSetup) => {},
})

const enableSplashScreen = () => {
  const splashScreen = document.getElementById('splash-screen')
  if (splashScreen) {
    splashScreen.style.setProperty('display', 'flex')
  }
}

const disableSplashScreen = () => {
  const splashScreen = document.getElementById('splash-screen')
  if (splashScreen) {
    splashScreen.style.setProperty('display', 'none')
  }
}

const LayoutProvider: FC<WithChildren> = ({ children }) => {
  const user: IUser = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as IUser
  if (user) {
    if (user.roles && !user.roles.includes('ROLE_ADMIN')) {
      LayoutSetup.updatePartialConfig({
        header: {
          display: true, // Set true|false to show or hide Header
          width: 'fixed', // Set fixed|fluid to change width type
          left: 'menu',
          fixed: {
            desktop: true, // Set true|false to set fixed Header for desktop mode
            tabletAndMobile: true, // Set true|false to set fixed Header for tablet and mobile modes
          },
          menuIcon: 'svg',
        },
        aside: {
          display: false,
          theme: 'dark',
          menu: 'main',
          fixed: true,
          minimized: false,
          minimize: true,
          hoverable: true,
          menuIcon: 'svg',
        },
        content: {
          width: 'fixed', // Set fixed|fluid to change width
          layout: 'default',
        },
        toolbar: {
          display: false, // Display toolbar
          width: 'fluid', // Set fixed|fluid to change width type,
          fixed: {
            desktop: true,
            tabletAndMobileMode: true,
          },
          layout: 'toolbar1',
          layouts: {
            toolbar1: {
              height: '55px',
              heightAndTabletMobileMode: '55px',
            },
            toolbar2: {
              height: '75px',
              heightAndTabletMobileMode: '65px',
            },
            toolbar3: {
              height: '55px',
              heightAndTabletMobileMode: '55px',
            },
            toolbar4: {
              height: '65px',
              heightAndTabletMobileMode: '65px',
            },
            toolbar5: {
              height: '75px',
              heightAndTabletMobileMode: '65px',
            },
          },
        },
      })
    }
  }
  const [config, setConfig] = useState(LayoutSetup.config)
  const [classes, setClasses] = useState(LayoutSetup.classes)
  const [attributes, setAttributes] = useState(LayoutSetup.attributes)
  const [cssVariables, setCSSVariables] = useState(LayoutSetup.cssVariables)
  const setLayout = (_themeConfig: Partial<ILayout>) => {
    enableSplashScreen()
    const bodyClasses = Array.from(document.body.classList)
    bodyClasses.forEach((cl) => document.body.classList.remove(cl))
    LayoutSetup.updatePartialConfig(_themeConfig)
    setConfig(Object.assign({}, LayoutSetup.config))
    setClasses(LayoutSetup.classes)
    setAttributes(LayoutSetup.attributes)
    setCSSVariables(LayoutSetup.cssVariables)
    setTimeout(() => {
      disableSplashScreen()
    }, 500)
  }
  const value: LayoutContextModel = {
    config,
    classes,
    attributes,
    cssVariables,
    setLayout,
  }

  useEffect(() => {
    disableSplashScreen()
  }, [])

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
}

export { LayoutContext, LayoutProvider }

export function useLayout() {
  return useContext(LayoutContext)
}
