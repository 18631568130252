import React from 'react'
import useWindowDimensions from '../../../../../../../../../_metronic/partials/mobile-check-view/useCheckMobileScreen'
import { KTSVG } from '../../../../../../../../../_metronic/helpers'
import Functions from '../../../../../../../../../setup/utilities/Functions'
import DropDownList from '../../../common/components/DropDownList'
import format from 'date-fns/format'
import { Link } from 'react-router-dom'
import SymbolMedicalProfessional from '../../../../../../../../../_metronic/partials/common/SymbolMedicalProfessional'

type Props = {
  timeSlot : ITimeSlot,
  handleShowCall: (calls: ICall[], timeSlot: ITimeSlot|null) => void,
  handleShowSugBug: () => void,
  checkIn: (timeSlot: ITimeSlot) => void,
  isLoading: boolean,
  journey: IJourney|null,
}

const TodoItem : React.FC<Props> = ({ timeSlot, handleShowSugBug, handleShowCall, checkIn, isLoading, journey }) => {
  const isMobile = useWindowDimensions()
  return (
    <>
      <div className={`card card-rounded mt-5 ${isLoading ? 'overlay overlay-block' : ''}`} >
        <div className={`card-body px-2 py-0 ${isLoading ? 'overlay overlay-block' : ''}`}>
          <div className="d-flex">
            <div className="mt-3">
              <div className="d-flex flex-column h-100 pb-2">
                <KTSVG
                  path='/media/icons/duotune/abstract/abs009.svg'
                  className='svg-icon svg-icon-1 svg-icon-gray-300'
                />
                <div className='vr bg-gray-300 opacity-100 w-3px h-100 ms-3'></div>
              </div>
            </div>
            <div className="flex-grow-1 ms-2">
              <div className="row mb-3 mt-2">
                <div className="col-sm-6">
                  <div className="d-flex flex-grow-1">
                    <div className="d-flex flex-column">
                      <Link to={`/doctor/${timeSlot?.todo?.medicalProfessional?.medKey}`}>
                        <SymbolMedicalProfessional
                          picture={timeSlot?.todo?.medicalProfessional?.profilePicture}
                          picturePath='/admin/uploads/users/'
                          withText={true}
                          widthClassName='symbol-40px me-5'
                          text={Functions.fullName(timeSlot?.todo?.medicalProfessional?.firstName || '', timeSlot?.todo?.medicalProfessional?.lastName || '')}
                          shortText={Functions.firstLetterUpperCase(timeSlot?.todo?.medicalProfessional?.firstName || '').charAt(0) + Functions.firstLetterUpperCase(timeSlot?.todo?.medicalProfessional?.lastName || '').charAt(0)}
                          description={timeSlot?.todo?.medicalProfessional?.defaultSpeciality}
                          backgroundClass=''
                          textClassName='fs-3 fw-400'
                          isCircular={false}
                          descriptionColor={'text-gray-500 fs-7 fw-500'}
                          textColor={'text-dark fs-6 fw-bolder'}
                          location={null}
                          withLocation={false}
                          isValid={timeSlot?.todo?.medicalProfessional.isValid || null}
                        />
                      </Link>
                      <div className="d-flex align-items-start mt-2">
                        <span className='badge badge-warning text-dark h-20px fw-500'> Med Pro</span>
                        <span className='badge badge-danger h-20px fw-500 ms-2'> Expired</span>
                        <span className='badge badge-success h-20px fw-500 ms-2'> Paid</span>
                      </div>
                    </div>
                    {isMobile ?
                      <>
                        <div className="ms-auto mt-2">
                          <div className="d-flex align-items-start">
                            <a className={'btn btn-icon btn-light-primary btn-color-primary ms-1 btn-sm w-40px h-40px'}>
                              <KTSVG
                                path='/media/icons/duotune/maps/map002.svg'
                                className='svg-icon svg-icon-2 svg-icon-white'
                              />
                            </a>
                            <DropDownList timeSlot={timeSlot} handleShowCall={handleShowCall} handleShowSugBug={handleShowSugBug} active={false}/>
                          </div>
                        </div>
                      </> :
                      <>
                      </>
                    }
                  </div>
                </div>
                <div className="col-sm-5">
                  <div className="d-flex align-items-lg-stretch mt-2">
                    <div className="d-flex align-items-start justify-content-start mt-3">
                      <span className='badge bg-med-light-dark h-30px p-1'>
                        <div className="d-flex align-items-center justify-content-center">
                          <span className='badge badge-white w-70px h-25px'>
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr027.svg'
                              className='svg-icon svg-icon-1 svg-icon-primary'
                            />
                            <span className="text-primary fs-6 fw-500">{timeSlot.startDate ? format(new Date(timeSlot.startDate), 'HH:mm') : '--:--'}</span>
                          </span>
                          <span className='badge badge-white w-70px h-25px ms-2'>
                            <KTSVG
                              path='/media/icons/duotune/abstract/reset.svg'
                              className='svg-icon svg-icon-1 svg-icon-danger'
                            />
                            <span className="text-danger fs-6 fw-500">{timeSlot.endDate ? format(new Date(timeSlot.endDate), 'HH:mm') : '--:--'}</span>
                          </span>
                        </div>
                      </span>
                    </div>
                    <div className="ms-auto mt-1">
                      <a className="btn btn-icon btn-success btn-sm w-40px h-40px rounded-3" onClick={() => checkIn(timeSlot)}>
                        <KTSVG
                          path='/media/icons/duotune/abstract/enter.svg'
                          className='svg-icon svg-icon-1 svg-icon-danger'
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!isMobile ?
              <>
                <div className="ms-auto mt-5">
                  <div className="d-flex align-content-end justify-content-end">
                    <a className={'btn btn-icon bg-med-light-red ms-1 w-40px h-40px rounded-3'}>
                      <KTSVG
                        path='/media/icons/duotune/abstract/trash.svg'
                        className='svg-icon svg-icon-1 svg-icon-danger'
                      />
                    </a>
                    <a className={'btn btn-icon bg-med-light-primary ms-1 w-40px h-40px rounded-3'}>
                      <KTSVG
                        path='/media/icons/duotune/maps/map002.svg'
                        className='svg-icon svg-icon-1 svg-icon-primary'
                      />
                    </a>
                    <a className={'btn btn-icon bg-secondary ms-1 btn-sm w-40px h-40px rounded-3'} onClick={() => handleShowCall(timeSlot.todo.medicalProfessional.calls, timeSlot)}>
                      <KTSVG
                        path='/media/icons/duotune/communication/com007.svg'
                        className='svg-icon svg-icon-1 svg-icon-dark'
                      />
                    </a>
                    <DropDownList timeSlot={timeSlot} handleShowCall={handleShowCall} handleShowSugBug={handleShowSugBug} active={false}/>
                  </div>
                </div>
              </> :
              <>
              </>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default TodoItem
