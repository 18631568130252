import React, { useEffect, useState } from 'react'
import { addDays } from 'date-fns'
import format from 'date-fns/format'
import PaymentService from '../../../../services/Accounting/PaymentService'
import CheckAndDraftListing from './CheckAndDraftListing'
import { KTSVG } from '../../../../../_metronic/helpers'
import { useIntl } from 'react-intl'
import Select from 'react-select'
import BankAccountService from '../../../../services/Accounting/BankAccountService'

const colourStyles = {
  control: (styles: any) => ({ ...styles, backgroundColor: '#F5F8FA', border: 0, height: '45px' }),
}

const months = [
  { label: 'January', value: 1 },
  { label: 'February', value: 2 },
  { label: 'March', value: 3 },
  { label: 'April', value: 4 },
  { label: 'May', value: 5 },
  { label: 'June', value: 6 },
  { label: 'July', value: 7 },
  { label: 'August', value: 8 },
  { label: 'September', value: 9 },
  { label: 'October', value: 10 },
  { label: 'November', value: 11 },
  { label: 'December', value: 12 },
]

const CheckAndDraftFilter : React.FC = () => {
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [payments, setPayments] = useState<IPayment[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [method, setMethod] = useState<string>('')
  const [totalPages, setTotalPages] = React.useState(1)
  const [showCollapse, setShowCollapse] = useState<boolean>(true)
  const [bankAccounts, setBankAccounts] = useState<{value: string, label: string}[]>([])
  const [bankAccount, setBankAccount] = useState<string>('')
  const [isIn, setIsIn] = useState<number>(0)
  const [isLate, setIsLate] = useState<number|null>(null)

  const handleShowCollapse = () => {
    if (showCollapse) {
      setShowCollapse(false)
    } else {
      setShowCollapse(true)
    }
  }

  const [range, setRange] = useState([
    {
      startDate: new Date(new Date().getFullYear(), 0, 1),
      endDate: addDays(new Date(new Date().getFullYear(), 0, 1), 365),
      key: 'selection',
    },
  ])

  // Filter by every month in the current year
  const handleChangeMonthButtons = (event: any) => {
    const now = new Date()
    const firstDay = new Date(now.getFullYear(), event.target.value - 1, 1)
    const lastDay = new Date(now.getFullYear(), event.target.value, 0)
    setRange([{ startDate: firstDay || new Date(), endDate: lastDay || new Date(), key: 'selection' || '' }])
  }

  const onChangeDestinationAccount = (event: any) => {
    setBankAccount(event.value)
  }

  const onChangeType = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setMethod(event.target.value)
  }

  const onChangeIsIn = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsIn(parseInt(event.target.value))
  }

  const onChangePayment = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (parseInt(event.target.value) === 1) {
      setRange([{ startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1), endDate: new Date(), key: 'selection' || '' }])
      setIsLate(parseInt(event.target.value))
    } else {
      setIsLate(0)
    }
  }

  const handleIsLoading = (loading: boolean) => {
    setIsLoading(loading)
  }

  const handleCurrentPage = (page:number) => {
    setIsLoading(true)
    setCurrentPage(page)
  }

  useEffect(() => {
    PaymentService.getAllPayments(currentPage, 10, method, isIn, false, '', bankAccount, format(range[0].startDate, 'yyyy/MM/dd'), format(range[0].endDate, 'yyyy/MM/dd')).then((response) => {
      setPayments(response.data.data.list)
      setTotalPages(response.data.data.totalPages)
      setIsLoading(false)
    })
  }, [isLoading, currentPage, range, method, isIn, bankAccount])

  useEffect(() => {
    BankAccountService.selectBankAccount().then((response) => {
      setBankAccounts(response.data.data)
    })
  }, [])

  useEffect(() => {}, [payments])

  return (
    <>
      <div className='card mb-3'>
        <div className='card-body'>
          <div className='d-flex align-items-center'>
            <div className='position-relative w-md-400px me-md-2'>
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6'
              />
              <input
                type='text'
                className='form-control form-control-solid ps-10'
                name='search'
                placeholder='Search'
              />
            </div>
            <div className='d-flex align-items-center'>
              <button type='submit' className='btn btn-primary me-5'>
                Search
              </button>
              <a
                id='kt_horizontal_search_advanced_link'
                className='btn-link'
                data-bs-toggle='collapse'
                onClick={handleShowCollapse}
                aria-expanded='true'
              >
                {showCollapse ?
                  intl.formatMessage({ id: 'accounting.invoice.filter.hide' }) :
                  intl.formatMessage({ id: 'accounting.invoice.filter.show' })}
              </a>
            </div>
          </div>
          <div className={`collapse ${showCollapse ? 'show' : ''}`} id='kt_advanced_search_form'>
            <div className='separator separator-dashed pt-5 mb-6'></div>
            <div className="row g-8">
              <div className="col-xxl-6 mt-8">
                <div className="row g-8">
                  <div className="col-lg-6">
                    <label className="fs-6 form-label fw-bold text-dark">Compte bancaire</label>
                    <Select
                      options={bankAccounts}
                      styles={colourStyles}
                      name="destinationAccount"
                      onChange={onChangeDestinationAccount}
                    />
                  </div>
                  <div className="col-lg-6">
                    <label className="fs-6 form-label fw-bold text-dark">Type de paiement</label>
                    <select className="form-select form-select-solid" onChange={onChangeType}>
                      <option value=''>All</option>
                      <option value='check'>Chèque</option>
                      <option value='draft'>Trait</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-xxl-3">
                <label className="fs-6 form-label fw-bold text-dark">Compte bancaire</label>
                <div className="d-flex gap-10">
                  <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary bg-gray-100 d-flex flex-stack text-start p-6 w-125px h-45px ${isIn === 0 ? 'active' : ''}`}>
                    <div className="d-flex align-items-center">
                      <div className="form-check form-check-custom form-check-solid form-check-primary me-2">
                        <input checked={isIn === 0} className="form-check-input" type="radio" value='0' name="isIn"
                          onChange={onChangeIsIn}
                        />
                      </div>
                      <div className="fw-600">
                        Emis
                      </div>
                    </div>
                  </label>
                  <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary bg-gray-100 d-flex flex-stack text-start p-6 w-125px h-45px ${isIn === 1 ? 'active' : ''}`}>
                    <div className="d-flex align-items-center">
                      <div className="form-check form-check-custom form-check-solid form-check-primary me-2">
                        <input checked={isIn === 1} className="form-check-input" type="radio" value='1' name="isIn"
                          onChange={onChangeIsIn}
                        />
                      </div>
                      <div className="fw-600">
                        Reçus
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div className="col-xxl-3">
                <label className="fs-6 form-label fw-bold text-dark">Paiement</label>
                <div className="d-flex gap-10">
                  <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary bg-gray-100 d-flex flex-stack text-start p-6 w-125px h-45px ${isLate === 0 ? 'active' : ''}`}>
                    <div className="d-flex align-items-center">
                      <div className="form-check form-check-custom form-check-solid form-check-primary me-2">
                        <input checked={isLate === 0} className="form-check-input" type="radio" value='0' name="isLate"
                          onChange={onChangePayment}
                        />
                      </div>
                      <div className="fw-600">
                        Tous
                      </div>
                    </div>
                  </label>
                  <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary bg-gray-100 d-flex flex-stack text-start p-6 w-125px h-45px ${isLate === 1 ? 'active' : ''}`}>
                    <div className="d-flex align-items-center">
                      <div className="form-check form-check-custom form-check-solid form-check-primary me-2">
                        <input checked={isLate === 1} className="form-check-input" type="radio" value='1' name="isLate"
                          onChange={onChangePayment}
                        />
                      </div>
                      <div className="fw-600">
                       Retard
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed pt-5 mb-6'></div>
            <div className='row g-2'>
              <label className='fs-6 form-label fw-bold text-dark'>Select Month</label>
              <div className='nav-group nav-group-fluid d-flex overflow-auto'>
                {months.map((month, index) => (
                  <label key={`month_${index}`}>
                    <input type='radio' className='btn-check' name='type' value={month.value} onClick={handleChangeMonthButtons}/>
                    <span className='btn btn-sm btn-color-muted btn-active btn-active-primary fw-bold px-4'>
                      {month.label}
                    </span>
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <CheckAndDraftListing
        currentPage={currentPage}
        handleCurrentPage={handleCurrentPage}
        payments={payments}
        isLoading={isLoading}
        totalPages={totalPages}
        handleIsLoading={handleIsLoading}
      />
    </>
  )
}

export default CheckAndDraftFilter
