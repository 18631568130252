import React, { useState, useEffect } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import Functions from '../../../../../setup/utilities/Functions'
import ProfileService from '../../../../services/Hr/ProfileService'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import Symbol from '../../../../../_metronic/partials/common/Symbol'
import { MenuComponent } from '../../../../../_metronic/assets/ts/components'

type Props = {
  isCreate: boolean,
  handleShow: () => void,
  profile?: IProfile
  isLoading: boolean,
  handleIsLoading: (isLoading: boolean) => void,
  page: string,
  profileLength: number,
}

const ProfileItem: React.FC<Props> = ({
  isCreate,
  handleShow,
  profile,
  isLoading,
  handleIsLoading,
  page,
  profileLength,
}) => {
  const [borderClass, setBorderClass] = useState<string>('')
  const intl = useIntl()

  const changeStatus = (event: any) => {
    ProfileService.updateStatus(profile?.uuid, {
      'active': event.target.checked,
    }).then(() => {
      handleIsLoading(true)
    })
  }

  const deleteProfile = async (uuid:string|undefined, isActive:boolean|undefined) => {
    if (!isActive) {
      const isConfirmed = await Functions.sweetAlert(
        '<span class="mt-5">Are you sure you want to delete this profile</span>',
        'warning',
        true,
        'Yes, delete!',
        'No, cancel',
        'btn btn-danger mt-10 mb-10',
        'btn btn-active-light mt-10 mb-10',
      )
      if (isConfirmed) {
        ProfileService.deleteProfile(uuid).then(() => {
          handleIsLoading(true)
        })
      }
    } else {
      await Functions.sweetAlert(
        '<span class="mt-5">You can\'t delete an active profile</span>',
        'error',
        false,
        'Ok',
        'No, cancel',
        'btn btn-primary mt-10 mb-10',
        'btn btn-active-light mt-10 mb-10',
      )
    }
  }

  useEffect(() => {
    MenuComponent.reinitialization()
    if (isCreate) {
      setBorderClass('border-bottom-light')
    } else if (profile?.isActive) {
      setBorderClass('border-bottom-success')
    } else {
      setBorderClass('border-bottom-danger')
    }
  }, [profile])

  return (
    <div className={`card card-stretch ${borderClass}`}>

      <div className={`card-body d-flex flex-column p-0 ${isCreate ? 'align-items-center' : ''}`}>
        {!isCreate ? (
          <>
            <div className={`flex-grow-1 card-px ${page !== 'display' ? 'pb-5' : ''}`}>
              <div className='me-2 pt-5 d-flex flex-stack flex-wrap'>
                <span
                  className={`badge me-auto px-4 py-3 ${profile?.isActive ? 'badge-light-success' : 'badge-light-danger'}`}>
                  {profile?.isActive ? 'Active' : 'Déactivé'}
                </span>
                <div className="form-check form-switch form-check-custom form-check-solid">
                  <input className="form-check-input cursor-pointer" type="checkbox"
                    checked={profile?.isActive} id="flexSwitchChecked" onChange={changeStatus}/>
                </div>
              </div>
              <div className='d-flex flex-stack flex-wrap mt-1'>
                <div className='me-2'>
                  <Link className="text-dark text-hover-primary fw-bold fs-3" to={{
                    pathname: `/profile/${profile?.uuid}`,
                  }}>
                    {profile?.name}
                  </Link>
                </div>
              </div>
              <div className='me-2 pt-2 d-flex flex-stack flex-wrap'>
                <div className="symbol-group symbol-hover">
                  {profile?.users && profile?.users.slice(0, 5).map((user, index) => (
                    <Symbol
                      key={`profile_users_${index}`}
                      picture={user.profilePicture}
                      picturePath={user.profilePicture}
                      withText={false}
                      widthClassName='symbol-35px mb-5'
                      text={Functions.fullName(user.firstName, user.lastName)}
                      shortText={user.firstName.charAt(0).toUpperCase()}
                      description={user.email}
                      isCircular={true}
                      backgroundClass='bg-primary'
                      textClassName='text-inverse-primary'
                      descriptionColor={'text-muted'}
                      textColor={'text-dark'}
                    />
                  ))}
                  {profile && profile.users.length - 5 > 0 ? (
                    <Symbol
                      picture={null}
                      picturePath='/admin/uploads/users/'
                      withText={false}
                      widthClassName='symbol-35px mb-5'
                      text=''
                      shortText={'+' + (profile.users.length - 5).toString()}
                      description=''
                      isCircular={true}
                      backgroundClass='bg-primary'
                      textClassName='text-inverse-primary'
                      descriptionColor={'text-muted'}
                      textColor={'text-dark'}
                    />
                  ) : null}
                </div>
                <div className="pb-4">
                  <button
                    type='button'
                    className='btn btn-light btn-active-light-primary btn-sm'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip="top-end"
                  >
                    Options
                    <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
                  </button>
                  {/* begin::Menu */}
                  <div
                    className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                    data-kt-menu='true'
                  >
                    {/* begin::Menu item */}
                    <div className='menu-item px-3'>
                      <a className='menu-link px-3'>
                        Edit
                      </a>
                    </div>
                    {/* end::Menu item */}

                    {/* begin::Menu item */}
                    <div className='menu-item px-3'>
                      <a
                        className='menu-link px-3'
                        data-kt-users-table-filter='delete_row'
                        onClick={async () => deleteProfile(profile?.uuid, profile?.isActive)}
                      >
                        Delete
                      </a>
                    </div>
                    {/* end::Menu item */}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={`flex-grow-1 card-px pt-2 ${isLoading ? 'pb-12' : 'pb-5'} ${profileLength % 3 === 0 ? 'pb-12' : ''}`}>
              <div className='d-flex flex-stack align-items-center flex-wrap fs-3 fw-bold mt-5'>
                {intl.formatMessage({ id: 'profile.create' })}
              </div>
              <div className='text-center fs-3 fw-bold pt-5'>
                <button
                  type="button"
                  className="btn btn-lg btn-light"
                  onClick={handleShow}
                >
                  <KTSVG
                    path='/media/icons/duotune/abstract/plus.svg'
                    className='svg-icon-4 svg-icon-gray-500 me-1'
                  />
                </button>
              </div>
            </div>
          </>
        )}
      </div>
      <div className={`card-footer border-top-0 ${page === 'display' ? 'p-0' : 'p-1-25'}`}>
      </div>
    </div>
  )
}

export default ProfileItem
