import apiService from '../../../setup/axios/ApiService'

const prefixURL = 'customer-category'

const selectCustomerCategory = () => apiService.get(`${prefixURL}/select-customer-category`)

const CustomerCategoryService = {
  selectCustomerCategory,
}

export default CustomerCategoryService
