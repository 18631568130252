import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import TableHeaderSolid from '../../../../../_metronic/partials/med-table/TableHeaderSolid'
import { KTSVG } from '../../../../../_metronic/helpers'
import PaginationBar from '../../../../../_metronic/partials/pagination/PaginationBar'
import WorkflowService from '../../../../services/WorkflowService'
import format from 'date-fns/format'
import InvoiceService from '../../../../services/Accounting/InvoiceService'
import { useNavigate } from 'react-router-dom'
import PurchaseOrderDetails from './PurchaseOrderDetails'
import PurchaseOrderService from '../../../../services/Accounting/PurchaseOrderService'

type Props = {
    purchaseOrders: IPurchaseOrder[],
    totalPages: number,
    currentPage: number,
    isLoading: boolean,
    handleCurrentPage: (page: number) => void,
    setIsLoading: (isLoading: boolean) => void
}

const PurchaseOrderListing : React.FC<Props> = ({
  purchaseOrders,
  currentPage,
  handleCurrentPage,
  totalPages,
  isLoading,
  setIsLoading,
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [showDetails, setShowDetails] = useState<boolean>()
  const [workflowInstanceTasks, setWorkflowInstanceTasks] = useState<IWorkflowInstanceTask[]>()

  const headers = [
    {
      minWidth: 'w-50px',
      text: intl.formatMessage({ id: 'accounting.invoice.entity.reference' }),
    },
    {
      minWidth: 'w-100px',
      text: intl.formatMessage({ id: 'accounting.invoice.entity.customer' }),
    },
    {
      minWidth: 'w-50px',
      text: intl.formatMessage({ id: 'accounting.invoice.entity.issueDate' }),
    },
    {
      minWidth: 'w-50px',
      text: intl.formatMessage({ id: 'accounting.invoice.entity.netAmount' }),
    },
    {
      minWidth: 'w-50px',
      text: intl.formatMessage({ id: 'accounting.invoice.entity.grossAmount' }),
    },
    {
      minWidth: 'w-50px',
      text: intl.formatMessage({ id: 'accounting.invoice.entity.status' }),
    },
    {
      minWidth: 'w-50px',
      text: intl.formatMessage({ id: 'actions' }),
    },
  ]

  const handleShowDetails = (workflowInstanceTask: IWorkflowInstanceTask[]) => {
    setShowDetails(true)
    setWorkflowInstanceTasks(workflowInstanceTask)
  }

  const handleCloseDetails = () => setShowDetails(false)

  const changeStatus = (value: string, workflowInstance: IWorkflowInstance, transition: string) => {
    const data = [
      {
        field: 'status',
        type: 'string',
        old_value: workflowInstance.status,
        new_value: value,
        isArrayField: false,
        isNewEntityInstance: workflowInstance.workflowType === 'convert_process',
      },
    ]

    WorkflowService.changeWorkflowInstance(workflowInstance.uuid, transition, data).then(() => {
      setIsLoading(true)
    })
  }

  const convert = (purchaseOrder: IPurchaseOrder) => {
    const convertWorkflowInstance = purchaseOrder?.workflowInstances.find((workflowInstance) => workflowInstance.workflowType === 'convert_process')
    const data = {
      paymentDate: format(new Date(), 'yyyy/MM/dd'),
      customer: purchaseOrder.customer.uuid,
      dueDate: purchaseOrder.issueDate,
      grossAmount: purchaseOrder.grossAmount,
      netAmount: purchaseOrder.netAmount,
      documentLines: purchaseOrder.documentLines,
      convertWorkflowInstance: convertWorkflowInstance?.uuid,
      transition: 'to_invoice',
      type: 'invoice_workflow',
    }
    InvoiceService.createInvoice(data).then((response) => {
      navigate('/invoice/create', { state: response.data.data })
    })
  }

  const duplicate = (purchaseOrder: IPurchaseOrder) => {
    console.log(purchaseOrder)
    PurchaseOrderService.duplicatePurchaseOrder(purchaseOrder.uuid).then(() => {
      setIsLoading(true)
    })
  }

  return (
    <>
      <div className={`card mb-5 ${isLoading ? 'overlay overlay-block' : ''}`}>
        <div className={`card-body py-3 ${isLoading ? 'overlay-wrapper' : ''}`}>
          <div className="row pt-5">
            <div className="table-responsive">
              <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                <TableHeaderSolid headers={headers} />
                <tbody>
                  {purchaseOrders && purchaseOrders.length > 0 ? (
                    purchaseOrders.map((purchaseOrder, index) => (
                      <tr key={`purchase_order_${index}`}>
                        <td>
                          <span className="text-muted ms-2">{purchaseOrder.reference}</span>
                        </td>
                        <td>
                          <span className="text-muted">{purchaseOrder.customer ? purchaseOrder.customer.displayName : 'Pas de client'}</span>
                        </td>
                        <td>
                          <span className="text-muted">{purchaseOrder.paymentDate}</span>
                        </td>
                        <td>
                          <span className="text-muted">{purchaseOrder.netAmount}</span>
                        </td>
                        <td>
                          <span className="text-muted">{purchaseOrder.grossAmount}</span>
                        </td>
                        <td>
                          {purchaseOrder.workflowInstances && purchaseOrder.workflowInstances.map((workflowInstance, index) => (
                            <div key={`workflow_${index}`}>
                              {workflowInstance.workflowType === 'purchase_order_workflow' ?
                                <>
                                  <button
                                    type='button'
                                    className={`btn btn-light btn-sm w-125px ${workflowInstance.status === 'draft' ? 'btn-light-warning' : workflowInstance.status === 'created' ? 'btn-light-info' : workflowInstance.status === 'valid' ? 'btn-light-success' : 'btn-light-danger'}`}
                                    data-kt-menu-trigger='click'
                                    data-kt-menu-placement='bottom-end'
                                    data-kt-menu-flip="top-end"
                                  >
                                    {workflowInstance.status}
                                    <KTSVG
                                      path='/media/icons/duotune/arrows/arr072.svg'
                                      className='svg-icon-4 svg-icon-gray-500 me-1'
                                    />
                                  </button>
                                  <div
                                    className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                                    data-kt-menu='true'
                                  >
                                    {workflowInstance.status === 'created' ?
                                      <>
                                        <div className='menu-item'>
                                          <a className='menu-link' defaultValue="canceled" onClick={() => changeStatus('cancelled', workflowInstance, 'to_canceled')}>
                                                  Cancel
                                          </a>
                                        </div>
                                        <div className='menu-item'>
                                          <a className='menu-link' defaultValue="accepted" onClick={() => changeStatus('valid', workflowInstance, 'to_valid')}>
                                                  Validate
                                          </a>
                                        </div>
                                      </> :
                                      <>
                                        {workflowInstance.status === 'valid' ?
                                          <>
                                            <div className='menu-item'>
                                              <a className='menu-link' onClick={() => changeStatus('cancelled', workflowInstance, 'to_canceled')}>
                                                              Cancel
                                              </a>
                                            </div>
                                          </> :
                                          <>
                                            {workflowInstance.status === 'canceled' ?
                                              <>
                                                <div className='menu-item'>
                                                  <a className='menu-link' onClick={() => changeStatus('draft', workflowInstance, 'to_draft')}>
                                                                    Reactivate
                                                  </a>
                                                </div>
                                              </> :
                                              <>
                                                {workflowInstance.status === 'draft' ?
                                                  <>
                                                    <div className='menu-item'>
                                                      <a className='menu-link' onClick={() => changeStatus('created', workflowInstance, 'to_created')}>
                                                                          Create
                                                      </a>
                                                    </div>
                                                  </> :
                                                  <>
                                                  </>
                                                }
                                              </>
                                            }
                                          </>
                                        }
                                      </>
                                    }

                                  </div>
                                </> :
                                <></>
                              }
                            </div>
                          ))}
                        </td>
                        <td className="text-end">
                          {purchaseOrder.workflowInstances && purchaseOrder.workflowInstances.map((workflowInstance, index) => (
                            <div key={`workflow_convert_${index}`}>
                              {workflowInstance.workflowType === 'convert_process' ?
                                <>
                                  <button
                                    type='button'
                                    className='btn btn-light btn-active-light-primary btn-sm'
                                    data-kt-menu-trigger='click'
                                    data-kt-menu-placement='bottom-end'
                                    data-kt-menu-flip="top-end"
                                  >
                                        Options
                                    <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
                                  </button>
                                  <div
                                    className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                                    data-kt-menu='true'
                                  >
                                    {workflowInstance.status === 'purchase_order' ?
                                      <div className='menu-item'>
                                        <a className='menu-link' onClick={() => convert(purchaseOrder)}>
                                            Invoice
                                        </a>
                                      </div> :
                                      <></>
                                    }
                                    <div className='menu-item'>
                                      <a className='menu-link' onClick={() => handleShowDetails(workflowInstance.workflowInstanceTasks)}>
                                        Details
                                      </a>
                                    </div>
                                    <div className='menu-item'>
                                      <a className='menu-link' onClick={() => duplicate(purchaseOrder)}>
                                        Duplicate
                                      </a>
                                    </div>
                                  </div>
                                </> :
                                <></>
                              }
                            </div>
                          ))}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={7}>
                        <div className='d-flex text-center w-100 fs-4 align-content-center justify-content-center mt-5'>
                          {intl.formatMessage({ id: 'action.not.found' })}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <PurchaseOrderDetails showDetails={showDetails ? showDetails : false} handleCloseDetails={handleCloseDetails} workflowInstanceTasks={workflowInstanceTasks ? workflowInstanceTasks : []}/>

        {totalPages > 1 ? (
          <PaginationBar
            totalPages={totalPages}
            currentPage={currentPage}
            handleCurrentPage={handleCurrentPage}
          />
        ) : (<></>)}
        {isLoading &&
          <div className="overlay-layer rounded bg-dark bg-opacity-5">
            <div
              className="spinner-border text-primary"
              role="status"
            >
              <span className="visually-hidden">{intl.formatMessage({ id: 'action.loading' })}</span>
            </div>
          </div>
        }
      </div>
    </>
  )
}

export default PurchaseOrderListing
