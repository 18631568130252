import React, { useEffect, useState } from 'react'
import Functions from '../../../../../../../setup/utilities/Functions'
import ProfileService from '../../../../../../services/Hr/ProfileService'
import PaginationBar from '../../../../../../../_metronic/partials/pagination/PaginationBar'
import { useIntl } from 'react-intl'
import TableHeader from '../../../../../../../_metronic/partials/med-table/TableHeader'
import Symbol from '../../../../../../../_metronic/partials/common/Symbol'

type Props = {
    profileUuid: string,
}

const ProfileUsersTable: React.FC<Props> = ({ profileUuid }) => {
  const [users, setUsers] = useState<IUserShort[]>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = React.useState(1)
  const [total, setTotal] = React.useState<number>()
  const intl = useIntl()
  const headers = [
    {
      minWidth: 'w-25px',
      text: '',
      isCheckbox: true,
    },
    {
      minWidth: 'min-w-50px',
      text: `${intl.formatMessage({ id: 'profile.entity.users.id' })}`,
      isCheckbox: false,
    },
    {
      minWidth: 'min-w-140px',
      text: `${intl.formatMessage({ id: 'profile.entity.users.label' })}`,
      isCheckbox: false,
    },
    {
      minWidth: 'min-w-100px',
      text: `${intl.formatMessage({ id: 'profile.entity.users.registeredAt' })}`,
      isCheckbox: false,
    },
    {
      minWidth: 'min-w-80px',
      text: `${intl.formatMessage({ id: 'actions' })}`,
      isCheckbox: false,
    },
  ]

  const handleCurrentPage = (page:number) => {
    setCurrentPage(page)
  }

  const retrieveUsers = () => {
    if (profileUuid) {
      ProfileService.getProfileUsers(profileUuid, currentPage, 5).then((res) => {
        setUsers(res.data.data.list)
        setTotalPages(res.data.data.totalPages)
        setTotal(res.data.data.total)
        setIsLoading(false)
      })
    }
  }

  useEffect(() => {
    retrieveUsers()
  }, [isLoading, currentPage])

  return (
    <div className={`card ${isLoading ? 'overlay overlay-block' : ''}`}>
      <div className={`${isLoading ? 'overlay-wrapper' : ''}`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fs-3 mb-1'>{intl.formatMessage({ id: 'profile.entity.users' })} <span
              className='text-muted'>({total})</span></span>
          </h3>
          <div className='card-toolbar'>
            <input type='text' placeholder='Search...' className='form-control form-control-solid'/>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              <TableHeader headers={headers} />
              <tbody>
                {users && users.map((user, index) => (
                  <tr key={`profile_user_${index}`}>
                    <td>
                      <div
                        className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input className='form-check-input widget-13-check' type='checkbox'
                          value='1'/>
                      </div>
                    </td>
                    <td>
                      <span className='text-muted fw-bold fs-6'>
                          ID{index + 1}
                      </span>
                    </td>
                    <td>
                      <Symbol
                        picture={user.profilePicture}
                        picturePath='/admin/uploads/users/'
                        withText={true}
                        widthClassName='symbol-45px me-5'
                        text={Functions.fullName(user.firstName, user.lastName)}
                        shortText={user.firstName.charAt(0).toUpperCase() + user.lastName.charAt(0).toUpperCase()}
                        description={user.email}
                        isCircular={true}
                        backgroundClass='bg-light-danger'
                        textClassName='text-danger'
                        descriptionColor={'text-muted'}
                        textColor={'text-dark'}
                      />
                    </td>
                    <td>
                      <span className='text-muted fw-bold d-block mb-1 fs-6'>
                        {user.registeredAt}
                      </span>
                    </td>
                    <td className='text-end'>
                      <div className="pb-4">
                        <button type="button" className="btn btn-sm btn-light">
                                                    Actions
                          <span className="svg-icon svg-icon-5 m-0">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="mh-50px">
                              <path
                                d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                                fill="currentColor"></path>
                            </svg>
                          </span>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {totalPages > 1 ? (
          <PaginationBar
            totalPages={totalPages}
            currentPage={currentPage}
            handleCurrentPage={handleCurrentPage}
          />
        ) : (<></>)}
      </div>
      {isLoading &&
          <div className="overlay-layer rounded bg-dark bg-opacity-5">
            <div
              className="spinner-border text-primary"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
      }
    </div>
  )
}


export default ProfileUsersTable
