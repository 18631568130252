import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import format from 'date-fns/format'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import CustomerActivityService from '../../../../../../../services/Accounting/CustomerActivityService'
import { Modal } from 'react-bootstrap'


const customerActivitySchema = Yup.object().shape({
  title: Yup.string()
    .required('Ce champs est obligatoire'),
  description: Yup.string()
    .required('Ce champs est obligatoire'),
  startDate: Yup.string()
    .required('Ce champs est obligatoire'),
  endDate: Yup.string()
    .required('Ce champs est obligatoire'),
})

type Inputs = {
  title: string,
  description: string,
  startDate: string,
  endDate: string,
}

type Props = {
  customerId: string|undefined,
  show: boolean|undefined,
  handleClose : (isSubmit: boolean) => void,
  action: string|undefined,
  activity: ICustomerActivity|undefined|null,
  handleIsLoading: (isLoading: boolean) => void,
}

const CustomerActivityCreate : React.FC<Props> = ({ customerId, handleClose, show, action, activity, handleIsLoading }) => {
  const intl = useIntl()
  const [startDate, setStartDate] = useState<string>(format(new Date(), 'MM/dd/yyyy'))
  const [endDate, setEndDate] = useState<string>(format(new Date(), 'MM/dd/yyyy'))

  const config = {
    resolver: yupResolver(customerActivitySchema),
    defaultValues: {
      title: '',
      description: '',
      startDate: '',
      endDate: '',
    },
  }

  const { register, formState: { errors }, reset, handleSubmit, setValue } = useForm<Inputs>(config)

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (action === 'create') {
      CustomerActivityService.createCustomerActivity(data, customerId!.toString()).then(() => {
        handleIsLoading(true)
        handleClose(true)
        reset()
      })
    } else {
      CustomerActivityService.updateCustomerActivity(data, activity!.uuid, customerId!.toString()).then(() => {
        handleIsLoading(true)
        handleClose(true)
        reset()
      })
    }
  }

  const handleChangeStartDate = (event: any) => {
    setStartDate(format(event, 'MM/dd/yyyy'))
    setValue('startDate', format(event, 'yyyy/dd/MM'))
  }
  const handleChangeEndDate = (event: any) => {
    setEndDate(format(event, 'MM/dd/yyyy'))
    setValue('endDate', format(event, 'yyyy/dd/MM'))
  }

  return (
    <Modal show={show} onHide={() => handleClose(false)} dialogClassName={'mw-500px'} backdropClassName={'custom-z-index'} centered>
      <Modal.Header closeButton>
        <Modal.Title>{intl.formatMessage({ id: 'accounting.customerActivity.create.modal' })}</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)} noValidate id='create_customer_activity_form'>
        <Modal.Body>
          <div className="px-7 py-5">
            <div className="mb-5">
              <label className="form-label fw-semibold">{intl.formatMessage({ id: 'accounting.customerActivity.entity.title' })}</label>
              <input
                placeholder={intl.formatMessage({ id: 'accounting.customerActivity.entity.title' })}
                {...register('title')}
                className={'form-control form-control-solid form-control-lg col-md-10'}
                type='text'
                autoComplete='off'
              />
              {errors.title && errors.title.message && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{errors.title.message}</span>
                </div>
              )}
            </div>
            <div className="mb-5">
              <label className="form-label fw-semibold">{intl.formatMessage({ id: 'accounting.customerActivity.entity.description' })}</label>
              <textarea
                {...register('description')}
                placeholder={intl.formatMessage({ id: 'accounting.customerActivity.entity.description' })}
                className={'form-control form-control-solid form-control-lg col-md-10'}
                name='description'
                autoComplete='off'
              />
              {errors.description && errors.description.message && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{errors.description.message}</span>
                </div>
              )}
            </div>
            <div className="mb-5">
              <label className="form-label fw-semibold">{intl.formatMessage({ id: 'accounting.customerActivity.entity.startDate' })}</label>
              <DatePicker onChange={handleChangeStartDate} value={startDate} className='form-control'/>
              {errors.startDate && errors.startDate.message && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{errors.startDate.message}</span>
                </div>
              )}
            </div>
            <div className="mb-5">
              <label className="form-label fw-semibold">{intl.formatMessage({ id: 'accounting.customerActivity.entity.endDate' })}</label>
              <DatePicker onChange={handleChangeEndDate} value={endDate} className='form-control'/>
              {errors.endDate && errors.endDate.message && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{errors.endDate.message}</span>
                </div>
              )}
            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn btn-sm btn-secondary'
            onClick={() => {
              handleClose(false)
              reset()
            }}
          >
            {intl.formatMessage({ id: 'action.close' })}
          </button>
          <button
            className='btn btn-sm btn-primary'
            type="submit"
          >
            {intl.formatMessage({ id: 'action.save' })}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default CustomerActivityCreate
