import React, { useEffect, useState } from 'react'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { useIntl } from 'react-intl'
import PackageToolbar from './PackageToolbar'
import PackageListing from './components/PackageListing'
import Functions from '../../../../setup/utilities/Functions'

const PackageWrapper :React.FC = () => {
  const [pack, setPack] = useState<IPackage|undefined|null>(null)
  const [action, setAction] = useState<string>('')
  const [show, setShow] = React.useState(false)
  const intl = useIntl()

  const packageBreadCrumbs : Array<PageLink> = [
    {
      title: intl.formatMessage({ id: 'home.label' }),
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: 'packages.management.label' }),
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: false,
      isActive: false,
    },
  ]

  const handleClose = async (isSubmit: boolean) => {
    if (!isSubmit) {
      const isConfirmed = await Functions.sweetAlert(
        '<span class="mt-5">Are you sure you would like to cancel</span>',
        'warning',
        true,
        'Yes, cancel it!',
        'No, return',
        'btn btn-primary mt-10 mb-10',
        'btn btn-active-light mt-10 mb-10',
      )
      if (isConfirmed) {
        setShow(false)
      }
    } else {
      setShow(false)
    }
  }
  const handleShow = (pack: IPackage|undefined|null, action: string) => {
    setPack(pack)
    setAction(action)
    setShow(true)
  }

  useEffect(() => {}, [pack])
  return (
    <>
      <PageTitle breadcrumbs={packageBreadCrumbs} pageTitle={intl.formatMessage({ id: 'packages.list' })}>
        <PackageToolbar handleShow={handleShow}/>
      </PageTitle>
      <PackageListing show={show} handleShow={handleShow} handleClose={handleClose} action={action} packSelected={pack}/>
    </>
  )
}

export default PackageWrapper
