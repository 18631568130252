import React, { useEffect, useState } from 'react'
import JourneyManagerItem from './JourneyManagerItem'
import JourneyService from '../../../../../../../services/Crm/JourneyService'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../../setup'

type Props = {
  handleSelectedId: (uuid: string) => void,
  selectedId: string|undefined
}

const JourneyManagerListing: React.FC<Props> = ({ handleSelectedId, selectedId }) => {
  const [journeys, setJourneys] = useState<IJourney[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const user: IUser = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as IUser

  const handleIsLoading = (isLoading: boolean) => {
    setIsLoading(isLoading)
  }
  useEffect(() => {
    JourneyService.getJourneysByManager(user.employeeUuid).then((response) => {
      setJourneys(response.data.data)
      setIsLoading(false)
    })
  }, [isLoading])

  return (
    <>
      {journeys && journeys.map((journey, index) => (
        <div key={`journey_${index}`}>
          <JourneyManagerItem selectedId={selectedId} journey={journey} isLoading={isLoading} handleIsLoading={handleIsLoading} handleSelectedId={handleSelectedId}/>
        </div>
      ))}
      {isLoading &&
        <div className="overlay-layer rounded bg-dark bg-opacity-5">
          <div
            className="spinner-border text-primary"
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      }
    </>
  )
}

export default JourneyManagerListing
