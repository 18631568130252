import React from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../../../../_metronic/helpers'


const PCL : React.FC = () => {
  const intl = useIntl()
  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <KTSVG
              path='/media/icons/duotune/communication/com007.svg'
              className='svg-icon-1 svg-icon-dark'
            />
            <h2 className='fw-bolder'>Profile Check-List ({intl.formatMessage({ id: 'medical.professional.pcl' })})</h2>
          </div>
          <div className='d-flex align-items-center w-100px w-sm-100px flex-column'>
            <div className='d-flex justify-content-between w-100 mt-2 mb-2'>
              <span className='fw-bolder fs-6'>50%</span>
            </div>
            <div className='h-5px w-100 bg-light mb-3'>
              <div
                className='bg-primary rounded h-5px'
                role='progressbar'
                style={{ width: '50%' }}
              ></div>
            </div>
          </div>
        </div>
        <div className='card-body'>
          <span className="text-dark fs-2 fw-bold">5</span>
          <span className="text-dark ms-2 fs-4">rubriques manquantes</span>
          <div className="card card-rounded bg-light-success mt-5">
            <div className="d-flex align-items-start justify-content-start">
              <div className="card-body px-5 py-1">
                <span className="text-dark fs-2 fw-bold">1 - </span>
                <span className="text-dark ms-2 fs-4">Genre</span>
              </div>
              <div className="me-2 mt-2">
                <KTSVG
                  path='/media/icons/duotune/general/gen043.svg'
                  className='svg-icon-1 svg-icon-success'
                />
              </div>
            </div>
          </div>
          <div className="card card-rounded bg-light-danger mt-3">
            <div className="d-flex align-items-start justify-content-start">
              <div className="card-body px-5 py-1">
                <span className="text-dark fs-2 fw-bold">2 - </span>
                <span className="text-dark ms-2 fs-4">Diplôme et formation (Arabe)</span>
              </div>
              <div className="me-2 mt-2">
                <KTSVG
                  path='/media/icons/duotune/general/gen040.svg'
                  className='svg-icon-1 svg-icon-danger'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PCL
