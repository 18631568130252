import React, { useCallback, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../../../_metronic/helpers'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import EmployeeService from '../../../../services/Hr/EmployeeService'
import DropDownEmployees from '../../journey/components/display/common/components/DropDownEmployees'
import CallService from '../../../../services/Crm/CallService'
import TimeSlotService from '../../../../services/Crm/TimeSlotService'
import { DateTimePicker } from '../../../../../_metronic/partials/date-time-picker'

type Props = {
  show: boolean,
  handleClose: () => void,
  timeSlot: ITimeSlot|null,
  checkOut: boolean,
  setIsLoading: (isLoading: boolean) => void,
  medKey: string|null
}

type Inputs = {
  type: string,
  present: string,
  message: string,
  tags: any,
  reminderType: string,
  time: string,
}

const callSchema = Yup.object().shape({
  type: Yup.string()
    .required('Ce champs est obligatoire'),
  present: Yup.string()
    .required('Ce champs est obligatoire'),
  message: Yup.string()
    .required('Ce champs est obligatoire'),
  tags: Yup.array()
    .notRequired(),
  reminderType: Yup.string()
    .notRequired(),
  time: Yup.string()
    .notRequired(),
})

const CallCreate : React.FC<Props> = ({ show, handleClose, timeSlot, checkOut, setIsLoading, medKey }) => {
  const [employees, setEmployees] = useState<{ uuid: string, picture: string, firstName: string, lastName:string, isChecked: boolean }[]>([])
  const [checkedEmployees, setCheckedEmployees] = useState<{ uuid: string, picture: string, firstName: string, lastName:string, isChecked: boolean }[]>([])
  const [reminderShow, setReminderShow] = useState<boolean>(true)
  const [date, setDate] = useState<string>('')

  const config = {
    resolver: yupResolver(callSchema),
    defaultValues: {
      type: '',
      present: '',
      message: '',
      reminderType: '',
      time: '',
      tags: [],
    },
  }
  const { register, handleSubmit, formState: { errors }, setValue, watch, reset } = useForm<Inputs>(config)

  const checkedType = watch('type')
  const checkedPlace = watch('present')
  const checkedReminderType = watch('reminderType')
  const watchedTime = watch('time')

  const addEmployee = (employee: { uuid: string, picture: string, firstName: string, lastName:string, isChecked: boolean }) => {
    const newEmployees = [...checkedEmployees]
    newEmployees.push(employee)
    setValue('tags', newEmployees)
    setCheckedEmployees(newEmployees)
  }

  const removeEmployee = (index: number, uuid : string) => {
    const newEmployees = [...checkedEmployees]
    newEmployees.splice(index, 1)
    setValue('tags', newEmployees)
    setCheckedEmployees(newEmployees)

    const removedEmployee = checkedEmployees[index]
    const employee = employees.find((element) => element.uuid === removedEmployee.uuid)
    // @ts-ignore
    const i = employees.indexOf(employee)
    const oldEmployees = [...employees]
    oldEmployees[i]['isChecked'] = false
    setEmployees(oldEmployees)
  }

  const onChangeEmployees = (employee: { uuid: string, picture: string, firstName: string, lastName:string, isChecked: boolean }, index: number) => {
    const oldEmployees = [...employees]
    oldEmployees[index]['isChecked'] = true
    setEmployees(oldEmployees)
  }

  const onChangeType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('type', event.target.value)
  }

  const onChangeReminderType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('reminderType', event.target.value)
  }

  const onChangePlace = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('present', event.target.value)
  }

  const onChangeReminder = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setReminderShow(false)
    } else {
      setReminderShow(true)
    }
  }

  useEffect(() => {
    EmployeeService.getAllEmployees().then((response) => {
      response.data.data.map((item : any) =>
        employees.push({ uuid: item.uuid, picture: item.profilePicture, firstName: item.firstName, lastName: item.lastName, isChecked: false }),
      )
    })
  }, [])

  const onHandleChangeDate = useCallback(
    (date: any) => {
      setDate(date)
    },
    [setDate],
  )
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const reminder = {
      type: checkedType,
      date,
      time: watchedTime,
      todo: timeSlot?.todo?.uuid,
    }

    const call = {
      type: data.type,
      message: data.message,
      tags: data.tags,
      present: data.present,
      medicalProfessional: medKey,
      reminder: !reminderShow ? reminder : null,
    }

    CallService.createCall(call).then(() => {
      handleClose()
      setCheckedEmployees([])
      reset()
      setIsLoading(true)
    })

    if (checkOut) {
      TimeSlotService.checkOut(timeSlot!.uuid, null).then(() => {
        setIsLoading(true)
      })
    }
  }
  return (
    <Modal show={show} onHide={handleClose} dialogClassName={'mw-700px modal-dialog modal-dialog-centered'}>
      <Modal.Header className="py-4" closeButton>
        <Modal.Title>
          <KTSVG
            path='/media/icons/duotune/communication/com007.svg'
            className='svg-icon-1 svg-icon-dark'
          />
          <span className="text-dark fs-2 fw-bold ms-5">Ajouter call</span>
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)} noValidate id='create-call-form'>
        <Modal.Body>
          <div className="h-500px overflow-scroll">
            <div className="fv-row fv-plugins-icon-container">
              <label className="fw-semibold fs-6 mb-2">Type</label>
              <div className="mb-10">
                <div className='d-flex align-items-start'>
                  <div className="form-check form-check-custom form-check-solid ms-3 mt-2">
                    <input checked={checkedType === 'call'} className="form-check-input rounded-circle" type="checkbox" value="call" name="type" onChange={onChangeType}/>
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                    Appel
                    </label>
                  </div>
                  <div className="form-check form-check-custom form-check-solid ms-5 mt-2">
                    <input checked={checkedType === 'visit'} className="form-check-input rounded-circle" type="checkbox" value="visit" name="type" onChange={onChangeType}/>
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                    Visite
                    </label>
                  </div>
                </div>
              </div>
              {errors.type && errors.type.message && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{errors.type.message}</span>
                </div>
              )}
            </div>

            <div className="fv-row fv-plugins-icon-container">
              <label className="fw-semibold fs-6 mb-2">Médecin est</label>
              <div className="mb-5">
                <div className='d-flex align-items-start'>
                  <div className="form-check form-check-custom form-check-solid ms-3 mt-2">
                    <input checked={checkedPlace === 'in'} className="form-check-input rounded-circle" type="checkbox" value="in" name="present" onChange={onChangePlace}/>
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                    Au cabinet
                    </label>
                  </div>
                  <div className="form-check form-check-custom form-check-solid ms-3 mt-2">
                    <input checked={checkedPlace === 'out'} className="form-check-input rounded-circle" type="checkbox" value="out" name="present" onChange={onChangePlace}/>
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                    N'est pas au cabinet
                    </label>
                  </div>
                </div>
              </div>
              {errors.present && errors.present.message && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{errors.present.message}</span>
                </div>
              )}
            </div>
            <div className='separator bg-secondary mt-5 mb-5'></div>
            <div className="d-flex flex-row">
              <div className="col-3">
                <label className="fw-semibold fs-6 mt-5">Taguer un collègue</label></div>
              <div className="col-9">
                <DropDownEmployees
                  drop={'down'}
                  toggleClassName={'form-control border border-1 border-gray-200 min-height-50px'}
                  employees={employees}
                  onChangeEmployees={onChangeEmployees}
                  addEmployee={addEmployee}
                  checkedEmployees={checkedEmployees}
                  removeEmployee={removeEmployee}
                />
              </div>
            </div>
            <div className='separator bg-secondary mt-5 mb-5'></div>
            <div className="fv-row mb-5 fv-plugins-icon-container">
              <textarea
                {...register('message')}
                placeholder={'Message'}
                className={'form-control form-control-lg'}
                autoComplete='off'
              />
              {errors.message && errors.message.message && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{errors.message.message}</span>
                </div>
              )}
            </div>
            <span className="fs-6 fw-400">Suggestion</span>
            <div className="mt-5">
              <div className="form-check form-check-custom form-check-solid">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Présentation Med.tn
                </label>
              </div>
              <div className="form-check form-check-custom form-check-solid mt-2">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Présentation MedPro
                </label>
              </div>
              <div className="form-check form-check-custom form-check-solid mt-2">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Formation secrétaire
                </label>
              </div>
              <div className="form-check form-check-custom form-check-solid mt-2">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Intéressé par l'abonnement MedPro
                </label>
              </div>
              <div className="form-check form-check-custom form-check-solid mt-2">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Intéressé par le logiciel de gestion de cabinet
                </label>
              </div>
              <div className="form-check form-check-custom form-check-solid mt-2">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Intéressé par le live Facebook
                </label>
              </div>
              <div className="form-check form-check-custom form-check-solid mt-2">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Intéressé par l'émssion Radio
                </label>
              </div>
              <div className="form-check form-check-custom form-check-solid mt-2">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Non Intéressé par le live
                </label>
              </div>
              <div className="form-check form-check-custom form-check-solid mt-2">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Interessé par télémédecine
                </label>
              </div>
              <div className="form-check form-check-custom form-check-solid mt-2">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Pas intéressé
                </label>
              </div>
              <div className="form-check form-check-custom form-check-solid mt-2">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Demande de visite
                </label>
              </div>
            </div>
            <div className='separator bg-secondary mt-5 mb-5'></div>
            <div className="form-check form-check-custom form-check-solid">
              <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={onChangeReminder}/>
              <label className="form-check-label" htmlFor="flexCheckDefault">
                Planifier rappel
              </label>
            </div>

            <div hidden={reminderShow}>
              <div className='d-flex align-items-start mb-5'>
                <div className="form-check form-check-custom form-check-solid mt-2">
                  <input checked={checkedReminderType === 'call'} className="form-check-input rounded-circle" type="checkbox" value="call" name="type" onChange={onChangeReminderType}/>
                  <label className="form-check-label" htmlFor="flexCheckDefault">
                  To call
                  </label>
                </div>
                <div className="form-check form-check-custom form-check-solid mt-2 ms-3">
                  <input checked={checkedReminderType === 'visit'} className="form-check-input rounded-circle" type="checkbox" value="visit" name="type" onChange={onChangeReminderType}/>
                  <label className="form-check-label" htmlFor="flexCheckDefault">
                  To Visit
                  </label>
                </div>
                <div className="ms-auto">
                  <div className="d-flex align-items-start">
                    <DateTimePicker
                      format="YYYY/MM/DD"
                      name={'bir_sey_tarihi'}
                      id={'bir_sey_tarihi'}
                      placeholder="Select Date"
                      locale="tr"
                      specialDays={[
                        { day: 1, month: 1, memo: 'New Years' },
                        { day: 14, month: 2, memo: 'Valentine`s Day' },
                      ]}
                      ariaLabelledby={'aria - labelledby'}
                      label={'Hello'}
                      date={date}
                      onHandleChangeDate={onHandleChangeDate}
                    />
                    <div className="ms-2">
                      <input {...register('time')} name="time" className="form-control form-control-solid" type="time" value="" id="flexCheckDefault"/>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="py-2">
          <button
            type='button'
            className='btn btn-light'
            onClick={handleClose}
          >
              Annuler
          </button>
          <button
            type='submit'
            className='btn btn-primary'
          >
              Ajouter
          </button>

        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default CallCreate
