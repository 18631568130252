import React from 'react'
import { KTSVG } from '../../../../../../../_metronic/helpers'
import format from 'date-fns/format'

type Props = {
    journey: IJourney|null,
    timeSlot: ITimeSlot|null
}

const BreakFinished : React.FC<Props> = ({ journey, timeSlot }) => {
  function diff(dt2: Date, dt1: Date) {
    const diffMs = dt2.getTime() - dt1.getTime() // milliseconds between now & Christmas
    const diffHrs = Math.floor((diffMs % 86400000) / 3600000) // hours
    const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000) // minutes

    return diffHrs + ' h ' + diffMins + ' min'
  }

  return (
    <>
      <div className='card card-rounded mt-5 bg-light-warning'>
        <div className='card-body px-2 py-0'>
          <div className="d-flex">
            <div className="mt-1">
              <div className="d-flex flex-column h-100 pb-2">
                <KTSVG
                  path='/media/icons/duotune/abstract/abs009.svg'
                  className='svg-icon svg-icon-1 svg-icon-gray-300'
                />
                <div className='vr bg-gray-300 opacity-100 w-3px h-100 ms-3'></div>
              </div>
            </div>
            <div className="flex-grow-1 ms-2">
              <div className="d-flex flex-column mt-2">
                <span className="text-dark fs-5 fw-500">Pause déjeuner :
                </span>
                <div className="d-flex align-items-start mt-2 mb-3">
                  <span className='badge badge-warning fs-8 fw-bold mt-1'>
                    <i className='fa-solid fa-pause text-dark'></i>
                    <span className='text-dark fs-5 fw-500 ms-2'>
                      <span>{diff(new Date(timeSlot?.endDate || ''), new Date(timeSlot?.startDate || ''))}</span>
                    </span>
                  </span>
                  <span className="text-dark mt-2 ms-4"> - </span>
                  <div className="d-flex align-items-center justify-content-center ms-4">
                    <span className='badge badge-white w-70px'>
                      <KTSVG
                        path='/media/icons/duotune/general/gen013.svg'
                        className='svg-icon-2 svg-icon-dark'
                      />
                      <span className="text-dark ms-1 fs-6 fw-500">{timeSlot?.startDate ? format(new Date(timeSlot.startDate), 'HH:mm') : '--:--'}</span>
                    </span>
                    <span className='badge badge-white w-70px ms-2'>
                      <KTSVG
                        path='/media/icons/duotune/general/gen013.svg'
                        className='svg-icon-2 svg-icon-dark'
                      />
                      <span className="text-dark fs-6 ms-1 fw-500">{timeSlot?.endDate ? format(new Date(timeSlot.endDate), 'HH:mm') : '--:--'}</span>
                    </span>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div></>
  )
}

export default BreakFinished
