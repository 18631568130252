import React from 'react'
import { useLayout } from '../../core/LayoutProvider'
import { Toolbar1 } from './Toolbar1'
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../../setup";

const Toolbar = () => {
  const { config } = useLayout()
  const user: IUser = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as IUser

  if(user && user.roles.includes('ROLE_ADMIN')) {
    switch (config.toolbar.layout) {
      case 'toolbar1':
        return <Toolbar1 />

      default:
        return <Toolbar1 />
    }
  } else {
    return <></>
  }
}

export { Toolbar }
