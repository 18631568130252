import React from 'react'
import Functions from '../../../../../../../../setup/utilities/Functions'
import Symbol from '../../../../../../../../_metronic/partials/common/Symbol'

type Props = {
    medicalProfessional: IMedicalProfessional|null
}

const CheckIn : React.FC<Props> = ({ medicalProfessional }) => (
  <>
    <span className="card card-rounded bg-med-danger" >
      <div className="card-body px-3 py-0">

        <div className="d-flex align-items-start justify-content-start">
          <img
            src='/media/icons/duotune/abstract/bell.svg'
            className='svg-icon svg-icon-1 svg-icon-dark mt-2'
          />
          <span className="text-dark fs-2 ms-1">Attention!</span>
        </div>
        <div className="d-flex align-items-start justify-content-start">
          <span className="text-dark fs-6 fw-400 ms-4">Etes-vous sûr de vouloir supprimer le médecin de la liste Todo ?
          </span>
        </div>

        <div className="card mt-5 mb-5">
          <div className="card-body px-3 py-3">
            <div className="d-flex align-items-start justify-content-start">
              <Symbol
                picture={medicalProfessional?.profilePicture || null}
                picturePath='/admin/uploads/users/'
                withText={true}
                widthClassName='symbol-50px me-5'
                text={Functions.fullName(medicalProfessional!.firstName, medicalProfessional!.lastName)}
                shortText={Functions.firstLetterUpperCase((medicalProfessional?.firstName || '').charAt(0)) + Functions.firstLetterUpperCase((medicalProfessional?.lastName || '').charAt(0))}
                description={medicalProfessional?.defaultSpeciality || ''}
                backgroundClass=''
                textClassName='fs-3 fw-400'
                isCircular={false}
                descriptionColor={'text-gray-500 fs-7 me-13 fw-500'}
                textColor={'text-dark fs-5 fw-bolder'}
              />
            </div>
          </div>
        </div>

      </div>

    </span>
  </>
)
export default CheckIn
