import apiService from '../../../setup/axios/ApiService'

const prefixURL = 'product-feature'

const getAllProductFeatures = (currentPage:number) => apiService.get(`${prefixURL}`, { page: currentPage })

const createProductFeature = (data:any) => apiService.post(`${prefixURL}/`, data)

const updateProductFeature = (uuid: string|undefined, data: any) => apiService.put(`${prefixURL}/${uuid}/edit`, data)

const deleteProductFeature = (uuid: string|undefined) => apiService.deletes(`${prefixURL}/${uuid}/delete`)

const selectProductFeatures = () => apiService.get(`${prefixURL}/select`)

const ProductFeatureService = {
  getAllProductFeatures,
  createProductFeature,
  updateProductFeature,
  deleteProductFeature,
  selectProductFeatures,
}

export default ProductFeatureService
