import React from 'react'
import { useLayout } from '../../../../../../../../_metronic/layout/core'
import { useIntl } from 'react-intl'
import clsx from 'clsx'
import { KTSVG } from '../../../../../../../../_metronic/helpers'


const CallHistoryToolbar: React.FC = () => {
  const { classes } = useLayout()
  const intl = useIntl()

  return (
    <>
      <div
        id='kt_page_title'
        data-kt-swapper='true'
        data-kt-swapper-mode='prepend'
        data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
        className={clsx('page-title d-flex', classes.pageTitle.join(' '))}
      >
      </div>
      <div className='d-flex align-items-center py-1'>
        <button
          type='button'
          className='btn btn-sm btn-success d-flex align-items h-36px'
        >
          <KTSVG
            path='/media/icons/duotune/abstract/add-user.svg'
            className='svg-icon-4 svg-icon-gray-500 me-1'
          />
          {intl.formatMessage({ id: 'journey.create.button' })}
        </button>
      </div>
    </>
  )
}

export default CallHistoryToolbar
