import React, { useEffect, useState } from 'react'
import { Navigate, Outlet, Route, Routes, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PageLink, PageTitle } from '../../../../../../_metronic/layout/core'
import CashFlowService from '../../../../../services/Accounting/CashFlowService'
import CashFlowHeader from './CashFlowHeader'
import CashFlowNavBar from './CashFlowNavBar'
import CashFlowDetailsToolbar from './CashFlowDetailsToolbar'
import OperationCreate from './components/OperationCreate'
import CashFlowTransactions from './components/CashFlowTransactions'
import BankDeposit from './components/BankDeposit'


const CashFlowDetailsWrapper : React.FC = () => {
  const { id } = useParams()
  const [cashFlow, setCashFlow] = useState<ICashFlow>()
  const intl = useIntl()
  const cashFlowDisplayBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({ id: 'home' }),
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: 'accounting.menu' }),
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: 'accounting.cash.flow.menu' }),
      path: '/customer',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]


  useEffect(() => {
    CashFlowService.getCashFlow(id).then((response) => {
      setCashFlow(response.data.data)
    })
  }, [])
  return (
    <>
      <div className="d-flex flex-column flex-xl-row">
        <Routes>
          <Route
            element={
              <>
                <CashFlowHeader cashFlow={cashFlow} />
                <Outlet/>
              </>
            }
          >
            <Route
              path='bank-deposit'
              element={
                <>
                  <PageTitle breadcrumbs={cashFlowDisplayBreadCrumbs} pageTitle={`${cashFlow?.name}`}>
                    <CashFlowDetailsToolbar/>
                  </PageTitle>
                  <div className="flex-lg-row-fluid ms-lg-8">
                    <CashFlowNavBar cashFlow={cashFlow} />
                    <BankDeposit />
                  </div>
                </>
              }
            />
            <Route
              path='transactions'
              element={
                <>
                  <PageTitle breadcrumbs={cashFlowDisplayBreadCrumbs} pageTitle={`${cashFlow?.name}`}>
                    <CashFlowDetailsToolbar/>
                  </PageTitle>
                  <div className="flex-lg-row-fluid ms-lg-8">
                    <CashFlowNavBar cashFlow={cashFlow} />
                    <CashFlowTransactions />
                  </div>
                </>
              }
            />
            <Route
              path='operation'
              element={
                <>
                  <PageTitle breadcrumbs={cashFlowDisplayBreadCrumbs} pageTitle={`${cashFlow?.name}`}>
                    <CashFlowDetailsToolbar/>
                  </PageTitle>
                  <div className="flex-lg-row-fluid ms-lg-8">
                    <CashFlowNavBar cashFlow={cashFlow} />
                    <OperationCreate />
                  </div>
                </>
              }
            />
            <Route index element={<Navigate to='transactions' />} />
          </Route>
        </Routes>
      </div>
    </>
  )
}

export default CashFlowDetailsWrapper
