import React from 'react'
import { PageLink, PageTitle } from '../../../../../_metronic/layout/core'
import { useIntl } from 'react-intl'
import LocationCreateToolbar from './LocationCreateToolbar'

const LocationCreateBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Gestion des Localisations',
    path: '',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Location',
    path: '/location',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const LocationCreateWrapper: React.FC = () => {
  // eslint-disable-next-line no-unused-vars
  const [show, setShow] = React.useState(false)
  const handleShow = () => setShow(true)
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={LocationCreateBreadCrumbs} pageTitle={intl.formatMessage({ id: 'location.create' })}>
      </PageTitle>
      <LocationCreateToolbar handleShow={handleShow} />
    </>
  )
}

export default LocationCreateWrapper
