import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import DatePicker from 'react-datepicker'
import format from 'date-fns/format'
import BankAccountService from '../../../../../../../services/Accounting/BankAccountService'
import Select from 'react-select'
import PaymentMethodService from '../../../../../../../services/Accounting/PaymentMethodService'

const colourStyles = {
  control: (styles: any) => ({ ...styles, backgroundColor: '#F5F8FA', border: 0, height: '45px' }),
}

type Props = {
  onChangeType: (event: any, uuid: string) => void,
  watchDepositType: string,
  cashFlow : ICashFlow|undefined,
  onChangeDestinationAccount : (event : any) => void
}

const DepositType : React.FC<Props> = ({ onChangeType, watchDepositType, cashFlow, onChangeDestinationAccount }) => {
  const { register, formState: { errors }, setValue } = useFormContext()
  const [date, setDate] = useState<any>(format(new Date(), 'MM/dd/yyyy'))
  const [bankAccounts, setBankAccounts] = useState<{value: string, label: string}[]>([])
  const [paymentMethods, setPaymentMethods] = useState<{value: string, label: string}[]>([])


  const onChangeDate = (event : any) => {
    setValue('date', format(event, 'MM/dd/yyyy'))
    setDate(format(event, 'MM/dd/yyyy'))
  }

  useEffect(() => {
    BankAccountService.selectBankAccount().then((response) => {
      setBankAccounts(response.data.data)
    })
    PaymentMethodService.selectPaymentMethods(0).then((response) => {
      setPaymentMethods(response.data.data)
    })
  }, [])

  return (
    <>
      <div className="row d-flex overflow-auto">
        <div className="row">
          <div className="col-lg-3">
            <label className="required fw-500 pt-4">Type</label>
          </div>
          <div className="col-9">
            <div className="d-flex gap-1">
              {paymentMethods.map((item, index) => (
                <label key={`payment_method_${index}`} className={`btn btn-outline btn-outline-dashed btn-active-light-primary bg-gray-100 d-flex flex-stack text-start p-2 w-100px h-50px ${watchDepositType === item.label ? 'active' : ''}`}>
                  <div className="d-flex align-items-center">
                    <div className="form-check form-check-custom me-2">
                      <input checked={watchDepositType === item.label} className={`form-check-input border rounded-circle  border-1 ${watchDepositType === item.label ? 'border-primary' : 'border-gray-500'}`} type="radio" value={item.label} name="depositType"
                        onChange={(e) => onChangeType(e, item.value)}
                        disabled={item.label === 'cash' && cashFlow && parseInt(cashFlow!.cash) > 0 ? false : item.label === 'check' && cashFlow && parseInt(cashFlow!.check) > 0 ? false : !(item.label === 'draft' && cashFlow && parseInt(cashFlow!.draft)) }
                      />
                    </div>
                    <div className="fw-600 fs-6">
                      {item.label}
                    </div>
                  </div>
                </label>
              ))}
            </div>
            {errors.depositType && errors.depositType.message && (
              <div className='fv-plugins-message-container text-danger'>
                <>{errors.depositType.message}</>
              </div>
            )}
          </div>
        </div>
        <div className="row pt-3">
          <div className="col-3">
            <label className="required fw-500 pt-3">Bank</label>
          </div>
          <div className="col-9">
            <Select
              options={bankAccounts}
              styles={colourStyles}
              name="destinationAccount"
              onChange={onChangeDestinationAccount}
            />
            {errors.destinationAccount && errors.destinationAccount.message && (
              <div className='fv-plugins-message-container text-danger'>
                <>{errors.destinationAccount.message}</>
              </div>
            )}
          </div>
        </div>
        <div className="row pt-3">
          <div className="col-3">
            <label className="required fw-500 pt-3">Number</label>
          </div>
          <div className="col-9">
            <input type="text"
              className={'form-control form-control-solid'}
              placeholder="Check Number"
              {...register('number')}
            />
            {errors.number && errors.number.message && (
              <div className='fv-plugins-message-container text-danger'>
                <>{errors.number.message}</>
              </div>
            )}
          </div>
        </div>
        <div className="row pt-3">
          <div className="col-3">
            <label className="required fw-500 pt-3">Date</label>
          </div>
          <div className="col-9">
            <DatePicker name="date" onChange={onChangeDate} value={date} className='form-control form-control-solid'/>
            {errors.date && errors.date.message && (
              <div className='fv-plugins-message-container text-danger'>
                <>{errors.date.message}</>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default DepositType
