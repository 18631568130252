import React from 'react'
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers'

type Props = {
  bankAccount : IBankAccount|null|undefined
}

const BankAccountHeader : React.FC<Props> = ({ bankAccount }) => (
  <div className="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10">
    <div
      className={'card bgi-no-repeat card-xl-stretch mb-xl-8'}
      style={{
        backgroundPosition: 'right top',
        backgroundSize: '30% auto',
        backgroundImage: `url(${toAbsoluteUrl('/media/svg/shapes/abstract-4.svg')})`,
      }}
    >
      {/* begin::Body */}
      <div className='card-body'>
        <a href='src/app/modules/accounting/bank-account/components/display/BankAccountHeader#' className='card-title fw-bold text-muted text-hover-primary fs-4'>
          {bankAccount?.name}
        </a>

        <div className='fw-bold text-primary my-6'>{bankAccount?.balance}</div>

        <p
          className='text-dark-75 fw-semibold fs-5 m-0'
        >
          {bankAccount?.bank.name}
        </p>
      </div>
    </div>
  </div>
)

export default BankAccountHeader
