import apiService from '../../../setup/axios/ApiService'

const selectParentFeatures = () => apiService.get('feature/select-parents/fr')

const getFeatureChildren = (uuid: string|undefined) => apiService.get(`feature/children/${uuid}/fr`)

const selectFeaturesForProducts = () => apiService.get('feature/select')


const FeatureService = {
  selectParentFeatures,
  getFeatureChildren,
  selectFeaturesForProducts,
}

export default FeatureService
