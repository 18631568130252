import apiService from '../../../setup/axios/ApiService'

const prefixURL = 'location'

const selectLocationByName = (inputLocation: string) => apiService.get(`${prefixURL}/select/fr?name=${inputLocation}`)

const selectLocationByCountry = (countryId : string) => apiService.get(`${prefixURL}/select/country/${countryId}/fr`)

const getLocations = (currentPage:number) => apiService.get(`${prefixURL}/`, { page: currentPage })

const updateIsPublicStatus = (uuid:string|undefined, data: any) => apiService.patch(`${prefixURL}/${uuid}/public`, data)

const createLocation = (data: any) => apiService.post(`${prefixURL}/`, data)

const LocationService = {
  selectLocationByName,
  selectLocationByCountry,
  getLocations,
  updateIsPublicStatus,
  createLocation,
}

export default LocationService
