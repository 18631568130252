import React, {useEffect, useState} from 'react'
import {useForm} from "react-hook-form";
import {Modal} from "react-bootstrap";

type Props = {
    show: boolean,
    handleClose : (isSubmit: boolean) => void,
    action: string,
    selectedDiscount: {type: string, value: string} | null,
    index: number|undefined,
    handleChangeDiscount: (index: number, createdDiscount: {type: string, value: string}) => void
}

type Inputs = {
    type: string,
    value: string,
}

const AccountingDiscount: React.FC<Props> = ({
         show,
         handleClose,
         selectedDiscount,
         index,
         action,
         handleChangeDiscount,
    }) => {
    const [placeholder, setPlaceHolder] = useState<string>('%')

    const { register, setValue, getValues } = useForm<Inputs>()

    const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setValue('type', e.target.value)
        if (e.target.value === '%') {
            setPlaceHolder('Pourcentage')
        } else {
            setPlaceHolder('Valeur')
        }
    }

    const onSubmit = () => {
        handleChangeDiscount(index || 0, { type: getValues('type'), value: getValues('value') })
    }

    useEffect(() => {
        setValue('type', selectedDiscount?.type || '')
        setValue('value', selectedDiscount?.value || '')
    }, [selectedDiscount])

    return (
        <>
            <Modal show={show} onHide={() => handleClose(true)} dialogClassName={'mw-500px'} backdropClassName={'custom-z-index'} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Ajout d'une remise</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row mb-5'>
                        <div className="input-group mb-5">
                            <select className="form-select form-select-solid w-25" onChange={onChange}>
                                <option value="%">Percentage</option>
                                <option value="value">Fix</option>
                            </select>
                            <input
                                placeholder={placeholder}
                                {...register('value')}
                                className={'form-control w-75'}
                                defaultValue={'%'}
                                type='text'
                                autoComplete='off'
                            />
                        </div>
                        <div className='col-md-10'>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type='button'
                        className='btn btn-sm btn-secondary'
                        onClick={() => {
                            handleClose(true)
                        }}
                    >
                        Close
                    </button>
                    <button
                        className='btn btn-sm btn-primary'
                        onClick={() => {
                            onSubmit()
                            handleClose(true)
                        }}
                    >
                        Save
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AccountingDiscount