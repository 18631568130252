import clsx from 'clsx'
import React, { FC } from 'react'
import { useLayout } from '../../../core'
import { usePageData } from '../../../core'
import BreadcrumbsDisplay from './BreadcrumbsDisplay'
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../../../setup";
import {toAbsoluteUrl} from "../../../../helpers";

const DefaultTitle: FC = () => {
  const { pageTitle, pageDescription } = usePageData()
  const { config, classes } = useLayout()
  const user: IUser = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as IUser

  return (
    <div
      id='kt_page_title'
      className={clsx('page-title d-block pt-2', classes.pageTitle.join(' '))}
    >
      {user && user.roles.includes('ROLE_ADMIN') ? (
          <>
            {/* begin::Title */}
            {pageTitle && (
                <h1 className='d-flex align-items-center text-dark my-1 fs-3'>
                  {pageTitle}
                  {pageDescription && config.pageTitle && config.pageTitle.description && (
                      <>
                        <span className='h-20px border-gray-200 border-start ms-3 mx-2'></span>
                        <small className='text-muted fs-7 my-1 ms-1'>{pageDescription}</small>
                      </>
                  )}
                </h1>
            )}
            {/* end::Title */}
            <BreadcrumbsDisplay />
          </>
      ): (
          <>
            <img src={toAbsoluteUrl('/media/logos/icon-med.svg')} alt="logo" className="logo-default w-55px" />
          </>
      )}

    </div>
  )
}

export { DefaultTitle }
