import React from "react";
import {Pagination} from "react-bootstrap";


type Props = {
    total: number,
    limit: number,
    totalPages: number,
    currentPage: number,
    handleCurrentPage: (page:number) => void
}


const PaginationBarDetails: React.FC<Props> = ({totalPages,currentPage,handleCurrentPage, total, limit}) => {
    const [startIndex, setStartIndex] = React.useState(0)
    const [endIndex, setEndIndex] = React.useState(currentPage + 2)
    const pages = []

    for (let i = 1; i <= totalPages; i++) {
        pages.push(i)
    }

    const handleStartAndEndIndex = (page: number) => {
        if (totalPages > 2) {
            if (page === totalPages) {
                setStartIndex(totalPages - 3)
                setEndIndex(page + 1)
            } else if (page - 3 < 0) {
                setStartIndex(0)
                if (page === 1) {
                    setEndIndex(page + 2)
                } else {
                    setEndIndex(page + 1)
                }
            } else {
                setStartIndex(page - 2)
                setEndIndex(page + 1)
            }
        } else {
            setStartIndex(0)
            setEndIndex(2)
        }
    }

    const previousPage = () => {
        if (currentPage >= 1) {
            handleCurrentPage(currentPage - 1)
            handleStartAndEndIndex(currentPage)
        }
    }

    const nextPage = () => {
        if (currentPage <= totalPages) {
            handleCurrentPage(currentPage + 1)
            handleStartAndEndIndex(currentPage)
        }
    }

    const changePage = (page: any) => {
        handleCurrentPage(page)
        handleStartAndEndIndex(page)
    }
    return (
        <div className='card-footer border-top-0'>
            <div className="d-flex justify-content-between">
                <div className="d-flex align-items-start">
                    <span className="fs-7">Showing {limit} of {total}</span>
                </div>
                <div className="d-flex align-items-center justify-content-md-end">
                    <Pagination>
                        {currentPage === 1 ? <Pagination.Prev className='disabled' onClick={previousPage}/> :
                            <Pagination.Prev onClick={previousPage}/>}
                        {pages.slice(startIndex, endIndex).map((page: any) => (
                            <Pagination.Item key={page} active={page === currentPage} onClick={() => changePage(page)}>
                                {page}
                            </Pagination.Item>
                        ))}
                        {currentPage === totalPages ? <Pagination.Next className='disabled' onClick={nextPage}/> :
                            <Pagination.Next onClick={nextPage}/>}
                    </Pagination>
                </div>
            </div>
        </div>
    )
}

export default PaginationBarDetails

