import React from 'react'
import { KTSVG } from '../../../../../../../_metronic/helpers'

type Props = {
    journey: IJourney|null,
}
const FirstCheckIn : React.FC<Props> = ({ journey }) => (
  <>
    <div className={'card card-rounded bg-med-light-green mt-5'}>
      <div className='card-body px-2 py-0'>
        <div className="d-flex">
          <div className="mt-2">
            <div className="d-flex flex-column h-100 pb-2">
              <KTSVG
                path='/media/icons/duotune/abstract/abs009.svg'
                className={'svg-icon svg-icon-1 svg-icon-success'}
              />
              <div className={'vr bg-success opacity-100 w-3px h-100 ms-3'}
              ></div>
            </div>
          </div>
          <div className="mt-3 ms-3 flex-grow-1">
            <div className="d-flex align-items-start">
              <span className="fs-4 fw-500 ms-2">Check-in</span>
              <div className="d-flex align-items-center justify-content-center ms-2">
                <span className='badge bg-med-green w-70px'>
                  <KTSVG
                    path='/media/icons/duotune/general/gen013.svg'
                    className='svg-icon-2 svg-icon-dark'
                  />
                  <span className="text-dark fs-6 ms-1 fw-500">{journey?.startDate ? journey?.startDate : '--:--'}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default FirstCheckIn
