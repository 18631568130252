import React from 'react'
import {KTSVG} from '../../helpers'

type Props = {
    picture: string|null,
    picturePath: string|null,
    withText: boolean,
    widthClassName: string,
    text: string,
    shortText: string,
    description: string|null,
    backgroundClass: string,
    textClassName: string,
    textColor: string,
    descriptionColor: string,
    isCircular: boolean,
    withLocation: boolean,
    location: string|null,
    isValid: boolean|null,
}

const SymbolMedicalProfessional : React.FC<Props> = ({
     picture,
     withText,
     widthClassName,
     text,
     shortText,
     description,
     backgroundClass,
     textClassName,
     textColor,
     descriptionColor,
     isCircular,
     picturePath,
     withLocation,
     location,
     isValid,
 }) => (
    <div className='d-flex align-items-center'>
        <div className={`symbol ${isCircular ? 'symbol-circle' : ''} ${widthClassName}`}>
            {picture !== null && picture !== undefined ? (
                <img
                    src={`data:image/png;base64,${picture}`}
                    alt={text}/>
            ) : (
                <div
                    className={`symbol-label fw-bold ${backgroundClass} ${textClassName}`}>
                    {shortText}
                </div>
            )}
        </div>
        {withText ?
            (
                <div className='d-flex flex-column'>
                    <div className="d-flex align-items-start">
                        <span className={`${textColor}`}>
                            {text}
                            {isValid ?
                                <span className="ms-3">

                                    <KTSVG
                                        path='/media/icons/duotune/general/gen026.svg'
                                        className='svg-icon-1 svg-icon-primary'
                                    />
                                </span> :
                                <>
                                </>
                            }
                            <KTSVG
                                path='/media/icons/duotune/general/gen045.svg'
                                className='svg-icon svg-icon-1 svg-icon-dark ms-2'
                            />
                        </span>
                    </div>
                    <div className="d-flex align-items-start">
                        {description !== null ?
                            (
                                <span className={`mt-1 ${descriptionColor}`}>
                                    {description}
                                </span>
                            ) :
                            (<></>)
                        }
                        {withLocation ?
                            (
                                <div className={`badge bg-med-light-dark badge-sm ${description !== null ? 'ms-3' : ''}`}>
                                    <span className="text-dark fw-400 fs-7">{location}</span>
                                </div>
                            ) :
                            (
                             <>
                             </>
                            )
                        }
                    </div>
                </div>
            ) :
            (<></>)
        }
    </div>
)

export default SymbolMedicalProfessional
