import React from 'react'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../../../../../../_metronic/helpers'
import CallListing from '../../../../../call/CallListing'

type Props = {
  calls: ICall[],
  timeSlot: ITimeSlot|null,
  showCall: boolean,
  showCreateCall: boolean,
  handleCloseCall : () => void,
  handleShowCreateCall: (checkout: boolean, timeSlot: ITimeSlot|null) => void,
  handleCloseCreateCall: () => void,
  checkOut: boolean,
  setIsLoading: (isLoading: boolean) => void
}
const Calls : React.FC<Props> = ({
  showCall,
  handleCloseCall,
  handleShowCreateCall,
  calls,
}) => (
  <>
    <Modal show={showCall} onHide={handleCloseCall} dialogClassName={'mw-700px modal-dialog modal-dialog-centered'}>
      <Modal.Header closeButton className="py-3">
        <Modal.Title>
          <KTSVG
            path='/media/icons/duotune/communication/com007.svg'
            className='svg-icon-1 svg-icon-dark'
          />
          <span className="text-dark fs-2 fw-bold ms-5">Call</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CallListing calls={calls} />

      </Modal.Body>
      <div className="d-flex justify-content-center align-items-center py-1 px-5 mb-3">
        <button
          type='button'
          className='btn btn-success d-block w-100'
          onClick={() => handleShowCreateCall(false, null)}
        >
              Ajouter Call
        </button>
      </div>

    </Modal>

  </>
)

export default Calls
