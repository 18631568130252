import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Document, Page, pdfjs} from 'react-pdf'
import {Modal} from 'react-bootstrap'

type Props = {
    HandleChangeConfig: (event: any) => void,
    label: string,
    color: string,
    isReference: boolean,
    isQuantity: boolean,
    isDiscount: boolean,
    isDisplayContact: boolean,
    isDisplayTotalAmount: boolean,
    isDisplayGrossAmount: boolean,
    onSubmit: () => void,
    handleDownload: () => void,
    pdf: any,
    onApprove: any
}

const AccountingSettings: React.FC<Props> = ({
         HandleChangeConfig,
         label,
         color,
         isQuantity,
         isDiscount,
         isDisplayContact,
         isDisplayTotalAmount,
         isDisplayGrossAmount,
         isReference,
         onSubmit,
         handleDownload,
         pdf,
         onApprove
     }) => {
    const [activeTab, setActiveTab] = useState('tab1')
    const intl = useIntl()
    const [show, setShow] = useState<boolean>(false)

    const handleShow = async () => {
        handleDownload()
        setShow(true)
    }
    const handleClose = () => setShow(false)

    const handleTab1 = () => {
        setActiveTab('tab1')
    }
    const handleTab2 = () => {
        setActiveTab('tab2')
    }

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
    }, [])

    return (
        <>
            <div className="flex-lg-auto min-w-lg-300px">
                <div className="card" data-kt-sticky="true" data-kt-sticky-name="invoice"
                     data-kt-sticky-offset="{default: false, lg: '200px'}" data-kt-sticky-width="{lg: '250px', lg: '300px'}"
                     data-kt-sticky-left="auto" data-kt-sticky-top="0px" data-kt-sticky-animation="true"
                     data-kt-sticky-zindex="50">
                    <div className="card-body p-10">
                        <div className="mb-5 hover-scroll-x">
                            <div className="d-grid">
                                <ul className="nav nav-tabs flex-nowrap text-nowrap border-0">
                                    <li className='nav-item w-100 me-0 mb-md-2'>
                                        <a className={`nav-link w-100 btn btn-flex btn-active-light-success ${activeTab === 'tab1' ? 'active' : ''}`} onClick={handleTab1}>
                                            <span className="fs-4 fw-bold">Overview</span>
                                        </a>
                                    </li>
                                    <li className="nav-item w-100 me-0 mb-md-2">
                                        <a className={`nav-link w-100 btn btn-flex btn-active-light-info ${activeTab === 'tab2' ? 'active' : ''}`} onClick={handleTab2}>
                                            <span className="fs-4 fw-bold">Settings</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="tab-content" id="myTabContent">
                            {activeTab === 'tab1' ?
                                <>
                                    <div className="separator separator-dashed mb-8"></div>
                                    <div className="mb-0">
                                        <div className="row mb-5">
                                            <div className="col">
                                                <a className="btn btn-light btn-active-light-primary w-100" onClick={handleShow}>Preview</a>
                                            </div>
                                        </div>
                                    </div>
                                </> :
                                <>
                                    <div className="separator separator-dashed mb-8"></div>
                                    <div className="mb-10">
                                        <label className="form-label fw-bold fs-6 text-gray-700">Label</label>
                                        <input type="text" className="form-control form-control-solid" name="label" value={label} onChange={(e) => HandleChangeConfig(e)}/>
                                    </div>
                                    <div className="separator separator-dashed mb-8"></div>
                                    <div className="d-flex align-items-start mb-5">
                                        <label className="form-label fw-bold fs-6 text-gray-700 mt-4">Color</label>
                                        <input type="color" className="form-control form-control-color form-control-transparent w-100px" name="color" value={color} onChange={(e) => HandleChangeConfig(e)}/>
                                    </div>
                                    <div className="separator separator-dashed mb-8"></div>
                                    <div className="mb-8">
                                        <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack mb-4">
                                            <span className="form-check-label ms-0 fw-bold fs-6 text-gray-700">Référence</span>
                                            <input className="form-check-input" name="isReference" checked={isReference} type="checkbox" onChange={(e) => HandleChangeConfig(e)} />
                                        </label>
                                        <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack mb-4">
                                            <span className="form-check-label ms-0 fw-bold fs-6 text-gray-700">Quantité</span>
                                            <input className="form-check-input" name="isQuantity" checked={isQuantity} type="checkbox" onChange={(e) => HandleChangeConfig(e)}/>
                                        </label>
                                        <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack mb-4">
                                            <span className="form-check-label ms-0 fw-bold fs-6 text-gray-700">Remise</span>
                                            <input className="form-check-input" name="isDiscount" checked={isDiscount} type="checkbox" onChange={(e) => HandleChangeConfig(e)}/>
                                        </label>
                                        <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack mb-4">
                                            <span className="form-check-label ms-0 fw-bold fs-6 text-gray-700">Afficher les montants totaux</span>
                                            <input className="form-check-input" name="isDisplayTotalAmount" checked={isDisplayTotalAmount} type="checkbox" onChange={(e) => HandleChangeConfig(e)}/>
                                        </label>
                                        <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack mb-4">
                                            <span className="form-check-label ms-0 fw-bold fs-6 text-gray-700">Afficher les prix en TTC</span>
                                            <input className="form-check-input" name="isDisplayGrossAmount" checked={isDisplayGrossAmount} type="checkbox" onChange={(e) => HandleChangeConfig(e)}/>
                                        </label>
                                        <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack mb-4">
                                            <span className="form-check-label ms-0 fw-bold fs-6 text-gray-700">Afficher contact</span>
                                            <input className="form-check-input" name="isDisplayContact" checked={isDisplayContact} type="checkbox" onChange={(e) => HandleChangeConfig(e)}/>
                                        </label>
                                    </div>
                                    <div className="separator separator-dashed mb-8"></div>
                                    <div className="d-flex align-content-center">

                                        <button
                                            className='btn btn-sm btn-primary ms-5'
                                            onClick={onSubmit}
                                        >
                                            {intl.formatMessage({ id: 'action.save' })}
                                        </button>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Modal show={show} onHide={() => handleClose()} dialogClassName={'mw-1200px'}>
                    <Modal.Header closeButton className="bg-gray-200">
                    </Modal.Header>
                    <Modal.Body className="bg-gray-200">
                        <div className="d-flex flex-column flex-lg-row">
                            <Document
                                options={{
                                    cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
                                    cMapPacked: true,
                                }}
                                file={`data:application/pdf;base64,${pdf}`}
                                noData={'Please select a document to Preview'}
                            >
                                {[1].map((page, ind) => (
                                    <div
                                        key={ind}
                                        id={`viewer-page-${ind + 1}`}
                                        className="d-flex justify-content-center align-items-center"
                                    >
                                        <Page
                                            key={`page_${ind + 1}`}
                                            pageNumber={ind + 1}
                                            scale={2}
                                            rotate={0}
                                            className={'has-hover bordered-active'}
                                            width={450}
                                            renderAnnotationLayer={false}
                                        />
                                    </div>
                                ))}
                            </Document>
                            <div className="flex-lg-auto min-w-lg-250px">
                                <div className="card" data-kt-sticky="true" data-kt-sticky-name="invoice"
                                     data-kt-sticky-offset="{default: false, lg: '200px'}" data-kt-sticky-width="{lg: '250px', lg: '300px'}"
                                     data-kt-sticky-left="auto" data-kt-sticky-top="0px" data-kt-sticky-animation="true"
                                     data-kt-sticky-zindex="50">
                                    <div className="card-body p-10">
                                        <div className="mb-5 hover-scroll-x">
                                            <div className="d-grid">
                                                <button type="submit" className="btn btn-success mb-5" onClick={onApprove}>Approuver
                                                </button>
                                                <button type="submit" className="btn btn-danger" onClick={handleClose}>Annuler
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}

export default AccountingSettings