import React from 'react'
import EmployeeItem from './EmployeeItem'
import EmployeeCreate from './add/EmployeeCreate'

type Props = {
    handleShow: (employee:IEmployee|undefined|null, action: string) => void
    handleClose: () => void,
    handleSubmit: () => void,
    show: boolean,
    employees: IEmployee[],
    isLoading: boolean,
    handleIsLoading: (isLoading: boolean) => void,
    currentStep: number,
    setCurrentStep: (step: number) => void,
    employeeSelected: IEmployee|null|undefined
    action: string
}

const EmployeeListingMobile : React.FC<Props> =
    ({
      handleShow,
      handleSubmit,
      handleClose, show,
      employees,
      isLoading,
      handleIsLoading,
      currentStep,
      setCurrentStep,
      employeeSelected,
      action,
    }) => (
      <>
        <div className={'row gy-2 g-xl-8'}>
          {employees && employees.map((employee, index) => (
            <div className='col-xxl-4' key={`user_${index}`}>
              <EmployeeItem
                handleShow={handleShow}
                employee={employee}
                isLoading={isLoading}
                handleIsLoading={handleIsLoading}
                page='list'/>
            </div>
          ))}
        </div>
        <EmployeeCreate
          show={show}
          handleClose={handleClose}
          handleIsLoading={handleIsLoading}
          handleSubmit={handleSubmit}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          employeeSelected={employeeSelected}
          action={action}
        />
      </>
    )

export default EmployeeListingMobile
