import React from 'react'
import { useLayout, usePageData } from '../../../../../../_metronic/layout/core'
import { useIntl } from 'react-intl'
import clsx from 'clsx'
import { KTSVG } from '../../../../../../_metronic/helpers'

type Props = {
    handleShow: (customer:ICustomer|undefined|null, action:string) => void
}

const CustomerDetailsToolbar : React.FC<Props> = ({ handleShow }) => {
  const { classes } = useLayout()
  const { pageTitle } = usePageData()
  const intl = useIntl()

  return (
    <>
      <div
        id='kt_page_title'
        data-kt-swapper='true'
        data-kt-swapper-mode='prepend'
        data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
        className={clsx('page-title d-flex', classes.pageTitle.join(' '))}
      >
        <div className='d-flex flex-wrap'>
          {pageTitle && (
            <h1 className='d-flex align-items-center text-dark fw-bolder my-1 fs-3 me-5'>
              {pageTitle}
            </h1>
          )}
        </div>

      </div>
      <div className='d-flex align-items-center py-1'>
        <button
          type='button'
          className='btn btn-sm btn-success d-flex align-items h-36px'
          onClick={() => handleShow(null, 'create')}
        >
          <KTSVG
            path='/media/icons/duotune/abstract/add-user.svg'
            className='svg-icon-4 svg-icon-gray-500 me-1'
          />
          {intl.formatMessage({ id: 'accounting.customer.create.button' })}
        </button>
      </div>
    </>
  )
}

export default CustomerDetailsToolbar
