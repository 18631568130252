import React from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import Functions from '../../../../../../setup/utilities/Functions'
import Symbol from '../../../../../../_metronic/partials/common/Symbol'

type Props = {
  customer: ICustomer|null|undefined
}

const CustomerDetailsHeader : React.FC<Props> = ({ customer }) => {
  const intl = useIntl()

  return (
    <>
      <div className="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10">
        <div className="card mb-5 mb-xl-8">
          <div className="card-body pt-15">
            <div className="d-flex flex-center flex-column mb-5">
              <div className="symbol symbol-100px symbol-circle mb-7">
                <Symbol
                  picture={null}
                  picturePath='/admin/uploads/users/'
                  withText={true}
                  widthClassName='symbol-45px'
                  text={''}
                  shortText={Functions.firstLetterUpperCase(customer ? customer?.displayName.charAt(0) : '')}
                  description={null}
                  backgroundClass='primary'
                  textClassName='fs-1 fw-800'
                  isCircular={false}
                  descriptionColor={'text-muted'}
                  textColor={'text-dark'}
                />
              </div>
              <a href="src/app/modules/accounting/customer/components/display/CustomerDetailsHeader#" className="fs-3 text-gray-800 text-hover-primary fw-bold mb-10">
                {customer?.displayName}
              </a>
              <div className="d-flex flex-wrap flex-center">
                <div className="border border-gray-300 border-dashed rounded py-3 px-3 mb-3">
                  <div className="fs-4 fw-bold text-gray-700">
                    <span className="w-75px">6,900</span>
                    <span className="svg-icon svg-icon-3 svg-icon-success">
                      <svg width="24" height="24" viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.5" x="13" y="6" width="13"
                          height="2" rx="1"
                          transform="rotate(90 13 6)"
                          fill="currentColor"></rect>
                        <path
                          d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                          fill="currentColor"></path>
                      </svg>
                    </span>
                  </div>
                  <div className="fw-semibold text-muted">Earnings</div>
                </div>
                <div className="border border-gray-300 border-dashed rounded py-3 px-3 mx-4 mb-3">
                  <div className="fs-4 fw-bold text-gray-700">
                    <span className="w-50px">130</span>
                    <span className="svg-icon svg-icon-3 svg-icon-danger">
                      <svg width="24" height="24" viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.5" x="11" y="18" width="13"
                          height="2" rx="1"
                          transform="rotate(-90 11 18)"
                          fill="currentColor"></rect>
                        <path
                          d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z"
                          fill="currentColor"></path>
                      </svg>
                    </span>
                  </div>
                  <div className="fw-semibold text-muted">Tasks</div>
                </div>
                <div className="border border-gray-300 border-dashed rounded py-3 px-3 mb-3">
                  <div className="fs-4 fw-bold text-gray-700">
                    <span className="w-50px">500</span>
                    <span className="svg-icon svg-icon-3 svg-icon-success">
                      <svg width="24" height="24" viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.5" x="13" y="6" width="13"
                          height="2" rx="1"
                          transform="rotate(90 13 6)"
                          fill="currentColor"></rect>
                        <path
                          d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                          fill="currentColor"></path>
                      </svg>
                    </span>
                  </div>
                  <div className="fw-semibold text-muted">Hours</div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-stack fs-4 py-3">
              <div className="fw-bold">Details
              </div>
              <Link to={`/customer/${customer?.uuid}/settings`} className='btn btn-sm btn-light-primary align-self-center'>
                {intl.formatMessage({ id: 'action.edit' })}
              </Link>
            </div>
            <div className="separator separator-dashed my-3"></div>
            <div id="kt_customer_view_details" className="collapse show">
              <div className="py-5 fs-6">
                <div className="fw-bold mt-5">Customer Category</div>
                <div className="text-gray-600">
                  {customer?.customerCategory ? customer.customerCategory.name : ''}
                </div>
                <div className="fw-bold mt-5">Location</div>
                <div className="text-gray-600">
                  <a href="src/app/modules/accounting/customer/components/display/CustomerDetailsHeader#" className="text-gray-600 text-hover-primary">
                    {customer?.location ? customer.location.name : ''}
                  </a>
                </div>
                <div className="fw-bold mt-5">Country</div>
                <div className="text-gray-600">{customer?.country ? customer.country.name : ''}
                </div>
                <div className="fw-bold mt-5">Email</div>
                <div className="text-gray-600">
                  {customer?.contacts && customer?.contacts.map((contact, index) => (
                    <div key={`contact_${index}`}>
                      {contact.isDefault ? contact.email : ''}
                    </div>
                  ))}</div>
                <div className="fw-bold mt-5">Phone</div>
                <div className="text-gray-600">
                  {customer?.contacts && customer?.contacts.map((contact, index) => (
                    <div key={`contact_${index}`}>
                      {contact.isDefault ? contact.phone : ''}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CustomerDetailsHeader
