import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import useWindowDimensions from '../../../../../../_metronic/partials/mobile-check-view/useCheckMobileScreen'
import { useIntl } from 'react-intl'

type Props = {
  employeeSelected: IEmployee|null|undefined
}

const Bank : React.FC<Props> = ({ employeeSelected }) => {
  const intl = useIntl()
  const isMobile = useWindowDimensions()
  const { register, formState: { errors }, setValue } = useFormContext()

  const onChangeAccount = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.value)
  }

  useEffect(() => {
    setValue('iBan', employeeSelected?.iBan || '')
  }, [employeeSelected])

  return (
    <>
      {isMobile ?
        (
          <>
            <div className="row d-flex overflow-auto">
              <div className="text-primary fw-bold">{intl.formatMessage({ id: 'employee.step.bank.title' })}</div>
              <div className="fv-row mb-7 fv-plugins-icon-container">
                <label className="required fw-semibold fs-6 mb-2">{intl.formatMessage({ id: 'employee.entity.iban' })}</label>
                <input
                  type="text"
                  className="form-control form-control-solid"
                  placeholder={intl.formatMessage({ id: 'employee.entity.iban' })}
                  {...register('iBan')}
                />
                {errors.iBan && errors.iBan.message && (
                  <div className='fv-plugins-message-container text-danger'>
                    <>{errors.iBan.message}</>
                  </div>
                )}
              </div>
            </div>
          </>
        ) :
        (
          <>
            <div className="row d-flex overflow-auto">
              <div className="text-primary fw-bold">{intl.formatMessage({ id: 'employee.step.bank.title' })}</div>
              <div className="w-1000px ms-20 pt-5">
                <div className="row pt-10">
                  <div className="col-2">
                    <label className="required fw-500 pt-3">{intl.formatMessage({ id: 'employee.entity.iban' })}</label>
                  </div>
                  <div className="col-10">
                    <input
                      type="text"
                      className="form-control form-control-solid"
                      placeholder={intl.formatMessage({ id: 'employee.entity.iban' })}
                      {...register('iBan')}
                      onChange={onChangeAccount}
                    />
                    {errors.iBan && errors.iBan.message && (
                      <div className='fv-plugins-message-container text-danger'>
                        <>{errors.iBan.message}</>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      }
    </>
  )
}

export default Bank
