import React, { useEffect, useState } from 'react'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { useIntl } from 'react-intl'
import Functions from '../../../../setup/utilities/Functions'
import EmployeeEquipmentToolbar from './EmployeeEquipmentToolbar'
import EmployeeEquipmentListing from './components/EmployeeEquipmentListing'


const EmployeeEquipmentWrapper : React.FC = () => {
  const [employeeEquipment, setEmployeeEquipment] = useState<IEmployeeEquipment|undefined|null>(null)
  const [action, setAction] = useState<string>('')
  const [show, setShow] = React.useState(false)
  const intl = useIntl()

  const employeeEquipmentBreadCrumbs : Array<PageLink> = [
    {
      title: intl.formatMessage({ id: 'home.label' }),
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: 'employeeEquipment.management.label' }),
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: false,
      isActive: false,
    },
  ]

  useEffect(() => {}, [employeeEquipment])

  const handleClose = async (isSubmit: boolean) => {
    if (!isSubmit) {
      const isConfirmed = await Functions.sweetAlert(
        '<span class="mt-5">Are you sure you would like to cancel</span>',
        'warning',
        true,
        'Yes, cancel it!',
        'No, return',
        'btn btn-primary mt-10 mb-10',
        'btn btn-active-light mt-10 mb-10',
      )
      if (isConfirmed) {
        setShow(false)
      }
    } else {
      setShow(false)
    }
  }

  const handleShow = (employeeEquipment: IEmployeeEquipment|undefined|null, action: string) => {
    setEmployeeEquipment(employeeEquipment)
    setAction(action)
    setShow(true)
  }

  return (
    <>
      <PageTitle breadcrumbs={employeeEquipmentBreadCrumbs} pageTitle={intl.formatMessage({ id: 'employeeEquipment.list' })}>
        <EmployeeEquipmentToolbar handleShow={handleShow}/>
      </PageTitle>
      <EmployeeEquipmentListing show={show} handleShow={handleShow} handleClose={handleClose} employeeEquipmentSelected={employeeEquipment} action={action}/>
    </>
  )
}

export default EmployeeEquipmentWrapper
