import Swal, { SweetAlertIcon } from 'sweetalert2'

const firstLetterUpperCase = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)

const replaceInString = (str: string, find: string, replace: string) => str.replaceAll(find, replace)

const fullName = (firstName: string, lastName: string) => firstLetterUpperCase(firstName) + ' ' + firstLetterUpperCase(lastName)

const sweetAlert = async (
  htmlContent:string,
  iconType:SweetAlertIcon,
  withCancelButton: boolean,
  confirmButtonContent: string,
  cancelButtonContent: string,
  confirmButtonCss: string,
  cancelButtonCss: string,
) => {
  const result = await Swal.fire({
    html: htmlContent,
    icon: iconType,
    buttonsStyling: false,
    showCancelButton: withCancelButton,
    confirmButtonText: confirmButtonContent,
    cancelButtonText: cancelButtonContent,
    customClass: {
      confirmButton: confirmButtonCss,
      cancelButton: cancelButtonCss,
    },
  })

  return result.isConfirmed
}

const sweetAlertOnTop = async (title: string) => {
  const result = await Swal.fire({
    position: 'top-right',
    title,
    background: '#E8FFF3',
    showConfirmButton: false,
    timer: 1000,
  })

  return result.isConfirmed
}

const checkExist = (arr: Array<any>, key: string, value: any) => {
  let bool = false
  arr.forEach((item) => {
    if (item[key] === value) {
      bool = true
    }
  })
  return bool
}

const popItem = (arr: Array<any>, key: string, value: any) => {
  let index = -1
  arr.forEach((item, i) => {
    if (item[key] === value) {
      index = i
    }
  })
  if (index > -1) {
    arr.splice(index, 1)
  }
}

const formatDateDayMonthYear = (
  date: string,
  dayType: 'numeric'|'2-digit'|undefined,
  monthType: 'numeric'|'2-digit'|'long'|'short'|'narrow'|undefined,
  yearType: 'numeric'|'2-digit'|undefined,
  timezone: string,
) => {
  const d = new Date(date)
  return d.toLocaleDateString(timezone, {
    day: dayType,
    month: monthType,
    year: yearType,
  })
}

const formatDateDayMonth = (
  date: string,
  dayType: 'numeric'|'2-digit'|undefined,
  monthType: 'numeric'|'2-digit'|'long'|'short'|'narrow'|undefined,
  timezone: string,
) => {
  const d = new Date(date)
  return d.toLocaleDateString(timezone, {
    day: dayType,
    month: monthType,
  })
}

const Functions = {
  firstLetterUpperCase,
  replaceInString,
  fullName,
  checkExist,
  popItem,
  sweetAlert,
  formatDateDayMonthYear,
  formatDateDayMonth,
  sweetAlertOnTop,
}

export default Functions
