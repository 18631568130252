import React from 'react'
import { useIntl } from 'react-intl'
import { PageLink, PageTitle } from '../../../../../../../../_metronic/layout/core'
import CallHistoryToolbar from './CallHistoryToolbar'
import CallHistoryListing from './components/CallHistoryListing'

const CallHistoryWrapper : React.FC = () => {
  const intl = useIntl()
  const journeyBreadCrumbs : Array<PageLink> = [
    {
      title: intl.formatMessage({ id: 'home.label' }),
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: false,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={journeyBreadCrumbs} pageTitle={intl.formatMessage({ id: 'journey.list' })}>
        <CallHistoryToolbar />
      </PageTitle>
      <CallHistoryListing />
    </>
  )
}

export default CallHistoryWrapper
