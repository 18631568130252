import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useFormContext } from 'react-hook-form'

type Props = {
  watchDepositType: string,
  cashFlow : ICashFlow|undefined,
  payments : IPayment[]|undefined
}

const DepositAmount : React.FC<Props> = ({ watchDepositType, cashFlow, payments }) => {
  const intl = useIntl()
  const [amountCash, setAmountCash] = useState<string>('0')
  const [amountCheck, setAmountCheck] = useState<number>(0)
  const [amountDraft, setAmountDraft] = useState<number>(0)
  const [paymentsSelected, setPaymentsSelected] = useState<any>([])
  const { formState: { errors }, setValue } = useFormContext()

  const onChangeRangeCash = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmountCash(event.target.value)
    setValue('amount', event.target.value)
  }

  const onSelectCheck = (index : number, amount: any, event : any) => {
    if (event.target.checked) {
      const newPayments = [...paymentsSelected]
      newPayments.push({ uuid: event.target.value })
      setPaymentsSelected(newPayments)
      setValue('checks', newPayments)
      setValue('amountCheck', amountCheck + amount)
      setAmountCheck(amountCheck + amount)
    } else {
      const newPayments = [...paymentsSelected]
      newPayments.splice(index, 1)
      setValue('checks', newPayments)
      setPaymentsSelected(newPayments)
      setValue('amountCheck', amountCheck - amount)
      setAmountCheck(amountCheck - amount)
    }
  }

  const onSelectDraft = (index : number, amount: any, event : any) => {
    if (event.target.checked) {
      const newPayments = [...paymentsSelected]
      newPayments.push({ uuid: event.target.value })
      setPaymentsSelected(newPayments)
      setValue('drafts', newPayments)
      setValue('amountDraft', amountDraft + amount)
      setAmountDraft(amountDraft + amount)
    } else {
      const newPayments = [...paymentsSelected]
      newPayments.splice(index, 1)
      setValue('drafts', newPayments)
      setPaymentsSelected(newPayments)
      setValue('amountDraft', amountDraft - amount)
      setAmountDraft(amountDraft - amount)
    }
  }

  return (
    <div className="row d-flex overflow-auto w-100 ms-10">
      {watchDepositType === 'cash' ?
        <>
          <div className="row mb-10">
            <label className="required fw-500 fs-3 pt-3">
            Amount
            </label>
          </div>
          <div className="row">
            <div className="d-flex flex-column text-center">
              <div className="d-flex align-items-start justify-content-center mb-7">
                <span className="fw-bold fs-3x">{amountCash} DT</span>
              </div>
              <input type="range" min={0} max={cashFlow?.cash} value={amountCash} onChange={onChangeRangeCash}/>
            </div>
          </div>
          <div className="row pt-10">
            <input type="number" className="form-control form-control-solid" min={0} max={cashFlow?.cash} value={amountCash} onChange={onChangeRangeCash}/>
            {errors.amount && errors.amount.message && (
              <div className='fv-plugins-message-container text-danger'>
                <>{errors.amount.message}</>
              </div>
            )}
          </div>
        </> :
        <>
          {watchDepositType === 'check' ?
            <>
              <div className="row">
                <div className="d-flex flex-column text-center">
                  <div className="d-flex align-items-start justify-content-center mb-7">
                    <span className="fw-bold fs-3x">{amountCheck} DT</span>
                  </div>
                  <input type="range" min={1} max={cashFlow?.check} value={amountCheck} />
                </div>
              </div>
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                </h3>
              </div>
              <div className='card-body py-3'>
                <div className='table-responsive'>
                  <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                    <thead>
                      <tr className='fw-bold text-muted'>
                        <th className='w-25px'>
                          <div className='form-check form-check-sm form-check-custom form-check-solid'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              value='1'
                              data-kt-check='true'
                              data-kt-check-target='.widget-13-check'
                            />
                          </div>
                        </th>
                        <th className='min-w-150px'>Amount</th>
                        <th className='min-w-150px'>Drawer</th>
                        <th className='min-w-150px'>Check number</th>
                        <th className='min-w-150px'>Due date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {payments && payments.length > 0 ? (
                        payments.map((item, index) => (
                          <tr key={`check_${index}`}>
                            <td>
                              <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input className='form-check-input widget-13-check' type='checkbox' value={item.uuid} onChange={(e) => onSelectCheck(index, item.amount, e)}/>
                              </div>
                            </td>
                            <td>
                              <span className='text-dark fw-bold text-hover-primary fs-6'>
                                {item.amount}
                              </span>
                            </td>
                            <td>
                              <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                {item.drawer}
                              </span>
                            </td>
                            <td className='text-dark fw-bold text-hover-primary fs-6'>{item.checkNumber}</td>
                            <td>
                              <span className='badge badge-light-success'>{item.dueDate}</span>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={7}>
                            <div className='d-flex text-center w-100 fs-4 align-content-center justify-content-center mt-5'>
                              {intl.formatMessage({ id: 'action.not.found' })}
                            </div>
                          </td>
                        </tr>
                      ) }
                    </tbody>
                  </table>
                </div>
              </div>
            </> :
            <>
              <div className="row">
                <div className="d-flex flex-column text-center">
                  <div className="d-flex align-items-start justify-content-center mb-7">
                    <span className="fw-bold fs-3x">{amountDraft} DT</span>
                  </div>
                  <input type="range" min={1} max={cashFlow?.draft} value={amountDraft} />
                </div>
              </div>
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                </h3>
              </div>
              <div className='card-body py-3'>
                <div className='table-responsive'>
                  <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                    <thead>
                      <tr className='fw-bold text-muted'>
                        <th className='w-25px'>
                          <div className='form-check form-check-sm form-check-custom form-check-solid'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              value='1'
                              data-kt-check='true'
                              data-kt-check-target='.widget-13-check'
                            />
                          </div>
                        </th>
                        <th className='min-w-150px'>Amount</th>
                        <th className='min-w-150px'>Drawer</th>
                        <th className='min-w-150px'>Draft number</th>
                        <th className='min-w-150px'>Due date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {payments && payments.length > 0 ? (
                        payments.map((item, index) => (
                          <tr key={`draft_${index}`}>
                            <td>
                              <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input className='form-check-input widget-13-check' type='checkbox' value={item.uuid} onChange={(e) => onSelectDraft(index, item.amount, e)}/>
                              </div>
                            </td>
                            <td>
                              <span className='text-dark fw-bold text-hover-primary fs-6'>
                                {item.amount}
                              </span>
                            </td>
                            <td>
                              <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                {item.drawer}
                              </span>
                            </td>
                            <td className='text-dark fw-bold text-hover-primary fs-6'>{item.draftNumber}</td>
                            <td>
                              <span className='badge badge-light-success'>{item.dueDate}</span>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={7}>
                            <div className='d-flex text-center w-100 fs-4 align-content-center justify-content-center mt-5'>
                              {intl.formatMessage({ id: 'action.not.found' })}
                            </div>
                          </td>
                        </tr>
                      ) }
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          }
        </>
      }
    </div>
  )
}

export default DepositAmount
