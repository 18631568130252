import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'
import Functions from '../../../../../../setup/utilities/Functions'
import jsPDF from 'jspdf'
import { PageLink, PageTitle } from '../../../../../../_metronic/layout/core'
import EstimateService from '../../../../../services/Accounting/EstimateService'
import AccountingCreate from '../../../../../../_metronic/partials/med-accounting/AccountingCreate'
import AccountingSettings from '../../../../../../_metronic/partials/med-accounting/AccountingSettings'
import EstimateCreateToolbar from './EstimateCreateToolbar'

const EstimateCreateWrapper : React.FC = () => {
  const intl = useIntl()
  const location = useLocation()
  const passedEstimate = location.state
  const [estimate, setEstimate] = useState<IEstimate>()
  const [label, setLabel] = useState<string>('')
  const [color, setColor] = useState<string>('')
  const [customer, setCustomer] = useState<ICustomer>()
  const [documentsLines, setDocumentLines] = useState<IDocumentLine[]>()
  const [isReference, setIsReference] = useState<boolean>()
  const [isQuantity, setIsQuantity] = useState<boolean>()
  const [isDiscount, setIsDiscount] = useState<boolean>()
  const [isDisplayContact, setIsDisplayContact] = useState<boolean>()
  const [isDisplayGrossAmount, setIsDisplayGrossAmount] = useState<boolean>()
  const [isDisplayTotalAmount, setIsDisplayTotalAmount] = useState<boolean>()
  const [config, setConfig] = useState<{ key: string, value: any }[]>([{ key: '', value: null }])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [id, setId] = useState<string>('')
  const pdfRef = useRef(null)
  const [pdf, setPdf] = useState<any>()
  const navigate = useNavigate()

  const alertOnChange = async (title: string) => {
    await Functions.sweetAlertOnTop(title)
  }

  const handleDownload = () => {
    const content = pdfRef.current
    // eslint-disable-next-line new-cap
    const doc = new jsPDF('p', 'mm', 'a4')
    // @ts-ignore
    doc.html(content, {
      callback(doc) {
        const fileEncode = btoa(doc.output())
        setPdf(fileEncode)
      },
      html2canvas: { scale: 0.25 },
    })
  }

  const teamBreadCrumbs: Array<PageLink> = [
    {
      title: 'Home',
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: 'accounting.estimate.menu' }),
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const getConfig = (e: any) => {
    const configArray = [...config]
    configArray.map((item, index) => {
      if (item.key === e.target.name) {
        if (index !== -1) {
          configArray.splice(index, 1)
          setConfig(configArray)
        }
      } else {
        setConfig([{ key: e.target.name, value: e.target.checked || e.target.value }, ...configArray])
      }
    })
  }

  const HandleChangeConfig = (e: any) => {
    switch (e.target.name) {
    case 'isReference':
      setIsReference(!isReference)
      getConfig(e)
      break
    case 'isQuantity':
      setIsQuantity(!isQuantity)
      getConfig(e)
      break
    case 'isDiscount':
      setIsDiscount(!isDiscount)
      getConfig(e)
      break
    case 'isDisplayContact':
      setIsDisplayContact(!isDisplayContact)
      getConfig(e)
      break
    case 'isDisplayTotalAmount':
      setIsDisplayTotalAmount(!isDisplayTotalAmount)
      getConfig(e)
      break
    case 'isDisplayGrossAmount':
      setIsDisplayGrossAmount(!isDisplayGrossAmount)
      getConfig(e)
      break
    case 'label':
      setLabel(e.target.value)
      getConfig(e)
      break
    case 'color':
      setColor(e.target.value)
      getConfig(e)
      break
    }
  }

  useEffect(() => {
    setEstimate(passedEstimate as IEstimate)
    // @ts-ignore
    setId(passedEstimate.uuid)
  }, [])

  useEffect(() => {
    // @ts-ignore
    EstimateService.getEstimate(passedEstimate.uuid).then((response) => {
      setIsLoading(false)
      setEstimate(response.data.data)
      setLabel(response.data.data.config.label || '')
      setColor(response.data.data.config.color || '')
      setIsReference(response.data.data.config.isReference || false)
      setIsQuantity(response.data.data.config.isQuantity || false)
      setIsDiscount(response.data.data.config.isDiscount || false)
      setIsDisplayContact(response.data.data.config.isDisplayContact || false)
      setIsDisplayTotalAmount(response.data.data.config.isDisplayTotalAmount || false)
      setIsDisplayGrossAmount(response.data.data.config.isDisplayGrossAmount || false)
      setCustomer(response.data.data.customer)
      setDocumentLines(response.data.data.documentLines)
    })
  }, [])

  const onChangeAnything = (data: any) => {
    const workflowData = {
      type: 'estimate_workflow',
      entity: 'Estimate',
    }

    const dataToSend = { ...data, ...workflowData }
    EstimateService.updateEstimate(id, dataToSend).then(() => {
      alertOnChange('Document saved successfuly')
    })
  }

  const onSubmit = () => {
    EstimateService.patchEstimateConfig(estimate?.uuid, Object.values(config)).then(() => {
      setIsLoading(true)
      EstimateService.getEstimate(estimate?.uuid).then((response) => {
        setEstimate(response.data.data)
        setIsLoading(false)
        alertOnChange('Changes Saved')
      })
    })
  }

  const onApprove = () => {
    const data = {
      transition: 'to_created',
      data: {},
      type: 'convert_process',
      entity: 'Estimate',
    }
    const workflowInstance = estimate?.workflowInstances.find((workflowInstance) => workflowInstance.workflowType === 'estimate_workflow')
    EstimateService.setReferenceForEstimate(id, workflowInstance!.uuid, data).then(() => {
      navigate('/estimate')
    })
  }

  return (
    <>
      <PageTitle breadcrumbs={teamBreadCrumbs} pageTitle={intl.formatMessage({ id: 'accounting.estimate.create.modal' })}>
        <EstimateCreateToolbar />
      </PageTitle>
      <div className="d-flex flex-column flex-lg-row">
        <AccountingCreate
          isQuantity={isQuantity ? isQuantity : false}
          isDiscount={isDiscount ? isDiscount : false}
          isDisplayContact={isDisplayContact ? isDisplayContact : false}
          isDisplayTotalAmount={isDisplayTotalAmount ? isDisplayTotalAmount : false}
          isDisplayGrossAmount={isDisplayGrossAmount ? isDisplayGrossAmount : false}
          setIsDisplayContact={setIsDisplayContact}
          color={color}
          label={label}
          isLoading={isLoading}
          alertOnChange={alertOnChange}
          pdfRef = {pdfRef}
          onChangeAnything={onChangeAnything}
          passedCustomer={customer ? customer : null}
          documentLines={documentsLines ? documentsLines : []}
        />

        <AccountingSettings
          HandleChangeConfig={HandleChangeConfig}
          label={label}
          color={color}
          isReference={isReference ? isReference : false}
          isQuantity={isQuantity ? isQuantity : false}
          isDiscount={isDiscount ? isDiscount : false}
          isDisplayContact={isDisplayContact ? isDisplayContact : false}
          isDisplayTotalAmount={isDisplayTotalAmount ? isDisplayTotalAmount : false}
          isDisplayGrossAmount={isDisplayGrossAmount ? isDisplayGrossAmount : false}
          onSubmit={onSubmit}
          handleDownload={handleDownload}
          pdf={pdf}
          onApprove={onApprove}/>
      </div>
    </>
  )
}

export default EstimateCreateWrapper
