import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { useFormContext } from 'react-hook-form'
import CountryService from '../../../../../services/CountryService'
import LocationService from '../../../../../services/Crm/LocationService'
import useWindowDimensions from '../../../../../../_metronic/partials/mobile-check-view/useCheckMobileScreen'
import { useIntl } from 'react-intl'
import Select from 'react-select'


type Props = {
  employeeSelected: IEmployee|null|undefined
}

const Contact : React.FC<Props> = ({ employeeSelected }) => {
  const colourStyles = {
    control: (styles: any) => ({ ...styles, backgroundColor: '#F5F8FA', border: 0, height: '45px' }),
  }
  const intl = useIntl()
  const [hidden, setHidden] = useState<boolean>(true)
  const [countries, setCountries] = useState<{value: string, label: string}[]>([])
  const [locations, setLocations] = useState([])
  const isMobile = useWindowDimensions()
  const [selectedCountry, setSelectedCountry] = useState<{value: string|undefined, label: string|undefined}>({ value: employeeSelected?.country.uuid, label: employeeSelected?.country.name })
  const [selectedLocation, setSelectedLocation] = useState<{value: string|undefined, label: string|undefined}>({ value: employeeSelected?.location.uuid, label: employeeSelected?.location.name })

  const { register, formState: { errors }, setValue } = useFormContext()

  const onAddPhone = () => {
    setHidden(false)
  }

  const onDeletePhone = () => {
    setHidden(true)
  }

  const handleCountry = (event: any) => {
    setValue('country', event.value)
    LocationService.selectLocationByCountry(event.value).then((response) => {
      setLocations(response.data.data)
    })
  }

  const handleLocation = (event: any) => {
    setValue('location', event.value)
  }

  useEffect(() => {
    setSelectedCountry({ value: employeeSelected?.country.uuid, label: employeeSelected?.country.name })
    setSelectedLocation({ value: employeeSelected?.location.uuid, label: employeeSelected?.location.name })
    setValue('country', employeeSelected?.country.uuid || '')
    setValue('location', employeeSelected?.location.uuid || '')
    setValue('address', employeeSelected?.address || '')
    setValue('email', employeeSelected?.email || '')
    setValue('username', employeeSelected?.username || '')
    setValue('phone', employeeSelected?.phone || '')
  }, [employeeSelected])


  useEffect(() => {
    CountryService.selectCountry().then((response) => {
      setCountries(response.data.data)
    })
  }, [])

  return (
    <>
      {isMobile ?
        (
          <>
            <div className="row d-flex overflow-auto">
              <div className="text-primary fw-bold">{intl.formatMessage({ id: 'employee.step.contact.title' })}</div>
              <div className="fv-row mb-7 fv-plugins-icon-container pt-5">
                <label className="required fw-semibold fs-6 mb-2">{intl.formatMessage({ id: 'employee.entity.country' })}</label>
                <Select
                  options={countries}
                  styles={colourStyles}
                  onChange={handleCountry}
                />
                {errors.country && errors.country.message && (
                  <div className='fv-plugins-message-container text-danger'>
                    <>{errors.country.message}</>
                  </div>
                )}
              </div>
              <div className="fv-row mb-7 fv-plugins-icon-container">
                <label className="required fw-semibold fs-6 mb-2">{intl.formatMessage({ id: 'employee.entity.location' })}</label>
                <select
                  {...register('location')}
                  className={'form-select form-select-lg form-select-solid'}
                  name='location'
                >
                  <option value={undefined}>{intl.formatMessage({ id: 'employee.entity.location' })}</option>
                  {locations.map((option: {value: string, label: string}, index) => (
                    <option key={`location_${index}`} value={option.value}>{option.label}</option>
                  ))}
                </select>
                {errors.location && errors.location.message && (
                  <div className='fv-plugins-message-container text-danger'>
                    <>{errors.location.message}</>
                  </div>
                )}
              </div>
              <div className="fv-row mb-7 fv-plugins-icon-container">
                <label className="required fw-semibold fs-6 mb-2">{intl.formatMessage({ id: 'employee.entity.address' })}</label>
                <input type="text"
                  className="form-control form-control-solid"
                  placeholder="Adresse"
                  {...register('address')}
                />
                {errors.address && errors.address.message && (
                  <div className='fv-plugins-message-container text-danger'>
                    <>{errors.address.message}</>
                  </div>
                )}
              </div>
              <div className="fv-row mb-7 fv-plugins-icon-container">
                <label className="required fw-semibold fs-6 mb-2">{intl.formatMessage({ id: 'employee.entity.email' })}</label>
                <input
                  type="email"
                  className="form-control form-control-solid"
                  placeholder="Email"
                  {...register('email')}
                />
                {errors.email && errors.email.message && (
                  <div className='fv-plugins-message-container text-danger'>
                    <>{errors.email.message}</>
                  </div>
                )}
              </div>
              <div className="fv-row mb-7 fv-plugins-icon-container">
                <label className="required fw-semibold fs-6 mb-2">{intl.formatMessage({ id: 'employee.entity.username' })}</label>
                <input
                  type="text"
                  className="form-control form-control-solid"
                  placeholder="Username"
                  {...register('username')}
                />
                {errors.username && errors.username.message && (
                  <div className='fv-plugins-message-container text-danger'>
                    <>{errors.username.message}</>
                  </div>
                )}
              </div>
              <div className="separator separator-dashed mb-6"></div>
              <div className="fv-row mb-7 fv-plugins-icon-container">
                <label className="required fw-semibold fs-6 mb-2">{intl.formatMessage({ id: 'employee.entity.phone' })}</label>
                <div className="row">
                  <div className="col-4">
                    <select className="form-select form-select-solid">
                      <option>Personel</option>
                      <option>Professionnel</option>
                    </select>
                  </div>
                  <div className="col-6 d-flex">
                    <select className="form-select bg-secondary w-25">
                      <option>TN</option>
                      <option>DZ</option>
                    </select>
                    <input
                      type="text"
                      className="form-control form-control-solid"
                      placeholder={intl.formatMessage({ id: 'employee.entity.phone' })}
                      {...register('phone')}
                    />
                  </div>
                  <div className="col-1">
                    <button onClick={onDeletePhone} className="btn btn-icon badge-light-danger btn-sm me-1 w-40px h-40px">
                      <KTSVG
                        path='/media/icons/duotune/abstract/trash-danger.svg'
                        className='svg-icon svg-icon-2'
                      />
                    </button>
                  </div>
                  {errors.phone && errors.phone.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <>{errors.phone.message}</>
                    </div>
                  )}
                </div>
              </div>
              <div className="fv-row mb-7 fv-plugins-icon-container">
                <div className="row" hidden={hidden}>
                  <div className="col-4">
                    <select className="form-select form-select-solid">
                      <option>Personel</option>
                      <option>Professionnel</option>
                    </select>
                  </div>
                  <div className="col-6 d-flex">
                    <select className="form-select bg-secondary w-25">
                      <option>TN</option>
                      <option>DZ</option>
                    </select>
                    <input
                      type="text"
                      className="form-control form-control-solid"
                      placeholder={intl.formatMessage({ id: 'employee.entity.phone' })}
                    />
                  </div>
                  <div className="col-1">
                    <button onClick={onDeletePhone} className="btn btn-icon badge-light-danger btn-sm me-1 w-40px h-40px" hidden={hidden}>
                      <KTSVG
                        path='/media/icons/duotune/abstract/trash-danger.svg'
                        className='svg-icon svg-icon-2'
                      />
                    </button>
                  </div>
                  {errors.phone && errors.phone.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <>{errors.phone.message}</>
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div hidden={!hidden}>
                  <button
                    className='btn btn-lg btn-light-primary py-4 pe-8 me-3 fs-7 d-flex'
                    onClick={onAddPhone}
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr087.svg'
                      className='svg-icon svg-icon-2'
                    />
                    {intl.formatMessage({ id: 'employee.entity.phone.add' })}
                  </button>
                </div>
              </div>
            </div>
          </>
        ) :
        (
          <div className="row d-flex overflow-auto">
            <div className="text-primary fw-bold">{intl.formatMessage({ id: 'employee.step.contact.title' })}</div>
            <div className="w-1000px ms-20 pt-5">
              <div className="row pt-7">
                <div className="col-2">
                  <label className="required fw-500 pt-3">{intl.formatMessage({ id: 'employee.entity.country' })}</label>
                </div>
                <div className="col-4">
                  <Select
                    options={countries}
                    styles={colourStyles}
                    defaultValue={selectedCountry}
                    onChange={handleCountry}
                  />
                  {errors.country && errors.country.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <>{errors.country.message}</>
                    </div>
                  )}
                </div>
                <div className="col-2">
                  <label className="required fw-500 pt-3">{intl.formatMessage({ id: 'employee.entity.location' })}</label>
                </div>
                <div className="col-4">
                  <Select
                    options={locations}
                    styles={colourStyles}
                    defaultValue={selectedLocation}
                    onChange={handleLocation}
                  />
                  {errors.location && errors.location.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <>{errors.location.message}</>
                    </div>
                  )}
                </div>
              </div>
              <div className="row pt-7">
                <div className="col-2">
                  <label className="required fw-500 pt-3">{intl.formatMessage({ id: 'employee.entity.address' })}</label>
                </div>
                <div className="col-10">
                  <input type="text"
                    className="form-control form-control-solid"
                    placeholder={intl.formatMessage({ id: 'employee.entity.address' })}
                    {...register('address')}
                  />
                  {errors.address && errors.address.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <>{errors.address.message}</>
                    </div>
                  )}
                </div>
              </div>
              <div className="row pt-7">
                <div className="col-2">
                  <label className="required fw-500 pt-3">{intl.formatMessage({ id: 'employee.entity.email' })}</label>
                </div>
                <div className="col-10">
                  <input
                    type="text"
                    className="form-control form-control-solid"
                    placeholder={intl.formatMessage({ id: 'employee.entity.email' })}
                    {...register('email')}
                  />
                  {errors.email && errors.email.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <>{errors.email.message}</>
                    </div>
                  )}
                </div>
              </div>
              <div className="row pt-7">
                <div className="col-2">
                  <label className="required fw-500 pt-3">{intl.formatMessage({ id: 'employee.entity.username' })}</label>
                </div>
                <div className="col-10">
                  <input
                    type="text"
                    className="form-control form-control-solid"
                    placeholder={intl.formatMessage({ id: 'employee.entity.username' })}
                    {...register('username')}
                  />
                  {errors.username && errors.username.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <>{errors.username.message}</>
                    </div>
                  )}
                </div>
              </div>
              <div className="separator separator-dashed pt-7 mb-6"></div>
              <div className="row">
                <div className="col-2">
                  <label className="required fw-500 pt-3">{intl.formatMessage({ id: 'employee.entity.phone' })}</label>
                </div>
                <div className="col-2">
                  <select className="form-select form-select-solid">
                    <option>Personel</option>
                    <option>Professionnel</option>
                  </select>
                </div>
                <div className="col-7">
                  <div className="d-flex">
                    <select className="form-select bg-secondary w-25">
                      <option>TN</option>
                      <option>DZ</option>
                    </select>
                    <input
                      type="text"
                      className="form-control form-control-solid w-75"
                      placeholder={intl.formatMessage({ id: 'employee.entity.phone' })}
                      {...register('phone')}
                    />
                  </div>
                  {errors.phone && errors.phone.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <>{errors.phone.message}</>
                    </div>
                  )}
                </div>
                <div className="col-1">
                  <button onClick={onDeletePhone} className="btn btn-icon badge-light-danger btn-sm me-1 w-40px h-40px">
                    <KTSVG
                      path='/media/icons/duotune/abstract/trash-danger.svg'
                      className='svg-icon svg-icon-2'
                    />
                  </button>
                </div>
              </div>
              <div className="row pt-5" hidden={hidden}>
                <div className="col-2">
                </div>
                <div className="col-2">
                  <select className="form-select form-select-solid">
                    <option>Personel</option>
                    <option>Professionnel</option>
                  </select>
                </div>
                <div className="col-7">
                  <div className="d-flex">
                    <select className="form-select bg-secondary w-25">
                      <option>TN</option>
                      <option>DZ</option>
                    </select>
                    <input
                      type="text"
                      className="form-control form-control-solid w-75"
                      placeholder={intl.formatMessage({ id: 'employee.entity.phone' })}
                    />
                  </div>
                </div>
                <div className="col-1">
                  <button onClick={onDeletePhone} className="btn btn-icon badge-light-danger btn-sm me-1 w-40px h-40px">
                    <KTSVG
                      path='/media/icons/duotune/abstract/trash-danger.svg'
                      className='svg-icon svg-icon-2'
                    />
                  </button>
                </div>
              </div>
              <div className="row pt-5">
                <div className="col-2">
                </div>
                <div className="col-4" hidden={!hidden}>
                  <button
                    type='button'
                    className='btn btn-lg btn-light-primary py-4 pe-8 me-3 fs-7 d-flex'
                    onClick={onAddPhone}
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr087.svg'
                      className='svg-icon svg-icon-2'
                    />
                    {intl.formatMessage({ id: 'employee.entity.phone.add' })}
                  </button>
                </div>
                <div className="col-8">
                </div>
              </div>
            </div>
          </div>
        )
      }
    </>
  )
}

export default Contact
