import React from 'react'
import Symbol from '../common/Symbol'
import useWindowDimensions from '../mobile-check-view/useCheckMobileScreen'

type Props = {
    steps: { order: string, class: string, title: string, description: string } [],
    currentStep: number,
}
const FormStepperHeader : React.FC<Props> = ({steps,currentStep}) => {
    const isMobile = useWindowDimensions()
    return(
        <>
            {isMobile ?
                (
                    <>
                        <div className='stepper-nav d-flex overflow-auto'>
                            {steps && steps.map((step, index) => (
                                <div key={index} className={`${step.class}`} data-kt-stepper-element='nav'>
                                    <div className='stepper-wrapper d-flex align-items-center ms-6'>
                                        <Symbol
                                            picture={null}
                                            picturePath={null}
                                            text={parseInt(step.order) >= currentStep ? step.title : ''}
                                            shortText={parseInt(step.order) >= currentStep ? step.order : '✔'}
                                            description={step.description}
                                            backgroundClass={`${parseInt(step.order) === currentStep ? 'bg-primary w-25px h-25px' : 'bg-gray-200 w-25px h-25px'}`}
                                            textClassName={`${parseInt(step.order) === currentStep ? 'fs-3 fw-900 text-inverse-primary' : 'fs-3 fw-900 text-primary'}`}
                                            isCircular={false}
                                            widthClassName={'me-3'}
                                            withText
                                            descriptionColor={'text-muted'}
                                            textColor={'text-dark'}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                ):
                (
                    <>
                        <div className='stepper-nav d-flex flex-fill h-80px'>
                            {steps && steps.map((step, index) => (
                                <div key={index} className={`${step.class} ${parseInt(step.order) === currentStep ? 'bg-white' : 'bg-gray-300'}`} data-kt-stepper-element='nav'>
                                    <div className='stepper-wrapper d-flex align-items-center ms-6'>
                                        <Symbol
                                            picture={null}
                                            picturePath={null}
                                            text={step.title}
                                            shortText={parseInt(step.order) >= currentStep ? step.order : '✔'}
                                            description={step.description}
                                            backgroundClass={`${parseInt(step.order) === currentStep ? 'bg-primary w-35px h-35px' : 'bg-gray-200 w-35px h-35px'}`}
                                            textClassName={`${parseInt(step.order) === currentStep ? 'fs-3 fw-900 text-inverse-primary' : 'fs-3 fw-900 text-primary'}`}
                                            isCircular={false}
                                            widthClassName={'me-3'}
                                            withText
                                            descriptionColor={'text-muted'}
                                            textColor={'text-dark'}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )
            }
        </>
    )
}

export default FormStepperHeader