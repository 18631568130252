import React, { useState } from 'react'
import ProfileItem from '../../ProfileItem'
import { Accordion } from 'react-bootstrap'
import ProfileUsersTable from './ProfileUsersTable'
import ProfilePermission from '../../add/ProfilePermission'
import { useIntl } from 'react-intl'

type ICheckedFeature = {
    uuid: string,
    name: string,
    slug: string,
    parentFeatureUuid: string,
    isParent: boolean,
}

type Props = {
    profile: IProfile|undefined
    isLoading: boolean
    handleIsLoading: (value: boolean) => void
    features: IFeature[]
    handleShow: () => void
    checkedFeatures: ICheckedFeature[]
}

const ProfileDisplay: React.FC<Props> = ({
  profile,
  isLoading,
  handleIsLoading,
  features,
  handleShow,
  checkedFeatures,
}) => {
  const [showPermissions, setShowPermissions] = useState(false)
  const [selectedParentFeature, setSelectedParentFeature] = useState<string>('')
  const intl = useIntl()
  const handleShowPermissions = (parentUuid: string) => {
    setSelectedParentFeature(parentUuid)
    setShowPermissions(true)
  }
  const handleClosePermissions = () => {
    setShowPermissions(false)
  }

  return (
    <>
      {profile ? (
        <>
          <div className='row'>
            <div className='col-xl-4'>
              <ProfileItem
                isCreate={false}
                handleShow={() => {}}
                profile={profile}
                isLoading={isLoading}
                handleIsLoading={handleIsLoading}
                page='display'
                profileLength={0}
              />
              <div className='card card-stretch mt-5'>
                <div className='card-header'>
                  <h3 className='card-title fs-2 fw-500'>
                    {intl.formatMessage({ id: 'feature.plural.label' })}
                  </h3>
                  <div className="card-toolbar">
                    <button
                      type='button'
                      className='btn btn-icon btn-light w-32px h-32px'
                      onClick={handleShow}
                    >
                      <i className='bi bi-pencil-square fs-3 text-primary'></i>
                    </button>
                  </div>
                </div>
                <div className='card-body'>
                  {features && features.map((feature, index) => (
                    <div key={`profile_single_feature_${index}`}>
                      <Accordion defaultActiveKey="2">
                        <Accordion.Item eventKey="0" className='border-0'>
                          <div className='d-flex'>
                            <Accordion.Header className='mb-2 accordion-profile pt-3'>
                              <span
                                className="form-check-label med-dark fw-500 fs-5">{feature.name}</span>
                            </Accordion.Header>
                            <div className='ms-auto'>
                              <button type='button'
                                className='btn btn-icon btn-light w-32px h-32px'
                                onClick={() => handleShowPermissions(feature.parent)}>
                                <i className='bi bi-pencil-square fs-3 text-primary'></i>
                              </button>
                            </div>
                          </div>
                          <Accordion.Body className='p-0'>
                            {feature.children && feature.children.map((subFeature, subIndex) => (
                              <span key={`sub_feature_${subIndex}`}
                                className="badge badge-light fw-400 fs-7 px-3 py-2 me-4 mb-4">
                                {subFeature.name}
                              </span>
                            ))}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      <div className="separator separator-dashed border-secondary border-2 my-5"></div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className='col-xl-8'>
              <ProfileUsersTable profileUuid={profile.uuid}/>
            </div>
          </div>
          <ProfilePermission
            profile={profile}
            show={showPermissions}
            handleClose={handleClosePermissions}
            handleFeatureChange={() => {}}
            handleSelectedParentFeature={() => {}}
            selectedParentFeature={selectedParentFeature}
            watchedFeatures={checkedFeatures}
            action='edit'
            handleIsLoading={handleIsLoading}
          />
        </>
      ) : (<></>)}
    </>
  )
}

export default ProfileDisplay
