import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

const contactSchema = Yup.object().shape({
  name: Yup.string()
    .required('Ce champs est obligatoire'),
  postalCode: Yup.string()
    .required('Ce champs est obligatoire'),
  isPrimary: Yup.boolean()
    .required('Ce champs est obligatoire'),
  country: Yup.string()
    .required('Ce champs est obligatoire'),
  location: Yup.string()
    .required('Ce champs est obligatoire'),
})

type Props = {
    address?: IAddress,
    deleteOneAddress : (address: IAddress) => void,
    handleCreateAddress: (address : IAddress) => void,
    show: boolean,
    action: string|undefined,
    handleClose: () => void,
}

type Inputs = {
  name: string,
  postalCode: string,
  isPrimary: boolean,
  country: string,
  location: string,
}


const AddressCreate : React.FC<Props> =
    ({ handleClose,
      show,
      handleCreateAddress,
      address,
      action,
      deleteOneAddress,
    }) => {
      const intl = useIntl()

      const config = {
        resolver: yupResolver(contactSchema),
        defaultValues: {
          name: '',
          postalCode: '',
          isPrimary: false,
          country: '',
          location:  '',
        },
      }

      const { register, formState: { errors }, reset, setValue, getValues } = useForm<Inputs>(config)

      setValue('name', address?.name || '')
      setValue('postalCode', address?.postalCode || '')
      setValue('isPrimary', address?.isPrimary || false)
      setValue('country', address?.country || '')
      setValue('location', address?.location || '')

      const onSubmit = () => {
        const addressToAdd : IAddress = {
          uuid: '',
          name: getValues('name') || '',
          postalCode: getValues('postalCode') || '',
          country: getValues('country') || '',
          location: getValues('location') || '',
          isPrimary: getValues('isPrimary') || false,
        }
        if (action === 'create') {
          handleCreateAddress(addressToAdd)
        } else if (address) {
          handleCreateAddress(addressToAdd)
          deleteOneAddress(address)
        }
        handleClose()
        reset()
      }

      useEffect(() => {}, [address])

      return (
        <>
          <Modal show={show} onHide={handleClose} dialogClassName={'mw-500px'} backdropClassName={'custom-z-index'} centered>
            <Modal.Header closeButton>
              <Modal.Title>{intl.formatMessage({ id: 'accounting.contact.address.create.modal' })}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='row mb-5'>
                <div className='col-md-2'>
                  <label className='form-label mt-3 fs-6 text-dark'>{intl.formatMessage({ id: 'accounting.contact.address.entity.name' })}</label>
                </div>
                <div className='col-md-10'>
                  <input
                    placeholder={intl.formatMessage({ id: 'accounting.contact.address.entity.name' })}
                    className={'form-control form-control-solid'}
                    type='text'
                    {...register('name')}
                    autoComplete='off'
                  />
                  {errors.name && errors.name.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.name.message}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col-md-2'>
                  <label className='form-label mt-3 fs-6 text-dark'>{intl.formatMessage({ id: 'accounting.contact.address.entity.postalCode' })}</label>
                </div>
                <div className='col-md-10'>
                  <input
                    placeholder={intl.formatMessage({ id: 'accounting.contact.address.entity.postalCode' })}
                    className={'form-control form-control-solid'}
                    type='text'
                    {...register('postalCode')}
                    autoComplete='off'
                  />
                  {errors.postalCode && errors.postalCode.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.postalCode.message}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col-md-2'>
                  <label className='form-label mt-3 fs-6 text-dark'>{intl.formatMessage({ id: 'accounting.contact.address.entity.country' })}</label>
                </div>
                <div className='col-md-10'>
                  <input
                    placeholder={intl.formatMessage({ id: 'accounting.contact.address.entity.country' })}
                    className={'form-control form-control-solid'}
                    type='text'
                    {...register('country')}
                    autoComplete='off'
                  />
                  {errors.country && errors.country.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.country.message}</span>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-5'>
                <div className='col-md-2'>
                  <label className='form-label mt-3 fs-6 text-dark'>{intl.formatMessage({ id: 'accounting.contact.address.entity.location' })}</label>
                </div>
                <div className='col-md-10'>
                  <input
                    placeholder={intl.formatMessage({ id: 'accounting.contact.address.entity.location' })}
                    className={'form-control form-control-solid'}
                    type='text'
                    {...register('location')}
                    autoComplete='off'
                  />
                  {errors.location && errors.location.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.location.message}</span>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-5'>
                <div className='col-md-2'>
                  <div className='form-label mt-3 fs-6 text-dark '>{intl.formatMessage({ id: 'accounting.contact.address.entity.isPrimary' })}</div>
                </div>
                <div className='col-md-10'>
                  <div className="form-check form-switch form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      {...register('isPrimary')}
                      id="flexSwitchChecked"
                    />
                    {errors.isPrimary && errors.isPrimary.message && (
                      <div className='fv-plugins-message-container text-danger'>
                        <span role='alert'>{errors.isPrimary.message}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type='button'
                className='btn btn-sm btn-secondary'
                onClick={() => {
                  handleClose()
                  reset()
                }}
              >
                {intl.formatMessage({ id: 'action.close' })}
              </button>
              <button
                className='btn btn-sm btn-primary'
                onClick={onSubmit}
              >
                {intl.formatMessage({ id: 'action.save' })}
              </button>
            </Modal.Footer>
          </Modal>
        </>
      )
    }

export default AddressCreate
