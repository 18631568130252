import React from 'react'
import { KTSVG } from '../../../../../../../_metronic/helpers'
import { useStopwatch } from 'react-timer-hook'
import TimeSlotService from '../../../../../../services/Crm/TimeSlotService'

type Props = {
  journey: IJourney|null,
  timeSlot: ITimeSlot|null,
  setIsLoading: (isLoading: boolean) => void,
  checkIn: (timeSlot: ITimeSlot) => void,
}

const BreakStarted : React.FC<Props> = ({ journey, setIsLoading, timeSlot, checkIn }) => {
  const offsetTimestamp = new Date()
  const outsetTimestamp = new Date(timeSlot?.startDate || '')


  function diff_minutes(dt2 : Date, dt1: Date) {
    const diff = (dt2.getTime() - dt1.getTime()) / 1000
    return Math.abs(Math.round(diff))
  }

  offsetTimestamp.setSeconds(offsetTimestamp.getSeconds() + diff_minutes(offsetTimestamp, outsetTimestamp))

  const {
    seconds,
    minutes,
    hours,
  } = useStopwatch({ autoStart: true, offsetTimestamp })

  const onStop = () => {
    const data = {
      isFinished: true,
      endDate: new Date(),
      title: 'Lunch Break',
      type: 'lunch_break',
      isStarted: true,
      status: 'undone',
      journey: journey?.uuid,
    }

    TimeSlotService.updateTimeSlot(timeSlot!.uuid, data).then(() => {
      setIsLoading(true)
    })
  }


  return (
    <div className='card card-rounded mt-5 bg-light-warning'>
      <div className='card-body px-2 py-0'>
        <div className="d-flex">
          <div className="mt-2">
            <div className="d-flex flex-column h-100 pb-2">
              <KTSVG
                path='/media/icons/duotune/abstract/abs009.svg'
                className='svg-icon svg-icon-1 svg-icon-warning'
              />
              <div className='vr bg-warning opacity-100 w-3px h-100 ms-3'></div>
            </div>
          </div>
          <div className="flex-grow-1">
            <div className="row mt-4 ms-3">
              <div className="col-sm-6">
                <span className="text-dark fs-5 fw-500">Pause déjeuner :
                </span>
              </div>
              <div className="col-sm-6">
                <span className="text-dark fs-3 fw-500">
                  <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
                </span>
              </div>


            </div>
          </div>
          <div className="mt-2">
            <div className="d-flex align-content-end justify-content-end">
              <a className='btn btn-icon btn-warning btn-color-dark ms-1 btn-sm w-40px h-40px rounded-3' onClick={onStop}>
                <KTSVG
                  path='/media/icons/duotune/abstract/abs006.svg'
                  className='svg-icon svg-icon-1 svg-icon-white'
                />
              </a>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BreakStarted
