import React from 'react'

type Props = {
    headers: { minWidth : string, text: string } []
}

const TableHeaderSolid : React.FC<Props> = ({ headers }) => (
  <>
    <thead>
      <tr className="fw-bolder text-muted border-0 bg-light fw-600">
        {headers && headers.map((header, index) => (
          <th key={index} className={`${header.minWidth} ps-4 ${index === 0 ? 'rounded-start' : ''} ${index === (headers.length - 1) ? 'text-end rounded-end pe-4' : ''}`}>{header.text}</th>
        ))}
      </tr>
    </thead>
  </>
)

export default TableHeaderSolid
