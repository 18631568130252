import React, { useEffect, useState } from 'react'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { useIntl } from 'react-intl'
import Functions from '../../../../setup/utilities/Functions'
import IncentiveToolbar from './IncentiveToolbar'
import IncentiveListing from './components/IncentiveListing'

const IncentiveWrapper :React.FC = () => {
  const [incentive, setIncentive] = useState<IIncentive|undefined|null>(null)
  const [action, setAction] = useState<string>('')
  const [show, setShow] = React.useState(false)
  const intl = useIntl()

  const incentiveBreadCrumbs : Array<PageLink> = [
    {
      title: intl.formatMessage({ id: 'home.label' }),
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: 'incentives.management.label' }),
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: false,
      isActive: false,
    },
  ]

  const handleClose = async (isSubmit: boolean) => {
    if (!isSubmit) {
      const isConfirmed = await Functions.sweetAlert(
        '<span class="mt-5">Are you sure you would like to cancel</span>',
        'warning',
        true,
        'Yes, cancel it!',
        'No, return',
        'btn btn-primary mt-10 mb-10',
        'btn btn-active-light mt-10 mb-10',
      )
      if (isConfirmed) {
        setShow(false)
      }
    } else {
      setShow(false)
    }
  }
  const handleShow = (incentive: IIncentive|undefined|null, action: string) => {
    setIncentive(incentive)
    setAction(action)
    setShow(true)
  }

  useEffect(() => {}, [incentive])

  return (
    <>
      <PageTitle breadcrumbs={incentiveBreadCrumbs} pageTitle={intl.formatMessage({ id: 'incentive.list' })}>
        <IncentiveToolbar handleShow={handleShow}/>
      </PageTitle>
      <IncentiveListing show={show} handleShow={handleShow} handleClose={handleClose} action={action} incentiveSelected={incentive}/>
    </>
  )
}

export default IncentiveWrapper
