import React, { useEffect, useState } from 'react'
import InputMask from 'react-input-mask'
import { useFormContext } from 'react-hook-form'
import useWindowDimensions from '../../../../../../_metronic/partials/mobile-check-view/useCheckMobileScreen'
import { useIntl } from 'react-intl'
import ImageWrapper from '../../../../../../_metronic/partials/common/ImageWrapper'

type Props = {
  employeeSelected: IEmployee|null|undefined,
  onImageChange: (event: any) => void,
}
const Information : React.FC<Props> = ({ employeeSelected, onImageChange }) => {
  const intl = useIntl()
  const isMobile = useWindowDimensions()
  const { register, formState: { errors }, watch, setValue } = useFormContext()
  const [hidden, setHidden] = useState<boolean>(true)
  const [familyStatus, setFamilyStatus] = useState<string>()
  const [civility, setCivility] = useState<string>()
  const [image, setImage] = useState<File|null>()


  const onChangeCivility = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCivility(e.target.value)
    setValue('civility', e.target.value)
  }

  const onChangeFamilyStatus = (e: React.ChangeEvent<any>) => {
    setHidden(false)
    setFamilyStatus(e.target.value)
    setValue('familyStatus', e.target.value)
  }

  const watchCivility = watch('civility')

  const watchFamilyStatus = watch('familyStatus')

  const b64toBlob = (b64Data: any, contentType: any, sliceSize: any) => {
    contentType = contentType || ''
    sliceSize = sliceSize || 512

    const byteCharacters = atob(b64Data)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)

      byteArrays.push(byteArray)
    }
    return new File(byteArrays, employeeSelected?.firstName + '.' + employeeSelected?.lastName, { type: contentType })
  }

  useEffect(() => {
    if (employeeSelected?.profilePicture) {
      const file = b64toBlob(employeeSelected?.profilePicture, 'image/jpeg', 512)
      setImage(file)
    } else {
      setImage(null)
    }
    setValue('profilePicture', employeeSelected?.profilePicture || '')
    setValue('civility', employeeSelected?.civility || '')
    setValue('firstName', employeeSelected?.firstName || '')
    setValue('lastName', employeeSelected?.lastName || '')
    // @ts-ignore
    setValue('birthDate', employeeSelected?.birthDate.date || '')
    setValue('cin', employeeSelected?.cin || '')
    setValue('address', employeeSelected?.address || '')
    setValue('familyStatus', employeeSelected?.familyStatus || '')
  }, [employeeSelected])

  const handleImage = (event: any) => {
    const file = event.target.files
    setImage(file[0])
    onImageChange(file[0])
  }
  return (
    <>
      { isMobile ?
        (
          <>
            <div className="row d-flex overflow-auto">
              <div className="text-primary fw-bold">{intl.formatMessage({ id: 'employee.step.information.title' })}</div>
              <div className="d-flex justify-content-center pt-5">
                <div className="fv-row mb-7">
                  <ImageWrapper image={image} handleImage={handleImage}/>
                </div>
              </div>
              <div className="fv-row mb-7 fv-plugins-icon-container">
                <label className="required fw-semibold fs-6 mb-2">{intl.formatMessage({ id: 'employee.entity.civility' })}</label>
                <div className="d-flex gap-4">
                  <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary bg-gray-100 d-flex flex-stack text-start p-6 w-50 h-50px ${watchCivility === 'male' ? 'active' : ''}`}>
                    <div className="d-flex align-items-center me-2">
                      <div className="form-check form-check-custom form-check-solid form-check-primary me-3">
                        <input className="form-check-input" type="radio" name="civility" value="male" onChange={onChangeCivility}/>
                      </div>
                      <div className="flex-grow-1">
                        <div className="fw-600">
                          {intl.formatMessage({ id: 'employee.entity.civility.male' })}
                        </div>
                      </div>
                    </div>
                  </label>
                  <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary bg-gray-100 d-flex flex-stack text-start p-6 w-50 h-50px ${watchCivility === 'female' ? 'active' : ''}`}>
                    <div className="d-flex align-items-center me-2">
                      <div className="form-check form-check-custom form-check-solid form-check-primary me-3">
                        <input className="form-check-input" type="radio" name="civility" value="female" onChange={onChangeCivility}/>
                      </div>
                      <div className="flex-grow-1">
                        <div className="fw-600">
                          {intl.formatMessage({ id: 'employee.entity.civility.female' })}
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
                {errors.civility && errors.civility.message && (
                  <div className='fv-plugins-message-container text-danger'>
                    <>{errors.civility.message}</>
                  </div>
                )}
              </div>
              <div className="fv-row mb-7 fv-plugins-icon-container">
                <label className="required fw-semibold fs-6 mb-2">{intl.formatMessage({ id: 'employee.entity.lastName' })}</label>
                <input type="text"
                  className={'form-control form-control-solid'}
                  placeholder="Nom"
                  {...register('lastName')}
                />
                {errors.lastName && errors.lastName.message && (
                  <div className='fv-plugins-message-container text-danger'>
                    <>{errors.lastName.message}</>
                  </div>
                )}
              </div>
              <div className="fv-row mb-7 fv-plugins-icon-container">
                <label className="required fw-semibold fs-6 mb-2">{intl.formatMessage({ id: 'employee.entity.firstName' })}</label>
                <input type="text"
                  className={'form-control form-control-solid'}
                  placeholder="Prénom"
                  {...register('firstName')}
                />
                {errors.firstName && errors.firstName.message && (
                  <div className='fv-plugins-message-container text-danger'>
                    <>{errors.firstName.message}</>
                  </div>
                )}
              </div>
              <div className="fv-row mb-7 fv-plugins-icon-container">
                <label className="required fw-semibold fs-6 mb-2">{intl.formatMessage({ id: 'employee.entity.birthDate' })}</label>
                <InputMask
                  className={'form-control form-control-solid'}
                  {...register('birthDate')}
                  mask='9999/99/99'
                  placeholder="----/--/--"
                />
                {errors.birthDate && errors.birthDate.message && (
                  <div className='fv-plugins-message-container text-danger'>
                    <>{errors.birthDate.message}</>
                  </div>
                )}
              </div>
              <div className="fv-row mb-7 fv-plugins-icon-container">
                <label className="required fw-semibold fs-6 mb-2">{intl.formatMessage({ id: 'employee.entity.cin' })}</label>
                <InputMask
                  className={'form-control form-control-solid'}
                  mask='99999999'
                  {...register('cin')}
                  placeholder="--------"
                />
                {errors.cin && errors.cin.message && (
                  <div className='fv-plugins-message-container text-danger'>
                    <>{errors.cin.message}</>
                  </div>
                )}
              </div>
              <div className="separator separator-dashed mb-6"></div>
              <div className="fv-row mb-7 fv-plugins-icon-container">
                <label className="required fw-semibold fs-6 mb-2">{intl.formatMessage({ id: 'employee.entity.familyStatus' })}</label>
                <select
                  className="form-select form-select-solid"
                  {...register('familyStatus')}
                  onChange={onChangeFamilyStatus}
                >
                  <option value="single">{intl.formatMessage({ id: 'employee.entity.familyStatus.single' })}</option>
                  <option value="married">{intl.formatMessage({ id: 'employee.entity.familyStatus.married' })}</option>
                  <option value="widower">{intl.formatMessage({ id: 'employee.entity.familyStatus.widower' })}</option>
                  <option value="seperated">{intl.formatMessage({ id: 'employee.entity.familyStatus.seperated' })}</option>
                  <option value="divorced">{intl.formatMessage({ id: 'employee.entity.familyStatus.divorced' })}</option>
                </select>
                {errors.familyStatus && errors.familyStatus.message && (
                  <div className='fv-plugins-message-container text-danger'>
                    <>{errors.familyStatus.message}</>
                  </div>
                )}
              </div>
              <div hidden={hidden}>
                { familyStatus === 'married' || familyStatus === 'widower' ?
                  <>
                    { civility === 'female' ?
                      <>
                        <div className="fv-row mb-7 fv-plugins-icon-container">
                          <label className="fw-semibold fs-6 mb-2">{intl.formatMessage({ id: 'employee.entity.maidenName' })}</label>
                          <input className="form-control form-control-solid" type="text" {...register('maidenName')}/>
                        </div>
                      </> :
                      <></>
                    }
                    <div className="fv-row mb-7 fv-plugins-icon-container">
                      <label className="fw-semibold fs-6 mb-2">{intl.formatMessage({ id: 'employee.entity.childrenNumber' })}</label>
                      <input className="form-control form-control-solid" type="number" {...register('childrenNumber')}/>
                    </div>
                  </> :
                  <>
                    {familyStatus === 'seperated' || familyStatus === 'divorced' ?
                      <>
                        <div className="col-lg-2">
                          <label className="required fw-500 pt-4">{intl.formatMessage({ id: 'employee.entity.childrenNumber' })}</label>
                        </div>
                        <div className="col-lg-4">
                          <input className="form-control form-control-solid" type="number" {...register('childrenNumber')}/>
                        </div>
                      </> :
                      <></>
                    }
                  </>
                }
              </div>
            </div>
          </>
        ) :
        (
          <>
            <div className="row d-flex overflow-auto">
              <div className="text-primary fw-bold">{intl.formatMessage({ id: 'employee.step.information.title' })}</div>
              <div className="w-1000px ms-20 pt-5">
                <div className="row">
                  <div className="col-lg-3 d-flex justify-content-center pt-5">
                    <div className="fv-row mb-7">
                      <ImageWrapper image={image} handleImage={handleImage}/>
                    </div>
                  </div>
                  <div className="col-lg-9">
                    <div className="row">
                      <div className="col-3">
                        <label className="required fw-500 pt-3">{intl.formatMessage({ id: 'employee.entity.civility' })}</label>
                      </div>
                      <div className="col-9">
                        <div className="d-flex gap-4" data-kt-buttons="true">
                          <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary bg-gray-100 d-flex flex-stack text-start p-6 w-150px h-50px ${watchCivility === 'male' ? 'active' : ''}`}>
                            <div className="d-flex align-items-center me-2">
                              <div className="form-check form-check-custom form-check-solid form-check-primary me-3">
                                <input checked={watchCivility === 'male'} className="form-check-input" type="radio" value="male" name="civility" onChange={onChangeCivility}/>
                              </div>
                              <div className="flex-grow-1">
                                <div className="fw-600">
                                  {intl.formatMessage({ id: 'employee.entity.civility.male' })}
                                </div>
                              </div>
                            </div>
                          </label>
                          <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary bg-gray-100 d-flex flex-stack text-start p-6 w-150px h-50px ${watchCivility === 'female' ? 'active' : ''}`}>
                            <div className="d-flex align-items-center me-2">
                              <div className="form-check form-check-custom form-check-solid form-check-primary me-3">
                                <input checked={watchCivility === 'female'} className="form-check-input" type="radio" value="female" name="civility" onChange={onChangeCivility}/>
                              </div>
                              <div className="flex-grow-1">
                                <div className="fw-600">
                                  {intl.formatMessage({ id: 'employee.entity.civility.female' })}
                                </div>
                              </div>
                            </div>
                          </label>
                        </div>
                        {errors.civility && errors.civility.message && (
                          <div className='fv-plugins-message-container text-danger'>
                            <>{errors.civility.message}</>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row pt-3">
                      <div className="col-3">
                        <label className="required fw-500 pt-3">{intl.formatMessage({ id: 'employee.entity.lastName' })}</label>
                      </div>
                      <div className="col-9">
                        <input type="text"
                          className={'form-control form-control-solid'}
                          placeholder="Nom"
                          {...register('lastName')}
                        />
                        {errors.lastName && errors.lastName.message && (
                          <div className='fv-plugins-message-container text-danger'>
                            <>{errors.lastName.message}</>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row pt-3">
                      <div className="col-3">
                        <label className="required fw-500 pt-3">{intl.formatMessage({ id: 'employee.entity.firstName' })}</label>
                      </div>
                      <div className="col-9">
                        <input type="text"
                          className={'form-control form-control-solid'}
                          placeholder="Prénom"
                          {...register('firstName')}
                        />
                        {errors.firstName && errors.firstName.message && (
                          <div className='fv-plugins-message-container text-danger'>
                            <>{errors.firstName.message}</>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row pt-3">
                      <div className="col-3">
                        <label className="required fw-500 pt-3">{intl.formatMessage({ id: 'employee.entity.birthDate' })}</label>
                      </div>
                      <div className="col-9">
                        <InputMask
                          className={'form-control form-control-solid'}
                          {...register('birthDate')}
                          mask='9999/99/99'
                          placeholder="----/--/--"
                        />
                        {errors.birthDate && errors.birthDate.message && (
                          <div className='fv-plugins-message-container text-danger'>
                            <>{errors.birthDate.message}</>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row pt-3">
                      <div className="col-3">
                        <label className="required fw-500 pt-3">{intl.formatMessage({ id: 'employee.entity.cin' })}</label>
                      </div>
                      <div className="col-9">
                        <InputMask
                          className={'form-control form-control-solid'}
                          {...register('cin')}
                          mask='99999999'
                          placeholder="--------"
                        />
                        {errors.cin && errors.cin.message && (
                          <div className='fv-plugins-message-container text-danger'>
                            <>{errors.cin.message}</>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="separator separator-dashed pt-5 mb-6 ms-10"></div>
                <div className="row d-flex justify-content-center">
                  <div className="col-lg-2">
                    <label className="required fw-500 pt-4">{intl.formatMessage({ id: 'employee.entity.familyStatus' })}</label>
                  </div>
                  <div className="col-9">
                    <div className="d-flex gap-2">
                      <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary bg-gray-100 d-flex flex-stack text-start p-6 mb-5 w-150px h-50px ${watchFamilyStatus === 'single' ? 'active' : ''}`}>
                        <div className="d-flex align-items-center">
                          <div className="form-check form-check-custom form-check-solid form-check-primary me-2">
                            <input checked={watchFamilyStatus === 'single'} className="form-check-input" type="radio" value="single" name="familyStatus" onChange={onChangeFamilyStatus}/>
                          </div>
                          <div className="fw-600">
                            {intl.formatMessage({ id: 'employee.entity.familyStatus.single' })}
                          </div>
                        </div>
                      </label>
                      <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary bg-gray-100 d-flex flex-stack text-start p-6 mb-5 w-150px h-50px ${watchFamilyStatus === 'married' ? 'active' : ''}`}>
                        <div className="d-flex align-items-center me-2">
                          <div className="form-check form-check-custom form-check-solid form-check-primary me-2">
                            <input checked={watchFamilyStatus === 'married'} className="form-check-input" type="radio" value="married" name="familyStatus" onChange={onChangeFamilyStatus}/>
                          </div>
                          <div className="flex-grow-1">
                            <div className="fw-600">
                              {intl.formatMessage({ id: 'employee.entity.familyStatus.married' })}
                            </div>
                          </div>
                        </div>
                      </label>
                      <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary bg-gray-100 d-flex flex-stack text-start p-6 mb-5 w-150px h-50px ${watchFamilyStatus === 'widower' ? 'active' : ''}`}>
                        <div className="d-flex align-items-center me-2">
                          <div className="form-check form-check-custom form-check-solid form-check-primary me-2">
                            <input checked={watchFamilyStatus === 'widower'} className="form-check-input" type="radio" value="widower" name="familyStatus" onChange={onChangeFamilyStatus}/>
                          </div>
                          <div className="flex-grow-1">
                            <div className="fw-600">
                              {intl.formatMessage({ id: 'employee.entity.familyStatus.widower' })}
                            </div>
                          </div>
                        </div>
                      </label>
                      <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary bg-gray-100 d-flex flex-stack text-start p-6 mb-5 w-150px h-50px ${watchFamilyStatus === 'seperated' ? 'active' : ''}`}>
                        <div className="d-flex align-items-center me-2">
                          <div className="form-check form-check-custom form-check-solid form-check-primary me-2">
                            <input checked={watchFamilyStatus === 'seperated'} className="form-check-input" type="radio" value="seperated" name="familyStatus" onChange={onChangeFamilyStatus}/>
                          </div>
                          <div className="flex-grow-1">
                            <div className="fw-600">
                              {intl.formatMessage({ id: 'employee.entity.familyStatus.seperated' })}
                            </div>
                          </div>
                        </div>
                      </label>
                      <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary bg-gray-100 d-flex flex-stack text-start p-6 mb-5 w-150px h-50px ${watchFamilyStatus === 'divorced' ? 'active' : ''}`}>
                        <div className="d-flex align-items-center">
                          <div className="form-check form-check-custom form-check-solid form-check-primary me-2">
                            <input checked={watchFamilyStatus === 'divorced'} className="form-check-input" type="radio" value="divorced" name="familyStatus" onChange={onChangeFamilyStatus}/>
                          </div>
                          <div className="flex-grow-1">
                            <div className="fw-600">
                              {intl.formatMessage({ id: 'employee.entity.familyStatus.divorced' })}
                            </div>
                          </div>
                        </div>
                      </label>
                    </div>
                    {errors.familyStatus && errors.familyStatus.message && (
                      <div className='fv-plugins-message-container text-danger'>
                        <>{errors.familyStatus.message}</>
                      </div>
                    )}
                  </div>
                  <div className="row w-950px ms-8" hidden={hidden}>
                    { familyStatus === 'married' || familyStatus === 'widower' ?
                      <>
                        { civility === 'female' ?
                          <>
                            <div className="col-lg-2">
                              <label className="required fw-500 pt-4">{intl.formatMessage({ id: 'employee.entity.maidenName' })}</label>
                            </div>
                            <div className="col-lg-4">
                              <input className="form-control form-control-solid" type="text" {...register('maidenName')}/>
                            </div>
                          </> :
                          <></>
                        }
                        <div className="col-lg-2">
                          <label className="required fw-500 pt-4">{intl.formatMessage({ id: 'employee.entity.childrenNumber' })}</label>
                        </div>
                        <div className="col-lg-4">
                          <input className="form-control form-control-solid" type="number" {...register('childrenNumber')}/>
                        </div>
                      </> :
                      <>
                        {familyStatus === 'seperated' || familyStatus === 'divorced' ?
                          <>
                            <div className="col-lg-2">
                              <label className="required fw-500 pt-4">{intl.formatMessage({ id: 'employee.entity.childrenNumber' })}</label>
                            </div>
                            <div className="col-lg-4">
                              <input className="form-control form-control-solid" type="number" {...register('childrenNumber')}/>
                            </div>
                          </> :
                          <></>
                        }
                      </>
                    }
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      }
    </>
  )
}

export default Information
