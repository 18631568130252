/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import Can from '../../../../app/services/casl/Can'

export function AsideMenuMain() {
  const intl = useIntl()
  return (
    <>
    <Can do="management" on="crm">
        <div className='menu-item'>
            <div className='menu-content pt-2 pb-2'>
                <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Raccourci</span>
            </div>
        </div>
        <Can do="management" on="medicalProfessional">
          <AsideMenuItemWithSub
            to=''
            title='Liste médecins'
            fontIcon=''
            icon='/media/icons/duotune/abstract/doctor.svg'
          >
              <AsideMenuItem to='/doctor' title={intl.formatMessage({ id: 'medical.professional.listing.menu' })} hasBullet={true} />
              <AsideMenuItem to='/med-pro' title={intl.formatMessage({ id: 'medical.professional.med.pro.menu' })} hasBullet={true} />
              <AsideMenuItem to='/med-link' title={intl.formatMessage({ id: 'medical.professional.med.link.menu' })} hasBullet={true} />
              <AsideMenuItem to='/subscription' title={intl.formatMessage({ id: 'medical.professional.subscription.menu' })} hasBullet={true} />
              <AsideMenuItem to='/demo' title={intl.formatMessage({ id: 'medical.professional.demo.menu' })} hasBullet={true} />
              <AsideMenuItem to='/secretary' title={intl.formatMessage({ id: 'medical.professional.secretary.menu' })} hasBullet={true} />
              <AsideMenuItem to='/pending-payment' title={intl.formatMessage({ id: 'medical.professional.pending.payment.menu' })} hasBullet={true} />
              <AsideMenuItem to='/call-history' title={intl.formatMessage({ id: 'medical.professional.call.history.menu' })} hasBullet={true} />
              <AsideMenuItem to='/call-patients' title={intl.formatMessage({ id: 'medical.professional.call.patients.menu' })} hasBullet={true} />
              <AsideMenuItem to='/zoning' title={intl.formatMessage({ id: 'medical.professional.zoning.menu' })} hasBullet={true} />
              <AsideMenuItem to='/flux-med-pro' title={intl.formatMessage({ id: 'medical.professional.medPro.flux.menu' })} hasBullet={true} />
              <AsideMenuItem to='/visit-history' title={intl.formatMessage({ id: 'medical.professional.visit.history.menu' })} hasBullet={true} />
              <AsideMenuItem to='/todo' title={intl.formatMessage({ id: 'medical.professional.todo.menu' })} hasBullet={true} />
              <AsideMenuItem to='/sponsorship' title={intl.formatMessage({ id: 'medical.professional.sponsorship.menu' })} hasBullet={true} />
              <AsideMenuItem to='/activities' title={intl.formatMessage({ id: 'medical.professional.activities.menu' })} hasBullet={true} />
              <AsideMenuItem to='/stock' title={intl.formatMessage({ id: 'medical.professional.stock.menu' })} hasBullet={true} />
              <AsideMenuItem to='/acts' title={intl.formatMessage({ id: 'medical.professional.acts.menu' })} hasBullet={true} />
              <AsideMenuItem to='/locality' title={intl.formatMessage({ id: 'medical.professional.locality.menu' })} hasBullet={true} />
          </AsideMenuItemWithSub>

          <AsideMenuItemWithSub
            to=''
            title='Pharmacies'
            fontIcon=''
            icon='/media/icons/duotune/abstract/pharmacy.svg'
          >
          </AsideMenuItemWithSub>
        </Can>
      <AsideMenuItem
        to='/calendar'
        title='Calendar'
        fontIcon=''
        icon='/media/icons/duotune/general/gen032.svg'
      >
      </AsideMenuItem>
      <AsideMenuItemWithSub
        to=''
        title='Map Data'
        fontIcon=''
        icon='/media/icons/duotune/finance/fin003.svg'
      >
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to=''
        title='Missions'
        fontIcon=''
        icon='/media/icons/duotune/finance/fin003.svg'
      >
      </AsideMenuItemWithSub>
        <Can do="management" on="journey">
            <AsideMenuItem
                to='/journey'
                title='Todo list'
                fontIcon=''
                icon='/media/icons/duotune/finance/fin003.svg'
            />
        </Can>


        <AsideMenuItemWithSub
            to=''
            title={intl.formatMessage({ id: 'products.management.menu' })}
            fontIcon=''
            icon='/media/icons/duotune/finance/fin006.svg'
        >
            <Can do="management" on="product">
                <AsideMenuItem to='/product' title={intl.formatMessage({ id: 'products.list' })} hasBullet={true} />
            </Can>
            <Can do="management" on="productFeature">
                <AsideMenuItem to='/product-feature' title={intl.formatMessage({ id: 'product-feature.list' })} hasBullet={true} />
            </Can>
            <Can do="management" on="package">
                <AsideMenuItem to='/package' title={intl.formatMessage({ id: 'packages.management.label' })} hasBullet={true} />
            </Can>

        </AsideMenuItemWithSub>
        <Can do="management" on="incentive">
            <AsideMenuItem
                to='/incentive'
                title={intl.formatMessage({ id: 'incentives.management.menu' })}
                fontIcon=''
                icon='/media/icons/duotune/ecommerce/ecm008.svg'
            />
        </Can>
        <Can do="management" on="location">
            <AsideMenuItem
                to='/location'
                title={intl.formatMessage({ id: 'locations.management.menu' })}
                fontIcon=''
                icon='/media/icons/duotune/maps/map002.svg'
            />
        </Can>
    </Can>
    <Can do="management" on="hr">
      <AsideMenuItemWithSub
        to='/employee-management'
        title={intl.formatMessage({ id: 'employee.management.menu' })}
        fontIcon=''
        icon='/media/icons/duotune/abstract/users.svg'
      >
          <Can do="management" on="employee">
            <AsideMenuItem to='/employee' title={intl.formatMessage({ id: 'employee.menu' })} hasBullet={true} />
          </Can>
          <Can do="management" on="salesPerson">
            <AsideMenuItem to='sales-team' title={intl.formatMessage({ id: 'salesTeam.list' })} hasBullet={true} />
          </Can>
          <Can do="management" on="userGroup">
            <AsideMenuItem to='/department' title={intl.formatMessage({ id: 'department.menu' })} hasBullet={true} />
          </Can>
          <Can do="management" on="team">
            <AsideMenuItem to='/team' title='Équipes' hasBullet={true} />
          </Can>
          <Can do="management" on="profile">
            <AsideMenuItem to='/profile' title={intl.formatMessage({ id: 'profile.menu' })} hasBullet={true} />
          </Can>
          <Can do="management" on="employeeEquipment">
            <AsideMenuItem to='/employee/equipment' title={intl.formatMessage({ id: 'equipment.menu' })} hasBullet={true} />
          </Can>
      </AsideMenuItemWithSub>
    </Can>
    <Can do="management" on="accounting">
      <div className='menu-item'>
        <div className='menu-content pt-2 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Comptabilité</span>
        </div>
      </div>
      <AsideMenuItemWithSub
            to=''
            title='Vente'
            fontIcon=''
            icon='/media/icons/duotune/finance/fin001.svg'
        >
          <Can do="management" on="estimate">
            <AsideMenuItem to='/estimate' title={intl.formatMessage({ id: 'accounting.estimate.menu' })} hasBullet={true} />
          </Can>
          <Can do="management" on="purchaseOrder">
            <AsideMenuItem to='/purchase-order' title={intl.formatMessage({ id: 'accounting.purchase.order.menu' })} hasBullet={true} />
          </Can>
          <Can do="management" on="invoice">
            <AsideMenuItem to='/invoice' title={intl.formatMessage({ id: 'accounting.invoice.menu' })} hasBullet={true} />
          </Can>
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
            to=''
            title='Achat'
            fontIcon=''
            icon='/media/icons/duotune/finance/fin002.svg'
        >
          <Can do="management" on="supplierOrder">
            <AsideMenuItem to='/order-supplier' title={intl.formatMessage({ id: 'accounting.supplier.order.menu' })} hasBullet={true} />
          </Can>
      </AsideMenuItemWithSub>
        <Can do="management" on="customer">
            <AsideMenuItem to='/customer' title={intl.formatMessage({ id: 'accounting.customer.menu' })} icon='/media/icons/duotune/abstract/users.svg' />
        </Can>
        <Can do="management" on="supplier">
            <AsideMenuItem to='/supplier' title={intl.formatMessage({ id: 'accounting.supplier.menu' })} icon='/media/icons/duotune/ecommerce/ecm006.svg' />
        </Can>
        <Can do="management" on="equipment">
            <AsideMenuItem to='/equipment' title={intl.formatMessage({ id: 'accounting.equipment.menu' })} icon='/media/icons/duotune/abstract/abs040.svg' />
        </Can>
      <AsideMenuItemWithSub
        to=''
        title='Comptabilité'
        fontIcon=''
        icon='/media/icons/duotune/ecommerce/ecm009.svg'
      >
          <Can do="management" on="cashFlow">
              <AsideMenuItem to='/cash-flow' title={intl.formatMessage({ id: 'accounting.cash.flow.menu' })} hasBullet={true} />
          </Can>
          <Can do="management" on="bankAccount">
              <AsideMenuItem to='/bank-account' title={intl.formatMessage({ id: 'accounting.bank.account.menu' })} hasBullet={true} />
          </Can>
          <Can do="management" on="payment">
              <AsideMenuItem to='/check-draft' title={intl.formatMessage({ id: 'accounting.check.and.draft.menu' })} hasBullet={true} />
          </Can>
      </AsideMenuItemWithSub>
    </Can>
    </>
  )
}
