import apiService from '../../../setup/axios/ApiService'

const prefixURL = 'income-category'

const selectIncomeCategories = () => apiService.get(`${prefixURL}/select`)

const IncomeCategoryService = {
  selectIncomeCategories,
}

export default IncomeCategoryService
