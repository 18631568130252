import apiService from '../../../setup/axios/ApiService'

const prefixURL = 'expense-category'

const selectExpenseCategories = () => apiService.get(`${prefixURL}/select`)

const ExpenseCategoryService = {
  selectExpenseCategories,
}

export default ExpenseCategoryService
