import React, {useEffect, useState} from 'react'
import DatePicker from 'react-datepicker'
import AsyncSelect from 'react-select/async'
import Select from 'react-select'
import {KTSVG} from '../../helpers'
import format from "date-fns/format"
import CustomerService from '../../../app/services/Accounting/CustomerService'
import Functions from '../../../setup/utilities/Functions'
import ProductService from '../../../app/services/Crm/ProductService'
import currency from 'currency.js'
import AccountingPreview from './AccountingPreview'
import AccountingDiscount from './AccountingDiscount'
import 'react-datepicker/dist/react-datepicker.css'


type Props = {
    isQuantity: boolean,
    isDiscount: boolean,
    isDisplayContact: boolean,
    isDisplayTotalAmount: boolean,
    isDisplayGrossAmount: boolean,
    setIsDisplayContact : (show: boolean) => void,
    label: string,
    color: string,
    isLoading: boolean
    alertOnChange: (title: string) => void,
    onChangeAnything: any,
    pdfRef: any,
    passedCustomer: ICustomer|null,
    documentLines: IDocumentLine[]
}

const colourStyles = {
    control: (styles: any) => ({ ...styles, backgroundColor: '#F5F8FA', border: 0, height: '43px' }),
}

// Calculate Total Amount Per Line
function calculateTotalAmountPerLine(price: any, quantity: number) {
    const priceTo = currency(price)
    return priceTo.multiply(quantity)
}

// Calculate Total Gross Per Line
function calculateGrossTotalPerLine(totalAmount: any, vat: any) {
    const priceTo = currency(totalAmount)
    return priceTo.multiply(vat).divide(100)
}

// Calculate Total Gross Amount
function calculateGrossAmountPerLine(totalAmount: any, gross: any) {
    const priceTo = currency(totalAmount)
    return priceTo.add(gross)
}

// Calculate Total Price after discount
function calculateAfterDiscount(grossAmount: any, type:any, value: any) {
    const priceTo = currency(grossAmount)
    if (type === '%') {
        const discountPercentage = priceTo.multiply(value).divide(100)
        return priceTo.subtract(discountPercentage)
    } else {
        return priceTo.subtract(value)
    }
}

// Calculate Total Price of all prices in the array
function calculateTotalPrice(data: { product: string, name: any, quantity: any, price: any, discount: any, discountType: any, vat: any, gross: any, total: any }[]) {
    return data.reduce((accumulator, object) => (object.price ? parseInt(currency(object.price).add(currency(accumulator)).toString()) : 0), 0)
}

// Calculate Gross Price of all prices in the array
function calculateGrossPrice(data: { product: string, name: any, quantity: any, price: any, discount: any, discountType: any, vat: any, gross: any, total: any }[]) {
    return data.reduce((accumulator, object) => (object.gross ? accumulator + parseInt(object.gross) : 0), 0)
}

// Calculate Gross Total of all prices in the array
function calculateGrossTotal(data: { product: string, name: any, quantity: any, price: any, discount: any, discountType: any, vat: any, gross: any, total: any }[]) {
    return data.reduce((accumulator, object) => (object.total ? accumulator + parseInt(object.total) : 0), 0)
}

// Calculate Total of all discounts in the array
function calculateTotalDiscount(data: { product: string, name: any, quantity: any, price: any, discount: any, discountType: any, vat: any, gross: any, total: any }[]) {
    return data.reduce((accumulator, object) => (object.discount ? accumulator + parseInt(object.discount) : 0), 0)
}

const AccountingCreate: React.FC<Props> = ({
      isQuantity,
      isDiscount,
      isDisplayContact,
      isDisplayTotalAmount,
      isDisplayGrossAmount,
      label,
      color,
      isLoading,
      setIsDisplayContact,
      alertOnChange,
      onChangeAnything,
      pdfRef,
      passedCustomer,
      documentLines
  }) => {
    const [customer, setCustomer] = useState<ICustomer|null>(null)
    const [date, setDate] = useState<string>(format(new Date(), 'MM/dd/yyyy'))
    const handleChangeDate = (event: any) => {
        setDate(format(event, 'MM/dd/yyyy'))
        onChangeAnything({ paymentDate: format(event, 'yyyy/MM/dd') })
    }

    const onChangeCustomer = (event : any) => {
        onChangeAnything({ customer: event.value })
        setIsDisplayContact(false)
        CustomerService.getCustomer(event.value).then((response) => {
            setCustomer(response.data.data)
        })
    }

    const loadCustomers = (inputValue: string, callback: (options: any) => void) => {
        CustomerService.selectCustomer(inputValue).then((response) => {
            callback(response.data.data.map((item: { value: any; label: any; }) => ({ label: item.label, value: item.value })))
        })
    }

    // Product Area
    const [products, setProducts] = useState<{value: string, label: string}[] >([])
    const [product, setProduct] = useState<IProduct>()
    const [formValues, setFormValues] = useState<{ product: string, name: any, quantity: any, price: any, discount: any, discountType: any, vat: any, gross: any, total: any }[]>(documentLines)
    const [action, setAction] = useState<string>('')
    const [index, setIndex] = useState<number>()
    const [discount, setDiscount] = useState<{type: string, value: any}|null>()
    const [vat, setVat] = useState<any>()
    const officialSeal = 0.6

    // Discount area
    const [showDiscount, setShowDiscount] = useState<boolean>(false)
    const handleShowDiscount = (selectedDiscount: {type: string, value: any} | null, action: string, index: number) => {
        setShowDiscount(true)
        setDiscount(selectedDiscount)
        setAction(action)
        setIndex(index)
    }

    const handleCloseDiscount = async (isSubmit: boolean) => {
        if (!isSubmit) {
            const isConfirmed = await Functions.sweetAlert(
                '<span class="mt-5">Are you sure you would like to cancel</span>',
                'warning',
                true,
                'Yes, cancel it!',
                'No, return',
                'btn btn-primary mt-10 mb-10',
                'btn btn-active-light mt-10 mb-10',
            )
            if (isConfirmed) {
                setShowDiscount(false)
            }
        } else {
            setShowDiscount(false)
        }
    }

    // Product Area
    const handleChangeProduct = (i: number, e: any) => {
        const newFormValues = [...formValues]
        ProductService.getProduct(e.value).then((response) => {
            setProduct(response.data.data)
            const price = calculateTotalAmountPerLine(response.data.data.rate, 1)
            const gross = calculateGrossTotalPerLine(response.data.data.rate, response.data.data.tax)
            const total = calculateGrossAmountPerLine(response.data.data.rate, gross)
            setVat(response.data.data.tax)
            newFormValues[i]['quantity'] = null
            newFormValues[i]['price'] = price
            newFormValues[i]['vat'] = response.data.data.tax
            newFormValues[i]['discount'] = 0
            newFormValues[i]['gross'] = gross
            newFormValues[i]['total'] = total
            newFormValues[i]['product'] = e.value
            newFormValues[i]['name'] = e.label
            onChangeAnything({ documentLines: newFormValues, netAmount: price, grossAmount: total})
        })
        setFormValues(newFormValues)
    }

    const handleChangeQuantity = (i: number, e: any) => {
        const newFormValues = [...formValues]
        const totalPrice = calculateTotalAmountPerLine(product?.rate, e.target.value)
        const gross = calculateGrossTotalPerLine(totalPrice, vat)
        let total = calculateGrossAmountPerLine(totalPrice, gross)
        if (newFormValues[i]['discount']) {
            total = calculateAfterDiscount(total, newFormValues[i]['discountType'], newFormValues[i]['discount'])
        }

        newFormValues[i]['quantity'] = e.target.value
        newFormValues[i]['price'] = totalPrice
        newFormValues[i]['gross'] = gross
        newFormValues[i]['total'] = total
        setFormValues(newFormValues)
        onChangeAnything({ documentLines: newFormValues, netAmount: totalPrice, grossAmount: total})
    }

    const handleChangeDiscount = (i : number, discount: { type: string, value: string }) => {
        const newFormValues = [...formValues]
        const TotalAfterDiscount = calculateAfterDiscount(newFormValues[i]['total'], discount.type, discount.value)
        newFormValues[i]['discount'] = discount.value
        newFormValues[i]['discountType'] = discount.type
        newFormValues[i]['total'] = TotalAfterDiscount
        setFormValues(newFormValues)
        onChangeAnything({ documentLines: newFormValues, grossAmount: TotalAfterDiscount })
    }

    const addFormFields = () => {
        if (customer) {
            setFormValues([...formValues, { product: '', name: null, quantity: null, price: null, discount: null, discountType: null, gross: null, vat: null, total: null }])
        } else {
            alertOnChange('You need to add Customer')
        }
    }

    const removeFormFields = (i: any) => {
        const newFormValues = [...formValues]
        newFormValues.splice(i, 1)
        setFormValues(newFormValues)
    }

    useEffect(() => {
        ProductService.selectProducts().then((response) => {
            setProducts(response.data.data)
        })
    }, [])

    const handleKeypress = (e: any) => {
        if (e.keyCode === 13) {
            addFormFields()
        }
    }

    useEffect(() => {
        if(passedCustomer){
            setCustomer(passedCustomer)
        }
        if(documentLines){
            setFormValues(documentLines)
        }
    }, [documentLines, passedCustomer])

    useEffect(() => {}, [product])

    useEffect(() => {}, [discount])

    useEffect(() => {}, [customer])

    return (
        <>
            <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
                <div className={`card ${isLoading ? 'overlay overlay-block' : ''}`}>
                    <div className={`card-body p-12 ${isLoading ? 'overlay-wrapper' : ''}`}>
                        <form noValidate>
                            <div className="d-flex flex-column align-items-start flex-xxl-row">
                                <div className="d-flex align-items-start fw-row me-4 order-2">
                                    <span className="fs-2x fw-bold text-gray-800">{label}</span>
                                </div>
                                <div className="d-flex align-items-center justify-content-end flex-equal order-3">
                                    <div className="image-input image-input-outline" data-kt-image-input="true">
                                        <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                               data-kt-image-input-action="change" title=""
                                               data-bs-original-title="Change avatar">
                                            <i className="bi bi-pencil-fill fs-7 ms-3"></i>
                                            <DatePicker onChange={handleChangeDate} value={date}/>
                                        </label>
                                        <span className="form-control form-control-flush fw-bold fs-3">
                      {date}
                    </span>
                                    </div>
                                </div>
                            </div>
                            <div className="separator separator-dashed my-10" style={{ backgroundColor: color }}></div>
                            <div className="row gx-10 mb-10">
                                <div className="col-lg-6">
                                    <label className="form-label fs-6 fw-bold text-gray-700 mb-3">Client</label>
                                    <div className="mb-5">
                                        <AsyncSelect
                                            styles={colourStyles}
                                            placeholder={'Choose a customer'}
                                            loadOptions={loadCustomers}
                                            onChange={onChangeCustomer}
                                            value={{value: customer?.uuid, label: customer?.displayName}}
                                        />
                                    </div>
                                    <table className="table table-flush fw-semibold gy-1" hidden={isDisplayContact}>
                                        <tbody>
                                        {customer?.contacts && customer?.contacts.map((item, index) => (
                                            <>
                                                {item.isDefault ?
                                                    <>
                                                        <tr>
                                                            <td className="text-muted min-w-125px w-125px">Name</td>
                                                            <td className="text-gray-800">{customer.displayName}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-muted min-w-125px w-125px">To</td>
                                                            <td className="text-gray-800">{item.name}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-muted min-w-125px w-125px">Phone</td>
                                                            <td className="text-gray-800">{item.phone}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-muted min-w-125px w-125px">Email</td>
                                                            <td className="text-gray-800">{item.email}</td>
                                                        </tr>
                                                    </> :
                                                    <></>
                                                }
                                            </>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="separator separator-dashed my-10" style={{ backgroundColor: color }}></div>
                            <div className="table-responsive">
                                <table className="table g-5 gs-0 mb-0 fw-bolds">
                                    <thead>
                                    <tr className="border-bottom fs-7 fw-bold text-uppercase">
                                        <th className="min-w-200px w-100px">Item</th>
                                        <th className="min-w-75px w-100px" hidden={isQuantity}>QTY</th>
                                        <th className="min-w-75px w-125px" hidden={isDisplayTotalAmount}>Price</th>
                                        <th className="min-w-75px w-100px">Vat</th>
                                        <th className="min-w-75px w-125px" hidden={isDiscount}>Discount</th>
                                        <th className="min-w-125px w-125px" hidden={isDisplayGrossAmount}>Total</th>
                                        <th className="min-w-50px w-50px text-end">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {formValues.map((element, index) => (
                                        <tr className="border-bottom border-bottom-dashed" key={index}>
                                            <td>
                                                <Select
                                                    styles={colourStyles}
                                                    options={products}
                                                    isSearchable={true}
                                                    name='product'
                                                    onChange={(e) => {
                                                        handleChangeProduct(index, e)
                                                    }}
                                                    onKeyDown={handleKeypress}
                                                    defaultValue={{value: element.product, label: element.name}}
                                                />
                                            </td>
                                            <td className="ps-0" hidden={isQuantity}>
                                                <input type="number" className="form-control form-control-solid" min="1" name="quantity" value={element.quantity} onChange={(e) => handleChangeQuantity(index, e)}/>
                                            </td>
                                            <td hidden={isDisplayTotalAmount}>
                                                <input type="text" className="form-control form-control-solid" name="price" value={element.price} />
                                            </td>
                                            <td>
                                                <input type="number" className="form-control form-control-solid" min="1" name="quantity" value={element.vat} onChange={(e) => handleChangeQuantity(index, e)}/>
                                            </td>
                                            <td hidden={isDiscount}>
                                                {element.discount ?
                                                    <>
                                                        <div className="image-input image-input-outline" data-kt-image-input="true">
                                                            <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                                                   data-kt-image-input-action="change" title="" onClick={() => handleShowDiscount(element.discount, 'edit', index)}
                                                                   data-bs-original-title="Change avatar">
                                                                <i className="bi bi-pencil-fill fs-7"></i>
                                                            </label>
                                                            <span className="form-control form-control-solid">
                                  {element.discount} {element.discountType === '%' ? '%' : ''}
                                </span>
                                                        </div>


                                                    </> :
                                                    <>
                                                        <button type='button' className='btn btn-icon btn-light-primary' onClick={() => handleShowDiscount(null, 'create', index)}>
                                                            <i className='bi bi-plus-lg fs-4'></i>
                                                        </button>
                                                    </>
                                                }

                                            </td>
                                            <td hidden={isDisplayGrossAmount}>
                                                <input type="text" className="form-control form-control-solid" name="total" value={element.total}/>
                                            </td>
                                            <td className="pt-5 d-flex align-items-center">
                                                <a onClick={() => removeFormFields(index)} className="btn btn-sm btn-icon btn-active-color-primary">
                                                    <KTSVG
                                                        path='/media/icons/duotune/abstract/trash.svg'
                                                        className='svg-icon svg-icon-2'
                                                    />
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                                <table className="table g-5 gs-0 mb-0 fw-bolds">
                                    <tfoot>
                                    <tr className="fs-6 fw-bold text-gray-700">
                                        <th>
                                            <a onClick={() => addFormFields()} className="btn btn-primary">
                                                Add Item
                                            </a>
                                        </th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th className="ps-0">
                                        </th>
                                        <th className="text-end">
                                        </th>
                                    </tr>
                                    </tfoot>
                                </table>
                                <table className="table g-5 gs-0 mb-0 fw-bolds">
                                    <tfoot className="border-top border-dashed">
                                    <tr className="fs-6 fw-bold text-gray-700" style={{ borderColor: color }}>
                                        <th>
                                        </th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th className="ps-0">
                                            <div className="d-flex flex-column align-items-start">
                                                <div className="fs-6">Total HT</div>
                                                <div className="fs-6">Total VAT</div>
                                                <div className="fs-6">Total Discount</div>
                                                <div className="fs-6">Total TTC</div>
                                                <div className="fs-6">Timbre Fiscale</div>
                                                <button
                                                    type='button'
                                                    className='btn btn-link py-1'
                                                    style={{ color }}
                                                    onClick={() => handleShowDiscount(null, 'create', 0)}
                                                >
                                                    Discount
                                                </button>
                                            </div>
                                        </th>
                                        <th className="text-end">
                                            <div className="d-flex flex-column align-items-start">
                                                <span data-kt-element="sub-total">{calculateTotalPrice(formValues)}</span>
                                                <span data-kt-element="sub-total">{calculateGrossPrice(formValues)}</span>
                                                <span data-kt-element="sub-total">{calculateTotalDiscount(formValues)}</span>
                                                <span data-kt-element="sub-total">{calculateGrossTotal(formValues)}</span>
                                                <span data-kt-element="sub-total">{officialSeal}</span>
                                                <span data-kt-element="sub-total">0.00</span>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr className="fw-bold text-gray-700">
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th className="fs-4 ps-0">Total</th>
                                        <th className="text-end fs-4 text-nowrap">
                                            <div className="d-flex flex-column align-items-start">
                                                <span data-kt-element="sub-total">{calculateGrossTotal(formValues) + officialSeal}</span>
                                            </div>
                                        </th>
                                    </tr>
                                    </tfoot>
                                </table>
                                <AccountingDiscount show={showDiscount} handleClose={handleCloseDiscount} action={action} selectedDiscount={discount ? discount : null} index={index} handleChangeDiscount={handleChangeDiscount}/>
                            </div>
                        </form>
                        <AccountingPreview
                            customer={customer}
                            date={date}
                            formValues={formValues}
                            isQuantity={isQuantity}
                            isDiscount={isDiscount}
                            isDisplayContact={isDisplayContact}
                            isDisplayTotalAmount={isDisplayTotalAmount}
                            isDisplayGrossAmount={isDisplayGrossAmount}
                            label={label}
                            color={color}
                            pdfRef={pdfRef}
                        />
                    </div>
                    {isLoading &&
                        <div className="overlay-layer rounded bg-dark bg-opacity-5">
                            <div
                                className="spinner-border text-primary"
                                role="status"
                            >
                                <span className="visually-hidden">Loading ...</span>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default AccountingCreate