import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { SubmitHandler, useForm } from 'react-hook-form'
import CustomerActivityCommentService from '../../../../../../../services/Accounting/CustomerActivityCommentService'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

const commentSchema = Yup.object().shape({
  note: Yup.string()
    .required('Ce champs est obligatoire'),
})

type Props = {
  activityId: string,
  handleIsLoading: (isLoading: boolean) => void,
  action: string|undefined,
  comment: IActivityComment|undefined|null,
  setShowComment: (show : boolean) => void
}
type Inputs = {
  note: string,
}

const CommentCreate : React.FC<Props> = ({ activityId, handleIsLoading, comment, action, setShowComment }) => {
  const intl = useIntl()

  const config = {
    resolver: yupResolver(commentSchema),
    defaultValues: {
      note: '',
    },
  }

  const { register, formState: { errors }, reset, handleSubmit, setValue } = useForm<Inputs>(config)

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (action === 'edit') {
      CustomerActivityCommentService.updateComment(data, comment!.uuid.toString()).then(() => {
        handleIsLoading(true)
        reset()
        setShowComment(true)
      })
    } else {
      CustomerActivityCommentService.createComment(data, activityId).then(() => {
        handleIsLoading(true)
        reset()
        setShowComment(true)
      })
    }
  }

  useEffect(() => {
    setValue('note', comment?.note || '')
  }, [comment])

  return (
    <>
      <form className="mt-10" onSubmit={handleSubmit(onSubmit)} noValidate id='create_activity_comment_form'>
        <div className="mb-5">
          <textarea
            {...register('note')}
            placeholder={intl.formatMessage({ id: 'accounting.customerActivity.entity.comment' })}
            className={'form-control form-control-solid form-control-lg col-md-10'}
            autoComplete='off'
          />
          {errors.note && errors.note.message && (
            <div className='fv-plugins-message-container text-danger'>
              <span role='alert'>{errors.note.message}</span>
            </div>
          )}
        </div>
        <div className="d-flex align-content-center">
          <button
            type='button'
            className='btn btn-sm btn-secondary'
            onClick={() => {
              setShowComment(true)
              reset()
            }}
          >
            {intl.formatMessage({ id: 'action.close' })}
          </button>
          <button
            className='btn btn-sm btn-primary ms-5'
            type="submit"
          >
            {intl.formatMessage({ id: 'action.save' })}
          </button>
        </div>

      </form>
    </>
  )
}

export default CommentCreate
