import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageLink, PageTitle } from '../../../../../../../_metronic/layout/core'
import JourneyService from '../../../../../../services/Crm/JourneyService'
import JourneySupportManagerToolbar from './JourneySupportManagerToolbar'
import JourneySupportManagerListing from './components/JourneySupportManagerListing'
import JourneySupportManagerDetails from './components/JourneySupportManagerDetails'

const JourneySupportManagerWrapper : React.FC = () => {
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState(false)
  const [selectedJourney, setSelectedJourney] = useState<IJourney|null>(null)
  const [selectedId, setSelectedId] = useState<string|undefined>()

  const journeySupportBreadCrumbs : Array<PageLink> = [
    {
      title: intl.formatMessage({ id: 'home.label' }),
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: false,
      isActive: false,
    },
  ]

  const handleSelectedId = (uuid: string) => {
    setIsLoading(true)
    setSelectedId(uuid)
  }

  useEffect(() => {
    if (selectedId) {
      JourneyService.getJourney(selectedId).then((response) => {
        setSelectedJourney(response.data.data)
        setIsLoading(false)
      })
    }
  }, [selectedId, isLoading])

  useEffect(() => {}, [selectedJourney])

  return (
    <>
      <PageTitle breadcrumbs={journeySupportBreadCrumbs} pageTitle={intl.formatMessage({ id: 'journey.list' })}>
        <JourneySupportManagerToolbar/>
      </PageTitle>
      <div className='row'>
        <div className='col-xxl-4'>
          <JourneySupportManagerListing selectedId={selectedId} handleSelectedId={handleSelectedId}/>
        </div>

        <div className={`col-xxl-8 px-0 ${isLoading ? 'overlay overlay-block' : ''}`}>

          {selectedJourney ?
            <JourneySupportManagerDetails journey={selectedJourney} isLoading={isLoading} setIsLoading={setIsLoading}/> :
            <div className="d-flex align-items-center justify-content-center">
              <span className="fs-1 fw-bold mt-20">Please select a journey</span>
            </div>
          }

        </div>
      </div>

    </>
  )
}

export default JourneySupportManagerWrapper
