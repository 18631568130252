import React from 'react'
import { useLayout, usePageData } from '../../../../../../_metronic/layout/core'
import clsx from 'clsx'
import { isMobile } from 'react-device-detect'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { useIntl } from 'react-intl'

const EmployeeDetailToolbar: React.FC = () => {
  const { classes } = useLayout()
  const { pageTitle } = usePageData()
  const intl = useIntl()

  return (
    <>
      <div
        id='kt_page_title'
        data-kt-swapper='true'
        data-kt-swapper-mode='prepend'
        data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
        className={clsx('page-title d-flex', classes.pageTitle.join(' '))}
      >
        {isMobile ? (
          <div className='d-flex flex-wrap'>
            {pageTitle && (
              <h1 className='d-flex align-items-center text-dark fw-bolder my-1 fs-3 me-5'>
                {pageTitle}
              </h1>
            )}
          </div>
        ) : (
          <div className="d-flex">
            <div className="d-flex align-items-center position-relative">
              <span className="svg-icon svg-icon-1 position-absolute ms-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                  viewBox="0 0 24 24" fill="none">
                  <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546"
                    height="2" rx="1" transform="rotate(45 17.0365 15.1223)"
                    fill="currentColor"></rect>
                  <path
                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                    fill="currentColor"></path>
                </svg>
              </span>
              <input type="text" data-kt-customer-table-filter="search"
                className="form-control form-control-solid ps-12 h-36px fs-7" placeholder="Rechercher utilisateur"/>
            </div>
            <div className="ps-2">
              <button
                type='button'
                className='btn btn-sm btn-light h-36px fw-400 d-flex align-items'
              >
                <KTSVG
                  path='/media/icons/duotune/abstract/filter.svg'
                  className='svg-icon svg-icon-4'
                />
                                Filter
              </button>
            </div>
          </div>
        )}
      </div>
      <div className='d-flex align-items-center py-1'>
        <button
          type='button'
          className='btn btn-sm btn-success d-flex align-items h-36px'
        >
          <KTSVG
            path='/media/icons/duotune/abstract/add-user.svg'
            className='svg-icon-4 svg-icon-gray-500 me-1'
          />
          {intl.formatMessage({ id: 'employee.create.button' })}
        </button>
      </div>
    </>
  )
}

export default EmployeeDetailToolbar
