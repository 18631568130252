import React, {useEffect, useState} from "react";
import {toAbsoluteUrl} from "../../helpers";

type Props = {
    image: File|null|undefined,
    handleImage: (event: any) => void,
}

const ImageWrapper: React.FC<Props> = ({image, handleImage}) => {
    const [preview, setPreview] = useState<string|null>()

    useEffect(() => {
        if (image) {
            const reader = new FileReader()
            reader.onloadend = () => {
                setPreview(reader.result as string)
            }
            reader.readAsDataURL(image)
        } else {
            setPreview(null)
        }
    }, [image])

    return (
        <div className="image-input image-input-outline" data-kt-image-input="true">
            {preview ? (
                <img
                    className="image-input-wrapper w-175px h-175px"
                    src={preview}
                    alt=''
                />
            ) : (
                <img
                    className="image-input-wrapper w-175px h-175px"
                    src={toAbsoluteUrl('media/avatars/emptyAvatar.png')}
                    alt=''
                />
            )}
            <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                   data-kt-image-input-action="change" data-bs-toggle="tooltip" title=""
                   data-bs-original-title="Change avatar">
                <i className="bi bi-pencil-fill fs-7"></i>
                <input
                    type="file"
                    name="profilePicture"
                    onChange={handleImage}
                />
                <input type="hidden" name="avatar_remove"/>
            </label>
            <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                  data-kt-image-input-action="remove" data-bs-toggle="tooltip" data-kt-initialized="1">
                <i className="bi bi-x fs-2"></i>
            </span>
        </div>
    )
}

export default ImageWrapper