import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import TableHeaderSolid from '../../../../../_metronic/partials/med-table/TableHeaderSolid'
import { KTSVG } from '../../../../../_metronic/helpers'
import DepartmentService from '../../../../services/Hr/DepartmentService'
import Functions from '../../../../../setup/utilities/Functions'
import Symbol from '../../../../../_metronic/partials/common/Symbol'
import DepartmentCreate from './add/DepartmentCreate'
import PaginationBar from '../../../../../_metronic/partials/pagination/PaginationBar'

type Props = {
  show: boolean,
  handleShow: (department:IDepartment|undefined|null, action: string) => void
  handleClose: (isSubmit: boolean) => void
  departmentSelected: IDepartment|null|undefined
  action: string
}

const DepartmentListing: React.FC<Props> = ({ show, handleClose, handleShow, departmentSelected, action }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [departments, setDepartments] = useState<IDepartment[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = React.useState(1)
  const intl = useIntl()
  const handleIsLoading = (loading: boolean) => {
    setIsLoading(loading)
  }
  const headers = [
    {
      minWidth: 'w-250px',
      text: intl.formatMessage({ id: 'department.entity.name' }),
    },
    {
      minWidth: 'w-300px',
      text: intl.formatMessage({ id: 'department.entity.users' }),
    },
    {
      minWidth: 'w-250px',
      text: intl.formatMessage({ id: 'department.objective' }),
    },
    {
      minWidth: 'w-80px',
      text: intl.formatMessage({ id: 'department.entity.active' }),
    },
    {
      minWidth: 'w-100px',
      text: intl.formatMessage({ id: 'actions' }),
    },
  ]

  const changeStatus = (uuid: string|undefined, event: any) => {
    DepartmentService.updateStatus(uuid, {
      'active': event.target.checked,
    }).then(() => {
      setIsLoading(true)
    })
  }

  const deleteDepartment = async (uuid:string|undefined, isActive:boolean|undefined) => {
    if (!isActive) {
      const isConfirmed = await Functions.sweetAlert(
        '<span class="mt-5">Are you sure you want to delete this department</span>',
        'warning',
        true,
        'Yes, delete!',
        'No, cancel',
        'btn btn-danger mt-10 mb-10',
        'btn btn-active-light mt-10 mb-10',
      )
      if (isConfirmed) {
        DepartmentService.deleteDepartment(uuid).then(() => {
          handleIsLoading(true)
        })
      }
    } else {
      await Functions.sweetAlert(
        '<span class="mt-5">You can\'t delete an active department</span>',
        'error',
        false,
        'Ok',
        'No, cancel',
        'btn btn-primary mt-10 mb-10',
        'btn btn-active-light mt-10 mb-10',
      )
    }
  }

  const handleCurrentPage = (page:number) => {
    setIsLoading(true)
    setCurrentPage(page)
  }

  useEffect(() => {
    DepartmentService.getAllDepartments(currentPage, 10).then((response) => {
      setDepartments(response.data.data.list)
      setTotalPages(response.data.data.totalPages)
      setIsLoading(false)
    })
  }, [currentPage, isLoading])

  useEffect(() => {}, [departmentSelected])

  return (
    <>
      <div className={`card mb-5 ${isLoading ? 'overlay overlay-block' : ''}`}>
        <div className={`card-body py-3 ${isLoading ? 'overlay-wrapper' : ''}`}>
          <div className="pt-5">
            <div className="table-responsive">
              <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                <TableHeaderSolid headers={headers} />
                <tbody>
                  {departments && departments.length > 0 ? (
                    departments.map((department, index) => (
                      <tr key={`department_${index}`}>
                        <td className='ps-4'>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span className="text-dark text-hover-primary fw-bold fs-6">
                                {department.shortName}
                              </span>
                              <span className="text-muted fw-500 text-muted d-block fs-7">{Functions.firstLetterUpperCase(department.name)}</span>
                            </div>
                          </div>
                        </td>
                        <td className='pt-8'>
                          <div className='me-2 pt-2 d-flex flex-stack flex-wrap'>
                            <div className="symbol-group symbol-hover">
                              {department.users && department.users.slice(0, 5).map((user, userIndex) => (
                                <Symbol
                                  key={`department_user_${userIndex}`}
                                  picture={user.profilePicture}
                                  picturePath='/admin/uploads/users/'
                                  withText={false}
                                  widthClassName='symbol-35px mb-5'
                                  text={Functions.fullName(user.firstName, user.lastName)}
                                  shortText={user.firstName.charAt(0)}
                                  description={null}
                                  backgroundClass='bg-med-grey'
                                  textClassName='text-inverse-primary'
                                  isCircular={true}
                                  descriptionColor={'text-muted'}
                                  textColor={'text-dark'}
                                />
                              ))}
                              {department.users && department.users.length - 5 > 0 ? (
                                <Symbol
                                  picture={null}
                                  picturePath='/admin/uploads/users/'
                                  withText={false}
                                  widthClassName='symbol-35px mb-5'
                                  text=''
                                  shortText={'+' + (department.users.length - 5).toString()}
                                  description=''
                                  isCircular={true}
                                  backgroundClass='bg-primary'
                                  textClassName='text-inverse-primary'
                                  descriptionColor={'text-muted'}
                                  textColor={'text-dark'}
                                />
                              ) : null}
                            </div>
                          </div>
                        </td>
                        <td className='pt-8'>
                          <div className='d-flex'>
                            <div
                              className='h-5px w-200px bg-light mb-5 mt-3 me-2'
                              data-bs-toggle='tooltip'
                              title='This project completed'
                            >
                              <div
                                className={'bg-success rounded h-5px'}
                                role='progressbar'
                                style={{ width: '75%' }}
                              ></div>
                            </div>
                            <div className='fw-bold text-muted'>75%</div>
                          </div>
                        </td>
                        <td>
                          <div className="form-check form-switch form-check-custom form-check-solid">
                            <input className="form-check-input w-32px h-20px" checked={department.isActive} onChange={() => changeStatus(department.uuid, event)} type="checkbox" id="flexSwitchChecked"/>
                          </div>
                        </td>
                        <td className="text-end">
                          <a href="src/app/modules/hr/department/components/DepartmentListing#" onClick={() => handleShow(department, 'edit')} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 w-32px h-32px">
                            <KTSVG
                              path='/media/icons/duotune/abstract/write.svg'
                              className='svg-icon svg-icon-2'
                            />
                          </a>
                          <a href="src/app/modules/hr/department/components/DepartmentListing#" onClick={() => deleteDepartment(department.uuid, department.isActive)} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 w-32px h-32px">
                            <KTSVG
                              path='/media/icons/duotune/abstract/trash.svg'
                              className='svg-icon svg-icon-2'
                            />
                          </a>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={7}>
                        <div className='d-flex text-center w-100 fs-4 align-content-center justify-content-center mt-5'>
                            No matching records found
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {totalPages > 1 ? (
          <PaginationBar
            totalPages={totalPages}
            currentPage={currentPage}
            handleCurrentPage={handleCurrentPage}
          />
        ) : (<></>)}
        {isLoading &&
            <div className="overlay-layer rounded bg-dark bg-opacity-5">
              <div
                className="spinner-border text-primary"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
        }
      </div>
      <DepartmentCreate
        department={departmentSelected}
        show={show}
        handleClose={handleClose}
        handleIsLoading={handleIsLoading}
        action={action}
      />
    </>
  )
}

export default DepartmentListing
