import React from 'react'
import useWindowDimensions from '../../../../../../../../../_metronic/partials/mobile-check-view/useCheckMobileScreen'
import { KTSVG } from '../../../../../../../../../_metronic/helpers'
import Functions from '../../../../../../../../../setup/utilities/Functions'
import DropDownList from '../../../common/components/DropDownList'
import format from 'date-fns/format'
import SymbolMedicalProfessional from '../../../../../../../../../_metronic/partials/common/SymbolMedicalProfessional'
import { Link } from 'react-router-dom'
import Symbol from '../../../../../../../../../_metronic/partials/common/Symbol'

type Props = {
    timeSlot : ITimeSlot,
    handleShowCall: (calls: ICall[], timeSlot: ITimeSlot|null) => void,
    handleShowSugBug: () => void,
    checkIn: (timeSlot: ITimeSlot) => void,
    isLoading: boolean,
    journey: IJourney|null,
}

const TodoItemSupport : React.FC<Props> = ({ timeSlot, handleShowSugBug, handleShowCall, checkIn, isLoading, journey }) => {
  const isMobile = useWindowDimensions()
  return (
    <>
      <div className={'card card-rounded mt-5'} >
        <div className={'card-body px-2 py-0'}>
          <div className="d-flex">
            <div className="mt-3">
              <div className="d-flex flex-column h-100 pb-2">
                <KTSVG
                  path='/media/icons/duotune/abstract/abs009.svg'
                  className='svg-icon svg-icon-1 svg-icon-gray-300'
                />
                <div className='vr bg-gray-300 opacity-100 w-3px h-100 ms-3'></div>
              </div>
            </div>
            <div className="flex-grow-1 ms-2">
              <div className="d-flex flex-grow-1 mt-2 mb-2">
                <div className="d-flex align-items-start">
                  <Link to={`/doctor/${timeSlot?.todo?.medicalProfessional?.medKey}`}>
                    <SymbolMedicalProfessional
                      picture={timeSlot?.todo?.medicalProfessional?.profilePicture}
                      picturePath='/admin/uploads/users/'
                      withText={true}
                      widthClassName='symbol-40px me-5'
                      text={Functions.fullName(timeSlot?.todo?.medicalProfessional?.firstName, timeSlot?.todo?.medicalProfessional?.lastName)}
                      shortText={Functions.firstLetterUpperCase(timeSlot?.todo?.medicalProfessional?.firstName || '').charAt(0) + Functions.firstLetterUpperCase(timeSlot?.todo?.medicalProfessional?.lastName || '').charAt(0)}
                      description={timeSlot?.todo?.medicalProfessional?.defaultSpeciality || null}
                      backgroundClass=''
                      textClassName='fs-3 fw-400'
                      isCircular={false}
                      descriptionColor={'text-gray-500 fs-7 fw-500'}
                      textColor={'text-dark fs-6 fw-bolder'}
                      withLocation={true}
                      location={timeSlot?.todo?.medicalProfessional?.customer?.location?.name || null}
                      isValid={timeSlot?.todo?.medicalProfessional.isValid || null}
                    />
                  </Link>
                </div>
                {isMobile ?
                  <>
                    <div className="ms-auto mt-2">
                      <div className="d-flex align-items-start">
                        <DropDownList timeSlot={timeSlot} handleShowCall={handleShowCall} handleShowSugBug={handleShowSugBug} active={false}/>
                      </div>
                    </div>
                  </> :
                  <>
                  </>
                }
              </div>
              {timeSlot?.todo?.medicalProfessional?.salesPerson ?
                <div className='d-flex align-items-start mt-2 mb-2'>
                  <span className="text-dark fs-6 fw-semibold mt-1"> By : </span>
                  <div className="ms-3">
                    <Symbol
                      picture={timeSlot?.todo?.medicalProfessional?.salesPerson?.profilePicture || null}
                      picturePath='/admin/uploads/users/'
                      withText={true}
                      widthClassName='symbol-30px ms-2'
                      text={Functions.fullName(timeSlot?.todo?.medicalProfessional?.salesPerson?.firstName || '', timeSlot?.todo?.medicalProfessional?.salesPerson?.lastName || '')}
                      shortText={Functions.firstLetterUpperCase((timeSlot?.todo?.medicalProfessional?.salesPerson?.firstName || '').charAt(0)) + Functions.firstLetterUpperCase((timeSlot?.todo?.medicalProfessional?.salesPerson?.lastName || '').charAt(0))}
                      description={null}
                      backgroundClass=''
                      textClassName='fs-3 fw-400'
                      isCircular={false}
                      descriptionColor={''}
                      textColor={'text-dark fs-6 ms-2'}
                    />
                  </div>
                  {timeSlot?.todo?.medicalProfessional?.isPremium ? <span className="badge badge-warning ms-3 mt-1 text-dark">VIP</span> : <></>}
                  {timeSlot?.todo?.medicalProfessional?.productSubscriptions.map((subscription, index) => (
                    <div key={`product_subscription_${index}`}>
                      <span className="badge badge-success ms-3 mt-1 text-dark">{subscription.name}</span>
                    </div>
                  ))}
                  {timeSlot?.todo?.medicalProfessional?.productSubscriptions.map((subscription, index) => (
                    <div key={`product_subscription_${index}`}>
                      <span className="badge badge-success ms-3 mt-1 text-dark">{subscription.status}</span>
                    </div>
                  ))}
                </div> :
                <></>
              }

            </div>
            {!isMobile ?
              <>
                <div className="ms-auto mt-2">
                  <div className="d-flex align-content-end justify-content-end">
                    <span className='badge bg-med-light-dark h-30px mt-2 p-1'>
                      <div className="d-flex align-items-center justify-content-center">
                        <span className='badge badge-white h-25px'>
                          <KTSVG
                            path='/media/icons/duotune/general/gen013.svg'
                            className='svg-icon svg-icon-1 svg-icon-dark'
                          />
                          <span className="text-dark fs-5 fw-500">{timeSlot.startDate ? format(new Date(timeSlot.startDate), 'HH:mm') : '--:--'}</span>
                        </span>
                      </div>
                    </span>
                    <a className={'btn btn-icon btn-success btn-sm w-40px h-40px rounded-3 ms-2'}>
                      <KTSVG
                        path='/media/icons/duotune/abstract/phone.svg'
                        className='svg-icon svg-icon-2 svg-icon-white'
                      />
                    </a>
                    <a className={'btn btn-icon bg-secondary ms-1 btn-sm w-40px h-40px rounded-3'} onClick={() => handleShowCall(timeSlot.todo.medicalProfessional.calls, timeSlot)}>
                      <KTSVG
                        path='/media/icons/duotune/communication/com007.svg'
                        className='svg-icon svg-icon-1 svg-icon-dark'
                      />
                    </a>
                    <DropDownList timeSlot={timeSlot} handleShowCall={handleShowCall} handleShowSugBug={handleShowSugBug} active={false}/>
                  </div>
                </div>
              </> :
              <>
              </>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default TodoItemSupport
