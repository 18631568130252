import React, { useEffect, useState } from 'react'
import CashFlowService from '../../../../services/Accounting/CashFlowService'
import CashFlowItem from './CashFlowItem'
import CashFlowCreate from './add/CashFlowCreate'

type Props = {
  show: boolean,
  handleClose: (isSubmit: boolean) => void
  cashFlowSelected: ICashFlow|null|undefined
  action: string
}

const CashFlowListing : React.FC<Props> =
    ({
      cashFlowSelected,
      action,
      show,
      handleClose,
    }) => {
      const [isLoading, setIsLoading] = useState<boolean>(true)
      const [cashFlows, setCashFlows] = useState<ICashFlow[]>([])

      const handleIsLoading = (isLoading: boolean) => {
        setIsLoading(isLoading)
      }

      useEffect(() => {
        CashFlowService.getAllCashFlows().then((response) => {
          setCashFlows(response.data.data)
          setIsLoading(false)
        })
      }, [isLoading])

      return (
        <>
          <div className={'row gy-2 g-xl-8'}>
            {cashFlows && cashFlows.map((cashFlow, index) => (
              <div className='col-xxl-4' key={`cash_flow_${index}`}>
                <CashFlowItem
                  cashFlow={cashFlow}
                  isLoading={isLoading}
                  handleIsLoading={handleIsLoading}
                />
              </div>
            ))}
          </div>
          <CashFlowCreate show={show} cashFlow={cashFlowSelected} handleClose={handleClose} handleIsLoading={handleIsLoading} action={'create'} />
        </>
      )
    }

export default CashFlowListing
