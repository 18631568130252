import React, { useEffect, useState } from 'react'
import TableHeaderSolid from '../../../../../_metronic/partials/med-table/TableHeaderSolid'
import { KTSVG } from '../../../../../_metronic/helpers'
import PaginationBar from '../../../../../_metronic/partials/pagination/PaginationBar'
import { useIntl } from 'react-intl'
import PackageService from '../../../../services/Crm/PackageService'
import PackageCreate from './add/PackageCreate'
import Functions from '../../../../../setup/utilities/Functions'

type Props = {
    show: boolean,
    packSelected: IPackage|null|undefined
    handleShow: (pack:IPackage|undefined|null, action: string) => void
    handleClose: (isSubmit: boolean) => void
    action: string
}

const PackageListing : React.FC<Props> = ({ show, handleClose, handleShow, packSelected, action }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [packs, setPacks] = useState<IPackage[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = React.useState(1)
  const intl = useIntl()
  const handleIsLoading = (loading: boolean) => {
    setIsLoading(loading)
  }

  const handleCurrentPage = (page:number) => {
    setIsLoading(true)
    setCurrentPage(page)
  }

  const deletePackage = async (uuid:string|undefined) => {
    const isConfirmed = await Functions.sweetAlert(
      '<span class="mt-5">Are you sure you want to delete this product feature?</span>',
      'warning',
      true,
      'Yes, delete!',
      'No, cancel',
      'btn btn-danger mt-10 mb-10',
      'btn btn-active-light mt-10 mb-10',
    )
    if (isConfirmed) {
      PackageService.deletePackage(uuid).then(() => {
        handleIsLoading(true)
      })
    }
  }

  const headers = [
    {
      minWidth: 'w-500px',
      text: intl.formatMessage({ id: 'name.label' }),
    },
    {
      minWidth: 'w-250px',
      text: intl.formatMessage({ id: 'package.fixedRate.label' }),
    },
    {
      minWidth: 'w-150px',
      text: intl.formatMessage({ id: 'package.discount.label' }),
    },
    {
      minWidth: 'w-250px',
      text: intl.formatMessage({ id: 'package.code.label' }),
    },
    {
      minWidth: 'w-300px',
      text: intl.formatMessage({ id: 'package.isAutoReactivation.label' }),
    },
    {
      minWidth: 'w-300px',
      text: intl.formatMessage({ id: 'isActive.label' }),
    },
    {
      minWidth: 'w-200px',
      text: intl.formatMessage({ id: 'actions' }),
    },
  ]

  const changeIsActiveStatus = (uuid: string|undefined, event: any) => {
    PackageService.updateIsActiveStatus(uuid, {
      'active': event.target.checked,
    }).then(() => {
      setIsLoading(true)
    })
  }

  const changeIsAutoReactivation = (uuid: string|undefined, event: any) => {
    PackageService.updateIsAutoReactivation(uuid, {
      'renew': event.target.checked,
    }).then(() => {
      setIsLoading(true)
    })
  }

  useEffect(() => {
    PackageService.getAllPackages(currentPage).then((response) => {
      setPacks(response.data.data.list)
      setTotalPages(response.data.data.totalPages)
      setIsLoading(false)
    })
  }, [isLoading, currentPage])

  return (
    <>
      <div className={`card mb-5 ${isLoading ? 'overlay overlay-block' : ''}`}>
        <div className={`card-body py-3 ${isLoading ? 'overlay-wrapper' : ''}`}>
          <div>
            <div className={'row pt-5'}>
              <div className="table-responsive">
                <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                  <TableHeaderSolid headers={headers} />
                  <tbody>
                    {packs && packs.map((packItem, index) => (
                      <tr key={`product_${index}`}>
                        <td>
                          <span className="text-muted text-muted">{packItem.name}</span>
                        </td>
                        <td>
                          <span className="text-muted text-muted">{packItem.fixedRate} DT</span>
                        </td>
                        <td>
                          <span className="text-muted text-muted">{packItem.discount} DT</span>
                        </td>
                        <td>
                          <span className="text-muted text-muted">{packItem.packageCode}</span>
                        </td>
                        <td>
                          <div className="form-check form-switch form-check-custom form-check-solid">
                            <input className="form-check-input w-32px h-20px" checked={packItem.isAutoReactivation} onChange={() => changeIsAutoReactivation(packItem.uuid, event)} type="checkbox" id="flexSwitchChecked"/>
                          </div>
                        </td>
                        <td>
                          <div className="form-check form-switch form-check-custom form-check-solid">
                            <input className="form-check-input w-32px h-20px" checked={packItem.isActive} onChange={() => changeIsActiveStatus(packItem.uuid, event)} type="checkbox" id="flexSwitchChecked"/>
                          </div>
                        </td>
                        <td className="text-end">
                          <a href="src/app/modules/crm/package/components/PackageListing#" onClick={() => handleShow(packItem, 'edit')} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 w-32px h-32px">
                            <KTSVG
                              path='/media/icons/duotune/abstract/write.svg'
                              className='svg-icon svg-icon-2'
                            />
                          </a>
                          <a href="src/app/modules/crm/package/components/PackageListing#" onClick={() => deletePackage(packItem.uuid)} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 w-32px h-32px">
                            <KTSVG
                              path='/media/icons/duotune/abstract/trash.svg'
                              className='svg-icon svg-icon-2'
                            />
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {isLoading &&
            <div className="overlay-layer rounded bg-dark bg-opacity-5">
              <div
                className="spinner-border text-primary"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          }
        </div>
        {totalPages > 1 ? (
          <PaginationBar
            totalPages={totalPages}
            currentPage={currentPage}
            handleCurrentPage={handleCurrentPage}
          />
        ) : (<></>)}
      </div>
      <PackageCreate
        pack={packSelected}
        show={show}
        handleClose={handleClose}
        handleIsLoading={handleIsLoading}
        action={action}
      />
    </>
  )
}

export default PackageListing
