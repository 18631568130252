import apiService from '../../../setup/axios/ApiService'

const prefixURL = 'incentive-rule'

const selectRules = () => apiService.get(`${prefixURL}/select`)

const IncentiveRuleService = {
  selectRules,
}

export default IncentiveRuleService
