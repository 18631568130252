import React, { useRef, useState } from 'react'
import { KTSVG } from '../../../../../../../_metronic/helpers'
import { useParams } from 'react-router-dom'
import CallService from '../../../../../../services/Crm/CallService'
import CallCreate from '../../../../call/add/CallCreate'
import SugBugCreate from '../../../../journey/components/display/common/components/SugBugCreate'
import { useInfiniteScroll } from 'use-react-infinite-scroll'
import Symbol from '../../../../../../../_metronic/partials/common/Symbol'
import Functions from '../../../../../../../setup/utilities/Functions'

const LIMIT = 3

const Call: React.FC = () => {
  const { id } = useParams()
  const [showSugBug, setShowSugBug] = useState<boolean>(false)
  const [showCreateCall, setShowCreateCall] = useState<boolean>(false)
  const [isLoadingFirst, setIsLoadingFirst] = useState<boolean>(false)

  const listRef = useRef<HTMLDivElement>(null)
  const [dataObj, setDataObj] = useState<{ list: ICall[], total: number }>({ list: [], total: -1 })
  const [isFetching, setIsFetching] = useState(false)
  async function fetchList() {
    setIsFetching(true)
    // call api to fetch data here
    const response = await CallService.getCallsByMedicalProfessional(id, 1, LIMIT)
    setDataObj((state) => ({
      list: [...state.list, ...response.data.data.list],
      total: response.data.data.total, // set total from api response
    }))
    setTimeout(() => { setIsFetching(false) }, 2000)
  }
  const loadMore = () => {
    if (!isFetching) {
      if (dataObj.total === -1 || dataObj.list.length < dataObj.total) {
        fetchList()
      }
    }
  }

  useInfiniteScroll(listRef, loadMore)

  // Show SugBug Create
  const handleShowSugBug = () => setShowSugBug(true)
  const handleCloseSugBug = () => setShowSugBug(false)

  // Show Call Create
  const handleShowCreateCall = () => setShowCreateCall(true)
  const handleCloseCreateCall = () => setShowCreateCall(false)

  return (
    <>
      <div className={`card mb-5 mb-xl-10 ${isLoadingFirst ? 'overlay overlay-block' : ''}`}>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <KTSVG
              path='/media/icons/duotune/communication/com007.svg'
              className='svg-icon-1 svg-icon-dark'
            />
            <h2 className='fw-bolder ms-3'>Calls</h2>
          </div>
        </div>
        <div className={`card-body ${isLoadingFirst ? 'overlay overlay-block' : ''}`}>
          <div ref={listRef}>
            {dataObj.list && dataObj.list.map((call, index) => (
              <div key={`call_${index}`}>
                <div className="d-flex justify-content-start">
                  <Symbol
                    picture={call.user ? call.user.profilePicture : null}
                    picturePath='/admin/uploads/users/'
                    withText={true}
                    widthClassName='symbol-40px'
                    text={Functions.fullName(call.user ? call.user.firstName : '', call.user ? call.user.lastName : '')}
                    shortText={Functions.firstLetterUpperCase(call.user ? call.user.firstName.charAt(0) : '') + Functions.firstLetterUpperCase(call.user ? call.user.lastName.charAt(0) : '')}
                    description={null}
                    backgroundClass=''
                    textClassName='fs-3 fw-400'
                    isCircular={false}
                    descriptionColor={'text-white fs-6 fw-500'}
                    textColor={'text-dark fs-6 fw-bold'}
                  />
                  <div className="ms-auto mt-2">
                    <span className="badge badge-warning text-dark h-25px"> Qualité</span>
                    <span className="text-dark"> {call.date}</span>
                  </div>
                </div>
                <div className="d-flex justify-content-start mt-3">
                  <span className="text-dark fs-5 fw-400">
                    {call.message}
                  </span>
                </div>
                <div className="d-flex justify-content-start mt-3">
                  {call.tags.length ? <span className="text-dark fs-3 fw-bold mt-1"> Tag : </span> : <></>}
                  <div className="d-flex align-items-start ms-3">
                    {call.tags && call.tags.map((item, index) => (
                      <Symbol key={`tag_${index}`}
                        picture={item.profilePicture}
                        picturePath='/admin/uploads/users/'
                        withText={true}
                        widthClassName='symbol-30px ms-2'
                        text={Functions.fullName(item.firstName, item.lastName)}
                        shortText={Functions.firstLetterUpperCase(item.firstName.charAt(0)) + Functions.firstLetterUpperCase(item.lastName.charAt(0))}
                        description={null}
                        backgroundClass=''
                        textClassName='fs-3 fw-400'
                        isCircular={false}
                        descriptionColor={''}
                        textColor={'text-dark fs-6 ms-2'}
                      />
                    ))}
                  </div>
                </div>

                <div className='separator mt-5 mb-5'></div>
              </div>
            ))}
          </div>
          {isFetching ?
            <div className="overlay-layer rounded bg-dark bg-opacity-5">
              <div
                className="spinner-border text-primary"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div> :
            <></>
          }
        </div>
      </div>
      <div className="d-flex align-content-end justify-content-end">
        <button className="btn btn-sm d-block btn-primary me-5" onClick={handleShowSugBug}>Ajouter Sugbug</button>
        <button className="btn btn-sm d-block btn-success" onClick={handleShowCreateCall}>Ajouter Call</button>
      </div>

      <CallCreate
        show={showCreateCall}
        handleClose={handleCloseCreateCall}
        timeSlot={null}
        checkOut={false}
        setIsLoading={setIsLoadingFirst}
        medKey={id ? id : null}
      />
      <SugBugCreate show={showSugBug} handleClose={handleCloseSugBug} timeSlot={null} medKey={id ? id : null}/>
    </>
  )
}

export default Call
