import React, { useEffect, useState } from 'react'
import TableHeaderSolid from '../../../../../_metronic/partials/med-table/TableHeaderSolid'
import { KTSVG } from '../../../../../_metronic/helpers'
import PaginationBar from '../../../../../_metronic/partials/pagination/PaginationBar'
import { useIntl } from 'react-intl'
import IncentiveService from '../../../../services/Crm/IncentiveService'
import Functions from '../../../../../setup/utilities/Functions'
import IncentiveCreate from './add/IncentiveCreate'

type Props = {
    show: boolean,
    incentiveSelected: IIncentive|null|undefined
    handleShow: (incentive:IIncentive|undefined|null, action: string) => void
    handleClose: (isSubmit: boolean) => void
    action: string
}

const IncentiveListing : React.FC<Props> = ({ show, handleClose, handleShow, incentiveSelected, action }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [incentives, setIncentives] = useState<IIncentive[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = React.useState(1)
  const intl = useIntl()
  const handleIsLoading = (loading: boolean) => {
    setIsLoading(loading)
  }

  const handleCurrentPage = (page:number) => {
    setIsLoading(true)
    setCurrentPage(page)
  }

  const deleteIncentive = async (uuid:string|undefined) => {
    const isConfirmed = await Functions.sweetAlert(
      '<span class="mt-5">Are you sure you want to delete this incentive?</span>',
      'warning',
      true,
      'Yes, delete!',
      'No, cancel',
      'btn btn-danger mt-10 mb-10',
      'btn btn-active-light mt-10 mb-10',
    )
    if (isConfirmed) {
      IncentiveService.deleteIncentive(uuid).then(() => {
        handleIsLoading(true)
      })
    }
  }

  const headers = [
    {
      minWidth: 'w-500px',
      text: intl.formatMessage({ id: 'name.label' }),
    },
    {
      minWidth: 'w-250px',
      text: intl.formatMessage({ id: 'incentive.type.label' }),
    },
    {
      minWidth: 'w-250px',
      text: intl.formatMessage({ id: 'incentive.startDate.label' }),
    },
    {
      minWidth: 'w-250px',
      text: intl.formatMessage({ id: 'incentive.endDate.label' }),
    },
    {
      minWidth: 'w-300px',
      text: intl.formatMessage({ id: 'incentive.amount.label' }),
    },
    {
      minWidth: 'w-300px',
      text: intl.formatMessage({ id: 'incentive.priority.label' }),
    },
    {
      minWidth: 'w-200px',
      text: intl.formatMessage({ id: 'incentive.isForAll.label' }),
    },
    {
      minWidth: 'w-200px',
      text: intl.formatMessage({ id: 'incentive.hasNoEndDate.label' }),
    },
    {
      minWidth: 'w-200px',
      text: intl.formatMessage({ id: 'isEnabled.label' }),
    },
    {
      minWidth: 'w-100px',
      text: intl.formatMessage({ id: 'actions' }),
    },
  ]

  const changeIsActiveStatus = (uuid: string|undefined, event: any) => {
    IncentiveService.updateIsActiveStatus(uuid, {
      'active': event.target.checked,
    }).then(() => {
      setIsLoading(true)
    })
  }
  const changeHasNoEndDate = (uuid: string|undefined, event: any) => {
    IncentiveService.updateHasNoEndDate(uuid, {
      'hasNoEndDate': event.target.checked,
    }).then(() => {
      setIsLoading(true)
    })
  }

  const changeIsForAll = (uuid: string|undefined, event: any) => {
    IncentiveService.updateIsForAll(uuid, {
      'isForAll': event.target.checked,
    }).then(() => {
      setIsLoading(true)
    })
  }

  useEffect(() => {
    IncentiveService.getAllIncentives(currentPage).then((response) => {
      setIncentives(response.data.data.list)
      setTotalPages(response.data.data.totalPages)
      setIsLoading(false)
    })
  }, [isLoading, currentPage])

  return (
    <>
      <div className={`card mb-5 ${isLoading ? 'overlay overlay-block' : ''}`}>
        <div className={`card-body py-3 ${isLoading ? 'overlay-wrapper' : ''}`}>
          <div>
            <div className={'row pt-5'}>
              <div className="table-responsive">
                <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                  <TableHeaderSolid headers={headers} />
                  <tbody>
                    {incentives && incentives.map((incentiveItem, index) => (
                      <tr key={`incentive_${index}`}>
                        <td>
                          <span className="text-muted text-muted">{incentiveItem.name}</span>
                        </td>
                        <td>
                          <span className="text-muted text-muted">{incentiveItem.type}</span>
                        </td>
                        <td>
                          <span className="text-muted text-muted">{incentiveItem.startDate}</span>
                        </td>
                        <td>
                          <span className="text-muted text-muted">{incentiveItem.endDate}</span>
                        </td>
                        <td>
                          <span className="text-muted text-muted">{incentiveItem.amount} DT</span>
                        </td>
                        <td>
                          <span className="text-muted text-muted">{incentiveItem.priority}</span>
                        </td>
                        <td>
                          <div className="form-check form-switch form-check-custom form-check-solid">
                            <input className="form-check-input w-32px h-20px" checked={incentiveItem.isForAll} onChange={() => changeIsForAll(incentiveItem.uuid, event)} type="checkbox" id="flexSwitchChecked"/>
                          </div>
                        </td>
                        <td>
                          <div className="form-check form-switch form-check-custom form-check-solid">
                            <input className="form-check-input w-32px h-20px" checked={incentiveItem.hasNoEndDate} onChange={() => changeHasNoEndDate(incentiveItem.uuid, event)} type="checkbox" id="flexSwitchChecked"/>
                          </div>
                        </td>
                        <td>
                          <div className="form-check form-switch form-check-custom form-check-solid">
                            <input className="form-check-input w-32px h-20px" checked={incentiveItem.isEnabled} onChange={() => changeIsActiveStatus(incentiveItem.uuid, event)} type="checkbox" id="flexSwitchChecked"/>
                          </div>
                        </td>
                        <td className="text-end">
                          <a href="src/app/modules/crm/incentive/components/IncentiveListing#" onClick={() => handleShow(incentiveItem, 'edit')} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 w-32px h-32px">
                            <KTSVG
                              path='/media/icons/duotune/abstract/write.svg'
                              className='svg-icon svg-icon-2'
                            />
                          </a>
                          <a href="src/app/modules/crm/incentive/components/IncentiveListing#" onClick={() => deleteIncentive(incentiveItem.uuid)} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 w-32px h-32px">
                            <KTSVG
                              path='/media/icons/duotune/abstract/trash.svg'
                              className='svg-icon svg-icon-2'
                            />
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {isLoading &&
                        <div className="overlay-layer rounded bg-dark bg-opacity-5">
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
          }
        </div>
        {totalPages > 1 ? (
          <PaginationBar
            totalPages={totalPages}
            currentPage={currentPage}
            handleCurrentPage={handleCurrentPage}
          />
        ) : (<></>)}
      </div>
      <IncentiveCreate
        incentive={incentiveSelected}
        show={show}
        handleClose={handleClose}
        handleIsLoading={handleIsLoading}
        action={action}
      />
    </>
  )
}

export default IncentiveListing
