import { FC, useRef, useEffect, useState } from 'react'
import { shallowEqual, useSelector, connect, useDispatch, ConnectedProps } from 'react-redux'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import * as auth from './AuthRedux'
import { RootState } from '../../../../setup'
import UserService from '../../../services/UserService'
import { WithChildren } from '../../../../_metronic/helpers'
import { useNavigate } from 'react-router-dom'
import keycloakService from '../../../security/KeycloakService'

const mapState = (state: RootState) => ({ auth: state.auth })
const connector = connect(mapState, auth.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const AuthInit: FC<PropsFromRedux & WithChildren> = (props) => {
  const didRequest = useRef(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const accessToken = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual)
  // We should request user by authToken before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          UserService.getUserByToken(accessToken as string).then((response) => {
            dispatch(props.fulfillUser(response.data.data.user))
          }).catch((error) => {
            navigate(`/error/${error.response.status}`)
          })
        }
      } catch (response) {
        console.error(response)
        keycloakService.doLogout()
        navigate('/')
        dispatch(props.logout())
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (accessToken) {
      requestUser()
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
}

export default connector(AuthInit)
