import apiService from '../../../setup/axios/ApiService'

const prefixURL = 'time-slot'

const getTimeSlotsByJourney = (uuid: string|undefined, delay: number, isFinished : number = 0) => apiService.get(`${prefixURL}/journey/${uuid}`, {
  delay,
  isFinished,
})
const checkIn = (uuid: string|undefined, data: any) => apiService.put(`${prefixURL}/${uuid}/check-in`, data)
const checkOut = (uuid: string|undefined, data: any) => apiService.put(`${prefixURL}/${uuid}/check-out`, data)
const createTimeSlot = (data: any) => apiService.post(`${prefixURL}/`, data)
const updateTimeSlot = (uuid: string, data: any) => apiService.put(`${prefixURL}/${uuid}`, data)

const TimeSlotService = {
  getTimeSlotsByJourney,
  checkIn,
  checkOut,
  createTimeSlot,
  updateTimeSlot,
}

export default TimeSlotService
