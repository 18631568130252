import React from 'react'
import FormStepperHeader from "./FormStepperHeader";
import FormStepperBody from "./FormStepperBody";

type Props = {
    steps: { order: string, class: string, title: string, description: string } [],
    forms : { form: JSX.Element}[],
    handleClose: (() => void)|null,
    nextStep : () => void,
    prevStep : () => void,
    currentStep: number,
    stepperRef: any,
    reset: () => void,
}

const FormStepper: React.FC<Props> = ({ steps, forms, handleClose, nextStep, prevStep,currentStep, stepperRef, reset }) => {

  return (
      <div>
        <div ref={stepperRef} className='stepper stepper-links' id='kt_modal_create_stepper'>
          <FormStepperHeader steps={steps} currentStep={currentStep} />
          <FormStepperBody forms={forms} handleClose={handleClose} nextStep={nextStep} prevStep={prevStep} reset={reset} />
        </div>
      </div>
  )
}

export { FormStepper }
