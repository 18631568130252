import React from 'react'
import { PageLink, PageTitle } from '../../../../../../../_metronic/layout/core'
import { useIntl } from 'react-intl'
import JourneySalesDetailsWrapper from '../../display/sales/JourneySalesDetailsWrapper'
import JourneySalesToolbar from './JourneySalesToolbar'


const JourneySalesWrapper: React.FC = () => {
  const intl = useIntl()

  const journeyBreadCrumbs : Array<PageLink> = [
    {
      title: intl.formatMessage({ id: 'home.label' }),
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: false,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={journeyBreadCrumbs} pageTitle={intl.formatMessage({ id: 'journey.list' })}>
        <JourneySalesToolbar />
      </PageTitle>
      <JourneySalesDetailsWrapper />
    </>
  )
}

export default JourneySalesWrapper
