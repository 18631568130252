import React from 'react'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'

type Props = {
  show: boolean,
  bankAccount: IBankAccount|null|undefined,
  handleClose: (isSubmit: boolean) => void,
  handleIsLoading: (isLoading: boolean) => void,
  action: string,
}

const BankAccountCreate : React.FC<Props> =
    ({
      bankAccount,
      handleIsLoading,
      action,
      show, handleClose,
    }) => {
      const intl = useIntl()
      return (
        <Modal show={show} onHide={() => handleClose(false)} dialogClassName={'mw-1000px'}>
          <Modal.Header closeButton>
            <Modal.Title>
              {intl.formatMessage({ id: 'accounting.bank.account.create.modal' })}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          </Modal.Body>
          <Modal.Footer>
            <button
              type='button'
              className='btn btn-sm btn-secondary'
              onClick={async () => handleClose(false)}
            >
              {intl.formatMessage({ id: 'action.close' })}
            </button>
            <button
              type='submit'
              className='btn btn-sm btn-secondary'
            >
              {intl.formatMessage({ id: 'action.save' })}
            </button>
          </Modal.Footer>
        </Modal>
      )
    }

export default BankAccountCreate
