import apiService from '../../../setup/axios/ApiService'

const prefixURL = 'supplier'

const getAllSuppliers = (currentPage:number, limit:number) => apiService.get(`${prefixURL}/`, {
  page: currentPage,
  limit,
})

const createSupplier = (data: any) => apiService.post(`${prefixURL}/`, data)

const updateSupplier = (uuid: string|undefined, data: any) => apiService.put(`${prefixURL}/${uuid}`, data)

const deleteSupplier = (uuid: string|undefined) => apiService.deletes(`${prefixURL}/${uuid}`)

const selectSupplier = (name: string) => apiService.get(`${prefixURL}/select/select-supplier/`, {
  name,
})

const getSupplier = (uuid:string|undefined) => apiService.get(`${prefixURL}/${uuid}`)

const SupplierService = {
  getAllSuppliers,
  createSupplier,
  updateSupplier,
  deleteSupplier,
  selectSupplier,
  getSupplier,
}

export default SupplierService
