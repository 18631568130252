import React from 'react'
import { KTSVG } from '../../../../../../../../_metronic/helpers'
import { useFormContext } from 'react-hook-form'

type Props = {
  bankAccount : IBankAccount|undefined,
}
const DepositValidation : React.FC<Props> = ({ bankAccount }) => {
  const { watch } = useFormContext()

  return (
    <>
      <div className="d-flex flex-column">
        <div className="d-flex flex-column flex-xl-row">
          <div className="py-4 flex-row-fluid">
            <div className="table-responsive">
              <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                <tbody className="fw-semibold text-gray-600">
                  <tr>
                    <td className="text-muted">
                      <div className="d-flex align-items-center">
                        <KTSVG path='/media/icons/duotune/finance/fin005.svg' className='svg-icon svg-icon-2 me-2' />
                        Account Name
                      </div>
                    </td>
                    <td className="fw-bold text-end">{bankAccount?.name}</td>
                  </tr>
                  <tr>
                    <td className="text-muted">
                      <div className="d-flex align-items-center">
                        <KTSVG path='/media/icons/duotune/finance/fin001.svg' className='svg-icon svg-icon-2 me-2' />
                        Bank
                      </div>
                    </td>
                    <td className="fw-bold text-end">
                      {bankAccount?.bank.name}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-muted">
                      <div className="d-flex align-items-center">
                        <KTSVG path='/media/icons/duotune/finance/fin009.svg' className='svg-icon svg-icon-2 me-2' />
                        Bank Code
                      </div>
                    </td>
                    <td className="fw-bold text-end">
                      {bankAccount?.bank.code}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="py-4 flex-row-fluid ms-10">
            <div className="table-responsive">
              <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                <tbody className="fw-semibold text-gray-600">
                  <tr>
                    <td className="text-muted">
                      <div className="d-flex align-items-center">
                        <KTSVG path='/media/icons/duotune/finance/fin009.svg' className='svg-icon svg-icon-2 me-2' />
                        Number
                      </div>
                    </td>
                    <td className="fw-bold text-end">
                      <div className="d-flex align-items-center justify-content-end">
                        {watch('number')}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-muted">
                      <div className="d-flex align-items-center">
                        <span className="svg-icon svg-icon-2 me-2">
                          <svg width="24" height="24"
                            viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3"
                              d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                              fill="currentColor"></path>
                            <path
                              d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                              fill="currentColor"></path>
                          </svg>
                        </span>
                        Amount
                      </div>
                    </td>
                    <td className="fw-bold text-gray-600 text-end">
                      {watch('depositType') === 'cash' ? watch('amount') : watch('depositType') === 'check' ? watch('amountCheck') : watch('amountDraft')}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-muted">
                      <div className="d-flex align-items-center">
                        <KTSVG path='/media/icons/duotune/files/fil002.svg' className='svg-icon svg-icon-2 me-2' />
                        Date
                      </div>
                    </td>
                    <td className="fw-bold text-end">{watch('date')}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DepositValidation
