import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import PaymentService from '../../../../../../services/Accounting/PaymentService'
import TableHeaderSolid from '../../../../../../../_metronic/partials/med-table/TableHeaderSolid'
import Symbol from '../../../../../../../_metronic/partials/common/Symbol'
import Functions from '../../../../../../../setup/utilities/Functions'
import { KTSVG } from '../../../../../../../_metronic/helpers'
import PaginationBar from '../../../../../../../_metronic/partials/pagination/PaginationBar'
import { useIntl } from 'react-intl'


const CashFlowTransactions : React.FC = () => {
  const { id } = useParams()
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [payments, setPayments] = useState<IPayment[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = React.useState(1)

  const headers = [
    {
      minWidth: 'w-200px',
      text: intl.formatMessage({ id: 'accounting.payment.entity.title' }),
    },
    {
      minWidth: 'w-100px',
      text: intl.formatMessage({ id: 'accounting.payment.entity.date' }),
    },
    {
      minWidth: 'w-150px',
      text: intl.formatMessage({ id: 'accounting.payment.entity.document' }),
    },
    {
      minWidth: 'w-150px',
      text: intl.formatMessage({ id: 'accounting.payment.entity.income' }),
    },
    {
      minWidth: 'w-150px',
      text: intl.formatMessage({ id: 'accounting.payment.entity.expense' }),
    },
    {
      minWidth: 'w-50px',
      text: intl.formatMessage({ id: 'accounting.payment.entity.user' }),
    },
    {
      minWidth: 'w-50px',
      text: intl.formatMessage({ id: 'actions' }),
    },
  ]

  const handleCurrentPage = (page:number) => {
    setIsLoading(true)
    setCurrentPage(page)
  }

  useEffect(() => {
    PaymentService.getPaymentsByCashFlow(id, currentPage, 10).then((response) => {
      setPayments(response.data.data.list)
      setTotalPages(response.data.data.totalPages)
      setIsLoading(false)
    })
  }, [isLoading, currentPage])


  return (
    <>
      <div className={`card mb-xl-10 ${isLoading ? 'overlay overlay-block' : ''}`}>
        <div className={`card-body pt-0 ${isLoading ? 'overlay-wrapper' : ''}`}>
          <div className="table-responsive">
            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 mt-10">
              <TableHeaderSolid headers={headers} />
              <tbody>
                {payments && payments.length > 0 ? (
                  payments.map((payment, index) => (
                    <tr key={`payment_${index}`}>
                      <td>
                        <span className="text-dark ms-2">{payment.title}</span>
                      </td>
                      <td>
                        <span className="text-dark">{payment.date}</span>
                      </td>
                      <td>
                        <span className="text-dark">{payment.document}</span>
                      </td>
                      <td>
                        {payment.isIncome ?
                          <span className='text-success fs-3 fw-bold'>
                            + {payment?.amount}
                          </span> :
                          <span className='text-dark'>
                            --
                          </span>
                        }
                      </td>
                      <td>
                        {!payment.isIncome ?
                          <span className='text-danger fs-3 fw-bold'>
                            - {payment?.amount}
                          </span> :
                          <span className='text-dark'>
                            --
                          </span>
                        }
                      </td>
                      <td>
                        <div className='me-2 d-flex flex-stack flex-wrap'>
                          <div className="symbol-group symbol-hover">
                            <Symbol
                              key={`user_${payment.uuid}`}
                              picture={payment?.user?.profilePicture || null}
                              picturePath='/admin/uploads/users/'
                              withText={false}
                              widthClassName='symbol-35px'
                              text={Functions.fullName(payment?.user?.firstName || '', payment?.user?.lastName || '')}
                              shortText={(payment?.user?.firstName || '').charAt(0)}
                              description={null}
                              backgroundClass='bg-primary'
                              textClassName='text-inverse-primary'
                              isCircular={true}
                              descriptionColor={'text-muted'}
                              textColor={'text-dark'}
                            />
                          </div>
                        </div>
                      </td>
                      <td className="text-end">
                        <a href="src/app/modules/accounting/cash-flow/components/display/components/CashFlowTransactions#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 w-32px h-32px">
                          <KTSVG
                            path='/media/icons/duotune/abstract/trash.svg'
                            className='svg-icon svg-icon-2'
                          />
                        </a>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 fs-4 align-content-center justify-content-center mt-5'>
                        {intl.formatMessage({ id: 'action.not.found' })}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {totalPages > 1 ? (
            <PaginationBar
              totalPages={totalPages}
              currentPage={currentPage}
              handleCurrentPage={handleCurrentPage}
            />
          ) : (<></>)}
          {isLoading &&
              <div className="overlay-layer rounded bg-dark bg-opacity-5">
                <div
                  className="spinner-border text-primary"
                  role="status"
                >
                  <span className="visually-hidden">{intl.formatMessage({ id: 'action.loading' })}</span>
                </div>
              </div>
          }
        </div>
      </div>
    </>
  )
}

export default CashFlowTransactions
