import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import CustomerService from '../../../../../../services/Accounting/CustomerService'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import LocationService from '../../../../../../services/Crm/LocationService'
import CountryService from '../../../../../../services/CountryService'
import CustomerCategoryService from '../../../../../../services/Accounting/CustomerCategoryService'
import { useIntl } from 'react-intl'
import Select from 'react-select'

const customerSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Ce champs est obligatoire'),
  lastName: Yup.string()
    .required('Ce champs est obligatoire'),
  displayName: Yup.string()
    .required('Ce champs est obligatoire'),
  taxRegistrationNumber: Yup.string()
    .required('Ce champs est obligatoire'),
  financialCode: Yup.string()
    .required('Ce champs est obligatoire'),
  location: Yup.string()
    .required('Ce champs est obligatoire'),
  country: Yup.string()
    .required('Ce champs est obligatoire'),
  customerCategory: Yup.string()
    .required('Ce champs est obligatoire'),
})


type Inputs = {
  firstName: string,
  lastName: string,
  displayName: string,
  taxRegistrationNumber: string,
  financialCode: string,
  location: string,
  country: string,
  customerCategory: string,
}

const Settings : React.FC = () => {
  const { id } = useParams()
  const colourStyles = {
    control: (styles: any) => ({ ...styles, backgroundColor: '#F5F8FA', border: 0, height: '45px' }),
  }
  const intl = useIntl()
  const [customer, setCustomer] = useState<ICustomer>()

  const config = {
    resolver: yupResolver(customerSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      displayName: '',
      taxRegistrationNumber: '',
      financialCode: '',
      location: '',
      country: '',
      customerCategory: '',
    },
  }
  const { register, handleSubmit, formState: { errors }, setValue } = useForm<Inputs>(config)


  const [countries, setCountries] = useState<{value: string, label: string}[]>([])
  const [locations, setLocations] = useState<{value: string, label: string}[]>([])
  const [customerCategories, setCustomerCategories] = useState<{value: string, label: string}[]>([])

  const handleCountry = (event: any) => {
    setValue('country', event.value)
    LocationService.selectLocationByCountry(event.value).then((response) => {
      setLocations(response.data.data)
    })
  }

  const handleLocation = (event: any) => {
    setValue('location', event.value)
  }
  const handleCustomerCategory = (event: any) => {
    setValue('customerCategory', event.value)
  }

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    CustomerService.updateCustomer(customer?.uuid, data).then(() => {
      window.location.reload()
    })
  }

  useEffect(() => {
    setValue('firstName', customer?.firstName || '')
    setValue('lastName', customer?.lastName || '')
    setValue('displayName', customer?.displayName || '')
    setValue('customerCategory', customer?.customerCategory ? customer?.customerCategory.uuid : '')
    setValue('taxRegistrationNumber', customer?.taxRegistrationNumber || '')
    setValue('financialCode', customer?.financialCode || '')
    setValue('country', customer?.country ? customer?.country.uuid : '')
    setValue('location', customer?.location ? customer?.location.uuid : '')
  }, [customer])

  useEffect(() => {
    CustomerService.getCustomer(id).then((response) => {
      setCustomer(response.data.data)
    })
  }, [])

  useEffect(() => {
    CountryService.selectCountry().then((response) => {
      setCountries(response.data.data)
    })
    CustomerCategoryService.selectCustomerCategory().then((response) => {
      setCustomerCategories(response.data.data)
    })
  }, [])

  return (
    <div className='card mb-5 mb-xl-10'>
      <form className='form w-100' onSubmit={handleSubmit(onSubmit)} noValidate id='edit-customer-form'>
        <div className='card-body border-top p-9'>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label required fw-bold fs-6'>{intl.formatMessage({ id: 'accounting.customer.entity.fullName' })}</label>
            <div className='col-lg-8'>
              <div className='row'>
                <div className='col-lg-6 fv-row'>
                  <input
                    {...register('firstName')}
                    placeholder={intl.formatMessage({ id: 'accounting.customer.entity.firstName' })}
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    type='text'
                    autoComplete='off'
                  />
                  {errors.firstName && errors.firstName.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.firstName.message}</span>
                    </div>
                  )}
                </div>
                <div className='col-lg-6 fv-row'>
                  <input
                    {...register('lastName')}
                    placeholder={intl.formatMessage({ id: 'accounting.customer.entity.lastName' })}
                    className='form-control form-control-solid'
                    autoComplete='off'
                  />
                  {errors.lastName && errors.lastName.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.lastName.message}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label required fw-bold fs-6'>{intl.formatMessage({ id: 'accounting.customer.entity.displayName' })}</label>
            <div className='col-lg-8 fv-row'>
              <input
                {...register('displayName')}
                placeholder={intl.formatMessage({ id: 'accounting.customer.entity.displayName' })}
                className='form-control form-control-solid'
                autoComplete='off'
              />
              {errors.displayName && errors.displayName.message && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{errors.displayName.message}</span>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span className='required'>{intl.formatMessage({ id: 'accounting.customer.entity.customerCategory' })}</span>
            </label>
            <div className='col-lg-8 fv-row'>
              <Select
                options={customerCategories}
                styles={colourStyles}
                value={customer?.customerCategory ? { value: customer?.customerCategory.uuid, label: customer?.customerCategory.name } : { value: null, label: null }}
                onChange={handleCustomerCategory}
              />
              {errors.customerCategory && errors.customerCategory.message && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{errors.customerCategory.message}</span>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span className='required'>{intl.formatMessage({ id: 'accounting.customer.entity.taxRegistrationNumber' })}</span>
            </label>
            <div className='col-lg-8 fv-row'>
              <input
                {...register('taxRegistrationNumber')}
                placeholder={intl.formatMessage({ id: 'accounting.customer.entity.taxRegistrationNumber' })}
                className='form-control form-control-solid'
                autoComplete='off'
              />
              {errors.taxRegistrationNumber && errors.taxRegistrationNumber.message && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{errors.taxRegistrationNumber.message}</span>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span className='required'>{intl.formatMessage({ id: 'accounting.customer.entity.financialCode' })}</span>
            </label>
            <div className='col-lg-8 fv-row'>
              <input
                {...register('financialCode')}
                placeholder={intl.formatMessage({ id: 'accounting.customer.entity.financialCode' })}
                className='form-control form-control-solid'
                autoComplete='off'
              />
              {errors.financialCode && errors.financialCode.message && (
                <div className='fv-plugins-message-container text-danger'>
                  <span role='alert'>{errors.financialCode.message}</span>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span className='required'>{intl.formatMessage({ id: 'accounting.customer.entity.country' })}</span>
            </label>
            <div className='col-lg-8 fv-row'>
              <Select
                options={countries}
                styles={colourStyles}
                value={customer?.country ? { value: customer?.country.uuid, label: customer?.country.name } : { value: null, label: null }}
                onChange={handleCountry}
              />
              {errors.country && errors.country.message && (
                <div className='fv-plugins-message-container text-danger'>
                  <>{errors.country.message}</>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span className='required'>{intl.formatMessage({ id: 'accounting.customer.entity.location' })}</span>
            </label>
            <div className='col-lg-8 fv-row'>
              <Select
                options={locations}
                styles={colourStyles}
                value={customer?.location ? { value: customer?.location.uuid, label: customer?.location.name } : { value: '', label: '' }}
                onChange={handleLocation}
              />
              {errors.location && errors.location.message && (
                <div className='fv-plugins-message-container text-danger'>
                  <>{errors.location.message}</>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary'>
              Enregistrer
          </button>
        </div>
      </form>
    </div>
  )
}

export default Settings
