import React, { useEffect, useState } from 'react'
import format from 'date-fns/format'
import { addDays } from 'date-fns'
import JourneyService from '../../../../../../../../services/Crm/JourneyService'
import CallHistoryHeader from './CallHistoryHeader'
import TimeSlotService from '../../../../../../../../services/Crm/TimeSlotService'
import CallHistoryItem from './CallHistoryItem'
import JourneyHeaderDate from '../../../../../../../../../_metronic/partials/common/JourneyHeaderDate'
import ToDonePatientCall from '../../../../display/support/components/cards/ToDonePatientCall'


const CallHistoryListing : React.FC = () => {
  const [journey, setJourney] = useState<IJourney>()
  const [date, setDate] = useState<string>(format(new Date(), 'MM/dd/yyyy'))
  const [timeSlots, setTimeSlots] = useState<ITimeSlot[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const nextDate = () => {
    setDate(format(addDays(new Date(date), 1), 'MM/dd/yyyy'))
  }

  const previousDate = () => {
    setDate(format(addDays(new Date(date), -1), 'MM/dd/yyyy'))
  }

  useEffect(() => {
    JourneyService.getJourneyOfToday(date).then((response) => {
      setJourney(response.data.data)
      TimeSlotService.getTimeSlotsByJourney(response.data.data.uuid, 0, 1).then((res) => {
        setTimeSlots(res.data.data)
        setIsLoading(false)
      })
    })
  }, [date, isLoading])

  useEffect(() => {}, [date])

  return (
    <>
      <div className="d-flex flex-column">
        <div className={`${isLoading ? 'overlay overlay-block' : ''}`}>
          <div className={`${isLoading ? 'overlay overlay-block' : ''}`}>
            <JourneyHeaderDate date={date} nextDate={nextDate} previousDate={previousDate} />
            <CallHistoryHeader journey={journey ? journey : null} isLoading={isLoading}/>
            <ToDonePatientCall journey={journey ? journey : null}/>
            {timeSlots && timeSlots.filter((timeSlot) => timeSlot.type === 'to_do').map((timeSlot, index) => (
              <div key={`call_history_${index}`}>
                <CallHistoryItem timeSlot={timeSlot}/>
              </div>
            ))}


          </div>
        </div>

      </div>
    </>
  )
}

export default CallHistoryListing
