import React, { useEffect, useState } from 'react'
import { PageLink, PageTitle } from '../../../../../../_metronic/layout/core'
import SalesTeamDisplayToolbar from './SalesTeamDisplayToolbar'
import SalesTeamDisplay from './components/SalesTeamDisplay'
import { useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import SalesTeamService from '../../../../../services/Crm/SalesTeamService'
import Functions from '../../../../../../setup/utilities/Functions'

const salesTeamBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Gestion des equipes des commerciaux',
    path: '',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Equipes des commerciaux',
    path: '/sales-team',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SalesTeamDisplayWrapper: React.FC = () => {
  const [show, setShow] = React.useState(false)
  const { id } = useParams()
  const [salesTeam, setSalesTeam] = useState<ISalesTeam|undefined|null>(null)
  const [action, setAction] = React.useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [managers, setManagers] = useState<IEmployee[]>([])
  const intl = useIntl()

  const handleClose = async (isSubmit: boolean) => {
    if (!isSubmit) {
      const isConfirmed = await Functions.sweetAlert(
        '<span class="mt-5">' + intl.formatMessage({ id: 'action.message' }) + '</span>',
        'warning',
        true,
        intl.formatMessage({ id: 'action.cancel' }),
        intl.formatMessage({ id: 'action.return' }),
        'btn btn-primary mt-10 mb-10',
        'btn btn-active-light mt-10 mb-10',
      )
      if (isConfirmed) {
        setShow(false)
      }
    } else {
      setShow(false)
    }
  }

  const handleIsLoading = (value: boolean) => {
    setIsLoading(value)
  }

  const handleShow = (salesTeam: ISalesTeam|undefined|null, action: string) => {
    setSalesTeam(salesTeam)
    setAction(action)
    setShow(true)
  }

  useEffect(() => {
    SalesTeamService.getSalesTeam(id).then((response) => {
      setSalesTeam(response.data.data)
      setIsLoading(false)
      SalesTeamService.getSalesTeamManagers(response.data.data.uuid).then((res) => {
        setManagers(res.data.data)
      })
    })
  }, [isLoading])

  useEffect(() => {}, [salesTeam])
  return (
    <>
      <PageTitle breadcrumbs={salesTeamBreadCrumbs} pageTitle={intl.formatMessage({ id: 'salesTeam.show' }) + ': ' + salesTeam?.name}>
        <SalesTeamDisplayToolbar/>
      </PageTitle>
      {salesTeam && <SalesTeamDisplay
        handleShow={handleShow}
        handleClose={handleClose}
        show={show}
        action={action}
        salesTeam={salesTeam}
        isLoading={isLoading}
        handleIsLoading={handleIsLoading}
        managers={managers}
      />}
    </>
  )
}

export default SalesTeamDisplayWrapper
