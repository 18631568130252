import React from 'react'
import { Modal } from 'react-bootstrap'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import EmployeeService from '../../../../../../services/Hr/EmployeeService'

type Props = {
  show: boolean,
  handleClose: () => void,
  employee: IEmployee|undefined,
  handleIsLoading: (isLoading: boolean) => void,
}

type Inputs = {
    phone: string,
}

const EmployeeAddPhone: React.FC<Props> = ({
  show,
  handleClose,
  employee,
  handleIsLoading,
}) => {
  const intl = useIntl()
  const { register, handleSubmit, reset } = useForm<Inputs>({
    defaultValues: {
      phone: '',
    },
  })
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    EmployeeService.updatePhone(employee?.uuid, data).then(() => {
      handleIsLoading(true)
      reset()
      handleClose()
    })
  }

  return (
    <Modal show={show} onHide={handleClose} dialogClassName='mw-700px'>
      <Modal.Header closeButton>
        <Modal.Title>{intl.formatMessage({ id: 'employee.add.phone' })}</Modal.Title>
      </Modal.Header>
      <form className='form w-100' onSubmit={handleSubmit(onSubmit)} noValidate id='add-phone-number'>
        <Modal.Body>
          <div className='card card-p-0 shadow-none'>
            <div className='card-body'>
              <div className='row'>
                <div className='col-md-4'>
                  <div className='form-label mt-3 fs-6 text-dark'>{intl.formatMessage({ id: 'employee.entity.phone' })}</div>
                </div>
                <div className='col-md-8'>
                  <input
                    placeholder={intl.formatMessage({ id: 'employee.entity.phone' })}
                    {...register('phone')}
                    className='form-control form-control-solid'
                    type='text'
                    name='phone'
                    autoComplete='off'
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn btn-sm btn-secondary' onClick={handleClose}>
            {intl.formatMessage({ id: 'action.close' })}
          </button>
          <button type='submit' className='btn btn-sm btn-primary'>
            {intl.formatMessage({ id: 'action.save' })}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default EmployeeAddPhone
