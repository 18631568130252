import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import FeatureService from '../../../../../services/Hr/FeatureService'
import PermissionList from './PermissionList'
import ProfileService from '../../../../../services/Hr/ProfileService'
import { useIntl } from 'react-intl'

type ICheckedFeature = {
    uuid: string,
    name: string,
    slug: string,
    parentFeatureUuid: string,
    isParent: boolean,
}

type Props = {
    profile: IProfile|null|undefined,
    show: boolean,
    handleClose: () => void,
    handleFeatureChange: (features: ICheckedFeature[]) => void,
    handleSelectedParentFeature: (uuid: string) => void,
    selectedParentFeature: string,
    watchedFeatures: ICheckedFeature[],
    action: string,
    handleIsLoading: (isLoading: boolean) => void,
}

const ProfilePermission: React.FC<Props> = ({ profile, show, handleClose, handleFeatureChange, selectedParentFeature, handleSelectedParentFeature, watchedFeatures, action, handleIsLoading }) => {
  const [options, setOptions] = useState([])
  const [isOptionsLoading, setIsOptionsLoading] = useState(true)
  const [checkedFeatures, setCheckedFeatures] = useState<Array<IFeature>>([])
  const intl = useIntl()

  const updateCheckedFeatures = (features: IFeature[]) => {
    setCheckedFeatures(features)
  }

  const onChange = (event: any) => {
    handleSelectedParentFeature(event.target.value)
  }

  const onSave = () => {
    if (action !== 'edit') {
      const checked: ICheckedFeature[] = []
      checkedFeatures.forEach((feature: IFeature) => {
        checked.push({
          uuid: feature.uuid,
          name: feature.name,
          slug: feature.slug,
          parentFeatureUuid: feature.parent,
          isParent: feature.isParent,
        })
      })
      handleFeatureChange(checked)
      setCheckedFeatures([])
      handleSelectedParentFeature('')
      handleClose()
    } else {
      ProfileService.updateFeatures(profile?.uuid, checkedFeatures).then((response) => {
        handleIsLoading(true)
        handleClose()
      })
    }
  }

  useEffect(() => {
    FeatureService.selectParentFeatures().then((response) => {
      setOptions(response.data.data)
      setIsOptionsLoading(false)
    })
    if (selectedParentFeature !== '') {
      if (watchedFeatures !== undefined) {
        watchedFeatures.forEach((feature: ICheckedFeature) => {
          checkedFeatures.push({
            uuid: feature.uuid,
            name: feature.name,
            slug: feature.slug,
            parent: feature.parentFeatureUuid ? feature.parentFeatureUuid : '',
            isParent: feature.isParent,
            children: [],
          })
        })
      }
    }
  }, [isOptionsLoading, selectedParentFeature])


  return (
    <Modal show={show} onHide={handleClose} dialogClassName={'mw-500px'} backdropClassName={'custom-z-index'} centered>
      <Modal.Header closeButton>
        <Modal.Title>{intl.formatMessage({ id: 'feature.create' })}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='card card-p-0'>
          <div className='card-body card-scroll h-350px'>
            <div className='fv-row mb-10'>
              <label className='form-label mt-3 fs-6 text-dark'>{intl.formatMessage({ id: 'profile.entity.features' })}</label>
              <select
                className={'form-select form-select-lg form-select-solid'}
                onChange={onChange}
                value={selectedParentFeature}
              >
                <option value={undefined}>{intl.formatMessage({ id: 'profile.entity.features' })}</option>
                {options.map((option: {value: string, label: string}, index) => (
                  <option key={`option_${index}`} value={option.value}>{option.label}</option>
                ))}
              </select>
              {selectedParentFeature !== '' ? (
                <div className='mt-5'>
                  <PermissionList parentFeatureUuid={selectedParentFeature} updateCheckedFeature={updateCheckedFeatures} checkedFeatures={checkedFeatures}/>
                </div>
              ) : (<></>)}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type='button'
          className='btn btn-sm btn-secondary'
          onClick={handleClose}
        >
          {intl.formatMessage({ id: 'action.close' })}
        </button>
        <button
          type='submit'
          className='btn btn-sm btn-primary'
          onClick={onSave}
        >
          {intl.formatMessage({ id: 'action.save' })}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default ProfilePermission
