import React, { useEffect, useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import SalesTeamService from '../../../../../services/Crm/SalesTeamService'
import EmployeeService from '../../../../../services/Hr/EmployeeService'
import Select from 'react-select'

const salesTeamSchema = Yup.object().shape({
  name: Yup.string()
    .required('Ce champs est obligatoire'),
  shortName: Yup.string()
    .notRequired(),
  isActive: Yup.boolean()
    .notRequired(),
  managers: Yup.array()
    .notRequired(),
  employees: Yup.array()
    .notRequired(),
})

type Inputs = {
  name: string,
  shortName: string,
  isActive: boolean,
  managers: string[],
  employees: string[],
}

type Props = {
  salesTeam: ISalesTeam|null|undefined,
  show: boolean,
  handleClose: (isSubmit: boolean) => void,
  handleIsLoading: (isLoading: boolean) => void,
  action: string
}

const SalesTeamCreate: React.FC<Props> = ({
  salesTeam,
  show,
  handleClose,
  handleIsLoading,
  action,
}) => {
  const colourStyles = {
    control: (styles: any) => ({ ...styles, backgroundColor: '#F5F8FA', border: 0 }),
  }
  const intl = useIntl()
  const [optionsManagers, setOptionsManagers] = useState<{value: string, label: string}[] >([])
  const [optionsEmployees, setOptionsEmployees] = useState<{value: string, label: string}[] >([])

  const config = {
    resolver: yupResolver(salesTeamSchema),
    defaultValues: {
      name: '',
      shortName: '',
      isActive: false,
      managers: [],
      employees: [],
    },
  }
  const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm<Inputs>(config)

  const handleManagers = (event: any) => {
    const values: string[] = []
    event.map((e: any) => {
      values.push(e.value)
    })
    setValue('managers', values)
  }

  const handleEmployees = (event: any) => {
    const values: string[] = []
    event.map((e: any) => {
      values.push(e.value)
    })
    setValue('employees', values)
  }

  const resetForm = () => {
    reset()
  }

  useEffect(() => {
    setValue('name', salesTeam?.name || '')
    setValue('shortName', salesTeam?.shortName || '')
    setValue('isActive', salesTeam?.isActive || false)
  }, [salesTeam])

  useEffect(() => {
    EmployeeService.selectEmployees().then((response) => {
      setOptionsManagers(response.data.data)
      setOptionsEmployees(response.data.data)
    })
  }, [])

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (action === 'create') {
      SalesTeamService.createSalesTeam(data).then(() => {
        handleIsLoading(true)
        handleClose(true)
        resetForm()
      })
    } else {
      SalesTeamService.updateSalesTeam(salesTeam?.uuid, data).then(() => {
        handleIsLoading(true)
        handleClose(true)
        resetForm()
      })
    }
  }

  return (
    <Modal show={show} onHide={() => handleClose(false)} dialogClassName={'mw-1000px'}>
      <Modal.Header closeButton>
        <Modal.Title>{intl.formatMessage({ id: 'salesTeam.create' })}</Modal.Title>
      </Modal.Header>
      <form className='form w-100' onSubmit={handleSubmit(onSubmit)} noValidate id='create_sales_team_form'>
        <Modal.Body>
          <div className='card card-p-0'>
            <div className='card-body'>
              <div className='row mb-5'>
                <div className='col-md-2'>
                  <label className='form-label mt-3 fs-6 text-dark required'>{intl.formatMessage({ id: 'name.label' })}</label>
                </div>
                <div className='col-md-10'>
                  <input
                    {...register('name')}
                    placeholder={intl.formatMessage({ id: 'salesTeam.name.placeholder' })}
                    className={`form-control form-control-solid form-control-lg col-md-10 ${errors.name ? 'is-invalid' : ''}`}
                    type='text'
                    name='name'
                    autoComplete='off'
                  />
                  {errors.name && errors.name.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.name.message}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col-md-2'>
                  <label className='form-label mt-3 fs-6 text-dark required'>{intl.formatMessage({ id: 'shortName.label' })}</label>
                </div>
                <div className='col-md-10'>
                  <input
                    {...register('shortName')}
                    placeholder={intl.formatMessage({ id: 'salesTeam.shortName.placeholder' })}
                    className={`form-control form-control-solid form-control-lg col-md-10 ${errors.shortName ? 'is-invalid' : ''}`}
                    type='text'
                    name='shortName'
                    autoComplete='off'
                  />
                  {errors.shortName && errors.shortName.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.shortName.message}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col-md-2'>
                  <div className='form-label mt-3 fs-6 text-dark required'>Active</div>
                </div>
                <div className='col-md-10'>
                  <div className="form-check form-switch form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      {...register('isActive')}
                      id="flexSwitchChecked"
                    />
                  </div>
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col-md-2'>
                  <div className='form-label mt-3 fs-6 text-dark required'>Managers</div>
                </div>
                <div className='col-md-10'>
                  <Select
                    styles={colourStyles}
                    options={optionsManagers}
                    isMulti={true}
                    defaultValue={salesTeam?.managers?.map((manager) => ({ value: manager.uuid, label: manager.firstName + ' ' + manager.lastName }))}
                    isSearchable={true}
                    name='managers'
                    closeMenuOnSelect={true}
                    onChange={handleManagers}
                  />
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col-md-2'>
                  <div className='form-label mt-3 fs-6 text-dark required'>Employees</div>
                </div>
                <div className='col-md-10'>
                  <Select
                    styles={colourStyles}
                    options={optionsEmployees}
                    isMulti={true}
                    defaultValue={salesTeam?.employees?.map((employee) => ({ value: employee.uuid, label: employee.firstName + ' ' + employee.lastName }))}
                    isSearchable={true}
                    name='employees'
                    closeMenuOnSelect={false}
                    onChange={handleEmployees}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn btn-sm btn-secondary'
            onClick={() => {
              handleClose(false)
              resetForm()
            }}
          >
            {intl.formatMessage({ id: 'action.close' })}
          </button>
          <button
            type='submit'
            className='btn btn-sm btn-primary'
          >
            {intl.formatMessage({ id: 'action.save' })}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
export default SalesTeamCreate
