import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import InputMask from 'react-input-mask'
import IncentiveService from '../../../../../services/Crm/IncentiveService'
import LocationService from '../../../../../services/Crm/LocationService'
import ProductService from '../../../../../services/Crm/ProductService'
import DepartmentService from '../../../../../services/Hr/DepartmentService'
import AsyncSelect from 'react-select/async'
import Select from 'react-select'
import IncentiveRuleService from '../../../../../services/Crm/IncenticeRuleService'

type Inputs = {
    name: string,
    type: string,
    startDate: string,
    endDate: string,
    amount: number,
    limitationCount: number,
    limitationPeriod: number,
    priority: number,
    isForAll: boolean,
    hasNoEndDate: boolean,
    isEnabled: boolean,
    locations: string[],
    products: string[],
    departments: string[],
    rules: string[],
}

type Props = {
    show: boolean,
    incentive: IIncentive|null|undefined,
    handleClose: (isSubmit: boolean) => void,
    handleIsLoading: (isLoading: boolean) => void,
    action: string,
}

const IncentiveCreate: React.FC<Props> = ({
  incentive,
  show,
  handleClose,
  handleIsLoading,
  action }) => {
  const intl = useIntl()
  const colourStyles = {
    control: (styles: any) => ({ ...styles, backgroundColor: '#F5F8FA', border: 0 }),
  }
  const [optionsProducts, setOptionsProducts] = useState<{value: string, label: string}[] >([])
  const [optionsDepartments, setOptionsDepartments] = useState<{value: string, label: string}[] >([])
  const [optionsRules, setOptionsRules] = useState<{value: string, label: string}[] >([])
  const incentiveSchema = Yup.object().shape({
    name: Yup.string().required(intl.formatMessage({ id: 'fieldIsRequired' })),
    type: Yup.string().required(intl.formatMessage({ id: 'fieldIsRequired' })),
    startDate: Yup.string().required(intl.formatMessage({ id: 'fieldIsRequired' })),
    endDate: Yup.string().notRequired(),
    amount: Yup.number().min(1, intl.formatMessage({ id: 'NumberGreaterThan0' })),
    priority: Yup.number().max(10, intl.formatMessage({ id: 'NumberLessThan10' })),
    limitationCount: Yup.string().notRequired(),
    limitationPeriod: Yup.string().notRequired(),
    isForAll: Yup.boolean().notRequired(),
    hasNoEndDate: Yup.boolean().notRequired(),
    isEnabled: Yup.boolean().notRequired(),
    locations: Yup.array().notRequired(),
    products: Yup.array().notRequired(),
    departments: Yup.array().notRequired(),
    rules: Yup.array().notRequired(),
  })

  const config = {
    resolver: yupResolver(incentiveSchema),
    defaultValues: {
      name: '',
      type: '',
      startDate: '',
      endDate: '',
      amount: 0,
      priority: 0,
      limitationCount: 0,
      limitationPeriod: 0,
      isForAll: false,
      hasNoEndDate: false,
      isEnabled: false,
      locations: [],
      products: [],
      departments: [],
      rules: [],
    },
  }

  const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm<Inputs>(config)

  useEffect(() => {
    setValue('name', incentive?.name || '')
    setValue('type', incentive?.type || '')
    setValue('startDate', incentive?.startDate || '')
    setValue('endDate', incentive?.endDate || '')
    setValue('amount', incentive?.amount || 0)
    setValue('priority', incentive?.priority || 0)
    setValue('limitationCount', incentive?.limitationCount || 0)
    setValue('limitationPeriod', incentive?.limitationPeriod || 0)
    setValue('isForAll', incentive?.isForAll || false)
    setValue('hasNoEndDate', incentive?.hasNoEndDate || false)
    setValue('isEnabled', incentive?.isEnabled || false)
    if (incentive?.locations) {
      setValue('locations', incentive?.locations.map((location) => location.uuid))
    }
    if (incentive?.products) {
      setValue('products', incentive?.products.map((product) => product.uuid))
    }
    if (incentive?.userGroups) {
      setValue('departments', incentive?.userGroups.map((userGroup) => userGroup.uuid))
    }
    if (incentive?.rules) {
      setValue('rules', incentive?.rules.map((rule) => rule.uuid))
    }
  }, [incentive])

  const handleProducts = (event: any) => {
    const values: string[] = []
    event.map((e: any) => {
      values.push(e.value)
    })
    setValue('products', values)
  }

  const handleRules = (event: any) => {
    const values: string[] = []
    event.map((e: any) => {
      values.push(e.value)
    })
    setValue('rules', values)
  }

  const handleDepartments = (event: any) => {
    const values: string[] = []
    event.map((e: any) => {
      values.push(e.value)
    })
    setValue('departments', values)
  }

  const onChangeLocation = (event : any) => {
    const values: string[] = []
    event.map((e: any) => {
      values.push(e.value)
    })
    setValue('locations', values)
  }

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (action === 'create') {
      IncentiveService.createIncentive(data).then(() => {
        handleIsLoading(true)
        handleClose(true)
        reset()
      })
    } else {
      IncentiveService.updateIncentive(incentive?.uuid, data).then(() => {
        handleIsLoading(true)
        handleClose(true)
        reset()
      })
    }
  }

  const onChangeIncentiveType = (event: any) => {
    setValue('type', event.target.value)
  }

  useEffect(() => {
    ProductService.selectProducts().then((response) => {
      setOptionsProducts(response.data.data)
    })
    DepartmentService.selectDepartment().then((response) => {
      setOptionsDepartments(response.data.data)
    })
    IncentiveRuleService.selectRules().then((response) => {
      setOptionsRules(response.data.data)
    })
  }, [])

  const loadLocations = (inputValue: string, callback: (options: any) => void) => {
    LocationService.selectLocationByName(inputValue).then((response) => {
      callback(response.data.data.map((item: { value: any; label: any; }) => ({ label: item.label, value: item.value })))
    })
  }

  return (
    <Modal show={show} onHide={() => handleClose(false)} dialogClassName={'mw-1000px'}>
      <Modal.Header closeButton>
        <Modal.Title>{intl.formatMessage({ id: 'incentive.create' })}</Modal.Title>
      </Modal.Header>
      <form className='form w-100' onSubmit={handleSubmit(onSubmit)} noValidate id='create-incentive-form'>
        <Modal.Body>
          <div className='card card-p-0'>
            <div className='card-body'>
              <div className='row mb-6'>
                <div className='col-2'>
                  <div className='form-label mt-3 fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'name.label' })}</div>
                </div>
                <div className='col-4'>
                  <input
                    {...register('name')}
                    placeholder='Nom de la prime'
                    className='form-control form-control-solid'
                    type='text'
                    name='name'
                    autoComplete='off'
                  />
                  {errors.name && errors.name.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.name.message}</span>
                    </div>
                  )}
                </div>
                <div className='col-2'>
                  <div className='form-label mt-3 fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'incentive.type.label' })}</div>
                </div>
                <div className='col-4'>
                  <select
                    defaultValue={incentive?.type}
                    className="form-select form-select-solid"
                    onChange={onChangeIncentiveType}
                  >
                    <option value={undefined}>{intl.formatMessage({ id: 'incentive.pleaseSelectIncentiveType' })}</option>
                    <option value="sale">Sale</option>
                    <option value="sale_vip">Sale VIP</option>
                    <option value="renewal">Renewal</option>
                    <option value="renewal_vip">Renewal VIP</option>
                    <option value="rebirth">Rebirth</option>
                    <option value="rebirth_vip">Rebirth VIP</option>
                    <option value="downgrade">Downgrade</option>
                    <option value="collection">Collection</option>
                    <option value="training">Training</option>
                  </select>
                  {errors.type && errors.type.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <>{errors.type.message}</>
                    </div>
                  )}
                </div>
              </div>
              <div className="row mb-6">
                <div className="col-2">
                  <label className="form-label mt-3 fs-6 fw-bolder text-dark required">{intl.formatMessage({ id: 'startDate.label' })}</label>
                </div>
                <div className="col-4">
                  <InputMask
                    className={'form-control form-control-solid'}
                    mask='9999/99/99'
                    {...register('startDate')}
                    placeholder="----/--/--"
                    defaultValue={incentive?.startDate}
                  />
                  {errors.startDate && errors.startDate.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <>{errors.startDate.message}</>
                    </div>
                  )}
                </div>
                <div className="col-2">
                  <label className="form-label mt-3 fs-6 fw-bolder text-dark">{intl.formatMessage({ id: 'incentive.endDate.label' })}</label>
                </div>
                <div className="col-4">
                  <InputMask
                    className={'form-control form-control-solid'}
                    mask='9999/99/99'
                    {...register('endDate')}
                    placeholder="----/--/--"
                    defaultValue={incentive?.endDate}
                  />
                  {errors.endDate && errors.endDate.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <>{errors.endDate.message}</>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <div className='col-2'>
                  <div className='form-label mt-3 fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'incentive.amount.label' })}</div>
                </div>
                <div className='col-4'>
                  <input
                    {...register('amount')}
                    placeholder='montant'
                    className='form-control form-control-solid'
                    type='number'
                    name='amount'
                    autoComplete='off'
                  />
                  {errors.amount && errors.amount.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.amount.message}</span>
                    </div>
                  )}
                </div>
                <div className='col-2'>
                  <div className='form-label mt-3 fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'incentive.priority.label' })}</div>
                </div>
                <div className='col-4'>
                  <input
                    {...register('priority')}
                    placeholder='priorité'
                    className='form-control form-control-solid'
                    type='number'
                    name='priority'
                    autoComplete='off'
                  />
                  {errors.priority && errors.priority.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.priority.message}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <div className='col-2'>
                  <div className='form-label mt-3 fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'incentive.limitationCount.label' })}</div>
                </div>
                <div className='col-4'>
                  <input
                    {...register('limitationCount')}
                    placeholder='limitation Count'
                    className='form-control form-control-solid'
                    type='number'
                    name='limitationCount'
                    autoComplete='off'
                  />
                  {errors.limitationCount && errors.limitationCount.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.limitationCount.message}</span>
                    </div>
                  )}
                </div>
                <div className='col-2'>
                  <div className='form-label mt-3 fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'incentive.limitationPeriod.label' })}</div>
                </div>
                <div className='col-4'>
                  <input
                    {...register('limitationPeriod')}
                    placeholder='limitationPeriod'
                    className='form-control form-control-solid'
                    type='number'
                    name='limitationPeriod'
                    autoComplete='off'
                  />
                  {errors.limitationPeriod && errors.limitationPeriod.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.limitationPeriod.message}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <div className='col-2'>
                  <div className='form-label mt-3 fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'incentive.isForAll.label' })}</div>
                </div>
                <div className='col-4'>
                  <div className="form-check form-switch form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      {...register('isForAll')}
                      id="flexSwitchChecked"
                    />
                  </div>
                </div>
                <div className='col-2'>
                  <div className='form-label mt-3 fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'incentive.hasNoEndDate.label' })}</div>
                </div>
                <div className='col-4'>
                  <div className="form-check form-switch form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      {...register('hasNoEndDate')}
                      id="flexSwitchChecked"
                    />
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <div className='col-2'>
                  <div className='form-label mt-3 fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'isEnabled.label' })}</div>
                </div>
                <div className='col-md-8'>
                  <div className="form-check form-switch form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      {...register('isEnabled')}
                      id="flexSwitchChecked"
                    />
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <div className='col-2'>
                  <div className='form-label mt-3 fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'locations.list' })}</div>
                </div>
                <div className='col-10'>
                  <AsyncSelect
                    styles={colourStyles}
                    isMulti={true}
                    placeholder={'Choose a Location'}
                    defaultValue={incentive?.locations?.map((location) => ({ value: location.uuid, label: location.name }))}
                    loadOptions={loadLocations}
                    onChange={onChangeLocation}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <div className='col-2'>
                  <div className='form-label mt-3 fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'products.list' })}</div>
                </div>
                <div className='col-10'>
                  <Select
                    styles={colourStyles}
                    options={optionsProducts}
                    isMulti={true}
                    defaultValue={incentive?.products?.map((product) => ({ value: product.uuid, label: product.name }))}
                    isSearchable={true}
                    name='products'
                    closeMenuOnSelect={false}
                    onChange={handleProducts}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <div className='col-2'>
                  <div className='form-label mt-3 fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'departments.list' })}</div>
                </div>
                <div className='col-10'>
                  <Select
                    styles={colourStyles}
                    options={optionsDepartments}
                    isMulti={true}
                    defaultValue={incentive?.userGroups?.map((userGroup) => ({ value: userGroup.uuid, label: userGroup.name }))}
                    isSearchable={true}
                    name='departments'
                    closeMenuOnSelect={false}
                    onChange={handleDepartments}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <div className='col-2'>
                  <div className='form-label mt-3 fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'incentive.rules.list' })}</div>
                </div>
                <div className='col-10'>
                  <Select
                    styles={colourStyles}
                    options={optionsRules}
                    isMulti={true}
                    defaultValue={incentive?.rules?.map((rule) => ({ value: rule.uuid, label: rule.name }))}
                    isSearchable={true}
                    name='rules'
                    closeMenuOnSelect={false}
                    onChange={handleRules}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn btn-sm btn-secondary'
            onClick={async () => handleClose(false)}
          >
            {intl.formatMessage({ id: 'action.close' })}
          </button>
          <button
            type='submit'
            className='btn btn-sm btn-secondary'
          >
            {intl.formatMessage({ id: 'action.save' })}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default IncentiveCreate
