import React from 'react'
import JourneyHeaderDate from '../../../../../../../../_metronic/partials/common/JourneyHeaderDate'
import { KTSVG } from '../../../../../../../../_metronic/helpers'

type Props = {
    journey: IJourney|null,
    date: string,
    nextDate: () => void,
    previousDate: () => void,
}
const JourneySupportHeader : React.FC<Props> = ({ journey, previousDate, nextDate, date }) => (
  <>
    <div className="d-flex align-items-start">
      <JourneyHeaderDate date={date} nextDate={nextDate} previousDate={previousDate} />
      <div className="ms-auto">
        <div className="d-flex align-items-start justify-content-start">
          <div className="d-flex flex-column ms-6">
            <div className="d-flex align-items-start justify-content-start">
              <KTSVG
                path='/media/icons/duotune/arrows/arr016.svg'
                className='svg-icon svg-icon-1 svg-icon-dark'
              />
              <KTSVG
                path='/media/icons/duotune/abstract/pharmacist.svg'
                className='svg-icon svg-icon-1 ms-1'
              />
              <span className="text-dark fs-3 fw-bold ms-2"> {journey?.progression || 0}</span>
              <span className="text-muted fs-4 fw-500 ms-2"> /{journey?.total || 0} </span>
            </div>
            <input type="range" className="input-range-support" disabled max={journey?.total ? journey.total : 0} min={0} defaultValue={journey?.progression ? journey.progression : 0}/>
          </div>
          <button
            type='button'
            className='btn btn-icon btn-light-primary btn-sm rounded-circle text-primary h-40px w-40px ms-8 border border-2 border-gray-300'
          >
            <KTSVG
              path='/media/icons/duotune/abstract/plus-primary.svg'
              className='svg-icon svg-icon-1'
            />
          </button>
        </div>

      </div>
    </div>
  </>
)

export default JourneySupportHeader
