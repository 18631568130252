import React from 'react'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import Functions from '../../../../../../setup/utilities/Functions'
import Symbol from '../../../../../../_metronic/partials/common/Symbol'

type Props = {
  medicalProfessional: IMedicalProfessionalHeader|null|undefined
}

const MedicalProfessionalDetailsHeader : React.FC<Props> = ({ medicalProfessional }) => {
  const intl = useIntl()
  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body px-0 pt-5 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3 px-8'>
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-start justify-content-start mb-2'>
                    <Symbol
                      picture={medicalProfessional?.profilePicture || null}
                      picturePath='/admin/uploads/users/'
                      withText={true}
                      widthClassName='symbol-50px me-5'
                      text={Functions.fullName(medicalProfessional?.firstName || '', medicalProfessional?.lastName || '')}
                      shortText={Functions.firstLetterUpperCase(medicalProfessional?.firstName.charAt(0) || '') + Functions.firstLetterUpperCase(medicalProfessional?.lastName.charAt(0) || '')}
                      description={medicalProfessional?.defaultSpeciality || null}
                      backgroundClass=''
                      textClassName='fs-3 fw-400'
                      isCircular={false}
                      descriptionColor={'text-gray-500 fs-7 fw-500'}
                      textColor={'text-dark fs-4 fw-bold'}
                    />
                    <div className="d-flex align-items-center mt-3">
                      {medicalProfessional?.isValid ?
                        <a className="ms-3">

                          <KTSVG
                            path='/media/icons/duotune/general/gen026.svg'
                            className='svg-icon-1 svg-icon-primary'
                          />
                        </a> :
                        <>
                        </>
                      }
                      {medicalProfessional?.isPremium ? <span className="badge badge-warning ms-3 text-dark">VIP</span> : <></>}

                      <a className="ms-3">
                        <KTSVG
                          path='/media/flags/tunisia-rounded.svg'
                          className='svg-icon-1'
                        />
                      </a>
                    </div>
                  </div>
                </div>

                <div className='d-flex'>
                  <button
                    className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2'
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen053.svg'
                      className='svg-icon svg-icon-2'
                    />
                  </button>
                  <button
                    className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2'
                  >
                    <KTSVG
                      path='/media/logos/logo-med.svg'
                      className='svg-icon svg-icon-2'
                    />
                  </button>
                  <button
                    className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2'
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen005.svg'
                      className='svg-icon svg-icon-2'
                    />
                  </button>
                  <button
                    className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2'
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen021.svg'
                      className='svg-icon svg-icon-2'
                    />
                  </button>
                  <button
                    className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2'
                  >
                    <KTSVG
                      path='/media/icons/duotune/communication/com011.svg'
                      className='svg-icon svg-icon-2'
                    />
                  </button>
                  <button
                    className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2'
                  >
                    <KTSVG
                      path='/media/icons/duotune/communication/com009.svg'
                      className='svg-icon svg-icon-2'
                    />
                  </button>
                </div>
              </div>

              <div className='d-flex flex-wrap flex-stack mt-2'>
                <div className='d-flex flex-wrap flex-stack'>
                  {medicalProfessional?.productSubscriptions.map((subscription, index) => (
                    <div key={`product_subscription_${index}`}>
                      <div className='border border-dashed border-success rounded bg-light-success min-w-250px h-40px py-3 px-5 me-3 mt-2'>
                        <div className='d-flex align-items-start'>
                          <div className='fs-6 fw-bold'>{subscription.name}</div>
                          <div className='fs-6 text-dark ms-3'>{subscription.subscriptionDate} / {subscription.expirationDate}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-5'>
                  <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                    <span className='fw-bold fs-6 text-gray-400'>Profile Check-List (PCL)</span>
                    <span className='fw-bolder fs-6'>{medicalProfessional?.pclProgression}%</span>
                  </div>
                  <div className='h-5px mx-3 w-100 bg-light mb-3'>
                    <div
                      className='bg-primary rounded h-5px'
                      role='progressbar'
                      style={{ width: `${medicalProfessional?.pclProgression}%` }}
                    ></div>
                  </div>
                </div>
              </div>

              {medicalProfessional?.salesPerson ? <>
                <div className='d-flex align-items-start mt-5'>
                  <span className="text-dark fs-5 fw-bold mt-1"> By : </span>
                  <div className="ms-3">
                    <Symbol
                      picture={medicalProfessional?.salesPerson?.profilePicture || null}
                      picturePath='/admin/uploads/users/'
                      withText={true}
                      widthClassName='symbol-30px ms-2'
                      text={Functions.fullName(medicalProfessional?.salesPerson?.firstName || '', medicalProfessional?.salesPerson?.lastName || '')}
                      shortText={Functions.firstLetterUpperCase((medicalProfessional?.salesPerson?.firstName || '').charAt(0)) + Functions.firstLetterUpperCase((medicalProfessional?.salesPerson?.lastName || '').charAt(0))}
                      description={null}
                      backgroundClass=''
                      textClassName='fs-3 fw-400'
                      isCircular={false}
                      descriptionColor={''}
                      textColor={'text-dark fs-6 ms-2'}
                    />
                  </div>
                </div>
              </> : <></>}

            </div>
          </div>

          <div className='separator d-flex flex-center mt-5'></div>

          <div className='d-flex overflow-auto px-8 h-50px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item'>
                <Link
                  className = {`nav-link fw-500 text-active-primary me-6 ${location.pathname === `/doctor/${medicalProfessional?.medKey}/info` ? 'active' : ''}`}
                  to={`/doctor/${medicalProfessional?.medKey}/info`}
                >
                  {intl.formatMessage({ id: 'medical.professional.info' })}
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className = {`nav-link fw-500 text-active-primary me-6 ${location.pathname === `/doctor/${medicalProfessional?.medKey}/pcl` ? 'active' : ''}`}

                  to={`/doctor/${medicalProfessional?.medKey}/pcl`}
                >
                  {intl.formatMessage({ id: 'medical.professional.pcl' })}
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className = {`nav-link fw-500 text-active-primary me-6 ${location.pathname === `/doctor/${medicalProfessional?.medKey}/stat` ? 'active' : ''}`}

                  to={`/doctor/${medicalProfessional?.medKey}/stat`}
                >
                  {intl.formatMessage({ id: 'medical.professional.stat' })}
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className = {`nav-link fw-500 text-active-primary me-6 ${location.pathname === `/doctor/${medicalProfessional?.medKey}/pay` ? 'active' : ''}`}

                  to={`/doctor/${medicalProfessional?.medKey}/pay`}
                >
                  {intl.formatMessage({ id: 'medical.professional.pay' })}
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className = {`nav-link fw-500 text-active-primary me-6 ${location.pathname === `/doctor/${medicalProfessional?.medKey}/pack` ? 'active' : ''}`}

                  to={`/doctor/${medicalProfessional?.medKey}/pack`}
                >
                  {intl.formatMessage({ id: 'medical.professional.pack' })}
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className = {`nav-link fw-500 text-active-primary me-6 ${location.pathname === `/doctor/${medicalProfessional?.medKey}/call` ? 'active' : ''}`}

                  to={`/doctor/${medicalProfessional?.medKey}/call`}
                >
                  {intl.formatMessage({ id: 'medical.professional.call' })}
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className = {`nav-link fw-500 text-active-primary me-6 ${location.pathname === `/doctor/${medicalProfessional?.medKey}/activities` ? 'active' : ''}`}

                  to={`/doctor/${medicalProfessional?.medKey}/activities`}
                >
                  {intl.formatMessage({ id: 'medical.professional.activities' })}
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className = {`nav-link fw-500 text-active-primary me-6 ${location.pathname === `/doctor/${medicalProfessional?.medKey}/smart-list` ? 'active' : ''}`}

                  to={`/doctor/${medicalProfessional?.medKey}/smart-list`}
                >
                  {intl.formatMessage({ id: 'medical.professional.smart.list' })}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default MedicalProfessionalDetailsHeader
