import apiService from '../../../setup/axios/ApiService'

const prefixURL = 'bank'

const selectBank = () => apiService.get(`${prefixURL}/select`)

const BankService = {
  selectBank,
}

export default BankService
