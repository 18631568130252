import Keycloak from 'keycloak-js'
import store from '../../setup/redux/Store'
import * as auth from '../../app/modules/auth/redux/AuthRedux'

const _kc = new Keycloak('/keycloak.json')
const initKeycloak = (onAuthenticatedCallback: Function) => {
  _kc.init({
    onLoad: 'check-sso',
  })
    .then((authenticated) => {
      if (!authenticated) {
        _kc.login()
      }
      store.dispatch(auth.actions.login(_kc.token as string, _kc.refreshToken as string))
      onAuthenticatedCallback()
    })
    .catch(console.error)
}


const doLogin = _kc.login

const doLogout = () => _kc.logout()


const getToken = () => _kc.token

const updateToken = (successCallback: Function) => _kc.updateToken(5)
  .then(() => {
    store.dispatch(auth.actions.refreshToken(_kc.token as string, _kc.refreshToken as string))
    successCallback()
    return Promise.resolve(_kc.token)
  })
  .catch(() => {
    _kc.login()
    return Promise.reject(new Error('Failed to refresh token'))
  })

const getUsername = () => _kc.tokenParsed?.preferred_username

const hasRole = (roles: any) => roles.some((role: string) => _kc.hasRealmRole(role))

const KeyCloakService = {
  initKeycloak,
  doLogin,
  doLogout,
  getToken,
  updateToken,
  getUsername,
  hasRole,
}

export default KeyCloakService
