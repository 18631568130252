import React, {useState} from 'react'
import {KTSVG} from '../../helpers'
import format from 'date-fns/format'
import {DateRangePicker} from 'react-date-range'
import {useIntl} from 'react-intl'

const months = [
    { label: 'January', value: 1 },
    { label: 'February', value: 2 },
    { label: 'March', value: 3 },
    { label: 'April', value: 4 },
    { label: 'May', value: 5 },
    { label: 'June', value: 6 },
    { label: 'July', value: 7 },
    { label: 'August', value: 8 },
    { label: 'September', value: 9 },
    { label: 'October', value: 10 },
    { label: 'November', value: 11 },
    { label: 'December', value: 12 },
]

type Props = {
    handleChangeName: (event: React.ChangeEvent<HTMLInputElement>) => void,
    handleChangeMonthButtons: (event: any) => void,
    handleChangeRange: (event: any) => void,
    handleChangeStatus: (event: any) => void,
    handleChangeType: (event: any) => void,
    range: { startDate: Date, endDate: Date, key: string, }[],
    statusOptions : {value: string, label: string}[]
}

const MedAccountingFilter : React.FC<Props> = ({
    range,
    handleChangeMonthButtons,
    handleChangeRange,
    handleChangeType,
    handleChangeStatus,
    handleChangeName,
    statusOptions
    }) => {
    const intl = useIntl()
    const [showCollapse, setShowCollapse] = useState<boolean>(false)

    const handleShowCollapse = () => {
        if (showCollapse) {
            setShowCollapse(false)
        } else {
            setShowCollapse(true)
        }
    }
    return(
        <>
            <div className='card mb-3'>
                <div className='card-body'>
                    <div className='d-flex align-items-center'>
                        <div className='position-relative w-md-400px me-md-2'>
                            <KTSVG
                                path='/media/icons/duotune/general/gen021.svg'
                                className='svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6'
                            />
                            <input
                                type='text'
                                className='form-control form-control-solid ps-10'
                                name='search'
                                placeholder='Search'
                                onChange={handleChangeName}
                            />
                        </div>
                        <div className='d-flex align-items-center'>
                            <button type='submit' className='btn btn-primary me-5'>
                                Search
                            </button>
                            <a
                                id='kt_horizontal_search_advanced_link'
                                className='btn-link'
                                data-bs-toggle='collapse'
                                onClick={handleShowCollapse}
                                aria-expanded='true'
                            >
                                {showCollapse ?
                                    intl.formatMessage({ id: 'accounting.invoice.filter.hide' }) :
                                    intl.formatMessage({ id: 'accounting.invoice.filter.show' })}
                            </a>
                        </div>
                    </div>
                    <div className={`collapse ${showCollapse ? 'show' : ''}`} id='kt_advanced_search_form'>
                        <div className='separator separator-dashed mt-9 mb-6'></div>
                        <div className='row g-8 mb-8'>
                            <label className='fs-6 form-label fw-bold text-dark'>Select Month</label>
                            <div className='nav-group nav-group-fluid d-flex overflow-auto'>
                                {months.map((month, index) => (
                                    <label key={`month_${index}`}>
                                        <input type='radio' className='btn-check' name='type' value={month.value} onClick={handleChangeMonthButtons}/>
                                        <span className='btn btn-sm btn-color-muted btn-active btn-active-primary fw-bold px-4'>
                      {month.label}
                    </span>
                                    </label>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='d-flex flex-row flex-stack mb-3' data-select2-id='select2-data-141-cur3'>
                <div className='d-flex flex-wrap align-items-center'>
                    <h3 className='fw-bold me-5 my-1'>Range :</h3>
                    <button
                        type='button'
                        className='btn btn-sm btn-secondary ms-7'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                    >
                        {`${format(range[0].startDate, 'MM/dd/yyyy')} to ${format(range[0].endDate, 'MM/dd/yyyy')}`}
                    </button>
                    <div
                        className='menu menu-sub menu-sub-dropdown ms-10 w-325px w-md-325px'
                        data-kt-menu='true'
                    >
                        <DateRangePicker
                            onChange={handleChangeRange}
                            editableDateInputs={false}
                            moveRangeOnFirstSelection={false}
                            ranges={range}
                            months={2}
                            direction='horizontal'
                        />
                    </div>
                </div>
                <div className='d-flex flex-wrap my-1'>
                    <ul className='nav nav-pills mb-2 mb-sm-0' role='tablist'>
                        <li className='nav-item m-0'>
                            <button
                                type='button'
                                className='btn btn-sm btn-primary'
                                data-kt-menu-trigger='click'
                                data-kt-menu-placement='bottom-end'
                            >
                                <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
                                Filter
                            </button>
                            <div
                                className='menu menu-sub menu-sub-dropdown w-300px w-md-325px'
                                data-kt-menu='true'
                            >
                                <div className='px-7 py-5'>
                                    <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
                                </div>
                                <div className='separator border-gray-200'></div>
                                <div className='px-7 py-5'>
                                    <div className='mb-10'>
                                        <label className='form-label fs-6 fw-bold'>Status</label>
                                        <select
                                            className='form-select form-select-solid'
                                            onChange={handleChangeStatus}
                                        >
                                            {statusOptions.map((item, index) =>
                                                <option key={`option_${index}`} value={item.value}>{item.label}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className='mb-10'>
                                        <div className='d-flex align-items-center'>
                                            <label className='form-label fs-6 fw-bold'>Doctors</label>
                                            <div className="form-check form-switch form-check-custom form-check-solid ms-3 mb-2">
                                                <input className="form-check-input w-32px h-20px" type="checkbox" onChange={handleChangeType}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-end'>
                                        <button
                                            type='button'
                                            className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                                            data-kt-menu-dismiss='true'
                                            data-kt-user-table-filter='reset'
                                        >
                                            Reset
                                        </button>
                                        <button
                                            type='button'
                                            className='btn btn-primary fw-bold px-6'
                                            data-kt-menu-dismiss='true'
                                            data-kt-user-table-filter='filter'
                                        >
                                            Apply
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default MedAccountingFilter