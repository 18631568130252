import React, { useEffect, useRef, useState } from 'react'
import { StepperComponent } from '../../../../../../../_metronic/assets/ts/components'
import DepositType from './bank-deposit/DepositType'
import DepositAmount from './bank-deposit/DepositAmount'
import DepositValidation from './bank-deposit/DepositValidation'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import CashFlowService from '../../../../../../services/Accounting/CashFlowService'
import PaymentService from '../../../../../../services/Accounting/PaymentService'
import format from 'date-fns/format'
import BankAccountService from '../../../../../../services/Accounting/BankAccountService'

const depositSchema = Yup.object().shape({
  depositType: Yup.string()
    .required(),
  paymentMethod: Yup.string()
    .notRequired(),
  number: Yup.string()
    .required(),
  date: Yup.string()
    .required(),
  destinationAccount: Yup.string()
    .required(),
  amount: Yup.string()
    .notRequired(),
  amountCheck: Yup.string()
    .notRequired(),
  amountDraft: Yup.string()
    .notRequired(),
  note: Yup.string()
    .notRequired(),
})

type Inputs = {
  depositType: string,
  paymentMethod: string,
  number: string,
  date: string,
  destinationAccount: string,
  amount: string,
  amountCheck: string,
  amountDraft: string,
  checks: string[],
  drafts: string[],
  note: string,
}


const BankDeposit : React.FC = () => {
  const { id } = useParams()
  const intl = useIntl()
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [cashFlow, setCashFlow] = useState<ICashFlow>()
  const [bankAccount, setBankAccount] = useState<IBankAccount>()
  const [payments, setPayments] = useState<IPayment[]>()

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const config = {
    resolver: yupResolver(depositSchema),
    defaultValues: {
      depositType: '',
      paymentMethod: '',
      number: '',
      date: '',
      destinationAccount: '',
      amount: '',
      amountCheck: '',
      amountDraft: '',
      checks: [],
      drafts: [],
      note: '',
    },
  }

  const methods = useForm<Inputs>(config)

  const watchDepositType = methods.watch('depositType')

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()
  }

  const nextStep = async () => {
    if (!stepper.current) {
      return
    }

    let isValid : boolean = false

    switch (stepper.current?.currentStepIndex) {
    case 1:
      isValid = await methods.trigger(['depositType', 'destinationAccount', 'number'])
      if (watchDepositType === 'check') {
        PaymentService.getAllPayments(
          1,
          10,
          'check',
          1,
          false,
            id!.toString(),
            '',
            format(new Date(new Date().getFullYear(), 0, 1), 'yyyy/MM/dd'),
            format(new Date(new Date().getFullYear(), 0, 365), 'yyyy/MM/dd'),
        ).then((response) => {
          setPayments(response.data.data.list)
        })
      } else if (watchDepositType === 'draft') {
        PaymentService.getAllPayments(
          1,
          10,
          'draft',
          1,
          false,
            id!.toString(),
            '',
            format(new Date(new Date().getFullYear(), 0, 1), 'yyyy/MM/dd'),
            format(new Date(new Date().getFullYear(), 0, 365), 'yyyy/MM/dd'),
        ).then((response) => {
          setPayments(response.data.data.list)
        })
      }
      break
    case 2:
      isValid = true
      break
    case 3:
      isValid = true
      break
    }

    if (isValid) {
      stepper.current.goNext()
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  useEffect(() => {
    CashFlowService.getCashFlow(id).then((response) => {
      setCashFlow(response.data.data)
    })
  }, [])

  const onChangeType = (e: React.ChangeEvent<any>, uuid : string) => {
    methods.setValue('paymentMethod', uuid)
    methods.setValue('depositType', e.target.value)
  }

  const onChangeDestinationAccount = (event: any) => {
    methods.setValue('destinationAccount', event.value)
    BankAccountService.getBankAccount(event.value).then((response) => {
      setBankAccount(response.data.data)
    })
  }

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    PaymentService.bankDeposit(data, id!.toString()).then(() => {
      window.location.reload()
    })
  }

  return (
    <div
      ref={stepperRef}
      className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
      id='kt_bank_deposit_stepper'
    >
      <div className={'card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-175px w-xxl-175px py-20 me-9 h-400px'}>
        <div className={'card-body px-5'}>
          <div className='stepper-nav'>
            <div className='stepper-item current' data-kt-stepper-element='nav'>
              <div className='stepper-wrapper'>
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>1</span>
                </div>
                <div className='stepper-label'>
                  <span className='stepper-title fs-6'>Deposit type</span>
                </div>
              </div>
              <div className='stepper-line h-40px'></div>
            </div>
            <div className='stepper-item' data-kt-stepper-element='nav'>
              <div className='stepper-wrapper'>
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>2</span>
                </div>
                <div className='stepper-label'>
                  <span className='stepper-title fs-6'>Deposit amount</span>
                </div>
              </div>
              <div className='stepper-line h-30px'></div>
            </div>
            <div className='stepper-item' data-kt-stepper-element='nav'>
              <div className='stepper-wrapper'>
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>3</span>
                </div>
                <div className='stepper-label'>
                  <span className='stepper-title fs-6'>Deposit Validation</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='d-flex flex-row-fluid flex-center bg-body rounded h-400px'>
        <FormProvider {...methods} >
          <form noValidate className='w-100 px-5' id='kt_modal_create_app_form' onSubmit={methods.handleSubmit(onSubmit)}>
            <div className='current' data-kt-stepper-element='content'>
              <DepositType onChangeType={onChangeType} watchDepositType={watchDepositType} cashFlow={cashFlow} onChangeDestinationAccount={onChangeDestinationAccount}/>
            </div>

            <div data-kt-stepper-element='content'>
              <DepositAmount watchDepositType={watchDepositType} cashFlow={cashFlow} payments={payments}/>
            </div>

            <div data-kt-stepper-element='content'>
              <DepositValidation bankAccount={bankAccount}/>
            </div>
            <div className='d-flex justify-content-end pt-10'>
              <div className='mr-2'>
                <button
                  type='button'
                  className='btn btn-lg btn-light py-4 pe-8 me-3'
                  data-kt-stepper-action='previous'
                  onClick={prevStep}
                >
                  {intl.formatMessage({ id: 'action.previous' })}
                </button>
              </div>
              <div>
                <button
                  type='submit'
                  className='btn btn-lg btn-primary py-4 ps-8 me-3'
                  data-kt-stepper-action='submit'
                >
                  Submit{' '}
                </button>

                <button
                  type='button'
                  className='btn btn-lg btn-primary py-4 ps-8 me-3'
                  data-kt-stepper-action='next'
                  onClick={nextStep}
                >
                  {intl.formatMessage({ id: 'action.next' })}
                </button>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  )
}

export default BankDeposit
