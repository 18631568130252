import React, { useCallback, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { DateTimePicker } from '../../../../../../../../_metronic/partials/date-time-picker'
import JourneyService from '../../../../../../../services/Crm/JourneyService'

type Props = {
  journey: IJourney|null,
  show: boolean,
  handleClose: () => void,
  planningTimeSlots : {uuid: string, profilePicture: string, firstName: string, lastName: string, speciality: string|null, isChecked: boolean }[],
  setIsLoading: (isLoading: boolean) => void
}

type Inputs = {
  timeSlots: {uuid: string, profilePicture: string, firstName: string, lastName: string, speciality: string, isChecked: boolean }[],
  date: string,
  journey: string,
  endDate: string,
}

const Planning : React.FC<Props> = ({ show, handleClose, planningTimeSlots, journey, setIsLoading }) => {
  const intl = useIntl()
  const [date, setDate] = useState<string>('')
  const config = {
    defaultValues: {
      timeSlots: [],
      date: '',
      journey: '',
      endDate: '',
    },
  }
  const { handleSubmit, setValue } = useForm<Inputs>(config)

  const onHandleChangeDate = useCallback(
    (date: any) => {
      setDate(date)
      setValue('date', date)
    },
    [setDate],
  )

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const planning = {
      date: data.date,
      endDate: new Date(),
      timeSlots: planningTimeSlots,
    }
    JourneyService.endJourney(journey!.uuid, planning).then(() => {
      if (planningTimeSlots.length !== 0) {
        handleClose()
        setIsLoading(true)
      }
    })
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} dialogClassName={'mw-500px'}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="text-dark fs-2 fw-bold">Ajout à ma todo list</span>
          </Modal.Title>
        </Modal.Header>
        <form className='form w-100' onSubmit={handleSubmit(onSubmit)} noValidate id='create-department-form'>
          <Modal.Body className="h-400px">
            <DateTimePicker
              format="YYYY/MM/DD"
              name={'bir_sey_tarihi'}
              id={'bir_sey_tarihi'}
              placeholder="Select Date"
              locale="tr"
              specialDays={[
                { day: 1, month: 1, memo: 'New Years' },
                { day: 14, month: 2, memo: 'Valentine`s Day' },
              ]}
              ariaLabelledby={'aria - labelledby'}
              label={'Hello'}
              date={date}
              onHandleChangeDate={onHandleChangeDate}
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              type='button'
              className='btn btn-sm btn-secondary'
              onClick={handleClose}
            >
              {intl.formatMessage({ id: 'action.close' })}
            </button>
            <button
              type='submit'
              className='btn btn-sm btn-secondary'
            >
              {intl.formatMessage({ id: 'action.save' })}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export default Planning
