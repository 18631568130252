import React from 'react'
import { useIntl } from 'react-intl'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import EstimateToolbar from './components/EstimateToolbar'
import EstimateFilter from './components/EstimateFilter'

const EstimateWrapper : React.FC = () => {
  const intl = useIntl()
  const teamBreadCrumbs: Array<PageLink> = [
    {
      title: 'Home',
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: 'accounting.estimate.menu' }),
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={teamBreadCrumbs} pageTitle={intl.formatMessage({ id: 'accounting.estimate.list' })}>
        <EstimateToolbar/>
      </PageTitle>
      <EstimateFilter />
    </>
  )
}

export default EstimateWrapper
