import React from 'react'
import { toAbsoluteUrlWithSubdomain } from '../../helpers'

type Props = {
  picture: string|null,
  picturePath: string|null,
  withText: boolean,
  widthClassName: string,
  text: string,
  shortText: string,
  description: string|null,
  backgroundClass: string,
  textClassName: string,
  textColor: string,
  descriptionColor: string,
  isCircular: boolean,
}

const Symbol: React.FC<Props> = ({
  picture,
  withText,
  widthClassName,
  text,
  shortText,
  description,
  backgroundClass,
  textClassName,
  textColor,
  descriptionColor,
  isCircular,
  picturePath,
}) => (
  <div className='d-flex align-items-center'>
    <div className={`symbol ${isCircular ? 'symbol-circle' : ''} ${widthClassName}`}>
      {picture !== null && picture !== undefined ? (
        <img
          src={`data:image/png;base64,${picture}`}
          alt={text}/>
      ) : (
        <div
          className={`symbol-label fw-bold ${backgroundClass} ${textClassName}`}>
          {shortText}
        </div>
      )}
    </div>
    {withText ? (
      <div className='d-flex justify-content-start flex-column'>
        <span className={`${textColor}`}>
          {text}
        </span>
        {description !== null ? (
          <span className={`${descriptionColor}`}>
            {description}
          </span>
        ) : (<></>)}
      </div>
    ) : (<></>)}
  </div>
)

export default Symbol
