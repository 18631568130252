import apiService from '../../../setup/axios/ApiService'

const prefixURL = 'employee/equipment'

const getOwnedEmployeeEquipments = (currentPage:number, limit:number = 10) => apiService.get(`${prefixURL}/owned`, {
  page: currentPage,
  limit,
})

const EmployeeEquipmentService = {
  getOwnedEmployeeEquipments,
}

export default EmployeeEquipmentService
