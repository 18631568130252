import React, { useEffect } from 'react'
import { KTSVG } from '../../../../../../../_metronic/helpers'
import { useStopwatch } from 'react-timer-hook'
import format from 'date-fns/format'

type Props = {
  journey: IJourney|null,
  timeSlot: ITimeSlot|null,
  setIsLoading: (isLoading: boolean) => void,
}

const Delay : React.FC<Props> = ({ journey, timeSlot, setIsLoading }) => {
  const offsetTimestamp = new Date()
  const outsetTimestamp = new Date(timeSlot?.startDate || '')


  function diff_minutes(dt2 : Date, dt1: Date) {
    const diff = (dt2.getTime() - dt1.getTime()) / 1000
    return Math.abs(Math.round(diff))
  }

  offsetTimestamp.setSeconds(offsetTimestamp.getSeconds() + diff_minutes(offsetTimestamp, outsetTimestamp))

  const {
    seconds,
    minutes,
    hours,
  } = useStopwatch({ autoStart: true, offsetTimestamp })

  useEffect(() => {}, [minutes])

  return (
    <>
      <div className={`card card-rounded mt-5 ${hours < 1 && minutes < 20 ? 'bg-med-light-orange' : hours < 1 && minutes < 45 ? 'bg-med-light-red' : 'bg-med-light-purple'}`}>
        <div className='card-body px-2 py-0'>
          <div className="d-flex">
            <div className="mt-2">
              <div className="d-flex flex-column h-100 pb-2">
                <KTSVG
                  path='/media/icons/duotune/abstract/abs009.svg'
                  className={`svg-icon svg-icon-1 ${hours < 1 && minutes < 20 ? 'med-color-orange' : hours < 1 && minutes < 45 ? 'med-color-red' : 'med-color-purple'}`}
                />
                <div className={`vr opacity-100 w-3px h-100 ms-3 ${hours < 1 && minutes < 20 ? 'bg-med-orange' : hours < 1 && minutes < 45 ? 'bg-med-red' : 'bg-med-purple'}`}
                ></div>
              </div>
            </div>
            <div className="mt-3 ms-3 flex-grow-1">
              <div className="d-flex align-items-start">
                <span className={`badge w-80px fs-8 fw-bold ${hours < 1 && minutes < 20 ? 'bg-med-orange' : hours < 1 && minutes < 45 ? 'bg-med-red' : 'bg-med-purple'}`}>
                  <i className={`fa-solid fa-pause ${hours < 1 && minutes < 45 ? 'text-dark' : 'text-white'}`}></i>
                  <span className={`fs-4 fw-500 ms-2 ${hours < 1 && minutes < 45 ? 'text-dark' : 'text-white'}`}>
                    <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
                  </span>
                </span>
                <span className="text-dark mt-1 ms-2"> - </span>
                <div className="d-flex align-items-center justify-content-center ms-2">
                  <span className='badge badge-white w-70px'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen013.svg'
                      className='svg-icon-2 svg-icon-dark'
                    />
                    <span className="text-dark fs-6 ms-1 fw-500">{timeSlot?.startDate ? format(new Date(timeSlot.startDate), 'HH:mm') : '--:--'}</span>
                  </span>
                  <span className='badge badge-white w-70px ms-2'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen013.svg'
                      className='svg-icon-2 svg-icon-dark'
                    />
                    <span className="text-dark fs-6 ms-1 fw-500">{timeSlot?.endDate ? format(new Date(timeSlot.endDate), 'HH:mm') : '--:--'}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Delay
