import React from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import SymbolMedicalProfessional from '../../../../../_metronic/partials/common/SymbolMedicalProfessional'
import Functions from '../../../../../setup/utilities/Functions'
import Symbol from '../../../../../_metronic/partials/common/Symbol'

type Props = {
  medicalProfessional: IMedicalProfessional
}
const MedicalProfessionalItem : React.FC<Props> = ({ medicalProfessional }) => (
  <>
    <div className='card card-rounded mt-5'>
      <div className='card-body px-4 py-0'>
        <div className="d-flex">
          <div className="flex-grow-1 mt-3 mb-3">
            <div className="d-flex">
              <Link to={`/doctor/${medicalProfessional?.medKey}`}>
                <SymbolMedicalProfessional
                  picture={medicalProfessional?.profilePicture}
                  picturePath='/admin/uploads/users/'
                  withText={true}
                  widthClassName='symbol-40px me-5'
                  text={Functions.fullName(medicalProfessional?.firstName || '', medicalProfessional?.lastName || '')}
                  shortText={Functions.firstLetterUpperCase(medicalProfessional?.firstName || '').charAt(0) + Functions.firstLetterUpperCase(medicalProfessional?.lastName || '').charAt(0)}
                  description={medicalProfessional?.defaultSpeciality || 'without speciality'}
                  backgroundClass=''
                  textClassName='fs-3 fw-400'
                  isCircular={false}
                  descriptionColor={'text-gray-500 fs-7 fw-500'}
                  textColor={'text-dark fs-6 fw-bolder'}
                  withLocation={true}
                  location={medicalProfessional?.customer?.location?.name || null}
                  isValid={medicalProfessional.isValid || null}
                />
              </Link>

            </div>
            {medicalProfessional?.salesPerson ? <>
              <div className='d-flex align-items-start mt-5'>
                <span className="text-dark fs-5 fw-bold mt-1"> By : </span>
                <div className="ms-3">
                  <Symbol
                    picture={medicalProfessional?.salesPerson?.profilePicture || null}
                    picturePath='/admin/uploads/users/'
                    withText={true}
                    widthClassName='symbol-30px ms-2'
                    text={Functions.fullName(medicalProfessional?.salesPerson?.firstName || '', medicalProfessional?.salesPerson?.lastName || '')}
                    shortText={Functions.firstLetterUpperCase((medicalProfessional?.salesPerson?.firstName || '').charAt(0)) + Functions.firstLetterUpperCase((medicalProfessional?.salesPerson?.lastName || '').charAt(0))}
                    description={null}
                    backgroundClass=''
                    textClassName='fs-3 fw-400'
                    isCircular={false}
                    descriptionColor={''}
                    textColor={'text-dark fs-6 ms-2'}
                  />
                </div>
              </div>
            </> : <></>}
          </div>
          <div className="ms-auto mt-5">
            <div className='d-flex'>

              <button
                className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2 rounded-3'
              >
                <KTSVG
                  path='/media/logos/logo-med.svg'
                  className='svg-icon svg-icon-2'
                />
              </button>
              <button
                className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2 rounded-3'
              >
                <KTSVG
                  path='/media/icons/duotune/general/gen005.svg'
                  className='svg-icon svg-icon-2'
                />
              </button>
              <button
                className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2 rounded-3'
              >
                <KTSVG
                  path='/media/icons/duotune/general/gen021.svg'
                  className='svg-icon svg-icon-2'
                />
              </button>
              <button
                className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2 rounded-3'
              >
                <KTSVG
                  path='/media/icons/duotune/communication/com011.svg'
                  className='svg-icon svg-icon-2'
                />
              </button>
              <button
                className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2 rounded-3'
              >
                <KTSVG
                  path='/media/icons/duotune/communication/com009.svg'
                  className='svg-icon svg-icon-2'
                />
              </button>
              <button className='btn btn-icon btn-success btn-sm rounded-3 me-2'>
                <KTSVG
                  path='/media/icons/duotune/abstract/phone.svg'
                  className='svg-icon svg-icon-2 svg-icon-white'
                />
              </button>
              <button
                className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2 rounded-3'
              >
                <KTSVG
                  path='/media/icons/duotune/general/gen053.svg'
                  className='svg-icon svg-icon-2'
                />
              </button>
            </div>


          </div>
        </div>
      </div>
    </div>
  </>
)

export default MedicalProfessionalItem
