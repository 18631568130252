// @flow
import cx from 'classnames';
import moment from 'moment';
import * as React from 'react';
import { memo, useCallback, useEffect, useState } from 'react';

type PropsTr = {
    days: any,
    week: any
}

const Tr = React.forwardRef<HTMLTableRowElement,PropsTr>(({ days, week }, ref) => (
    <tr ref={ref} data-row={week} data-week={week}>
        {days}
    </tr>
));

type PropsTd = {
    day: any,
    month: any
    year: any
    data: any
    bg: any
    onClick: (e: any) => void
}

const Td = React.forwardRef<HTMLTableDataCellElement, PropsTd>(
    ({ day, month, year, data, bg, onClick }, ref) => {
        const handleClick = useCallback(
            (e: any) => {
                onClick(data);

                return e.preventDefault() && e.stopPropagation();
            },
            [onClick, data]
        );
        return (
            <td
                ref={ref}
                data-day={day}
                data-month={month}
                data-year={year}
                className="p-2 text-end position-relative"
            >
        <span
            data-day={day}
            data-month={month}
            data-year={year}
            className={bg}
            onClick={handleClick}
        >
          {day}
        </span>
            </td>
        );
    }
);

const TdBade = React.forwardRef<HTMLTableDataCellElement, PropsTd>(
    ({ day, month, year, data, bg, onClick }, ref) => {
        const handleClick = useCallback(
            (e: any) => {
                onClick(data);

                return e.preventDefault() && e.stopPropagation();
            },
            [onClick, data]
        );
        return (
            <td
                ref={ref}
                data-day={day}
                data-month={month}
                data-year={year}
                className="text-end position-relative me-5"
            >
        <span
            data-day={day}
            data-month={month}
            data-year={year}
            className={bg}
            onClick={handleClick}
        >
          {day}
            <span
                className="position-absolute top-1 start-75 translate-middle badge badge-circle badge-danger"
                style={{ width: '1rem', height: '1rem' }}
            >
            &nbsp;
          </span>
        </span>
            </td>
        );
    }
);

type Props = {
    date: any,
    specialDays: any
    onChangeDay: (e: any) => void
}

const Days = memo<Props>(
    ({
         date,
         specialDays,
         onChangeDay,
     })=> {
        const [changedDate, changeDate] = useState('');

        let week = 1;

        let now = moment(date);

        let prevMonth = now.clone().subtract(1, 'month');

        let day = prevMonth.daysInMonth();

        prevMonth.date(day).startOf('week');

        let nextMonth = moment(prevMonth).clone().add(42, 'd');

        let weeks = [];
        let days = [];

        const onHandleClick = useCallback(
            (e: any) => {
                changeDate(e);
                return onChangeDay(e);
            },
            [date]
        );

        while (prevMonth?.isBefore(nextMonth)) {
            let classes = {
                'text-dark': false,
                'text-hover-dark bg-hover-light': true,
                'text-bold bg-light-warning text-warning':prevMonth.isSame(changedDate, 'day'),
                'text-white text-inverse-info indigo-800':
                    specialDays.findIndex(
                        (d: any) =>
                            d.day === prevMonth.date() && d.month === prevMonth.month() + 1
                    ) >= 0,

                'text-bold bg-light-primary text-primary bg-hover-primary text-hover-inverse-primary':
                    prevMonth.isSame(moment(), 'day'),

                'text-muted text-hover-primary bg-hover-light':
                    prevMonth.year() < date.year() ||
                    (prevMonth.year() === date.year() &&
                        prevMonth.month() < date.month()) ||
                    prevMonth.year() > date.year() ||
                    (prevMonth.year() === date.year() &&
                        prevMonth.month() > date.month()),

                'me-8 rounded-2 fs-6': true,
            };

            if (
                specialDays.findIndex(
                    (d: any) => d.day === prevMonth.date() && d.month === prevMonth.month() + 1
                ) >= 0
            ) {
                days.push(
                    <TdBade
                        day={prevMonth.date()}
                        year={prevMonth.year()}
                        month={prevMonth.month()}
                        key={prevMonth.month() + '-' + prevMonth.date()}
                        data={prevMonth.clone()}
                        bg={cx(classes)}
                        onClick={onHandleClick}
                    />
                );
            } else {
                days.push(
                    <Td
                        day={prevMonth.date()}
                        year={prevMonth.year()}
                        month={prevMonth.month()}
                        key={prevMonth.month() + '-' + prevMonth.date()}
                        bg={cx(classes)}
                        data={prevMonth.clone()}
                        onClick={onHandleClick}
                    />
                );
            }

            if (prevMonth.weekday() === moment().endOf('week').weekday()) {
                weeks.push(
                    <Tr
                        days={days}
                        key={prevMonth.month() + '-' + prevMonth.date()}
                        week={prevMonth.month() + '-' + week}
                    />
                );

                days = [];

                week++;
            }

            prevMonth.add(1, 'd');
        }

        return <>{weeks && weeks}</>;
    }
);

type PropsCalendar = {
    locale: string,
    format: string,
    onChange: (e: any) => void
    specialDays: any,
}

export const Calender = React.forwardRef<HTMLDivElement, PropsCalendar>((props, ref) => {
    const { locale = 'tr', format = 'DD/MM/YYYY', onChange } = props;

    const [date, changeDate] = useState(moment(new Date()));
    const [displayDate, setDisplayDate] = useState<string>('');
    const [dayLabels, setDayLabels] = useState([]);
    const [months, setMonthLabel] = useState<string[]>([]);

    useEffect(() => {
        if (locale) {
            moment().locale(locale);

            let daysLabelShort : any = moment.weekdaysShort(false);
            let monthsLabel = moment.months();

            setDayLabels(daysLabelShort);
            setMonthLabel(monthsLabel);
        }
    }, [locale]);

    useEffect(() => {
        if (date) {
            let now = moment(date); //, 'YYYY/MM/DD', locale);

            setDisplayDate(now.format('MMMM YYYY'));
        }

        return () => {};
    }, [date]);

    const prevHandleClick = useCallback(
        (e: any) => {
            e.preventDefault() && e.stopPropagation();

            let prevDate = moment(date).add(-1, 'month');

            changeDate(prevDate);
        },
        [date]
    );

    const nextHandleClick = useCallback(
        (e: any) => {
            e.preventDefault() && e.stopPropagation();

            let prevDate = moment(date).add(1, 'month');

            changeDate(prevDate);
        },
        [date]
    );

    const onHandleYearChange = useCallback(
        (e: any) => {
            e.preventDefault() && e.stopPropagation();

            if (e.target.value < 0) {
                return;
            }

            let prevDate = moment(date).set('year', e.target.value);

            changeDate(prevDate);
        },
        [date]
    );

    const onHandleMonthChange = useCallback(
        (e: any) => {
            e.preventDefault() && e.stopPropagation();
            let prevDate = moment(date).set('month', e.target.value);

            changeDate(prevDate);
        },
        [date]
    );

    const onHandleSelectDate = useCallback(
        (e: any) => {
            let _format = format;

            if (format.length === 0) _format = 'DD/MM/YYYY';

            onChange(e.format(_format));
        },
        [format, onChange]
    );

    useEffect(() => {}, [date]);

    // @ts-ignore
    const Navigation = ({ displayDate }) => {
        return (
            <tr>
                <th>
                    <a
                        href="#"
                        onClick={prevHandleClick}
                        className="text-dark fw-bolder text-hover-primary bg-hover-light p-3 rounded-2 fs-6"
                    >
                        <i className="bi bi-chevron-left" />
                    </a>
                </th>
                <th className="text-center">
                    <span className="fw-bolder text-dark">{displayDate}</span>
                </th>
                <th>
                    <a
                        href="#"
                        onClick={nextHandleClick}
                        className="text-dark fw-bolder text-hover-primary bg-hover-light p-3 rounded-2 fs-6"
                    >
                        <i className="bi bi-chevron-right" />
                    </a>
                </th>
            </tr>
        );
    };

    const DaysLabel = ({ labels = [] }) => {
        const label = (day: string, i: number) => (
            <th key={i} data-label={day}>
                <span className="fw-bolder text-dark">{day}</span>
            </th>
        );
        return <tr>{labels.map(label)}</tr>;
    };

    return (
        <>
            <div ref={ref} className="card card-xxl-stretch mb-xl-3 p-0 border-0">
                <div className="card-body border-0 px-1">
                    <div className="d-flex align-items-start justify-content-start">
                        <h3 className="card-title align-items-start flex-column mt-3"><span className="card-label fw-bolder text-dark">{displayDate}</span></h3>
                        <div className="ms-auto">
                            <div className="d-flex align-content-end">
                                <input
                                    type="number"
                                    className="form-control form-control-solid form-control-sm w-100px"
                                    value={date.format('YYYY')}
                                    aria-label="year"
                                    onChange={onHandleYearChange}
                                />
                                <select
                                    name="month"
                                    id="month"
                                    value={date.months()}
                                    defaultValue={date.months()}
                                    className="form-select form-select-solid form-select-sm ms-2"
                                    onChange={onHandleMonthChange}
                                >
                                    {months.map((o, i) => (
                                        <option key={i} value={i}>
                                            {o}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-center mt-4 mb-4">
                        <Navigation displayDate={displayDate} />
                    </div>
                    <table className="table table-condensed table-bordered">
                        <thead>
                        {/*<Navigation displayDate={displayDate} />*/}
                        <DaysLabel labels={dayLabels} />
                        </thead>
                        <tbody>
                        <Days
                            date={date}
                            onChangeDay={onHandleSelectDate}
                            specialDays={props.specialDays}
                        />
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
});
