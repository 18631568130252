import React, { useState } from 'react'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { useIntl } from 'react-intl'
import TeamToolbar from './TeamToolbar'
import TeamListing from './components/TeamListing'

const TeamWrapper: React.FC = () => {
  const intl = useIntl()
  const [show, setShow] = useState(false)
  const [team, setTeam] = useState<ITeam|undefined|null>(null)
  const [action, setAction] = useState<string>('')
  const handleShow = (team: ITeam|undefined|null, action: string) => {
    setTeam(team)
    setAction(action)
    setShow(true)
  }
  const handleClose = () => setShow(false)

  const teamBreadCrumbs: Array<PageLink> = [
    {
      title: 'Home',
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: 'employee.management.menu' }),
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  return (
    <>
      <PageTitle breadcrumbs={teamBreadCrumbs} pageTitle={intl.formatMessage({ id: 'team.list' })}>
        <TeamToolbar handleShow={handleShow}/>
      </PageTitle>
      <TeamListing show={show} handleClose={handleClose} handleShow={handleShow} teamSelected={team} action={action}/>
    </>
  )
}

export default TeamWrapper
