import apiService from '../../../setup/axios/ApiService'

const prefixURL = 'sug-bug'

const createSugBug = (data: any) => apiService.post(`${prefixURL}/`, data)

const SugBugService = {
  createSugBug,
}

export default SugBugService
