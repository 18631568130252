import apiService from '../../../setup/axios/ApiService'

const prefixURL = 'profile'

const getAllProfiles = () => apiService.get(`${prefixURL}/`)

const createProfile = (data: any) => apiService.post(`${prefixURL}/`, data)

const getProfile = (uuid:string|undefined) => apiService.get(`${prefixURL}/${uuid}`)

const updateStatus = (uuid:string|undefined, data: any) => apiService.put(`${prefixURL}/${uuid}/active`, data)

const getProfileFeatures = (uuid:string|undefined) => apiService.get(`${prefixURL}/${uuid}/features`)

const getProfileUsers = (uuid:string|undefined, currentPage:number, limit:number) => apiService.get(`${prefixURL}/${uuid}/users`, {
  page: currentPage,
  limit,
})

const updateProfile = (uuid:string|undefined, data: any) => apiService.put(`${prefixURL}/${uuid}/edit`, data)

const updateFeatures = (uuid:string|undefined, data: any) => apiService.put(`${prefixURL}/${uuid}/edit/features`, {
  features: data,
})

const deleteProfile = (uuid:string|undefined) => apiService.deletes(`${prefixURL}/${uuid}/delete`)

const selectProfile = () => apiService.get(`${prefixURL}/select-profile`)

const ProfileService = {
  getAllProfiles,
  createProfile,
  updateStatus,
  getProfile,
  getProfileFeatures,
  updateProfile,
  updateFeatures,
  getProfileUsers,
  deleteProfile,
  selectProfile,
}

export default ProfileService
