import { useState, useEffect } from 'react'

function getWindowDimensions() {
  const { innerWidth: width } = window
  return {
    width,
  }
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
  const [isMobile, setIsMobile] = useState<boolean>()

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    if (windowDimensions.width < 1024) {
      setIsMobile(true)
      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    } else {
      setIsMobile(false)
      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }
  }, [isMobile, windowDimensions])

  return isMobile
}
