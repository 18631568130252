import apiService from '../../../setup/axios/ApiService'

const prefixURL = 'purchase-order'

const getAllPurchaseOrders = (currentPage:number, limit:number, customerName: string, customerType: boolean, status: string, startDate: string, endDate: string) => apiService.get(`${prefixURL}/`, {
  page: currentPage,
  limit,
  startDate,
  endDate,
  customerName,
  status,
  customerType,
})

const createPurchaseOrder = (data: any) => apiService.post(`${prefixURL}/`, data)

const updatePurchaseOrder = (uuid: string|undefined, data: any) => apiService.put(`${prefixURL}/${uuid}`, data)

const getPurchaseOrder = (uuid:string|undefined) => apiService.get(`${prefixURL}/${uuid}`)

const patchPurchaseOrderConfig = (uuid: string|undefined, data: any) => apiService.patch(`${prefixURL}/${uuid}/config`, data)

const setReferenceForPurchaseOrder = (uuid: string|undefined, workflowId:string, data: any) => apiService.patch(`${prefixURL}/${uuid}/reference/workflow/${workflowId}`, data)

const getPurchaseOrdersByCustomer = (currentPage:number, limit:number, uuid: string|undefined) => apiService.get(`${prefixURL}/customer/${uuid}`, {
  page: currentPage,
  limit,
})

const duplicatePurchaseOrder = (uuid: string|undefined) => apiService.post(`${prefixURL}/${uuid}/duplicate`, {})

const PurchaseOrderService = {
  getAllPurchaseOrders,
  createPurchaseOrder,
  updatePurchaseOrder,
  getPurchaseOrder,
  patchPurchaseOrderConfig,
  setReferenceForPurchaseOrder,
  getPurchaseOrdersByCustomer,
  duplicatePurchaseOrder,
}

export default PurchaseOrderService
