import React, { useEffect, useState } from 'react'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { useIntl } from 'react-intl'
import Functions from '../../../../setup/utilities/Functions'
import LocationToolbar from './LocationToolbar'
import LocationListing from './components/LocationListing'

const LocationWrapper: React.FC = () => {
  const [location, setLocation] = useState<ILocation|undefined|null>(null)
  const [action, setAction] = useState<string>('')
  const [show, setShow] = React.useState(false)
  const intl = useIntl()

  const locationBreadCrumbs : Array<PageLink> = [
    {
      title: intl.formatMessage({ id: 'home.label' }),
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: 'locations.management.label' }),
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: false,
      isActive: false,
    },
  ]

  const handleClose = async (isSubmit: boolean) => {
    if (!isSubmit) {
      const isConfirmed = await Functions.sweetAlert(
        '<span class="mt-5">Are you sure you would like to cancel</span>',
        'warning',
        true,
        'Yes, cancel it!',
        'No, return',
        'btn btn-primary mt-10 mb-10',
        'btn btn-active-light mt-10 mb-10',
      )
      if (isConfirmed) {
        setShow(false)
      }
    } else {
      setShow(false)
    }
  }

  const handleShow = (locations: ILocation|undefined|null, action: string) => {
    setLocation(location)
    setAction(action)
    setShow(true)
  }

  useEffect(() => {}, [location])

  return (
    <>
      <PageTitle breadcrumbs={locationBreadCrumbs} pageTitle={intl.formatMessage({ id: 'locations.list' })}>
        <LocationToolbar handleShow={handleShow}/>
      </PageTitle>
      <LocationListing show={show} handleShow={handleShow} handleClose={handleClose} action={action} locationSelected={location}/>
    </>
  )
}

export default LocationWrapper
