import React, { useEffect, useState } from 'react'
import { Navigate, Outlet, Route, Routes, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PageLink, PageTitle } from '../../../../../../_metronic/layout/core'
import SupplierService from '../../../../../services/Accounting/SupplierService'
import SupplierDetailsHeader from './SupplierDetailsHeader'
import SupplierDetailsToolbar from './SupplierDetailsToolbar'
import SupplierCreate from '../add/SupplierCreate'
import Overview from './components/Overview'
import Settings from './components/Settings'
import SuppliersOrders from './components/SuppliersOrders'


const SupplierDetailsWrapper : React.FC = () => {
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [supplier, setSupplier] = useState<ISupplier>()
  const [show, setShow] = useState(false)
  const intl = useIntl()
  const supplierDisplayBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({ id: 'home' }),
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: 'accounting.menu' }),
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: 'accounting.supplier.menu' }),
      path: '/supplier',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const handleIsLoading = (isLoading: boolean) => {
    setIsLoading(isLoading)
  }

  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)

  useEffect(() => {
    SupplierService.getSupplier(id).then((response) => {
      setSupplier(response.data.data)
    })
  }, [isLoading])
  return (
    <>
      <Routes>
        <Route
          element={
            <>
              <SupplierDetailsHeader supplier={supplier}/>
              <Outlet/>
            </>
          }
        >
          <Route
            path='overview'
            element={
              <>
                <PageTitle breadcrumbs={supplierDisplayBreadCrumbs} pageTitle={`${supplier?.name}`}>
                  <SupplierDetailsToolbar handleShow={handleShow}/>
                </PageTitle>
                <Overview/>
              </>
            }
          />
          <Route
            path='settings'
            element={
              <>
                <PageTitle breadcrumbs={supplierDisplayBreadCrumbs} pageTitle={`${supplier?.name}`}>
                  <SupplierDetailsToolbar handleShow={handleShow}/>
                </PageTitle>
                <Settings />
              </>
            }
          />
          <Route
            path='supplier-orders'
            element={
              <>
                <PageTitle breadcrumbs={supplierDisplayBreadCrumbs} pageTitle={`${supplier?.name}`}>
                  <SupplierDetailsToolbar handleShow={handleShow}/>
                </PageTitle>
                <SuppliersOrders />
              </>
            }
          />
          <Route index element={<Navigate to='overview' />} />
        </Route>
      </Routes>
      <SupplierCreate
        supplier={null}
        show={show}
        handleClose={handleClose}
        handleIsLoading={handleIsLoading}
        action={'create'}
      />
    </>
  )
}

export default SupplierDetailsWrapper
