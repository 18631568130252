import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import ProductService from '../../../../../services/Crm/ProductService'
import Select from 'react-select'
import ProductFeatureService from '../../../../../services/Crm/ProductFeatureService'

type Inputs = {
    name: string,
    periodicity: number,
    isActive: boolean,
    rate: number,
    productCode: string,
    isAutoRenew: boolean,
    tax: number,
    defaultDowngradeDuration: number,
    productFeatures: string[],
}

type Props = {
  show: boolean,
  product: IProduct|null|undefined,
  handleClose: (isSubmit: boolean) => void,
  handleIsLoading: (isLoading: boolean) => void,
  action: string,
}

const ProductCreate: React.FC<Props> = ({
  product,
  show,
  handleClose,
  handleIsLoading,
  action,
}) => {
  const colourStyles = {
    control: (styles: any) => ({ ...styles, backgroundColor: '#F5F8FA', border: 0 }),
  }
  const intl = useIntl()
  const [optionsProductFeatures, setOptionsProductFeatures] = useState<{value: string, label: string}[] >([])
  const productSchema = Yup.object().shape({
    name: Yup.string().required(intl.formatMessage({ id: 'fieldIsRequired' })),
    productCode: Yup.string().required(intl.formatMessage({ id: 'fieldIsRequired' })),
    periodicity: Yup.number().min(1, intl.formatMessage({ id: 'NumberGreaterThan0' })),
    rate: Yup.number().min(1, intl.formatMessage({ id: 'NumberGreaterThan0' })),
    defaultDowngradeDuration: Yup.number().min(1, intl.formatMessage({ id: 'NumberGreaterThan0' })),
  })
  const config = {
    resolver: yupResolver(productSchema),
    defaultValues: {
      name: '',
      periodicity: 0,
      isActive: false,
      rate: 0,
      productCode: '',
      isAutoRenew: false,
      tax: 0,
      defaultDowngradeDuration: 0,
      productFeatures: [],
    },
  }
  const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm<Inputs>(config)
  // @ts-ignore
  setValue('name', product?.name || '')
  setValue('periodicity', product?.periodicity || 0)
  setValue('isActive', product?.isActive || false)
  setValue('rate', product?.rate || 0)
  setValue('productCode', product?.productCode || '')
  setValue('isAutoRenew', product?.isAutoRenew || false)
  setValue('tax', product?.tax || 0)
  setValue('defaultDowngradeDuration', product?.defaultDowngradeDuration || 0)
  if (product?.productFeatures) {
    setValue('productFeatures', product.productFeatures.map((productFeature) => productFeature.uuid))
  }

  const handleProductFeatures = (event: any) => {
    const values: string[] = []
    event.map((e: any) => {
      values.push(e.value)
    })
    setValue('productFeatures', values)
  }
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (action === 'create') {
      ProductService.createProduct(data).then(() => {
        handleIsLoading(true)
        handleClose(true)
        reset()
      })
    } else {
      ProductService.updateProduct(product?.uuid, data).then(() => {
        handleIsLoading(true)
        handleClose(true)
        reset()
      })
    }
  }

  useEffect(() => {
    ProductFeatureService.selectProductFeatures().then((response) => {
      setOptionsProductFeatures(response.data.data)
    })
  }, [])

  return (
    <Modal show={show} onHide={() => handleClose(false)} dialogClassName={'mw-1000px'}>
      <Modal.Header closeButton>
        <Modal.Title>{intl.formatMessage({ id: 'product.create' })}</Modal.Title>
      </Modal.Header>
      <form className='form w-100' onSubmit={handleSubmit(onSubmit)} noValidate id='create-product-form'>
        <Modal.Body>
          <div className='card card-p-0'>
            <div className='card-body'>
              <div className='row mb-5'>
                <div className='col-md-4'>
                  <div className='form-label mt-3 fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'name.label' })}</div>
                </div>
                <div className='col-md-8'>
                  <input
                    {...register('name')}
                    placeholder='Nom du Produit'
                    className='form-control form-control-solid'
                    type='text'
                    name='name'
                    autoComplete='off'
                  />
                  {errors.name && errors.name.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.name.message}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col-md-4'>
                  <div className='form-label mt-3 fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'productCode.label' })}</div>
                </div>
                <div className='col-md-8'>
                  <input
                    {...register('productCode')}
                    placeholder='Code du Produit'
                    className='form-control form-control-solid'
                    type='text'
                    name='productCode'
                    autoComplete='off'
                  />
                  {errors.productCode && errors.productCode.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.productCode.message}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col-md-4'>
                  <div className='form-label mt-3 fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'product.periodicity.label' })}</div>
                </div>
                <div className='col-md-8'>
                  <input
                    {...register('periodicity')}
                    placeholder='périodicité du Produit'
                    className='form-control form-control-solid'
                    type='number'
                    name='periodicity'
                    autoComplete='off'
                  />
                  {errors.periodicity && errors.periodicity.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.periodicity.message}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col-md-4'>
                  <div className='form-label mt-3 fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'product.rate.label' })}</div>
                </div>
                <div className='col-md-8'>
                  <input
                    {...register('rate')}
                    placeholder='tarif du Produit'
                    className='form-control form-control-solid'
                    type='number'
                    name='rate'
                    autoComplete='off'
                  />
                  {errors.rate && errors.rate.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.rate.message}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col-md-4'>
                  <div className='form-label mt-3 fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'product.tax.label' })}</div>
                </div>
                <div className='col-md-8'>
                  <input
                    {...register('tax')}
                    placeholder='impot sur le produit'
                    className='form-control form-control-solid'
                    type='number'
                    name='tax'
                    autoComplete='off'
                  />
                  {errors.tax && errors.tax.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.tax.message}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col-md-4'>
                  <div className='form-label mt-3 fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'product.defaultDowngradeDuration.label' })}</div>
                </div>
                <div className='col-md-8'>
                  <input
                    {...register('defaultDowngradeDuration')}
                    placeholder='Durée de rétrogradation par defaut du produit'
                    className='form-control form-control-solid'
                    type='number'
                    name='defaultDowngradeDuration'
                    autoComplete='off'
                  />
                  {errors.tax && errors.tax.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.tax.message}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col-md-4'>
                  <div className='form-label mt-3 fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'product.isAutoRenew.label' })}</div>
                </div>
                <div className='col-md-8'>
                  <div className="form-check form-switch form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      {...register('isAutoRenew')}
                      id="flexSwitchChecked"
                    />
                  </div>
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col-md-4'>
                  <div className='form-label mt-3 fs-6 fw-bolder text-dark'>Active</div>
                </div>
                <div className='col-md-8'>
                  <div className="form-check form-switch form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      {...register('isActive')}
                      id="flexSwitchChecked"
                    />
                  </div>
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col-md-4'>
                  <div className='form-label mt-3 fs-6 fw-bolder text-dark required'>Product Features</div>
                </div>
                <div className='col-md-8'>
                  <Select
                    styles={colourStyles}
                    options={optionsProductFeatures}
                    className="form-select form-select-solid"
                    isMulti={true}
                    defaultValue={product?.productFeatures?.map((productFeature) => ({ value: productFeature.uuid, label: productFeature.name }))}
                    isSearchable={true}
                    name='productFeatures'
                    closeMenuOnSelect={false}
                    onChange={handleProductFeatures}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn btn-sm btn-secondary'
            onClick={async () => handleClose(false)}
          >
            {intl.formatMessage({ id: 'action.close' })}
          </button>
          <button
            type='submit'
            className='btn btn-sm btn-secondary'
          >
            {intl.formatMessage({ id: 'action.save' })}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default ProductCreate
