import React from 'react'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../../_metronic/helpers'
import { useIntl } from 'react-intl'

type Props = {
  show: boolean,
  handleClose: (isSubmit: boolean) => void,
  remainingPayment: number,
  payment: number,
  formValues : any,
  onSubmit : () => void
}
const AccountingContract : React.FC<Props> = ({ handleClose, show, remainingPayment, formValues, onSubmit, payment }) => {
  const intl = useIntl()
  return (
    <>
      <Modal show={show} onHide={() => handleClose(false)} dialogClassName={'mw-600px'}>
        <Modal.Body>
          <div className="d-flex align-items-center justify-content-center mt-2">
            <span className="badge badge-warning text-dark mt-2">Paid {payment} DT</span>
          </div>
          <div className="d-flex align-items-center justify-content-center mt-2">
            <span className="badge badge-danger">Not paid {remainingPayment} DT</span>
          </div>
          <div className="table-responsive mt-5">
            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
              <thead>
                <tr className="fw-bolder text-muted border-0 bg-light-primary fw-600">
                  <th className="ms-5">
                    <span className="ms-5 fs-5 fw-500 text-gray-700">Méthode</span>
                  </th>
                  <th className="text-end">
                    <span className="me-5 fs-5 fw-500 text-gray-700">Montant</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {formValues.map((item: any, index: number) => (
                  <tr key={`payment_${index}`}>
                    <td>
                      <KTSVG
                        path={`/media/icons/duotune/${item.paymentType === 'cash' ? 'abstract/money.svg' : item.paymentType === 'check'}`}
                        className='svg-icon svg-icon-2 svg-icon-dark ms-2'
                      />
                      <span className="text-dark fs-5 fw-500 ms-5">{item.paymentType}</span>
                    </td>
                    <td className="text-end">
                      <span className="text-primary fs-5 fw-500 me-5">{item.amount} TND</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <button
              type='button'
              className='btn btn-danger'
              onClick={async () => {
                handleClose(false)
              }}
            >
              {intl.formatMessage({ id: 'action.previous' })}
            </button>
            <button
              type='submit'
              className='btn btn-success'
              onClick={onSubmit}
            >
                  Envoyer Contrat
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AccountingContract
