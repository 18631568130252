import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import EquipmentService from '../../../../services/Accounting/EquipmentService'
import TableHeaderSolid from '../../../../../_metronic/partials/med-table/TableHeaderSolid'
import PaginationBar from '../../../../../_metronic/partials/pagination/PaginationBar'
import { KTSVG } from '../../../../../_metronic/helpers'
import EquipmentCreate from './add/EquipmentCreate'
import Functions from '../../../../../setup/utilities/Functions'

type Props = {
  show: boolean,
  handleShow: (equipment:IEquipment|undefined|null, action: string) => void
  handleClose: (isSubmit: boolean) => void
  equipmentSelected: IEquipment|null|undefined
  action: string
}

const EquipmentListing: React.FC<Props> = ({ show, handleShow, handleClose, equipmentSelected, action }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [equipments, setEquipments] = useState<IEquipment[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = React.useState(1)
  const intl = useIntl()

  const handleIsLoading = (loading: boolean) => {
    setIsLoading(loading)
  }

  const headers = [
    {
      minWidth: 'w-100px',
      text: intl.formatMessage({ id: 'accounting.equipment.entity.title' }),
    },
    {
      minWidth: 'w-100px',
      text: intl.formatMessage({ id: 'accounting.equipment.entity.link' }),
    },
    {
      minWidth: 'w-100px',
      text: intl.formatMessage({ id: 'accounting.equipment.entity.state' }),
    },
    {
      minWidth: 'w-100px',
      text: intl.formatMessage({ id: 'accounting.equipment.entity.reference' }),
    },
    {
      minWidth: 'w-100px',
      text: intl.formatMessage({ id: 'actions' }),
    },
  ]

  const deleteEquipment = async (uuid:string|undefined) => {
    const isConfirmed = await Functions.sweetAlert(
      '<span class="mt-5">Are you sure you want to delete this equipment</span>',
      'warning',
      true,
      'Yes, delete!',
      'No, cancel',
      'btn btn-danger mt-10 mb-10',
      'btn btn-active-light mt-10 mb-10',
    )
    if (isConfirmed) {
      EquipmentService.deleteEquipment(uuid).then(() => {
        handleIsLoading(true)
      })
    }
  }

  const handleCurrentPage = (page:number) => {
    setIsLoading(true)
    setCurrentPage(page)
  }

  useEffect(() => {
    EquipmentService.getAllEquipments(currentPage, 10).then((response) => {
      setEquipments(response.data.data.list)
      setTotalPages(response.data.data.totalPages)
      setIsLoading(false)
    })
  }, [isLoading, currentPage])

  return (
    <>
      <div className={`card mb-5 ${isLoading ? 'overlay overlay-block' : ''}`}>
        <div className={`card-body py-3 ${isLoading ? 'overlay-wrapper' : ''}`}>
          <div className="row pt-5">
            <div className="table-responsive">
              <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                <TableHeaderSolid headers={headers} />
                <tbody>
                  {equipments && equipments.length > 0 ? (
                    equipments.map((equipment, index) => (
                      <tr key={`equipment_${index}`}>
                        <td>
                          <span className="text-muted text-muted">{equipment.title}</span>
                        </td>
                        <td>
                          <span className="text-muted text-muted">{equipment.link}</span>
                        </td>
                        <td>
                          <div className="d-flex">
                            <span className="text-muted text-muted">{equipment.state}</span>
                          </div>
                        </td>
                        <td>
                          <span className="text-muted text-muted">{equipment.reference}</span>
                        </td>
                        <td className="text-end">
                          <a onClick={() => handleShow(equipment, 'edit')} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 w-32px h-32px">
                            <KTSVG
                              path='/media/icons/duotune/abstract/write.svg'
                              className='svg-icon svg-icon-2'
                            />
                          </a>
                          <a onClick={() => deleteEquipment(equipment.uuid)} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 w-32px h-32px">
                            <KTSVG
                              path='/media/icons/duotune/abstract/trash.svg'
                              className='svg-icon svg-icon-2'
                            />
                          </a>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={7}>
                        <div className='d-flex text-center w-100 fs-4 align-content-center justify-content-center mt-5'>
                          {intl.formatMessage({ id: 'action.not.found' })}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {totalPages > 1 ? (
          <PaginationBar
            totalPages={totalPages}
            currentPage={currentPage}
            handleCurrentPage={handleCurrentPage}
          />
        ) : (<></>)}
        {isLoading &&
            <div className="overlay-layer rounded bg-dark bg-opacity-5">
              <div
                className="spinner-border text-primary"
                role="status"
              >
                <span className="visually-hidden">{intl.formatMessage({ id: 'action.loading' })}</span>
              </div>
            </div>
        }
      </div>
      <EquipmentCreate
        equipment={equipmentSelected}
        show={show}
        handleClose={handleClose}
        handleIsLoading={handleIsLoading}
        action={action}
      />
    </>
  )
}

export default EquipmentListing
