import React from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

type Props = {
  customer: ICustomer|null|undefined
}

const CustomerNavBar : React.FC<Props> = ({ customer }) => {
  const intl = useIntl()
  return (
    <div className="mb-5">
      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
        <li className='nav-item'>
          <Link
            className = {`nav-link text-active-primary me-6 ${location.pathname === `/customer/${customer?.uuid}/overview` ? 'active' : ''}`}
            to={`/customer/${customer?.uuid}/overview`}
          >
            {intl.formatMessage({ id: 'accounting.customer.overview' })}
          </Link>
        </li>
        <li className='nav-item'>
          <Link
            className = {`nav-link text-active-primary me-6 ${location.pathname === `/customer/${customer?.uuid}/settings` ? 'active' : ''}`}
            to={`/customer/${customer?.uuid}/settings`}
          >
            {intl.formatMessage({ id: 'accounting.customer.settings' })}
          </Link>
        </li>
        <li className='nav-item'>
          <Link
            className = {`nav-link text-active-primary me-6 ${location.pathname === `/customer/${customer?.uuid}/activities` ? 'active' : ''}`}
            to={`/customer/${customer?.uuid}/activities`}
          >
            {intl.formatMessage({ id: 'accounting.customer.activities' })}
          </Link>
        </li>
        <li className='nav-item'>
          <Link
            className = {`nav-link text-active-primary me-6 ${location.pathname === `/customer/${customer?.uuid}/estimates` ? 'active' : ''}`}
            to={`/customer/${customer?.uuid}/estimates`}
          >
            {intl.formatMessage({ id: 'accounting.estimate.menu' })}
          </Link>
        </li>
        <li className='nav-item'>
          <Link
            className = {`nav-link text-active-primary me-6 ${location.pathname === `/customer/${customer?.uuid}/purchase-orders` ? 'active' : ''}`}
            to={`/customer/${customer?.uuid}/purchase-orders`}
          >
            {intl.formatMessage({ id: 'accounting.purchase.order.menu' })}
          </Link>
        </li>
        <li className='nav-item'>
          <Link
            className = {`nav-link text-active-primary me-6 ${location.pathname === `/customer/${customer?.uuid}/invoices` ? 'active' : ''}`}
            to={`/customer/${customer?.uuid}/invoices`}
          >
            {intl.formatMessage({ id: 'accounting.invoice.menu' })}
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default CustomerNavBar
