import apiService from '../../../setup/axios/ApiService'

const prefixURL = 'payment'

const createPayment = (data: any, uuid: string) => apiService.post(`${prefixURL}/invoice/${uuid}`, data)

const bankDeposit = (data: any, uuid: string) => apiService.post(`${prefixURL}/bank-deposit/cash-flow/${uuid}`, data)

const createOperation = (data: any, uuid: string) => apiService.post(`${prefixURL}/operation/cash-flow/${uuid}`, data)

const createOperationFromBankAccount = (data: any, uuid: string) => apiService.post(`${prefixURL}/operation/bank-account/${uuid}`, data)

const getPaymentsByCashFlow = (uuid: string|undefined, currentPage:number, limit:number) => apiService.get(`${prefixURL}/cash-flow/${uuid}`, {
  page: currentPage,
  limit,
})

const getPaymentsByBankAccount = (uuid: string|undefined, currentPage:number, limit:number) => apiService.get(`${prefixURL}/bank-account/${uuid}`, {
  page: currentPage,
  limit,
})

const getAllPayments = (
  currentPage:number,
  limit:number,
  method: string,
  isIn : number,
  isCashed : boolean,
  cashFlow: string,
  bankAccount: string,
  startDate: string,
  endDate: string,
) => apiService.get(`${prefixURL}/`, {
  page: currentPage,
  limit,
  startDate,
  endDate,
  method,
  cashFlow,
  bankAccount,
  isIn,
  isCashed,
})

const PaymentService = {
  createPayment,
  getPaymentsByCashFlow,
  getAllPayments,
  bankDeposit,
  createOperation,
  getPaymentsByBankAccount,
  createOperationFromBankAccount,
}

export default PaymentService
