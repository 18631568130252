import React from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import TableHeaderSolid from '../../../../../_metronic/partials/med-table/TableHeaderSolid'
import Functions from '../../../../../setup/utilities/Functions'
import Symbol from '../../../../../_metronic/partials/common/Symbol'
import EmployeeCreate from './add/EmployeeCreate'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

type Props = {
  handleClose: () => void,
  handleSubmit: () => void,
  handleShow: (employee:IEmployee|undefined|null, action: string) => void
  show: boolean,
  isLoading: boolean,
  employees: IEmployee[],
  changeStatus : (event: any, uuid: string) => void
  handleIsLoading: (isLoading: boolean) => void,
  currentStep: number,
  setCurrentStep: (step: number) => void,
  employeeSelected: IEmployee|null|undefined
  action: string
}

const EmployeeListing : React.FC<Props> =
    ({
      handleClose,
      handleSubmit,
      handleShow,
      show,
      isLoading,
      employees,
      changeStatus,
      handleIsLoading,
      currentStep,
      setCurrentStep,
      employeeSelected,
      action,
    }) => {
      const intl = useIntl()
      const headers = [
        {
          minWidth: 'min-w-175px',
          text: intl.formatMessage({ id: 'employee.entity.label' }),
        },
        {
          minWidth: 'min-w-125px',
          text: intl.formatMessage({ id: 'employee.entity.email' }),
        },
        {
          minWidth: 'min-w-125px',
          text: intl.formatMessage({ id: 'employee.entity.phone' }),
        },
        {
          minWidth: 'min-w-125px',
          text: intl.formatMessage({ id: 'employee.entity.department' }),
        },
        {
          minWidth: 'min-w-150px',
          text: intl.formatMessage({ id: 'employee.entity.lastActivity' }),
        },
        {
          minWidth: 'min-w-100px',
          text: intl.formatMessage({ id: 'employee.entity.active' }),
        },
        {
          minWidth: 'min-w-100px',
          text: '',
        },
      ]
      return (
        <>
          <div className={`card mb-5 ${isLoading ? 'overlay overlay-block' : ''}`}>
            <div className={`card-body py-3 ${isLoading ? 'overlay-wrapper' : ''}`}>
              <div className="pt-5">
                <div className="table-responsive">
                  <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                    <TableHeaderSolid headers={headers} />
                    <tbody>
                      {employees && employees.map((employee, index) => (
                        <tr key={index}>
                          <td>
                            <Link to={`/employee/${employee.uuid}`}>
                              <Symbol
                                picture={employee.profilePicture}
                                picturePath='/admin/uploads/users/'
                                withText={true}
                                widthClassName='symbol-45px me-5'
                                text={Functions.fullName(employee.firstName, employee.lastName)}
                                shortText={Functions.firstLetterUpperCase(employee.firstName.charAt(0)) + Functions.firstLetterUpperCase(employee.lastName.charAt(0))}
                                description={employee.profession}
                                backgroundClass=''
                                textClassName='fs-3 fw-400'
                                isCircular={false}
                                descriptionColor={'text-muted'}
                                textColor={'text-dark'}
                              />
                            </Link>
                          </td>
                          <td>
                            <div className="d-flex">
                              <KTSVG
                                path='/media/icons/duotune/abstract/at.svg'
                                className='svg-icon svg-icon-2 svg-icon-primary pt-half me-1'
                              />
                              <a className="text-primary d-block">{employee.email}</a>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex">
                              <KTSVG
                                path='/media/icons/duotune/abstract/phone.svg'
                                className='svg-icon svg-icon-2 svg-icon-primary pt-half me-1'
                              />
                              <a className="text-primary d-block">{employee.phone}</a>
                            </div>
                          </td>
                          <td>
                            <span className="text-muted text-muted">{employee.group.name}</span>
                          </td>
                          <td>
                            <span className="text-muted text-muted">22-06-2022</span>
                          </td>
                          <td>
                            <div className="form-check form-switch form-check-custom form-check-solid">
                              <input className="form-check-input w-32px h-20px" type="checkbox" defaultChecked={employee?.isEnabled} id="flexSwitchChecked" onChange={() => changeStatus(event, employee.uuid)}/>
                            </div>
                          </td>
                          <td className="text-end">
                            <a onClick={() => handleShow(employee, 'edit')} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 w-32px h-32px">
                              <KTSVG
                                path='/media/icons/duotune/abstract/write.svg'
                                className='svg-icon svg-icon-2'
                              />
                            </a>
                            <a className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 w-32px h-32px">
                              <KTSVG
                                path='/media/icons/duotune/abstract/trash.svg'
                                className='svg-icon svg-icon-2'
                              />
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {isLoading &&
              <div className="overlay-layer rounded bg-dark bg-opacity-5">
                <div
                  className="spinner-border text-primary"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
              }
            </div>
            <EmployeeCreate
              show={show}
              handleClose={handleClose}
              handleIsLoading={handleIsLoading}
              handleSubmit={handleSubmit}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              employeeSelected={employeeSelected}
              action={action}
            />
          </div>
        </>
      )
    }
export default EmployeeListing
