import React, { useEffect, useState } from 'react'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { useIntl } from 'react-intl'
import EquipmentToolbar from './components/EquipmentToolbar'
import EquipmentListing from './components/EquipmentListing'
import Functions from '../../../../setup/utilities/Functions'

const EquipmentWrapper: React.FC = () => {
  const intl = useIntl()
  const teamBreadCrumbs: Array<PageLink> = [
    {
      title: 'Home',
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: 'accounting.equipment.menu' }),
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  const [equipment, setEquipment] = useState<IEquipment|undefined|null>(null)
  const [action, setAction] = useState<string>('')
  const [show, setShow] = React.useState(false)
  const handleClose = async (isSubmit: boolean) => {
    if (!isSubmit) {
      const isConfirmed = await Functions.sweetAlert(
        '<span class="mt-5">' + intl.formatMessage({ id: 'action.message' }) + '</span>',
        'warning',
        true,
        intl.formatMessage({ id: 'action.cancel' }),
        intl.formatMessage({ id: 'action.return' }),
        'btn btn-primary mt-10 mb-10',
        'btn btn-active-light mt-10 mb-10',
      )
      if (isConfirmed) {
        ''
        setShow(false)
      }
    } else {
      setShow(false)
    }
  }
  const handleShow = (equipment: IEquipment|undefined|null, action: string) => {
    setEquipment(equipment)
    setAction(action)
    setShow(true)
  }

  useEffect(() => {}, [equipment])
  return (
    <>
      <PageTitle breadcrumbs={teamBreadCrumbs} pageTitle={intl.formatMessage({ id: 'accounting.equipment.list' })}>
        <EquipmentToolbar handleShow={handleShow}/>
      </PageTitle>
      <EquipmentListing show={show} handleShow={handleShow} handleClose={handleClose} equipmentSelected={equipment} action={action}/>
    </>
  )
}

export default EquipmentWrapper
