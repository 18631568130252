import apiService from '../../../setup/axios/ApiService'

const prefixURL = 'activity-comment'

const createComment = (data: any, activityId: string) => apiService.post(`${prefixURL}/${activityId}`, data)

const updateComment = (data: any, uuid: string) => apiService.put(`${prefixURL}/${uuid}/edit`, data)

const deleteComment = (uuid: string|undefined) => apiService.deletes(`${prefixURL}/${uuid}/delete`)


const CustomerActivityCommentService = {
  createComment,
  updateComment,
  deleteComment,
}

export default CustomerActivityCommentService
