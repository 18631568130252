import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import Functions from '../../../../../setup/utilities/Functions'
import SupplierService from '../../../../services/Accounting/SupplierService'
import TableHeaderSolid from '../../../../../_metronic/partials/med-table/TableHeaderSolid'
import { KTSVG } from '../../../../../_metronic/helpers'
import PaginationBar from '../../../../../_metronic/partials/pagination/PaginationBar'
import SupplierCreate from './add/SupplierCreate'
import Symbol from '../../../../../_metronic/partials/common/Symbol'
import { Link } from 'react-router-dom'

type Props = {
    show: boolean,
    handleShow: (supplier:ISupplier|undefined|null, action: string) => void
    handleClose: (isSubmit: boolean) => void
    supplierSelected: ISupplier|null|undefined
    action: string
}

const SupplierListing : React.FC<Props> = ({ show, handleShow, handleClose, supplierSelected, action }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [suppliers, setSuppliers] = useState<ISupplier[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = React.useState(1)
  const intl = useIntl()

  const handleIsLoading = (loading: boolean) => {
    setIsLoading(loading)
  }

  const headers = [
    {
      minWidth: 'w-100px',
      text: intl.formatMessage({ id: 'accounting.supplier.entity.name' }),
    },
    {
      minWidth: 'w-100px',
      text: intl.formatMessage({ id: 'accounting.supplier.entity.phone' }),
    },
    {
      minWidth: 'w-100px',
      text: intl.formatMessage({ id: 'accounting.supplier.entity.location' }),
    },
    {
      minWidth: 'w-100px',
      text: intl.formatMessage({ id: 'accounting.supplier.entity.country' }),
    },
    {
      minWidth: 'w-100px',
      text: intl.formatMessage({ id: 'actions' }),
    },
  ]

  const deleteSupplier = async (uuid:string|undefined) => {
    const isConfirmed = await Functions.sweetAlert(
      '<span class="mt-5">Are you sure you want to delete this supplier</span>',
      'warning',
      true,
      'Yes, delete!',
      'No, cancel',
      'btn btn-danger mt-10 mb-10',
      'btn btn-active-light mt-10 mb-10',
    )
    if (isConfirmed) {
      SupplierService.deleteSupplier(uuid).then(() => {
        handleIsLoading(true)
      })
    }
  }

  const handleCurrentPage = (page:number) => {
    setIsLoading(true)
    setCurrentPage(page)
  }

  useEffect(() => {
    SupplierService.getAllSuppliers(currentPage, 10).then((response) => {
      setSuppliers(response.data.data.list)
      setTotalPages(response.data.data.totalPages)
      setIsLoading(false)
    })
  }, [isLoading, currentPage])

  return (
    <>
      <div className={`card mb-5 ${isLoading ? 'overlay overlay-block' : ''}`}>
        <div className={`card-body py-3 ${isLoading ? 'overlay-wrapper' : ''}`}>
          <div className="row pt-5">
            <div className="table-responsive">
              <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                <TableHeaderSolid headers={headers} />
                <tbody>
                  {suppliers && suppliers.length > 0 ? (
                    suppliers.map((supplier, index) => (
                      <tr key={`supplier_${index}`}>
                        <td>
                          <Link to={`${supplier.uuid}`}>
                            <Symbol
                              picture={null}
                              picturePath='/admin/uploads/users/'
                              withText={true}
                              widthClassName='symbol-45px me-5'
                              text={supplier.name}
                              shortText={Functions.firstLetterUpperCase(supplier.name.charAt(0))}
                              description={null}
                              backgroundClass=''
                              textClassName='fs-3 fw-400'
                              isCircular={false}
                              descriptionColor={'text-muted'}
                              textColor={'text-dark'}
                            />
                          </Link>
                        </td>
                        <td>
                          <span className="text-muted text-muted">{supplier.phone}</span>
                        </td>
                        <td>
                          <div className="d-flex">
                            <span className="text-muted text-muted">{supplier.location.name}</span>
                          </div>
                        </td>
                        <td>
                          <span className="text-muted text-muted">{supplier.country.name}</span>
                        </td>
                        <td className="text-end">
                          <a onClick={() => handleShow(supplier, 'edit')} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 w-32px h-32px">
                            <KTSVG
                              path='/media/icons/duotune/abstract/write.svg'
                              className='svg-icon svg-icon-2'
                            />
                          </a>
                          <a onClick={() => deleteSupplier(supplier.uuid)} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 w-32px h-32px">
                            <KTSVG
                              path='/media/icons/duotune/abstract/trash.svg'
                              className='svg-icon svg-icon-2'
                            />
                          </a>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={7}>
                        <div className='d-flex text-center w-100 fs-4 align-content-center justify-content-center mt-5'>
                          {intl.formatMessage({ id: 'action.not.found' })}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {totalPages > 1 ? (
          <PaginationBar
            totalPages={totalPages}
            currentPage={currentPage}
            handleCurrentPage={handleCurrentPage}
          />
        ) : (<></>)}
        {isLoading &&
                  <div className="overlay-layer rounded bg-dark bg-opacity-5">
                    <div
                      className="spinner-border text-primary"
                      role="status"
                    >
                      <span className="visually-hidden">{intl.formatMessage({ id: 'action.loading' })}</span>
                    </div>
                  </div>
        }
      </div>
      <SupplierCreate
        supplier={supplierSelected}
        show={show}
        handleClose={handleClose}
        handleIsLoading={handleIsLoading}
        action={action}
      />
    </>
  )
}

export default SupplierListing
