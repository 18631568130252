import React from 'react'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../../../_metronic/helpers'
import { useIntl } from 'react-intl'

type Props = {
  handleShow: (employee:IEmployee|undefined|null, action: string) => void
  employee?: IEmployee
  isLoading: boolean,
  handleIsLoading: (isLoading: boolean) => void,
  page: string,
}

const EmployeeItem : React.FC<Props> = ({ handleShow, employee, isLoading, handleIsLoading, page }) => {
  const intl = useIntl()
  return (
    <>
      <div className={'card card-stretch'}>

        <div className={'card-body d-flex flex-column p-2'}>
          <div className={`flex-grow-1 ${page !== 'display' ? 'pb-5' : ''}`}>
            <div className='me-2 d-flex flex-stack flex-wrap'>
              <div className="d-flex align-items-center">
                {employee?.profilePicture ? (
                  <div className="symbol symbol-45px me-5">
                    <img src={`data:image/*;base64,${employee?.profilePicture}`}/>
                  </div>
                ) :
                  <div className="symbol symbol-45px me-5">
                    <div className="symbol-label fs-3 fw-400">
                      {employee?.firstName.charAt(0)}{employee?.lastName.charAt(0)}
                    </div>
                  </div>
                }
                <div className="d-flex justify-content-start flex-column">
                  <Link className="text-dark fw-bolder text-hover-primary fs-6" to={{
                    pathname: `/employee/${employee?.uuid}`,
                  }}>
                    {employee?.firstName} {employee?.lastName}
                  </Link>
                  <span className="text-muted fw-bold text-muted d-block fs-7">{employee?.profession} | {employee?.group.name}</span>
                </div>
              </div>
              <div className="symbol-group symbol-hover">
                <a href="src/app/modules/hr/employee/components/EmployeeItem#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm w-32px h-32px">
                  <KTSVG
                    path='/media/icons/duotune/general/gen053.svg'
                    className='svg-icon svg-icon-2 text-primary'
                  />
                </a>
              </div>
            </div>
            <div className='ms-15 pt-2 d-flex'>
              <div className="symbol-group symbol-hover">
                <a href="src/app/modules/hr/employee/components/EmployeeItem#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 w-32px h-32px">
                  <KTSVG
                    path='/media/icons/duotune/abstract/at.svg'
                    className='svg-icon svg-icon-4 pt-half me-1'
                  />
                </a>
              </div>
              <div className="symbol-group symbol-hover">
                <a href="src/app/modules/hr/employee/components/EmployeeItem#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 w-32px h-32px">
                  <KTSVG
                    path='/media/icons/duotune/abstract/phone.svg'
                    className='svg-icon svg-icon-4 pt-half me-1'
                  />
                </a>
              </div>
              <div className="text-muted fw-500 ms-4 pt-2">
                {intl.formatMessage({ id: 'employee.entity.lastActivity' })} : 22-06-2022
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default EmployeeItem
