import apiService from '../../../setup/axios/ApiService'

const prefixURL = 'supplier-order'

const getAllSupplierOrders = (currentPage:number, limit:number, supplierName: string, customerType: boolean, status: string, startDate: string, endDate: string) => apiService.get(`${prefixURL}/`, {
  page: currentPage,
  limit,
  startDate,
  endDate,
  supplierName,
  status,
  customerType,
})

const createSupplierOrder = (data: any) => apiService.post(`${prefixURL}/`, data)

const updateSupplierOrder = (uuid: string|undefined, data: any) => apiService.put(`${prefixURL}/${uuid}`, data)

const getSupplierOrder = (uuid:string|undefined) => apiService.get(`${prefixURL}/${uuid}`)

const patchSupplierOrderConfig = (uuid: string|undefined, data: any) => apiService.patch(`${prefixURL}/${uuid}/config`, data)

const setReferenceForSupplierOrder = (uuid: string|undefined, workflowId:string, data: any) => apiService.patch(`${prefixURL}/${uuid}/reference/workflow/${workflowId}`, data)

const getSupplierOrdersBySupplier = (currentPage:number, limit:number, uuid: string|undefined) => apiService.get(`${prefixURL}/supplier/${uuid}`, {
  page: currentPage,
  limit,
})

const SupplierOrderService = {
  getAllSupplierOrders,
  createSupplierOrder,
  updateSupplierOrder,
  getSupplierOrder,
  patchSupplierOrderConfig,
  setReferenceForSupplierOrder,
  getSupplierOrdersBySupplier,
}

export default SupplierOrderService
