import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import ProductFeatureService from '../../../../../services/Crm/ProductFeatureService'
import Select from 'react-select'
import FeatureService from '../../../../../services/Hr/FeatureService'

type Inputs = {
    name: string,
    description: string,
    features: string[],
}

type Props = {
  show: boolean,
  productFeature: IProductFeature|null|undefined,
  handleClose: (isSubmit: boolean) => void,
  handleIsLoading: (isLoading: boolean) => void,
  action: string,
}

const ProductFeatureCreate: React.FC<Props> = ({
  productFeature,
  show,
  handleClose,
  handleIsLoading,
  action,
}) => {
  const colourStyles = {
    control: (styles: any) => ({ ...styles, backgroundColor: '#F5F8FA', border: 0 }),
  }
  const intl = useIntl()
  const [optionsFeatures, setOptionsFeatures] = useState<{value: string, label: string}[] >([])
  const ProductFeatureSchema = Yup.object().shape({
    name: Yup.string().required(intl.formatMessage({ id: 'fieldIsRequired' })),
    description: Yup.string().required(intl.formatMessage({ id: 'fieldIsRequired' })),
  })
  const config = {
    resolver: yupResolver(ProductFeatureSchema),
    defaultValues: {
      name: '',
      description: '',
      features: [],
    },
  }
  const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm<Inputs>(config)
  // @ts-ignore
  setValue('name', productFeature?.name || '')
  setValue('description', productFeature?.description || '')
  if (productFeature?.features) {
    setValue('features', productFeature.features.map((feature) => feature.uuid))
  }

  const handleFeatures = (event: any) => {
    const values: string[] = []
    event.map((e: any) => {
      values.push(e.value)
    })
    setValue('features', values)
  }

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (action === 'create') {
      ProductFeatureService.createProductFeature(data).then(() => {
        handleIsLoading(true)
        handleClose(true)
        reset()
      })
    } else {
      ProductFeatureService.updateProductFeature(productFeature?.uuid, data).then(() => {
        handleIsLoading(true)
        handleClose(true)
        reset()
      })
    }
  }

  useEffect(() => {
    FeatureService.selectFeaturesForProducts().then((response) => {
      setOptionsFeatures(response.data.data)
    })
  }, [])


  return (
    <Modal show={show} onHide={() => handleClose(false)} dialogClassName={'mw-1000px'}>
      <Modal.Header closeButton>
        <Modal.Title>{intl.formatMessage({ id: 'productFeature.create' })}</Modal.Title>
      </Modal.Header>
      <form className='form w-100' onSubmit={handleSubmit(onSubmit)} noValidate id='create-product-feature-form'>
        <Modal.Body>
          <div className='card card-p-0'>
            <div className='card-body'>
              <div className='row mb-5'>
                <div className='col-md-4'>
                  <div className='form-label mt-3 fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'product-feature.entity.name.label' })}</div>
                </div>
                <div className='col-md-8'>
                  <input
                    {...register('name')}
                    placeholder='Nom du Product feature'
                    className='form-control form-control-solid'
                    type='text'
                    name='name'
                    autoComplete='off'
                  />
                  {errors.name && errors.name.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.name.message}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col-md-4'>
                  <div className='form-label mt-3 fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'product-feature.entity.description.label' })}</div>
                </div>
                <div className='col-md-8'>
                  <input
                    {...register('description')}
                    placeholder='Ecrire votre description ici'
                    className='form-control form-control-solid'
                    name='description'
                    autoComplete='off'
                  />
                  {errors.description && errors.description.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.description.message}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col-md-4'>
                  <div className='form-label mt-3 fs-6 fw-bolder text-dark required'>Features</div>
                </div>
                <div className='col-md-8'>
                  <Select
                    styles={colourStyles}
                    options={optionsFeatures}
                    isMulti={true}
                    defaultValue={productFeature?.features?.map((feature) => ({ value: feature.uuid, label: feature.name }))}
                    isSearchable={true}
                    name='features'
                    closeMenuOnSelect={false}
                    onChange={handleFeatures}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn btn-sm btn-secondary'
            onClick={async () => handleClose(false)}
          >
            {intl.formatMessage({ id: 'action.close' })}
          </button>
          <button
            type='submit'
            className='btn btn-sm btn-secondary'
          >
            {intl.formatMessage({ id: 'action.save' })}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default ProductFeatureCreate
