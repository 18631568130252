import apiService from '../../../setup/axios/ApiService'

const prefixURL = 'absence'

const createLeave = (data: any) => apiService.post(`${prefixURL}/request`, data)

const getEmployeeFutureLeaves = (employeeUuid: string|undefined) => apiService.get(`${prefixURL}/${employeeUuid}/future`)

const updateStatus = (uuid: string, data: any) => apiService.patch(`${prefixURL}/${uuid}/status`, data)

const getAllEmployeeLeaves = (employeeUuid: string|undefined, leaveType: string|undefined, status: string) => apiService.get(`${prefixURL}/`, {
  employeeUuid,
  leaveType,
  status,
})

const LeaveService = {
  createLeave,
  getEmployeeFutureLeaves,
  updateStatus,
  getAllEmployeeLeaves,
}

export default LeaveService
