import React, { useEffect, useState } from 'react'
import { Accordion } from 'react-bootstrap'
import PaymentCreate from '../../../../../accounting/payment/PaymentCreate'
import ProductService from '../../../../../../services/Crm/ProductService'
import { useParams } from 'react-router-dom'

const Pack : React.FC = () => {
  const { id } = useParams()
  const [show, setShow] = useState<boolean>(false)
  const [totalAmount, setTotalAmount] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [productCategories, setProductCategories] = useState<IProductCategory[]>([])
  const [documentLines, setDocumentLines] = useState<{ uuid: string, name:string, quantity:number, price: number, total: number, vat:number, categoryId: string }[]>([])

  const handleIsLoading = (loading: boolean) => {
    setIsLoading(loading)
  }
  const handleShow = () => setShow(true)
  const handleClose = async () => setShow(false)

  useEffect(() => {
    ProductService.getProductsWithMedicalProfessional(id).then((response) => {
      setIsLoading(false)
      setProductCategories(response.data.data)
    })
  }, [isLoading])


  const onCheckProduct = (id: string, categoryId: string) => {
    const product = documentLines.find((item) => item.categoryId === categoryId)
    const newDocumentLines = [...documentLines]
    if (product) {
      const index = documentLines.indexOf(product)
      newDocumentLines.splice(index, 1)
      setDocumentLines(newDocumentLines)
    }
    ProductService.getProduct(id).then((response) => {
      documentLines.push()
      setDocumentLines([...newDocumentLines, {
        uuid: id,
        name: response.data.data.name,
        quantity: 1,
        price: response.data.data.rate,
        total: response.data.data.total,
        vat: response.data.data.tax,
        categoryId,
      }])
      setTotalAmount(totalAmount + response.data.data.rate)
    })
  }

  useEffect(() => {}, [documentLines])

  return (
    <>
      <h2 className="mb-5">Nos Packs</h2>
      {productCategories && productCategories.map((productCategory, index) => (
        <Accordion key={`product_category_${index}`}>
          <div className="card mt-5">
            <Accordion.Item eventKey="0">
              <div className="card-body px-0 py-0">
                <Accordion.Header className="me-5 mt-5 ms-4">
                  <input className="form-check-input form-check-solid" type="checkbox" value="" id="flexCheckDefault"/>
                  <span className="form-check-label med-dark fw-500 fs-3 ms-5">{productCategory.name}</span>
                </Accordion.Header>
                <div className='separator d-flex flex-center mt-5'></div>
                <Accordion.Body>
                  {productCategory.products && productCategory.products.map((product, indexProduct) => (
                    <div key={`product_${indexProduct}`}>
                      <div className="d-flex px-5">
                        <div className='d-flex align-items-start'>
                          <div className="form-check form-check-med-custom form-check-secondary form-check-solid">
                            <input className="form-check-input mt-3"
                              type="radio"
                              value={product.uuid}
                              name={productCategory.name}
                              onChange={() => onCheckProduct(product.uuid, productCategory.uuid)}
                              disabled={product.isOwnedByMedicalProfessional}
                            />
                          </div>
                          <div className="d-flex flex-column ms-5">
                            <span className="text-dark fw-500 fs-4">{product.product}</span>
                            <div className="d-flex align-items-start mt-2">
                              <span className="badge badge-light-primary text-dark fs-6 fw-500">Remise de {product.discount}%</span>
                            </div>
                          </div>

                        </div>
                        <div className="ms-auto">
                          <span className="text-dark fs-2 fw-bolder">{product.rate}</span>
                          <span className="text-dark fs-6 fw-500 ms-2">TND TTC</span>
                        </div>
                      </div>
                      <div className='separator d-flex flex-center mt-5 mb-5'></div>
                    </div>
                  ))}
                </Accordion.Body>
              </div>
            </Accordion.Item>
          </div>
        </Accordion>
      ))}

      <div className="d-flex align-items-end justify-content-end mt-5">
        <button className="btn btn-sm btn-success" onClick={handleShow} >Appliquer</button>
      </div>
      <PaymentCreate id={id} documentLines={documentLines} handleIsLoading={handleIsLoading} show={show} handleClose={handleClose} totalAmount={totalAmount} setTotalAmount={setTotalAmount} />
    </>
  )
}

export default Pack
