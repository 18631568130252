import React from 'react'
import { useIntl } from 'react-intl'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import PurchaseOrderToolbar from './components/PurchaseOrderToolbar'
import PurchaseOrderFilter from './components/PurchaseOrderFilter'


const PurchaseOrderWrapper : React.FC = () => {
  const intl = useIntl()
  const teamBreadCrumbs: Array<PageLink> = [
    {
      title: 'Home',
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: 'accounting.purchase.order.menu' }),
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={teamBreadCrumbs} pageTitle={intl.formatMessage({ id: 'accounting.purchase.order.list' })}>
        <PurchaseOrderToolbar />
      </PageTitle>
      <PurchaseOrderFilter/>
    </>
  )
}

export default PurchaseOrderWrapper
