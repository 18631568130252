import React from 'react'
import { KTSVG } from '../../../../../../../../_metronic/helpers'
import Symbol from '../../../../../../../../_metronic/partials/common/Symbol'
import Functions from '../../../../../../../../setup/utilities/Functions'

type Props = {
  journey?: IJourney
  isLoading: boolean,
  handleIsLoading: (isLoading: boolean) => void,
  handleSelectedId: (uuid: string) => void,
  selectedId: string|undefined,
}

const JourneySupportManagerItem : React.FC<Props> = ({ journey, isLoading, handleIsLoading, handleSelectedId, selectedId }) => (
  <>
    {journey?.startDate ?
      <>
        <div className={`card card-rounded bg-med-success mb-3 ${journey.uuid === selectedId ? 'border border-3 border-warning triangle' : ''}`} onClick={() => handleSelectedId(journey!.uuid)}>
          <div className='card-body px-4 py-4'>
            <div className="d-flex">
              <div className="flex-grow-1 ms-2">
                <div className="d-flex justify-content-start align-items-start">
                  <Symbol
                    picture={journey?.employee?.profilePicture || null}
                    picturePath='/admin/uploads/users/'
                    withText={true}
                    widthClassName='symbol-35px'
                    text={Functions.fullName(journey?.employee?.firstName || '', journey?.employee?.lastName || '')}
                    shortText={Functions.firstLetterUpperCase((journey?.employee?.firstName || '').charAt(0)) + Functions.firstLetterUpperCase((journey?.employee?.lastName || '').charAt(0))}
                    description={null}
                    backgroundClass=''
                    textClassName='fs-3 fw-400'
                    isCircular={false}
                    descriptionColor={''}
                    textColor={'text-white ms-3 fs-5 fw-bold'}
                  />
                </div>
                <div className="row mt-6">
                  <div className="col-3">
                    <span className="d-flex align-items-start">
                      <KTSVG
                        path='/media/icons/duotune/abstract/phone.svg'
                        className='svg-icon svg-icon-1 svg-icon-white'
                      />
                      <KTSVG
                        path='/media/icons/duotune/abstract/pharmacist.svg'
                        className='svg-icon svg-icon-1 svg-icon-white'
                      />
                      <span className="text-white fs-3 fw-bold ms-2">
                        {journey?.patientCalls || 0}
                      </span>
                    </span>
                  </div>
                  <div className="col-3">
                    <span className="d-flex align-items-start">
                      <KTSVG
                        path='/media/icons/duotune/abstract/phone.svg'
                        className='svg-icon svg-icon-1 svg-icon-white'
                      />
                      <KTSVG
                        path='/media/icons/duotune/communication/com014.svg'
                        className='svg-icon svg-icon-1 svg-icon-white'
                      />
                      <span className="text-white fs-3 fw-bold ms-2">
                        {journey?.patientCalls || 0}
                      </span>
                    </span>
                  </div>
                  <div className="col-6">
                    <div className="d-flex justify-content-end align-items-end me-2">
                      <span className='badge bg-med-light-dark fs-8 fw-bold h-30px p-1'>
                        <div className="d-flex align-items-center">
                          <span className='badge badge-white w-70px h-25px'>
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr027.svg'
                              className='svg-icon svg-icon-1 svg-icon-primary'
                            />
                            <span className="text-primary fs-6 fw-500">{journey?.startDate ? journey.startDate : '--:--'}</span>
                          </span>
                          <span className='badge badge-white w-70px ms-2 h-25px'>
                            <KTSVG
                              path='/media/icons/duotune/abstract/reset.svg'
                              className='svg-icon svg-icon-1 svg-icon-danger'
                            />
                            <span className="text-danger fs-6 fw-500">{journey?.endDate ? journey.endDate : '--:--'}</span>
                          </span>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </> :
      <>
        <div className='card card-rounded mb-3'>
          <div className='card-body px-4 py-4'>
            <div className="d-flex">
              <div className="flex-grow-1 ms-2">
                <div className="d-flex justify-content-start align-items-start">
                  <Symbol
                    picture={journey?.employee?.profilePicture || null}
                    picturePath='/admin/uploads/users/'
                    withText={true}
                    widthClassName='symbol-40px'
                    text={Functions.fullName(journey?.employee?.firstName || '', journey?.employee?.lastName || '')}
                    shortText={Functions.firstLetterUpperCase((journey?.employee?.firstName || '').charAt(0)) + Functions.firstLetterUpperCase((journey?.employee?.lastName || '').charAt(0))}
                    description={null}
                    backgroundClass=''
                    textClassName='fs-3 fw-400'
                    isCircular={false}
                    descriptionColor={''}
                    textColor={'text-dark ms-3 fs-5 fw-bold'}
                  />
                </div>
                <div className="row mt-6">
                  <div className="col-3">
                    <span className="d-flex align-items-start">
                      <KTSVG
                        path='/media/icons/duotune/abstract/phone.svg'
                        className='svg-icon svg-icon-1 svg-icon-dark'
                      />
                      <KTSVG
                        path='/media/icons/duotune/abstract/pharmacist.svg'
                        className='svg-icon svg-icon-1 svg-icon-dark'
                      />
                      <span className="text-dark fs-3 fw-bold ms-2">
                        {journey?.patientCalls || 0}
                      </span>
                    </span>
                  </div>
                  <div className="col-3">
                    <span className="d-flex align-items-start">
                      <KTSVG
                        path='/media/icons/duotune/abstract/phone.svg'
                        className='svg-icon svg-icon-1 svg-icon-dark'
                      />
                      <KTSVG
                        path='/media/icons/duotune/communication/com014.svg'
                        className='svg-icon svg-icon-1 svg-icon-dark'
                      />
                      <span className="text-dark fs-3 fw-bold ms-2">
                        {journey?.patientCalls || 0}
                      </span>
                    </span>
                  </div>
                  <div className="col-6">
                    <div className="d-flex justify-content-end align-items-end me-2">
                      <span className='badge bg-med-light-dark fs-8 fw-bold h-30px p-1'>
                        <div className="d-flex align-items-center">
                          <span className='badge badge-white w-70px h-25px'>
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr027.svg'
                              className='svg-icon svg-icon-1 svg-icon-primary'
                            />
                            <span className="text-primary fs-6 fw-500">{journey?.startDate ? journey.startDate : '--:--'}</span>
                          </span>
                          <span className='badge badge-white w-70px ms-2 h-25px'>
                            <KTSVG
                              path='/media/icons/duotune/abstract/reset.svg'
                              className='svg-icon svg-icon-1 svg-icon-danger'
                            />
                            <span className="text-danger fs-6 fw-500">{journey?.endDate ? journey.endDate : '--:--'}</span>
                          </span>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    }

  </>
)

export default JourneySupportManagerItem
