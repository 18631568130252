import React from 'react'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import useWindowDimensions from '../../../../../../../_metronic/partials/mobile-check-view/useCheckMobileScreen'
import DropZone from '../../../../../../../_metronic/partials/common/DropZone'
// @ts-ignore
import InputMask from 'react-input-mask'
import LeaveService from '../../../../../../services/Hr/LeaveService'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../setup'

const leaveSchema = Yup.object().shape({
  leaveType: Yup.string()
    .required('Ce champs est obligatoire'),
  date: Yup.string()
    .when('leaveType', {
      is: 'authorization',
      then: Yup.string().required('Ce champs est obligatoire'),
    }),
  startDate: Yup.string()
    .when('leaveType', (leaveType) => {
      if (leaveType === 'leave' || leaveType === 'sickness_leave') {
        return Yup.string().required('Ce champs est obligatoire')
      }
      return Yup.string()
    }),
  endDate: Yup.string()
    .when('leaveType', (leaveType) => {
      if (leaveType === 'leave' || leaveType === 'sickness_leave') {
        return Yup.string().required('Ce champs est obligatoire')
      }
      return Yup.string()
    }),
})

type Inputs = {
  employeeUuid: string,
  leaveType: string,
  reason: string,
  startDate: string,
  endDate: string,
  date: string,
  timeOfDay: string,
  status: string,
  files: File[],
}

type Props = {
  show: boolean,
  handleClose: () => void,
  employee: IEmployee|undefined,
  handleIsLeaveLoading: (isLoading: boolean) => void,
  initialLeaveType: string,
}

const CreateLeave: React.FC<Props> = ({
  show,
  handleClose,
  employee,
  handleIsLeaveLoading,
  initialLeaveType,
}) => {
  const intl = useIntl()
  const isMobile = useWindowDimensions()
  const user: IUser = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as IUser
  const config = {
    resolver: yupResolver(leaveSchema),
    defaultValues: {
      employeeUuid:  '',
      leaveType: '',
      reason: '',
      startDate: '',
      status: '',
      endDate: '',
      date: '',
      timeOfDay: '',
      files: [],
    },
  }
  const { register, setValue, handleSubmit, formState: { errors }, watch, reset } = useForm<Inputs>(config)
  const [files, setFiles] = React.useState<File[]>([])
  const handleFiles = (files: File[]) => {
    setFiles(files)
    setValue('files', files)
  }

  const handleLeaveTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue('leaveType', e.target.value)
  }

  const handleTimeOfDayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue('timeOfDay', e.target.value)
  }

  const watchLeaveType = watch('leaveType')

  const watchTimeOfDay = watch('timeOfDay')

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (employee) {
      setValue('employeeUuid', employee.uuid)
    }
    if (user.roles.includes('ROLE_ADMIN')) {
      setValue('status', 'approved')
    } else {
      setValue('status', 'requested')
    }
    const formData = new FormData()
    formData.append('employeeUuid', data.employeeUuid)
    formData.append('leaveType', data.leaveType)
    formData.append('reason', data.reason)
    formData.append('startDate', data.startDate)
    formData.append('status', data.status)
    formData.append('endDate', data.endDate)
    formData.append('date', data.date)
    formData.append('timeOfDay', data.timeOfDay)
    const filesLength = data.files.length
    formData.append('filesLength', filesLength.toString())
    for (let i = 0; i < filesLength; i++) {
      formData.append(`file[${i}]`, data.files[i])
    }

    LeaveService.createLeave(formData).then(() => {
      handleIsLeaveLoading(true)
      handleClose()
      reset()
    })
  }

  React.useEffect(() => {
    setValue('leaveType', initialLeaveType)
  }, [initialLeaveType])


  return (
    <Modal show={show} onHide={handleClose} dialogClassName={'mw-1000px'} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {intl.formatMessage({ id: 'employee.leave.request' })}
          {watchLeaveType === 'authorization' ? (
            <div
              className='btn btn-light-warning btn-sm px-3 fs-6 py-2 med-dark ms-4'
            >
              {intl.formatMessage({ id: 'employee.authorization.balance.number' })}0 / 2
            </div>
          ) : (watchLeaveType === 'sickness_leave' ? (
            <div
              className='btn btn-light btn-sm px-3 fs-6 py-2 text-primary ms-4'
            >
              {intl.formatMessage({ id: 'employee.leave.balance.sickness' })}5
            </div>
          ) : (
            <div
              className='btn btn-light btn-sm px-3 fs-6 py-2 text-primary ms-4'
            >
              {intl.formatMessage({ id: 'employee.leave.balance.number' })}7
            </div>
          )
          )}
        </Modal.Title>
      </Modal.Header>
      <form className='form w-100' onSubmit={handleSubmit(onSubmit)} noValidate id='request-leave-form'>
        <Modal.Body>
          <div className='card card-p-0 shadow-none'>
            <div className='card-body'>
              <div className='row mb-5'>
                <div className='col-md-2'>
                  <div className='form-label mt-3 fs-6 text-dark required'>{intl.formatMessage({ id: 'leave.label' })}</div>
                </div>
                <div className='col-md-10'>
                  <div className='row g-3' data-kt-buttons="true">
                    <div className='col'>
                      <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start ${isMobile ? '' : 'w-max-content'} p-6 mb-5 ${watchLeaveType === 'leave' ? 'active' : ''}`}>
                        <div className="d-flex align-items-center me-2">
                          <div className="form-check form-check-custom form-check-solid form-check-primary me-3">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="type"
                              value="leave"
                              checked={watchLeaveType === 'leave'}
                              onChange={handleLeaveTypeChange}
                            />
                          </div>
                          <div className="flex-grow-1">
                            <div className="fw-600">
                              {intl.formatMessage({ id: 'leave.label' })}
                            </div>
                          </div>
                        </div>
                      </label>
                    </div>
                    <div className='col'>
                      <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start ${isMobile ? '' : 'w-max-content'} p-6 mb-5 ${watchLeaveType === 'sickness_leave' ? 'active' : ''}`}>
                        <div className="d-flex align-items-center me-2">
                          <div className="form-check form-check-custom form-check-solid form-check-primary me-3">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="type"
                              value="sickness_leave"
                              checked={watchLeaveType === 'sickness_leave'}
                              onChange={handleLeaveTypeChange}
                            />
                          </div>
                          <div className="flex-grow-1">
                            <div className="fw-600">
                              {intl.formatMessage({ id: 'sickness.leave.label' })}
                            </div>
                          </div>
                        </div>
                      </label>
                    </div>
                    <div className='col'>
                      <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start ${isMobile ? '' : 'w-max-content'} p-6 mb-5 ${watchLeaveType === 'half_day_leave' ? 'active' : ''}`}>
                        <div className="d-flex align-items-center me-2">
                          <div className="form-check form-check-custom form-check-solid form-check-primary me-3">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="type"
                              value="half_day_leave"
                              checked={watchLeaveType === 'half_day_leave'}
                              onChange={handleLeaveTypeChange}
                            />
                          </div>
                          <div className="flex-grow-1">
                            <div className="fw-600">
                              {intl.formatMessage({ id: 'half.day.leave.label' })}
                            </div>
                          </div>
                        </div>
                      </label>
                    </div>
                    <div className='col'>
                      <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start ${isMobile ? '' : 'w-max-content'} p-6 mb-5 ${watchLeaveType === 'authorization' ? 'active' : ''}`}>
                        <div className="d-flex align-items-center me-2">
                          <div className="form-check form-check-custom form-check-solid form-check-primary me-3">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="type"
                              value="authorization"
                              checked={watchLeaveType === 'authorization'}
                              onChange={handleLeaveTypeChange}
                            />
                          </div>
                          <div className="flex-grow-1">
                            <div className="fw-600">
                              {intl.formatMessage({ id: 'authorization.label' })}
                            </div>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                  {errors.leaveType && errors.leaveType.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.leaveType.message}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col-md-2'>
                  <div className='form-label mt-3 fs-6 text-dark'>Raison</div>
                </div>
                <div className='col-md-10'>
                  <input
                    {...register('reason')}
                    placeholder='Reason'
                    className='form-control form-control-solid'
                    type='text'
                    name='reason'
                    autoComplete='off'
                  />
                </div>
              </div>
              {watchLeaveType === 'authorization' || watchLeaveType === 'half_day_leave' ? (
                <div className='row mb-5'>
                  <div className='col-md-2'>
                    <div className='form-label mt-3 fs-6 text-dark required'>Date</div>
                  </div>
                  <div className='col-md-10 row pe-0'>
                    <div className='col-md-5'>
                      <InputMask
                        {...register('date')}
                        name='date'
                        className="form-control form-control-solid"
                        mask='9999-99-99' placeholder="----/--/--"
                      />
                      {errors.date && errors.date.message && (
                        <div className='fv-plugins-message-container text-danger'>
                          <span role='alert'>{errors.date.message}</span>
                        </div>
                      )}
                    </div>
                    <div className={`col-md-7 pe-0 ${isMobile ? 'mt-5' : ''}`}>
                      <div className='d-flex gap-3' data-kt-buttons="true">
                        <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start w-250px h-46px p-6 mb-5 ${watchTimeOfDay === 'morning' ? 'active' : ''}`}>
                          <div className="d-flex align-items-center me-2">
                            <div className="form-check form-check-custom form-check-solid form-check-primary form-check-sm me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="typeOfDay"
                                value="morning"
                                onChange={handleTimeOfDayChange}
                              />
                            </div>
                            <div className="flex-grow-1">
                              <div className="fw-600">
                                  Matin
                              </div>
                            </div>
                          </div>
                        </label>
                        <label className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start w-250px h-46px p-6 mb-5 ${watchTimeOfDay === 'evening' ? 'active' : ''}`}>
                          <div className="d-flex align-items-center me-2">
                            <div className="form-check form-check-custom form-check-solid form-check-primary form-check-sm me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="typeOfDay"
                                value="evening"
                                onChange={handleTimeOfDayChange}
                              />
                            </div>
                            <div className="flex-grow-1">
                              <div className="fw-600">
                                  Après-midi
                              </div>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='row mb-5'>
                  <div className='col-md-2'>
                    <div className='form-label mt-3 fs-6 text-dark required'>Date de début</div>
                  </div>
                  <div className='col-md-10 row pe-0'>
                    <div className='col-md-5'>
                      <InputMask
                        {...register('startDate')}
                        name='startDate'
                        className="form-control form-control-solid"
                        mask='9999-99-99' placeholder="----/--/--"
                      />
                      {errors.startDate && errors.startDate.message && (
                        <div className='fv-plugins-message-container text-danger'>
                          <span role='alert'>{errors.startDate.message}</span>
                        </div>
                      )}
                    </div>
                    <div className='col-md-2'>
                      <div className='form-label mt-3 fs-6 text-dark required'>Date de fin</div>
                    </div>
                    <div className='col-md-5 pe-0'>
                      <InputMask
                        {...register('endDate')}
                        name='endDate'
                        className="form-control form-control-solid"
                        mask='9999-99-99' placeholder="----/--/--"
                      />
                      {errors.endDate && errors.endDate.message && (
                        <div className='fv-plugins-message-container text-danger'>
                          <span role='alert'>{errors.endDate.message}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {watchLeaveType === 'sickness_leave' && (
                <div className='row mb-5'>
                  <DropZone files={files} handleFiles={handleFiles} isMultiple={true} />
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn btn-sm btn-secondary'
            onClick={handleClose}
          >
            {intl.formatMessage({ id: 'action.close' })}
          </button>
          <button
            type='submit'
            className='btn btn-sm btn-primary'
          >
            {intl.formatMessage({ id: 'action.save' })}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default CreateLeave
