import React from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

type Props = {
  cashFlow : ICashFlow|null|undefined
}

const CashFlowNavBar : React.FC<Props> = ({ cashFlow }) => {
  const intl = useIntl()
  return (
    <>
      <div className="mb-6 ms-1">
        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
          <li className='nav-item'>
            <Link
              className = {`nav-link text-active-primary me-6 ${location.pathname === `/cash-flow/${cashFlow?.uuid}/transactions` ? 'active' : ''}`}
              to={`/cash-flow/${cashFlow?.uuid}/transactions`}
            >
              {intl.formatMessage({ id: 'accounting.cash.flow.transactions' })}
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              className = {`nav-link text-active-primary me-6 ${location.pathname === `/cash-flow/${cashFlow?.uuid}/bank-deposit` ? 'active' : ''}`}
              to={`/cash-flow/${cashFlow?.uuid}/bank-deposit`}
            >
              {intl.formatMessage({ id: 'accounting.cash.flow.deposit' })}
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              className = {`nav-link text-active-primary me-6 ${location.pathname === `/cash-flow/${cashFlow?.uuid}/operation` ? 'active' : ''}`}
              to={`/cash-flow/${cashFlow?.uuid}/operation`}
            >
              {intl.formatMessage({ id: 'accounting.cash.flow.operation' })}
            </Link>
          </li>
        </ul>
      </div>
    </>
  )
}

export default CashFlowNavBar
