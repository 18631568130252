import React, { useEffect, useState } from 'react'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { useIntl } from 'react-intl'
import ProductFeatureToolbar from './ProductFeatureToolbar'
import ProductFeatureListing from './components/ProductFeatureListing'
import Functions from '../../../../setup/utilities/Functions'

const productFeatureBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ProductFeatureWrapper: React.FC = () => {
  const [productFeature, setProductFeature] = useState<IProductFeature|undefined|null>(null)
  const [action, setAction] = useState<string>('')
  const [show, setShow] = React.useState(false)
  const intl = useIntl()

  const handleClose = async (isSubmit: boolean) => {
    if (!isSubmit) {
      const isConfirmed = await Functions.sweetAlert(
        '<span class="mt-5">Are you sure you would like to cancel</span>',
        'warning',
        true,
        'Yes, cancel it!',
        'No, return',
        'btn btn-primary mt-10 mb-10',
        'btn btn-active-light mt-10 mb-10',
      )
      if (isConfirmed) {
        setShow(false)
      }
    } else {
      setShow(false)
    }
  }

  const handleShow = (productFeature: IProductFeature|undefined|null, action: string) => {
    setProductFeature(productFeature)
    setAction(action)
    setShow(true)
  }

  useEffect(() => {}, [productFeature])

  return (
    <>
      <PageTitle breadcrumbs={productFeatureBreadCrumbs} pageTitle={intl.formatMessage({ id: 'product-feature.list' })}>
        <ProductFeatureToolbar handleShow={handleShow}/>
      </PageTitle>
      <ProductFeatureListing show={show} handleShow={handleShow} handleClose={handleClose} productFeatureSelected={productFeature} action={action}/>
    </>
  )
}

export default ProductFeatureWrapper
