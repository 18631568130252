import apiService from '../../setup/axios/ApiService'

const getUserByToken = (token:string) => {
  const headers = {
    'Authorization': `Bearer ${token}`,
  }
  return apiService.get('user/info', undefined, headers)
}

const UserService = {
  getUserByToken,
}

export default UserService
