import React from 'react'
import {KTSVG} from "../../helpers";
import format from "date-fns/format";

type Props = {
    date: string,
    nextDate: () => void,
    previousDate: () => void
}
const JourneyHeaderDate : React.FC<Props> = ({date,nextDate,previousDate}) => (
    <>
        <div className="mb-3">
            <div className="d-flex align-items-start">
                <a className='btn btn-icon bg-med-light-primary w-40px h-40px rounded-3' onClick={previousDate}>
                    <KTSVG
                        path='/media/icons/duotune/arrows/arr022.svg'
                        className='svg-icon svg-icon-2 svg-icon-dark'
                    />
                </a>
                <a className='btn btn-icon bg-med-light-primary ms-2 w-40px h-40px rounded-3' onClick={nextDate}>
                    <KTSVG
                        path='/media/icons/duotune/arrows/arr023.svg'
                        className='svg-icon svg-icon-2 svg-icon-dark'
                    />
                </a>
                <span className="text-dark fs-4 fw-semibold mt-2 ms-5">{format(new Date(date), 'dd')} {format(new Date(date), 'MMMM')} {format(new Date(date), 'yyyy')}</span>
            </div>
        </div>
    </>
)

export default JourneyHeaderDate