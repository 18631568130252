import React, { useEffect, useState } from 'react'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import EmployeeToolbar from './components/EmployeeToolbar'
import EmployeeListing from './components/EmployeeListing'
import EmployeeListingMobile from './components/EmployeeListingMobile'
import { useIntl } from 'react-intl'
import useWindowDimensions from '../../../../_metronic/partials/mobile-check-view/useCheckMobileScreen'
import Functions from '../../../../setup/utilities/Functions'
import EmployeeService from '../../../services/Hr/EmployeeService'
import PaginationBar from '../../../../_metronic/partials/pagination/PaginationBar'


const employeeBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const EmployeeWrapper: React.FC = () => {
  const intl = useIntl()
  const [show, setShow] = React.useState(false)
  const [employee, setEmployee] = useState<IEmployee|undefined|null>(null)
  const [action, setAction] = useState<string>('')
  const [currentStep, setCurrentStep] = useState<number>(1)
  const handleClose = async () => {
    const isConfirmed = await Functions.sweetAlert(
      '<span class="mt-5">' + intl.formatMessage({ id: 'action.message' }) + '</span>',
      'warning',
      true,
      'Oui, annuler',
      'Retour',
      'btn btn-primary mt-10 mb-10',
      'btn btn-light mt-10 mb-10',
    )
    if (isConfirmed) {
      setShow(false)
      setCurrentStep(1)
    }
  }

  const handleSubmit = async () => {
    const isConfirmed = await Functions.sweetAlert(
      '<span class="mt-5">Employee Created Successfully</span>',
      'success',
      true,
      'Ok',
      '',
      'btn btn-primary ms-20',
      'btn btn-active-light',
    )
    if (isConfirmed) {
      setShow(false)
      setCurrentStep(1)
    }
  }
  const handleShow = (employee: IEmployee|undefined|null, action: string) => {
    setEmployee(employee)
    setShow(true)
    setAction(action)
  }

  const isMobile = useWindowDimensions()

  const [employees, setEmployees] = useState<IEmployee[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = React.useState(1)

  const handleCurrentPage = (page:number) => {
    setCurrentPage(page)
  }

  const handleIsLoading = (isLoading: boolean) => {
    setIsLoading(isLoading)
  }

  const changeStatus = (event: any, employeeUuid: string) => {
    EmployeeService.updateStatus(employeeUuid, {
      'active': event.target.checked,
    }).then(() => {
      handleIsLoading(true)
    })
  }

  useEffect(() => {
    EmployeeService.getAllEmployees(currentPage, 10, true).then((response) => {
      setEmployees(response.data.data.list)
      setTotalPages(response.data.data.totalPages)
      setIsLoading(false)
    })
  }, [isLoading, currentPage])

  useEffect(() => {}, [employee])
  return (
    <>
      <PageTitle breadcrumbs={employeeBreadCrumbs} pageTitle={intl.formatMessage({ id: 'employee.list' })}>
        <EmployeeToolbar handleShow={handleShow}/>
      </PageTitle>
      {isMobile ? (
        <EmployeeListingMobile
          handleShow={handleShow}
          handleSubmit={handleSubmit}
          handleClose={handleClose}
          show={show}
          employees={employees}
          handleIsLoading={handleIsLoading}
          isLoading={isLoading}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          action={action}
          employeeSelected={employee}
        />
      ) : (
        <EmployeeListing
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          show={show}
          changeStatus={changeStatus}
          employees={employees}
          handleIsLoading={handleIsLoading}
          isLoading={isLoading}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          action={action}
          employeeSelected={employee}
          handleShow={handleShow}/>
      )}
      {totalPages > 1 ? (
        <PaginationBar
          totalPages={totalPages}
          currentPage={currentPage}
          handleCurrentPage={handleCurrentPage}
        />
      ) : (<></>)}
    </>
  )
}

export default EmployeeWrapper
