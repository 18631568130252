import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { KTSVG } from '../../../../../../../../_metronic/helpers'
import Symbol from '../../../../../../../../_metronic/partials/common/Symbol'
import Functions from '../../../../../../../../setup/utilities/Functions'
import EmployeeService from '../../../../../../../services/Hr/EmployeeService'
import JourneyService from '../../../../../../../services/Crm/JourneyService'

type Props = {
  journey: IJourney|null,
  setIsLoading : (isLoading: boolean) => void,
}
const DropDownManagers : React.FC<Props> = ({ journey, setIsLoading }) => {
  const [managers, setManagers] = useState<{ uuid: string, profilePicture: string, firstName: string, lastName:string, isChecked: boolean }[]>([])


  const checkManager = (index: number) => {
    const newManagers = [...managers]
    newManagers[index]['isChecked'] = !newManagers[index]['isChecked']
    setManagers(newManagers)
    const data = {
      managerUuid: newManagers[index]['uuid'],
    }
    JourneyService.chooseManager(journey!.uuid, data).then(() => {
      setIsLoading(true)
    })
  }

  useEffect(() => {
    EmployeeService.getManagersByCurrentUser().then((response) => {
      setManagers(response.data.data)
    })
  }, [])

  useEffect(() => {}, [managers])

  return (
    <>
      <div className="ms-3">
        <div className="d-flex align-content-start me-5">
          <div className="image-input image-input-outline" data-kt-image-input="true">
            <Dropdown drop={'down'}>
              <Dropdown.Toggle
                variant="text-dark"
                bsPrefix={'t'}
                className={'btn btn-icon btn-circle btn-active-color-primary w-40px h-40px shadow border border-white'}
              >
                <KTSVG
                  path={'/media/icons/duotune/abstract/eye.svg'}
                  className='svg-icon svg-icon-1 svg-icon-white' />
              </Dropdown.Toggle>

              <Dropdown.Menu className="w-200px" align='start'>
                <span className="fs-7 ms-3 fw-bold">Visite avec</span>
                {managers && managers.map((manager, index) => (
                  <div key={`employee_${index}`} className='menu-item'>
                    <div className="d-flex align-items-start mt-2">
                      <div className="form-check form-check-custom form-check-solid ms-3">
                        <input className="form-check-input form-check-success rounded-circle" type="radio" name="manager" checked={manager.isChecked} onChange={() => {
                          checkManager(index)
                        }}/>
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                          <Symbol
                            picture={manager.profilePicture}
                            picturePath='/admin/uploads/users/'
                            withText={true}
                            widthClassName='symbol-30px'
                            text={Functions.fullName(manager.firstName, manager.lastName)}
                            shortText={Functions.firstLetterUpperCase(manager.firstName.charAt(0)) + Functions.firstLetterUpperCase(manager.lastName.charAt(0))}
                            description={null}
                            backgroundClass='bg-light-primary'
                            textClassName='fs-7 fw-400'
                            isCircular={false}
                            descriptionColor={''}
                            textColor={'text-dark fw-bold fs-7 ms-1'}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                ))}

              </Dropdown.Menu>
            </Dropdown>

            <span className="btn btn-icon btn-circle btn-warning h-15px w-15px me-10" data-kt-image-input-action="change" data-bs-toggle="tooltip" title=""
              data-bs-original-title="Change avatar">
              <span className="text-dark fw-bold">{managers.filter((item) => item.isChecked).length}</span>
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default DropDownManagers
