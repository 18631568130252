import React, { useEffect, useState } from 'react'
import MedicalProfessionalService from '../../../../services/Crm/MedicalProfessionalService'
import MedicalProfessionalListing from './MedicalProfessionalListing'

type Props = {
  show: boolean,
  selectedMedicalProfessional: IMedicalProfessional|null|undefined
  handleShow: (medicalProfessional:IMedicalProfessional|undefined|null) => void
  handleClose: (isSubmit: boolean) => void
}

const MedicalProfessionalFilter : React.FC<Props> = ({
  selectedMedicalProfessional,
  show,
  handleClose,
  handleShow,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [medicalProfessionals, setMedicalProfessionals] = useState<IMedicalProfessional[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = React.useState(1)
  const [total, setTotal] = React.useState(1)

  const handleIsLoading = (loading: boolean) => {
    setIsLoading(loading)
  }

  const handleCurrentPage = (page:number) => {
    setIsLoading(true)
    setCurrentPage(page)
  }

  useEffect(() => {
    MedicalProfessionalService.getAllMedicalProfessional(currentPage, 10).then((response) => {
      setMedicalProfessionals(response.data.data.list)
      setTotalPages(response.data.data.totalPages)
      setTotal(response.data.data.total)
      setIsLoading(false)
    })
  }, [isLoading, currentPage])

  return (
    <>
      <MedicalProfessionalListing
        currentPage={currentPage}
        handleCurrentPage={handleCurrentPage}
        medicalProfessionals={medicalProfessionals}
        isLoading={isLoading}
        totalPages={totalPages}
        handleIsLoading={handleIsLoading}
        limit={10}
        total={total}
      />
    </>
  )
}

export default MedicalProfessionalFilter
