import React from 'react'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { useIntl } from 'react-intl'

type Props = {
  supplier: ISupplier|null|undefined
}

const SupplierDetailsHeader : React.FC<Props> = ({ supplier }) => {
  const intl = useIntl()

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <a className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                      {supplier?.name}
                    </a>
                    <a href='src/app/modules/accounting/supplier/components/display/SupplierDetailsHeader#'>
                      <KTSVG
                        path='/media/icons/duotune/general/gen026.svg'
                        className='svg-icon-1 svg-icon-primary'
                      />
                    </a>
                  </div>

                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <a
                      href='src/app/modules/accounting/supplier/components/display/SupplierDetailsHeader#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen018.svg'
                        className='svg-icon-4 me-1'
                      />
                      {supplier?.location ? supplier.location.name : ''}, {supplier?.country ? supplier.country.name : ''}
                    </a>
                    <a
                      href='src/app/modules/accounting/supplier/components/display/SupplierDetailsHeader#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                    >
                      <KTSVG
                        path='/media/icons/duotune/communication/com011.svg'
                        className='svg-icon-4 me-1'
                      />
                      {supplier?.contacts && supplier?.contacts.map((contact, index) => (
                        <div key={`contact_${index}`}>
                          {contact.isDefault ? contact.email : ''}
                        </div>
                      ))}
                    </a>
                  </div>
                </div>

                <div className='d-flex my-4'>
                  <a href='src/app/modules/accounting/supplier/components/display/SupplierDetailsHeader#' className='btn btn-sm btn-light me-2' id='kt_user_follow_button'>
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr012.svg'
                      className='svg-icon-3 d-none'
                    />

                    <span className='indicator-label'>Follow</span>
                    <span className='indicator-progress'>
                    Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  </a>
                  <a
                    href='src/app/modules/accounting/supplier/components/display/SupplierDetailsHeader#'
                    className='btn btn-sm btn-primary me-3'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_offer_a_deal'
                  >
                      Hire Me
                  </a>
                  <div className='me-0'>
                    <button
                      className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                    >
                      <i className='bi bi-three-dots fs-3'></i>
                    </button>
                  </div>
                </div>
              </div>

              <div className='d-flex flex-wrap flex-stack'>
                <div className='d-flex flex-column flex-grow-1 pe-8'>
                  <div className='d-flex flex-wrap'>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr066.svg'
                          className='svg-icon-3 svg-icon-success me-2'
                        />
                        <div className='fs-2 fw-bolder'>4500$</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>Earnings</div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr065.svg'
                          className='svg-icon-3 svg-icon-danger me-2'
                        />
                        <div className='fs-2 fw-bolder'>75</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>Projects</div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr066.svg'
                          className='svg-icon-3 svg-icon-success me-2'
                        />
                        <div className='fs-2 fw-bolder'>60%</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>Success Rate</div>
                    </div>
                  </div>
                </div>

                <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                  <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                    <span className='fw-bold fs-6 text-gray-400'>Profile Compleation</span>
                    <span className='fw-bolder fs-6'>50%</span>
                  </div>
                  <div className='h-5px mx-3 w-100 bg-light mb-3'>
                    <div
                      className='bg-success rounded h-5px'
                      role='progressbar'
                      style={{ width: '50%' }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item'>
                <Link
                  className = {`nav-link text-active-primary me-6 ${location.pathname === `/supplier/${supplier?.uuid}/overview` ? 'active' : ''}`}
                  to={`/supplier/${supplier?.uuid}/overview`}
                >
                  {intl.formatMessage({ id: 'accounting.supplier.overview' })}
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className = {`nav-link text-active-primary me-6 ${location.pathname === `/supplier/${supplier?.uuid}/settings` ? 'active' : ''}`}

                  to={`/supplier/${supplier?.uuid}/settings`}
                >
                  {intl.formatMessage({ id: 'accounting.supplier.settings' })}
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className = {`nav-link text-active-primary me-6 ${location.pathname === `/supplier/${supplier?.uuid}/supplier-orders` ? 'active' : ''}`}
                  to={`/supplier/${supplier?.uuid}/supplier-orders`}
                >
                  {intl.formatMessage({ id: 'accounting.supplier.supplier.orders' })}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}
export default SupplierDetailsHeader
