import apiService from '../../../setup/axios/ApiService'

const prefixURL = 'equipment'

const getAllEquipments = (currentPage:number, limit:number) => apiService.get(`${prefixURL}/`, {
  page: currentPage,
  limit,
})

const createEquipment = (data: any) => apiService.post(`${prefixURL}/`, data)

const updateEquipment = (uuid: string|undefined, data: any) => apiService.put(`${prefixURL}/${uuid}`, data)

const deleteEquipment = (uuid: string|undefined) => apiService.deletes(`${prefixURL}/${uuid}`)

const selectEquipments = () => apiService.get(`${prefixURL}/select/select-equipment`)

const getEquipment = (uuid:string|undefined) => apiService.get(`${prefixURL}/${uuid}`)

const EquipmentService = {
  getAllEquipments,
  createEquipment,
  updateEquipment,
  deleteEquipment,
  selectEquipments,
  getEquipment,
}

export default EquipmentService
