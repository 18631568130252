import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { useIntl } from 'react-intl'
import { MenuComponent } from '../../../../_metronic/assets/ts/components'
import ContactService from '../../../services/Accounting/ContactService'
import Functions from '../../../../setup/utilities/Functions'

type Props = {
    isCreate: boolean,
    contact : IContact|null,
    page: string,
    handleIsLoading: (isLoading: boolean) => void,
    handleShow: (contact : IContact|null, action : string) => void,
    isLoading: boolean,
}

const ContactItem : React.FC<Props> = ({ contact, page, handleIsLoading, isCreate, handleShow }) => {
  const intl = useIntl()
  const [borderClass, setBorderClass] = useState<string>('')

  const changeStatus = (event: any) => {
    ContactService.updateStatus(contact?.uuid, {
      'isDefault': event.target.checked,
    }).then(() => {
      handleIsLoading(true)
    })
  }

  const deleteContact = async (uuid:string|undefined) => {
    const isConfirmed = await Functions.sweetAlert(
      '<span class="mt-5">Are you sure you want to delete this profile</span>',
      'warning',
      true,
      'Yes, delete!',
      'No, cancel',
      'btn btn-danger mt-10 mb-10',
      'btn btn-active-light mt-10 mb-10',
    )
    if (isConfirmed) {
      ContactService.deleteContact(uuid).then(() => {
        handleIsLoading(true)
      })
    }
  }

  useEffect(() => {
    MenuComponent.reinitialization()
    if (isCreate) {
      setBorderClass('border-bottom-light')
    } else if (contact?.isDefault) {
      setBorderClass('border-bottom-success')
    } else {
      setBorderClass('border-bottom-danger')
    }
  }, [contact])

  return (
    <div className={`card card-stretch ${borderClass}`}>

      <div className={`card-body d-flex flex-column p-0 ${isCreate ? 'align-items-center' : ''}`}>
        {!isCreate ? (
          <>
            <div className={`flex-grow-1 card-px ${page !== 'display' ? 'pb-10' : ''}`}>
              <div className='me-2 pt-5 d-flex flex-stack flex-wrap'>
                <span
                  className={`badge me-auto px-4 py-3 ${contact?.isDefault ? 'badge-light-success' : 'badge-light-danger'}`}>
                  {contact?.isDefault ? 'Par défaut' : 'Déactivé'}
                </span>
                <div className="form-check form-switch form-check-custom form-check-solid">
                  <input className="form-check-input cursor-pointer" type="checkbox"
                    checked={contact?.isDefault} id="flexSwitchChecked" onChange={changeStatus}/>
                </div>
              </div>
              <div className='d-flex flex-stack flex-wrap mt-1'>
                <div className='me-2'>
                  <span className="text-dark text-hover-primary fw-bold fs-3" >
                    {contact?.name} | {contact?.jobTitle}
                  </span>
                </div>
              </div>
              <div className='me-2 pt-2 d-flex flex-stack flex-wrap'>
                <span className="text-muted fw-bold text-muted d-block fs-7">{contact?.email} | {contact?.phone}</span>

                <div className="pb-4">
                  <button
                    type='button'
                    className='btn btn-light btn-active-light-primary btn-sm'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip="top-end"
                  >
                            Options
                    <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
                  </button>
                  <div
                    className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                    data-kt-menu='true'
                  >
                    <div className='menu-item px-3'>
                      <a className='menu-link px-3'
                        onClick={() => handleShow(contact, 'edit')}
                      >
                        {intl.formatMessage({ id: 'action.edit' })}
                      </a>
                    </div>
                    <div className='menu-item px-3'>
                      <a
                        className='menu-link px-3'
                        data-kt-users-table-filter='delete_row'
                        onClick={async () => deleteContact(contact?.uuid)}
                      >
                        {intl.formatMessage({ id: 'action.delete' })}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={`flex-grow-1 card-px ${page !== 'display' ? 'pb-10' : ''}`}>
              <div className='d-flex flex-stack align-items-center flex-wrap fs-3 fw-bold mt-5'>
                {intl.formatMessage({ id: 'accounting.contact.create.button' })}
              </div>
              <div className='text-center fs-3 fw-bold pt-5'>
                <button
                  type="button"
                  className="btn btn-lg btn-light"
                  onClick={() => handleShow(null, 'create')}
                >
                  <KTSVG
                    path='/media/icons/duotune/abstract/plus.svg'
                    className='svg-icon-4 svg-icon-gray-500 me-1'
                  />
                </button>
              </div>
            </div>
          </>
        )}
      </div>
      <div className={`card-footer border-top-0 ${page === 'display' ? 'p-0' : 'p-1-25'}`}>
      </div>
    </div>
  )
}

export default ContactItem
