/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, { FC } from 'react'
import { useLayout, usePageData } from '../../core'

const Toolbar1: FC = () => {
  const { classes } = useLayout()
  const { toolbarContent } = usePageData()

    return (
    <div className='toolbar' id='kt_toolbar'>
      {/* begin::Container */}
      <div
        id='kt_toolbar_container'
        className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
      >
        {toolbarContent}
      </div>
      {/* end::Container */}
    </div>
  )
}

export { Toolbar1 }
