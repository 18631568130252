import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import InvoiceService from '../../../../../../services/Accounting/InvoiceService'
import TableHeaderSolid from '../../../../../../../_metronic/partials/med-table/TableHeaderSolid'
import { KTSVG } from '../../../../../../../_metronic/helpers'
import PaginationBar from '../../../../../../../_metronic/partials/pagination/PaginationBar'
import WorkflowService from '../../../../../../services/WorkflowService'
import format from 'date-fns/format'
import PurchaseOrderService from '../../../../../../services/Accounting/PurchaseOrderService'
import EstimateDetails from '../../../../estimate/components/EstimateDetails'
import EstimateService from '../../../../../../services/Accounting/EstimateService'


const Estimates : React.FC = () => {
  const { id } = useParams()
  const intl = useIntl()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [estimates, setEstimates] = useState<IEstimate[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = React.useState(1)
  const [showDetails, setShowDetails] = useState<boolean>()
  const [workflowInstanceTasks, setWorkflowInstanceTasks] = useState<IWorkflowInstanceTask[]>()
  const handleShowDetails = (workflowInstanceTask: IWorkflowInstanceTask[]) => {
    setShowDetails(true)
    setWorkflowInstanceTasks(workflowInstanceTask)
  }
  const handleCloseDetails = () => setShowDetails(false)

  const handleCurrentPage = (page:number) => {
    setIsLoading(true)
    setCurrentPage(page)
  }

  const headers = [
    {
      minWidth: 'w-50px',
      text: intl.formatMessage({ id: 'accounting.invoice.entity.reference' }),
    },
    {
      minWidth: 'w-50px',
      text: intl.formatMessage({ id: 'accounting.invoice.entity.paymentDate' }),
    },
    {
      minWidth: 'w-50px',
      text: intl.formatMessage({ id: 'accounting.invoice.entity.grossAmount' }),
    },
    {
      minWidth: 'w-150px',
      text: intl.formatMessage({ id: 'accounting.invoice.entity.status' }),
    },
    {
      minWidth: 'w-50px',
      text: intl.formatMessage({ id: 'actions' }),
    },
  ]

  useEffect(() => {
    EstimateService.getEstimatesByCustomer(currentPage, 10, id).then((response) => {
      setEstimates(response.data.data.list)
      setTotalPages(response.data.data.totalPages)
      setIsLoading(false)
    })
  }, [isLoading, currentPage, status])

  const changeStatus = (value: string, workflowInstance: IWorkflowInstance, transition: string) => {
    const data = [
      {
        field: 'status',
        type: 'string',
        old_value: workflowInstance.status,
        new_value: value,
        isArrayField: false,
        isNewEntityInstance: workflowInstance.workflowType === 'convert_process',
      },
    ]

    WorkflowService.changeWorkflowInstance(workflowInstance.uuid, transition, data).then(() => {
      setIsLoading(true)
    })
  }

  useEffect(() => {}, [workflowInstanceTasks])

  const convert = (estimate: IEstimate, workflowInstance: IWorkflowInstance, transition: string) => {
    const convertWorkflowInstance = estimate?.workflowInstances.find((workflowInstance) => workflowInstance.workflowType === 'convert_process')
    const data = {
      paymentDate: format(new Date(), 'yyyy/MM/dd'),
      customer: estimate.customer.uuid,
      dueDate: estimate.issueDate,
      grossAmount: estimate.grossAmount,
      netAmount: estimate.netAmount,
      documentLines: estimate.documentLines,
      convertWorkflowInstance: convertWorkflowInstance?.uuid,
      transition,
      type: transition === 'to_invoice' ? 'invoice_workflow' : 'purchase_order_workflow',
    }
    if (transition === 'to_invoice') {
      InvoiceService.createInvoice(data).then((response) => {
        navigate('/invoice/create', { state: response.data.data })
      })
    } else if (transition === 'to_purchase_order') {
      PurchaseOrderService.createPurchaseOrder(data).then((response) => {
        navigate('/purchase-order/create', { state: response.data.data })
      })
    }
  }

  const duplicate = (estimate: IEstimate) => {
    console.log(estimate)
    EstimateService.duplicateEstimate(estimate.uuid).then(() => {
      setIsLoading(true)
    })
  }

  return (
    <>
      <div className={`card mb-xl-10 ${isLoading ? 'overlay overlay-block' : ''}`}>
        <div className={`card-body pt-0 ${isLoading ? 'overlay-wrapper' : ''}`}>
          <div className="table-responsive">
            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 mt-10">
              <TableHeaderSolid headers={headers} />
              <tbody>
                {estimates && estimates.length > 0 ? (
                  estimates.map((estimate, index) => (
                    <tr key={`estimate_${index}`}>
                      <td>
                        <span className="text-muted">{estimate.reference}</span>
                      </td>
                      <td>
                        <span className="text-muted">{estimate.paymentDate}</span>
                      </td>
                      <td>
                        <span className="text-muted">{estimate.grossAmount}</span>
                      </td>
                      <td>
                        {estimate.workflowInstances && estimate.workflowInstances.map((workflowInstance, index) => (
                          <div key={`workflow_${index}`}>
                            {workflowInstance.workflowType === 'estimate_workflow' ?
                              <>
                                <button
                                  type='button'
                                  className={`btn btn-light btn-sm w-125px ${workflowInstance.status === 'draft' ? 'btn-light-warning' : workflowInstance.status === 'created' ? 'btn-light-info' : workflowInstance.status === 'negotiation' ? 'btn-light-primary' : workflowInstance.status === 'accepted' ? 'btn-light-success' : 'btn-light-danger'}`}
                                  data-kt-menu-trigger='click'
                                  data-kt-menu-placement='bottom-end'
                                  data-kt-menu-flip="top-end"
                                >
                                  {workflowInstance.status}
                                  <KTSVG
                                    path='/media/icons/duotune/arrows/arr072.svg'
                                    className='svg-icon-4 svg-icon-gray-500 me-1'
                                  />
                                </button>
                                <div
                                  className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                                  data-kt-menu='true'
                                >
                                  {workflowInstance.status === 'created' ?
                                    <>
                                      <div className='menu-item'>
                                        <a className='menu-link' defaultValue="canceled" onClick={() => changeStatus('cancelled', workflowInstance, 'to_canceled')}>
                                                Cancel
                                        </a>
                                      </div>
                                      <div className='menu-item'>
                                        <a className='menu-link' defaultValue="accepted" onClick={() => changeStatus('accepted', workflowInstance, 'to_accepted')}>
                                                Accept
                                        </a>
                                      </div>
                                      <div className='menu-item'>
                                        <a className='menu-link' defaultValue="negotiation" onClick={() => changeStatus('negotiation', workflowInstance, 'to_negotiation')}>
                                                Negotiation
                                        </a>
                                      </div>
                                    </> :
                                    <>
                                      {workflowInstance.status === 'negotiation' ?
                                        <>
                                          <div className='menu-item'>
                                            <a className='menu-link' onClick={() => changeStatus('cancelled', workflowInstance, 'to_canceled')}>
                                                      Cancel
                                            </a>
                                          </div>
                                          <div className='menu-item'>
                                            <a className='menu-link' onClick={() => changeStatus('accepted', workflowInstance, 'to_accepted')}>
                                                      Accept
                                            </a>
                                          </div>
                                        </> :
                                        <>
                                          {workflowInstance.status === 'accepted' ?
                                            <>
                                              <div className='menu-item'>
                                                <a className='menu-link' onClick={() => changeStatus('cancelled', workflowInstance, 'to_canceled')}>
                                                            Cancel
                                                </a>
                                              </div>
                                            </> :
                                            <>
                                              {workflowInstance.status === 'canceled' ?
                                                <>
                                                  <div className='menu-item'>
                                                    <a className='menu-link' onClick={() => changeStatus('draft', workflowInstance, 'to_draft')}>
                                                                  Reactivate
                                                    </a>
                                                  </div>
                                                </> :
                                                <>
                                                  {workflowInstance.status === 'draft' ?
                                                    <>
                                                      <div className='menu-item'>
                                                        <a className='menu-link' onClick={() => changeStatus('created', workflowInstance, 'to_created')}>
                                                                        Create
                                                        </a>
                                                      </div>
                                                    </> :
                                                    <>
                                                    </>
                                                  }
                                                </>
                                              }
                                            </>
                                          }
                                        </>
                                      }
                                    </>
                                  }

                                </div>
                                <a onClick={() => handleShowDetails(workflowInstance.workflowInstanceTasks)} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm ms-5">
                                  <KTSVG
                                    path='/media/icons/duotune/general/gen038.svg'
                                    className='svg-icon svg-icon-2'
                                  />
                                </a>
                              </> :
                              <></>
                            }
                          </div>
                        ))}
                      </td>
                      <td className="text-end">
                        {estimate.workflowInstances && estimate.workflowInstances.map((workflowInstance, index) => (
                          <div key={`workflow_convert_${index}`}>
                            {workflowInstance.workflowType === 'convert_process' ?
                              <>
                                <button
                                  type='button'
                                  className='btn btn-light btn-active-light-primary btn-sm'
                                  data-kt-menu-trigger='click'
                                  data-kt-menu-placement='bottom-end'
                                  data-kt-menu-flip="top-end"
                                >
                                      Options
                                  <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
                                </button>
                                <div
                                  className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                                  data-kt-menu='true'
                                >
                                  {workflowInstance.status === 'estimate' ?
                                    <>
                                      <div className='menu-item'>
                                        <a className='menu-link' onClick={() => convert(estimate, workflowInstance, 'to_purchase_order')}>
                                                Purchase Order
                                        </a>
                                      </div>
                                      <div className='menu-item'>
                                        <a className='menu-link' onClick={() => convert(estimate, workflowInstance, 'to_invoice')}>
                                                Invoice
                                        </a>
                                      </div>
                                    </> :
                                    <></>
                                  }
                                  <div className='menu-item'>
                                    <a className='menu-link' onClick={() => handleShowDetails(workflowInstance.workflowInstanceTasks)}>
                                          Details
                                    </a>
                                  </div>

                                  <div className='menu-item'>
                                    <a className='menu-link' onClick={() => duplicate(estimate)}>
                                          Duplicate
                                    </a>
                                  </div>
                                </div>
                              </> :
                              <></>
                            }
                          </div>
                        ))}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 fs-4 align-content-center justify-content-center mt-5'>
                        {intl.formatMessage({ id: 'action.not.found' })}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <EstimateDetails showDetails={showDetails ? showDetails : false} handleCloseDetails={handleCloseDetails} workflowInstanceTasks={workflowInstanceTasks ? workflowInstanceTasks : []}/>
        {totalPages > 1 ? (
          <PaginationBar
            totalPages={totalPages}
            currentPage={currentPage}
            handleCurrentPage={handleCurrentPage}
          />
        ) : (<></>)}
        {isLoading &&
                    <div className="overlay-layer rounded bg-dark bg-opacity-5">
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="visually-hidden">{intl.formatMessage({ id: 'action.loading' })}</span>
                      </div>
                    </div>
        }
      </div>
    </>
  )
}

export default Estimates
