import apiService from '../../setup/axios/ApiService'

const prefixURL = 'country'

const selectCountry = () => apiService.get(`${prefixURL}/select-country`)

const CountryService = {
  selectCountry,
}

export default CountryService
