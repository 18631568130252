import React, { useCallback, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../../../_metronic/helpers'
import Select from 'react-select'
import CashFlowService from '../../../../../services/Accounting/CashFlowService'
import BankAccountService from '../../../../../services/Accounting/BankAccountService'
import PaymentService from '../../../../../services/Accounting/PaymentService'
import currency from 'currency.js'
import Functions from '../../../../../../setup/utilities/Functions'
import PaymentMethodService from '../../../../../services/Accounting/PaymentMethodService'
import BankService from '../../../../../services/Accounting/BankService'
import { DateTimePicker } from '../../../../../../_metronic/partials/date-time-picker'

type Props = {
  invoice: IInvoice|null,
  show: boolean,
  handleClose: (show: boolean) => void,
  handleIsLoading: (isLoading: boolean) => void,
  remainingPayment: number,
  setRemainingPayment: (remainingPayment: number) => void
}

const colourStyles = {
  control: (styles: any) => ({ ...styles, backgroundColor: '#F5F8FA', border: 0, height: '45px' }),
}

const PaymentCreate : React.FC<Props> = ({ invoice, show, handleClose, handleIsLoading, remainingPayment, setRemainingPayment }) => {
  const intl = useIntl()
  const [formValues, setFormValues] = useState<any>([])
  const [cashFlows, setCashFlows] = useState<{value: string, label: string}[]>([])
  const [bankAccounts, setBankAccounts] = useState<{value: string, label: string}[]>([])
  const [banks, setBanks] = useState<{value: string, label: string}[]>([])
  const [paymentMethods, setPaymentMethods] = useState<{value: string, label: string}[]>([])
  const [paymentFormType, setPaymentFormType] = useState<string>('')
  const [cashForm, setCashForm] = useState<{ paymentMethod: string|null, paymentType: string|null, amount: number|null, cashFlow: string|null, cashFlowName: string|null, date: string|null }>({ paymentMethod: null, paymentType: null, cashFlow: null, cashFlowName: null, amount: null, date: null })
  const [checkForm, setCheckForm] = useState<{ paymentMethod: string|null, paymentType: string|null, amount: number|null, cashFlow: string|null, cashFlowName: string|null, checkNumber: string|null, drawer: string|null, bank: string|null, bankName: string|null, settlementDate: string|null, dueDate: string|null, }>({ paymentMethod: null, paymentType: null, cashFlow: null, cashFlowName: null, amount: null, checkNumber: null, drawer: null, bank: null, bankName: null, settlementDate: null, dueDate: null })
  const [draftForm, setDraftForm] = useState<{ paymentMethod: string|null, paymentType: string|null, amount: number|null, cashFlow: string|null, cashFlowName: string|null, draftNumber: string|null, sourceAccount: string|null, settlementDate: string|null, dueDate: string|null, }>({ paymentMethod: null, paymentType: null, cashFlow: null, cashFlowName: null, amount: null, draftNumber: null, sourceAccount: null, settlementDate: null, dueDate: null })
  const [transferForm, setTransferForm] = useState<{ paymentMethod: string|null, paymentType: string|null, amount: number|null, destinationAccount: string|null, destinationAccountName: string|null, transferInformation: string|null, settlementDate: string|null, }>({ paymentMethod: null, paymentType: null, amount: null, destinationAccount: null, destinationAccountName: null, transferInformation: null, settlementDate: null })

  // Add A Form to payment table
  const addForm = () => {
    if (paymentFormType === 'cash') {
      setFormValues([...formValues, cashForm])
      setCashForm({ paymentMethod: null, paymentType: null, cashFlow: null, cashFlowName: null, amount: null, date: null })
    } else if (paymentFormType === 'check') {
      setFormValues([...formValues, checkForm])
      setCheckForm({ paymentMethod: null, paymentType: null, cashFlow: null, cashFlowName: null, amount: null, bank: null, bankName: null, checkNumber: null, drawer: null, settlementDate: null, dueDate: null })
    } else if (paymentFormType === 'draft') {
      setFormValues([...formValues, draftForm])
      setDraftForm({ paymentMethod: null, paymentType: null, cashFlow: null, cashFlowName: null, amount: null, draftNumber: null, sourceAccount: null, settlementDate: null, dueDate: null })
    } else if (paymentFormType === 'transfer') {
      setFormValues([...formValues, transferForm])
      setTransferForm({ paymentMethod: null, paymentType: null, destinationAccount: null, destinationAccountName: null, amount: null, transferInformation: null, settlementDate: null })
    }
    setPaymentFormType('')
  }

  // Edit a line from Table
  const editForm = (index: number, action: string) => {
    const newFormValues = [...formValues]
    if (action === 'cash') {
      setCashForm({ paymentMethod: newFormValues[index]['paymentMethod'], paymentType: newFormValues[index]['paymentType'], amount: newFormValues[index]['amount'], cashFlow: newFormValues[index]['cashFlow'], cashFlowName: newFormValues[index]['cashFlowName'], date: newFormValues[index]['date'] })
      setPaymentFormType(action)
    } else if (action === 'check') {
      setCheckForm({ paymentMethod: newFormValues[index]['paymentMethod'], paymentType: newFormValues[index]['paymentType'], amount: newFormValues[index]['amount'], cashFlow: newFormValues[index]['cashFlow'], cashFlowName: newFormValues[index]['cashFlowName'], drawer: newFormValues[index]['drawer'], checkNumber: newFormValues[index]['checkNumber'], bank: newFormValues[index]['bank'], bankName: newFormValues[index]['bankName'], settlementDate: newFormValues[index]['settlementDate'], dueDate: newFormValues[index]['dueDate'] })
      setPaymentFormType(action)
    } else if (action === 'draft') {
      setDraftForm({ paymentMethod: newFormValues[index]['paymentMethod'], paymentType: newFormValues[index]['paymentType'], amount: newFormValues[index]['amount'], cashFlow: newFormValues[index]['cashFlow'], cashFlowName: newFormValues[index]['cashFlowName'], draftNumber: newFormValues[index]['draftNumber'], sourceAccount: newFormValues[index]['sourceAccount'], settlementDate: newFormValues[index]['settlementDate'], dueDate: newFormValues[index]['dueDate'] })
      setPaymentFormType(action)
    } else if (action === 'transfer') {
      setTransferForm({ paymentMethod: newFormValues[index]['paymentMethod'], paymentType: newFormValues[index]['paymentType'], amount: newFormValues[index]['amount'], destinationAccount: newFormValues[index]['destinationAccount'], destinationAccountName: newFormValues[index]['destinationAccountName'], transferInformation: newFormValues[index]['transferInformation'], settlementDate: newFormValues[index]['settlementDate'] })
      setPaymentFormType(action)
    }
    newFormValues.splice(index, 1)
    setFormValues(newFormValues)
  }

  // Remove the actual Form
  const removeForm = () => {
    setPaymentFormType('')
    if (paymentFormType === 'cash') {
      setCashForm({ paymentMethod: null, paymentType: null, amount: null, cashFlow: null, cashFlowName: null, date: null })
    } else if (paymentFormType === 'check') {
      setCheckForm({ paymentMethod: null, paymentType: null, amount: null, cashFlow: null, cashFlowName: null, bank: null, bankName: null, checkNumber: null, drawer: null, settlementDate: null, dueDate: null })
    } else if (paymentFormType === 'draft') {
      setDraftForm({ paymentMethod: null, paymentType: null, amount: null, cashFlow: null, cashFlowName: null, draftNumber: null, sourceAccount: null, settlementDate: null, dueDate: null })
    } else if (paymentFormType === 'transfer') {
      setTransferForm({ paymentMethod: null, paymentType: null, amount: null, destinationAccount: null, destinationAccountName: null, transferInformation: null, settlementDate: null })
    }
  }

  // Remove payment from table
  const removeFormValues = (index: number) => {
    const newFormValues = [...formValues]
    newFormValues.splice(index, 1)
    setFormValues(newFormValues)
  }

  // Change the payment method via buttons
  const onChangeMethodType = (uuid: string) => {
    PaymentMethodService.getPaymentMethod(uuid).then((response) => {
      if (response.data.data.name === 'cash') {
        setPaymentFormType('cash')
        setCashForm({
          paymentMethod: response.data.data.uuid,
          paymentType: response.data.data.name,
          amount: cashForm.amount,
          cashFlow: cashForm.cashFlow,
          cashFlowName: cashForm.cashFlowName,
          date: cashForm.date,
        })
      } else if (response.data.data.name === 'check') {
        setPaymentFormType('check')
        setCheckForm({
          paymentMethod: response.data.data.uuid,
          paymentType: response.data.data.name,
          checkNumber: checkForm.checkNumber,
          drawer: checkForm?.drawer,
          bank: checkForm?.bank,
          bankName: checkForm.bankName,
          amount: checkForm.amount,
          cashFlow: checkForm.cashFlow,
          cashFlowName: checkForm.cashFlowName,
          settlementDate: checkForm.settlementDate,
          dueDate: checkForm?.dueDate,
        })
      } else if (response.data.data.name === 'transfer') {
        setPaymentFormType('transfer')
        setTransferForm({
          paymentMethod: response.data.data.uuid,
          paymentType: response.data.data.name,
          amount: transferForm.amount,
          destinationAccount: transferForm.destinationAccount,
          destinationAccountName: transferForm.destinationAccountName,
          transferInformation: transferForm.transferInformation,
          settlementDate: transferForm.settlementDate,
        })
      } else if (response.data.data.name === 'draft') {
        setPaymentFormType('draft')
        setDraftForm({
          paymentMethod: response.data.data.uuid,
          paymentType: response.data.data.name,
          amount: draftForm.amount,
          cashFlow: draftForm.cashFlow,
          cashFlowName: draftForm.cashFlowName,
          draftNumber: draftForm.draftNumber,
          sourceAccount: draftForm.sourceAccount,
          settlementDate: draftForm.settlementDate,
          dueDate: draftForm.dueDate,
        })
      }
    })
  }

  // Change any field type in all forms
  const onChangeField = (event: any) => {
    if (paymentFormType === 'cash') {
      setCashForm({
        paymentMethod: cashForm?.paymentMethod,
        paymentType: cashForm?.paymentType,
        amount: event.target.value,
        cashFlow: cashForm.cashFlow,
        cashFlowName: cashForm.cashFlowName,
        date: cashForm?.date,
      })
    } else if (paymentFormType === 'check') {
      setCheckForm({
        paymentMethod: checkForm?.paymentMethod,
        paymentType: checkForm?.paymentType,
        checkNumber: event.target.name === 'checkNumber' ? event.target.value : checkForm.checkNumber,
        amount: event.target.name === 'amount' ? event.target.value : checkForm.amount,
        drawer: event.target.name === 'drawer' ? event.target.value : checkForm.drawer,
        bank: checkForm?.bank,
        bankName: checkForm?.bankName,
        cashFlow: checkForm.cashFlow,
        cashFlowName: checkForm.cashFlowName,
        settlementDate: checkForm?.settlementDate,
        dueDate: checkForm?.dueDate,
      })
    } else if (paymentFormType === 'draft') {
      setDraftForm({
        paymentMethod: draftForm?.paymentMethod,
        paymentType: draftForm?.paymentType,
        draftNumber: event.target.name === 'draftNumber' ? event.target.value : draftForm.draftNumber,
        amount: event.target.name === 'amount' ? event.target.value : draftForm.amount,
        cashFlow: draftForm.cashFlow,
        cashFlowName: draftForm.cashFlowName,
        sourceAccount: event.target.name === 'sourceAccount' ? event.target.value : draftForm.sourceAccount,
        settlementDate: draftForm?.settlementDate,
        dueDate: draftForm?.dueDate,
      })
    } else if (paymentFormType === 'transfer') {
      setTransferForm({
        paymentMethod: transferForm.paymentMethod,
        paymentType: transferForm.paymentType,
        amount: event.target.name === 'amount' ? event.target.value : transferForm.amount,
        transferInformation: event.target.name === 'transferInformation' ? event.target.value : transferForm.transferInformation,
        settlementDate: transferForm?.settlementDate,
        destinationAccount: transferForm.destinationAccount,
        destinationAccountName: transferForm.destinationAccountName,
      })
    }
  }

  const onChangeCashFlow = (event: any) => {
    if (paymentFormType === 'cash') {
      setCashForm({
        paymentMethod: cashForm?.paymentMethod,
        paymentType: cashForm?.paymentType,
        amount: cashForm?.amount,
        cashFlow: event.value,
        cashFlowName: event.label,
        date: cashForm?.date,
      })
    } else if (paymentFormType === 'check') {
      setCheckForm({
        paymentMethod: checkForm?.paymentMethod,
        paymentType: checkForm?.paymentType,
        checkNumber: checkForm.checkNumber,
        amount: checkForm.amount,
        drawer: checkForm.drawer,
        bank: checkForm?.bank,
        bankName: checkForm?.bankName,
        cashFlow: event.value,
        cashFlowName: event.label,
        settlementDate: checkForm?.settlementDate,
        dueDate: checkForm?.dueDate,
      })
    } else if (paymentFormType === 'draft') {
      setDraftForm({
        paymentMethod: draftForm?.paymentMethod,
        paymentType: draftForm?.paymentType,
        draftNumber: draftForm.draftNumber,
        amount: draftForm.amount,
        cashFlow: event.value,
        cashFlowName: event.label,
        sourceAccount: draftForm.sourceAccount,
        settlementDate: draftForm?.settlementDate,
        dueDate: draftForm?.dueDate,
      })
    }
  }

  // Change Bank in Check Form
  const onChangeBank = (event: any) => {
    setCheckForm({
      paymentMethod: checkForm?.paymentMethod,
      paymentType: checkForm?.paymentType,
      checkNumber: checkForm?.checkNumber,
      amount: checkForm?.amount,
      drawer: checkForm?.drawer,
      bank: event.value,
      bankName: event.label,
      cashFlow: checkForm.cashFlow,
      cashFlowName: checkForm.cashFlowName,
      settlementDate: checkForm?.settlementDate,
      dueDate: checkForm?.dueDate,
    })
  }

  const onChangeDestinationAccount = (event: any) => {
    setTransferForm({
      paymentMethod: transferForm.paymentMethod,
      paymentType: transferForm.paymentType,
      amount: transferForm.amount,
      transferInformation: transferForm.transferInformation,
      settlementDate: transferForm.settlementDate,
      destinationAccount: event.value,
      destinationAccountName: event.label,
    })
  }

  // Change Date in Cash Form
  const onHandleChangeDate = useCallback(
    (date: any) => {
      setCashForm({
        paymentMethod: cashForm?.paymentMethod,
        paymentType: cashForm?.paymentType,
        cashFlow: cashForm?.cashFlow,
        cashFlowName: cashForm?.cashFlowName,
        amount: cashForm?.amount,
        date,
      })
    },
    [cashForm],
  )

  // Change Settlement Date in Check, Draft and Transfer Form
  const onHandleChangeSettlementDate = useCallback(
    (date: any) => {
      if (paymentFormType === 'check') {
        setCheckForm({
          paymentMethod: checkForm?.paymentMethod,
          paymentType: checkForm?.paymentType,
          checkNumber: checkForm?.checkNumber,
          amount: checkForm?.amount,
          cashFlow: checkForm.cashFlow,
          cashFlowName: checkForm.cashFlowName,
          drawer: checkForm?.checkNumber,
          bank: checkForm?.bank,
          bankName: checkForm?.bankName,
          settlementDate: date,
          dueDate: checkForm?.dueDate,
        })
      } else if (paymentFormType === 'draft') {
        setDraftForm({
          paymentMethod: draftForm?.paymentMethod,
          paymentType: draftForm?.paymentType,
          amount: draftForm?.amount,
          cashFlow: draftForm.cashFlow,
          cashFlowName: draftForm.cashFlowName,
          draftNumber: draftForm?.draftNumber,
          sourceAccount: draftForm?.sourceAccount,
          settlementDate: date,
          dueDate: draftForm?.dueDate,
        })
      } else if (paymentFormType === 'transfer') {
        setTransferForm({
          paymentMethod: transferForm.paymentMethod,
          paymentType: transferForm.paymentType,
          amount: transferForm.amount,
          destinationAccount: transferForm.destinationAccount,
          destinationAccountName: transferForm.destinationAccountName,
          transferInformation: transferForm.transferInformation,
          settlementDate: date,
        })
      }
    },
    [checkForm, transferForm, draftForm],
  )

  // Change Due Date in Check and Draft Form
  const onHandleChangeDueDate = useCallback(
    (date: any) => {
      if (paymentFormType === 'check') {
        setCheckForm({
          paymentMethod: checkForm?.paymentMethod,
          paymentType: checkForm?.paymentType,
          checkNumber: checkForm?.checkNumber,
          amount: checkForm?.amount,
          cashFlow: checkForm.cashFlow,
          cashFlowName: checkForm.cashFlowName,
          drawer: checkForm?.checkNumber,
          bank: checkForm?.bank,
          bankName: checkForm?.bankName,
          settlementDate: checkForm.settlementDate,
          dueDate: date,
        })
      } else if (paymentFormType === 'draft') {
        setDraftForm({
          paymentMethod: draftForm?.paymentMethod,
          paymentType: draftForm?.paymentType,
          amount: draftForm?.amount,
          cashFlow: draftForm.cashFlow,
          cashFlowName: draftForm.cashFlowName,
          draftNumber: draftForm?.draftNumber,
          sourceAccount: draftForm?.sourceAccount,
          settlementDate: draftForm.settlementDate,
          dueDate: date,
        })
      }
    },
    [checkForm, draftForm],
  )

  useEffect(() => {
    CashFlowService.selectCashFlow().then((response) => {
      setCashFlows(response.data.data)
    })
    BankAccountService.selectBankAccount().then((response) => {
      setBankAccounts(response.data.data)
    })
    BankService.selectBank().then((response) => {
      setBanks(response.data.data)
    })
  }, [])

  const alertOnChange = async (title: string) => {
    await Functions.sweetAlertOnTop(title)
  }

  const onSubmit = () => {
    const sum = formValues.reduce((accumulator: number, object: any) => (object.amount ? parseFloat(currency(object.amount).add(currency(accumulator)).toString()) : 0), 0)
    if (sum >= remainingPayment) {
      alertOnChange('Somme des payements est supérieur à le paiement restant')
    } else {
      PaymentService.createPayment(formValues, invoice!.uuid).then(() => {
        handleClose(true)
        setFormValues([])
        handleIsLoading(true)
      })
    }
  }

  useEffect(() => {}, [remainingPayment, invoice])

  useEffect(() => {
    PaymentMethodService.selectPaymentMethods(1).then((response) => {
      setPaymentMethods(response.data.data)
    })
  }, [])


  return (
    <Modal show={show} onHide={() => handleClose(false)} dialogClassName={'mw-600px'}>
      <Modal.Header>
        <div className="d-flex flex-column">
          <h4>Plan de paiement</h4>
          <div className="d-flex align-items-start mt-2">
            <span className="badge badge-danger">Reste à payer {remainingPayment}</span>
            <span className="badge badge-warning ms-2">Total 850DT</span>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center">
          {paymentMethods.map((item, index) => (
            <button key={`button_${index}`} className="btn btn-sm btn-primary me-5" onClick={() => onChangeMethodType(item.value)}>
              <KTSVG
                path={`/media/icons/duotune/${item.label === 'cash' ? 'abstract/money.svg' : item.label === 'check' ? 'abstract/card.svg' : ''}`}
                className='svg-icon svg-icon-2'
              />
              {item.label}
            </button>
          ))}
        </div>

        {paymentFormType === 'cash' ?
          <>
            <div className="card mt-10 border border-1 border-secondary">
              <div className="card-body px-7 py-5">
                <span className="badge bg-med-orange fs-5 fw-500">{paymentFormType}</span>
                <div className="mb-5 mt-5">
                  <label
                    className="form-label fw-semibold">{intl.formatMessage({ id: 'accounting.payment.entity.amount' })}</label>
                  <input
                    placeholder={intl.formatMessage({ id: 'accounting.payment.entity.amount' })}
                    className={'form-control form-control-solid form-control-lg col-md-10'}
                    type='text'
                    autoComplete='off'
                    name="amount"
                    onChange={onChangeField}
                    value={cashForm.amount as number}
                  />
                </div>
                <div className="mb-5">
                  <label
                    className="form-label fw-semibold">{intl.formatMessage({ id: 'accounting.payment.entity.cashFlow' })}</label>
                  <Select
                    options={cashFlows}
                    styles={colourStyles}
                    name="cashFlow"
                    onChange={(e) => onChangeCashFlow(e)}
                    defaultValue={{ label: cashForm.cashFlowName, value: cashForm.cashFlow }}
                  />
                </div>
                <div className="mb-5">
                  <label
                    className="form-label fw-semibold">{intl.formatMessage({ id: 'accounting.payment.entity.date' })}</label>
                  <DateTimePicker
                    format="YYYY/MM/DD"
                    name={'bir_sey_tarihi'}
                    id={'bir_sey_tarihi'}
                    placeholder="Select Date"
                    locale="tr"
                    specialDays={[
                      { day: 1, month: 1, memo: 'New Years' },
                      { day: 14, month: 2, memo: 'Valentine`s Day' },
                    ]}
                    ariaLabelledby={'aria - labelledby'}
                    label={'Hello'}
                    date={cashForm?.date as string}
                    onHandleChangeDate={onHandleChangeDate}
                  />
                </div>
                <div className="d-flex align-content-start">
                  <button onClick={addForm} className="btn btn-sm btn-success btn-sm me-1">
                        Ajouter
                  </button>
                  <div className="ms-auto">
                    <a onClick={removeForm} className="btn btn-icon btn-sm me-1 w-32px h-32px">
                      <KTSVG
                        path='/media/icons/duotune/abstract/trash.svg'
                        className='svg-icon svg-icon-2 svg-icon-danger'
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </> :
          <>
            {paymentFormType === 'check' ?
              <>
                <div className="card mt-10 border border-1 border-secondary">
                  <div className="card-body px-7 py-5">
                    <span className="badge bg-med-orange fs-5 fw-500">{paymentFormType}</span>
                    <div className="mb-5 mt-5">
                      <label
                        className="form-label fw-semibold">{intl.formatMessage({ id: 'accounting.payment.entity.amount' })}</label>
                      <input
                        placeholder={intl.formatMessage({ id: 'accounting.payment.entity.amount' })}
                        className={'form-control form-control-solid form-control-lg col-md-10'}
                        type='text'
                        autoComplete='off'
                        name="amount"
                        value={checkForm.amount as number}
                        onChange={(e) => onChangeField(e)}
                      />
                    </div>
                    <div className="mb-5">
                      <label
                        className="form-label fw-semibold">{intl.formatMessage({ id: 'accounting.payment.entity.cashFlow' })}</label>
                      <Select
                        options={cashFlows}
                        styles={colourStyles}
                        name="bank"
                        onChange={(e) => onChangeCashFlow(e)}
                        defaultValue={{ label: checkForm.cashFlowName, value: checkForm.cashFlow }}
                      />
                    </div>
                    <div className="mb-5">
                      <label
                        className="form-label fw-semibold">{intl.formatMessage({ id: 'accounting.payment.entity.drawer' })}</label>
                      <input
                        placeholder={intl.formatMessage({ id: 'accounting.payment.entity.drawer' })}
                        className={'form-control form-control-solid form-control-lg col-md-10'}
                        type='text'
                        autoComplete='off'
                        name="drawer"
                        value={checkForm.drawer as string}
                        onChange={(e) => onChangeField(e)}
                      />
                    </div>
                    <div className="mb-5">
                      <label
                        className="form-label fw-semibold">{intl.formatMessage({ id: 'accounting.payment.entity.checkNumber' })}</label>
                      <input
                        placeholder={intl.formatMessage({ id: 'accounting.payment.entity.checkNumber' })}
                        className={'form-control form-control-solid form-control-lg col-md-10'}
                        type='text'
                        autoComplete='off'
                        name="checkNumber"
                        value={checkForm.checkNumber as string}
                        onChange={(e) => onChangeField(e)}
                      />
                    </div>
                    <div className="mb-5">
                      <label
                        className="form-label fw-semibold">{intl.formatMessage({ id: 'accounting.payment.entity.bank' })}</label>
                      <Select
                        options={banks}
                        styles={colourStyles}
                        name="bank"
                        onChange={(e) => onChangeBank(e)}
                        defaultValue={{ label: checkForm.bankName, value: checkForm.bank }}
                      />
                    </div>
                    <div className="mb-5">
                      <label
                        className="form-label fw-semibold">{intl.formatMessage({ id: 'accounting.payment.entity.settlementDate' })}</label>
                      <DateTimePicker
                        format="YYYY/MM/DD"
                        name={'bir_sey_tarihi'}
                        id={'bir_sey_tarihi'}
                        placeholder="Select Date"
                        locale="tr"
                        specialDays={[
                          { day: 1, month: 1, memo: 'New Years' },
                          { day: 14, month: 2, memo: 'Valentine`s Day' },
                        ]}
                        ariaLabelledby={'aria - labelledby'}
                        label={'Hello'}
                        date={checkForm?.settlementDate as string}
                        onHandleChangeDate={onHandleChangeSettlementDate}
                      />
                    </div>
                    <div className="mb-5">
                      <label
                        className="form-label fw-semibold">{intl.formatMessage({ id: 'accounting.payment.entity.dueDate' })}</label>
                      <DateTimePicker
                        format="YYYY/MM/DD"
                        name={'bir_sey_tarihi'}
                        id={'bir_sey_tarihi'}
                        placeholder="Select Date"
                        locale="tr"
                        specialDays={[
                          { day: 1, month: 1, memo: 'New Years' },
                          { day: 14, month: 2, memo: 'Valentine`s Day' },
                        ]}
                        ariaLabelledby={'aria - labelledby'}
                        label={'Hello'}
                        date={checkForm?.dueDate as string}
                        onHandleChangeDate={onHandleChangeDueDate}
                      />
                    </div>
                    <div className="d-flex align-content-start">
                      <button onClick={addForm}
                        className="btn btn-sm btn-success btn-sm me-1">
                              Ajouter
                      </button>
                      <div className="ms-auto">
                        <a onClick={removeForm} className="btn btn-icon btn-sm me-1 w-32px h-32px">
                          <KTSVG
                            path='/media/icons/duotune/abstract/trash.svg'
                            className='svg-icon svg-icon-2 svg-icon-danger'
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </> :
              <>
                {paymentFormType === 'transfer' ?
                  <>
                    <div className="card mt-10 border border-1 border-secondary">
                      <div className="card-body px-7 py-5">
                        <span className="badge bg-med-orange fs-5 fw-500">{paymentFormType}</span>
                        <div className="mb-5 mt-5">
                          <label
                            className="form-label fw-semibold">{intl.formatMessage({ id: 'accounting.payment.entity.amount' })}</label>
                          <input
                            placeholder={intl.formatMessage({ id: 'accounting.payment.entity.amount' })}
                            className={'form-control form-control-solid form-control-lg col-md-10'}
                            type='text'
                            autoComplete='off'
                            name="amount"
                            value={transferForm.amount as number}
                            onChange={(e) => onChangeField(e)}
                          />
                        </div>
                        <div className="mb-5">
                          <label
                            className="form-label fw-semibold">{intl.formatMessage({ id: 'accounting.payment.entity.bankAccount' })}</label>
                          <Select
                            options={bankAccounts}
                            styles={colourStyles}
                            name="bank"
                            onChange={(e) => onChangeDestinationAccount(e)}
                            defaultValue={{ label: transferForm.destinationAccountName, value: transferForm.destinationAccount }}
                          />
                        </div>
                        <div className="mb-5">
                          <label
                            className="form-label fw-semibold">{intl.formatMessage({ id: 'accounting.payment.entity.transferInformation' })}</label>
                          <input
                            placeholder={intl.formatMessage({ id: 'accounting.payment.entity.transferInformation' })}
                            className={'form-control form-control-solid form-control-lg col-md-10'}
                            type='text'
                            autoComplete='off'
                            name="transferInformation"
                            value={transferForm.transferInformation as string}
                            onChange={(e) => onChangeField(e)}
                          />
                        </div>
                        <div className="mb-5">
                          <label
                            className="form-label fw-semibold">{intl.formatMessage({ id: 'accounting.payment.entity.settlementDate' })}</label>
                          <DateTimePicker
                            format="YYYY/MM/DD"
                            name={'bir_sey_tarihi'}
                            id={'bir_sey_tarihi'}
                            placeholder="Select Date"
                            locale="tr"
                            specialDays={[
                              { day: 1, month: 1, memo: 'New Years' },
                              { day: 14, month: 2, memo: 'Valentine`s Day' },
                            ]}
                            ariaLabelledby={'aria - labelledby'}
                            label={'Hello'}
                            date={transferForm?.settlementDate as string}
                            onHandleChangeDate={onHandleChangeSettlementDate}
                          />
                        </div>
                        <div className="d-flex align-content-start">
                          <button onClick={addForm}
                            className="btn btn-sm btn-success btn-sm me-1">
                                    Ajouter
                          </button>
                          <div className="ms-auto">
                            <a onClick={removeForm}
                              className="btn btn-icon btn-sm me-1 w-32px h-32px">
                              <KTSVG
                                path='/media/icons/duotune/abstract/trash.svg'
                                className='svg-icon svg-icon-2 svg-icon-danger'
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </> :
                  <>
                    {paymentFormType === 'draft' ?
                      <div className="card mt-10 border border-1 border-secondary">
                        <div className="card-body px-7 py-5">
                          <span className="badge bg-med-orange fs-5 fw-500">{paymentFormType}</span>
                          <div className="mb-5 mt-5">
                            <label
                              className="form-label fw-semibold">{intl.formatMessage({ id: 'accounting.payment.entity.amount' })}</label>
                            <input
                              placeholder={intl.formatMessage({ id: 'accounting.payment.entity.amount' })}
                              className={'form-control form-control-solid form-control-lg col-md-10'}
                              type='text'
                              autoComplete='off'
                              name="amount"
                              value={draftForm.amount as number}
                              onChange={(e) => onChangeField(e)}
                            />
                          </div>
                          <div className="mb-5">
                            <label
                              className="form-label fw-semibold">{intl.formatMessage({ id: 'accounting.payment.entity.cashFlow' })}</label>
                            <Select
                              options={cashFlows}
                              styles={colourStyles}
                              name="bank"
                              onChange={(e) => onChangeCashFlow(e)}
                              defaultValue={{ label: draftForm.cashFlowName, value: draftForm.cashFlow }}
                            />
                          </div>
                          <div className="mb-5">
                            <label
                              className="form-label fw-semibold">{intl.formatMessage({ id: 'accounting.payment.entity.sourceAccount' })}</label>
                            <input
                              placeholder={intl.formatMessage({ id: 'accounting.payment.entity.sourceAccount' })}
                              className={'form-control form-control-solid form-control-lg col-md-10'}
                              type='text'
                              name="sourceAccount"
                              value={draftForm.sourceAccount as string}
                              autoComplete='off'
                              onChange={(e) => onChangeField(e)}
                            />
                          </div>
                          <div className="mb-5">
                            <label
                              className="form-label fw-semibold">{intl.formatMessage({ id: 'accounting.payment.entity.draftNumber' })}</label>
                            <input
                              placeholder={intl.formatMessage({ id: 'accounting.payment.entity.draftNumber' })}
                              className={'form-control form-control-solid form-control-lg col-md-10'}
                              type='text'
                              autoComplete='off'
                              name="draftNumber"
                              value={draftForm.draftNumber as string}
                              onChange={(e) => onChangeField(e)}
                            />
                          </div>
                          <div className="mb-5">
                            <label
                              className="form-label fw-semibold">{intl.formatMessage({ id: 'accounting.payment.entity.settlementDate' })}</label>
                            <DateTimePicker
                              format="YYYY/MM/DD"
                              name={'bir_sey_tarihi'}
                              id={'bir_sey_tarihi'}
                              placeholder="Select Date"
                              locale="tr"
                              specialDays={[
                                { day: 1, month: 1, memo: 'New Years' },
                                { day: 14, month: 2, memo: 'Valentine`s Day' },
                              ]}
                              ariaLabelledby={'aria - labelledby'}
                              label={'Hello'}
                              date={draftForm?.settlementDate as string}
                              onHandleChangeDate={onHandleChangeSettlementDate}
                            />
                          </div>
                          <div className="mb-5">
                            <label
                              className="form-label fw-semibold">{intl.formatMessage({ id: 'accounting.payment.entity.dueDate' })}</label>
                            <DateTimePicker
                              format="YYYY/MM/DD"
                              name={'bir_sey_tarihi'}
                              id={'bir_sey_tarihi'}
                              placeholder="Select Date"
                              locale="tr"
                              specialDays={[
                                { day: 1, month: 1, memo: 'New Years' },
                                { day: 14, month: 2, memo: 'Valentine`s Day' },
                              ]}
                              ariaLabelledby={'aria - labelledby'}
                              label={'Hello'}
                              date={draftForm?.dueDate as string}
                              onHandleChangeDate={onHandleChangeDueDate}
                            />
                          </div>
                          <div className="d-flex align-content-start">
                            <button onClick={addForm}
                              className="btn btn-sm btn-success btn-sm me-1">
                                        Ajouter
                            </button>
                            <div className="ms-auto">
                              <a onClick={removeForm}
                                className="btn btn-icon btn-sm me-1 w-32px h-32px">
                                <KTSVG
                                  path='/media/icons/duotune/abstract/trash.svg'
                                  className='svg-icon svg-icon-2 svg-icon-danger'
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div> :
                      <>
                      </>
                    }
                  </>
                }
              </>
            }
          </>
        }

        <div className="table-responsive mt-5">
          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            <thead>
              <tr className="fw-bolder text-muted border-0 bg-light-primary fw-600">
                <th className="ms-5">
                  <span className="ms-5 fs-5 fw-500 text-gray-700">Méthode</span>
                </th>
                <th className="text-end">
                  <span className="me-5 fs-5 fw-500 text-gray-700">Montant</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {formValues.map((item: any, index: number) => (
                <tr key={`payment_${index}`}>
                  <td>
                    <KTSVG
                      path={`/media/icons/duotune/${item.paymentType === 'cash' ? 'abstract/money.svg' : item.paymentType === 'check'}`}
                      className='svg-icon svg-icon-2 svg-icon-dark ms-2'
                    />
                    <span className="text-dark fs-5 fw-500 ms-5">{item.paymentType}</span>
                  </td>
                  <td className="text-end">
                    <span className="text-primary fs-5 fw-500 me-5">{item.amount} TND</span>
                    <a onClick={() => removeFormValues(index)} className="btn btn-icon btn-sm me-1 w-32px h-32px">
                      <KTSVG
                        path='/media/icons/duotune/abstract/trash.svg'
                        className='svg-icon svg-icon-2 svg-icon-danger'
                      />
                    </a>
                    <a className="btn btn-icon btn-sm me-1 w-32px h-32px" onClick={() => editForm(index, item.paymentType)}>
                      <KTSVG path="/media/icons/duotune/abstract/write.svg" className="svg-icon svg-icon-2 svg-icon-dark" />
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type='button'
          className='btn btn-sm btn-secondary'
          onClick={async () => {
            handleClose(false)
            setFormValues([])
          }}
        >
          {intl.formatMessage({ id: 'action.close' })}
        </button>
        <button
          type='submit'
          className='btn btn-sm btn-primary'
          onClick={onSubmit}
        >
          {intl.formatMessage({ id: 'action.save' })}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default PaymentCreate
