import { AbilityBuilder, Ability, AbilityClass } from '@casl/ability'

type Actions = 'management' | 'create' | 'index' | 'edit' | 'delete';
type Subjects =
    'crm' |
    'journey' | 'journeySales' | 'journeySupport' | 'journeyManagerSales' | 'journeyManagerSupport' | 'medicalProfessional' | 'product' | 'package' | 'incentive' | 'productFeature' | 'location' |
    'accounting' |
    'estimate' | 'purchaseOrder' | 'invoice' | 'supplierOrder' | 'payment' | 'bankAccount' | 'cashFlow' | 'bank' | 'customer' | 'supplier' | 'equipment' |
    'hr' |
    'employee' | 'salesPerson' | 'team' | 'userGroup' | 'profile' | 'employeeEquipment' | 'employeeAbsence'


export type AppAbility = Ability<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<AppAbility>

function defineRulesFor(user: IUser) {
  const permissions = user?.features
  const { can, rules } = new AbilityBuilder(AppAbility)

  if (permissions) {
    permissions.forEach((p: any) => {
      const per = p.slug.split('_')
      can(per[0], per[1])
    })
  }

  return rules
}

export function buildAbilityFor(user: IUser): AppAbility {
  return new AppAbility(defineRulesFor(user), {
    // https://casl.js.org/v5/en/guide/subject-type-detection
    detectSubjectType: (object:any) => object!.type,
  })
}
