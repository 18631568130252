import React from 'react'
import { useIntl } from 'react-intl'
import MedicalProfessionalItem from './MedicalProfessionalItem'
import PaginationBarDetails from '../../../../../_metronic/partials/pagination/PaginationBarDetails'
type Props = {
  medicalProfessionals: IMedicalProfessional[],
  totalPages: number,
  currentPage: number,
  isLoading: boolean,
  handleCurrentPage: (page: number) => void,
  handleIsLoading: (isLoading: boolean) => void,
  total: number,
  limit: number,
}

const MedicalProfessionalListing : React.FC<Props> = ({
  isLoading,
  currentPage,
  medicalProfessionals,
  handleIsLoading,
  handleCurrentPage,
  totalPages,
  total,
  limit,
}) => {
  const intl = useIntl()
  return (
    <>
      <div className={`${isLoading ? 'overlay overlay-block' : ''}`}>
        <div className={`mb-5 ${isLoading ? 'overlay-wrapper' : ''}`}>
          {medicalProfessionals && medicalProfessionals.length > 0 && medicalProfessionals.map((medicalProfessional, index) => (
            <MedicalProfessionalItem key={`medical_professional_${index}`} medicalProfessional={medicalProfessional} />
          ))}
        </div>
        {totalPages > 1 ? (
          <PaginationBarDetails
            total={total}
            limit={limit}
            totalPages={totalPages}
            currentPage={currentPage}
            handleCurrentPage={handleCurrentPage}
          />
        ) : (<></>)}
        {isLoading &&
            <div className="overlay-layer rounded bg-dark bg-opacity-5">
              <div
                className="spinner-border text-primary"
                role="status"
              >
                <span className="visually-hidden">{intl.formatMessage({ id: 'action.loading' })}</span>
              </div>
            </div>
        }
      </div>
    </>
  )
}

export default MedicalProfessionalListing
