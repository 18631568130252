import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import Symbol from '../../../../../../../../_metronic/partials/common/Symbol'
import Functions from '../../../../../../../../setup/utilities/Functions'
import { KTSVG } from '../../../../../../../../_metronic/helpers'
import Planning from './Planning'

type Props = {
  journey: IJourney|null,
  show : boolean,
  handleClose: () => void,
  undoneTimeSlots: ITimeSlot[],
  activeTimeSlot: ITimeSlot|null,
  checkout: (uuid: string) => void,
  setIsLoading: (isLoading: boolean) => void
}
const EndJourney : React.FC<Props> = ({ show, handleClose, activeTimeSlot, undoneTimeSlots, journey, checkout, setIsLoading }) => {
  const [planningTimeSlots, setPlanningTimeSlots] = useState<{ uuid: string, profilePicture: string, firstName: string, lastName: string, speciality: string|null, isChecked: boolean }[]>([])
  const [showCalendar, setShowCalendar] = useState<boolean>(false)

  const handleShowCalender = () => {
    handleClose()
    setShowCalendar(true)
  }
  const handleCloseCalender = () => setShowCalendar(false)

  const checkUndoneTimeSlot = (timeSlot : ITimeSlot) => {
    setPlanningTimeSlots([...planningTimeSlots, {
      uuid: timeSlot.uuid,
      profilePicture: timeSlot.todo.medicalProfessional.profilePicture,
      firstName: timeSlot.todo.medicalProfessional.firstName,
      lastName: timeSlot.todo.medicalProfessional.lastName,
      speciality: timeSlot.todo.medicalProfessional.defaultSpeciality ? timeSlot.todo.medicalProfessional.defaultSpeciality : '',
      isChecked: true }])
  }

  // const uncheckUndoneTimeSlot = (index: number) => {
  //   const newPlannings = [...planningTimeSlots]
  //   newPlannings.splice(index, 1)
  //   setPlanningTimeSlots(newPlannings)
  // }

  return (
    <>
      <Modal show={show} onHide={handleClose} dialogClassName={'mw-500px'}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="text-dark fs-2 fw-bold">Fin de journée</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column">
          <div className={`${activeTimeSlot ? 'mb-10' : ''}`}>
            {activeTimeSlot ?
              <>
                <span className="card card-rounded bg-med-danger" >
                  <div className="card-body px-5 py-0">
                    <div className="d-flex align-items-start justify-content-start">
                      <img
                        src='/media/icons/duotune/abstract/bell.svg'
                        className='svg-icon svg-icon-1 svg-icon-dark mt-2'
                      />
                      <span className="text-dark fs-2 ms-3">Attention!</span>
                    </div>
                    <div className="d-flex align-items-start justify-content-start">
                      <span className="text-dark fs-6 fw-400 ms-8">Etes-vous sûr de vouloir supprimer le médecin de la liste Todo ?
                      </span>
                    </div>
                    <div className="card mt-5 mb-5">
                      <div className="card-body px-0 py-3">
                        <div className="d-flex align-items-start justify-content-start ms-5">
                          <Symbol
                            picture={activeTimeSlot?.todo?.medicalProfessional?.profilePicture || null}
                            picturePath='/admin/uploads/users/'
                            withText={true}
                            widthClassName='symbol-50px'
                            text={Functions.fullName(activeTimeSlot?.todo?.medicalProfessional?.firstName, activeTimeSlot?.todo?.medicalProfessional?.lastName)}
                            shortText={Functions.firstLetterUpperCase((activeTimeSlot?.todo?.medicalProfessional?.firstName || '').charAt(0)) + Functions.firstLetterUpperCase((activeTimeSlot?.todo?.medicalProfessional?.lastName || '').charAt(0))}
                            description={activeTimeSlot?.todo?.medicalProfessional?.defaultSpeciality || ''}
                            backgroundClass=''
                            textClassName='fs-3 fw-400'
                            isCircular={false}
                            descriptionColor={'text-dark fs-6 fw-500 ms-8'}
                            textColor={'text-dark fs-6 fw-bold ms-8'}
                          />
                          <div className='ms-auto me-5 mt-1'>
                            <a className="btn btn-icon btn-danger btn-sm w-50px h-50px" onClick={() => checkout(activeTimeSlot!.uuid)}>
                              <KTSVG
                                path='/media/icons/duotune/abstract/quit.svg'
                                className='svg-icon svg-icon-1'
                              />
                            </a>
                          </div>
                        </div>

                      </div>
                    </div>

                  </div>

                </span>
              </> :
              <>
              </>
            }
          </div>
          <span className='fs-2 fw-500'> Médecins non visités</span>
          <div className="card bg-light-warning mt-5 mb-5">
            <div className="card-body px-0 py-3">
              <div className="d-flex align-items-start">
                <KTSVG
                  path='/media/icons/duotune/general/gen045.svg'
                  className='svg-icon svg-icon-1 svg-icon-dark ms-2'
                />
                <span className="text-dark fs-6 fw-400 ms-3">
                  Replanifier les médecins que vous n'avez pas visités aujourd'hui.
                </span>
              </div>
            </div>
          </div>
          {undoneTimeSlots && undoneTimeSlots.map((item, index) => (
            <div key={`undone_medical_professional_${index}`} className="d-flex align-items-start justify-content-start mt-2">
              <input type="checkbox" className="form-check-input mt-5 me-5" onChange={() => checkUndoneTimeSlot(item)}/>
              <Symbol
                picture={item?.todo?.medicalProfessional?.profilePicture || null}
                picturePath='/admin/uploads/users/'
                withText={true}
                widthClassName='symbol-50px'
                text={Functions.fullName(item?.todo?.medicalProfessional?.firstName, item?.todo?.medicalProfessional?.lastName)}
                shortText={Functions.firstLetterUpperCase((item?.todo?.medicalProfessional?.firstName || '').charAt(0)) + Functions.firstLetterUpperCase((item?.todo?.medicalProfessional?.lastName || '').charAt(0))}
                description={item?.todo?.medicalProfessional?.defaultSpeciality || ''}
                backgroundClass=''
                textClassName='fs-3 fw-400'
                isCircular={false}
                descriptionColor={'text-dark fs-6 fw-500 ms-5'}
                textColor={'text-dark fs-6 fw-bold ms-5'}
              />
            </div>
          ))}

        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn btn-sm bg-light-primary text-primary'
            onClick={handleClose}
          >
                        Annuler
          </button>
          <button
            type='submit'
            className='btn btn-sm btn-primary'
            onClick={handleShowCalender}
          > Replanifier
          </button>
        </Modal.Footer>

      </Modal>
      <Planning show={showCalendar} handleClose={handleCloseCalender} planningTimeSlots={planningTimeSlots} journey={journey ? journey : null} setIsLoading={setIsLoading}/>
    </>
  )
}

export default EndJourney
