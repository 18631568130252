import apiService from '../../../setup/axios/ApiService'

const prefixURL = 'group'

const getAllDepartments = (currentPage:number, limit:number) => apiService.get(`${prefixURL}/`, {
  page: currentPage,
  limit,
})

const updateStatus = (uuid:string|undefined, data: any) => apiService.put(`${prefixURL}/${uuid}/active`, data)

const createDepartment = (data: any) => apiService.post(`${prefixURL}/`, data)

const updateDepartment = (uuid: string|undefined, data: any) => apiService.put(`${prefixURL}/${uuid}/edit`, data)

const deleteDepartment = (uuid: string|undefined) => apiService.deletes(`${prefixURL}/${uuid}/delete`)

const selectDepartment = () => apiService.get(`${prefixURL}/select-group`)

const DepartmentService = {
  getAllDepartments,
  updateStatus,
  createDepartment,
  updateDepartment,
  deleteDepartment,
  selectDepartment,
}

export default DepartmentService
