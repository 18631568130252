import React from 'react'
import { KTSVG } from '../../../../../../../_metronic/helpers'


const Activities: React.FC = () => (
  <>
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <KTSVG
            path='/media/icons/duotune/communication/com007.svg'
            className='svg-icon-1 svg-icon-dark'
          />
          <h2 className='fw-bolder ms-3'>Online Appointments</h2>
        </div>
      </div>
      <div className='card-body'>
        <span className="badge badge-primary">
          <span className="text-white fs-6 fw-400">Inserted RDV : </span>
          <span className="text-white fs-6 fw-bold ms-2">10926 </span>
          <span className="badge bg-med-light-primary text-dark ms-2">
            Last in 04/10/2022 11:25
          </span>
        </span>
        <span className="badge badge-success ms-2">
          <span className="text-white fs-6 fw-400">Online RDV : </span>
          <span className="text-white fs-6 fw-bold ms-2">3387 </span>
        </span>

        <span className="badge badge-danger ms-2">
          <span className="text-white fs-6 fw-400">Expired : </span>
          <span className="text-white fs-6 fw-bold ms-2">3387 </span>
        </span>

        <span className="badge badge-danger ms-2">
          <span className="text-white fs-6 fw-400">Cancelled : </span>
          <span className="text-white fs-6 fw-bold ms-2">3387 </span>
        </span>
      </div>

      <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6 px-10">
        <li className="nav-item">
          <a className="nav-link active" data-bs-toggle="tab" href="#kt_tab_pane_4">Historique RDV</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_5">Reviews (164 )</a>
        </li>
      </ul>

      <div className="tab-content px-10" id="myTabContent">
        <div className="tab-pane fade show active" id="history" role="tabpanel">
          hello
        </div>
        <div className="tab-pane fade" id="kt_tab_pane_5" role="tabpanel">
          ...
        </div>
      </div>
    </div>
  </>
)

export default Activities
