import React, { useState, useEffect } from 'react'
import TeamService from '../../../../services/Hr/TeamService'
import { useIntl } from 'react-intl'
import TableHeaderSolid from '../../../../../_metronic/partials/med-table/TableHeaderSolid'
import Functions from '../../../../../setup/utilities/Functions'
import Symbol from '../../../../../_metronic/partials/common/Symbol'
import { KTSVG } from '../../../../../_metronic/helpers'
import CreateTeam from './CreateTeam'
import PaginationBar from '../../../../../_metronic/partials/pagination/PaginationBar'

type Props = {
  show: boolean,
  handleClose: () => void,
  handleShow: (team: ITeam|undefined|null, action: string) => void,
  teamSelected: ITeam|undefined|null,
  action: string,
}

const TeamListing: React.FC<Props> = ({ show, handleClose, handleShow, teamSelected, action }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [teams, setTeams] = useState<ITeam[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = React.useState(1)
  const intl = useIntl()
  const handleIsLoading = (isLoading: boolean) => {
    setIsLoading(isLoading)
  }

  const headers = [
    {
      minWidth: 'w-250px',
      text: intl.formatMessage({ id: 'team.entity.name' }),
    },
    {
      minWidth: 'w-300px',
      text: intl.formatMessage({ id: 'team.entity.users' }),
    },
    {
      minWidth: 'w-80px',
      text: intl.formatMessage({ id: 'team.entity.active' }),
    },
    {
      minWidth: 'w-100px',
      text: intl.formatMessage({ id: 'actions' }),
    },
  ]

  const changeStatus = (uuid: string|undefined, event: any) => {
    TeamService.updateStatus(uuid, {
      'active': event.target.checked,
    }).then(() => {
      setIsLoading(true)
    })
  }

  const deleteTeam = async (uuid:string|undefined) => {
    const isConfirmed = await Functions.sweetAlert(
      '<span class="mt-5">Are you sure you want to delete this team</span>',
      'warning',
      true,
      'Yes, delete!',
      'No, cancel',
      'btn btn-danger mt-10 mb-10',
      'btn btn-active-light mt-10 mb-10',
    )
    if (isConfirmed) {
      TeamService.deleteTeam(uuid).then(() => {
        setIsLoading(true)
      })
    }
  }

  const handleCurrentPage = (page:number) => {
    setIsLoading(true)
    setCurrentPage(page)
  }

  useEffect(() => {
    TeamService.getAllTeams(currentPage, 10).then((response) => {
      setTeams(response.data.data.list)
      setTotalPages(response.data.data.totalPages)
      setIsLoading(false)
    })
  }, [currentPage, isLoading])


  return (
    <>
      <div className={`card mb-5 ${isLoading ? 'overlay overlay-block' : ''}`}>
        <div className={`card-body py-3 ${isLoading ? 'overlay-wrapper' : ''}`}>
          <div className="pt-5">
            <div className="table-responsive">
              <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                <TableHeaderSolid headers={headers} />
                <tbody>
                  {teams && teams.length > 0 ? (
                    teams.map((team, index) => (
                      <tr key={`team_${index}`}>
                        <td className='ps-4'>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span className="text-dark text-hover-primary fw-bold fs-6">
                                {Functions.firstLetterUpperCase(team.name)}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='me-2 pt-2 d-flex flex-stack flex-wrap'>
                            <div className="symbol-group symbol-hover">
                              {team.employees && team.employees.slice(0, 5).map((employee, employeeIndex) => (
                                <Symbol
                                  key={`team_employee_${employeeIndex}`}
                                  picture={employee.profilePicture}
                                  picturePath='/admin/uploads/users/'
                                  withText={false}
                                  widthClassName='symbol-35px mb-5'
                                  text={Functions.fullName(employee.firstName, employee.lastName)}
                                  shortText={Functions.firstLetterUpperCase(employee.firstName.charAt(0))}
                                  description={null}
                                  backgroundClass='bg-med-grey'
                                  textClassName='text-inverse-primary'
                                  isCircular={true}
                                  descriptionColor={'text-muted'}
                                  textColor={'text-dark'}
                                />
                              ))}
                              {team.employees && team.employees.length - 5 > 0 ? (
                                <Symbol
                                  picture={null}
                                  picturePath='/admin/uploads/users/'
                                  withText={false}
                                  widthClassName='symbol-35px mb-5'
                                  text=''
                                  shortText={'+' + (team.employees.length - 5).toString()}
                                  description=''
                                  isCircular={true}
                                  backgroundClass='bg-primary'
                                  textClassName='text-inverse-primary'
                                  descriptionColor={'text-muted'}
                                  textColor={'text-dark'}
                                />
                              ) : null}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="form-check form-switch form-check-custom form-check-solid">
                            <input className="form-check-input w-32px h-20px" checked={team.isEnabled} onChange={() => changeStatus(team.uuid, event)} type="checkbox" id="flexSwitchChecked"/>
                          </div>
                        </td>
                        <td className="text-end">
                          <button type='button' onClick={() => handleShow(team, 'edit')} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 w-32px h-32px">
                            <KTSVG
                              path='/media/icons/duotune/abstract/write.svg'
                              className='svg-icon svg-icon-2'
                            />
                          </button>
                          <button type='button' onClick={() => deleteTeam(team.uuid)} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 w-32px h-32px">
                            <KTSVG
                              path='/media/icons/duotune/abstract/trash.svg'
                              className='svg-icon svg-icon-2'
                            />
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={7}>
                        <div className='d-flex text-center w-100 fs-4 align-content-center justify-content-center mt-5'>
                                  No matching records found
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {totalPages > 1 ? (
          <PaginationBar
            totalPages={totalPages}
            currentPage={currentPage}
            handleCurrentPage={handleCurrentPage}
          />
        ) : (<></>)}
        {isLoading &&
          <div className="overlay-layer rounded bg-dark bg-opacity-5">
            <div
              className="spinner-border text-primary"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        }
      </div>
      <CreateTeam show={show} handleClose={handleClose} handleIsLoading={handleIsLoading} teamSelected={teamSelected} action={action}/>
    </>
  )
}

export default TeamListing
