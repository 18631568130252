import apiService from '../../../setup/axios/ApiService'

const prefixURL = 'incentive'

const getAllIncentives = (currentPage:number) => apiService.get(`${prefixURL}/`, { page: currentPage })

const updateIsActiveStatus = (uuid:string|undefined, data: any) => apiService.patch(`${prefixURL}/${uuid}/active`, data)

const updateHasNoEndDate = (uuid:string|undefined, data: any) => apiService.patch(`${prefixURL}/${uuid}/has-no-end-date`, data)

const updateIsForAll = (uuid:string|undefined, data: any) => apiService.patch(`${prefixURL}/${uuid}/is-for-all`, data)

const deleteIncentive = (uuid: string|undefined) => apiService.deletes(`${prefixURL}/${uuid}/delete`)

const selectIncentiveTypes = () => apiService.get(`${prefixURL}/select-types`)

const createIncentive = (data: any) => apiService.post(`${prefixURL}/`, data)

const updateIncentive = (uuid: string|undefined, data: any) => apiService.put(`${prefixURL}/${uuid}/edit`, data)

const IncentiveService = {
  getAllIncentives,
  updateIsActiveStatus,
  updateHasNoEndDate,
  updateIsForAll,
  deleteIncentive,
  selectIncentiveTypes,
  createIncentive,
  updateIncentive,
}

export default IncentiveService
