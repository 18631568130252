import React, { useEffect, useState } from 'react'
import MedAccountingFilter from '../../../../../_metronic/partials/med-accounting/MedAccountingFilter'
import { addDays } from 'date-fns'
import { RangeKeyDict } from 'react-date-range'
import format from 'date-fns/format'
import EstimateListing from './EstimateListing'
import EstimateService from '../../../../services/Accounting/EstimateService'

const statusOptions = [
  {
    value: 'canceled',
    label: 'Canceled',
  },
  {
    value: 'created',
    label: 'Created',
  },
  {
    value: 'negotiation',
    label: 'Negotiation',
  },
  {
    value: 'accepted',
    label: 'Accepted',
  },
  {
    value: 'draft',
    label: 'Draft',
  },
]

const EstimateFilter : React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [estimates, setEstimates] = useState<IEstimate[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = React.useState(1)
  const [customerName, setCustomerName] = useState<string>('')
  const [status, setStatus] = useState<string>('')
  const [customerType, setCustomerType] = useState<boolean>(false)
  const [range, setRange] = useState([
    {
      startDate: new Date(new Date().getFullYear(), 0, 1),
      endDate: addDays(new Date(new Date().getFullYear(), 0, 1), 365),
      key: 'selection',
    },
  ])

  // Filter By customer name
  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerName(event.target.value)
  }

  // Filter by any search range of date
  const handleChangeRange = (item: RangeKeyDict) => {
    setRange([{ startDate: item.selection.startDate || new Date(), endDate: item.selection.endDate || new Date(), key: item.selection.key || '' }])
  }

  // Filter by every month in the current year
  const handleChangeMonthButtons = (event: any) => {
    const now = new Date()
    const firstDay = new Date(now.getFullYear(), event.target.value - 1, 1)
    const lastDay = new Date(now.getFullYear(), event.target.value, 0)
    setRange([{ startDate: firstDay || new Date(), endDate: lastDay || new Date(), key: 'selection' || '' }])
  }

  // Filter by invoice status
  const handleChangeStatus = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setStatus(event.target.value)
  }

  // Filter by customer type
  const handleChangeType = (event: any) => {
    setCustomerType(event.target.value)
  }

  const handleCurrentPage = (page:number) => {
    setIsLoading(true)
    setCurrentPage(page)
  }

  useEffect(() => {
    EstimateService.getAllEstimates(currentPage, 10, customerName, customerType, status, format(range[0].startDate, 'yyyy/MM/dd'), format(range[0].endDate, 'yyyy/MM/dd')).then((response) => {
      setEstimates(response.data.data.list)
      setTotalPages(response.data.data.totalPages)
      setIsLoading(false)
    })
  }, [isLoading, currentPage, status, range, customerName])

  useEffect(() => {}, [estimates])
  return (
    <>
      <MedAccountingFilter
        handleChangeName={handleChangeName}
        handleChangeMonthButtons={handleChangeMonthButtons}
        handleChangeRange={handleChangeRange}
        handleChangeStatus={handleChangeStatus}
        handleChangeType={handleChangeType}
        range={range}
        statusOptions={statusOptions}
      />
      <EstimateListing
        currentPage={currentPage}
        handleCurrentPage={handleCurrentPage}
        estimates={estimates}
        isLoading={isLoading}
        totalPages={totalPages}
        setIsLoading={setIsLoading}
      />
    </>
  )
}

export default EstimateFilter
