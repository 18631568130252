import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import packageService from '../../../../../services/Crm/PackageService'
import ProductService from '../../../../../services/Crm/ProductService'
import Select from 'react-select'

type Inputs = {
    name: string,
    isActive: boolean,
    fixedRate: number,
    discount: number,
    packageCode: string,
    isAutoReactivation: boolean,
    tax: number,
    products: string[],
}

type Props = {
    show: boolean,
    pack: IPackage|null|undefined,
    handleClose: (isSubmit: boolean) => void,
    handleIsLoading: (isLoading: boolean) => void,
    action: string,
}

const PackageCreate: React.FC<Props> = ({
  pack,
  show,
  handleClose,
  handleIsLoading,
  action }) => {
  const colourStyles = {
    control: (styles: any) => ({ ...styles, backgroundColor: '#F5F8FA', border: 0 }),
  }
  const intl = useIntl()
  const [optionsProducts, setOptionsProducts] = useState<{value: string, label: string}[] >([])
  const packageSchema = Yup.object().shape({
    name: Yup.string().required(intl.formatMessage({ id: 'fieldIsRequired' })),
    packageCode: Yup.string().required(intl.formatMessage({ id: 'fieldIsRequired' })),
    fixedRate: Yup.number().min(1, intl.formatMessage({ id: 'NumberGreaterThan0' })),
    tax: Yup.number().min(1, intl.formatMessage({ id: 'NumberGreaterThan0' })),
  })
  const config = {
    resolver: yupResolver(packageSchema),
    defaultValues: {
      name: '',
      packageCode: '',
      fixedRate: 0,
      discount: 0,
      tax: 0,
      isAutoRenew: false,
      isActive: false,
      products: [],
    },
  }
  const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm<Inputs>(config)
  setValue('name', pack?.name || '')
  setValue('packageCode', pack?.packageCode || '')
  setValue('fixedRate', pack?.fixedRate || 0)
  setValue('discount', pack?.discount || 0)
  setValue('tax', pack?.tax || 0)
  setValue('isAutoReactivation', pack?.isAutoReactivation || false)
  setValue('isActive', pack?.isActive || false)
  if (pack?.products) {
    setValue('products', pack.products.map((product) => product.uuid))
  }

  const handleProducts = (event: any) => {
    const values: string[] = []
    event.map((e: any) => {
      values.push(e.value)
    })
    setValue('products', values)
  }

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (action === 'create') {
      packageService.createPackage(data).then(() => {
        handleIsLoading(true)
        handleClose(true)
      })
    } else {
      packageService.updatePackage(pack?.uuid, data).then(() => {
        handleIsLoading(true)
        handleClose(true)
      })
    }
    reset()
  }

  useEffect(() => {
    ProductService.selectProducts().then((response) => {
      setOptionsProducts(response.data.data)
    })
  }, [])

  return (
    <Modal show={show} onHide={() => handleClose(false)} dialogClassName={'mw-1000px'}>
      <Modal.Header closeButton>
        <Modal.Title>{intl.formatMessage({ id: 'package.create' })}</Modal.Title>
      </Modal.Header>
      <form className='form w-100' onSubmit={handleSubmit(onSubmit)} noValidate id='create-package-form'>
        <Modal.Body>
          <div className='card card-p-0'>
            <div className='card-body'>
              <div className='row mb-5'>
                <div className='col-md-4'>
                  <div className='form-label mt-3 fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'name.label' })}</div>
                </div>
                <div className='col-md-8'>
                  <input
                    {...register('name')}
                    placeholder='Nom du paquet'
                    className='form-control form-control-solid'
                    type='text'
                    name='name'
                    autoComplete='off'
                  />
                  {errors.name && errors.name.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.name.message}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col-md-4'>
                  <div className='form-label mt-3 fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'package.code.label' })}</div>
                </div>
                <div className='col-md-8'>
                  <input
                    {...register('packageCode')}
                    placeholder='Code du paquet'
                    className='form-control form-control-solid'
                    type='text'
                    name='packageCode'
                    autoComplete='off'
                  />
                  {errors.packageCode && errors.packageCode.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.packageCode.message}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col-md-4'>
                  <div className='form-label mt-3 fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'package.fixedRate.label' })}</div>
                </div>
                <div className='col-md-8'>
                  <input
                    {...register('fixedRate')}
                    placeholder='prix du Produit'
                    className='form-control form-control-solid'
                    type='number'
                    name='fixedRate'
                    autoComplete='off'
                  />
                  {errors.fixedRate && errors.fixedRate.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.fixedRate.message}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col-md-4'>
                  <div className='form-label mt-3 fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'package.discount.label' })}</div>
                </div>
                <div className='col-md-8'>
                  <input
                    {...register('discount')}
                    placeholder='remise'
                    className='form-control form-control-solid'
                    type='number'
                    name='discount'
                    autoComplete='off'
                  />
                  {errors.discount && errors.discount.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.discount.message}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col-md-4'>
                  <div className='form-label mt-3 fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'product.tax.label' })}</div>
                </div>
                <div className='col-md-8'>
                  <input
                    {...register('tax')}
                    placeholder='impot sur le paquet'
                    className='form-control form-control-solid'
                    type='number'
                    name='tax'
                    autoComplete='off'
                  />
                  {errors.tax && errors.tax.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.tax.message}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col-md-4'>
                  <div className='form-label mt-3 fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'package.isAutoReactivation.label' })}</div>
                </div>
                <div className='col-md-8'>
                  <div className="form-check form-switch form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      {...register('isAutoReactivation')}
                      id="flexSwitchChecked"
                    />
                  </div>
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col-md-4'>
                  <div className='form-label mt-3 fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'isActive.label' })}</div>
                </div>
                <div className='col-md-8'>
                  <div className="form-check form-switch form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      {...register('isActive')}
                      id="flexSwitchChecked"
                    />
                  </div>
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col-md-4'>
                  <div className='form-label mt-3 fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'products.list' })}</div>
                </div>
                <div className='col-md-8'>
                  <Select
                    styles={colourStyles}
                    options={optionsProducts}
                    isMulti={true}
                    defaultValue={pack?.products?.map((product) => ({ value: product.uuid, label: product.name }))}
                    isSearchable={true}
                    name='products'
                    closeMenuOnSelect={false}
                    onChange={handleProducts}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn btn-sm btn-secondary'
            onClick={async () => handleClose(false)}
          >
            {intl.formatMessage({ id: 'action.close' })}
          </button>
          <button
            type='submit'
            className='btn btn-sm btn-secondary'
          >
            {intl.formatMessage({ id: 'action.save' })}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default PackageCreate
