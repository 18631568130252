import React, { useState, useEffect } from 'react'
import { PageLink, PageTitle } from '../../../../../../_metronic/layout/core'
import { useParams } from 'react-router-dom'
import EmployeeDetailToolbar from './EmployeeDetailToolbar'
import EmployeeService from '../../../../../services/Hr/EmployeeService'
import EmployeesList from './components/EmployeesList'
import EmployeeDetail from './components/EmployeeDetail'
import CreateLeave from './components/CreateLeave'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../../../setup'
import { useIntl } from 'react-intl'

const EmployeeDetailWrapper: React.FC = () => {
  const { id } = useParams()
  const [employee, setEmployee] = useState<IEmployee>()
  const [selectedId, setSelectedId] = useState<string|undefined>(id)
  const [isLoading, setIsLoading] = useState(true)
  const [isLeaveLoading, setIsLeaveLoading] = useState(true)
  const [show, setShow] = useState(false)
  const [leaveType, setLeaveType] = useState('')
  const intl = useIntl()
  const user: IUser = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as IUser
  const employeeDisplayBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({ id: 'home' }),
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: 'employee.management.menu' }),
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: 'employee.management.breadcrumbs' }),
      path: '/employee',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  const handleSelectedId = (id: string) => {
    setSelectedId(id)
    setIsLoading(true)
  }

  const handleIsLeaveLoading = (isLoading: boolean) => {
    setIsLeaveLoading(isLoading)
  }

  const handleIsLoading = (isLoading: boolean) => {
    setIsLoading(isLoading)
  }

  const handleLeaveType = (leaveType: string) => {
    setLeaveType(leaveType)
  }
  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)

  useEffect(() => {
    EmployeeService.getEmployee(selectedId).then((response) => {
      setEmployee(response.data.data)
      setIsLoading(false)
    })
  }, [isLoading])

  return (
    <>
      {user && user.roles.includes('ROLE_ADMIN') ? (
        <PageTitle breadcrumbs={employeeDisplayBreadCrumbs} pageTitle={`${employee?.firstName} ${employee?.lastName}`}>
          <EmployeeDetailToolbar />
        </PageTitle>
      ) : (
        <PageTitle pageTitle={`${employee?.firstName} ${employee?.lastName}`} />
      )}
      <div className='row'>
        {user && user.roles.includes('ROLE_ADMIN') && (
          <div className='col-xxl-4'>
            <EmployeesList handleSelectedId={handleSelectedId}/>
          </div>
        )}
        <div className={`${user && user.roles.includes('ROLE_ADMIN') ? 'col-xxl-8 px-0' : ''} ${isLoading ? 'overlay overlay-block' : ''}`}>
          <EmployeeDetail
            employee={employee}
            handleShow={handleShow}
            isLeaveLoading={isLeaveLoading}
            handleIsLeaveLoading={handleIsLeaveLoading}
            handleLeaveType={handleLeaveType}
            handleIsLoading={handleIsLoading}
            isLoading={isLoading}
          />
        </div>
      </div>
      <CreateLeave
        show={show}
        handleClose={handleClose}
        employee={employee}
        handleIsLeaveLoading={handleIsLeaveLoading}
        initialLeaveType={leaveType}
      />
    </>
  )
}

export default EmployeeDetailWrapper
