import apiService from '../../../setup/axios/ApiService'

const prefixURL = 'customer-activity'

const getAllCustomerActivities = (customerId : string|undefined) => apiService.get(`${prefixURL}/${customerId}`)

const createCustomerActivity = (data: any, customerId: string) => apiService.post(`${prefixURL}/${customerId}`, data)

const updateCustomerActivity = (data: any, uuid: string, customerId: string) => apiService.put(`${prefixURL}/${uuid}/${customerId}/edit`, data)


const CustomerActivityService = {
  getAllCustomerActivities,
  createCustomerActivity,
  updateCustomerActivity,
}

export default CustomerActivityService
