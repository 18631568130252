import apiService from '../../../setup/axios/ApiService'

const prefixURL = 'bank-account'

const getAllBankAccounts = () => apiService.get(`${prefixURL}/`)

const selectBankAccount = () => apiService.get(`${prefixURL}/select/select-bank-account/`)

const createBankAccount = (data: any) => apiService.post(`${prefixURL}/`, data)

const updateBankAccount = (uuid: string|undefined, data: any) => apiService.put(`${prefixURL}/${uuid}`, data)

const deleteBankAccount = (uuid: string|undefined) => apiService.deletes(`${prefixURL}/${uuid}`)

const getBankAccount = (uuid:string|undefined) => apiService.get(`${prefixURL}/${uuid}`)

const BankAccountService = {
  getAllBankAccounts,
  selectBankAccount,
  createBankAccount,
  updateBankAccount,
  deleteBankAccount,
  getBankAccount,
}

export default BankAccountService
