import apiService from '../../setup/axios/ApiService'

const prefixURL = 'workflow-instances'

const changeWorkflowInstance = (id: string, transition: string, data: any) => apiService.put(`${prefixURL}/${id}/workflow/change`, {
  transition,
  data,
})

const WorkflowService = {
  changeWorkflowInstance,

}

export default WorkflowService
