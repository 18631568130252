import React from 'react'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'

type Props = {
    showDetails : boolean,
    handleCloseDetails : () => void,
    workflowInstanceTasks: IWorkflowInstanceTask[]
}
const EstimateDetails : React.FC<Props> = ({ showDetails, handleCloseDetails, workflowInstanceTasks }) => {
  const intl = useIntl()
  return (
    <Modal show={showDetails} onHide={handleCloseDetails} dialogClassName={'mw-500px'}
      backdropClassName={'custom-z-index'} centered>
      <Modal.Header closeButton>
        <Modal.Title>{intl.formatMessage({ id: 'accounting.estimate.details.modal' })}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="timeline-label">
          {workflowInstanceTasks && workflowInstanceTasks.map((item, index) => (
            <div key={`task_${index}`}>
              <div className="timeline-item">
                <div className="timeline-label fw-bold text-gray-800 fs-6">{item.createdAt}</div>
                <div className="timeline-badge">
                  <i className="fa fa-genderless text-primary fs-1"></i>
                </div>
                <div className="timeline-content fw-mormal text-muted ps-3">{item.previousStatus}</div>
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default EstimateDetails
