import React, { useEffect, useState } from 'react'
import TableHeaderSolid from '../../../../../_metronic/partials/med-table/TableHeaderSolid'
import ProductFeatureService from '../../../../services/Crm/ProductFeatureService'
import { KTSVG } from '../../../../../_metronic/helpers'
import PaginationBar from '../../../../../_metronic/partials/pagination/PaginationBar'
import ProductFeatureCreate from './add/ProductFeatureCreate'
import Functions from '../../../../../setup/utilities/Functions'

type Props = {
  show: boolean,
  productFeatureSelected: IProductFeature|null|undefined
  handleShow: (productFeature:IProductFeature|undefined|null, action: string) => void
  handleClose: (isSubmit: boolean) => void
  action: string
}

const ProductFeatureListing : React.FC<Props> = ({ show, handleClose, handleShow, productFeatureSelected, action }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [productFeatures, setProductFeatures] = useState<IProductFeature[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = React.useState(1)
  const handleIsLoading = (loading: boolean) => {
    setIsLoading(loading)
  }

  const headers = [
    {
      minWidth: 'min-w-125px',
      text: 'Name',
    },
    {
      minWidth: 'min-w-175',
      text: 'Description',
    },
    {
      minWidth: 'min-w-100px',
      text: '',
    },
  ]

  const handleCurrentPage = (page:number) => {
    setIsLoading(true)
    setCurrentPage(page)
  }

  const deleteProductFeature = async (uuid:string|undefined) => {
    const isConfirmed = await Functions.sweetAlert(
      '<span class="mt-5">Are you sure you want to delete this product feature?</span>',
      'warning',
      true,
      'Yes, delete!',
      'No, cancel',
      'btn btn-danger mt-10 mb-10',
      'btn btn-active-light mt-10 mb-10',
    )
    if (isConfirmed) {
      ProductFeatureService.deleteProductFeature(uuid).then(() => {
        handleIsLoading(true)
      })
    }
  }

  useEffect(() => {
    ProductFeatureService.getAllProductFeatures(currentPage).then((response) => {
      setProductFeatures(response.data.data.list)
      setTotalPages(response.data.data.totalPages)
      setIsLoading(false)
    })
  }, [isLoading, currentPage])

  return (
    <>
      <div className={`card mb-5 ${isLoading ? 'overlay overlay-block' : ''}`}>
        <div className={`card-body py-3 ${isLoading ? 'overlay-wrapper' : ''}`}>
          <div>
            <div className={'row pt-5'}>
              <div className="table-responsive">
                <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                  <TableHeaderSolid headers={headers} />
                  <tbody>
                    {productFeatures && productFeatures.map((productFeatureItem, index) => (
                      <tr key={`product_feature_${index}`}>
                        <td>
                          <span className="text-muted text-muted">{productFeatureItem.name}</span>
                        </td>
                        <td>
                          <span className="text-muted text-muted">{productFeatureItem.description}</span>
                        </td>
                        <td className="text-end">
                          <a href="src/app/modules/crm/product-feature/components/ProductFeatureListing#" onClick={() => handleShow(productFeatureItem, 'edit')} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 w-32px h-32px">
                            <KTSVG
                              path='/media/icons/duotune/abstract/write.svg'
                              className='svg-icon svg-icon-2'
                            />
                          </a>
                          <a href="src/app/modules/crm/product-feature/components/ProductFeatureListing#" onClick={() => deleteProductFeature(productFeatureItem.uuid)} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 w-32px h-32px">
                            <KTSVG
                              path='/media/icons/duotune/abstract/trash.svg'
                              className='svg-icon svg-icon-2'
                            />
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {isLoading &&
              <div className="overlay-layer rounded bg-dark bg-opacity-5">
                <div
                  className="spinner-border text-primary"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
          }
        </div>
        {totalPages > 1 ? (
          <PaginationBar
            totalPages={totalPages}
            currentPage={currentPage}
            handleCurrentPage={handleCurrentPage}
          />
        ) : (<></>)}
      </div>
      <ProductFeatureCreate
        productFeature={productFeatureSelected}
        show={show}
        handleClose={handleClose}
        handleIsLoading={handleIsLoading}
        action={action}
      />
    </>
  )
}

export default ProductFeatureListing
