import axios from 'axios'

const API_URL = process.env.REACT_APP_BACK_END_POINT

const get = (URL: string, params?: any, headers?: any) => {
  let query = ''
  if (params !== undefined) {
    const searchParams = new URLSearchParams(params)
    query = `?${searchParams.toString()}`
  }
  return axios.get(`${API_URL}/${URL}${query}`, {
    headers: {
      headers,
    },
  })
}

const post = (URL: string, body: any) => axios.post(`${API_URL}/${URL}`, body)

const put = (URL: string, body: any) => axios.put(`${API_URL}/${URL}`, body)

const deletes = (URL: string) => axios.delete(`${API_URL}/${URL}`)

const patch = (URL: string, body: any) => axios.patch(`${API_URL}/${URL}`, body)

const apiService = {
  get,
  post,
  put,
  deletes,
  patch,
}

export default apiService
