import React from 'react'

type Props = {
    headers: { minWidth : string, text: string, isCheckbox: boolean} []
}

const TableHeader : React.FC<Props> = ({ headers }) => (
  <>
    <thead>
      <tr className="fw-bold text-muted">
        {headers && headers.map((header, index) => (
          <th
            key={index}
            className={`${header.minWidth} ${index === (headers.length - 1) ? 'text-end' : ''}`}
          >
            {header.isCheckbox ? (
              <div className='form-check form-check-sm form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  value='1'
                  data-kt-check='true'
                  data-kt-check-target='.widget-13-check'
                />
              </div>
            ) : (<>{header.text}</>)}
          </th>
        ))}
      </tr>
    </thead>
  </>
)

export default TableHeader
