import React from 'react'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../../../../_metronic/helpers'

type Props = {
    cashFlow : ICashFlow|null|undefined
}

const CashFlowHeader : React.FC<Props> = ({ cashFlow }) => (
  <>
    <div className="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10">
      <div className={'card card-xl-stretch mb-xl-8'}>
        <div className='card-body p-0'>
          <div className={'px-9 pt-7 card-rounded h-275px w-100 bg-primary'}>
            <div className='d-flex flex-stack'>
              <Link to={`${cashFlow?.uuid}`}>
                <h3 className='m-0 text-white fw-bold fs-3'>{cashFlow?.name}</h3>
              </Link>
              <div className='ms-1'>
                <button
                  type='button'
                  className={'btn btn-sm btn-icon btn-color-white btn-active-white btn-active-color-primary border-0 me-n3'}
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                >
                  <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
                </button>
              </div>
            </div>
            <div className='d-flex text-center flex-column text-white pt-8'>
              <span className='fw-bold fs-2x pt-1'>{cashFlow?.balance} DT</span>
            </div>
          </div>
          <div
            className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-body'
            style={{ marginTop: '-100px' }}
          >
            <div className='d-flex align-items-center mb-6'>
              <div className='symbol symbol-45px w-40px me-5'>
                <span className='symbol-label bg-lighten'>
                  <KTSVG path='/media/icons/duotune/maps/map004.svg' className='svg-icon-1' />
                </span>
              </div>
              <div className='d-flex align-items-center flex-wrap w-100'>
                <div className='mb-1 pe-3 flex-grow-1'>
                  <a href='src/app/modules/accounting/cash-flow/components/display/CashFlowHeader#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                                      Espèces
                  </a>
                </div>
                <div className='d-flex align-items-center'>
                  <div className='fw-bold fs-5 text-gray-800 pe-1'>{cashFlow?.cash}</div>
                </div>
              </div>
            </div>
            <div className='d-flex align-items-center mb-6'>
              <div className='symbol symbol-45px w-40px me-5'>
                <span className='symbol-label bg-lighten'>
                  <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-1' />
                </span>
              </div>
              <div className='d-flex align-items-center flex-wrap w-100'>
                <div className='mb-1 pe-3 flex-grow-1'>
                  <a href='src/app/modules/accounting/cash-flow/components/display/CashFlowHeader#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                                      Chèques
                  </a>
                </div>
                <div className='d-flex align-items-center'>
                  <div className='fw-bold fs-5 text-gray-800 pe-1'>{cashFlow?.check}</div>
                </div>
              </div>
            </div>
            <div className='d-flex align-items-center mb-6'>
              <div className='symbol symbol-45px w-40px me-5'>
                <span className='symbol-label bg-lighten'>
                  <KTSVG path='/media/icons/duotune/electronics/elc005.svg' className='svg-icon-1' />
                </span>
              </div>
              <div className='d-flex align-items-center flex-wrap w-100'>
                <div className='mb-1 pe-3 flex-grow-1'>
                  <a href='src/app/modules/accounting/cash-flow/components/display/CashFlowHeader#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                                      Trait
                  </a>
                </div>
                <div className='d-flex align-items-center'>
                  <div className='fw-bold fs-5 text-gray-800 pe-1'>{cashFlow?.draft}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default CashFlowHeader
