import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import DepartmentService from '../../../../../services/Hr/DepartmentService'
import ProfileService from '../../../../../services/Hr/ProfileService'

const departmentSchema = Yup.object().shape({
  name: Yup.string()
    .required('Ce champs est obligatoire'),
  shortName: Yup.string()
    .required('Ce champs est obligatoire'),
})

type Inputs = {
    name: string,
    shortName: string,
    profileUuid: string,
    isActive: boolean,
}

type Props = {
  department: IDepartment|null|undefined,
  show: boolean,
  handleClose: (isSubmit: boolean) => void,
  handleIsLoading: (isLoading: boolean) => void,
  action: string,
}

const DepartmentCreate: React.FC<Props> = ({
  department,
  show,
  handleClose,
  handleIsLoading,
  action,
}) => {
  const [options, setOptions] = useState([])
  const intl = useIntl()
  const config = {
    resolver: yupResolver(departmentSchema),
    defaultValues: {
      name: '',
      shortName: '',
      isActive: false,
    },
  }
  const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm<Inputs>(config)
  setValue('name', department?.name || '')
  setValue('shortName', department?.shortName || '')
  if (department?.profileUuid) {
    setValue('profileUuid', department.profileUuid)
  }
  setValue('isActive', department?.isActive || false)

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (action === 'create') {
      DepartmentService.createDepartment(data).then(() => {
        handleIsLoading(true)
        handleClose(true)
        reset()
      })
    } else {
      DepartmentService.updateDepartment(department?.uuid, data).then(() => {
        handleIsLoading(true)
        handleClose(true)
        reset()
      })
    }
  }

  useEffect(() => {}, [department])

  useEffect(() => {
    ProfileService.selectProfile().then((response) => {
      setOptions(response.data.data)
    })
  }, [])


  return (
    <Modal show={show} onHide={() => handleClose(false)} dialogClassName={'mw-1000px'}>
      <Modal.Header closeButton>
        <Modal.Title>Ajouter un département</Modal.Title>
      </Modal.Header>
      <form className='form w-100' onSubmit={handleSubmit(onSubmit)} noValidate id='create-department-form'>
        <Modal.Body>
          <div className='card card-p-0'>
            <div className='card-body'>
              <div className='row mb-5'>
                <div className='col-md-4'>
                  <div className='form-label mt-3 fs-6 text-dark required'>Nom du département</div>
                </div>
                <div className='col-md-8'>
                  <input
                    {...register('name')}
                    placeholder='Nom du département'
                    className='form-control form-control-solid'
                    type='text'
                    name='name'
                    autoComplete='off'
                  />
                  {errors.name && errors.name.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.name.message}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col-md-4'>
                  <div className='form-label mt-3 fs-6 text-dark required'>Abréviation du département</div>
                </div>
                <div className='col-md-8'>
                  <input
                    {...register('shortName')}
                    placeholder='Abréviation du département'
                    className='form-control form-control-solid'
                    name='shortName'
                    autoComplete='off'
                  />
                  {errors.shortName && errors.shortName.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.shortName.message}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col-md-4'>
                  <div className='form-label mt-3 fs-6 text-dark required'>Profile par défault</div>
                </div>
                <div className='col-md-8'>
                  <select
                    {...register('profileUuid')}
                    className={'form-select form-select-lg form-select-solid'}
                    name='profileUuid'
                  >
                    <option value={undefined}>Choississez un profil</option>
                    {options.map((option: {value: string, label: string}, index) => (
                      <option key={`option_profile_${index}`} value={option.value}>{option.label}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col-md-4'>
                  <div className='form-label mt-3 fs-6 text-dark required'>Active</div>
                </div>
                <div className='col-md-8'>
                  <div className="form-check form-switch form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      {...register('isActive')}
                      id="flexSwitchChecked"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn btn-sm btn-secondary'
            onClick={async () => handleClose(false)}
          >
            {intl.formatMessage({ id: 'action.close' })}
          </button>
          <button
            type='submit'
            className='btn btn-sm btn-secondary'
          >
            {intl.formatMessage({ id: 'action.save' })}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default DepartmentCreate
