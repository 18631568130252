import React from 'react'
import { useIntl } from 'react-intl'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import InvoiceToolbar from './components/InvoiceToolbar'
import InvoiceFilter from './components/InvoiceFilter'

const InvoiceWrapper : React.FC = () => {
  const intl = useIntl()

  const invoiceBreadCrumbs: Array<PageLink> = [
    {
      title: 'Home',
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: 'accounting.invoice.menu' }),
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={invoiceBreadCrumbs} pageTitle={intl.formatMessage({ id: 'accounting.invoice.list' })}>
        <InvoiceToolbar/>
      </PageTitle>
      <InvoiceFilter/>
    </>
  )
}

export default InvoiceWrapper
