import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import Functions from '../../../../../../../../setup/utilities/Functions'
import { renderToString } from 'react-dom/server'
import CheckIn from '../../../display/common/components/CheckIn'
import TimeSlotService from '../../../../../../../services/Crm/TimeSlotService'
import Symbol from '../../../../../../../../_metronic/partials/common/Symbol'
import { KTSVG } from '../../../../../../../../_metronic/helpers'
import BreakNotStarted from '../../../display/common/BreakNotStarted'
import BreakStarted from '../../../display/common/BreakStarted'
import BreakFinished from '../../../display/common/BreakFinished'
import Calls from '../../../display/common/components/Calls'
import CallCreate from '../../../../../call/add/CallCreate'
import SugBugCreate from '../../../display/common/components/SugBugCreate'
import ToDoneSupportManager from './ToDoneSupportManager'
import ToDonePatientCall from '../../../display/support/components/cards/ToDonePatientCall'

type Props = {
  journey: IJourney|undefined,
  isLoading: boolean,
  setIsLoading: (isLoading: boolean) => void,
}

const JourneySupportManagerDetails : React.FC<Props> = ({ journey, isLoading, setIsLoading }) => {
  const [showCall, setShowCall] = useState<boolean>(false)
  const [showSugBug, setShowSugBug] = useState<boolean>(false)
  const [showCreateCall, setShowCreateCall] = useState<boolean>(false)
  const [checkOut, setCheckout] = useState<boolean>(false)
  const [calls, setCalls] = useState<ICall[]>([])
  const [timeSlot, setTimeSlot] = useState<ITimeSlot|null>()
  const intl = useIntl()
  const [timeSlots, setTimeSlots] = useState<ITimeSlot[]>([])

  // Show Call History
  const handleShowCall = (calls : ICall[], timeSlot: ITimeSlot|null) => {
    setCalls(calls)
    setTimeSlot(timeSlot)
    setShowCall(true)
  }
  const handleCloseCall = () => setShowCall(false)

  // Show SugBug Create
  const handleShowSugBug = () => setShowSugBug(true)
  const handleCloseSugBug = () => setShowSugBug(false)

  // Show Call Create
  const handleShowCreateCall = (checkout: boolean) => {
    setShowCreateCall(true)
    setCheckout(checkout)
    handleCloseCall()
  }
  const handleCloseCreateCall = () => setShowCreateCall(false)

  // Alert on CheckIn
  const checkIn = async (timeSlot: ITimeSlot) => {
    const item = timeSlots.find((element) => element.isStarted && !element.isFinished)
    if (item) {
      const isConfirmed = await Functions.sweetAlert(renderToString(<CheckIn medicalProfessional={item ? item.todo.medicalProfessional : null}/>),
        'error',
        false,
        'Check out',
        intl.formatMessage({ id: 'action.return' }),
        'btn btn-danger',
        '',
      )
      if (isConfirmed) {
        TimeSlotService.checkOut(item.uuid, null).then(() => {
          setIsLoading(true)
        })
      }
    } else {
      TimeSlotService.checkIn(timeSlot.uuid, null).then(() => {
        setIsLoading(true)
      })
    }
  }

  useEffect(() => {
    if (journey?.uuid) {
      TimeSlotService.getTimeSlotsByJourney(journey!.uuid, 1).then((response) => {
        setTimeSlots(response.data.data)
        setIsLoading(false)
      })
    }
  }, [isLoading, journey])

  return (
    <>

      <div className={`card card-rounded bg-med-purple mb-5 ${isLoading ? 'overlay overlay-block' : ''}`}>
        <div className={`card-body px-0 py-5 ${isLoading ? 'overlay overlay-block' : ''}`}>
          {/* Card Header */}
          <div className="d-flex ms-5">
            <div className="flex-grow-1">
              <div className="row">
                <div className="col-sm-6">
                  <div className="d-flex align-items-start">
                    <Symbol
                      picture={journey?.employee ? journey.employee?.profilePicture : null}
                      picturePath='/admin/uploads/users/'
                      withText={true}
                      widthClassName='symbol-40px me-5'
                      text={Functions.fullName(journey?.employee ? journey?.employee.firstName : '', journey?.employee ? journey.employee.lastName : '')}
                      shortText={Functions.firstLetterUpperCase(journey?.employee ? journey.employee.firstName.charAt(0) : '') + Functions.firstLetterUpperCase(journey?.employee ? journey.employee.lastName.charAt(0) : '')}
                      description={null}
                      backgroundClass=''
                      textClassName='fs-3 fw-400'
                      isCircular={false}
                      descriptionColor={'text-white fs-7 fw-500'}
                      textColor={'text-white fs-3 fw-bold'}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="d-flex justify-content-end">
                    <div className="ms-auto me-3">
                      <div className="d-flex align-items-end">
                        <span className="mt-2">
                          <KTSVG
                            path='/media/icons/duotune/abstract/phone.svg'
                            className='svg-icon svg-icon-1 svg-icon-white'
                          />
                          <KTSVG
                            path='/media/icons/duotune/abstract/pharmacist.svg'
                            className='svg-icon svg-icon-1 svg-icon-white'
                          />
                          <span className="text-white fs-3 fw-bold ms-2">
                            {journey?.medicalProfessionalCalls || 0}
                          </span>
                        </span>
                        <span className="mt-2 ms-10">
                          <KTSVG
                            path='/media/icons/duotune/abstract/phone.svg'
                            className='svg-icon svg-icon-1 svg-icon-white'
                          />
                          <KTSVG
                            path='/media/icons/duotune/communication/com014.svg'
                            className='svg-icon svg-icon-1 svg-icon-white'
                          />
                          <span className="text-white fs-3 fw-bold ms-2">
                            {journey?.patientCalls || 0}
                          </span>
                        </span>
                        <span className='badge badge-white ms-10'>
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr027.svg'
                            className='svg-icon svg-icon-1 svg-icon-primary'
                          />
                          <span className="text-primary fs-5 fw-500">{journey?.startDate ? journey?.startDate : '--:--'}</span>
                        </span>
                        <span className='badge badge-white ms-2'>
                          <KTSVG
                            path='/media/icons/duotune/abstract/reset.svg'
                            className='svg-icon svg-icon-1 svg-icon-danger'
                          />
                          <span className="text-danger fs-5 fw-500">{journey?.endDate ? journey?.endDate : '--:--'}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="separator mt-3"></div>
          {/* /Card Header */}

          {/* Card Body */}
          <div className="mt-5 mb-5 ms-5 me-5">
            <span className="text-white fs-2 fw-bold">Call effectuées</span>
            <ToDonePatientCall journey={journey ? journey : null}/>
            {timeSlots && timeSlots.map((timeSlot, index) => (
              <div key={`to_done_${index}`}>
                {timeSlot.isFinished ?
                  <>
                    {timeSlot.type === 'to_do' ?
                      <>
                        <ToDoneSupportManager timeSlot={timeSlot} handleShowCall={handleShowCall} handleShowCreateCall={handleShowCreateCall} handleShowSugBug={handleShowSugBug}/>
                      </> :
                      <>
                        {timeSlot.type === 'lunch_break' ?
                          <>
                            {timeSlot.isFinished ?
                              <>
                                <BreakFinished journey={journey ? journey : null} timeSlot={timeSlot}/>
                              </> :
                              <></>
                            }
                          </> :
                          <>
                          </>
                        }
                      </>
                    }
                  </> :
                  <></>
                }
              </div>
            ))}
            {timeSlots.filter((item) => item.type === 'lunch_break').length === 0 ?
              <>
                <BreakNotStarted setIsLoading={setIsLoading} journey={journey ? journey : null} timeSlot={null}/>
              </> :
              <>
                {timeSlots && timeSlots.filter((item) => item.type === 'lunch_break').map((item, index) => (
                  <div key={`break_${index}`}>
                    {!item.isFinished ?
                      <>
                        <BreakStarted checkIn={checkIn} setIsLoading={setIsLoading} journey={journey ? journey : null} timeSlot={item}/>
                      </> :
                      <>
                      </>
                    }

                  </div>
                ))}
              </>
            }
          </div>
          {/* /Card Body */}
        </div>
      </div>


      <Calls
        checkOut={checkOut}
        timeSlot={timeSlot ? timeSlot : null}
        calls={calls}
        showCall={showCall}
        handleCloseCall={handleCloseCall}
        handleShowCreateCall={handleShowCreateCall}
        handleCloseCreateCall={handleCloseCreateCall}
        showCreateCall={showCreateCall}
        setIsLoading={setIsLoading}
      />
      <CallCreate
        show={showCreateCall}
        handleClose={handleCloseCreateCall}
        timeSlot={timeSlot ? timeSlot : null}
        checkOut={checkOut}
        setIsLoading={setIsLoading}
        medKey={timeSlot?.todo.medicalProfessional ? timeSlot?.todo.medicalProfessional.medKey : null}
      />
      <SugBugCreate show={showSugBug} handleClose={handleCloseSugBug} timeSlot={timeSlot ? timeSlot : null} medKey={timeSlot?.todo.medicalProfessional ? timeSlot?.todo.medicalProfessional.medKey : null}/>
    </>
  )
}

export default JourneySupportManagerDetails
