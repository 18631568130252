import apiService from '../../../setup/axios/ApiService'

const prefixURL = 'product'

const getAllProducts = (currentPage:number) => apiService.get(`${prefixURL}/`, { page: currentPage })

const updateIsActiveStatus = (uuid:string|undefined, data: any) => apiService.patch(`${prefixURL}/${uuid}/active`, data)

const updateIsAutoRenewStatus = (uuid:string|undefined, data: any) => apiService.patch(`${prefixURL}/${uuid}/renew`, data)

const createProduct = (data:any) => apiService.post(`${prefixURL}/`, data)

const deleteProduct = (uuid: string|undefined) => apiService.deletes(`${prefixURL}/${uuid}/delete`)

const updateProduct = (uuid: string|undefined, data: any) => apiService.put(`${prefixURL}/${uuid}/edit`, data)

const selectFeatureParents = () => apiService.get(`${prefixURL}/select-parents/fr`)

const selectProducts = () => apiService.get(`${prefixURL}/select/select-product`)

const getProduct = (uuid:string|undefined) => apiService.get(`${prefixURL}/${uuid}`)

const getProductsWithMedicalProfessional = (medKey:string|undefined) => apiService.get(`${prefixURL}/products/medical-professional`, {
  medKey,
})

const getProductsByMedicalProfessional = (medKey :string|undefined) => apiService.get(`${prefixURL}/medical-professional/${medKey}`)

const ProductService = {
  getAllProducts,
  updateIsActiveStatus,
  updateIsAutoRenewStatus,
  createProduct,
  deleteProduct,
  updateProduct,
  selectFeatureParents,
  selectProducts,
  getProduct,
  getProductsWithMedicalProfessional,
  getProductsByMedicalProfessional,
}

export default ProductService
