import React from 'react'
import Symbol from '../../../../_metronic/partials/common/Symbol'
import Functions from '../../../../setup/utilities/Functions'

type Props = {
  calls: ICall[]|null
}
const CallListing : React.FC<Props> = ({ calls }) => (
  <>
    {calls && calls.map((call, index) => (
      <div key={`call_${index}`}>
        <div className="d-flex justify-content-start">
          <Symbol
            picture={call.user ? call.user.profilePicture : null}
            picturePath='/admin/uploads/users/'
            withText={true}
            widthClassName='symbol-40px'
            text={Functions.fullName(call.user ? call.user.firstName : '', call.user ? call.user.lastName : '')}
            shortText={Functions.firstLetterUpperCase(call.user ? call.user.firstName.charAt(0) : '') + Functions.firstLetterUpperCase(call.user ? call.user.lastName.charAt(0) : '')}
            description={null}
            backgroundClass=''
            textClassName='fs-3 fw-400'
            isCircular={false}
            descriptionColor={'text-white fs-6 fw-500'}
            textColor={'text-dark fs-6 fw-bold'}
          />
          <div className="ms-auto mt-2">
            <span className="badge badge-warning text-dark h-25px"> Qualité</span>
            <span className="text-dark"> {call.date}</span>
          </div>
        </div>
        <div className="d-flex justify-content-start mt-3">
          <span className="text-dark fs-5 fw-400">
            {call.message}
          </span>
        </div>
        <div className="d-flex justify-content-start mt-3">
          {call.tags.length ? <span className="text-dark fs-3 fw-bold mt-1"> Tag : </span> : <></>}
          <div className="d-flex align-items-start ms-3">
            {call.tags && call.tags.map((item, index) => (
              <Symbol key={`tag_${index}`}
                picture={item.profilePicture}
                picturePath='/admin/uploads/users/'
                withText={true}
                widthClassName='symbol-30px ms-2'
                text={Functions.fullName(item.firstName, item.lastName)}
                shortText={Functions.firstLetterUpperCase(item.firstName.charAt(0)) + Functions.firstLetterUpperCase(item.lastName.charAt(0))}
                description={null}
                backgroundClass=''
                textClassName='fs-3 fw-400'
                isCircular={false}
                descriptionColor={''}
                textColor={'text-dark fs-6 ms-2'}
              />
            ))}
          </div>
        </div>

        <div className='separator mt-5 mb-5'></div>
      </div>
    ))}
  </>
)

export default CallListing
