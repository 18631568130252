import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { KTSVG } from '../../../../../../../../_metronic/helpers'
import { Link } from 'react-router-dom'

type Props = {
  timeSlot: ITimeSlot,
  handleShowCall: (calls: ICall[], timeSlot:ITimeSlot) => void
  handleShowSugBug: (event: any) => void
  active: boolean,
}

const DropDownList : React.FC<Props> = ({ handleShowSugBug, handleShowCall, timeSlot, active }) => (
  <>
    <Dropdown drop={'down'}>
      <Dropdown.Toggle
        variant="text-dark"
        id="dropdown-basic"
        size="lg"
        bsPrefix={'hello'}
        className={'btn btn-icon btn-secondary btn-sm ms-1 w-40px h-40px rounded-3'}
      >
        <KTSVG
          path='/media/icons/duotune/general/gen053.svg'
          className='svg-icon svg-icon-1'
        />
      </Dropdown.Toggle>

      <Dropdown.Menu className="w-200px bg-gray-100" align='start'>
        <div className='menu-item'>
          <a className='menu-link'>
            <Link className="mt-2" to={`/doctor/${timeSlot?.todo?.medicalProfessional?.medKey}`}>
              <KTSVG
                path='/media/icons/duotune/general/gen005.svg'
                className='svg-icon-1 svg-icon-dark'
              />
              <span className="text-dark ms-4">Fiche</span>
            </Link>
          </a>
        </div>
        <div className='menu-item'>
          <a className='menu-link' onClick={() => handleShowCall(timeSlot.todo.medicalProfessional.calls, timeSlot)}>
            <KTSVG
              path='/media/icons/duotune/communication/com007.svg'
              className='svg-icon-1 svg-icon-dark'
            />
            <span className="text-dark ms-4">Call</span>
          </a>
        </div>
        <div className='menu-item'>
          <a className='menu-link'>
            <KTSVG
              path='/media/icons/duotune/finance/fin003.svg'
              className='svg-icon-1 svg-icon-dark'
            />
            <span className="text-dark ms-4">Pay</span>
          </a>
        </div>
        <div className='menu-item'>
          <a className='menu-link' onClick={handleShowSugBug}>
            <KTSVG
              path='/media/icons/duotune/general/gen045.svg'
              className='svg-icon-1 svg-icon-dark'
            />
            <span className="text-dark ms-4">Ajouter Sugbug</span>
          </a>
        </div>
        <div className='menu-item'>
          <a className='menu-link'>
            <KTSVG
              path='/media/icons/duotune/abstract/phone.svg'
              className='svg-icon-1 svg-icon-dark'
            />
            <span className="text-dark ms-4">Appeler</span>
          </a>
        </div>

        {active ?
          <>
          </> :
          <>
            <div className='menu-item'>
              <a className='menu-link'>
                <KTSVG
                  path='/media/icons/duotune/abstract/arrows.svg'
                  className='svg-icon-1 svg-icon-dark'
                />
                <span className="text-dark ms-4">Déplacer</span>
              </a>
            </div>
            <div className='menu-item'>
              <a className='menu-link'>
                <KTSVG
                  path='/media/icons/duotune/abstract/trash.svg'
                  className='svg-icon-1 svg-icon-danger'
                />
                <span className="text-dark ms-4">Supprimer</span>
              </a>
            </div>
          </>
        }

      </Dropdown.Menu>
    </Dropdown>
  </>
)


export default DropDownList
