import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageLink, PageTitle } from '../../../../../../../_metronic/layout/core'
import JourneyService from '../../../../../../services/Crm/JourneyService'
import TimeSlotService from '../../../../../../services/Crm/TimeSlotService'
import TodoSupport from './components/TodoSupport'
import format from 'date-fns/format'
import { addDays } from 'date-fns'
import JourneySupportDetailsToolbar from './JourneySupportDetailsToolbar'
import JourneySupportHeader from './components/JourneySupportHeader'


const JourneySupportDetailsWrapper : React.FC = () => {
  const intl = useIntl()
  const [journey, setJourney] = useState<IJourney>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [timeSlots, setTimeSlots] = useState<ITimeSlot[]>([])
  const [date, setDate] = useState<string>(format(new Date(), 'MM/dd/yyyy'))

  const nextDate = () => {
    setDate(format(addDays(new Date(date), 1), 'MM/dd/yyyy'))
  }

  const previousDate = () => {
    setDate(format(addDays(new Date(date), -1), 'MM/dd/yyyy'))
  }

  const journeyDisplayBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({ id: 'home' }),
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: intl.formatMessage({ id: 'journey.menu' }),
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  useEffect(() => {
    JourneyService.getJourneyOfToday(date).then((response) => {
      setJourney(response.data.data)
      TimeSlotService.getTimeSlotsByJourney(response.data.data.uuid, 0).then((response) => {
        setTimeSlots(response.data.data)
        setTimeout(() => {
          setIsLoading(false)
        }, 1000)
      })
    })
  }, [isLoading, date])
  return (
    <>
      <PageTitle breadcrumbs={journeyDisplayBreadCrumbs} pageTitle={`${journey?.employee?.firstName} ${journey?.employee?.lastName}`}>
        <JourneySupportDetailsToolbar/>
      </PageTitle>
      <div className="d-flex flex-column">
        <div className={`${isLoading ? 'overlay overlay-block' : ''}`}>
          <div className={`${isLoading ? 'overlay overlay-block' : ''}`}>
            <JourneySupportHeader journey={journey ? journey : null} date={date} nextDate={nextDate} previousDate={previousDate} />
            <TodoSupport journey={journey ? journey : null} timeSlots={timeSlots} isLoading={isLoading} setIsLoading={setIsLoading}/>
          </div>
        </div>
      </div>
    </>
  )
}

export default JourneySupportDetailsWrapper
