import React from 'react'
import { useLayout, usePageData } from '../../../../../../_metronic/layout/core'
import clsx from 'clsx'


const BankAccountDetailsToolbar : React.FC = () => {
  const { classes } = useLayout()
  const { pageTitle } = usePageData()

  return (
    <>
      <div
        id='kt_page_title'
        data-kt-swapper='true'
        data-kt-swapper-mode='prepend'
        data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
        className={clsx('page-title d-flex', classes.pageTitle.join(' '))}
      >
        <div className='d-flex flex-wrap'>
          {pageTitle && (
            <h1 className='d-flex align-items-center text-dark fw-bolder my-1 fs-3 me-5'>
              {pageTitle}
            </h1>
          )}
        </div>

      </div>
    </>
  )
}

export default BankAccountDetailsToolbar
