import React from 'react'
import { Dropdown } from 'react-bootstrap'
import Symbol from '../../../../../../../../_metronic/partials/common/Symbol'
import { DropDirection } from 'react-bootstrap/DropdownContext'
import Functions from '../../../../../../../../setup/utilities/Functions'
import { KTSVG } from '../../../../../../../../_metronic/helpers'

type Props = {
  employees: { uuid: string, picture: string, firstName: string, lastName:string, isChecked: boolean }[],
  drop: DropDirection,
  toggleClassName : string,
  onChangeEmployees: (employee: { uuid: string, picture: string, firstName: string, lastName:string, isChecked: boolean }, index: number) => void,
  addEmployee: (employee: { uuid: string, picture: string, firstName: string, lastName:string, isChecked: boolean }) => void,
  removeEmployee: (index:number, uuid: string) => void,
  checkedEmployees : { uuid: string, picture: string, firstName: string, lastName:string, isChecked: boolean }[]
}

const DropDownEmployees : React.FC<Props> = ({
  drop,
  toggleClassName,
  employees,
  onChangeEmployees,
  addEmployee,
  removeEmployee,
  checkedEmployees,
}) => (
  <>
    <Dropdown drop={drop}>
      <Dropdown.Toggle
        variant="text-dark"
        bsPrefix={'t'}
        className={toggleClassName}
      >
        { checkedEmployees && checkedEmployees.map((employee, index) => (
          <span className="badge badge-light-primary ms-1" key={`selected_employee_${index}`}>
            {employee.firstName} {employee.lastName}
            <a onClick={() => removeEmployee(index, employee.uuid)} className="btn btn-sm btn-icon btn-color-primary h-20px">
              <KTSVG
                path='/media/icons/duotune/arrows/arr011.svg'
                className='svg-icon svg-icon-2'
              />
            </a>
          </span>
        ))}

      </Dropdown.Toggle>

      <Dropdown.Menu className="w-200px" align='start'>
        {employees && employees.map((employee, index) => (
          <div key={`employee_${index}`} className='menu-item'>
            <div className="d-flex align-items-start mb-2">
              <div className="form-check form-check-custom form-check-solid ms-3">
                <input className="form-check-input rounded-circle" type="checkbox" disabled={employee.isChecked} checked={employee.isChecked} onChange={() => {
                  onChangeEmployees(employee, index),
                  addEmployee(employee)
                }}/>
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  <Symbol
                    picture={employee.picture}
                    picturePath='/admin/uploads/users/'
                    withText={true}
                    widthClassName='symbol-35px'
                    text={Functions.fullName(employee.firstName, employee.lastName)}
                    shortText={Functions.firstLetterUpperCase(employee.firstName.charAt(0)) + Functions.firstLetterUpperCase(employee.lastName.charAt(0))}
                    description={null}
                    backgroundClass=''
                    textClassName='fs-6 fw-400'
                    isCircular={false}
                    descriptionColor={''}
                    textColor={'text-dark ms-2 fs-7 fw-400'}
                  />
                </label>
              </div>
            </div>
          </div>
        ))}

      </Dropdown.Menu>
    </Dropdown>
  </>
)

export default DropDownEmployees
