import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import CountryService from '../../../../../services/CountryService'
import LocationService from '../../../../../services/Crm/LocationService'
import Select from 'react-select'
import SupplierService from '../../../../../services/Accounting/SupplierService'

type Props = {
  supplier: ISupplier|null|undefined,
  show: boolean,
  handleClose: (isSubmit: boolean) => void,
  handleIsLoading: (isLoading: boolean) => void,
  action: string,
}
const supplierSchema = Yup.object().shape({
  name: Yup.string()
    .required('Ce champs est obligatoire'),
  taxRegistrationNumber: Yup.string()
    .required('Ce champs est obligatoire'),
  commercialRegisterNumber: Yup.string()
    .required('Ce champs est obligatoire'),
  address: Yup.string()
    .required('Ce champs est obligatoire'),
  location: Yup.string()
    .required('Ce champs est obligatoire'),
  country: Yup.string()
    .required('Ce champs est obligatoire'),
  phone: Yup.string()
    .required('Ce champs est obligatoire'),
  email: Yup.string()
    .required('Ce champs est obligatoire'),
})


type Inputs = {
  name: string,
  taxRegistrationNumber: string,
  commercialRegisterNumber: string,
  address: string,
  location: string,
  country: string,
  phone: string,
  email: string,
  isFodecTax: boolean,
  isStamp: boolean,
}
const SupplierCreate : React.FC<Props> = ({ supplier, show, handleClose, handleIsLoading, action }) => {
  const colourStyles = {
    control: (styles: any) => ({ ...styles, backgroundColor: '#F5F8FA', border: 0, height: '45px' }),
  }
  const intl = useIntl()
  const config = {
    resolver: yupResolver(supplierSchema),
    defaultValues: {
      name: '',
      taxRegistrationNumber: '',
      commercialRegisterNumber: '',
      address: '',
      location: '',
      country: '',
      phone: '',
      email: '',
      isFodecTax: false,
      isStamp: false,
    },
  }
  const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm<Inputs>(config)

  const [countries, setCountries] = useState<{value: string, label: string}[]>([])
  const [locations, setLocations] = useState([])

  const handleCountry = (event: any) => {
    setValue('country', event.value)
    LocationService.selectLocationByCountry(event.value).then((response) => {
      setLocations(response.data.data)
    })
  }

  const handleLocation = (event: any) => {
    setValue('location', event.value)
  }

  useEffect(() => {
    setValue('name', supplier?.name || '')
    setValue('taxRegistrationNumber', supplier?.taxRegistrationNumber || '')
    setValue('commercialRegisterNumber', supplier?.commercialRegisterNumber || '')
    setValue('address', supplier?.address || '')
    setValue('phone', supplier?.phone || '')
    setValue('email', supplier?.email || '')
    setValue('country', supplier?.country.uuid || '')
    setValue('location', supplier?.location.uuid || '')
    setValue('isFodecTax', supplier?.isFodecTax || false)
    setValue('isStamp', supplier?.isStamp || false)
  }, [supplier])

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (action === 'create') {
      SupplierService.createSupplier(data).then(() => {
        handleIsLoading(true)
        handleClose(true)
        reset()
      })
    } else {
      SupplierService.updateSupplier(supplier?.uuid, data).then(() => {
        handleIsLoading(true)
        handleClose(true)
        reset()
      })
    }
  }

  useEffect(() => {
    CountryService.selectCountry().then((response) => {
      setCountries(response.data.data)
    })
  }, [])

  return (
    <Modal show={show} onHide={() => handleClose(false)} dialogClassName={'mw-1000px'}>
      <Modal.Header closeButton>
        <Modal.Title>{action === 'create' ?
          <>{intl.formatMessage({ id: 'accounting.supplier.create.modal' })}</> :
          <>{intl.formatMessage({ id: 'accounting.supplier.update.modal' })}: <span className="text-muted fs-5">{supplier?.name}</span></>
        } </Modal.Title>
      </Modal.Header>
      <form className='form w-100' onSubmit={handleSubmit(onSubmit)} noValidate id='create-department-form'>
        <Modal.Body>
          <div className='card card-p-0'>
            <div className='card-body'>
              <div className='row mb-5'>
                <div className='col-md-2'>
                  <div className='form-label mt-3 fs-6 text-dark required'>{intl.formatMessage({ id: 'accounting.supplier.entity.name' })}</div>
                </div>
                <div className='col-md-4'>
                  <input
                    {...register('name')}
                    placeholder={intl.formatMessage({ id: 'accounting.supplier.entity.name' })}
                    className='form-control form-control-solid'
                    type='text'
                    autoComplete='off'
                  />
                  {errors.name && errors.name.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.name.message}</span>
                    </div>
                  )}
                </div>
                <div className='col-md-2'>
                  <div className='form-label mt-3 fs-6 text-dark required'>{intl.formatMessage({ id: 'accounting.supplier.entity.address' })}</div>
                </div>
                <div className='col-md-4'>
                  <input
                    {...register('address')}
                    placeholder={intl.formatMessage({ id: 'accounting.supplier.entity.address' })}
                    className='form-control form-control-solid'
                    autoComplete='off'
                  />
                  {errors.address && errors.address.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.address.message}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col-md-2'>
                  <div className='form-label mt-3 fs-6 text-dark required'>{intl.formatMessage({ id: 'accounting.supplier.entity.taxRegistrationNumber' })}</div>
                </div>
                <div className='col-md-4'>
                  <input
                    {...register('taxRegistrationNumber')}
                    placeholder={intl.formatMessage({ id: 'accounting.supplier.entity.taxRegistrationNumber' })}
                    className='form-control form-control-solid'
                    autoComplete='off'
                  />
                  {errors.taxRegistrationNumber && errors.taxRegistrationNumber.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.taxRegistrationNumber.message}</span>
                    </div>
                  )}
                </div>
                <div className='col-md-2'>
                  <div className='form-label mt-3 fs-6 text-dark required'>{intl.formatMessage({ id: 'accounting.supplier.entity.commercialRegisterNumber' })}</div>
                </div>
                <div className='col-md-4'>
                  <input
                    {...register('commercialRegisterNumber')}
                    placeholder={intl.formatMessage({ id: 'accounting.supplier.entity.commercialRegisterNumber' })}
                    className='form-control form-control-solid'
                    autoComplete='off'
                  />
                  {errors.commercialRegisterNumber && errors.commercialRegisterNumber.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.commercialRegisterNumber.message}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col-md-2'>
                  <div className='form-label mt-3 fs-6 text-dark required'>{intl.formatMessage({ id: 'accounting.supplier.entity.country' })}</div>
                </div>
                <div className='col-md-4'>
                  <Select
                    options={countries}
                    styles={colourStyles}
                    defaultValue={{ value: supplier?.country.uuid, label: supplier?.country.name }}
                    onChange={handleCountry}
                  />
                  {errors.country && errors.country.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <>{errors.country.message}</>
                    </div>
                  )}
                </div>
                <div className='col-md-2'>
                  <div className='form-label mt-3 fs-6 text-dark required'>{intl.formatMessage({ id: 'accounting.supplier.entity.location' })}</div>
                </div>
                <div className='col-md-4'>
                  <Select
                    options={locations}
                    styles={colourStyles}
                    defaultValue={{ value: supplier?.location.uuid, label: supplier?.location.name }}
                    onChange={handleLocation}
                  />
                  {errors.location && errors.location.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <>{errors.location.message}</>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col-md-2'>
                  <div className='form-label mt-3 fs-6 text-dark required'>{intl.formatMessage({ id: 'accounting.supplier.entity.phone' })}</div>
                </div>
                <div className='col-md-4'>
                  <input
                    {...register('phone')}
                    placeholder={intl.formatMessage({ id: 'accounting.supplier.entity.phone' })}
                    className='form-control form-control-solid'
                    autoComplete='off'
                    type="number"
                  />
                  {errors.phone && errors.phone.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.phone.message}</span>
                    </div>
                  )}
                </div>
                <div className='col-md-2'>
                  <div className='form-label mt-3 fs-6 text-dark required'>{intl.formatMessage({ id: 'accounting.supplier.entity.email' })}</div>
                </div>
                <div className='col-md-4'>
                  <input
                    {...register('email')}
                    placeholder={intl.formatMessage({ id: 'accounting.supplier.entity.email' })}
                    className='form-control form-control-solid'
                    autoComplete='off'
                    type="text"
                  />
                  {errors.email && errors.email.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.email.message}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col-md-2'>
                  <div className='form-label mt-3 fs-6 text-dark'>{intl.formatMessage({ id: 'accounting.supplier.entity.isFodecTax' })}</div>
                </div>
                <div className='col-md-4'>
                  <div className="form-check form-switch form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      {...register('isFodecTax')}
                    />
                  </div>
                  {errors.isFodecTax && errors.isFodecTax.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.isFodecTax.message}</span>
                    </div>
                  )}
                </div>
                <div className='col-md-2'>
                  <div className='form-label mt-3 fs-6 text-dark'>{intl.formatMessage({ id: 'accounting.supplier.entity.isStamp' })}</div>
                </div>
                <div className='col-md-4'>
                  <div className="form-check form-switch form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      {...register('isStamp')}
                    />
                  </div>
                  {errors.isStamp && errors.isStamp.message && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert'>{errors.isStamp.message}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn btn-sm btn-secondary'
            onClick={async () => handleClose(false)}
          >
            {intl.formatMessage({ id: 'action.close' })}
          </button>
          <button
            type='submit'
            className='btn btn-sm btn-secondary'
          >
            {intl.formatMessage({ id: 'action.save' })}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default SupplierCreate
