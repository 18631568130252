import React, { useState, useEffect } from 'react'
import LeaveService from '../../../../../../../services/Hr/LeaveService'
import { Modal } from 'react-bootstrap'
import TableHeaderSolid from '../../../../../../../../_metronic/partials/med-table/TableHeaderSolid'
import Functions from '../../../../../../../../setup/utilities/Functions'

type Props = {
  employee: IEmployee|undefined,
  leaveType: string,
  show: boolean,
  handleClose: () => void
}

const LeaveHistory: React.FC<Props> = ({
  employee,
  leaveType,
  show,
  handleClose,
}) => {
  const [leaves, setLeaves] = useState<IEmployeeAbsence[]>()
  const [isLoading, setIsLoading] = useState(true)
  const headers = [
    {
      minWidth: 'min-w-200px',
      text: 'Raison',
    },
    {
      minWidth: 'min-w-175px',
      text: 'Type',
    },
    {
      minWidth: 'min-w-175px',
      text: 'Date',
    },
    {
      minWidth: 'min-w-125px',
      text: 'Status',
    },
  ]

  const handleChangeStatus = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setIsLoading(true)
    LeaveService.getAllEmployeeLeaves(employee?.uuid, leaveType, e.target.value).then((response) => {
      setLeaves(response.data.data)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    LeaveService.getAllEmployeeLeaves(employee?.uuid, leaveType, 'all').then((response) => {
      setLeaves(response.data.data)
      setIsLoading(false)
    })
  }, [employee, leaveType])

  useEffect(() => {
    setIsLoading(true)
  }, [leaveType])


  return (
    <Modal show={show} onHide={handleClose} dialogClassName={'mw-1000px'} centered>
      <Modal.Header closeButton>
        <Modal.Title>Historique congé</Modal.Title>
      </Modal.Header>
      <Modal.Body className='pt-0'>
        <div className={`card card-p-0 px-2 shadow-none ${isLoading ? 'overlay overlay-block' : ''}`}>
          <div className='card-header border-0'>
            <span className='card-title'></span>
            <div className='card-toolbar'>
              <select className='form-select form-select-solid w-200px' onChange={handleChangeStatus}>
                <option value='all'>Tous</option>
                <option value='requested'>En attente</option>
                <option value='approved'>Approuvé</option>
                <option value='rejected'>Rejeté</option>
                <option value='canceled'>Annulé</option>
              </select>
            </div>
          </div>
          <div className={`card-body h-600px ${isLoading ? 'overlay-wrapper' : ''}`}>
            <div className={'row'}>
              <div className="table-responsive">
                <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                  <TableHeaderSolid headers={headers} />
                  <tbody>
                    {leaves && leaves.map((leave, index) => (
                      <tr key={`leave_${index}`}>
                        <td className='ps-3 fw-bold'>
                          {Functions.firstLetterUpperCase(leave.reason)}
                        </td>
                        <td className='fw-bold'>
                          { leave.leaveType === 'leave' && 'Congé' }
                          { leave.leaveType === 'authorization' && 'Autorisation 2 heures' }
                          { leave.leaveType === 'sickness_leave' && 'Congé de maladie' }
                          { leave.leaveType === 'half_day_leave' && 'Demi journée' }
                        </td>
                        <td className='fw-bold'>
                          {((leave.leaveType === 'half_day_leave') || (leave.leaveType === 'authorization')) ?
                            Functions.formatDateDayMonthYear(leave.date, 'numeric', 'long', 'numeric', 'fr-FR') + ' ' + Functions.firstLetterUpperCase(leave.timeOfDay) :
                            Functions.formatDateDayMonth(leave.startDate, 'numeric', 'long', 'fr-FR') + ' - ' + Functions.formatDateDayMonthYear(leave.endDate, 'numeric', 'long', 'numeric', 'fr-FR')}
                        </td>
                        <td className='text-end fw-bold'>
                          {leave.status === 'requested' && <span className='badge badge-warning'>En attente</span>}
                          {leave.status === 'approved' && <span className='badge badge-success'>Validé</span>}
                          {leave.status === 'canceled' && <span className='badge badge-dark'>Annulé</span>}
                          {leave.status === 'rejected' && <span className='badge badge-danger'>Rejeté</span>}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {isLoading &&
                <div className="overlay-layer rounded bg-dark bg-opacity-5">
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
          }
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default LeaveHistory
